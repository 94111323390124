import React, {useState, useCallback} from 'react';
import {Typography,Button} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {TabelleWissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelleWissenstestE1';
import {Tabelle2Wissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelle2WissentestE1';
import {Tabelle3Wissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelle3WissentestE1';
const Stammblatt6 = '/media/Waage_Stammblatt.pdf';
const Stammblatt7 = '/media/Widder_Stammblatt.pdf';
const Stammblatt8 = '/media/Stier_Stammblatt.pdf';



function Wissenstest_Abschluss(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0');
  const [reactnummer]=useState('025');
  const [merkmal]=useState("WissenAbschluss");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
        // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
 
      <Typography variant="h4" color="primary">Wissensabschlusstest</Typography> 
            <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={8}>
                  <Typography variant="h6">
                        Wende dein Wissen an
                      
                  </Typography>

              </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>

            <Tabelle2Wissen 
                tabellenkey='1' 
                frage='Wie ermittelt sich das Nettoentgelt' 
                antwort1='Brutooentgelt abzüglich Steuer und Nettoabzüge' 
                antwort2='Bruttoentgelt abzüglich Sozialversicherungsbeiträge zuzüglich Steuer' 
                antwort3='Bruttoentgelt abzüglich Steuer und Sozialversicherungsbeiträge ' 
                antwort4='Bruttoentgelt abzüglich Steuer, Sozialversicherungsbeiträge und Nettoabzüge' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <TabelleWissen 
                tabellenkey='2' 
                frage='Welche Personengruppe hat ein Geringfügig Beschäftigter?' 
                antwort1='Personengruppe 106' 
                antwort2='Personengruppe 109' 
                antwort3='Personengruppe 110' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='3' 
                frage='In welcher Höhe wird der GWV für die Privatfahrten eines Firmenwagen angesetzt?' 
                antwort1='0,03% vom BLP' 
                antwort2='1% vom Kaufpreis' 
                antwort3='0,03% vom Kaufpreis' 
                antwort4='1% vom BLP' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='4' 
                frage='Welche Grenze wird für die jährliche KV Prüfung verwendet?' 
                antwort1='Beitragsbemessungsgrenze RV West' 
                antwort2='Jahresarbeitsentgeltgrenze' 
                antwort3='Bezugsgröße' 
                antwort4='Geringfügigkeitsgrenze' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
              
            <Tabelle2Wissen
                tabellenkey='5' 
                frage='Mit welcher Formel wird der pauschal zu versteuerte Betrag für die KM zwischen Wohnung und Arbeit berechnet' 
                antwort1='KM*15*0,30' 
                antwort2='KM*BLP*0,03%' 
                antwort3='KM*0,30 Euro' 
                antwort4='KM*BLP*0,002%' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='6' 
                frage='Bitte lege anhand des Stammblatt 600 Wilma Waage die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' 
                antwort1='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort2='Personengruppe:101; Beitragsgruppe:0110; Versicherungsstatuts: privat versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort3='Personengruppe:101; Beitragsgruppe:0111; Versicherungsstatuts: freiwillig versicherter Selbstzahler; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort4='Personengruppe:101; Beitragsgruppe:9111; Versicherungsstatuts: freiwillig versicherter Firmenzahler; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />

                  <Grid item xs={10}style={{marginTop:"15px",marginBottom:"15px"}}>
                          <Button 
                            color="secondary"  
                            variant="contained"
              //              fullWidth
                            href={Stammblatt6}download>
                            Stammblatt 600 Wilma Waage
                          </Button>
                  </Grid>
            
            <Tabelle2Wissen 
                tabellenkey='7' 
                frage='Bitte lege anhand des Stammblatt 700 Walter Widder die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' 
                antwort1='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort2='Personengruppe:106; Beitragsgruppe:0100; Versicherungsstatuts: RV pflichtig; Beschäftigungsart: Werkstudent' 
                antwort3='Personengruppe:109; Beitragsgruppe:6100; Versicherungsstatuts: geringfügig ohne RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' 
                antwort4='Personengruppe:110; Beitragsgruppe:0000; Versicherungsstatuts: sv-frei; Beschäftigungsart: Kurzfristige Beschäftigung' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                  
                  <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
                          <Button 
                            color="secondary"  
                            variant="contained"
              //              fullWidth
                            href={Stammblatt7}download>
                            Stammblatt 700 Walter Widder
                          </Button>
                  </Grid>

            <Tabelle2Wissen 
                tabellenkey='8' 
                frage='Bitte lege anhand des Stammblatt 800 Stefan Stier die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' 
                antwort1='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort2='Personengruppe:101; Beitragsgruppe:0110; Versicherungsstatuts: privat versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort3='Personengruppe:101; Beitragsgruppe:0111; Versicherungsstatuts: freiwillig versicherter Selbstzahler; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                antwort4='Personengruppe:101; Beitragsgruppe:9111; Versicherungsstatuts: freiwillig versicherter Firmenzahler; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                  
                  <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
                            <Button 
                              color="secondary"  
                              variant="contained"
                //              fullWidth
                              href={Stammblatt8}download>
                              Stammblatt 800 Stefan Stier
                            </Button>
                  </Grid>


            <Tabelle3Wissen 
                tabellenkey='9' 
                frage='Wer muss einen Zuschlag zur Pflegeversicherung bezahlen?' 
                antwort1='Alle Mitarbeiter' 
                antwort2='Alle kinderlosen Mitarbeiter' 
                antwort3='Alle kinderlosen Mitarbeiter über 23' 
                antwort4='Alle Mitarbeiter mit Kindern' 
                antwort5='Alle kinderlosen Mitarbeiter über 25' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />

            <TabelleWissen 
                tabellenkey='10' 
                frage='Welche Informationen gibt mir der Beitragsgruppenschlüssel?' 
                antwort1='Welcher Zweig der Sozialversicherung pflichtig ist' 
                antwort2='Bei welcher Krankenkasse der Arbeitnehmer versichert ist' 
                antwort3='Wie der Arbeitnehmer versichert ist' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />

            <Tabelle2Wissen 
                tabellenkey='11' 
                frage='Welche Besonderheit gibt es bei der Steuerberechnung und wie wird dieses Prinzip genannt?' 
                antwort1='Das Quartalsprinzip d.h. die Steuer wird generell quartalsweise berechnet' 
                antwort2='Das Fiktionsprinzip d.h. die Steuer wird auch dann berechnet, wenn der Arbeitnehmer nur ein Recht auf eine Vergütung hat, diese Zahlung aber noch nicht bekommen hat' 
                antwort3='Das Zuflussprinzip d.h. die Steuer wird immer dann berechnet, wenn der Arbeitnehmer die Vergütung tatsächlich bekommt (zufließt).' 
                antwort4='Das "Entstehungsprinzip"- d.h. die Steuer wird immer dann berechnet, wenn der Anspruch auf die Zahlung entsteht' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='12' 
                frage='Wie lautet der Versicherungsstatus eines Mitarbeiters mit der Beitragsgruppe 9111?' 
                antwort1='Freiwillig Versicherter Selbstzahler' 
                antwort2='Gesetzlich Versicherter' 
                antwort3='Freiwillig Versicherter Firmenzahler' 
                antwort4='Sozialversicherungsfreier' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle3Wissen 
                tabellenkey='13' 
                frage='Gibt es Begrenzungen bei der Beitragspflicht?' 
                antwort1='Nein, Beiträge sind immer aus voller Höhe des Entgelts zu berechnen' 
                antwort2='Ja, Begrenzungen auf die Jahresarbeitsentgeltgrenze' 
                antwort3='Ja, Begrenzung auf die Beitragsbemessungsgrenze' 
                antwort4='Ja, aber nur für Auszubildene ' 
                antwort5='Ja, aber nur für Mitarbeiter mit Elterneigenschaften' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
   
            <Tabelle2Wissen 
                tabellenkey='14' 
                frage='Gibt es in der Beitragsberechnung Unterschiede zwischen laufendem und einmalig gezahltem Entgelt?' 
                antwort1='Nein, Beitragsberechnung erfolgt immer über die volle Höhe des Entgelts' 
                antwort2='Ja, bei laufendem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei einmalig gezahltem Entgelt die jährliche Beitragsbemessungsgrenze' 
                antwort3='Ja, bei laufendem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei einmalig gezahltem Entgelt die anteilige jährliche Beitragsbemessungsgrenze' 
                antwort4='Ja, bei einmalig gezahltem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei laufende gezahltem Entgelt die jährliche Beitragsbemessungsgrenze' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='15' 
                frage='Ein Mitarbeiter bekommt einen Firmenwagen zum 22.05.2019. Wie wird der GWV abgerechnet?' 
                antwort1='1% vom BLP' 
                antwort2='1% vom Kaufpreis' 
                antwort3='1% vom BLP anteilig für die Arbeitstage ab 22.05' 
                antwort4='1% vom Kaufpreis anteilig für die Arbeitstage ab 22.05.' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='16' 
                frage='Wozu dienen ELStAM- Daten?' 
                antwort1='Um die Versicherungsnummer abzufragen' 
                antwort2='Für den Lohnsteuerabzug' 
                antwort3='zur Prüfung der postalisch richtigen Adresse' 
                antwort4='Für die Berechnung der Sozialversicherungsbeiträge' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='17' 
                frage='Wie lange dauert der normale Mutterschutz (ohne Früh- oder Mehrlingsgeburt)?' 
                antwort1='8 Wochen vor und 6 Wochen nach der Geburt' 
                antwort2='6 Wochen vor und 12 Wochen nach der Geburt' 
                antwort3='6 Wochen vor und 8 Wochen nach der Geburt' 
                antwort4='8 Wochen vor und 12 Wochen nach der Geburt' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='18' 
                frage='Was wird gezahlt, wenn ein Mitarbeiter länger als 42 Tage wegen eines Arbeitsunfall krank ist und von wem?' 
                antwort1='Arbeitsentgelt vom Arbeitgeber' 
                antwort2='Krankengeld von der Krankenkasse' 
                antwort3='Übergangsgeld von der Deutschen Rentenversicherung' 
                antwort4='Verletztengeld von der Krankenkasse' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='19' 
                frage='Was wird gezahlt, wenn ein Mitarbeiter länger als 42 Tage arbeitsunfähig ist und in die Reha geht?' 
                antwort1='Arbeitsentgelt vom Arbeitgeber' 
                antwort2='Krankengeld von der Krankenkasse' 
                antwort3='Übergangsgeld von der Deutschen Rentenversicherung' 
                antwort4='Verletztengeld von der Krankenkasse' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />
                
            <Tabelle2Wissen 
                tabellenkey='20' 
                frage='Bitte entscheide, ob die folgenden Lohnarten laufend oder als EGA abgerechnet werden können. Überstunden eines Monats, Quartalsprovision, monatliches Urlaubsgeld' 
                antwort1='Überstunden eines Monats- laufend; Quartalsprovision- EGA; monatliches Urlaubsgeld- laufend' 
                antwort2='Überstunden eines Monats- EGA; Quartalsprovision- laufend; monatliches Urlaubsgeld- EGA' 
                antwort3='Überstunden eines Monats- EGA; Quartalsprovision- EGA; monatliches Urlaubsgeld- EGA' 
                antwort4='Überstunden eines Monats- laufend; Quartalsprovision- EGA; monatliches Urlaubsgeld- EGA' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />


            <TabelleWissen 
                tabellenkey='21' 
                frage='Überstunden aus mehreren Monaten sind laufend oder als EGA abzurechnen?' 
                antwort1='als laufendes Entgelt' 
                antwort2='als einmalig gezahltes Entgelt (EGA)' 
                antwort3='es spielt keine Rolle, ob die Überstunden als laufend oder EGA abgerechnet werden' 
                setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
            />


          </Grid>



      <Kursabgeben  anzahlFehler={anzFehler} bezeichnung="Erklärung angeschaut und verstanden" antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
}


export default Wissenstest_Abschluss;