import React from 'react';
import {Grid, Typography, TextField, MenuItem} from '@material-ui/core';


function TabelleLohnarten (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten} = props;

  return(

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>
    <Grid item xs={12} style={{border:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>Erfassung Lohnarten</Typography>
    </Grid>
    <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>
        Lohnart
      </Typography>
    </Grid>

    <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>
        Bezeichnung
      </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>
        Steuerpflichtig
     </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>
        Sozialvers.pflichtig
      </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography>
        Betrag 
      </Typography>
    </Grid>

    <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <TextField
        select // durch select wird DropDown initialisiert
        id={'loa'+tabellenKey} 
        label="Lohnart auswählen"
        fullWidth
        value={antworten['loa'+tabellenKey]||''}
        error={fehlerliste["loa"+tabellenKey]}
        onChange={(event) => setzeAntworten('loa'+tabellenKey,event.target.value)}
        variant="outlined"
        margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
        InputLabelProps={{
            shrink: true,
        }}       
        >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </TextField> 
    </Grid>

    <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <TextField

        select // durch select wird DropDown initialisiert
        id={'text'+tabellenKey} 
        label="Bezeichnung auswählen "
        fullWidth
        value={antworten['text'+tabellenKey]||''}
        error={fehlerliste["text"+tabellenKey]}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('text'+tabellenKey,event.target.value)}
        variant="outlined"
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}       
        >
        <MenuItem value={'Gehalt'}>Gehalt</MenuItem>
        <MenuItem value={'Kontrollzulage'}>Kontrollzulage</MenuItem>
        <MenuItem value={'Prämie'}>Prämie</MenuItem>
        <MenuItem value={'Provision'}>Provision</MenuItem>
        <MenuItem value={'Q-Provision'}>Q-Provision</MenuItem>
        <MenuItem value={'Nachtarbeit'}>Nachtarbeit</MenuItem>
        <MenuItem value={'Nachtzuschlag'}>Nachtzuschlag</MenuItem>
        <MenuItem value={'Urlaubsgeld'}>Urlaubsgeld</MenuItem>
        <MenuItem value={'Überstunden'}>Überstunden</MenuItem>
        <MenuItem value={'Sonntagsarbeit'}>Sonntagsarbeit</MenuItem>
        <MenuItem value={'Sonntagszuschlag'}>Sonntagszuschlag</MenuItem>
        <MenuItem value={'VWL-Zulage'}>VWL-Zulage</MenuItem>
        <MenuItem value={'Zulage'}>Zulage</MenuItem>
      </TextField> 
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>


      
      <TextField
        type="number"
        id={'stpfl'+tabellenKey} 
        label="Steuerpflichtigen Betrag eingeben"
        fullWidth
        error={fehlerliste['stpfl'+tabellenKey]}
        variant="outlined"
        inputProps={{style:{textAlign:"center"}}}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('stpfl'+tabellenKey,event.target.value)} 
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}
        > 
  
      </TextField>             
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
    <TextField

        type="number"
        id={'svpfl'+tabellenKey} 
        label="Sozialversicherungsbeitrag eingeben"
        fullWidth
        error={fehlerliste['svpfl'+tabellenKey]}
        variant="outlined"
        inputProps={{style:{textAlign:"center"}}}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('svpfl'+tabellenKey,event.target.value)} 
        margin="normal"
        InputLabelProps={{
            shrink: true,
           }}   
      >
      </TextField>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
    <TextField
        type="number"
        id={'betrag'+tabellenKey} 
        label="Betrag eingeben"
        fullWidth
        error={fehlerliste['betrag'+tabellenKey]}
        variant="outlined"
        inputProps={{style:{textAlign:"center"}}}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('betrag'+tabellenKey,event.target.value)} 
        margin="normal"
        InputLabelProps={{
            shrink: true,
           }}   
      
      >
      </TextField>
    </Grid>
  </Grid>


   
  )
}






export {TabelleLohnarten};