import React, { Fragment, useCallback, useState } from 'react';
const ectoollogo = '/media/ectool_Logo_2019.png';
import './Header.css';
import { default as apiRequesttwo } from '../../functions/api';
import { useLocation, useNavigate } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import NavigationDrawer from "../shared/components/NavigationDrawer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import MenuIcon from "@material-ui/icons/Menu";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { DEBUG_FLAG } from '../../constants/debug';
import { useAuth, useClient } from '../../context/auth-context';
import { useLocalStorage } from '../../utils/hooks';
import { LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';


function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  icon: {
    color: theme.palette.primary
  },
  debugTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.error.main,
  }
}));

console.log("test");
function Header(props) {
  const [accessToken,] = useLocalStorage(LOCAL_STORAGE_KEY.accessToken);
  const { selectedTab, title } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { logout } = useAuth();
  const apiRequest = useClient();
  const [theme, setTheme] = useLocalStorage(LOCAL_STORAGE_KEY.theme);

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const openMobileDrawer = useCallback(() => {
    setIsMobileOpen(true);
  }, [setIsMobileOpen]);

  const closeMobileDrawer = useCallback(() => {
    setIsMobileOpen(false);
  }, [setIsMobileOpen]);

  const doLogout = () => {
    closeMobileDrawer();
    apiRequest('logout').then(() => logout());
  }

  const setRedirect = (path) => {
    navigate(path);
  }

  const linkCb = (response) => {
    if (response.status === 200) {
      if (response.data.rehmlink !== "") {
        window.open(response.data.rehmlink)
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const LexikonLohnbüro = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": "getLink",
      "reactnummer": "046"
    }
    apiRequesttwo('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
  }


  const menuItems = [
    {
      link: "/",
      name: "Startseite",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <DashboardIcon
            className={
              selectedTab === "Dashboard" ? classes.textPrimary : classes.icon
            }
            fontSize="small"
          />
        ),
        mobile: <DashboardIcon className={classes.icon} />,
      },
    },
    {
      name: "Lexikon für das Lohnbüro",
      onClick: LexikonLohnbüro,
      icon: {
        desktop: (
          <DashboardIcon
            fontSize="small"
          />
        ),
        mobile: <DashboardIcon className={classes.icon} />,
      },
    },
    {
      name: theme === "hell" ? "Dark" : "Light",
      onClick: () => {
        if (theme === "hell") setTheme("dark")
        else setTheme("hell")
      },
      icon: {
        desktop:
          theme === "hell" ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />
        ,
        mobile: theme === "hell" ? <DarkModeIcon className={classes.icon} fontSize="small" /> : <LightModeIcon className={classes.icon} fontSize="small" />,
      },
    },

    {
      name: "Logout",
      onClick: doLogout,
      icon: {
        desktop: (
          <PowerSettingsNewIcon className={classes.icon} fontSize="small" />
        ),
        mobile: <PowerSettingsNewIcon className={classes.icon} />,
      },
    },

  ];

  const backButtonClicked = () => {
    localStorage.removeItem('dataCourse');
    localStorage.removeItem('hsutkey');
    if (location.pathname === '/verify-admincode') {
      navigate.go(-2);
    } else {
      navigate(-1);
    }
  }

  return (
    <Fragment>
      <ElevationScroll {...props}>
        <AppBar position="sticky" className={classes.appBar}>
          <div className="ios-padding"></div>
          <Toolbar>
            {(location.pathname !== '/'
              && location.pathname !== '/login/device'
              && location.pathname !== '/login/password'
              && location.pathname !== '/login/user') && (
                <IconButton edge="start" onClick={() => backButtonClicked()} aria-label="back">
                  <ArrowBackIosIcon />
                </IconButton>
              )}
            <Hidden smDown>
              <img src={ectoollogo} height="30" alt="ectool" loading="lazy" />
            </Hidden>
            <Typography variant="h6" className={classes.title}>
              {title || ''}
            </Typography>
            {DEBUG_FLAG && <Typography variant="h6" className={classes.debugTitle}>
              -LOKAL-
            </Typography>}
            <IconButton onClick={openMobileDrawer} aria-label="back">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <NavigationDrawer
        menuItems={menuItems.map((element) => ({
          link: element.link,
          name: element.name,
          icon: element.icon.mobile,
          onClick: element.onClick,
        }))}
        anchor="right"
        open={isMobileOpen}
        selectedItem={selectedTab}
        onClose={closeMobileDrawer}
      />
    </Fragment>
  );
}

export default Header;
