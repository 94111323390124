import React from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';


function TabelleteilentNeu (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten} = props;

  return(
    <>
      <Tabelleteilent titel="anteiliges Gehalt nach Arbeitstagen" tabellenKey={tabellenKey} setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
      <Tabelleteilent2 titel="anteiliges Gehalt nach SV-Tagen" tabellenKey={tabellenKey+'.'+tabellenKey} setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
      <Tabelleteilent2 titel="anteiliges Gehalt nach Kalendertagen" tabellenKey={tabellenKey+'.'+tabellenKey+'.'+tabellenKey} setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    </>
  )
}



function Tabelleteilent (props) {
  const {titel, tabellenKey, fehlerliste, antworten, setzeAntworten} = props;
  return(
      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
         <Typography style={{}}>
              {titel}
         </Typography>
      </Grid>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography style={{textAlign:'left'}}>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography style={{textAlign:'left'}}>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography style={{textAlign:'left'}}>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography style={{textAlign:'left'}}>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Betrag 
        </Typography>
      </Grid>
  
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px',textAlign:'center'}}>
          0001
        </Typography>
      </Grid>
  
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px',textAlign:'center'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["tage"+tabellenKey]===true)?"red":""}}>
        <TextField 
        type='number' 
        id={'tage'+tabellenKey} 
        error={fehlerliste["tage"+tabellenKey]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage'+tabellenKey,event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["stunde"+tabellenKey]===true)?"red":""}}>
        <TextField  
          id={'stunde'+tabellenKey} 
          type='number'
          error={fehlerliste["stunde"+tabellenKey]}
          label="Eintrag" 
          inputProps={{style:{textAlign:"center"}}}
          placeholder=""  
          style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde'+tabellenKey,event.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
             {antworten['betrag'+tabellenKey]}
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
              {antworten['betrag'+tabellenKey]}
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["betrag"+tabellenKey]===true)?"red":""}}>
         <TextField  
           id={'betrag'+tabellenKey} 
           type='number'
           error={fehlerliste["betrag"+tabellenKey]}
          label="Eintrag" 
          inputProps={{style:{textAlign:"center"}}}
           placeholder=""  
           style={{display:"flex"}}onChange={(event) => setzeAntworten('betrag'+tabellenKey,event.target.value)}/>
      </Grid>
    </Grid>
  
  )
}

function Tabelleteilent2 (props) {
const {titel, tabellenKey, fehlerliste, antworten, setzeAntworten} = props;
return(
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
    <Grid item xs={12} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
       <Typography style={{}}>
            {titel}
       </Typography>
    </Grid>
    <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
      <Typography>
        Lohnart
      </Typography>
    </Grid>
    <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography style={{textAlign:'left'}}>
        Bezeichnung
      </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
      <Typography style={{textAlign:'left'}}>
        Tage
     </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
      <Typography style={{textAlign:'left'}}>
        Stunden
     </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
      <Typography style={{textAlign:'left'}}>
        Steuerpflichtig
     </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
      <Typography style={{textAlign:'left'}}>
        Sozialvers.pflichtig
      </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
      <Typography style={{textAlign:'left'}}>
        Betrag 
      </Typography>
    </Grid>

    <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
      <Typography style={{marginTop:'15px',textAlign:'center'}}>
        0001
      </Typography>
    </Grid>

    <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
      <Typography style={{marginTop:'15px',textAlign:'center'}}>
        Gehalt
      </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["tage"+tabellenKey]===true)?"red":""}}>
      <TextField 
      type='number' 
      id={'tage'+tabellenKey} 
      label="Eintrag" 
      inputProps={{style:{textAlign:"center"}}}
      placeholder=""  
      style={{display:"flex"}}
      value={antworten["tage"+tabellenKey]}
      onChange={(event) => setzeAntworten('tage'+tabellenKey,event.target.value)}
      />
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["stunde"+tabellenKey]===true)?"red":""}}>
      <TextField  
      id={'stunde'+tabellenKey} 
      type='number'
      label="Eintrag" 
      inputProps={{style:{textAlign:"center"}}}
      placeholder=""  
      style={{display:"flex"}}
      value={antworten["stunde"+tabellenKey]}
      onChange={(event) => setzeAntworten('stunde'+tabellenKey,event.target.value)}
      />
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
       <Typography style={{marginTop:'15px',textAlign:'center'}}>
                {antworten['betrag'+tabellenKey]}
       </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
       <Typography style={{marginTop:'15px',textAlign:'center'}}>
                {antworten['betrag'+tabellenKey]}
       </Typography>
    </Grid>
    <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:(fehlerliste["betrag"+tabellenKey]===true)?"red":""}}>
       <TextField  
         id={'betrag'+tabellenKey} 
         type='number'
         error={fehlerliste["betrag"+tabellenKey]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
         placeholder=""  
         style={{display:"flex"}}onChange={(event) => setzeAntworten('betrag'+tabellenKey,event.target.value)}/>
    </Grid>
  </Grid>

)
}


export {TabelleteilentNeu};