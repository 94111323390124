import React, {useState, useCallback, useEffect} from 'react';
import {Typography, Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {BruttoNeu2} from '../Rahmendaten/BruttoNeu2';
import {Tabelle3spaltig} from './../Rahmendaten/Tabelle3spaltig'
import {Tabelle4spaltig} from './../Rahmendaten/Tabelle4spaltig'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {SVBlockneu} from '../../components/Rahmendaten/SVBlockneu';
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'
import {Tabelle8spaltig} from '../Rahmendaten/Tabelle8spaltig'

const handbuch = '/media/rehm_kurzarbeit.pdf';
const KugSollEntgelt ='/media/KurzarbeitSollentgelt.png';
const KugLeistungssatz = '/media/KurzarbeitLeistungssatz.png';
const KugIstEntgelt = '/media/KurzarbeitIstentgelt.png'

function Kurzarbeit(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99')
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [kaltage, setKaltage]=useState([]);
  const [txtwtage,setTxtwtage]=useState([]);
  const [sollzeit, setSollzeit]=useState([]);
  const [istzeit, setIstzeit]=useState([]);
  const [kugstd, setKugstd]=useState([]);
  const [ausgewaehlteLohnarten, setAusgewaehlteLohnarten]=useState([]);
  const [reactnummer]=useState('046');
  const [merkmal]=useState("Kurzarbeit");

  const listlohnarten = [
    { id: "1", content: "1029-Gehalt" },
    { id: "2", content: "1308-Tarifl.Zulage" },
    { id: "3", content: "1309-Frw.Zulage" },
    { id: "4", content: "1310-Schichtzulage" },
    { id: "5", content: "1043-Nachtarbeitszuschlag" },
    { id: "6", content: "1310-Prämie" },
    { id: "7", content: "1401-Meisterzulage" }
  ];


  var gehalt='2700,00', istentgelt='1928,57', kugsventgelt="2545,71", tzulage="200,00", fzulage='150,00', schichtzul='170,00', schichtzul1='120,00', schichtzul2='200,00'
    const setzeAntworten = ( key,value) => {
      let values = Object.assign(antworten);
      values[key] = value;
      setAntworten(values);
      forceUpdate();
    }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler)=>{
    console.log("fehler: "+fehler)
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "ausgewaehlteLoas": ausgewaehlteLohnarten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
    const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
    }

    const linkCb = (response) => {
        if (response.status === 200) {
          if (response.data.rehmlink !== "") {
              window.open(response.data.rehmlink)
          }
         } else {
           if (response) {
             showErrorMessage(response.data.message);
           } else {
             showErrorMessage('Keine Internetverbindung');
           }
         }

    }

    const LexikonLohnbüro = () => {
        const headers={
            "Authorization": "Bearer "+accessToken,
        }
        const payload={
            "kennung": "kursabgabe",
            "merkmal": "getLink",
            "reactnummer": "046"
        } 
        apiRequest('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
    }

    const kug_tabelle = () =>{
      setRedirect();
    }

    return(
      <div>
        <Briefkopf  
            ueberschrift1="Kurzarbeit" 
            ueberschrift2="Was bedeutet Ausfallentgelt, Kurzarbeitergeld und Ausfallstunden?" 
        />

        <Grid container spacing={0} style={{marginTop:'15px', display:"flex"}} >
            <Grid item xs={12} style={{textAlign:"left"}} >
              <Typography variant="h5" color="primary" >
                  Kurzarbeit
              </Typography>
              <Typography >
                <b>Kurzarbeit</b> im Arbeitsverhältnis bedeutet die vorübergehende Verringerung der regelmäßigen Arbeitszeit in einem Betrieb aufgrund eines erheblichen Arbeitsausfalls. <br/>
                Von der Kurzarbeit können alle oder nur ein Teil der Arbeitnehmer des Betriebes betroffen sein. Die betroffenen Arbeitnehmer arbeiten bei Kurzarbeit weniger oder überhaupt nicht. <br/>
                Ob ein Arbeitgeber Kurzarbeit einführen darf und ob sich bei Kurzarbeit der Anspruch auf Arbeitsentgelt (Lohn, Gehalt) der Arbeitnehmer entsprechend verringert, richtet sich nach arbeitsrechtlichen Bestimmungen.<br/><br/>
                Kurzarbeit kann ein Instrument sein, um bei vorübergehendem Arbeitsausfall (v. a. Entfall von Aufträgen) Kündigungen zu vermeiden. Um in diesen Fällen den Verdienstausfall der Arbeitnehmer teilweise auszugleichen, können die Arbeitnehmer unter bestimmten Voraussetzungen eine Entgeltersatzleistung aus der Arbeitslosenversicherung, das so genannte Kurzarbeitergeld, beanspruchen. Zuständig für diese Leistung ist in Deutschland die Bundesagentur für Arbeit, in Österreich der Arbeitsmarktservice. Ein ähnliches Instrument ist der Zwangsurlaub. 

              </Typography>
            </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'30px'}} >
            <ListItem>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    R
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText>
                    <Typography variant="h5">
                        Eigenrecherche im Lexikon für das Lohnbüro
                    </Typography>  
                    <Typography style={{textAlign:"left"}}>
                      Mit Klick auf den Button gelangst Du auf das digitale Lexikon für das Lohnbüro. <br/>
                      Benutze das Lexikon, um Dich auf dem Gebiet der Kurzarbeit schlau zu machen.<br/>
                      Anschließend kannst Du die Aufgaben bestimmt lösen und erkennst die Zusammenhänge.<br/>
                    </Typography>
                    <Button 
                      color="primary"  
                      variant="contained"
                      alignItems="center"
                      backgroundColor="#4F4F4F"
                      style={{align:"center"}}
                      onClick={() => LexikonLohnbüro()} 
                    >
                      Lexikon für das Lohnbüro
                    </Button>
                </ListItemText> 
                <Button 
                  color="primary"  
                  variant="contained"
  //              fullWidth
                  href={handbuch}download>
                  Wie gelange ich zu dem Thema "Kurzarbeitergeld" in dem digitalen Lexikon?"
                </Button>
            </ListItem>
        </Grid>

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6", width:"60px"}}>
              Bsp
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung des Ausfallentgeltes bei Kurzarbeit an einem einfachen Beispiel</strong><br/>
            </Typography>
              <strong>Bei Kurzarbeit ist das Kurzarbeitergeld (Ausfallentgelt) zu berechnen. </strong><br/>
              Zum besseren Verständnis zeigen wir Dir zuerst die Berechnung bei gleichbleibendem Gehalt, ohne Zulagen und flexiblen Gehaltsbestandteile.  <br/>
              Im folgenden, einfachen Beispiel stehen folgende Rahmeninformationen zur Verfügung: <br/>
              - Die abzurechnende Person arbeitet von Montag bis Freitag jeweils 8 Stunden.<br/>
              - Die Person bekommt jeden Monat ein Festgehalt von 2700,00 Euro. <br/>
              - Desweiteren wurde die Person von seinem Arbeitgeber an 6 ganzen Tagen im Monat aufgrund der aktuellen Situation von der Arbeit freigestellt. <br/>
              - Somit ergeben die Ausfall- bzw. KUG-Stunden der Person für den Monat Mai 2021 = 48 Stunden. <br/>
              - Die Person besitzt die Steuerklasse 1, hat keine Kinder und unterliegt keiner Konfession. <br/>

          </ListItemText> 
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'1px' }} justifyContent={"left"} alignItems={"center"} >
        <Avatar style={{backgroundColor:"#FF8C00",  width:24, height:24}}>
        <ArrowForwardIcon></ArrowForwardIcon>
        </Avatar> 
        Der aktuell abzurechnende Monat ist Mai 2021 <br/>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:"60px"}}>
              Bsp1.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung der Sollarbeitszeit des jeweiligen Abrechnungsmonat</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle3spaltig 
                anzReihen="1"
                ueberschrift="Ermittlung Sollarbeitszeit"
                uebschr_spalte1="Arbeitstage Mai 2021"
                spalte1Platz="center"
                uebschr_spalte2="Arbeitszeit pro Tag"
                spalte2Platz="center"
                uebschr_spalte3="= Soll-Arbeitszeit Mai 2021"
                spalte3Platz="center"

                spalten1={["21"]}
                spalten2={["8,00"]}
                spalten3={["168,00"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6", width:"60px"}}>
              Bsp1.2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung Ausfall-Entgelt des jeweiligen Abrechnungsmonats</strong><br/>
            </Typography>


          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle4spaltig 
                anzReihen="1"
                ueberschrift="Ermittlung Ausfall-Entgelt"
                uebschr_spalte1="Gehalt"
                spalte1Platz="center"
                uebschr_spalte2="geteilt durch die Soll-Arbeitszeit Mai 2021"
                spalte2Platz="center"
                uebschr_spalte3="multipliziert mit Ausfallzeit Mai 2021"
                spalte3Platz="center"
                uebschr_spalte4="= Ausfall-Entgelt Mai 2021"
                spalte4Platz="center"
                spalten1={["2700,00"]}
                spalten2={["168,00"]}
                spalten3={["48,00"]}
                spalten4={["771,43"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6", width:"60px"}}>
              1.3
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung Ist-Entgelt des jeweiligen Abrechnungsmonat</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle3spaltig 
                tabellenKey="3" 
                anzReihen="1"
                ueberschrift="Ermittlung Ist-Entgelt"
                uebschr_spalte1="Soll-Entgelt"
                spalte1Platz="center"
                uebschr_spalte2="minus Ausfallentgelt"
                spalte2Platz="center"
                uebschr_spalte3="= Ist-Entgelt Mai 2021"
                spalte3Platz="center"
                spalten1={["2700,00"]}
                spalten2={["711,43"]}
                spalten3={["1928,57"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6", width:"60px"}}>
              1.4
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Nun wird mit beiden Werten (Soll-Entgelt und Ist-Entgelt) in der im jeweiligen Abrechnungszeitraum gültigen KUG-Tabelle die rechnerischen Leistungssätze ermittelt. </strong><br/>
            </Typography>
            <Typography>
              Die für den Abrechnungszeitraum gültige KUG-Tabelle bekommst mit dem Klick auf den Button "KUG-Tabelle 2021"-
            </Typography>
          </ListItemText> 
        </ListItem>
        <Button 
            color="primary"  
            variant="contained"
            alignItems="center"
            backgroundColor="#4F4F4F"
            style={{align:"center"}}
            onClick={() => window.open("https://www.arbeitsagentur.de/datei/berechnung-des-kurzarbeitergeldes-2021-67-60-prozent-_ba146763.pdf")} 
            >
            KUG-Tabelle 2021
          </Button>
      </Grid>
    </Grid>

    <Grid container spacing={4} style={{marginTop:'40px' }} justifyContent={"center"} alignItems={"center"} >
      <Grid item xs={1}></Grid>
      <Grid item xs={4}>
        <img
          src={KugSollEntgelt}
          style={{width:"650px", height:"450px"}}
          alt='KugSollEntgelt'
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={4}>
        <img
          src={KugIstEntgelt}
          style={{width:"650px", height:"450px"}}
          alt='KugSollEntgelt'
        />
      </Grid>
      <Grid item xs={1}></Grid>

      <Grid item xs={2}></Grid>
      <Grid item xs={10} >
        <img
          src={KugLeistungssatz}
          style={{width:"950px", height:"250px"}}
          alt='KugSollEntgelt'
        />
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11}>
            <Tabelle3spaltig 
                tabellenKey="4" 
                anzReihen="1"
                ueberschrift="Ermittlung KUG-Leistungssätze gem. KUG-Tabelle"
                uebschr_spalte1="Leistungssatz Soll-Entgelt"
                spalte1Platz="center"
                uebschr_spalte2="minus Leistungssatz Ist-Entgelt"
                spalte2Platz="center"
                uebschr_spalte3="= KUG-Geld"
                spalte3Platz="center"
                spalten1={["1102,50"]}
                spalten2={["832,90"]}
                spalten3={["269,60"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          <strong>Bruttoblog Entgeltabrechnung Mai 2021</strong>
        </Typography>
        <Typography align="left">
          Der Bruttoblock der Entgeltabrechnung sieht mit den errechneten Werten wie folgt aus:
        </Typography>
        <BruttoNeu2
              anzReihen={4}
              tabellenKey="5" 
              Lohnart={['1029','9961','9969']}
              Intern={['029','961','969']}
              Bezeichnung={["Gehalt",'KUG-Geld','Ausfallentgelt','Summen']}
              Tage={["21,75",'0,00','0,00',"---"]}
              Stunden={["174,00",'48,00','-48,00',"---"]}
              Faktor={[gehalt,'5,62','0,00',"---"]}
              Kennung={["LFD","--","LFD","---"]}
              Prozent={["0,00","60,00","0,00","---"]}
              Steuerpflichtig={[gehalt,"0,00","-771,43","1928,57"]}
              Sozialverspflichtig={[gehalt,"0,00","-771,43","1928,57"]}
              Betrag={[gehalt,"269,60","-771,43","2198,17"]} 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>


    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              5
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Nun folgt die beitragsrechtliche Darstellung bei Vergütung von Kurzarbeit-Geld (KUG)</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10} >
        <SVBlockneu
              kvlfdtage='30' 
              kvlfdPflichtig={kugsventgelt}
              kvlfdANanteil='153,32' 
              kvlfdAGanteil="251,45"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='30' 
              rvlfdPflichtig={kugsventgelt}
              rvlfdANanteil='179,36' 
              rvlfdAGanteil="294,15"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='30' 
              avlfdPflichtig={istentgelt}
              avlfdANanteil='23,14' 
              avlfdAGanteil="23,14"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='30' 
              pvlfdPflichtig={kugsventgelt} 
              pvlfdANanteil='34,23' 
              pvlfdAGanteil="48,23"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar style={{marginLeft:"70px"}}>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              5.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Warum ist das KV-, RV- und PV-Brutto (2545,71) höher als das SV-Brutto (1928,57) bei Vergütung von Kurzarbeit-Geld (KUG)?</strong><br/>
            </Typography>
          <Typography variant='h6'>
            Für die Berechnung der KV, RV und PV Beiträge bei Beziehern von Kurzarbeit wird das tatsächlich erzielte Arbeitsentgelt (Istentgelt) um ein fiktives Arbeitsentgelt erhöht.<br/>
            Ausgangsbasis ist der (auf 80 % verminderte) Unterschiedsbetrag zwischen dem Bruttoarbeitsentgelt, das der Arbeitnehmer ohne den Arbeitsausfall im Anspruchszeitraum erzielt hätte (Sollentgelt), und dem Bruttoarbeitsentgelt, das er im Anspruchszeitraum tatsächlich erzielt hat (Istentgelt). 
            Die Beiträge des fiktiven Entgelts trägt der Arbeitgeber vollständig. 

        </Typography>
        </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={3}> </Grid>
      <Grid item xs={7} >
        <Tabelle2spaltig 
                anzReihen="5"
                ueberschrift="Berechnung fiktives und beitragspflichtiges Entgelt für KV-, RV- und PV-Beiträge"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="left"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Soll-Entgelt (ohne Ausfall) =","Ist-Entgelt =","Ausfall-Entgelt (Unterschiedsbetrag) =","davon 80% (fiktives Entgelt) =","Ist-Entgelt + fiktives Entgelt = beitragspflichtiges Entgelt für KV, RV und PV"]}
                spalten2={["2700,00","1928,57","771,43","617,14","2545,71"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
        />
        <Tabelle8spaltig 
          tabellenKey="" 
          anzReihen="10"
          ueberschrift="Beitragsberechnung für Arbeitnehmer- und Arbeitgeberanteil"
          uebschr_spalte1=""
          spalte1Platz="left"
          uebschr_spalte2="%"
          spalte2Platz="center"
          uebschr_spalte3="AN Anteil (1/2 Beiträge aus Istentgelt)"
          spalte3Platz="right"
          uebschr_spalte4=""
          spalte4Platz="center"
          uebschr_spalte5="AG Anteil (1/2 Beiträge aus Istentgelt)"
          spalte5Platz="right"
          uebschr_spalte6="AG Anteil (volle Beiträge aus fiktivem Entgelt)"
          spalte6Platz="right"
          uebschr_spalte7="="
          spalte7Platz="center"
          uebschr_spalte8="Arbeitgeber-Beiträge gesamt"
          spalte8Platz="right"

          spalten1={["Basis","KV","Zusatzbeitrag","KV Summe","RV","AV","PV","PV Zuschlag","PV Summe"]}
          spalten2={[,"14,60","1,30",,"18,60","2,40","3,05","0,25",]}
          spalten3={["1928,57","140,79","12,53","153,32","179,36","23,14","29,41","4,82","34,23"]}
          spalten4={[,,,,,,,,,,]}
          spalten5={["1928,57","140,79","12,53","153,32","179,36","23,14","29,41",,"29,41"]}
          spalten6={["617,14","90,10","8,02","98,13","114,79",,"18,82",,"18,82"]}
          spalten7={[,,,,,,,,,,]}
          spalten8={["2545,71",,,"251,45","294,15","23,14",,,"48,23"]}

          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
      />

      </Grid>




    </Grid>

    <Grid container spacing={1} style={{marginTop:'70px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar >
            <Avatar style={{backgroundColor:"#1fbba6", width:100, height:100}}>
              Aufgabe1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText style={{marginLeft:"30px"}}>
            <Typography variant="h5">
              <strong>Überprüfe das eben gerlernte und berechne folgenden KUG-Fall</strong><br/>
            </Typography>
              - Abrechnungsmonat Mai 2022<br/>
              - Gehalt 3200,- <br/>
              - Arbeitszeit: 37 h/Woche bei 5 Tage pro Woche (Mo-Fr)<br/>
              - Steuerklasse = 1 / Kinderfreibetrag = 0,5<br/>
              - Gesetzliche Krankenversicherung mit 0,7 % Zusatzbeitrag<br/>
              - Im Abrechnungsmonat ergaben sich 99 KUG-Stunden<br/>
          </ListItemText> 
        </ListItem>
      </Grid>

{//* Ab hier fängt die Aufgabe an Start Beginn Aufgabenbeginn **********************************************************************************************************************************************************************
}

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung der Sollarbeitszeit des jeweiligen Abrechnungsmonat</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle3spaltig 
                tabellenKey="11" 
                anzReihen="1"
                ueberschrift="Ermittlung Sollarbeitszeit"
                uebschr_spalte1="Arbeitstage Mai 2022"
                spalte1Platz="center"
                uebschr_spalte2="Arbeitszeit pro Tag"
                spalte2Platz="center"
                uebschr_spalte3="= Soll-Arbeitszeit Mai 2022"
                spalte3Platz="center"

                spalten1={[""]}
                spalten2={[""]}
                spalten3={[""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung Ausfall-Entgelt des jeweiligen Abrechnungsmonats</strong><br/>
            </Typography>


          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle4spaltig 
                tabellenKey="12" 
                anzReihen="1"
                ueberschrift="Ermittlung Ausfall-Entgelt"
                uebschr_spalte1="Gehalt"
                spalte1Platz="center"
                uebschr_spalte2="geteilt durch die Soll-Arbeitszeit Mai 2022"
                spalte2Platz="center"
                uebschr_spalte3="multipliziert mit Ausfallzeit Mai 2022"
                spalte3Platz="center"
                uebschr_spalte4="= Ausfall-Entgelt Mai 2022"
                spalte4Platz="center"
                spalten1={[""]}
                spalten2={[""]}
                spalten3={[""]}
                spalten4={[""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.3
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung Ist-Entgelt des jeweiligen Abrechnungsmonat</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle3spaltig 
                tabellenKey="13" 
                anzReihen="1"
                ueberschrift="Ermittlung Ist-Entgelt"
                uebschr_spalte1="Soll-Entgelt"
                spalte1Platz="center"
                uebschr_spalte2="minus Ausfallentgelt"
                spalte2Platz="center"
                uebschr_spalte3="= Ist-Entgelt Mai 2022"
                spalte3Platz="center"
                spalten1={[""]}
                spalten2={[""]}
                spalten3={[""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.4
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Nun wird mit beiden Werten (Soll-Entgelt und Ist-Entgelt) in der im jeweiligen Abrechnungszeitraum gültigen KUG-Tabelle die rechnerischen Leistungssätze ermittelt. </strong><br/>
            </Typography>
            <Typography>
              Die für den Abrechnungszeitraum gültige KUG-Tabelle bekommst mit dem Klick auf den Button "KUG-Tabelle 2021"-
            </Typography>
          </ListItemText> 
        </ListItem>
        <Button 
          color="primary"  
          variant="contained"
          alignItems="center"
          backgroundColor="#4F4F4F"
          style={{align:"center"}}
          onClick={() => window.open("https://www.arbeitsagentur.de/datei/berechnung-des-kurzarbeitergeldes-2022-67-60-prozent_ba147226.pdf")} 
          >
          KUG-Tabelle 2022
        </Button>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10}>
            <Tabelle3spaltig 
                tabellenKey="14" 
                anzReihen="1"
                ueberschrift="Ermittlung KUG-Leistungssätze gem. KUG-Tabelle"
                uebschr_spalte1="Leistungssatz Soll-Entgelt"
                spalte1Platz="center"
                uebschr_spalte2="minus Leistungssatz Ist-Entgelt"
                spalte2Platz="center"
                uebschr_spalte3="= KUG-Geld"
                spalte3Platz="center"
                spalten1={[""]}
                spalten2={[""]}
                spalten3={[""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px' }} justifyContent={"left"} alignItems={"left"} >

    <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.5
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Lohnarten im Bruttoblog mit den richtigen Werten vervollständigen. </strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          <strong>Bruttoblog Entgeltabrechnung Mai 2022</strong>
        </Typography>

        <BruttoNeu2
              anzReihen={3}
              tabellenKey="1" 
              Lohnart={['1029','9961','9969']}
              Intern={['029','961','969']}
              Bezeichnung={["Gehalt",'KUG-Geld','Ausfallentgelt']}
              Tage={["",'0,00','0,00']}
              Stunden={["",'','']}

              Faktor={['','','']}
              Kennung={["","--",""]}
              Prozent={["0,00","","0,00"]}
              Steuerpflichtig={['',"",""]}
              Sozialverspflichtig={['',"",""]}
              Betrag={["","",""]}
              
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", width:50, height:50}}>
              A1.6
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              <strong>Berechnung der SV-Beiträge und den SV-Blog mit den exakten Werten bestücken.</strong><br/>
            </Typography>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={10} >
        <SVBlockneu
              tabellenKey="1" 
              kvlfdtage='' 
              kvlfdPflichtig=''
              kvlfdANanteil='' 
              kvlfdAGanteil=""
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig=""
              rvlfdANanteil='' 
              rvlfdAGanteil=""
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig=''
              avlfdANanteil='' 
              avlfdAGanteil=""
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig=''
              pvlfdANanteil='' 
              pvlfdAGanteil=""
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>
      </Grid>

    </Grid>


   
    </Grid>
      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
  }
  

export default Kurzarbeit;
