import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Button, ListItem, ListItemAvatar, Avatar, ListItemText, MenuItem, TextField, Typography, Grid, Paper } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
import "../../../node_modules/video-react/dist/video-react.css"
import { useMessage } from '../../context/message-context';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


function EignungstestZeit1(props) {
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [zeit, setZeit] = useState();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [antworten, setAntworten] = useState({});
  const [uhrzeit, ] = useState(new Date().toLocaleTimeString('de-DE'));  
  const [okay, setOkay] = useState(true);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const {showErrorMessage} = useMessage();

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }


  const setRedirect = (path) => {
    navigate(path);
  }

  useEffect(() => {
    setOkay(false);
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "sperren",
      "merkmal": "EignungZeit1",
      "reactnummer": "200"
    } 
    apiRequest('POST', 'eignungstest', payload, headers, holeDatenCb, setRedirect);
    // eslint-disable-next-line
  }, [])

  const holeDatenCb = (response) => {   
    if (response.status === 200) {
   
      setZeit(2700);
      if (response.data.zeitstempel !== 0) {
        var zwiszeit = response.data.zeitstempel;
        var startzeit = parseInt(zwiszeit.slice(0,2))*60*60
                    + parseInt(zwiszeit.slice(3,5))*60
                    + parseInt(zwiszeit.slice(6,8));


        var endzeit = parseInt(uhrzeit.slice(0,2))*60*60
                    + parseInt(uhrzeit.slice(3,5))*60
                    + parseInt(uhrzeit.slice(6,8));

        var differenz =  endzeit-startzeit; 
        if (differenz < 0) {
          differenz = 0;
        }
        zwiszeit = 2700 - differenz

        setZeit(zwiszeit);
        if (zwiszeit > 0) {
          setOkay(true);
        }
        if (response.data.tagesdat === "FALSE") {
          setOkay(false);
          setZeit(0);
        }


      }

      if (response.data.abgabedat !== '') {
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setZeit(0);
        setOkay(false);
        setTestabgabe(true);
      }
      } else {
      if (response) {
        showErrorMessage(response.data.message);
        navigate(-1);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  //eslint-disable-next-line
  useEffect(() => {
    const timer=setTimeout(() => {
        setZeit(zeit-1);
    }, 1000);
    if (zeit < 0) {
      setOkay(false);
    } 
  return () => clearTimeout(timer);
});


  const antwortenSenden = () => {
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "testabgabe",
        "merkmal": "Eignung1",
        "daten": antworten,
        "reactnummer": "011",
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'eignungstest', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setOkay(false);
      setZeit(0);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      props.showSuccess(response.data.message);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  return(
    <div>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="150" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Eignungstest Zeiterfassung</Typography> 
                  <Typography color="error">
                      Der Test kann nur einmal aktiviert werden.<br />
                      Der Test wird mit dem Anklicken des Buttons "Test abgeben" abgegeben. <br /> <br />
                  </Typography>
                    <h5>Verbleibende Zeit: </h5>
                  {zeit>0 ? 
                    <h5>{
                       Math.floor((zeit / 60) % 60)+' Minuten ' + Math.floor((zeit) % 60)+' Sekunden'
                      }</h5> : <h4>Time's up!</h4>}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      

      {(okay === true) && 
      <div>     
        <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"left"} alignItems={"left"} >
            <Typography align="left">
              Und los geht`s <br/>
              Löse die Quizfragen so schnell wie möglich
            </Typography>
        </Grid>

        <Paper>
          <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={12} >
              <ListItem style={{marginTop:"40px"}}>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    Q1
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText variant="h6">
                    <strong>Quizfrage Nr. 1</strong><br/>
                    Hans ist langsamer als Julia.<br/>
                    Julia ist schneller als Nicole.<br/>
                    Hans ist langsamer als Jan.<br/>
                    Jan ist schneller als Julia.<br/><br/>
                    <strong>Wer ist am schnellsten?</strong>
                </ListItemText> 
              </ListItem>
            </Grid>
            <Grid item xs={6} align="left">
              <TextField
                select // durch select wird DropDown initialisiert
                id="Quiz1"
                label="Antwort auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Quiz1']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Quiz1',event.target.value)} 
                margin="normal"
                textAlign="left"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={1}>Hans</MenuItem>
                <MenuItem value={2}>Julia</MenuItem>
                <MenuItem value={3}>Jan</MenuItem>
                <MenuItem value={4}>Frank</MenuItem>
              </TextField> 

            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={12} >
              <ListItem style={{marginTop:"40px"}}>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    Q2
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText variant="h6">
                    <strong>Quizfrage Nr. 2</strong><br/>
                      Weg A ist länger als Weg B.<br/>
                      Weg B ist kürzer als Weg C.<br/>
                      Weg C ist gleich kurz wie Weg D.<br/>
                      Weg D ist kürzer als Weg A.<br/><br/>
                      <strong>Welcher Weg ist der Kürzeste?</strong>
                </ListItemText> 
              </ListItem>
            </Grid>
            <Grid item xs={6} >
              <TextField
                select // durch select wird DropDown initialisiert
                id="Quiz2"
                label="Antwort auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Quiz2']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Quiz2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={1}>Weg A</MenuItem>
                <MenuItem value={2}>Weg B</MenuItem>
                <MenuItem value={3}>Weg C</MenuItem>
                <MenuItem value={4}>Weg D</MenuItem>
              </TextField> 

            </Grid>
          </Grid>
        </Paper>


        <Paper>
          <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={12} >
              <ListItem style={{marginTop:"40px"}}>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    Q3
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText variant="h6">
                    <strong>Quizfrage Nr. 3</strong><br/>
                    5 Frauen fragen sich, wer die Schönste von ihnen ist.<br/>
                    Frau 1 ist Zweitschönste in NRW, aber Schönste in Niedersachsen.<br/>
                    Frau 2 ist Zweitschönste in Sachsen, aber Schönste in Bayern.<br/>
                    Frau 3 ist Schönste in Hamburg, aber Zweitschönste in Berlin.<br/>
                    Frau 4 ist Zweitschönste in Hamburg, aber Schönste in Sachsen.<br/>
                    Frau 5 ist Schönste in NRW, aber Zweitschönste in Bayern.<br/><br/>
                    Welche Frau ist im Verhältnis zu den anderen Frauen die Schönste?<br/>
                    Hinweis: Frau 5 ist z.B. verhältnismäßig schöner als Frau 1, da Frau 5 in NRW Schönste ist, während Frau 1 in NRW nur Zweitschönste ist.
                </ListItemText> 
              </ListItem>
            </Grid>
            <Grid item xs={6} >
              <TextField
                select // durch select wird DropDown initialisiert
                id="Quiz3"
                label="Antwort auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Quiz3']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Quiz3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={1}>Frau 1</MenuItem>
                <MenuItem value={2}>Frau 2</MenuItem>
                <MenuItem value={3}>Frau 3</MenuItem>
                <MenuItem value={4}>Frau 4</MenuItem>
                <MenuItem value={4}>Frau 5</MenuItem>
              </TextField> 

            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={12} >
              <ListItem style={{marginTop:"40px"}}>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    Q4
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText variant="h6">
                    <strong>Quizfrage Nr. 4</strong><br/>
                    Vorgabe:	<br/>
                    Menschen mit Make-Up legen Wert auf Aussehen.<br/>
                    Die schöne Blondine Marie trägt Make-Up.<br/><br/>
                    Welche der folgenden Aussagen lässt sich aufgrund der Vorgabe eindeutig treffen?<br/>

    
                </ListItemText> 
              </ListItem>
            </Grid>

            <Grid item xs={1} >
            </Grid>
            <Grid item xs={4} >
              <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Nr</TableCell>
                            <TableCell style={{textAlign:'center'}}>Aussage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                A
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                              Alle Menschen tragen Make-Up
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                B
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                              Alle schönen Menschen tragen Make-Up.<br/>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                C
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                              Marie legt Wert auf Aussehen.<br/>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                D
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                            Alle Blondinen sind schön.<br/>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
              </TableContainer>
              <TextField
                select // durch select wird DropDown initialisiert
                id="Quiz4"
                label="Antwort auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Quiz4']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Quiz4',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={1}>Weg A</MenuItem>
                <MenuItem value={2}>Weg B</MenuItem>
                <MenuItem value={3}>Weg C</MenuItem>
                <MenuItem value={4}>Weg D</MenuItem>
              </TextField> 

            </Grid>
            <Grid item xs={7} >
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={12} >
              <ListItem style={{marginTop:"40px"}}>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    Q5
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText variant="h6">
                    <strong>Quizfrage Nr. 5</strong><br/>
                    Vorgabe:	<br/>
                    Alle Personen mit Jacket sind verheiratet.<br/>
                    Alle Verheirateten sind reich.<br/><br/>
                    Welche der folgenden Aussagen lässt sich aufgrund der Vorgabe eindeutig treffen?<br/>
                </ListItemText> 
              </ListItem>

            </Grid>
            <Grid item xs={1} >
            </Grid>
            <Grid item xs={4} >
              <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Nr</TableCell>
                            <TableCell style={{textAlign:'center'}}>Aussage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                A
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               Einige Personen mit Jacket sind arm
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                B
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               Alle Personen mit Jacket sind verheiratet
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                C
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               Alle reiche Personen sind Personen mit Jacket
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                D
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               Alle Personen mit Jacket sind reich
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
              </TableContainer>
              <TextField
                select // durch select wird DropDown initialisiert
                id="Quiz5"
                label="Antwort auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Quiz5']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Quiz5',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={1}>Antwort A</MenuItem>
                <MenuItem value={2}>Antwort B</MenuItem>
                <MenuItem value={3}>Antwort C</MenuItem>
                <MenuItem value={4}>Antwort D</MenuItem>
              </TextField> 


            </Grid>
            <Grid item xs={7} >
            </Grid>
          </Grid>
        </Paper>


      </div>     
      }

      {(testabgabe === true) && 
      <Fragment>
        <Grid container spacing={2} justifyContent="center" style={{marginTop:"15px"}} >
          <Grid item xs={8}>
              <Typography variant="h6">
                Du hast Deinen Test am {abgabedat} um {abgabezeit} Uhr rechtzeitig bei ectool abgegeben. <br /> <br />
              </Typography>
              <Typography variant="h6">
                Nun kommst Du zum Test Nr. 2 <br />
                Für den Test Nr.2 hast Du 45 Minuten Zeit.  <br />
                Sobald Du auf den Button "MIT TEST 2 BEGINNEN" klickst, läuft die Zeit. 
              </Typography>
          </Grid>   
          <Grid item xs={8}>
              <Button 
      //            fullWidth color="primary" 
                  color="primary"
                  variant="contained"
                  onClick={() => navigate('/011/teil2')} 
                  >
                  Mit Test 2 beginnen
              </Button>
          </Grid>
        </Grid>
      </Fragment>
     }


    </div>

  )
}

export default EignungstestZeit1;