import React, {useState} from 'react';
import {Grid, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

function VariableCheckBox (props) {
    const {tabellenKey, fehlerliste, antworten, setzeAntworten, Checktexte, anzReihen, checked, ueberschrift}= props;
 //   let farbe="#000000"
    let farbe="#000000"

    for (let B = 0; B < anzReihen; B++) {
      if (fehlerliste["cbchecked"+tabellenKey+B] === true) {
        farbe="#ee2c2c"
      }

      if (antworten["cbchecked"+tabellenKey+B] !== true && antworten["cbchecked"+tabellenKey+B] !== false)  {
            antworten["cbchecked"+tabellenKey+B]=checked[B];
        }
    } 
    
    const reihenEinfügen  = Checktexte.map((value, i) => {
      
      return (    
        <Grid item xs={12}>
          <div id="blah" style={{textAlign:"left"}}>
          <FormControlLabel
            control={
              <Checkbox
                style={{marginLeft:"60px"}}
                checked={antworten["cbchecked"+tabellenKey+i]}
                onChange={(event) => setzeAntworten("cbchecked"+tabellenKey+i,event.target.checked)}
                icon={<RadioButtonUncheckedIcon fontSize="large"/>}
                checkedIcon={<CheckCircleOutlineIcon fontSize="large"/>}
                
              />
              }
            // label={<Typography style={{color:farbe}} >{Checktexte[i]}</Typography>}
            label={
              <>
              {value.split("\r\n").map((textZeile) => <Typography style={{color:farbe}} >{textZeile}</Typography>)}
              </>
              }
          />  
        </div>
          
        </Grid>
    );})

return(
    <div>

    <Grid container spacing={3} style={{marginTop:'5px' }} >
      {ueberschrift &&
      <Grid item xs={12} >
        <Typography variant="h6" align="left">
           {ueberschrift}
        </Typography>
      </Grid>
      }
        {reihenEinfügen}

    </Grid>

    </div>
 
  )
}

export {VariableCheckBox};