import React, { useState } from 'react';
import {Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';


const MessageContext = React.createContext();


const MessageProvider = (props) => {
  const [message, setMessage] = useState({text: '', severity: 'info'});

  const showErrorMessage = (text) => {
      setMessage({text: text, severity: 'error'});
  }

  const showSuccessMessage = (text) => {
      setMessage({text: text, severity: 'success'});
  }

  const showWarningMessage = (text) => {
    setMessage({text: text, severity: 'warning'});
}

const showInfoMessage = (text) => {
  setMessage({text: text, severity: 'info'});
}

const hideMessage = () => {
    setMessage({text: '', severity: message.severity});
  }

  return <MessageContext.Provider value={{message, showErrorMessage, showSuccessMessage, showWarningMessage, showInfoMessage, hideMessage}}>{props.children}</MessageContext.Provider>
}

const useMessage = () => {
  const context = React.useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
    return context;
}

const MessageViewer = () => { 
  const {message, hideMessage} = useMessage();
  const {text, severity} = message;
  return(
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={text ? true : false}
    autoHideDuration={4000}
    onClose={() => hideMessage()}
    message={text}
  >
    <MuiAlert elevation={6} variant="filled" onClose={() => hideMessage()} severity={severity}>
    {text}
    </MuiAlert>
  </Snackbar>
)
}

export {MessageProvider, useMessage, MessageViewer}