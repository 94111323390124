import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function NettoLoa (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,text, NLohnart, NIntern, NBezeichnung, NBetrag} = props;


    if (NLohnart !== '') {
        antworten["nloa"+tabellenKey]=NLohnart;
        var nloaBlockiert=true;
    }

    if (NIntern !== '') {
        antworten["nintern"+tabellenKey]=NIntern;
        var ninternBlockiert=true;
    }

    if (NBezeichnung !== '') {
        antworten["nbez"+tabellenKey]=NBezeichnung;
        var nbezeichnungBlockiert=true;
    }

    if (NBetrag !== '') {
        antworten["nbetr"+tabellenKey]=NBetrag;
        var nbetragBlockiert=true;
    }

    var svFarbe="#71ff00";
    return (    
    <Grid container spacing={0} style={{marginTop:'30px'}} >
        
        <Grid item xs={12}>
            <Typography>
                {text}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Nettoabzüge</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Lohnart</TableCell>
                            <TableCell style={{textAlign:"center"}}>Intern</TableCell>
                            <TableCell style={{textAlign:"left"}}>Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"right"}}>Betrag</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell key="NLohnart" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={nloaBlockiert}
                                    placeholder={"Eingabe"}
                                    error={fehlerliste["nloa"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nloa"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nloa"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('nloa'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="NIntern" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={ninternBlockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nintern"+tabellenKey]}     
                                    style={{display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["nintern"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nintern"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('nintern'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="NBezeichnung" >
                                <Eingabe 
                                    disabled={nbezeichnungBlockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nbez"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nbez"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nbez"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('nbez'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="NBetrag" style={{}} style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={nbetragBlockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nbetr"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nbetr"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["nbetr"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('nbetr'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {NettoLoa};