import React, {useState, useCallback} from 'react';
import {Avatar, Typography, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const BBGallgemein = '/media/BBG Allgemein.m4v';
const bbgSVtage = '/media/BBG SV Tage.m4v';
const bbgOst = '/media/Chat_Tutorial_Beitragsbemessungsgrenze_Ost.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
const Beispiel1 = "/media/unterBBG.png";
const Beispiel2 = '/media/ueberBBG.png';
import { TabelleBBG} from './tabelleBBG';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function Beitragsbemessungsgrenze(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('0');
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('012');
  const [merkmal]=useState('Beitragsbemessungsgrenze');


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      'hsutkey': hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    console.log(response.data.message);

    if (response.status === 200) {
          setTestabgabe(true);
          fehlerAusLesen(response.data.fehler,response.data.anzfehler)
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
            ueberschrift1="Die Beitragsbemessungsgrenze in der Sozialversicherung" 
            ueberschrift2=" Die Beitragsbemessungsgrenze ist eine Rechengröße im deutschen Sozialversicherungsrecht. ^Sie dient als Deckel für die zu erhebenden Beiträge und bestimmt, 
            bis zu welchem Betrag die beitragspflichtigen Einnahmen von gesetzlich Versicherten, für die Beitragsberechnung der gesetzlichen Sozialversicherung, herangezogen werden. 
            ^Übersteigt das Arbeitsentgelt die Beitragsbemessungsgrenze, wird der Versicherungsbeitrag höchstens von diesem Grenzbetrag erhoben." 
      />

      
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    V1
                  </Avatar> 
                </ListItemAvatar>
              <ListItemText primary="In diesem Video wird Dir ausführlich die Methode der Beitragsbemessungsgrenze erklärt."/>
            </ListItem>
        </Grid>
        <Grid item xs={10} style={{}}>
            <Player
                poster={POSTER}
                src={BBGallgemein}
                >
                <ControlBar autoHide={false} />
            </Player>
        </Grid>
       

          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  T
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Im Internet findest Du die historischen und aktuellen Werte der Beitragsbemessungsgrenzen."/>
          </ListItem>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  B1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Beispiel 1:"/>
          </ListItem>
          <Grid item >
              <img src={Beispiel1} height="500" alt="ectool" loading="lazy"/>
          </Grid>

          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  B2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Beispiel 2:"/>
          </ListItem>
          <Grid item >
              <img src={Beispiel2} height="500" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} style={{marginTop:'40px'}}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  V2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  Wenn für die Beitragsbemessungsgrenze kein ganzer Monat angesetzt werden kann, 
                  ist die Beitragsbemessungsgrenze entsprechend der anteiligen SV-Tage zu berechnen.
                  Wie die Berechnung nach SV-Tagen erfolgt, wird Dir in dem Video detailliert erklärt.                                 
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={10} style={{}}>
            <Player
                poster={POSTER}
                src={bbgSVtage}
                >
                <ControlBar autoHide={false} />
            </Player>
          </Grid>

          <Grid item xs={12} style={{marginTop:'40px'}}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  V3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  Was bedeutet Beitragsbemessungsgrenze Ost?
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={10} style={{}}>
            <Player
                poster={POSTER}
                src={bbgOst}
                >
                <ControlBar autoHide={false} />
            </Player>
          </Grid>

      </Grid>

      <Grid item xs={12} style={{marginTop:'40px'}} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                  <strong>Aufgabe:</strong> <br/> Berechne die jeweilige Monats- und Tages-BBG
                                  
            </ListItemText> 
          </ListItem>
      </Grid>
      

      <Grid container spacing={0} style={{marginTop:'30px', position: 'sticky', top:60, left:0, zIndex:10,backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={2} style={{height:'30px'}}>
          <Typography>
             
          </Typography>
        </Grid>
        <Grid item xs={2} style={{border:'solid'}}>
          <Typography style={{fontWeight:"bold"}}>
            BBG-KV-PV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={4} style={{border:'solid'}}>
          <Typography style={{fontWeight:"bold"}}>
            BBG-RV/AV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
             
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            West
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            West
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            Ost
          </Typography>
        </Grid>

        <Grid item xs={2} style={{borderTop:'solid',borderBottom:'solid', borderLeft:'solid'}}>
          <Typography>
             Jahr
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            58.050,00€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>

        <Grid item xs={2} style={{borderTop:'solid',borderBottom:'solid', borderLeft:'solid'}}>
          <Typography>
            Jahr
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            85.200,00€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
          <Typography>
            80.400,00€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid',borderBottom:'solid',height:'50px' }}>
          <Typography style={{marginTop:'12px'}}>
            Monat
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='monatKVw' 
             type='number'
             error={fehlerliste["monatKVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["monatKVw"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('monatKVw',event.target.value)}/>

        </Grid>

        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>


        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'12px'}}>
            Monat
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='monatRVw' 
             type='number'
             error={fehlerliste["monatRVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["monatRVw"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('monatRVw',event.target.value)}/>

        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='monatRVo' 
             type='number'
             error={fehlerliste["monatRVo"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["monatRVo"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('monatRVo',event.target.value)}/>

        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid',borderBottom:'solid',height:'50px'}}>
          <Typography style={{marginTop:'12px'}}>
             Tag
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='tagKVw' 
             type='number'
             error={fehlerliste["tagKVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["tagKVw"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('tagKVw',event.target.value)}/>

        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography>
            
          </Typography>
        </Grid>

        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'12px'}}>
            Tag
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='tagRVw' 
             type='number'
             error={fehlerliste["tagRVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["tagRVw"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('tagRVw',event.target.value)}/>

        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
          <TextField  
             id='tagRVo' 
             type='number'
             error={fehlerliste["tagRVo"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex", backgroundColor:(fehlerliste["tagRVo"]===true)?"red":""}}
             onChange={(event) => setzeAntworten('tagRVo',event.target.value)}/>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
         <Grid item xs={12}>
         <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                <strong> Eintritt: 01.03.</strong>              
            </ListItemText> 
          </ListItem>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Lohnart
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Bezeichnung
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Tage
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Stunden
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Steuerpflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            SVPflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Betrag
          </Typography>
        </Grid>
      </Grid>     
      <Grid container spacing={0} style={{}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            10
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
           Gehalt
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            21,75
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
            174
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            3.700,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
            3.700,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            3.700,00€
          </Typography>
        </Grid>
      <ListItem>
            <ListItemText>
                 Trage die SV-Tage und den zu verbeitragenden Betrag in die dafür vorgesehenen Spalten ein. <br />
                 Für die Ermittlung des zu verbeitragenden Entgelts verwende bitte immer die Werte der Beitragsbemessungsgrenze West.
                                  
            </ListItemText> 
          </ListItem>
       </Grid>  
    

      <TabelleBBG tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
         <Grid item xs={12}>
         <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A3
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                 <strong> Eintritt: 01.04.</strong>              
            </ListItemText> 
          </ListItem>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Lohnart
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Bezeichnung
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Tage
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Stunden
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Steuerpflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            SVPflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Betrag
          </Typography>
        </Grid>
      </Grid>     
      <Grid container spacing={0} style={{}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            10
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
           Gehalt
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            21,75
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
            174
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            8.000,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
            8.000,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            8.000,00€
          </Typography>
        </Grid>
        <ListItem>
            <ListItemText>
                 Trage bitte die SV-Tage und den zu verbeitragenden Betrag in die dafür vorgesehenen Spalten ein.
                 Für die Ermittlung des zu verbeitragenden Entgelts verwende bitte immer die Werte der Beitragsbemessungsgrenze West.                 
            </ListItemText> 
          </ListItem>
      </Grid> 
      <TabelleBBG tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
         <Grid item xs={12}>
         <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A4
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                 <strong> Eintritt: 17.05.</strong>              
            </ListItemText> 
          </ListItem>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Lohnart
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Bezeichnung
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Tage
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            Stunden
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Steuerpflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderTop:'solid',borderBottom:'solid'}}>
          <Typography>
            SVPflichtig
          </Typography>
        </Grid>
        <Grid item xs={1} style={{border:'solid'}}>
          <Typography>
            Betrag
          </Typography>
        </Grid>
      </Grid>     
      <Grid container spacing={0} style={{}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            10
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
           Gehalt
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
            11,39
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
            91,14
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
             2.500,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid'}}>
          <Typography>
             2.500,00€
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderLeft:'solid',borderRight:'solid',borderBottom:'solid'}}>
          <Typography>
             2.500,00€
          </Typography>
        </Grid>
        <ListItem>
            <ListItemText>
                 Trage bitte die SV-Tage und den zu verbeitragenden Betrag in die dafür vorgesehenen Spalten ein
                 Für die Ermittlung des zu verbeitragenden Entgelts verwende bitte immer die Werte der Beitragsbemessungsgrenze West.
                                  
            </ListItemText> 
          </ListItem>
      </Grid> 
      <TabelleBBG tabellenKey="3" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
}


export default Beitragsbemessungsgrenze;