import React, {useState, useCallback} from 'react';
import {Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import {Kursabgeben} from '../Rahmendaten/Kursabgeben';
import {BruttoNeu2} from '../Rahmendaten/BruttoNeu2';
import {BruttoNeu} from '../Rahmendaten/BruttoNeu';
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import {SVBlockneu} from '../../components/Rahmendaten/SVBlockneu';

function SonstEinnahmeSV(props) {
  const { accessToken, hsutkey } = props;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [testabgabe, setTestabgabe]=useState(false);
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('040');
  const [merkmal]=useState("§23cSGBIVSV");

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
//    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
//    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
//   console.log({antworten})

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
 //   console.log(response.data.message);

    if (response.status === 200) {
        setTestabgabe(true);
        fehlerAusLesen(response.data.fehler,response.data.anzfehler)
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
} else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }


  return(    
  <div>
    <Briefkopf  
        ueberschrift1="§23c SGB IV - Sonstige beitragspflichtige Einnahme" 
        ueberschrift2="Anwendung § 23c SGB IV bei geldwerten Vorteilen (Firmenwagen)" 
    />

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              !
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>
              Du hast im vorherigen Kurs alle Berechnungen für die Beurteilung des § 23c SGB IV richtig durchgeführt.<br/>
              Gratulation!!! <br/>
              Hast Du Lust darauf, die Auswirkungen der Berechnungen auf die monatlichen SV-Tage kennen zu lernen? <br/>
              Wie viel SV-Tage hat der Abrechnungsmonat, wenn der anteilige geldwerte Vorteil größer als der anteilige Freibetrag gem. § 23c SGB IV ist?<br/>
              Und wie viel SV-Tage hat der Abrechnungsmonat, wenn der anteilige geldwerte Vorteil kleiner als der anteilige Freibetrag gem. § 23c SGB IV ist?<br/>
              <InsertEmoticonIcon/>Antworten auf diese Fragen bekommst Du in diesem Kurs. <InsertEmoticonIcon/>
            </strong>
           
          </ListItemText> 
        </ListItem>
      </Grid>

      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Bsp
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Bruttoblog ohne Berechnung gem. §23c SGB IV <br/> </strong>
          </ListItemText> 
        </ListItem>
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog einer Entgeltabrechnung ohne besondere Vorkommnisse
        </Typography>
        <BruttoNeu2
              anzReihen={4}
//*              tabellenKey="1" 
              Lohnart={['1029','1350','1355']}
              Intern={['029','350','355']}
              Bezeichnung={["Gehalt","Priv.KfZ-Nutzg*","Fahrten Whn-Arb*"]}
              Tage={["21,75","--","--","--"]}
              Stunden={["152,25","--","--","--"]}
              Faktor={["3000,00","750,00","270,00"]}
              Kennung={["LFD","LFD","LFD",]}
              Prozent={["0,00","0,00","0,00"]}
              Steuerpflichtig={["3000,00","750,00","270,00"]}
              Sozialverspflichtig={["3000,00","750,00","270,00"]}
              Betrag={["3000,00","750,00","270,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={1}>*</Grid>
      <Grid item xs={10} >
        <Typography align="left">
            Lohnart 1350 Priv.KfZ-Nutzg = geldwerter Vorteil für die Nutzung des Firmenwagens <br/>
            Lohnart 1355 Fahrten Whn-Arb = geldwerter Vorteil für die Fahrten von der Wohnung zur Arbeitsstätte mit dem Firmenwagen<br/>
        </Typography>
      </Grid>


      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Bsp
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Bruttoblog mit Berechnung gem. §23c SGB IV <br/> </strong>
          </ListItemText> 
        </ListItem>
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog einer Entgeltabrechnung 09/2021, bei der die Lohnfortzahlung am <strong>22.09.2021</strong> endet.<br/>
        </Typography>
        <BruttoNeu2
              anzReihen={4}
//*              tabellenKey="1" 
              Lohnart={['1029','1350',"1781","0783"]}
              Intern={['029','350','781','783']}
              Bezeichnung={["Gehalt","Priv.KfZ-Nutzg*",'KK-Leistung*','Freibtr.gem23c*']}
              Tage={["15,82","--","--","--"]}
              Stunden={["110,73","--","--","--"]}
              Faktor={["2181,82","750,00","75,39","-67,05"]}
              Kennung={["LFD","LFD","--","--"]}
              Prozent={["0,00","0,00","0,00","0,00"]}
              Steuerpflichtig={["2181,82","750,00","0,00","0,00"]}
              Sozialverspflichtig={["2181,82","750,00","0,00","-67,05"]}
              Betrag={["2181,82","750,00","0,00","0,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={1}>*</Grid>
      <Grid item xs={10} >
        <Typography align="left">
            Lohnart 1350 Priv.KfZ-Nutzg = geldwerter Vorteil für die Nutzung des Firmenwagens <br/>
            Lohnart 1781 KK-Leistung = von der Krankenkasse pro Kalendertag übermitteltes netto Krankengeld<br/>
            Lohnart 0783 Freibtr.gem23c = anteiliger Freibetrag gem. § 23c SGB IV<br/>
            <strong>Die Lohnart 1355 Fahrten Whn-Arb braucht nicht mehr abgerechnet zu werden, da in dem Abrechnungsmonat die Arbeitnehmerin aufgrund ihrer ganzmonatiger Krankheit kein einziges Mal mit dem Firmenwagen in die Firma gefahren ist
            und somit kein geldwerter Vorteil für die Fahrten von der Wohnung zur Arbeitsstätte entstanden sind. </strong> <br/>
        </Typography>
      </Grid>
  </Grid>
  
  <Grid container spacing={3} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              gwV
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            Berechnung des anteiligen geldwerten Vorteils für den Zeitraum des Krankengeldbezuges.<br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={5} >
        <Tabelle2spaltig 
          tabellenKey="3" 
          anzReihen="4"
          ueberschrift="Geldwerte Vorteil für den Firmenwagen während Krankengeldbezug"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Wert"
          spalte2Platz="right"
          spalten1={["Geldwerte Vorteile Firmenwagen 1%-Methode","SV-Tage Krankengeldbezug","Soll-SV-Tage ganzer Monat","anteiliger geldwerte Vorteil während Krankengeldbezug"]}
          spalten2={["750,00","8","30",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>

    <Grid container spacing={3} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              <Typography variant="h3">!</Typography>
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            Da der anteilige, für den Zeitraum des Krankengeldbezuges vergütete geldwerte Vorteil größer ist, als der anteilige Freibetrag in Höhe von 67,05 Euro, 
            findet <strong>keine Kürzung von SV-Tagen</strong> statt.<br/>
            Somit sieht der SV-Block auf der Entgeltabrechnung wie folgt aus. <br/>
            <Typography variant='body2'>(Auf die Anzeige der AN- und AG-Anteile wurde verzichtet, da hier das Hauptaugenmerk auf die SV-Tage und das beitragspflichtige Brutto liegen soll.)</Typography> 
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={5} >
      </Grid>
    </Grid>

      <SVBlockneu
          tabellenKey="1"
          kvlfdtage='30' 
          kvlfdPflichtig='3134,77' 
          kvlfdANanteil='--' 
          kvlfdAGanteil="--"
          kvegatage='0' 
          kvegaPflichtig='0,00' 
          kvegaANanteil='--' 
          kvegaAGanteil="--"
          rvlfdtage='30' 
          rvlfdPflichtig='3134,77' 
          rvlfdANanteil='--' 
          rvlfdAGanteil="--"
          rvegatage='0' 
          rvegaPflichtig='0,00' 
          rvegaANanteil='--' 
          rvegaAGanteil="--"
          avlfdtage='30' 
          avlfdPflichtig='3134,77' 
          avlfdANanteil='--' 
          avlfdAGanteil="--"
          avegatage='0' 
          avegaPflichtig='0,00' 
          avegaANanteil='--' 
          avegaAGanteil="--"
          pvlfdtage='30' 
          pvlfdPflichtig='3134,77' 
          pvlfdANanteil='--' 
          pvlfdAGanteil="--"
          pvegatage='0' 
          pvegaPflichtig='0,00' 
          pvegaANanteil='--' 
          pvegaAGanteil="--"
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
      />


    <Grid container spacing={3} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              <Typography variant="h3">!</Typography>
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
          <strong>Ist hingegen der anteilige geldwerte Vorteil zusammen mit einem etwaigen Zuschuss zum Krankengeld kleiner als der anteilige Freibetrag, werden die SV-Tage gekürzt</strong> <br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={5} >
      </Grid>

      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              <Typography variant="h5">A</Typography>
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <strong>Aufgabe</strong><br/>
            Fülle den Brutto- und SV-Block für 09/2021 unter der Annahme aus, dass die erkrankte Person den Firmenwagen am 31.08.2021 dem Arbeitgeber zurück 
            gegeben hat und der Arbeitgeber keinen Zuschuss zum Krankengeld bezahlt. <br/>
            Das Ende der Lohnfortzahlung ist nach wie vor am 22.09.2021
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={5} >
      </Grid>
    </Grid>

    <BruttoNeu
        tabellenKey="1"
        Lohnart='1029'
        Intern='029'
        Bezeichnung='Gehalt'
        Tage=''
        Stunden=''
        Faktor=''
        Kennung='LFD'
        Prozent='0,00'
        Steuerpflichtig=''
        Sozialverspflichtig=''
        Betrag =''
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
    />  

    <SVBlockneu
          tabellenKey="2"
          kvlfdtage='' 
          kvlfdPflichtig='' 
          kvlfdANanteil='--' 
          kvlfdAGanteil="--"
          kvegatage='0' 
          kvegaPflichtig='0,00' 
          kvegaANanteil='--' 
          kvegaAGanteil="--"
          rvlfdtage='' 
          rvlfdPflichtig='' 
          rvlfdANanteil='--' 
          rvlfdAGanteil="--"
          rvegatage='0' 
          rvegaPflichtig='0,00' 
          rvegaANanteil='--' 
          rvegaAGanteil="--"
          avlfdtage='' 
          avlfdPflichtig='' 
          avlfdANanteil='--' 
          avlfdAGanteil="--"
          avegatage='0' 
          avegaPflichtig='0,00' 
          avegaANanteil='--' 
          avegaAGanteil="--"
          pvlfdtage='' 
          pvlfdPflichtig='' 
          pvlfdANanteil='--' 
          pvlfdAGanteil="--"
          pvegatage='0' 
          pvegaPflichtig='0,00' 
          pvegaANanteil='--' 
          pvegaAGanteil="--"
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
    />


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>      
  </div>

    )



  }

export default SonstEinnahmeSV;