import React, {useState, useCallback} from 'react';
import {Avatar,Typography,Button, MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function ScheckLStAnm(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0');
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('804');
  const [merkmal]=useState("ScheckLStAnm");

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };

 
  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
    if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        fehlerAusLesen(response.data.fehler);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }


  return(
    <div>
      <Briefkopf  
          ueberschrift1="Lohnsteueranmeldung" 
          ueberschrift2="" 
      />
      <Grid container spacing={1}  style={{marginTop:'30px',textAlign:'left'}}  alignItems={"center"}>
        <Grid item xs={12}>
         <Typography variant='h5'color='primary'>
          1. Allgemein
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
              Ein Arbeitnehmer ist verpflichtet, von seinen Bruttoentgelten (je nach gesetzlichen Bestimmungen) Lohn- und Kirchensteuer sowie Solidaritätszuschlag abzuführen. Da die Lohnsteuer eine vorweggenommene Einkommenssteuer ist, wird sie unterjährig im Voraus abgeführt. 
              Diese zu zahlenden Steuerabgaben <strong>sind vom Arbeitgeber einzubehalten</strong> und zusammen mit den von ihm zu entrichtenden pauschalen Lohnsteuern
              für begünstigte Bezüge <strong>beim zuständigen Finanzamt anzumelden und abzuführen.</strong>          
          </Typography>
          <Typography style={{marginTop:'15px'}}>
              Für jede lohnsteuerliche Betriebsstätte ist dies separat vorzunehmen. Das zuständige Finanzamt ist dann immer das jeweilige Betriebsstättenfinanzamt.
              Mehrere Lohnsteueranmeldungen für die gleiche Betriebsstätte dürfen in einem Lohnsteuer-Anmeldezeitraum nicht erfolgen.
              Die Anmeldung erfolgt dabei in Form einer Lohnsteueranmeldung. In welcher Regelmäßigkeit diese zu erstellen und zu übermitteln ist, hängt von der Höhe der jährlich abzuführenden Lohnsteuer ab.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            Monatlich: 
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            Wenn die abzuführende Lohnsteuer für das vergangene Kalenderjahr mehr als  
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={10} style={{marginTop:'-8px'}}>
          <Typography>
             5000,00 € (bis 2016: 4000,00 €) betragen hat.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            Vierteljährlich:
          </Typography> 
        </Grid>
        <Grid item xs={10}>
          <Typography>
            Wenn die abzuführende Lohnsteuer für das vergangene Kalenderjahr mehr als
          </Typography> 
        </Grid>
        <Grid item xs={2}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={10} style={{marginTop:'-8px'}}>
          <Typography>
             1080,00 € (bis 2014: 1000,00 €) betragen hat, aber höchstens 5000,00 €.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            Jährlich:
          </Typography> 
        </Grid>
        <Grid item xs={10}>
          <Typography>
            Wenn die abzuführende Lohnsteuer für das vergangene Kalenderjahr nicht mehr als
          </Typography> 
        </Grid>
        <Grid item xs={2}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={10} style={{marginTop:'-8px'}}>
          <Typography>
            1080,00 € (bis 2014: 1000,00 €) betragen hat.         
          </Typography>
        </Grid>
        <Grid item xs={12}>
         <Typography variant='h6'>
          Ausnahme
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
             Ein Arbeitgeber der nur Minijobber beschäftigt für die die einheitliche Pauschalsteuer von 2% an die Minijob-Zentrale abzuführen ist, kann sich von der Pflicht zur Abgabe einer Lohnsteueranmeldung befreien lassen.          
          </Typography>
        </Grid>
        <Grid item xs={12}>
         <Typography variant='h5'color='primary'>
          1.1. Anmeldefrist
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Die Lohnsteueranmeldung muss bis <strong> spätestens am 10. Tag </strong>nach Ablauf des Lohnsteuer- Anmeldezeitraums abgeben werden.
          </Typography>
        </Grid>
        <Grid item xs={12}>
         <Typography variant='h5'color='primary'>
          1.2. Zahlungsfrist
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
              Der angemeldete Betrag ist zu den gleichen Terminen, wie die Anmeldung erfolgen muss, abzuführen. Eine Bezahlung in Teilbeträgen ist nicht zulässig.
              Wenn die Zahlung durch Banküberweisung (nicht bei Barzahlung oder Scheck) erfolgt, wird eine Zahlungsschonfrist von bis zu 3 Tagen eingeräumt. Wird auch diese Frist nicht eingehalten, fallen Säumniszuschläge an.     
          </Typography>
        </Grid>
        <Grid item xs={12}>
         <Typography variant='h5'color='primary'>
          2. Aufbau
         </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Der Vordruck für die Lohnsteueranmeldung wird vom Finanzamt jedes Jahr neu veröffentlich. Dabei gibt es für jedes Bundesland individuelle Formulare.
          </Typography>
          <Typography style={{marginTop:'15px'}}>
            Darauf sind viele Pflichtangaben zu machen wie:
          </Typography>
        </Grid>
        
        <Grid item xs={1}>

        </Grid>
        <Grid item xs={10}>
              <Typography>
                - Steuernummer des Unternehmens <br/> - Zahl der beschäftigten Arbeitnehmer <br/> - Ob eine berichtigte Anmeldung vorliegt <br/> - Die einzelnen kumulierten Beträge (Lohnsteuer, Kirchensteuer, Solidaritätszuschlag etc.)
              </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant='h5'>
                    Fülle die Felder der Lohnsteueranmeldung für den Abrechnungsmonat September anhand der drei Abrechnungen aus."
                </Typography>
              </ListItemText>
            </ListItem>
        </Grid>

      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
        <Grid item xs={6} >
          <Typography variant='h5' style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid',borderTop:'solid'}}>
            Steuernummer
          </Typography>
          <Typography style={{display:"",borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}>
            2812045879213
          </Typography><br/>
        </Grid>
        <Grid item xs={5} >
        
        </Grid>
        <Grid item xs={1} >
          <Typography variant='h3' style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid',borderTop:'solid'}}>
            2022
          </Typography>
        </Grid>

        <Grid item xs={6} >
      
        </Grid>
        <Grid item xs={1} >
          <Typography  style={{ borderLeft:'solid',borderRight:'solid',borderTop:'solid', height:'40px'}}>
            30
          </Typography>
        </Grid>
        <Grid item xs={5} >
          <Typography  style={{textAlign:'left'}}>
            Eingangsstempel oder -datum
          </Typography>
        </Grid>
        <Grid item xs={6} >
          
        </Grid>
        <Grid item xs={4} >
          <Typography variant='h6' style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid',borderTop:'solid', textAlign:'left'}}>
            Lohnsteuer- Anmeldung 2022
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
        <Grid item xs={6} >
        </Grid>
        <Grid item xs={6} >
          <Typography  style={{textAlign:'left'}}>
            Anmeldezeitraum:
          </Typography>
        </Grid>
        <Grid item xs={6} >
          <Typography style={{textDecoration:'underline', textAlign:'left'}}>
          <strong> Finanzamt </strong>
          </Typography>
          
        </Grid>
        <Grid item xs={2} >
          <TextField 
            select
            id="standard-basic" 
            label="Anmeldezeitraum"
            error={fehlerliste["anmeldezeitraum"]}
            style={{display:"flex",borderBottom:'solid', borderLeft:'solid',borderRight:'solid',borderTop:'solid', width:'100%'}}
            onChange={(event) => setzeAntworten('anmeldezeitraum',event.target.value)}
            >
            <MenuItem value={1}>Januar</MenuItem>
            <MenuItem value={2}>Februar</MenuItem>
            <MenuItem value={3}>März</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>Mai</MenuItem>
            <MenuItem value={6}>Juni</MenuItem>
            <MenuItem value={7}>Juli</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>Oktober</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>Dezember</MenuItem>
          </TextField><br/>

        </Grid>
        <Grid item xs={4} >
        </Grid>      
        <Grid item xs={6} >
          <Typography style={{ textAlign:'left'}}>
            Finanzamt Waiblingen <br/> 
            Fronackerstr. 77
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign:'left'}}>
          <Typography>
            Md-Nr.: 00777,
          </Typography>
        </Grid>
        <Grid item xs={4} >
        </Grid>
        <Grid item xs={6} >
          <Typography style={{ textAlign:'left'}}>
          71332 Waiblingen
          </Typography>
        </Grid>

      </Grid>
      <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
        <Grid item xs={6} >
          <Typography style={{textDecoration:'underline', textAlign:'left'}}>
            <strong>Arbeitgeber- Anschrift der Betriebsstätte - Telefon- E-Mail </strong>
          </Typography>
          
        </Grid>
        <Grid item xs={2} >
        </Grid>
        <Grid item xs={4} >
        </Grid>      
        <Grid item xs={6} >
          <Typography style={{ textAlign:'left'}}>
            ectool (Lohn macht Spass) <br/> 
            Max-Eyth-Str.
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign:'left'}}>
          <Typography>
          <strong>Berichtigte Anmeldung</strong> <br/>
          <strong>Zahl der Arbeitnehmer</strong><br/>
          <strong>zu Zeile 22: Zahl der Arbeitnehmer mit BAV-Förderbetrag</strong>
          </Typography>
        </Grid>
        <Grid item xs={1} style={{textAlign:'right',float:'right'}} >
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid',width:'30%',height:'30px',textAlign:'center'}}>
            10
          </Typography>
          <Typography style={{borderTop:'solid', borderRight:'solid', borderLeft:'solid',width:'30%',height:'30px',textAlign:'center'}}>
            86
          </Typography>
          <Typography style={{borderTop:'solid', borderRight:'solid', borderBottom:'solid', borderLeft:'solid',width:'30%',height:'30px',textAlign:'center'}}>
            90
          </Typography>
        </Grid>

        <Grid item xs={1}  >
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid',width:'30%',height:'30px'}}>
            
          </Typography>
          <TextField  
              id="zahl Arbeitnehmer" 
              type='number'
              error={fehlerliste["zahlArbeitnehmer"]}
              inputProps={{style:{textAlign:"center"}}}    
              placeholder="Eingabe"  
              style={{display:"flex", borderTop:'solid', borderRight:'solid', borderLeft:'solid', backgroundColor:(fehlerliste["zahlArbeitnehmer"]===true)?"red":""}}
              onChange={(event) => setzeAntworten('zahlArbeitnehmer',event.target.value)}/>

          <Typography style={{borderTop:'solid', borderRight:'solid', borderBottom:'solid', borderLeft:'solid'}}>
            0
          </Typography>
        </Grid>

        <Grid item xs={6} >
          <Typography style={{ textAlign:'left'}}>
          71332 Waiblingen
          </Typography>
        </Grid>

      </Grid>

      <Grid container spacing={1}  style={{marginTop:'30px', textAlign:'left'}}  alignItems={"center"}>
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography >
            <small>1) Negativen Beträgen ist ein Minuszeichen voranzustellen   3) Kann auf volle Cent zugunsten des Arbeitgebers gerundet werden <br/>
            2) Nach Abzug der im Lohnsteuer-Jahresausgleich erstatteten Beträge </small>
          </Typography>
        </Grid>
        <Grid item xs={1} style={{height:'55px'}}>
          <Typography >
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderTop:'solid',borderRight:'solid',borderLeft:'solid',    height:'55px'}}>
          <Typography>
            <strong>Euro</strong>
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderTop:'solid', borderRight:'solid',height:'55px'}} >
          <Typography>
            <strong>Cent</strong>
          </Typography>
        </Grid>
      
        <Grid item xs={8} style={{borderTop:'solid',borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Summe der einzubehaltenden Lohnsteuer 1)2)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',borderTop:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            42
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
              <TextField  
              id="euro42" 
              type='number'
              error={fehlerliste["euro42"]}
              label="" 
              inputProps={{style:{textAlign:"right"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('euro42',event.target.value)}/>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
              <TextField  
              id="cent42" 
              type='number'
              error={fehlerliste["cent42"]}
              label="" 
              inputProps={{style:{textAlign:"left"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('cent42',event.target.value)}/>
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Summe der pauschalen Lohnsteuer -ohne §37b EStG- 1)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            41
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
          <Typography style={{marginTop:'10px'}}>
            0.
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
          <Typography style={{marginTop:'10px'}}>
            00
          </Typography>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
              Beiträge zur Krankenversicherung für geringfügig Beschäftigung
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            44
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
          <Typography style={{marginTop:'10px'}}>
            0.
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
          <Typography style={{marginTop:'10px'}}>
            00
          </Typography>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            abzügl. Kürzungsbetrag für Besatzungsmitgl. von Handelsschiffen
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            33
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
              
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            abzügl. BAV-Förderbetr. zur betriebl. Altersvers. n. §100EStG
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            45
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
          <Typography style={{marginTop:'10px'}}>
            0.
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
          <Typography style={{marginTop:'10px'}}>
            00
          </Typography>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
              Verbleiben 1)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            48
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px',textAlign:'right'}}>
          <Typography>
              {antworten['euro42']}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px',textAlign:'left'}} >
          <Typography>
              {antworten['cent42']}
          </Typography>     
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Solidaritätszuschlag 1)2)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            49
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
              <TextField  id="euro49" 
              type='number'
              error={fehlerliste["euro49"]}
              label="" 
              inputProps={{style:{textAlign:"right"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('euro49',event.target.value)}/>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
              <TextField  id="cent49" 
              type='number'
              error={fehlerliste["cent49"]}
              label="" 
              inputProps={{style:{textAlign:"left"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('cent49',event.target.value)}/>
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            pauschale Kirchensteuer im vereinfachten Verfahren
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            47
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
          <Typography style={{marginTop:'10px'}}>
            0.
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
          <Typography style={{marginTop:'10px'}}>
            00
          </Typography>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Evangelische Kirchensteuer 1)2)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            61
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
              <TextField  id="euro61" 
              type='number'
              error={fehlerliste["euro61"]}
              label="" 
              inputProps={{style:{textAlign:"right"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('euro61',event.target.value)}/>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
              <TextField  id="cent61" 
              type='number'
              error={fehlerliste["cent61"]}
              label="" 
              inputProps={{style:{textAlign:"left"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('cent61',event.target.value)}/>
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Römisch - Katholische Kirchensteuer - rk 1)2)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            62
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
              <TextField  id="euro62" 
              type='number'
              error={fehlerliste["euro62"]}
              label="" 
              inputProps={{style:{textAlign:"right"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('euro62',event.target.value)}/>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
              <TextField  id="cent62" 
              type='number'
              error={fehlerliste["cent62"]}
              label="" 
              inputProps={{style:{textAlign:"left"}}}    
              placeholder="Eingabe"  
              style={{display:"flex"}}
              onChange={(event) => setzeAntworten('cent62',event.target.value)}/>
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
        </Grid>      
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px', textAlign:'right'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
        </Grid>

        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
        </Grid>  

        <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
          <Typography>
            Gesamtbetrag 1)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px',textAlign:'center'}}>
          <Typography style={{marginTop:'10px'}}>
            83
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid',borderLeft:'solid',borderTop:'solid', height:'55px'}}>
              <TextField  id="euro83" 
              type='number'
              error={fehlerliste["euro83"]}
              label="" 
              inputProps={{style:{textAlign:"right"}}}    
              placeholder="Eingabe"  style={{display:"flex"}}
              onChange={(event) => setzeAntworten('euro83',event.target.value)}/>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',borderTop:'solid',height:'55px'}} >
              <TextField  id="cent83" 
              type='number'
              error={fehlerliste["cent83"]}
              label="" 
              inputProps={{style:{textAlign:"left"}}}    
              placeholder="Eingabe"  style={{display:"flex"}}
              onChange={(event) => setzeAntworten('cent83',event.target.value)}/>
        </Grid>      
        <Grid item xs={9}>

        </Grid>
        <Grid item xs={3} style={{borderTop:'solid'}}>

        </Grid>


      </Grid>
      <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
        <Grid item xs={12} style={{textAlign:'left'}}>
          <Typography>
            Ein Erstattungsbeitrag wird auf das dem Finanzamt benannte Konto überwiesen, soweit der Betrag <br/>
            nicht mit Steuerschulden verrechnet wird.
          </Typography>
        </Grid>
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            <strong>Verrechnung des Erstattungsbetrags erwünscht/ Erstattungsbetrag ist abgetreten.</strong> (falls ja, bitte eine "1" eintragen)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            29
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>

        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            Geben Sie bitte die Verrechnungswünsche auf einem besonderen Blatt oder auf dem beim Finanzamt <br/> 
            erhältlichen Vordruck "Verrechnungsantrag" an.
          </Typography>
        </Grid>
        <Grid item xs={4} style={{}}>
          
        </Grid>  
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            Das <strong>SEPA-Lastschriftmandat</strong> wird ausnahmsweise (z.B. wegen Verrechnungswünschen)<br/> 
            für diesen Anmeldungszeitraum widerrufen (falls ja, bitte eine "1 eintragen")<br/> 
            EIn ggf. verbleibender Restbetrag ist gesondert zu entrichten
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            26
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            Über die Angaben in der Steueranmeldung hinaus sind weitere oder abweichende Angaben <br/>
            oder Sachverhalte zu berücksichtigen (falls ja, bitte eine 1 eintragen)
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            23
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>

      </Grid>
      <Grid container spacing={0} style={{marginTop:'30px' }} >
        <Grid item xs={4}>
          
        </Grid>
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            <strong> Datenschutzhinweise:</strong><br/> 
            Die mit der Steueranmeldung angeforderten Daten werden auf Grund der §§ 149, 150 der
            Abgabenordnung und des § 41a des Einkommensteuergesetzes erhoben. Die Angabe der Telefonnummer und der E-Mail-Adresse ist freiwillig. <br/> 
            Informationen über die Verarbeitung personenbezogener Daten in der Steuerverwaltung und über Ihre Rechte <br/> nach der Datenschutz-Grundverordnung sowie über Ihre Ansprechpartner in Datenschutzfragen entnehmen <br/> 
          </Typography>
        </Grid>
        <Grid item xs={4} style={{}}>
            <TextField  disabled id="datum unterschrift" label="" placeholder="" fullWidth style={{display:"flex",width:"50%"}}
//            onChange={(event) => setzeAntworten('datum unterschrift',event.target.value)}
            />
        </Grid>
        <Grid item xs={8} style={{textAlign:'left'}}>
          <Typography>
            Sie bitte dem allgemeinen Informationsschreiben der Finanzverwaltung. Dieses Informationsschreiben finden 
            Sie unter www.finanzamt.de (unter der Rubrik "Datenschutz") oder erhalten Sie bei Ihrem Finanzamt.
          </Typography>
        </Grid>
        <Grid item xs={4} style={{textAlign:'left'}}>
          <Typography>
            Datum, Unterschrift
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{marginTop:'30px' }} >
        <Grid item xs={5} style={{ textAlign:'left' ,borderTop:'solid'}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{  }}>
          <Typography>
            Vom Finanzamt auszufüllen
          </Typography>
        </Grid>
        <Grid item xs={5} style={{ textAlign:'left' ,borderTop:'solid'}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign:'left'}}>
          <Typography>
            <strong>Bearbeitungshinweis</strong>
          </Typography>
        </Grid>
      </Grid>
        <Grid container spacing={0} style={{marginTop:'30px',   alignItems:"center"  }} >
        <Grid item xs={7} style={{textAlign:'left'}}>
          <Typography>
            1. Die aufgeführten Daten sind mit Hilfe des geprüften und geneh- <br/> 
              migten Programms sowie ggf. unter Berücksichtigung der ge- <br/> 
              speicherten Daten maschinell zu verarbeiten. 
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            11
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            19
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>

        <Grid item xs={7} style={{textAlign:'left'}}>
          <Typography>
            2. Die weitere Bearbeitung richtet sich nach den Ergebnissen der maschinellen Verarbeitung.
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right',height:'30px'}}>
            
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'right'}}>
            12
          </Typography>
        </Grid>      
        <Grid item xs={1} style={{}}>
          <Typography style={{borderTop:'solid', borderRight:'solid',  borderLeft:'solid', borderBottom:'solid',width:'30%', float:'left',height:'30px'}}>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>
        <Grid item xs={1} style={{}}>
          <Typography style={{}}>
            
          </Typography>
        </Grid>
        <Grid item xs={5} style={{}}>
            <TextField  
              disabled
              id="datum namenszeichen" 
              error={fehlerliste["datum namenszeichen"]} 
              inputProps={{style:{textAlign:"center"}}} 
              placeholder="" 
              fullWidth 
              style={{display:"flex",width:"80%"}}
//              onChange={(event) => setzeAntworten('datum namenszeichen',event.target.value)}
              />
        </Grid>
        <Grid item xs={6} style={{borderLeft:'solid',borderTop:'solid'}}>
          <Typography>
            
          </Typography>
        </Grid>
        <Grid item xs={6} style={{borderBottom:'solid'}}>
          <Typography>
            Datum, Namenszeichen/ Unterschrift
          </Typography>
        </Grid>      
        <Grid item xs={6} style={{borderLeft:'solid'}}>
          <Typography>
            <strong> Kontrollzahl und / oder Datenerfassungsvermerk</strong>
          </Typography>
        </Grid>


      </Grid>
      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
     </div>
    )
  }   


export default ScheckLStAnm;
