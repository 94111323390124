// theme.js
import { responsiveFontSizes } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

// colors
const primary = "#009988";
const secondary = "#33445c";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const backgroundLight = "#ffffff";//"#f5f5f5";
const backgroundDark = "#eeeeee";
const backgroundDim="#ededed"
const backgroundDim2="#cccccc"
const highlight="#1fbba644"
const lightred="#ffe6e6"
const red="#ffdcdc"
const lightgreen="#e6ffe6"
const green="#dcffdc"
const lightblue="#e6e6ff"
const blue="#dcdcff"
const lightyellow="#ffffe6"
const yellow="#ffffdc"
const lightpurple="e6c4ff"
const purple="#dcbaff"
const lightorange="ffe6c4"
const orange="#ffdcba"
const back="#eeeeee"
const hyellow="#ffffdc"

//const warningText = "rgb(255, 127, 39)"
//const warningLight = "rgba(253, 200, 69, .3)";
//const warningMain = "rgba(253, 200, 69, .5)";
//const warningDark = "rgba(253, 200, 69, .7)";
 
//const errorMain = "rgb(248, 100, 73)";

// border
const borderWidth = 1;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const lighttheme = createTheme({
  palette: {
    type: 'light',
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack
    },
    background: {
      paper: backgroundLight,
      light: backgroundDark,
      default: backgroundLight,
      dark: backgroundLight,
      dim:backgroundDim,
      dim2:backgroundDim2,
      highlight:highlight,
    },
    blocks: {
      lightred: lightred,
      red: red,
      lightgreen: lightgreen,
      green: green,
      lightblue: lightblue,
      blue: blue,
      lightyellow: lightyellow,
      yellow: yellow,
      lightpurple: lightpurple,
      purple: purple,
      lightorange: lightorange,
      orange: orange,
      back:back,
      hyellow: hyellow,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    spacing
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs
    }
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static"
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: backgroundDark,
      }
    },


    MuiDivider: {
      light: {
        backgroundColor: borderColor,
        height: borderWidth
      },
      root: {
        backgroundColor: borderColor,
        height: borderWidth
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});

export default responsiveFontSizes(lighttheme);
