import React, {useState, useCallback} from 'react';
import {Avatar, ListItem, MenuItem, ListItemAvatar, ListItemText, Typography, Grid} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { TabelleLohnarten} from './tabelleL';
import { Player, ControlBar } from 'video-react';
const lohnarten = '/media/Chat-Tutorial_Lohnarten.M4V';
const POSTER = '/media/Grafik_Filmrolle.png';
import { useMessage } from '../../context/message-context';
import TextField from '@material-ui/core/TextField';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';



function Lohnarten(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('004');
  const [merkmal]=useState("Lohnarten");

 
  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
    if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        fehlerAusLesen(response.data.fehler);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }


  return(
    <div>
      <Briefkopf  
          ueberschrift1="Lohnarten" 
          ueberschrift2="Wann verwendest Du welche Lohnarten? ^Das Chat-Tutorial erklärt Dir zusammenfassend, auf was Du bei der Auswahl der richtigen Lohnart achten solltest." 
      />
    
    <Grid container spacing={0} style={{marginTop:'30px', justify:"center",  alignItems:"center"  }} >
      <Grid item xs={12} style={{}}>
            <Player
              poster={POSTER}
                src={lohnarten}
            >
            <ControlBar autoHide={false} />
            </Player>
      </Grid>
    </Grid>



    <Grid container spacing={0} style={{marginTop:'30px' }} justifyContent="flex-start" alignItems="left">
      <Typography style={{textAlign:"left"}}>
            Die Lohnarten-Tabelle zeigt Dir 3 verschiedene Lohnarten an. <br/>
            Mit dem "X" in der jeweiligen Spalte erkennst Du die Eigenschaften der Lohnart. <br/>
            So hat zum Beispiel die Lohnart-Nr. 10 die Eigenschaften steuer- und sozialversicherungspflichtig 
            und den Charakter "laufendes Entgelt".<br/>
      </Typography>

    </Grid>
    <Grid container spacing={0} style={{marginTop:'0px', position: 'sticky', top:60, left:0, zIndex:10}} justifyContent="flex-start" alignItems="center">
      <Grid item xs={12} style={{border:"solid",backgroundColor:"#1fbba6"}}>
          <Typography>Lohnarten-Tabelle</Typography>
      </Grid>
      <Grid item xs={1} style={{borderLeft:"solid", borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Lohnart<br />
          NR
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Steuerpf- <br />
          pflichtig 
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Steuer- <br />
          frei 
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Sozialvers.- <br />
          pflichtig 
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Sozialvers.- <br />
          frei 
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Entgeltart <br />
          EGA
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#1fbba6"}}>
        <Typography>
          Entgeltart <br />
          Laufend 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          10
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
           X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
         --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          20
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
           X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
         X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          30
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
           --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
         --
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:'white'}}>
        <Typography>
          X 
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                H
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Hinweis:</h4>
                  Recherchiere im Internet im §3 ESTG nach den möglichen steuerfreien Vergütungen. <br />
                  Du wirst die Recherche bei der ein oder anderen Aufgabe brauchen. <br />
              </ListItemText>  
          </ListItem>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>ToDo:</h4>
                  Bestimme für jeden dargestellten Fall die passende Lohnart unter Berücksichtigung aller Kriterien. <br />
                  Zudem übernehme die richtige Bezeichnung und übertrage die Beträge in die Betragsspalten. 
              </ListItemText>  
          </ListItem>
    </Grid>


    <Grid container spacing={3} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Beispiel:</h4>
                  Der Arbeitnehmer soll ein monatliches Gehalt von 2300,- Euro bekommen. <br />
                  Die Bezeichnung der Lohnart soll "Gehalt" sein. <br />
            </ListItemText>  
            <ListItemText>
                  <h4>Lösung:</h4>
                  Das monatliche Gehalt ist steuer- und beitragspflichtiges Entgelt und wird als "monatliches" Gehalt laufend vergütet. <br />
                  Somit ist für dieses Beispiel die Lohnart Nr. 10 einzutragen. <br />
            </ListItemText>  

          </ListItem>
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} style={{border:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>Erfassung Lohnarten</Typography>
      </Grid>
      <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>

      <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
        <TextField
          select // durch select wird DropDown initialisiert
          id="Lohnart"
          label="Lohnart auswählen "
          disabled={true}
          fullWidth
          value={10}
          onChange={(event) => setzeAntworten('Lohnart1',event.target.value)}
          variant="outlined"
 //         onChange={(event) => setLiga(event.target.value)} 
          margin="normal"
 //         error={ErrorLiga !==""}
 //         helperText={ErrorLiga}
          InputLabelProps={{
              shrink: true,
          }}       
          >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </TextField> 
      </Grid>

      <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
        <TextField
          select // durch select wird DropDown initialisiert
          id="texteingabe"
          label="Bezeichnung auswählen "
          disabled={true}
          fullWidth
          value={'Gehalt'}
          onChange={(event) => setzeAntworten('text1',event.target.value)}
          variant="outlined"
 //         onChange={(event) => setLiga(event.target.value)} 
          margin="normal"
 //         error={ErrorLiga !==""}
 //         helperText={ErrorLiga}
          InputLabelProps={{
              shrink: true,
          }}       
          >
        <MenuItem value={'Gehalt'}>Gehalt</MenuItem>
        <MenuItem value={'Kontrollzulage'}>Kontrollzulage</MenuItem>
        <MenuItem value={'Prämie'}>Prämie</MenuItem>
        <MenuItem value={'Provision'}>Provision</MenuItem>
        <MenuItem value={'Q-Provision'}>Q-Provision</MenuItem>
        <MenuItem value={'Nachtarbeit'}>Nachtarbeit</MenuItem>
        <MenuItem value={'Nachtzuschlag'}>Nachtzuschlag</MenuItem>
        <MenuItem value={'Urlaubsgeld'}>Urlaubsgeld</MenuItem>
        <MenuItem value={'Überstunden'}>Überstunden</MenuItem>
        <MenuItem value={'Sonntagsarbeit'}>Sonntagsarbeit</MenuItem>
        <MenuItem value={'Sonntagszuschlag'}>Sonntagszuschlag</MenuItem>
        <MenuItem value={'VWL-Zulage'}>VWL-Zulage</MenuItem>
        <MenuItem value={'Zulage'}>Zulage</MenuItem>
        </TextField> 
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
        <TextField
//          type="number"
          id="Steuerpflichtig"
          label="Steuerpflichtigen Betrag eingeben"
          disabled={true}
          fullWidth
          value='2300,00'
          variant="outlined"
          onChange={(event) => setzeAntworten('stpfl1',event.target.value)}
          margin="normal"
  //        error={ErrorNumFeld !==""}
  //        helperText={ErrorNumFeld}
          InputLabelProps={{
          shrink: true,
          }}       
          inputProps={{style:{textAlign:"center"}}}
        >
        </TextField>             
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
      <TextField
//          type="number"
          id="Sozialversicherungspflichitig"
          label="Sozialversicherungspflichtigen Betrag eingeben"
          disabled={true}
          fullWidth
          value='2300,00'
          variant="outlined"
          onChange={(event) => setzeAntworten('svpfl1',event.target.value)} 
          margin="normal"
  //        error={ErrorNumFeld !==""}
  //        helperText={ErrorNumFeld}
          InputLabelProps={{
          shrink: true,
          }}
          inputProps={{style:{textAlign:"center"}}}
       
        >
        </TextField>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
      <TextField
//          type="number"
          id="Betrag"
          label="Betrag eingeben"
          disabled={true}
          fullWidth
          value='2300,00'
          variant="outlined"
  //        onChange={(event) => setzeAntworten('betr1',event.target.value)}
          margin="normal"
  //        error={ErrorNumFeld !==""}
  //        helperText={ErrorNumFeld}
          InputLabelProps={{
          shrink: true,
          }}       
          inputProps={{style:{textAlign:"center"}}}
        >
        </TextField>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der Arbeitnehmer soll ein monatliches Gehalt von 2400,- Euro vergütet bekommen.<br/>
                  Die Bezeichnung der Lohnart soll "Gehalt" sein.
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste} />

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                3
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der Arbeitnehmer soll eine Quartalsprämie in Höhe von <br/>
                  1300,- Euro vergütet bekommen.<br/>
                  Die Bezeichnung der Lohnart soll "Q-Provision" sein.
              </ListItemText>  
          </ListItem>
    </Grid>
    <TabelleLohnarten tabellenKey="3" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>




    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                4
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der Arbeitnehmer soll für die in einem Monat von 20:00 Uhr bis 2:00 Uhr <br/>
                  gearbeiteten Stunden, einen Beitrag von 500,- Euro bekommen.<br/>
                  Die Bezeichnung der Lohnart soll "Nachtarbeit" sein.
              </ListItemText>  
          </ListItem>
    </Grid>
    <TabelleLohnarten tabellenKey="4" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                5
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll für die in einem Monat von 20:00 Uhr bis 2:00 Uhr gearbeiteten Stunden <br/>
              einen Nachtarbeitszuschlag in Höhe von 25% = 125,- Euro bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Nachtzuschlag" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="5" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                6
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll das jährliche Urlaubsgeld in Höhe von 700 Euro vergütet bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Urlaubsgeld" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="6" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                7
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll die in dem aktuellen Monat geleisteten Überstunden in <br />
              Höhe von 560,- Euro vergütet bekommen. <br /> 
              Die Bezeichnung der Lohnart soll "Überstunden" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="7" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                8
              </Avatar>
            </ListItemAvatar>
             <ListItemText>
              Der Arbeitnehmer soll die in dem Monat erbrachte Kontrollzulage in Höhe von 300,- Euro erhalten. <br/> 
              Die Bezeichnung der Lohnart soll "Kontrollzulage" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="8" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                9
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
               Der Arbeitnehmer soll die monatliche VWL-Zulage in Höhe von 78,- Euro erhalten.<br/> 
               Die Bezeichnung der Lohnart soll "VWL-Zulage" sein. <br />
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="9" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                10
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
               Der Arbeitnehmer soll die in den letzten 6 Monaten erbrachten Überstunden<br/>
               in Höhe von 2000,- Euro vergütet bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Überstunden" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="10" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                11
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll für die an Sonntagen in dem Monat geleisteten Arbeitsstunden<br/>
              einen Betrag in Höhe von 600,- Euro vergütet bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Sonntagsarbeit" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="11" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                12
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll für die, an Sonntagen in dem Monat, geleisteten <br/> 
              Arbeitsstunden einen Zuschlag von 50% = 300,- Euro vergütet bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Sonntagszuschlag" sein. 
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="12" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                13
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
              Der Arbeitnehmer soll die monatliche Provision in Höhe von 800,- Euro vergütet bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Provision" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="13" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                14
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
            Für hervorragende Leistungen in den letzten 4 Monaten soll der <br />
            Arbeitnehmer eine Prämie in Höhe von 500,- Euro bekommen.<br/> 
              Die Bezeichnung der Lohnart soll "Prämie" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="14" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                15
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
               Der Arbeitnehmer bekommt die monatliche Zulage von 100,- Euro vergütet.<br/> 
               Die Bezeichnung der Lohnart soll "Zulage" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="15" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                16
              </Avatar>
          </ListItemAvatar>
            <ListItemText>
               Der Arbeitnehmer soll monatlich einen pauschalen Nachtarbeitszuschlag von 700,- Euro.<br/> 
               Die Bezeichnung der Lohnart soll "Nachzuschlag" sein.
            </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleLohnarten tabellenKey="16" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </div>
    )
  }   


export default Lohnarten;
