import React from 'react';
import ReactDom from 'react-dom';
import App from './App';
import AppProviders from './context';
import {LOCAL_STORAGE_KEY} from './constants/localStorage'

const renderReactDom = () => {
  ReactDom.render(
    <AppProviders>
      <App />
    </AppProviders>,
    document.getElementById('root')
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    localStorage.setItem(LOCAL_STORAGE_KEY.accessToken, '');
    localStorage.setItem(LOCAL_STORAGE_KEY.client, '');
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}
