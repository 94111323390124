import React, {useState, useCallback } from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText, Grid} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
const VIDEO = '/media/Chat_Tutorial_Zufluss_Entstehungsprinzip.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import { Player, ControlBar } from 'video-react';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';



function ZuflussEntstehungsprinzip(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('030');
  const [merkmal]=useState("Zuflussprinzip");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Das Zufluss- und Entstehungsprinzip" 
          ueberschrift2="Woran unterscheiden sich die zwei Prinzipien und ^welche Auswirkungen ergeben sich dadurch für die Entgeltabrechnung?" 
      />

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <Typography>
                  Dieses Video eines Chat-Verlaufes hilft dir die zwei unterschiedlichen Prinzipien zu verstehen.
              </Typography>
            </ListItemText> 
          </ListItem>
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Aufgabe 1"/>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                  Wir befinden uns im Abrechnungsmonat Februar. <br/> 
                  Gem. Arbeitsvertrag soll eine Arbeitnehmerin ab Dezember vergangenen Jahres eine Gehaltserhöhung um 100,- <br/>  
                  Euro von 2350,- auf 2450,- Euro bekommen. <br/> Das wurde versehentlich nicht berücksichtigt.
              </Typography>
            </ListItemText> 
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                Korrigiere die Abrechnungen
              </Typography>
            </ListItemText> 
          </ListItem>
          
          <BruttoNeu2
              anzReihen={2}
              tabellenKey="1" 
              text='Abrechnungsmonat Dezember'
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Nachzahlung"]}
              Tage={["21.75","---"]}
              Stunden={["174","---"]}
              Faktor={["2350.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2350.00",""]}
              Sozialverspflichtig={["2350.00",""]}
              Betrag={["2350.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
          
          <BruttoNeu2
              anzReihen={2}
              tabellenKey="2" 
              text='Abrechnungsmonat Januar'
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Nachzahlung"]}
              Tage={["21.75","---"]}
              Stunden={["174","---"]}
              Faktor={["2350.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2350.00",""]}
              Sozialverspflichtig={["2350.00",""]}
              Betrag={["2350.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
        
          <BruttoNeu2
              anzReihen={2}
              tabellenKey="3" 
              text='Abrechnungsmonat Februar'
              Lohnart={['1029','3222']}
              Intern={['029','222']}
              Bezeichnung={["Gehalt","Nachzahlung"]}
              Tage={["21.75","---"]}
              Stunden={["174","---"]}
              Faktor={["2450.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2450.00",""]}
              Sozialverspflichtig={["2450.00",""]}
              Betrag={["2450.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

        </Grid>
      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

</div>
)
}


export default ZuflussEntstehungsprinzip;