import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import { TextField, Card, CardMedia, CardContent, Button, Box, Typography, Grid } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {VERSION} from '../../constants/version';
const headerv1 = '/media/ecschool_header1.png';
import {
  isBrowser,
  isMobile,
  osName,
  osVersion,
  browserVersion,
  browserName,
  mobileModel,
  mobileVendor
} from "react-device-detect";
import {useAuth} from '../../context/auth-context';
import {useAsync, useLocalStorage} from '../../utils/hooks';
import { useNotification } from '../../context/notification-context';
import { LOCAL_STORAGE_KEY } from '../../constants/localStorage';

function LoginPassword() {
  const [password , setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showAdmincode, setShowAdmincode] = useState(false);
  const [username, setUsername] = useLocalStorage(LOCAL_STORAGE_KEY.username);
  const [deviceId, ] = useLocalStorage(LOCAL_STORAGE_KEY.deviceId);
  const {login, loginAdmincode} = useAuth();
  const {isLoading, isError, error, run} = useAsync();
  const notificationToken = useNotification();
  const [admincodeError, setAdmincodeError] = useState('');
  const [admincode, setAdmincode] = useState('');
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [preLoginUser, setPreLoginUser] = useState(null);

  useEffect(() => {
    setUserIsLoading(true);
    const payload = {
      username: username,
      deviceId: deviceId,
    }

    axios.post(API_BASE_URL+'login/user', payload)
    .then(function (response) {
      setUserIsLoading(false);
      setPreLoginUser(response.data.user);
    })
    .catch(function (err) {
      setUserIsLoading(false);
      console.log(err);
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data.message);
      } else {
        console.log(err);
      }
    });

  }, [username, deviceId]);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowAdmincode = () => {
    setShowAdmincode(!showAdmincode);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAdmincodeSubmitClick = (e) => {
    e.preventDefault();
    if (isSending) return;
    setIsSending(true);
    var payload={}
    if (isMobile) {
      payload={
        "username": username,
        "admincode": admincode,
        "deviceId": deviceId,
        "notifToken": notificationToken,
        "device": mobileVendor+' '+mobileModel,
        "os": osName,
        "osBez": mobileVendor+' '+mobileModel+' '+osName+' '+osVersion,
        "clientVersion": VERSION
      }
    }
    if (isBrowser) {
      payload={
        "username": username,
        "admincode": admincode,
        "deviceId": deviceId,
        "notifToken": notificationToken,
        "browser": browserName+' '+browserVersion,
        "os": osName,
        "osBez": osName+' '+osVersion+' '+browserName+' '+browserVersion,
        "clientVersion": VERSION
      }
    }
    loginAdmincode(payload).then((data) => {
      setIsSending(false);
      if (data) {
      }
    }).catch((error) => {
      setIsSending(false);
      setAdmincodeError(error.message);
    });
  }

  const updateAdmincode = (e) => {
    e.preventDefault();
    setAdmincode(e.target.value);
  }

    
  const handlePasswordSubmitClick = (e) => {
    e.preventDefault();
    if (isLoading) return;
    var payload={}
    if (isMobile) {
      payload={
        "username": username,
        "password": password,
        "deviceId": deviceId,
        "notifToken": notificationToken,
        "device": mobileVendor+' '+mobileModel,
        "os": osName,
        "osBez": mobileVendor+' '+mobileModel+' '+osName+' '+osVersion,
        "clientVersion": VERSION
      }
    }
    if (isBrowser) {
      payload={
        "username": username,
        "password": password,
        "deviceId": deviceId,
        "notifToken": notificationToken,
        "browser": browserName+' '+browserVersion,
        "os": osName,
        "osBez": osName+' '+osVersion+' '+browserName+' '+browserVersion,
        "clientVersion": VERSION
      }
    }
    run(login(payload));
  }

  return(
    <Box p={2}>
      <Grid container >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={8} md={6}>
              <Card >
                <CardMedia
                style={{paddingTop:'60.25%'}} 
                image={headerv1}
                title="ecakademie Titelbild"
                />
                <CardContent>
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                      <Typography variant="h5">Hallo {username}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Geben Sie Ihr Passwort ein um sich anzumelden.</Typography>
                    </Grid>
                  </Grid>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField 
                          type={showPassword ? "text":"password"}
                          fullWidth
                          error={isError}
                          helperText={error && error.message}
                          label="Passwort"
                          variant="outlined"
                          id="password"
                          placeholder="Passwort eingeben ..."
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: 
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                          }}
                        />
                      </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handlePasswordSubmitClick}
                            fullWidth
                          >Anmelden</Button>
                        </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <Box p={2}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box p={1}>
                      <Typography variant="body1">Sie sind nicht {username}?</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      color="primary"
                      fullWidth
                      onClick={() => setUsername('')}
                    >Benutzer wechseln</Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoginPassword;
