import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function Tab_BBG_2Jahre (props) {
  const {jahr1, bbgkvjahr1, bbgkvmonat1, bbgrvjahr1, bbgrvmonat1, jahr2, bbgkvjahr2, bbgkvmonat2, bbgrvjahr2, bbgrvmonat2} = props;





return(
    <div>

    <Grid container spacing={0} style={{border:'solid', marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
           <TableContainer> 
               <Typography style={{textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE'}}>
                    Tabelle der Beitragsbemessungsgrenzen
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Jahr</TableCell>
                            <TableCell style={{textAlign:'center'}}>Gruppe</TableCell>
                            <TableCell style={{textAlign:'center'}}>Jahres-BBG </TableCell>
                            <TableCell style={{textAlign:'center'}}>Monats-BBG</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow style={{borderTop:"solid"}}>
                            <TableCell style={{textAlign:'center'}} >
                                {jahr1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}} >
                                Kranken-/Pflegeversicherung
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgkvjahr1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgkvmonat1}
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {jahr1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}} >
                                Renten-/Arbeitslosenversicherung (West)
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgrvjahr1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgrvmonat1}
                            </TableCell>
                        </TableRow>

                        <TableRow style={{borderTop:"solid"}}>
                            <TableCell style={{textAlign:'center'}} >
                                {jahr2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}} >
                                Kranken-/Pflegeversicherung
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgkvjahr2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgkvmonat2}
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {jahr2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}} >
                                Renten-/Arbeitslosenversicherung (West)
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgrvjahr2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {bbgrvmonat2}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </div>
  )
}

export {Tab_BBG_2Jahre};