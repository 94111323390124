import React from 'react';
import {Typography, TextField, MenuItem } from '@material-ui/core';

const Eingabe = (props) => {
    if (props.disabled) {
        return(
        <Typography 
 //           style={{textAlign:"center"}} 
        >
            {props.value}
        </Typography>)
    }
    if (props.datum) {
        return(
            <TextField 
                type="date"
                disabled={props.disabled}
                placeholder={props.placeholder}
                error={props.error}   
                style={props.style}
                inputProps={props.inputProps}
                value={props.value}
                onChange={(event) => props.onChange(event)}
             >
         </TextField>
        )
    }

    if (props.text) {
        return(
            <TextField 
                disabled={props.disabled}
                placeholder={props.placeholder}
                error={props.error}   
                style={props.style}
                inputProps={props.inputProps}
                value={props.value}
                onChange={(event) => props.onChange(event)}
                >
            </TextField>
        )
    }


    if (props.dropd) {
        return(
            <TextField 
                select
                disabled={props.disabled}
                placeholder={props.placeholder}
                error={props.error}   
                style={props.style}
                inputProps={props.inputProps}
                value={props.value}
                onChange={(event) => props.onChange(event)}
                >
                {props.kenndrops.map((option) => (
                   <MenuItem key={option} value={option}>
                       {option}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

  return (
   <TextField 
      type="number"
      disabled={props.disabled}
      placeholder={props.placeholder}
      error={props.error}   
      style={props.style}
      inputProps={props.inputProps}
      value={props.value}
      onChange={(event) => props.onChange(event)}
   >
   </TextField>
  )
} 
export {Eingabe};