import React from 'react';
import {Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function SVBlockneu (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,kvlfdtage, kvlfdPflichtig,kvlfdAGanteil,kvlfdANanteil, kvegatage, kvegaPflichtig, kvegaANanteil, kvegaAGanteil,rvlfdtage, rvlfdPflichtig,rvlfdAGanteil,rvlfdANanteil, rvegatage, rvegaPflichtig, rvegaANanteil, rvegaAGanteil,avlfdtage, avlfdPflichtig,avlfdAGanteil,avlfdANanteil, avegatage, avegaPflichtig, avegaANanteil, avegaAGanteil,pvlfdtage, pvlfdPflichtig,pvlfdAGanteil,pvlfdANanteil, pvegatage, pvegaPflichtig, pvegaANanteil, pvegaAGanteil} = props;

  var svFarbe="#71ff00";


  if (kvlfdtage !== '') {
      antworten["KVlfdTag"+tabellenKey]=kvlfdtage;
      var KVlfdTagblockiert=true;

  }
  if (kvlfdPflichtig !== '') {
    antworten["KVlfdpfl"+tabellenKey]=kvlfdPflichtig;
    var KVlfdpflblockiert=true;
  }
  if (kvlfdANanteil !== '') {
    antworten["KVlfdAN"+tabellenKey]=kvlfdANanteil;
    var KVlfdANblockiert=true;
  }
  if (kvlfdAGanteil !== '') {
    antworten["KVlfdAG"+tabellenKey]=kvlfdAGanteil;
    var KVlfdAGblockiert=true;
  }
  if (kvegatage !== '') {
    antworten["KVegaTag"+tabellenKey]=kvegatage;
    var KVegaTagblockiert=true;
    }
    if (kvegaPflichtig !== '') {
    antworten["KVegapfl"+tabellenKey]=kvegaPflichtig;
    var KVegapflblockiert=true;
    }
    if (kvegaANanteil !== '') {
    antworten["KVegaAN"+tabellenKey]=kvegaANanteil;
    var KVegaANblockiert=true;
    }
    if (kvegaAGanteil !== '') {
    antworten["KVegaAG"+tabellenKey]=kvegaAGanteil;
    var KVegaAGblockiert=true;
    }

    if (rvlfdtage !== '') {
        antworten["RVlfdTag"+tabellenKey]=rvlfdtage;
        var RVlfdTagblockiert=true;
    }
    if (rvlfdPflichtig !== '') {
      antworten["RVlfdpfl"+tabellenKey]=rvlfdPflichtig;
      var RVlfdpflblockiert=true;
    }
    if (rvlfdANanteil !== '') {
      antworten["RVlfdAN"+tabellenKey]=rvlfdANanteil;
      var RVlfdANblockiert=true;
    }
    if (rvlfdAGanteil !== '') {
      antworten["RVlfdAG"+tabellenKey]=rvlfdAGanteil;
      var RVlfdAGblockiert=true;
    }
    if (rvegatage !== '') {
      antworten["RVegaTag"+tabellenKey]=rvegatage;
      var RVegaTagblockiert=true;
      }
      if (rvegaPflichtig !== '') {
      antworten["RVegapfl"+tabellenKey]=rvegaPflichtig;
      var RVegapflblockiert=true;
      }
      if (rvegaANanteil !== '') {
      antworten["RVegaAN"+tabellenKey]=rvegaANanteil;
      var RVegaANblockiert=true;
      }
      if (rvegaAGanteil !== '') {
      antworten["RVegaAG"+tabellenKey]=rvegaAGanteil;
      var RVegaAGblockiert=true;
      }
      if (avlfdtage !== '') {
        antworten["AVlfdTag"+tabellenKey]=avlfdtage;
        var AVlfdTagblockiert=true;
    }
    if (avlfdPflichtig !== '') {
      antworten["AVlfdpfl"+tabellenKey]=avlfdPflichtig;
      var AVlfdpflblockiert=true;
    }
    if (avlfdANanteil !== '') {
      antworten["AVlfdAN"+tabellenKey]=avlfdANanteil;
      var AVlfdANblockiert=true;
    }
    if (avlfdAGanteil !== '') {
      antworten["AVlfdAG"+tabellenKey]=avlfdAGanteil;
      var AVlfdAGblockiert=true;
    }
    if (avegatage !== '') {
      antworten["AVegaTag"+tabellenKey]=avegatage;
      var AVegaTagblockiert=true;
      }
      if (avegaPflichtig !== '') {
      antworten["AVegapfl"+tabellenKey]=avegaPflichtig;
      var AVegapflblockiert=true;
      }
      if (avegaANanteil !== '') {
      antworten["AVegaAN"+tabellenKey]=avegaANanteil;
      var AVegaANblockiert=true;
      }
      if (avegaAGanteil !== '') {
      antworten["AVegaAG"+tabellenKey]=avegaAGanteil;
      var AVegaAGblockiert=true;
      }
      if (pvlfdtage !== '') {
        antworten["PVlfdTag"+tabellenKey]=pvlfdtage;
        var PVlfdTagblockiert=true;
    }
    if (pvlfdPflichtig !== '') {
      antworten["PVlfdpfl"+tabellenKey]=pvlfdPflichtig;
      var PVlfdpflblockiert=true;
    }
    if (pvlfdANanteil !== '') {
      antworten["PVlfdAN"+tabellenKey]=pvlfdANanteil;
      var PVlfdANblockiert=true;
    }
    if (pvlfdAGanteil !== '') {
      antworten["PVlfdAG"+tabellenKey]=pvlfdAGanteil;
      var PVlfdAGblockiert=true;
    }
    if (pvegatage !== '') {
      antworten["PVegaTag"+tabellenKey]=pvegatage;
      var PVegaTagblockiert=true;
      }
      if (pvegaPflichtig !== '') {
      antworten["PVegapfl"+tabellenKey]=pvegaPflichtig;
      var PVegapflblockiert=true;
      }
      if (pvegaANanteil !== '') {
      antworten["PVegaAN"+tabellenKey]=pvegaANanteil;
      var PVegaANblockiert=true;
      }
      if (pvegaAGanteil !== '') {
      antworten["PVegaAG"+tabellenKey]=pvegaAGanteil;
      var PVegaAGblockiert=true;
      }




return(
    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
        <Grid item xs={8}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}}>Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}}>Tage</TableCell>
                            <TableCell style={{textAlign:"right", backgroundColor:'#71ff00'}}>Pflichtig</TableCell>
                            <TableCell style={{textAlign:"right", backgroundColor:'#71ff00'}}>AN-Anteil</TableCell>
                            <TableCell style={{textAlign:"right", backgroundColor:'#71ff00'}}>AG-Anteil</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}} >
                                KV LFD
                            </TableCell>
                            <TableCell key="kvlfdtage" style={{textAlign:"center"}} > 
                                <Eingabe 
                                    disabled={KVlfdTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVlfdTag"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVlfdTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["KVlfdTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVlfdTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvlfdPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={KVlfdpflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVlfdpfl"+tabellenKey]}     
                                    style={{textAlign:"right", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["KVlfdpfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVlfdpfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVlfdpfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvlfdANanteil" style={{textAlign:"right"}}  >
                                <Eingabe 
                                    disabled={KVlfdANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVlfdAN"+tabellenKey]}     
                                    style={{textAlign:"right",display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVlfdAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVlfdAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVlfdAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvlfdAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={KVlfdAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVlfdAG"+tabellenKey]}     
                                    style={{textAlign:"right", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVlfdAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVlfdAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVlfdAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell  style={{textAlign:"center", backgroundColor:'#71ff00'}}>
                                KV EGA
                            </TableCell>
                            <TableCell key="kvegatage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={KVegaTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVegaTag"+tabellenKey]}     
                                    style={{textAlign:"center", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVegaTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["KVegaTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVegaTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvegaPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={KVegapflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVegapfl"+tabellenKey]}     
                                    style={{textAlign:"right", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVegapfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVegapfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVegapfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvegaANanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={KVegaANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVegaAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVegaAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVegaAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVegaAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="kvegaAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={KVegaAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["KVegaAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["KVegaAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["KVegaAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('KVegaAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}} >
                                RV LFD 
                            </TableCell>
                            <TableCell key="rvlfdtage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={RVlfdTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVlfdTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVlfdTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["RVlfdTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVlfdTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvlfdPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={RVlfdpflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVlfdpfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVlfdpfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVlfdpfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVlfdpfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvlfdANanteil" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={RVlfdANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVlfdAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVlfdAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVlfdAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVlfdAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvlfdAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={RVlfdAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVlfdAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVlfdAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVlfdAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVlfdAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}}>
                                RV EGA
                            </TableCell>
                            <TableCell key="rvegatage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={RVegaTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVegaTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVegaTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["RVegaTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVegaTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvegaPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={RVegapflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVegapfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVegapfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVegapfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVegapfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvegaANanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={RVegaANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVegaAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVegaAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVegaAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVegaAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="rvegaAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={RVegaAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["RVegaAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["RVegaAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["RVegaAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('RVegaAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}} >
                                AV LFD
                            </TableCell>
                            <TableCell key="avlfdtage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={AVlfdTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVlfdTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVlfdTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["AVlfdTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVlfdTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avlfdPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={AVlfdpflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVlfdpfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVlfdpfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVlfdpfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVlfdpfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avlfdANanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={AVlfdANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVlfdAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVlfdAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVlfdAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVlfdAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avlfdAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={AVlfdAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVlfdAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVlfdAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVlfdAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVlfdAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}}>
                                AV EGA
                            </TableCell>
                            <TableCell key="avegatage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={AVegaTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVegaTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVegaTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["AVegaTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVegaTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avegaPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={AVegapflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVegapfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVegapfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVegapfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVegapfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avegaANanteil" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={AVegaANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVegaAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVegaAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVegaAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVegaAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="avegaAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={AVegaAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AVegaAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AVegaAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AVegaAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AVegaAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}} >
                                PV LFD
                            </TableCell>
                            <TableCell key="pvlfdtage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={PVlfdTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVlfdTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVlfdTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["PVlfdTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVlfdTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvlfdPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={PVlfdpflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVlfdpfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVlfdpfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVlfdpfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVlfdpfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvlfdANanteil" style={{textAlign:"right"}}  >
                                <Eingabe 
                                    disabled={PVlfdANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVlfdAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste['PVlfdAN'+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVlfdAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVlfdAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvlfdAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={PVlfdAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PvlfdAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVlfdAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVlfdAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVlfdAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:'#71ff00'}} >
                                PV EGA
                            </TableCell>
                            <TableCell key="pvegatage" style={{textAlign:"center"}} >
                                <Eingabe 
                                    disabled={PVegaTagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVegaTag"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVegaTag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["PVegaTag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVegaTag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvegaPflichtig" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={PVegapflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVegapfl"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVegapfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVegapfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVegapfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvegaANanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={PVegaANblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVegaAN"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVegaAN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVegaAN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVegaAN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="pvegaAGanteil" style={{textAlign:"right"}} >
                                <Eingabe 
                                    disabled={PVegaAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["PVegaAG"+tabellenKey]}     
                                    style={{color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["PVegaAG"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["PVegaAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('PVegaAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {SVBlockneu};