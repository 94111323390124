
import React, { useState } from "react";
import VerdienstbescheinigungArbeitgebers from "./VerdienstbescheinigungArbeitgebers";




const VerdienstbescheinigungArbeitgebers3 = (props) => {
    const { accessToken, hsutkey } = props;
    const [reactnummer] = useState("844")
    const [merkmal] = useState("VerdienstbescheinigungArbeitgeber3")
    

    return (
        <div>
            <VerdienstbescheinigungArbeitgebers reactNummer={reactnummer} merkMal={merkmal} token={accessToken} hsut={hsutkey} von="10/2022" bis="03/2023"></VerdienstbescheinigungArbeitgebers>
        </div>
    );
}

export default VerdienstbescheinigungArbeitgebers3