import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';




function FahrtenWhngArbTab (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, adressewohn, adressefirma, km, blp, faktor, ergebnis, text} = props;

    if (adressewohn !== '') {
        antworten["adrwohn"+tabellenKey]=adressewohn;
        var adrwohnblockiert=true;
    }

    if (adressefirma !== '') {
        antworten["adrfirm"+tabellenKey]=adressefirma;
        var adrfirmblockiert=true;
    }

    if (km !== '') {
        antworten["km"+tabellenKey]=km;
        var kmblockiert=true;
    }

    if (blp !== '') {
        antworten["blp"+tabellenKey]=blp;
        var blpblockiert=true;
    }

    if (faktor !== '') {
        antworten["faktor"+tabellenKey]=faktor;
        var faktorblockiert=true;
    }

    if (ergebnis !== '') {
        antworten["ergebnis"+tabellenKey]=ergebnis;
        var ergebnisblockiert=true;
    }


return(
    <div>
    <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="h5">
                {text}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Adresse Wohnsitz</TableCell>
                            <TableCell>Adresse Beschäftigungsort</TableCell>
                            <TableCell>Entfernungskilometer <br/>(volle Km abgerundet)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell >
                                <Eingabe 
                                    disabled={adrwohnblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["adrwohn"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["adrwohn"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["adrwohn"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('adrwohn'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell >
                                <Eingabe 
                                    text={true}
                                    disabled={adrfirmblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["adrfirm"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["adrfirm"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["adrfirm"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('adrfirm'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>


                            </TableCell>
                            <TableCell >
                                <Eingabe 
                                    disabled={kmblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["km"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["km"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["km"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('km'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>


            <TableContainer style={{marginTop:"10px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableHead>
                        <TableRow  >
                            <TableCell style={{textAlign:"center"}}>Bruttolistenpreis</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">x</TableCell>
                            <TableCell style={{textAlign:"center"}}>Kilometer</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">x</TableCell>
                            <TableCell style={{textAlign:"center"}} >Faktor %</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">=</TableCell>
                            <TableCell style={{textAlign:"center"}} >monatlicher gwV für<br/> Km Whng-Arbeit</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={blpblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["blp"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["blp"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["blp"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('blp'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={kmblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["km"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["km"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["km"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('km'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={faktorblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["faktor"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["faktor"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["faktor"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('faktor'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={ergebnisblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["ergebnis"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["ergebnis"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["ergebnis"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('ergebnis'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    </Grid>

    </div>
 
  )
}

export {FahrtenWhngArbTab};