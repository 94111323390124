import React from 'react';
import {Avatar, Button, ListItem, ListItemAvatar, ListItemText, Typography, Grid, Paper} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
const lsttabpdf = '/media/lohnsteuertabelle.pdf';
import { Player, ControlBar } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"
const tutorial = '/media/TutorialEignungstestOnline.mp4';


function EignungstestZeit(props) {
  const navigate=useNavigate();

  return(
    <div>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="250" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Zeiterfassung - Time Calculator</Typography> 
                  <Typography variant="h4" style={{margin:"15px"}}>
                     Prima, dass Du den Eignungstest machst und <br />
                     Deine Fähigkeiten unter Beweis stellst. <br />
                  </Typography> 
                  <Typography>
                     Informationen zur Datenverarbeitung im Rahmen Ihrer Bewerbung entnimmst Du bitte <br />
                     der "Allgemeinen Information nach DS-GVO für Bewerber der Firma Eckhardt GmbH, <br />
                     die Du mit der Eingangsbestätigung Deiner Bewerbung per E-Mail erhalten hast.<br />
                  </Typography> 

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der komplette Test besteht aus 3 Einzeltests. <br />
                  Für die ersten zwei Tests hast Du jeweils 45 Minuten Zeit. <br />
                  Für den dritten Test hast Du 15 Minunten Zeit. <br />
                  Jeder Test kann nur einmal aktiviert werden.<br />
                  Erst wenn der erste Test abgegeben wurde, folgt der zweite Test. <br />
                  Nach Abgabe von Test 2 folgt Test 3. <br />
                  Zwischen den Tests kannst Du kurze Pausen machen. <br />
                  Fange erst mit dem Test an, wenn Du mindestens 105 Minuten dran bleiben kannst,<br />
                  da ein erneutes Einloggen nicht möglich ist.<br />
              </ListItemText>  
          </ListItem>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Beginnen wir mit dem ersten Test.  <br />
                  Für diesen Test hast Du 45 Minuten Zeit. <br />
                  Der Countdown beginnt, sobald Du den Button "MIT DEM TEST BEGINNEN" anklickst.  <br />
                  Nehme Dir am besten ein Stift, Papier und Taschenrechner zur Hand, um die Werte strukturiert addieren zu können.<br />
                  Gerne kannst Du Dir auch ein Tabellenprogramm öffnen, um die Berechnungen durchzuführen.<br />
              </ListItemText>  
          </ListItem>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
              <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    5
                  </Avatar>
              </ListItemAvatar>
              <ListItemText>
                  Vergiss nicht am Ende des Tests den Button "Test abgeben" anzuklicken, <br />
                  um den Test auch wirklich abzugeben. <br />
              </ListItemText>
          </ListItem>
      </Grid>

      <Grid container spacing={1} style={{margin:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <Grid item xs={8}>
              <Button 
    //              fullWidth 
                  alignItems="left"
                  backgroundColor="#4F4F4F"
                  variant="contained"
                  onClick={() => navigate('/201/teil1')} 
                  >
                  Mit dem Test beginnen
              </Button>
          </Grid>

      </Grid>
    </div>

  )
}

export default EignungstestZeit;
