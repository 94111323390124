import React, {useState, useCallback} from 'react';
import {Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEO1 = '/media/1_Chat-Tutorial_Firmenwagen.m4v';
const VIDEO2 = '/media/2_Chat-Tutorial_Firmenwagen_1.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {FahrtenWhngArbTab} from '../Rahmendaten/FirmenwagenWhngArbTab';
import {FahrtenWhngArbPausch} from '../Rahmendaten/FirmenwagenWhngArbeitPauschal';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import { NettoLoa } from '../Rahmendaten/NettoLoa';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';




function Firmenwagen(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const {showErrorMessage} = useMessage();
  const [reactnummer]=useState('019');
  const [merkmal]=useState("Firmenwagen");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    console.log("fehler: "+fehler)
    let array=fehler.split(";");
    console.log("fehlerarray: "+array);
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
        console.log("objekt: "+objekt);
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }
  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="Firmenwagen" 
            ueberschrift2="In diesem Kurs lernst Du die Handhabung von Firmenwägen und deren Bedeutung auf der Entgeltabrechnung" 
        />

        <Grid container spacing={2} justifyContent="center">

          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText variant="h6" primary="Einstieg"/>
            </ListItem>
            <Player
                  poster={POSTER}
                  src={VIDEO1}
                  >
                  <ControlBar autoHide={false} />
            </Player>

            <ListItem style={{marginTop:"30px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText variant="h6" primary="Erklärung 1% Methode "/>
            </ListItem>

            <Player
                poster={POSTER}
                src={VIDEO2}
                >
                <ControlBar autoHide={false} />
            </Player>
          
            <ListItem style={{marginTop:"40px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText variant="h6">
                  Der Bruttolistenpreis des Fahrzeugs beträgt <strong>32642,95 Euro.</strong><br/>
                  Berechne den geldwerten Vorteil für die private Firmenwagen-Nutzung und für die Fahrten Wohnung-Arbeitsstätte.<br/>
                  Der Arbeitnehmer arbeitet regelmäßig an 5 Tagen pro Woche von Montag bis Freitag.
              </ListItemText> 
            </ListItem>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" style={{marginTop:"10px"}}>
          <Grid item xs={11}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1.1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Berechne den geldwerten Vorteil für die Privatnutzung des Firmenwagens gem. der %-Methode</b>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <BruttoNeu2
                anzReihen={2}
                tabellenKey="1" 
                text=''
                Lohnart={['1029','1350']}
                Intern={['029','350']}
                Bezeichnung={["Gehalt","Firmenwagen"]}
                Tage={["21.75","---"]}
                Stunden={["174,00","---"]}
                Faktor={["2350.00",""]}
                Kennung={["LFD",""]}
                Prozent={["0,00","0,00"]}
                Steuerpflichtig={["2350.00",""]}
                Sozialverspflichtig={["2350.00",""]}
                Betrag={["2350.00",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
              /> 
          </Grid>


          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <NettoLoa
              tabellenKey="1" 
              text=''
              NLohnart='2067'
              NIntern='067'
              NBezeichnung="Firmenwagen"
              NBetrag=""
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
            <Typography style={{textAlign:"left", marginTop:"20px"}}>
                Der geldwerte Vorteil für die private Nutzung eines Firmenwagens unterliegt der Umsatzsteuer. <br/>
                Diese gesetzliche Regelung findet dann versehentlich keine Beachtung, wenn der Betrag im brutto nur der Steuer und der Sozialversicherung unterworfen wird, aber keine Auszahlung stattfindet.<br/>
                <b>Durch die Vorgehensweise, dass der geldwerte Vorteil im brutto steuer- und sv-pflichtig ausbezahlt und im netto wieder abgezogen wird,
                ist der Nettoabzug ein "Ertrag", der dann mit der richtigen Zuordnung im Buchungsbeleg auch der Umsatzsteuer unterworfen wird.</b> 
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" style={{marginTop:"35px"}}>
          <Grid item xs={11}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1.2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText >
                  <b>Berechnung geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte</b>
              </ListItemText>
            </ListItem>
            <FahrtenWhngArbTab 
              tabellenKey="1" 
              text=''
              fehlerliste={fehlerliste} 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              adressewohn="Andreas-Stihl-Str. 4, 71336 Waiblingen"
              adressefirma="Max-Eyth-Str. 18, 71332 Waiblingen"
              km=""
              blp=""
              faktor=""
              ergebnis=""
            />
          </Grid>
        </Grid>        

        <Grid container spacing={2} justifyContent="center" style={{marginTop:"35px"}}>
          <Grid item xs={11}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1.3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText >
                <b>Ergänze die Lohnart 1355 mit den ermittelten Werten für den geldwerten Vorteil der Fahrten zwischen Wohnung und Arbeitsstätte</b>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <BruttoNeu2
              anzReihen={3}
              tabellenKey="2" 
              text=''
              Lohnart={['1029','1350','1355']}
              Intern={['029','350','355']}
              Bezeichnung={["Gehalt","Firmenwagen",`Fahrten Whng-Arb.`]}
              Tage={["21.75","---","---"]}
              Stunden={["174,00","---","---"]}
              Faktor={["2350.00",antworten["fak11"],""]}
              Kennung={["LFD",antworten["kenn11"],""]}
              Prozent={["0,00","0,00","0,00"]}
              Steuerpflichtig={["2350.00",antworten["steuerpfl11"],""]}
              Sozialverspflichtig={["2350.00",antworten["sozial11"],""]}
              Betrag={["2350.00",antworten["betr11"],""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <NettoLoa2
              anzReihen={2}
              tabellenKey="2" 
              text=''
              NLohnart={['2067','2068']}
              NIntern={['067','068']}
              NBezeichnung={["Firmenwagen","Fahrten Whng-Arb."]}
              NBetrag={[antworten["nbetr1"],""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>
        </Grid>


        <Grid container spacing={2} justifyContent="center" style={{marginTop:"35px"}}>
          <Grid item xs={11}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1.4
                </Avatar> 
              </ListItemAvatar>
              <ListItemText >
                  <b>Pauschale Versteuerung des geldwerten Vorteil für die Fahrten von der Wohnung zur Arbeit</b><br/>
                  Von dem geldwerten Vorteil für die Fahrten zwischen Wohnung und Arbeitsstätte <b>kann</b> ein Teil mit 15% pauschal versteuert werden. <br/>
                  Der Arbeitnehmer macht von dieser Möglichkeit gebrauch und möchte, dass ein Teil des geldwerten Vorteils für die Fahrten von der Wohnung zur Arbeitsstätte pauschal versteuert werden.<br/>
                  Fülle die Tabelle aus und berechne somit den pauschal zu versteuernden Betrag 

              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <FahrtenWhngArbPausch
              tabellenKey="1"
              km=""
              ergpausch=""
              tage=""
              kmsatz="0,30"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" style={{marginTop:"45px"}}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10} style={{textAlign:"left"}}>
            <Typography >
              Die Lohnarten ergeben sich, wenn der Arbeitnehmer die 15% Pauschalierungsmöglichkeit in Anspruch nimmt. 
              Ergänze die Lohnarten mit den richtigen Werten.
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <BruttoNeu2
              anzReihen={4}
              tabellenKey="3" 
              text=''
              Lohnart={['1029','1350','1355','1545']}
              Intern={['029','350','355','545']}
              Bezeichnung={["Gehalt","Firmenwagen",`Fahrten Whng-Arb.`,'Pausch.Whng-Arb']}
              Tage={["21.75","---","---","---",]}
              Stunden={["174,00","---","---","---"]}
              Faktor={["2350.00",antworten["fak11"],"",""]}
              Kennung={["LFD",antworten["kenn11"],"",""]}
              Prozent={["0,00","0,00","0,00","0,00"]}
              Steuerpflichtig={["2350.00",antworten["steuerpfl11"],"",""]}
              Sozialverspflichtig={["2350.00",antworten["sozial11"],"",""]}
              Betrag={["2350.00",antworten["betr11"],"",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <NettoLoa2
              anzReihen={3}
              tabellenKey="3" 
              text=''
              NLohnart={['2067','2068',"2068"]}
              NIntern={['067','068','068']}
              NBezeichnung={["Firmenwagen","Fahrten Whng-Arb.",'Pausch.Whng-Arb']}
              NBetrag={[antworten["nbetr1"],"",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>

            <Typography style={{textAlign:"left"}}>
                Die Lohnart 2068 kann auf der Entgeltabrechnung auch zusammengefasst und summiert in einer Lohnart ausgewiesen werden. 
            </Typography>

          </Grid>
        </Grid>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
      </div>
    )
  }
  

export default Firmenwagen;
