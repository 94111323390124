import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function Tabelle2spaltig (props) {
   const {tabellenKey, fehlerliste, setzeAntworten, antworten, anzReihen, ueberschrift, uebschr_spalte1, uebschr_spalte2, spalten1, spalten2, spalte1Platz, spalte2Platz} = props;

   let spalten1Blockiert=[""]
   let spalten2Blockiert=[""]

    let ausrichtung = "center"
   for (let B = 0; B < anzReihen; B++) {
        if (spalten1[B] !== '') {
            antworten["spalt1"+tabellenKey+B]=spalten1[B];
            spalten1Blockiert[B]=true;
        }
     }    
     for (let B = 0; B < anzReihen; B++) {
        if (spalten2[B] !== '') {
            antworten["spalt2"+tabellenKey+B]=spalten2[B];
            spalten2Blockiert[B]=true;
        }
     }    

    let reihen=[];
    for (let i = 0; i < anzReihen; i++) {
         reihen.push("A")
    }
    
    
    const reihenEinfügen  = reihen.map((value, i) => {
        return (    
            <TableRow >
                <TableCell key="Spalte1" style={{textAlign:spalte1Platz}}>
                    <Eingabe 
                        disabled={spalten1Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt1"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["spalt1"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte1Platz}}}
                        value={antworten["spalt1"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt1'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>
                <TableCell key="Spalte2" style={{textAlign:spalte2Platz}}>
                    <Eingabe 
                        disabled={spalten2Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt2"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt2"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte2Platz}}}
                        value={antworten["spalt2"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt2'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>
            </TableRow>
    );})

 
  

return(
 
    <div>
    <Grid container spacing={0} style={{border:'solid', marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
           <TableContainer>
               <Typography style={{textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE'}}>
                    {ueberschrift}
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:spalte1Platz}}>{uebschr_spalte1}</TableCell>
                            <TableCell style={{textAlign:spalte2Platz}}>{uebschr_spalte2}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reihenEinfügen}
                    </TableBody>
                </Table>
            </TableContainer>
    </Grid>
    </div>


 
  )
}

export {Tabelle2spaltig};