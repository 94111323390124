import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, Checkbox, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {KursAufgabenErledigt} from '../../components/Rahmendaten/KursAufgabenErledigt';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { element } from 'prop-types';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";


const Figur1 = '/media/Figur1.png';
const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const ecschoolRahmen ='/media/ecschool Rahmen.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';

const bilder =[Figur1,BuchDateneingabe,ecschoolRahmen,BuchLeseUndLerne,BuchDraufHast]

// var bild=bilder[Math.floor(Math.random()*bilder.length)]
// console.log(bild);


function ScheckM01 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('800');
    const [kurskennung, setKurskennung]=useState("ScheckM01");
    const [merkmal]=useState('');
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);

        }
      }
    }

    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "aufgabgeerl",
          "reactnummer": reactnummer,
          "kurskennung": kurskennung,
          "merkmal": merkmal,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'aufgabeerl', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

      const DokumentOeffnen = (docnr, docbez, docendung) => {
          const payload={
            "kennung": "kursdaten",
            "kurskennung": "download_lohndaten",
            "reactnummer": reactnummer,
            "docnr":docnr,
            "docname": docbez,
            "hsutkey": hsutkey
          }
          const headers={
            "Authorization": "Bearer "+accessToken,
            "Accept": "application/pdf"
          }
          axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
            .then((response) => {
              if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
              var contentFile = response.data;
              const mimeType = response.headers['content-type'] || 'application/pdf'
              openFile(fileName, contentFile,mimeType);
            })
            .catch(function (error) {
              showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
            })
        }
        
return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="In dem ersten Modul lernst Du, wie man einen Arbeitnehmer und Lohnarten anlegt.^Darüber hinaus lernst Du den SCHECK-Verteiler mit seinen Besonderheiten kennen." 
    />


    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h5'>
          Das SCHECK-Programm ist ein sehr vielschichtiges Programm mit einer Menge von Anwendungen und Möglichkeiten. 
        </Typography>
        <Typography variant='h5'>
          Lass uns mit dem ersten elementaren Programm beginnen.<br/>
          Der Datenerfassung eines neuen Arbeitnehmers.
        </Typography>
      </Grid>
    </Grid>







{
    lohndatenwerte && 
    <Fragment>
    <Grid style={{display: 'flex', marginTop:'50px', justifyContent:"center", }} >
      <Grid item xs={12}>
      <Typography variant='h5'>
        Beginne nun zusammen mit den Handbüchern die folgenden Daten im SCHECK-Programm einzugeben. 
      </Typography>
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Hw
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Abrechnungsmonat 01 September<br/>
              Erledige die folgenden Aufgaben in der angegebenen Reihenfolge.
              Gerne kannst Du für die Dateneingabe die entsprechenden Handbücher verwenden.
            </Typography>

          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center" }} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Bitte lege die 3 neuen Mitarbeiter anhand der Stammblätter im Programm Arbeitnehmerstamm an. <br/>
                Verwende die Excel-Tabelle "Arbeitnehmerstamm vorausgefüllt mit unrelevanten Daten.xlsx" um Eingaben machen zu können, zu denen keine Angaben auf den Stammblättern gemacht werden. 
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Hast Du die Mitarbeiter angelegt, erfasse bitte die Gehälter und die variablen Lohnarten. <br/>
                Die jeweiligen Gehälter entnimmst Du den Stammblättern und die variablen Vergütungsbestandteile stehen in dem Erfassungsbogen. <br/>
                Verwende für die variablen Lohnarten das Interview "Interviewerfassung Variable Lohnarten".<br/>
                Für die Stammlohnarten (Gehälter) verwende das Programm "Stammlohnarten Erfassung".<br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A3
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Mit dem Programm "Digitale Entgeltabrechnung" kannst Du deine Eingaben kontrollieren. <br/>
                Verwende es am besten gleich, um deine Erfassungen auf Richtigkeit zu prüfen. <br/>
                Hast Du alles überprüft und ggfls. alle Korrekturen vorgenommen, klicke auf den Button "Aufgaben erledigt".<br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    </Fragment>
  }

  <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

  {
    lohndaten?.map((inhalt,index) => {
          return(

              <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez, inhalt.endung)}
                >
                  Zu erfassende Lohndaten in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
 </Grid>

 <Grid style={{marginTop:'50px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h4'>
          Handbücher
        </Typography>
      </Grid>
  </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
           var grafik = bilder[Math.floor(Math.random()*bilder.length)]
          if (index === 3) {
            //  grafik = bild
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>

  

    <KursAufgabenErledigt  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </div>
 
    )
}

export default ScheckM01;