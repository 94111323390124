import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, ListItem, ListItemAvatar, ListItemText, MenuItem, TextField } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../Rahmendaten/Kursabgeben';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Tabelle2spaltig} from '../Rahmendaten/Tabelle2spaltig'
import {Tabelle8spaltig} from '../Rahmendaten/Tabelle8spaltig'
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckJan1 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('816');
    const [kurskennung, setKurskennung]=useState("ScheckJan1");
    const [merkmal]=useState('ScheckJan1');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);

        }
      }
    }


    const setzeAntworten = ( key,value) => {
      let values = Object.assign(antworten);
      values[key] = value;
      setAntworten(values);
      forceUpdate();   
    }

    const antwortenSenden = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursabgabe",
        "merkmal": merkmal,
        "daten": antworten,
        "reactnummer": reactnummer,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  
    }
  
    const kurseCb = (response) => {
      if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
        fehlerAusLesen(response.data.fehler);

      } else {
        if (response) {
          props.showError(response.data.message);
        } else {
          props.showError('Keine Internetverbindung');
        }
      }
    }

    const fehlerAusLesen=(fehler)=>{
      let array=fehler.split(";");
      let fehlerzwis=[]
      array.map(
        // eslint-disable-next-line
        (objekt)=> {
          fehlerzwis[objekt]=true;    
        }
      );
      setFehlerliste(fehlerzwis);
    };


    const DokumentOeffnen = (docnr, docbez, docendung) => {
      const payload={
        "kennung": "kursdaten",
        "kurskennung": "download_lohndaten",
        "reactnummer": reactnummer,
        "docnr":docnr,
        "docname": docbez,
        "hsutkey": hsutkey
      }
      const headers={
        "Authorization": "Bearer "+accessToken,
        "Accept": "application/pdf"
      }
      axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
        .then((response) => {
          if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
          var contentFile = response.data;
          const mimeType = response.headers['content-type'] || 'application/pdf'
          openFile(fileName, contentFile,mimeType);
        })
        .catch(function (error) {
          showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
        })
    }

return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im vierten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h5' align="left">
            In diesem Kurs lernst Du die Prüfungen kennen, die zum Jahresbeginn durchzuführen sind. <br/>
            Des Weiteren ist für alle Arbeitnehmer zu prüfen, ob sie in der gesetzlichen, freiwilligen oder privaten Krankenversicherung versichert bleiben. 
        </Typography>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>

  {
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={6}>
              <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Zu erfassende Lohndaten in einem neuen Tab anzeigen!
                </Button>

              </Card>
            </Grid>
          )
        })
    }


  <Fragment>
    <Grid style={{marginTop:'100px'}} justifyContent={"left"} >
      <Grid item xs={12}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
              <Typography variant="h4" >    
                Die KV Prüfung <br/>
               </Typography>

                <Typography variant="h5" style={{marginLeft:"30px", marginTop:"10px"}} >    
                  Anhand der Informationen aus dem Handbuch "Erklärung Prüfung Jahresbeginn" soll nun in diesen 7 Fällen geprüft werden, ob der Versicherungsstatus (gesetzlich, freiwillig oder privat versichert) aus 2018 beibehalten werden kann oder ob sich anhand der vorgegebenen Entgeltinformationen für 2018/2019 der Versicherungstatus ändert. 
                </Typography>
              </ListItemText>
            </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b/>!<b/>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant='h4'>
                  Beachte bitte dabei folgende Punkte:
                </Typography>
             </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"80px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Die Prüfung zur KV-Freiheit ist rechnerisch in Excelformeln darzustellen
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"80px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Die Prüfung findet zum Jahreswechsel 2018/2019 statt
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"80px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Das Ergebnis der Prüfung ist in zwei ergänzenden Spalten - Beitragsgruppe und Versicherungsstatus (Bezeichnung - gesetzlich, freiwillig, privat) nach Prüfung - darzustellen
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"80px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Die Entgeltbestandteile aus Spalte E-G sind vertraglich zugesagtes Entgelt und müssen berücksichtigt werden. Die Prämie (Fall 6) ist nicht zugesagt.
            </Typography>
        </ListItemText>

      </ListItem>

    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={12}>

    <Tabelle8spaltig 
        tabellenKey="" 
        anzReihen="7"
        ueberschrift="Abrechnungswerte 2018 für die KV-Prüfung 2019"
        uebschr_spalte1="Fall"
        spalte1Platz="center"
        uebschr_spalte2="akt. Beitragsgruppe"
        spalte2Platz="center"
        uebschr_spalte3="Verdienst 2018"
        spalte3Platz="center"
        uebschr_spalte4="mntl. Gehalt 2019"
        spalte4Platz="center"
        uebschr_spalte5="VWL AG Anteil"
        spalte5Platz="center"
        uebschr_spalte6="GKV PKW 1% Regel"
        spalte6Platz="center"
        uebschr_spalte7="BAV Umwandlung"
        spalte7Platz="center"
        uebschr_spalte8="Zusatzinformationen"
        spalte8Platz="center"

        spalten1={["1","2","3","4","5","6","7"]}
        spalten2={["9111","0110","0111","1111","1111","9111","0110"]}
        spalten3={["60.150,00","60.000,00","58.000,00","65.000,00","55.000,00","59.500,00","55.500,00"]}
        spalten4={["5.000,00","5.000,00","4.900,00","5.300,00","5.299,00","4.700,00","4.625,00"]}
        spalten5={["26,59","--","26,59","26,59","--","--","--"]}
        spalten6={["--","250,00","300,00","--","--","451,00","--"]}
        spalten7={["--","--","-50,00","-200,00","--","-70,00","--"]}
        spalten8={["--","--","--","--","--","Prämie 2019: 2.500,00 €","Geburtsdatum: 02.03.1960 - PKV seit: 01.01.2002"]}

        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
    />
    </Grid>

  </Grid>

  <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={5}>
      <Typography>Grenzwerte der Sozialversicherung ab dem 01.01.2018</Typography>
      <Tabelle2spaltig 
        tabellenKey="" 
        anzReihen="3"
        ueberschrift="BBG KV/PV"
        uebschr_spalte1="Zeitraum"
        spalte1Platz="center"
        uebschr_spalte2="Betrag"
        spalte2Platz="right"
        spalten1={["monatlich","jährlch"]}
        spalten2={["4.425,00 €","53.100,00 €"]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>
    <Grid item xs={2}></Grid>

    <Grid item xs={5}>
      <Typography>Grenzwerte der Sozialversicherung ab dem 01.01.2019</Typography>
      <Tabelle2spaltig 
        tabellenKey="" 
        anzReihen="3"
        ueberschrift="BBG KV/PV"
        uebschr_spalte1="Zeitraum"
        spalte1Platz="center"
        uebschr_spalte2="Betrag"
        spalte2Platz="right"
        spalten1={["monatlich","jährlch"]}
        spalten2={["4.537,50 €","54.450,00 €"]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid item xs={5}>
      <Tabelle2spaltig 
        tabellenKey="" 
        anzReihen="2"
        ueberschrift="JAE Jahresarbeitsentgeltgrenze"
        uebschr_spalte1="Zeitraum"
        spalte1Platz="center"
        uebschr_spalte2="Betrag"
        spalte2Platz="right"
        spalten1={["monatlich","jährlch"]}
        spalten2={["4.950,00 €","59.400,00 €"]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>
    <Grid item xs={2}></Grid>

    <Grid item xs={5}>
      <Tabelle2spaltig 
        tabellenKey="" 
        anzReihen="2"
        ueberschrift="JAE Jahresarbeitsentgeltgrenze"
        uebschr_spalte1="Zeitraum"
        spalte1Platz="center"
        uebschr_spalte2="Betrag"
        spalte2Platz="right"
        spalten1={["monatlich","jährlch"]}
        spalten2={["5.062,50 €","60.750,00 €"]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'70px'}} justifyContent={"center"} >
    <Grid item xs={12}>
        <Typography variant='h4' >Ergebnis Deiner Berechnungen und Feststellungen für 2019</Typography>
    </Grid>
    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F1"
        style={{}}
        label="Ergebnis Fall 1 auswählen "
        fullWidth
        error={fehlerliste["F1"]}
        value={antworten["F1"]||''}
        onChange={(event) => setzeAntworten("F1",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>
    <Grid item xs={2}></Grid>
    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F2"
        style={{}}
        label="Ergebnis Fall 2 auswählen "
        fullWidth
        error={fehlerliste["F2"]}
        value={antworten["F2"]||''}
        onChange={(event) => setzeAntworten("F2",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>

    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F3"
        style={{}}
        label="Ergebnis Fall 3 auswählen "
        fullWidth
        error={fehlerliste["F3"]}
        value={antworten["F3"]||''}
        onChange={(event) => setzeAntworten("F3",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>
    <Grid item xs={2}></Grid>
    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F4"
        style={{}}
        label="Ergebnis Fall 4 auswählen "
        fullWidth
        error={fehlerliste["F4"]}
        value={antworten["F4"]||''}
        onChange={(event) => setzeAntworten("F4",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>

    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F5"
        style={{}}
        label="Ergebnis Fall 5 auswählen "
        fullWidth
        error={fehlerliste["F5"]}
        value={antworten["F5"]||''}
        onChange={(event) => setzeAntworten("F5",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>
    <Grid item xs={2}></Grid>
    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F6"
        style={{}}
        label="Ergebnis Fall 6 auswählen "
        fullWidth
        error={fehlerliste["F6"]}
        value={antworten["F6"]||''}
        onChange={(event) => setzeAntworten("F6",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>

    <Grid item xs={5}>
      <TextField
        select // durch select wird DropDown initialisiert
        id="F7"
        style={{}}
        label="Ergebnis Fall 7 auswählen "
        fullWidth
        error={fehlerliste["F7"]}
        value={antworten["F7"]||''}
        onChange={(event) => setzeAntworten("F7",event.target.value)}
        variant="outlined"
        margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
        InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }} >
          <MenuItem value={10}>keine Änderung</MenuItem>
          <MenuItem value={20}>wechsel in die gesetzliche Krankenversicherung mit Beitragsgruppe 1111</MenuItem>
          <MenuItem value={30}>Wechsel in die freiwillige KV mit der Möglichkeit der privaten KV BGR: 0111,9111,0110</MenuItem>
      </TextField>          
    </Grid>

  </Grid>



  </Fragment>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>      

    </div>
 
    )
  }

export default ScheckJan1;