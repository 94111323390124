import React from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';


function TabelleLohnarten (props) {
  const {fehlerliste, antworten, setzeAntworten} = props;

//  const [hintergrundfarbe, setHintergrundfarbe]= useState("red");
   
  const hintergrundfarbe="#e3e3e3";
//  const [antworten,setAntworten]=useState({});



  return(

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>
        <Typography variant={'h6'} >Lohnarten - Block</Typography>

        <Grid item xs={12} style={{border:"solid", }}>
          <Typography>Erfassung Lohnarten</Typography>
        </Grid>

        <Grid item xs={1} style={{border:"solid"}}>
          <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Lohnart</Typography>
          <TextField 
            placeholder="Eingabe"
            inputProps={{style:{textAlign:"center"}}}
            error={fehlerliste["LOA1"]}
            style={{backgroundColor:(fehlerliste["LOA1"]===true)?"red":""}}
            value={antworten["LOA1"]||''}
            onChange={(event) => setzeAntworten('LOA1',event.target.value)}
            >
            </TextField>
          <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
          <TextField disabled style={{backgroundColor:hintergrundfarbe}}></TextField>
       </Grid>

       <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
          <Typography 
            style={{borderBottom:"solid", backgroundColor:"grey"}}
            >
              Intern
          </Typography>
          <TextField 
            placeholder="Eingabe"
            error={fehlerliste["INT1"]}
            style={{backgroundColor:(fehlerliste["INT1"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            onChange={(event) => setzeAntworten('INT1',event.target.value)}>
            </TextField>
            <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
            <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
       </Grid>
       <Grid item xs={2} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
            <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Bezeichnung</Typography>
            <TextField 
              placeholder="Eingabe"
              error={fehlerliste["BEZ1"]}
              style={{backgroundColor:(fehlerliste["BEZ1"]===true)?"red":""}}
              inputProps={{style:{textAlign:"center"}}}
              value={antworten["BEZ1"]||''}
              onChange={(event) => setzeAntworten('BEZ1',event.target.value)}
            >
            </TextField>
            <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
            <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
       </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Tage</Typography>
      <TextField
        placeholder="Eingabe"
        error={fehlerliste["TAG1"]}
        style={{backgroundColor:(fehlerliste["TAG1"]===true)?"red":""}} 
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["TAG1"]||''}
        onChange={(event) => setzeAntworten('TAG1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Stunden</Typography>
      <TextField
        placeholder="Eingabe"
        error={fehlerliste["STD1"]}
        style={{backgroundColor:(fehlerliste["STD1"]===true)?"red":""}} 
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["STD1"]||''}
        onChange={(event) => setzeAntworten('STD1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Faktor</Typography>
      <TextField
        placeholder="Eingabe"
        error={fehlerliste["FAK1"]}
        style={{backgroundColor:(fehlerliste["FAK1"]===true)?"red":""}} 
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["FAK1"]||''}
        onChange={(event) => setzeAntworten('FAK1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>%</Typography>
      <TextField 
          disabled
 //       inputProps={{style:{textAlign:"center"}}}
//        value={antworten["PRO1"]||''}     
//        onChange={(event) => setzeAntworten('PRO1',event.target.value)}
        >
      </TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Steuerpflichtig</Typography>
      <TextField 
        placeholder="Eingabe"
        error={fehlerliste["STE1"]}
        style={{backgroundColor:(fehlerliste["STE1"]===true)?"red":""}}
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["STE1"]||''}
        onChange={(event) => setzeAntworten('STE1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={1} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Sozialvers.pfl</Typography>
      <TextField
        placeholder="Eingabe"
        error={fehlerliste["SOZ1"]}
        style={{backgroundColor:(fehlerliste["SOZ1"]===true)?"red":""}} 
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["SOZ1"]||''}
        onChange={(event) => setzeAntworten('SOZ1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
     <Grid item xs={2} style={{borderTop:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <Typography style={{borderBottom:"solid", backgroundColor:"grey"}}>Betrag</Typography>
      <TextField 
        placeholder="Eingabe"
        error={fehlerliste["BET1"]}
        style={{backgroundColor:(fehlerliste["BET1"]===true)?"red":""}}
        inputProps={{style:{textAlign:"center"}}}
        value={antworten["BET1"]||''}
        onChange={(event) => setzeAntworten('BET1',event.target.value)}></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
      <TextField style={{backgroundColor:hintergrundfarbe}} disabled></TextField>
     </Grid>
    </Grid>
  )
}


export {TabelleLohnarten};