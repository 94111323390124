import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Kursabgeben } from '../Rahmendaten/Kursabgeben';
import { Briefkopf } from '../Rahmendaten/Briefkopf';
const handbuch = '/media/rehm_kurzarbeit.pdf';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import { Tabelle3spaltig } from '../Rahmendaten/Tabelle3spaltig'
import { DragDrop2Compoment } from '../Rahmendaten/DragDrop2Compoment'
import { pad } from "../../functions/default";
import { Tabelle2spaltigDropBoxSp1 } from '../Rahmendaten/Tabelle2spaltigDropBoxSp1'
import { Tabelle5spaltigDropBoxSp1 } from '../Rahmendaten/Tabelle5spaltigDropBoxSp1'
import { Tabelle2spaltig } from '../Rahmendaten/Tabelle2spaltig'
import { Tabelle8spaltig } from '../Rahmendaten/Tabelle8spaltig'

function Kurzarbeit2(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate = useNavigate();
  const [antworten, setAntworten] = useState({});
  const [testabgabe, setTestabgabe] = useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste, setFehlerliste] = useState([]);
  const [anzFehler, setAnzFehler] = useState('0')
  const { showErrorMessage } = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [kaltage, setKaltage] = useState([]);
  const [txtwtage, setTxtwtage] = useState([]);
  const [sollzeit, setSollzeit] = useState([]);
  const [istzeit, setIstzeit] = useState([]);
  const [kugstd, setKugstd] = useState([]);
  const [schichtzulAZ, setSchichtzulAZ] = useState([]);
  const [nachzzulageAZ, setNachzzulageAZ] = useState([]);
  const [meisterzulAZ, setMeisterZulAZ] = useState([]);
  const [ausgewaehlteLohnarten, setAusgewaehlteLohnarten] = useState([]);
  const [reactnummer] = useState('047');
  const [merkmal] = useState("Kurzarbeit2");

  const listlohnarten = [
    { id: "1", content: "1029-Gehalt" },
    { id: "2", content: "1308-Tarifl.Zulage" },
    { id: "3", content: "1309-Frw.Zulage" },
    { id: "4", content: "1310-Schichtzulage" },
    { id: "5", content: "1043-Nachtarbeitszuschlag" },
    { id: "6", content: "1310-Prämie" },
    { id: "7", content: "1401-Meisterzulage" }
  ];


  var gehalt = '2700,00', Istentgelt = '1928,57', tzulage = "200,00", fzulage = '150,00', schichtzul = '168,25', schichtzul1 = '120,18', schichtzul2 = '200,00'
  var nzulagest = '--', nzulagesv = "--", nachtstd = '70,00', nfaktor = "20,03", nachtproz = "25,00", schichtproz = "20,00", nbetrag = "350,53", nzulagest1 = '--', nzulagesv1 = "--", nachtstd1 = '65,00', nbetrag1 = "325,49"
  var prämie = "300,00", meisterzul = "200,00", prämieJuli = "250,00", meisterzulJuli = "200,00", schichtzulJuli = "200,30", nzulagestJuli = '--', nzulagesvJuli = "--", nachtstdJuli = '73,00', nbetragJuli = "365,55"
  var meisterzulAugust = "120,00", schichtzulAugust = "112,17", nachtstdAugust = '21,00', nbetragAugust = "105,16", nachtausglAugust = "100,00"


  useEffect(() => {
    var datum = ""
    var wochentag = ""
    var kalendertage = kaltage.slice();
    var textwochentage = txtwtage.slice();
    var istarbeitszeit = istzeit.slice();
    var sollarbeitszeit = sollzeit.slice();
    var kugstunden = kugstd.slice();
    for (let i = 1; i < 32; i++) {
      kalendertage.push(i);
      datum = new Date("2021-08-" + pad(i, 2))
      wochentag = datum.getDay()
      if (wochentag == 0) textwochentage.push("Sonntag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("--") && istarbeitszeit.push("--") && kugstunden.push("--");

      if (wochentag == 1 && i == "16") textwochentage.push("Montag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 1 && i == "23") textwochentage.push("Montag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 1 && i != "16" && i != "23") textwochentage.push("Montag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("7,50") && kugstunden.push("--");

      if (wochentag == 2 && i == "10") textwochentage.push("Dienstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 2 && i != "10") textwochentage.push("Dienstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("7,50") && kugstunden.push("--");

      if (wochentag == 3) textwochentage.push("Mittwoch, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("7,50") && kugstunden.push("--");

      if (wochentag == 4 && i == "5") textwochentage.push("Donnerstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("5,00") && kugstunden.push("2,50");
      if (wochentag == 4 && i == "12") textwochentage.push("Donnerstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 4 && i == "19") textwochentage.push("Donnerstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 4 && i == "26") textwochentage.push("Donnerstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("--") && kugstunden.push("7,50");
      if (wochentag == 4 && i != "5" && i != "12" && i != "19" && i != "26") textwochentage.push("Donnerstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("7,50") && kugstunden.push("--");


      if (wochentag == 5) textwochentage.push("Freitag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("7,50") && istarbeitszeit.push("7,50") && kugstunden.push("--");

      if (wochentag == 6) textwochentage.push("Samstag, " + pad(i, 2) + ".08.") && sollarbeitszeit.push("--") && istarbeitszeit.push("--") && kugstunden.push("--");

    }

    setTxtwtage(textwochentage);
    setSollzeit(sollarbeitszeit);
    setIstzeit(istarbeitszeit);
    setKugstd(kugstunden);
    setMeisterZulAZ([, "8,00", "8,00", "8,00", "8,00", , , "8,00", , "8,00", , "8,00", , , "8,00", "8,00", "8,00", , , , , , "8,00", "8,00", , "8,00", , , , "8,00", "8,00",]);
    setSchichtzulAZ([, "4,00", , "4,00", , , , , "4,00", , "4,00", , , , , , "4,00", , , "4,00", , , , "4,00",]);
    setNachzzulageAZ([, "3,00", , "3,00", , , , , "3,00", , "3,00", , , , , , "3,00", , , "3,00", , , , , "3,00",]);


    // eslint-disable-next-line
  }, [])


  const setzeAntworten = (key, value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen = (fehler) => {
    console.log("fehler: " + fehler)
    let array = fehler.split(";");
    let fehlerzwis = []
    array.map(
      // eslint-disable-next-line
      (objekt) => {
        fehlerzwis[objekt] = true;
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "ausgewaehlteLoas": ausgewaehlteLohnarten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    }
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }


  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6, 8) + "." + response.data.abgabedat.slice(4, 6) + "." + response.data.abgabedat.slice(0, 4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const linkCb = (response) => {
    if (response.status === 200) {
      if (response.data.rehmlink !== "") {
        window.open(response.data.rehmlink)
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }

  }

  const LexikonLohnbüro = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": "getLink",
      "reactnummer": "046"
    }
    apiRequest('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
  }

  const kug_tabelle = () => {
    setRedirect();
  }

  return (
    <div>
      <Briefkopf
        ueberschrift1="Kurzarbeit"
        ueberschrift2="Was bedeutet Soll-Aufschlag?"
      />

      <Grid container spacing={0} style={{ marginTop: '15px', display: "flex" }} >
        <Grid item xs={12} style={{ textAlign: "left" }} >
          <Typography variant="h4" color="primary" >
            Der Soll-Auschlag bei Kurzarbeit
          </Typography>
          <Typography variant="h5" >
            <b>W</b>as bedeutet der Soll-Aufschlag?<br />
            <b>W</b>ie wird der Soll-Aufschlag berechnet??<br />
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{ marginTop: '30px' }} >
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#1fbba6" }}>
              R
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              Eigenrecherche im Lexikon für das Lohnbüro
            </Typography>
            <Typography style={{ textAlign: "left" }}>
              Mit Klick auf den Button gelangst Du auf das digitale Lexikon für das Lohnbüro. <br />
              Benutze das Lexikon, um Dich auf dem Gebiet der Kurzarbeit schlau zu machen.<br />
              Anschließend kannst Du die Aufgaben bestimmt lösen und erkennst die Zusammenhänge.<br />
            </Typography>
            <Button
              color="primary"
              variant="contained"
              alignItems="center"
              backgroundColor="#4F4F4F"
              style={{ align: "center" }}
              onClick={() => LexikonLohnbüro()}
            >
              Lexikon für das Lohnbüro
            </Button>
          </ListItemText>
          <Button
            color="primary"
            variant="contained"
            //              fullWidth
            href={handbuch} download>
            Wie gelange ich zu dem Thema "Kurzarbeitergeld" in dem digitalen Lexikon?"
          </Button>
        </ListItem>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "100px", height: "70px" }}>
                Was
              </Avatar>
            </ListItemAvatar>
            <ListItemText style={{ marginLeft: "30px" }}>
              <Typography variant="h5">
                <strong>Was bedeutet Soll-Aufschlag?</strong><br />
              </Typography>
              In dem vorherigen Kurs wurde Dir die grundlegende Berechnung von Kurzarbeitergeld anhand eines einfachen Beispiels aufgezeigt.<br />
              <b>Wie verhält es sich nun aber in den Fällen, in welchen die von Kurzarbeit betroffenen Personen z.B. Schichtzulagen oder Provisionen erhalten?</b><br />
              <b>Fallen die in den vorherigen Monaten gearbeiteten Schichten oder in der Arbeitszeit erwirtschaftete Provisionen untern Tisch oder sollen diese berücksichtigt werden?</b><br />
              Für die Berechnung der Kurzarbeit ist das Soll-Entgelt maßgebend, welches der Arbeitnehmer ohne den Arbeitsausfall erzielt hätte. Lässt sich das Soll-Entgelt des Arbeitnehmers im Anspruchsmonat nicht mit hinreichender Sicherheit feststellen, ist ein Durchschnitt zu ermitteln.
              Wenn die von Kurzarbeit betroffenen Ausfallstunden auch z.B. die Schichtarbeit betrifft, erhalten die Personen auch keine Schichtzulagen für diese Zeiten. Kann die Höhe der ausgefallenen Schichtzulagen nicht mit hinreichender Sicherheit festgestellt werden, ist für derartige
              Verdienstausfälle durch Kurzarbeit ein Durchschnitt zu ermittelt und als Soll-Aufschlag zu berücksichtigen. Dieser Soll-Aufschlag erhöht das Soll-Entgelt.
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>


      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "100px", height: "70px" }}>
                Wie
              </Avatar>
            </ListItemAvatar>
            <ListItemText style={{ marginLeft: "30px" }}>
              <Typography variant="h5">
                <strong>Wie wird der Soll-Aufschlag (Soll-Entgelt) ermittelt?</strong><br />
              </Typography>
              Der Soll-Aufschlag ist ein jeweils zu bildender Durchschnitt der letzten 3 Monate vor Kurzarbeit. <br />
              Alle <b>regelmäßigen</b> und <b>variablen beitragspflichtigen Entgeltbestandteile</b> (Lohnarten) <b>der letzten 3 Monaten vor Kurzarbeit,</b> werden für den Durchschnitt berücksichtigt.<br />
              Vergütungen von <b>EGA</b> werden bei der Ermittlung des Durchschnittswertes <b>nicht berücksichtigt.</b><br />
              Für den Durchschnittswert werden die Lohnarten verwendet, welche zusätzlich zu den verstetigten (Stamm-)Lohnarten vergütet werden.
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item xs={12} >
          <ListItem style={{ marginLeft: "50px" }}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                *
              </Avatar>
            </ListItemAvatar>
            <ListItemText style={{ marginLeft: "20px" }}>
              Bei allen mit einem * gekennzeichneten Lohnarten handelt es sich um feste, monatlich gleichbleibende Stammlohnarten. <br />
            </ListItemText>
          </ListItem>
        </Grid>



        <Grid item xs={1}> </Grid>
        <Grid item xs={11} >
          <Typography align="left">
            <strong>Bruttoblog Entgeltabrechnung Mai 2021</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={7}
            Lohnart={['1029', '1308', '1309', '1310', '1043', '1310', '1401']}
            Intern={['029', '308', '308', '308', '043', '310', '308']}
            Bezeichnung={["Gehalt*", "Tarifl.Zulage*", "Frw.Zulage*", "Schichtzulage", "Nachtarbeitszuschlag", "Prämie", "Meisterzulage"]}
            Tage={["21,75", "--", "--", "--", "--", "--", "20,00"]}
            Stunden={["152,25", "--", "--", "42,00", nachtstd, "--", "--"]}

            Faktor={[gehalt, tzulage, fzulage, nfaktor, nfaktor, prämie, "10,00"]}
            Kennung={["LFD", "LFD", "LFD", "LFD", "LFD", "EGA", "LFD"]}
            Prozent={["0,00", "0,00", "0,00", schichtproz, nachtproz, , "0,00"]}
            Steuerpflichtig={[gehalt, tzulage, fzulage, schichtzul, nzulagest, prämie, meisterzul]}
            Sozialverspflichtig={[gehalt, tzulage, fzulage, schichtzul, nzulagesv, prämie, meisterzul]}
            Betrag={[gehalt, tzulage, fzulage, schichtzul, nbetrag, prämie, meisterzul]}


            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={1}> </Grid>
        <Grid item xs={11} >
          <Typography align="left">
            <strong>Bruttoblog Entgeltabrechnung Juni 2021</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={5}
            Lohnart={['1029', '1308', '1309', '1310', '1043']}
            Intern={['029', '308', '308', '308', '043']}
            Bezeichnung={["Gehalt*", "Tarifl.Zulage*", "Frw.Zulage*", "Schichtzulage", "Nachtarbeitszuschlag"]}
            Tage={["21,75", "--", "--", "--", "--"]}
            Stunden={["152,25", "--", "--", "30,00", nachtstd1]}

            Faktor={[gehalt, tzulage, fzulage, nfaktor, nfaktor]}
            Kennung={["LFD", "LFD", "LFD", "LFD", "LFD"]}
            Prozent={["0,00", "0,00", "0,00", "20,00", nachtproz]}
            Steuerpflichtig={[gehalt, tzulage, fzulage, schichtzul1, nzulagest1]}
            Sozialverspflichtig={[gehalt, tzulage, fzulage, schichtzul1, nzulagesv1]}
            Betrag={[gehalt, tzulage, fzulage, schichtzul1, nbetrag1]}

            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={1}> </Grid>
        <Grid item xs={11} >
          <Typography align="left">
            <strong>Bruttoblog Entgeltabrechnung Juli 2021</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={7}
            Lohnart={['1029', '1308', '1309', '1310', '1043', '1310', '1401']}
            Intern={['029', '308', '308', '308', '043', '310', '308']}
            Bezeichnung={["Gehalt*", "Tarifl.Zulage*", "Frw.Zulage*", "Schichtzulage", "Nachtarbeitszuschlag", "Prämie", "Meisterzulage"]}
            Tage={["21,75", "--", "--", "--", "--", "--", "20,00"]}
            Stunden={["152,25", "--", "--", "50,00", nachtstdJuli, "--", ,]}

            Faktor={[gehalt, tzulage, fzulage, nfaktor, nfaktor, prämieJuli, "10,00"]}
            Kennung={["LFD", "LFD", "LFD", "LFD", "LFD", "EGA", "LFD"]}
            Prozent={["0,00", "0,00", "0,00", "20,00", nachtproz, , "0,00"]}
            Steuerpflichtig={[gehalt, tzulage, fzulage, schichtzulJuli, nzulagestJuli, prämie, meisterzul]}
            Sozialverspflichtig={[gehalt, tzulage, fzulage, schichtzulJuli, nzulagesvJuli, prämieJuli, meisterzulJuli]}
            Betrag={[gehalt, tzulage, fzulage, schichtzulJuli, nbetragJuli, prämieJuli, meisterzulJuli]}


            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '50px' }} justifyContent={"left"} alignItems={"left"} >
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#1fbba6", width: "100px", height: "70px" }}>
                  Aufgabe1
                </Avatar>
              </ListItemAvatar>
              <ListItemText style={{ marginLeft: "30px" }}>
                <Typography variant='h5'>
                  Bestimme die richtigen Lohnarten, die für die Ermittlung des Durchschnitts für den Soll-Aufschlag herangezogen werden sollen und berechne den durchschnittlichen Sollaufschlag für diese Lohnarten.
                  Trage die Lohnarten bitte nach aufsteigender Lohnart-Nr. ein.
                </Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <Tabelle5spaltigDropBoxSp1
              tabellenKey="1"
              anzReihen="5"
              ueberschrift="Vergütungsbetrag pro ausgewählte Lohnart pro Monat"
              uebschr_spalte1="Lohnart "
              spalte1Platz="center"
              uebschr_spalte2="Mai"
              spalte2Platz="right"
              uebschr_spalte3="Juni"
              spalte3Platz="right"
              uebschr_spalte4="Juli"
              spalte4Platz="right"
              uebschr_spalte5="Summe pro Loa"
              spalte5Platz="right"
              dropd={true}
              kenndrops={["1029-Gehalt", "1308-Tarifl.Zulage", "1309-Frw.Zulage", "1310-Schichtzulage", "1043-Nachtarbeitszuschlag", "1310-Prämie", "1401-Meisterzulage"]}

              spalten1={["", "", "Summe", "--", "--"]}
              spalten2={["", "", "--", "--", "--"]}
              spalten3={["", "", "--", "--", "--"]}
              spalten4={["", "", "--", "Teiler Anzahl Monate", "= Soll-Aufschlag"]}
              spalten5={["", "", "", "3", ""]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: '50px' }} justifyContent={"left"} alignItems={"left"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "50px", height: "50px" }}>
                A2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                <b>Der Bruttoblog ohne Berücksichtigung der Ausfallstunden sieht im Abrechnungsmonat August wie folgt aus.</b>
              </Typography>
            </ListItemText>
          </ListItem>

          <Grid item xs={1}></Grid>

          <Grid item xs={11} >
            <BruttoNeu2
              anzReihen={6}
              Lohnart={['1029', '1308', '1309', '1310', '1043', '1401']}
              Intern={['029', '308', '308', '308', '043', '308']}
              Bezeichnung={["Gehalt", "Tarifl.Zulage", "Frw.Zulage", "Schichtzulage", "Nachtarbeitszuschlag", "Meisterzulage"]}
              Tage={["21,75", "--", "--", "--", "--", "12,00"]}
              Stunden={["152,25", "--", "--", "28,00", nachtstdAugust, "--"]}

              Faktor={[gehalt, tzulage, fzulage, nfaktor, nfaktor, "10,00"]}
              Kennung={["LFD", "LFD", "LFD", "LFD", "LFD", "LFD"]}
              Prozent={["0,00", "0,00", "0,00", "20,00", nachtproz, "0,00"]}
              Steuerpflichtig={[gehalt, tzulage, fzulage, schichtzulAugust, nzulagest, meisterzulAugust]}
              Sozialverspflichtig={[gehalt, tzulage, fzulage, schichtzulAugust, nzulagesv, meisterzulAugust]}
              Betrag={[gehalt, tzulage, fzulage, schichtzulAugust, nbetragAugust, meisterzulAugust]}

              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={1} style={{ marginTop: '50px' }} justifyContent={"left"} alignItems={"left"} >
          <Grid item xs={12}>
            <Typography align="left" variant="h5">
              <b>Die Ermittlung der tatsächlichen Arbeitszeit und der durch KUG ausgefallenen Stunden des aktuellen Abrechnungsmonat erfolgt über die Zeiterfassung</b><br />
            </Typography>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#1fbba6", width: "50px", height: "50px" }}>
                  A2.1
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  Der Beispiel-Arbeitnehmer hat eine vertraglich geregelte Arbeitszeit von Montag bis Freitag mit 7,5 Stunden pro Tag.<br />
                  Aus dem Arbeitszeitkonto des Arbeitnehmers für August gehen die tatsächlichen Arbeits- und Ausfallstunden während KUG hervor.
                </Typography>
              </ListItemText>
            </ListItem>
          </Grid>

          <Grid item xs={1}> </Grid>

          <Grid item xs={10} >
            <Tabelle8spaltig
              anzReihen="31"
              ueberschrift="Arbeitszeitkonto August"
              uebschr_spalte1="Tag"
              spalte1Platz="center"
              uebschr_spalte2="Soll-Arbeitszeit"
              spalte2Platz="right"
              uebschr_spalte3="IST-Arbeitszeit"
              spalte3Platz="right"
              uebschr_spalte4="KUG-Stunden/Ausfallzeit"
              spalte4Platz="right"
              uebschr_spalte5="Schichtzulage"
              spalte5Platz="right"
              uebschr_spalte6="Nachzuschlag"
              spalte6Platz="right"
              uebschr_spalte7="Meisterzulage"
              spalte7Platz="right"
              tabhoehe={700}
              spalten1={txtwtage}
              spalten2={sollzeit}
              spalten3={istzeit}
              spalten4={kugstd}
              spalten5={schichtzulAZ}
              spalten6={nachzzulageAZ}
              spalten7={meisterzulAZ}
              spalten8=""
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />

            <Tabelle8spaltig
              anzReihen="1"
              ueberschrift="Zusammenfassung Arbeitszeitkonto August"
              uebschr_spalte1="Summen "
              spalte1Platz="center"
              uebschr_spalte2="Soll-Arbeitszeit"
              spalte2Platz="right"
              uebschr_spalte3="IST-Arbeitszeit"
              spalte3Platz="right"
              uebschr_spalte4="KUG-Stunden/Ausfallzeit"
              spalte4Platz="right"
              uebschr_spalte5="Schichtzulage"
              spalte5Platz="right"
              uebschr_spalte6="Nachzuschlag"
              spalte6Platz="right"
              uebschr_spalte7="Meisterzulage"
              spalte7Platz="right"

              spalten1={["--"]}
              spalten2={["165,00"]}
              spalten3={["117,50"]}
              spalten4={["47,50"]}
              spalten5={["28,00"]}
              spalten6={["21,00"]}
              spalten7={["12,00"]}
              spalten8=""
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />

          </Grid>

          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#1fbba6", width: "50px", height: "50px" }}>
                  A2.2
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  Anhand dem Arbeitszeitkonto kann nun das Ausfallentgelt berechnet werden. <br />
                  Bei einem Gehaltsempfänger werden für die Berechnung die verstetigten, monatlich gleichbleibenden Lohnarten herangezogen.
                </Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6} >
            <Tabelle2spaltig
              tabellenKey="2"
              anzReihen="3"
              ueberschrift="Ermittlung Ausfallentgelt"
              uebschr_spalte1="Lohnart"
              spalte1Platz="left"
              uebschr_spalte2="Betrag"
              spalte2Platz="right"
              spalten1={["Gehalt", "Tarifl.Zulage", "Frw.Zulage"]}
              spalten2={["", "", ""]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
            <Tabelle2spaltig
              tabellenKey="3"
              anzReihen="4"
              ueberschrift="Berechnung Ausfallentgelt"
              uebschr_spalte1="Text"
              spalte1Platz="left"
              uebschr_spalte2="Betrag"
              spalte2Platz="right"
              spalten1={["Summe der Lohnarten", "multipliziert mit den Ausfallstunden von", "geteilt durch MonatsSollArbeitszeit von", "ergibt ein Ausfallentgelt von"]}
              spalten2={["", "", "", ""]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />

          </Grid>
          <Grid item xs={5}></Grid>



          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#1fbba6", width: "50px", height: "50px" }}>
                  A2.3
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  Nach der Berechnung des Ausfall-Entgelts kann das Soll-Entgelt und das Ist-Entgelt berechnet werden.<br />
                  Trage die Lohnarten bitte nach aufsteigender Lohnart-Nr. ein.
                </Typography>
              </ListItemText>
            </ListItem>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={4} >
            <Tabelle2spaltigDropBoxSp1
              tabellenKey="4"
              anzReihen="5"
              ueberschrift="Berechnung Soll-Entgelt"
              uebschr_spalte1="Lohnart "
              spalte1Platz="left"
              uebschr_spalte2="Betrag"
              spalte2Platz="right"
              dropd={true}
              kenndrops={["1029-Gehalt", "1308-Tarifl.Zulage", "1309-Frw.Zulage", "1310-Schichtzulage", "1043-Nachtarbeitszuschlag", "1310-Prämie", "1401-Meisterzulage","1968-KUG-Sollaufschlag"]}

              spalten1={["", "", "", "", "= Soll-Entgelt"]}
              spalten2={["", "", "", "", "", ""]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} >
            <Tabelle2spaltigDropBoxSp1
              tabellenKey="5"
              anzReihen="7"
              ueberschrift="Berechnung Ist-Entgelt"
              uebschr_spalte1="Lohnart "
              spalte1Platz="left"
              uebschr_spalte2="Betrag"
              spalte2Platz="right"
              dropd={true}
              kenndrops={["1029-Gehalt", "1308-Tarifl.Zulage", "1309-Frw.Zulage", "1310-Schichtzulage", "1043-Nachtarbeitszuschlag", "1310-Prämie", "1401-Meisterzulage","1969-Ausfallentgelt"]}

              spalten1={["", "", "", "", "", "", "= Ist-Entgelt"]}
              spalten2={["", "", "", "", "", "", ""]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={1}></Grid>



          <Grid container spacing={1} style={{ marginTop: '50px' }} justifyContent={"left"} alignItems={"left"} >

            <Grid item xs={1}> </Grid>
            <Grid item xs={11} >
              <ListItem >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#1fbba6", width: 50, height: 50 }}>
                    A2.4
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="h5">
                    <strong>Nun wird mit beiden Werten (Soll-Entgelt und Ist-Entgelt) in der im jeweiligen Abrechnungszeitraum gültigen KUG-Tabelle die rechnerischen Leistungssätze ermittelt. </strong><br />
                  </Typography>
                  <Typography variant="h6">
                    Die abzurechnende Person hat folgende Abrechnungsmerkmale:<br/>
                    Steuerklasse = 1 / kein Kinderfreibetrag / Konfession = ev.<br/>
                  </Typography>
                  <Typography>
                    Die für den Abrechnungszeitraum gültige KUG-Tabelle bekommst mit dem Klick auf den Button "KUG-Tabelle 2021"-
                  </Typography>
                </ListItemText>
              </ListItem>

              <Grid item xs={11} >
                <Button
                  color="primary"
                  variant="contained"
                  alignItems="center"
                  backgroundColor="#4F4F4F"
                  style={{ align: "center" }}
                  onClick={() => window.open("https://www.arbeitsagentur.de/datei/berechnung-des-kurzarbeitergeldes-2021-67-60-prozent-_ba146763.pdf")}
                >
                  KUG-Tabelle 2021
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={1}> </Grid>
            <Grid item xs={10}>
              <Tabelle3spaltig
                tabellenKey="6"
                anzReihen="1"
                ueberschrift="Ermittlung KUG-Leistungssätze gem. KUG-Tabelle"
                uebschr_spalte1="Leistungssatz Soll-Entgelt"
                spalte1Platz="center"
                uebschr_spalte2="minus Leistungssatz Ist-Entgelt"
                spalte2Platz="center"
                uebschr_spalte3="= KUG-Geld"
                spalte3Platz="center"
                spalten1={[""]}
                spalten2={[""]}
                spalten3={[""]}
                setzeAntworten={setzeAntworten}
                antworten={antworten}
                fehlerliste={fehlerliste}
              />
            </Grid>
            <Grid item xs={1}> </Grid>



          </Grid>




          <Grid item xs={12} style={{marginTop:"40px"}}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#1fbba6", width: "50px", height: "50px" }}>
                  A2.5
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  Nachdem alles berechnet wurde, kannst Du den Bruttoblog auf der Entgeltabrechnung korrekt eingeben. <br />
                </Typography>
              </ListItemText>
            </ListItem>

            <BruttoNeu2
              anzReihen={9}
              tabellenKey="7"
              Lohnart={['1029', '1308', '1309', '1310', '1043', '1401', '1961', '1968', '1969']}
              Intern={['029', '308', '308', '308', '043', '401', '961', '968', '969']}
              Bezeichnung={["Gehalt*", "Tarifl.Zulage*", "Frw.Zulage*", "Schichtzulage", "Nachtarbeitszuschlag", "Meisterzulage", "KUG-Geld", "KUG Sollaufschlag", "Ausfallentgelt"]}
              Tage={["21,75", "--", "--", "--", "--", "", , ,]}
              Stunden={["152,25", "--", "--", "", "", , "", , ""]}
              Faktor={[gehalt, tzulage, fzulage, nfaktor, nfaktor, "10,00", "", ""]}
              Kennung={["LFD", "LFD", "LFD", "LFD", "LFD", "LFD",,,"LFD"]}
              Prozent={["0,00", "0,00", "0,00", "20,00", nachtproz, , ,]}
              Steuerpflichtig={[gehalt, tzulage, fzulage, "", "--", "", "", , ""]}
              Sozialverspflichtig={[gehalt, tzulage, fzulage, "", "--", "", "", , ""]}
              Betrag={[gehalt, tzulage, fzulage, "", "", "", "", , "",]}
              setzeAntworten={setzeAntworten}
              antworten={antworten}
              fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

      </Grid>
      <Kursabgeben anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />

    </div>
  )
}


export default Kurzarbeit2;
