import React, {useState, useCallback} from 'react';
import {Avatar, Checkbox, FormControlLabel, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import {Kursabgeben} from '../Rahmendaten/Kursabgeben';
import {BruttoNeu2} from '../Rahmendaten/BruttoNeu2';
import {BruttoNettoBlock} from './../Rahmendaten/BruttoNettoBlock'
import {Beitragssaetze} from './../Rahmendaten/Beitragssaetze'
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'


function SonstEinnahmeFW(props) {
  const { accessToken, hsutkey } = props;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [testabgabe, setTestabgabe]=useState(false);
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('039');
  const [merkmal]=useState("§23cSGBIVFW");

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
//    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
//    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
//   console.log({antworten})

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
 //   console.log(response.data.message);

    if (response.status === 200) {
        setTestabgabe(true);
        fehlerAusLesen(response.data.fehler,response.data.anzfehler)
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
} else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }


  return(    
  <div>
    <Briefkopf  
        ueberschrift1="§23c SGB IV - Sonstige beitragspflichtige Einnahme" 
        ueberschrift2="Teil 1: Anwendung § 23c SGB IV bei geldwerten Vorteilen (Firmenwagen)" 
    />

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              ?
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>
              In dem vorherigen Kurs hast Du die Handhabung des § 23c SGB IV bei Zahlung von Krankengeldzuschuss durch den Arbeitgeber kennen gelernt. <br/>
              Wie verhält es sich aber, wenn der Arbeitnehmer während dem Bezug von Krankengeld (also keine Lohnfortzahlung durch den Arbeitgeber) Firmeneigentum weiterhin benutzen darf und dies auch tut?
            </strong>
          </ListItemText> 
        </ListItem>
      </Grid>

      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Bsp.
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Sachverhalt <br/> </strong>
              Eine Arbeitnehmerin fährt einen Firmenwagen. <br/>
              Der Bruttolistenpreis des Firmenwagens beträgt 75000,- Euro. <br/>
              Bei der Arbeitnehmerin wird für die Versteuerung und Verbeitragung des geldwerten Vorteils die 1%-Methode und für die Fahrten Wohnung-Arbeitsstätte die 0,03% Methode angewendet. <br/>
              Die Entfernung von der Wohnung zur regelmäßigen Arbeitsstätte beträgt 12 Km.<br/>
              Somit sehen die Bruttowerte auf der Entgeltabrechnung der Arbeitnehmerin für einen Abrechnungsmonat ohne besondere Vorkommnisse wie folgt aus:
          </ListItemText> 
          <Tabelle2spaltig 
   //*       tabellenKey="1" 
          anzReihen="7"
          ueberschrift="Entgeltabrechnungsrelevante Daten der Arbeitnehmerin"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Wert"
          spalte2Platz="right"
          spalten1={["Steuerklasse","Faktor","Anzahl Kinder","Konfession","Krankenversicherung","Zusatzbeitrag der KK", "Geburtsjahr"]}
          spalten2={["Vier","0,00","2","ev.","gesetzlich","1,50%","1971"]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
        </ListItem>
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog Entgeltabrechnung Juli 2021 ohne besondere Vorkommnisse
        </Typography>
        <BruttoNeu2
              anzReihen={4}
//*              tabellenKey="1" 
              Lohnart={['1029','1350','1355']}
              Intern={['029','350','355']}
              Bezeichnung={["Gehalt","Priv.KfZ-Nutzg*","Fahrten Whn-Arb*"]}
              Tage={["21,75","--","--","--"]}
              Stunden={["152,25","--","--","--"]}
              Faktor={["3000,00","750,00","270,00"]}
              Kennung={["LFD","LFD","LFD",]}
              Prozent={["0,00","0,00","0,00"]}
              Steuerpflichtig={["3000,00","750,00","270,00"]}
              Sozialverspflichtig={["3000,00","750,00","270,00"]}
              Betrag={["3000,00","750,00","270,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={1}>*</Grid>
      <Grid item xs={10} >
        <Typography align="left">
            Lohnart 1350 Priv.KfZ-Nutzg = geldwerter Vorteil für die Nutzung des Firmenwagens <br/>
            Lohnart 1355 Fahrten Whn-Arb = geldwerter Vorteil für die Fahrten von der Wohnung zur Arbeitsstätte mit dem Firmenwagen<br/>
        </Typography>
      </Grid>

    </Grid>
  

  <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
    <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Bsp.
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Weiterer Sachverhalt <br/></strong>
              Die Arbeitnehmerin erkrankt ab 12.08.2021 für einen längeren Zeitraum. <br/>
              Die Lohnfortzahlung endet somit am 22.09.2021<br/>
              Entsprechend einer Vereinbarung darf die Arbeitnehmerin während der Krankheit das Firmenfahrzeug weiterhin nutzen.
              Auch über das Ende der Lohnfortzahlung hinaus.<br/>
              Ab 23.09. bekommt sie Krankengeld von ihrer Krankenkasse<br/>
          </ListItemText> 
          <Tabelle2spaltig 
    //*      tabellenKey="1" 
          anzReihen="2"
          ueberschrift="Von der Krankenkasse übermittelte Krankengeldberechnung"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Wert"
          spalte2Platz="right"
          spalten1={["kalendertägliches brutto Krankengeld","kalendertägliches netto Krankengeld"]}
          spalten2={["93,80","75,39"]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />

        </ListItem>
      </Grid>
    </Grid>


    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Info
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
              <strong>Beitragssätze 2021</strong><br/>

          </ListItemText> 
        </ListItem>
        <Beitragssaetze 
//*              tabellenKey="1" 
              kvbeitragssatz="14,60 %"
              rvbeitragssatz="18,60 %"
              avbeitragssatz="2,40 %"
              pvbeitragssatz="3,05 %"
              pvelternsatz="0,25 %"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />

      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Ermittle das Vergleichsnetto für den Monat Julli 2021, da die Krankheit im Monat 08/2021 begann. <br/>
          Für die Ermittlung der Lohnsteuer verwende bitte die Homepage "https://www.bmf-steuerrechner.de/index.xhtml" des Bundesministerium für Finanzen. <br/>
        </Typography>
        <BruttoNettoBlock 
              tabellenKey="1"
              svFarbe=""
              bruttolfd='' 
              bruttoega='' 
              bruttogesamt='' 
              steuern=""
              sozvers=""
              agantAusz="0,00" 
              netto="" 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              3.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Berechne nun den Freibetrag zum Krankengeld</strong><br/>
            Verwende für die Berechnung immer einen ganzen Monat
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={7} >
        <Tabelle2spaltig 
          tabellenKey="1" 
          anzReihen="3"
          ueberschrift="Ermittlung Monatsfreibetrag"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Betrag"
          spalte2Platz="right"
          spalten1={["ermitteltes Vergleichsnetto (Monat Juli)","abzgl. ermittelte KK-Leistung (Nettokrankengeld x 30","=Monatsfreibetrag"]}
          spalten2={["","",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              3.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Wenn das Krankengeld nicht für den ganzen Monat, sondern nur für einen Teilmonat bezahlt wurde, ist der Freibetrag für den Teilmonat entsprechend zu kürzen</strong><br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={7} >
        <Tabelle2spaltig 
          tabellenKey="2" 
          anzReihen="4"
          ueberschrift="Freibetragsermittlung nach Teilmonat"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Wert"
          spalte2Platz="right"
          spalten1={["Monatsfreibetrag","multipliziert mit SV-Tage des Krankengeldbezuges"," geteilt durch SV-Tage des Monats","tatsächlicher (anteiliger) Freibetrag"]}
          spalten2={[antworten["spalt212"],"","",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
  </Grid>

  <Grid container spacing={3} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              4.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            Ansicht des Bruttoblogs mit Berechnung gem. §23c SGB IV .<br/>
          </ListItemText> 
        </ListItem>
      </Grid>


      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog einer Entgeltabrechnung 09/2021, bei der die Lohnfortzahlung am <strong>22.09.2021</strong> endet.<br/>
        </Typography>
        <BruttoNeu2
              anzReihen={4}
              tabellenKey="1" 
              Lohnart={['1029','1350',"1781","0783"]}
              Intern={['029','350','781','783']}
              Bezeichnung={["Gehalt","Priv.KfZ-Nutzg*",'KK-Leistung*','Freibtr.gem23c*']}
              Tage={["15,82","--","--","--"]}
              Stunden={["110,73","--","--","--"]}
              Faktor={["2181,82","750,00","75,39",""]}
              Kennung={["LFD","LFD","--","--"]}
              Prozent={["0,00","0,00","0,00","0,00"]}
              Steuerpflichtig={["2181,82","750,00","0,00",""]}
              Sozialverspflichtig={["2181,82","750,00","0,00",""]}
              Betrag={["2181,82","750,00","0,00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={1}>*</Grid>
      <Grid item xs={10} >
        <Typography align="left">
            Lohnart 1350 Priv.KfZ-Nutzg = geldwerter Vorteil für die Nutzung des Firmenwagens <br/>
            Lohnart 1781 KK-Leistung = von der Krankenkasse pro Kalendertag übermitteltes netto Krankengeld<br/>
            Lohnart 0783 Freibtr.gem23c = anteiliger Freibetrag gem. § 23c SGB IV<br/>
            <strong>Die Lohnart 1355 Fahrten Whn-Arb braucht nicht mehr abgerechnet zu werden, da in dem Abrechnungsmonat die Arbeitnehmerin aufgrund ihrer ganzmonatiger Krankheit kein einziges Mal mit dem Firmenwagen in die Firma gefahren ist
            und somit kein geldwerter Vorteil für die Fahrten von der Wohnung zur Arbeitsstätte entstanden sind. </strong> <br/>
        </Typography>
      </Grid>
  </Grid>
  <Grid container spacing={3} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              gwV
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            Berechnung des anteiligen geldwerten Vorteils für den Zeitraum des Krankengeldbezuges.<br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={5} >
        <Tabelle2spaltig 
          tabellenKey="3" 
          anzReihen="4"
          ueberschrift="Geldwerte Vorteil für den Firmenwagen während Krankengeldbezug"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Wert"
          spalte2Platz="right"
          spalten1={["Geldwerte Vorteile Firmenwagen 1%-Methode","SV-Tage Krankengeldbezug","Soll-SV-Tage ganzer Monat","anteiliger geldwerte Vorteil während Krankengeldbezug"]}
          spalten2={["750,00","","",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
  </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>      
  </div>

    )



  }

export default SonstEinnahmeFW;