import React from 'react';
import {Grid, Typography,TextField,MenuItem} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function Meldung (props) {
  const {tabellenKey,MeldeHinweis,Von,Bis,StaatsA,PersGruppe,Mehrfach,Betriebstätte,Tätigkeit,KV,AV,PV,RV, fehlerliste, setzeAntworten, antworten,text, NLohnart, NIntern, NBezeichnung, NBetrag, anzReihen} = props;
    let vonDatblockiert=false;
    let bisDatblockiert=false;
    let StaatsAblockiert=false;

    if (MeldeHinweis!==""){
        antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (Von!==""){
      antworten["Von"+tabellenKey]=Von;
      vonDatblockiert=true
    }
    if (Bis!==""){
      antworten["Bis"+tabellenKey]=Bis;
      bisDatblockiert=true
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }
    if (MeldeHinweis!==""){
      antworten["MeldeHinweis"+tabellenKey]=MeldeHinweis
    }


console.log(antworten["Von"+tabellenKey])

  return(
<Grid container spacing={1} style={{marginTop:'30px',  textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
  <Grid item xs={2} style={{}}>
    <Typography style={{float:'left',lineHeight:"32px"}}>
      Besonderer Meldehinweis:   
    </Typography>
  </Grid>
  <Grid item xs={10} style={{}}>
    <TextField  id="standard-basic" label="" placeholder="" fullWidth  style={{display:"flex",borderTop:"solid",borderRight:"solid",borderLeft:'solid', borderBottom:'solid'}}onChange={(event) => setzeAntworten('bMHw',event.target.value)}/>
  </Grid>
  <Grid item xs={12} style={{fontWeight:'bold'}}>
    <p style={{float:'left',fontSize:"1,5em"}}>
        Beschäftigungszeit
    </p>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography >
       Von
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
   <Typography>
     Bis
   </Typography>
  </Grid>
  <Grid item xs={6} style={{}}>
   <Typography>
     Personengruppe 
   </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
   <Typography>
     Mehrfachbeschäftigt 
   </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
  <div style={{}}>
  <TextField 
      disabled={vonDatblockiert}
      type='date' 
      id="von" 
      variant="outlined" 
      label="Von " 
      value={antworten["Von"+tabellenKey]}
      error={fehlerliste["Von"]}
      margin='normal'
      placeholder=""  style={{display:"", backgroundColor:(fehlerliste["Von"]===true)?"red":""}}onChange={(event) => setzeAntworten('Von',event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }}/>              </div>
  </Grid>
  <Grid item xs={2} style={{}}>
  <div style={{}}>
  <TextField  
      disabled={bisDatblockiert}
      type='date' 
      id="Bis" 
      variant="outlined" 
      error={fehlerliste["Bis"]}
      label="Bis" 
      margin='normal'
      placeholder=""  style={{display:""}}onChange={(event) => setzeAntworten('Bis',event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}/>    
   </div>
  </Grid>
  <Grid item xs={6} style={{}}>
    <TextField
      select // durch select wird DropDown initialisiert
      id="PG"
      label="Personengruppe auswählen "
      fullWidth
      error={fehlerliste["PG"]}
      value={antworten['PG']||''}
      onChange={(event) => setzeAntworten('PG',event.target.value)}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
         shrink: true,
         style:{color:"#1fbba6"}

      }} >
        <MenuItem value={101}>101= SV-pfl. Beschäftigte ohne bes. Merkmal</MenuItem>
        <MenuItem value={102}>102= Auszubildende ohne besondere Merkmale bzw. Auszubildende ohne Arbeitsentgelt</MenuItem>
        <MenuItem value={103}>103= Beschäftigte in Altersteilzeit</MenuItem>
        <MenuItem value={105}>105= Praktikanten</MenuItem>
        <MenuItem value={106}>106= Werkstudenten</MenuItem>
        <MenuItem value={109}>109= Geringfügig entlohnte Beschäftigte nach § 8 Absatz 1 Nummer 1 SGB IV</MenuItem>
        <MenuItem value={110}>110= Kurzfristig Beschäftigte nach § 8 Absatz 1 Nummer 2 SGB IV</MenuItem>
        <MenuItem value={119}>119= Versicherungsfreie Altersvollrentner und Versorgungsbezieher wegen Alters</MenuItem>
        <MenuItem value={120}>120= Versicherungspflichtige Altersvollrentner 1)</MenuItem>
        <MenuItem value={121}>121= Auszubildende, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={122}>122= Auszubildende in einer außerbetrieblichen Einrichtung (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={123}>123= Personen, die ein freiwilliges soziales, ein freiwilliges ökologisches Jahr oder einen Bundesfreiwilligendienst leisten (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={124}>124= Heimarbeiter ohne Anspruch auf Entgeltfortzahlung im Krankheitsfall</MenuItem>
        <MenuItem value={140}>140= Seeleute</MenuItem>
        <MenuItem value={144}>144= Auszubildende in der Seefahrt, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={150}>150= In der Seefahrt beschäftigte versicherungspflichtige Altersvollrentner 1)</MenuItem>
        <MenuItem value={190}>190= Beschäftigte, die ausschließlich in der gesetzlichen Unfallversicherung versichert sind</MenuItem>
    </TextField>          
  </Grid>
  <Grid item xs={2} style={{}}>
    <TextField
      select // durch select wird DropDown initialisiert
      id="Mb"
      label="Mehrbeschäftigung?"
      fullWidth
      error={fehlerliste["Mb"]}
      value={antworten['Mb']||''}
      onChange={(event) => setzeAntworten('Mb',event.target.value)}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={10}>JA</MenuItem>
      <MenuItem value={20}>NEIN</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={4} style={{}}>
    <Typography style={{}}>
      Beitragsgruppen
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography style={{}}>
      Angaben zur Tätigkeit
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography style={{}}>
      Schlüssel der Staatsangehörigkeit
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography style={{}}>
      Betriebsstätte
    </Typography>
  </Grid>          
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      KV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      RV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      AV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      PV
    </Typography>
  </Grid>
  <Grid item xs={8}>

  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="KV"
      label="KV auswählen "
      fullWidth
      error={fehlerliste["KV"]}
      value={antworten['KV']||''}              
      onChange={(event) => setzeAntworten('KV',event.target.value.toString())}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={0}>0 kein Beitrag</MenuItem>
      <MenuItem value={1}>1 allgemeiner Beitrag</MenuItem>
      <MenuItem value={2}>2 erhöhter Beitrag (nur für Meldezeitraum bis 31.12.2008 zulässig)</MenuItem>
      <MenuItem value={3}>3 ermäßigter Beitrag</MenuItem>
      <MenuItem value={4}>4 Beitrag zur landwirtschaftlichen KV</MenuItem>
      <MenuItem value={5}>5 Arbeitgeberbeitrag zur landwirtschaftlichen KV</MenuItem>
      <MenuItem value={6}>6 Pauschalbetrag für gerinfügig Beschäftigte</MenuItem>
      <MenuItem value={9}>9 Firmenzahler</MenuItem>

    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="RV"
      label="RV auswählen "
      fullWidth
      error={fehlerliste["RV"]}
      value={antworten['RV']||''}
      onChange={(event) => setzeAntworten('RV',event.target.value.toString())}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
        <MenuItem value={0}>0 kein Beitrag</MenuItem>
        <MenuItem value={1}>1 voller Beitrag</MenuItem>
        <MenuItem value={3}>3 halber Beitrag</MenuItem>
        <MenuItem value={5}>5 Pauschalbetrag für gerinfügugig Beschäftigte</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="AV"
      label="AV auswählen "
      fullWidth
      error={fehlerliste["AV"]}
      value={antworten['AV']||''}
      onChange={(event) => setzeAntworten('AV',event.target.value.toString())}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
        <MenuItem value={0}>0 kein Beitrag</MenuItem>
        <MenuItem value={1}>1 voller Beitrag</MenuItem>
        <MenuItem value={2}>2 halber Beitrag</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="PV"
      label="PV auswählen "
      fullWidth
      error={fehlerliste["PV"]}
      value={antworten['PV']||''}
      onChange={(event) => setzeAntworten('PV',event.target.value.toString())}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={0}>0 kein Beitrag</MenuItem>
      <MenuItem value={1}>1 voller Beitrag</MenuItem>
      <MenuItem value={2}>2 halber Beitrag</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={3} >
  
    <TextField  
      id="angabenT" 
      variant="outlined" 
      label="Tätigkeit angeben" 
      error={fehlerliste["angabenT"]}
      margin='normal'
      fullWidth
      placeholder=""  style={{display:""}}onChange={(event) => setzeAntworten('angabenT',event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}/>       
  
  </Grid>
  <Grid item xs={3} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="StaatA"
      label="Staatsangehörigkeit auswählen "
      fullWidth
      error={fehlerliste["StaatA"]}
      value={antworten['StaatA']||''}
      onChange={(event) => setzeAntworten('StaatA',event.target.value)}
      variant="outlined"
//         onChange={(event) => setLiga(event.target.value)} 
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={"000"}>000 = deutsch</MenuItem>
      <MenuItem value={"129"}>129 = französisch</MenuItem>
      <MenuItem value={"148"}>148 = niederländisch</MenuItem>      
      <MenuItem value={"161"}>161 = spanisch</MenuItem>
      <MenuItem value={"163"}>163 = türkisch</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={2} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="BS"
      label="Betriebsstätte auswählen "
      fullWidth
      error={fehlerliste["BS"]}
      value={antworten['BS']||''}
      onChange={(event) => setzeAntworten('BS',event.target.value)}
      variant="outlined"
//         onChange={(event) => setLiga(event.target.value)} 
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={10}>West</MenuItem>
      <MenuItem value={20}>Ost</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Beitragspflichtiges Bruttoarbeitsentgelt</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Währung</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>
      Betrag
     </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>
       Statuskennzeichen
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>beitragspflichtiges Bruttoarbeitsentgelt</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
      <TextField disabled id="waehrung" label="" error={fehlerliste["waehrung"]} placeholder=""  style={{display:"flex", marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('waehrung',event.target.value)}/>
  </Grid>
  <Grid item xs={3} style={{}}>
      <TextField disabled id="betrag" label="" placeholder="" error={fehlerliste["betrag"]}  style={{display:"flex",marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('betrag',event.target.value)}/>
  </Grid>
  <Grid item xs={3} style={{}}>
      <TextField disabled id="Statusk" label="" error={fehlerliste["Statusk"]} placeholder=""  style={{display:"flex",marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('Statusk',event.target.value)}/>
  </Grid>
</Grid>

  );

}

export {Meldung};