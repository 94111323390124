import React, { useEffect } from 'react';

import {Grid, Typography, TextField, Avatar, MenuItem, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';



function TabelleRabatt (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten, aufgabe, titel} = props;

  return(

    <Grid container spacing={1} style={{justify:"center",  alignItems:"center", textAlign:'left'}} >      
        <Grid Item xs={12}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  {aufgabe}
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                {titel}
              </ListItemText>    
          </ListItem>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                Jahres-Bruttowarenwert
            </Typography>
        </Grid>
        <Grid item xs={1}>
            <TextField 
                type='number' 
                id={"bruttoware"+tabellenKey}  
                error={fehlerliste["bruttoware"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('bruttoware'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                abzüglich 4% pauschaler Abschlag
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"abschlag"+tabellenKey}  
                error={fehlerliste["abschlag"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('abschlag'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3} style={{borderBottom:"solid"}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid"}}>
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                verbleiben
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"verbleiben"+tabellenKey}  
                error={fehlerliste["verbleiben"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('verbleiben'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                abzgl. vom Arbeitnehmer bezahlt
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"arbnbezahlt"+tabellenKey}  
                error={fehlerliste["arbnbezahlt"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('arbnbezahlt'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                = geldwerter Vorteil
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"gewvorteil"+tabellenKey}  
                error={fehlerliste["gewvorteil"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('gewvorteil'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                abzüglich Rabattfreibetrag
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"rabattfreibetrag"+tabellenKey}  
                error={fehlerliste["rabattfreibetrag"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('rabattfreibetrag'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                = steuerpflichtiger geldwerter Vorteil
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"stpflgewvorteil"+tabellenKey}  
                error={fehlerliste["stpflgewvorteil"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}
                onChange={(event) => setzeAntworten('stpflgewvorteil'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        


  </Grid>


  )
}

export {TabelleRabatt};