import React from 'react';
import {Grid, Typography } from '@material-ui/core';
//import BannerHeader from '/media/Akademie_Header_Banner.svg';
//import {ReactComponent as LogoAkademie} from '/media/Logo_ectool_Akademie.svg';



function Briefkopf (props) {
  const {ueberschrift1, ueberschrift2} = props;



    const ausgabeUeberschrift2=ueberschrift2.split("^").map((objekt, count)=>{
        return (
            <Typography variant="h5" key={count} >
                {objekt}
            </Typography>
        )
    }
    )
//       <img src={BannerHeader} height="350" width="700" alt="ectool" loading="lazy"/>

return(

    <Grid container spacing={1} style={{marginTop:'5px', marginBottom:"5px", borderBottom:"solid", borderColor:"#1fbba6" }} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={2} >
            <img src="/Logo_ectool_Akademie.svg" alt="ectool" loading="lazy" />
        </Grid> 

        <Grid item xs={10} style={{textAlign:"left", borderLeft:"solid", borderColor:"#1fbba6"}}>
            <Typography variant="h2" color="primary">
                {ueberschrift1}
            </Typography>
            {ausgabeUeberschrift2}
        </Grid> 
        <Grid item xs={12}>
            <img src="/Akademie_Header_Banner.svg" style={{height:"100px"}} alt="ectool" loading="lazy"/>
        </Grid> 
    </Grid>
)
}

export {Briefkopf};