import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {KursAufgabenErledigt} from '../../components/Rahmendaten/KursAufgabenErledigt';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";
import ForwardIcon from '@mui/icons-material/Forward';

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckMai3 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('835');
    const [kurskennung, setKurskennung]=useState("ScheckMai3");
    const [merkmal]=useState('');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);
        }
      }
    }

    const antwortenSenden = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "aufgabeerl",
        "merkmal": merkmal,
        "daten": antworten,
        "reactnummer": reactnummer,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'aufgabeerl', payload, headers, kurseCb, setRedirect);
  
    }
  
    const kurseCb = (response) => {
      if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);

      } else {
        if (response) {
          props.showError(response.data.message);
        } else {
          props.showError('Keine Internetverbindung');
        }
      }
    }

    const DokumentOeffnen = (docnr, docbez, docendung) => {
      const payload={
        "kennung": "kursdaten",
        "kurskennung": "download_lohndaten",
        "reactnummer": reactnummer,
        "docnr":docnr,
        "docname": docbez,
        "hsutkey": hsutkey
      }
      const headers={
        "Authorization": "Bearer "+accessToken,
        "Accept": "application/pdf"
      }
      axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
        .then((response) => {
          if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
          var contentFile = response.data;
          const mimeType = response.headers['content-type'] || 'application/pdf'
          openFile(fileName, contentFile,mimeType);
        })
        .catch(function (error) {
          showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
        })
    }

return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im neunten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h4' align="left">
            <b>bAV</b>betriebliche Altersvorsorge <br/>
            Wer sich hier auskennt, liegt vorne. 
        </Typography>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>
    <Fragment>
    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                <b>Aufgabe</b> <br/>
                Nachdem Du das Handbuch bAV durchgearbeitet hast, solltest Du die abgerechneten bAVs auf den beigefügten Abrechnungen Deiner Teamleiterin bzw. Deinem Teamleiter erklären können. <br/>
                Hierzu bitte eine eigene Erklärung bzw. ein geeignetes Schema erstellen. <br/>
                Die BAVs auf den Abrechnungen sind jährliche bAV's, d.h. sie werden einmal im Jahr im Juni abgerechnet.<br/><br/>
                Schenke bitte den folgenden Themen besondere Beachtung bei Deinen Erklärungen bzw. bei Deinem Schema.
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'10px'}} >
        <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px"}}>
                        <ForwardIcon/>
                    </Avatar> 
                </ListItemAvatar>
                <ListItemText style={{marginLeft:"30px"}}>
                    <Typography variant='h5' >
              	        Welche Arten von bAV's gibt es
                    </Typography>
                </ListItemText>
            </ListItem>
        </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'10px'}} >
        <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px"}}>
                        <ForwardIcon/>
                    </Avatar> 
                </ListItemAvatar>
                <ListItemText style={{marginLeft:"30px"}}>
                    <Typography variant='h5' >
              	        Arbeitgeberfinanziert und/oder Umwandlung beim Arbeitnehmer
                    </Typography>
                </ListItemText>
            </ListItem>
        </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'10px'}} >
        <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px"}}>
                        <ForwardIcon/>
                    </Avatar> 
                </ListItemAvatar>
                <ListItemText style={{marginLeft:"30px"}}>
                    <Typography variant='h5' >
              	        Besonderheiten
                    </Typography>
                </ListItemText>
            </ListItem>
        </Grid>
    </Grid>

    </Fragment>

  {
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={6}>
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDraufHast}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Fehlerhafte Abrechnungen in einem neuen Tab anzeigen!
                </Button>

              </Card>
            </Grid>
          )
        })
    }

    <Grid style={{display: 'flex', marginTop:'60px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                <b>Aufgabe 2</b> <br/>
                Nachdem Du das Schema erstellt hast, widme Dich bitte der Bearbeitung des Abrechnungsmonats Mai Deines Schulungsmandanten. <br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemText>
            <Typography variant='h5' align='center'>
                <b>Viel Erfolg!</b>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>


    <KursAufgabenErledigt  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
    </div>
 
    )
  }

export default ScheckMai3;