import React from 'react';
import {Grid, MenuItem, TextField, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';





function BetragEingeben (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, betragbez, betragswert } = props;

    if (betragbez !== '') {
        antworten["betragbez"+tabellenKey]=betragbez;
        var betragbezblockiert=true;
    }

    if (betragswert !== '') {
        antworten["betragswert"+tabellenKey]=betragswert;
        var betragswertblockiert=true;
    }



return(
    <div>

    <Grid container spacing={2} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={10}>
           <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" >Bezeichnung</TableCell>
                            <TableCell align="center" >Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell key="BetragBez">
                                <Eingabe 
                                    disabled={betragbezblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["betragbez"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["betragbez"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["betragbez"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('betragbez'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="BetragsWert">
                                <Eingabe 
                                    disabled={betragswertblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["betragswert"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["betragswert"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["betragswert"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('betragswert'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>


    </Grid>

    </div>
 
  )
}

export {BetragEingeben};