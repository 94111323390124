import React, {useState, useCallback, Fragment } from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {TabelleEGA} from './../BbgEGA/tabelleega'
import {TabelleWissen} from './../Wissenstest_Entgeltabr_T1/tabelleWissenstestE1';
import {Verdiensttabelle} from './Verdiensttabelle'
import {BruttoNettoBlock} from './../Rahmendaten/BruttoNettoBlock'




function Maerzklausel(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('026');
  const [merkmal]=useState("Maerzklausel");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Die Märzklausel" 
          ueberschrift2="§ 23a Abs. 2 SBG IV ^Was ist die Märzklausel und warum gibt es diese? ^Die Märzklausel besagt, dass in der Zeit vom 1. Januar bis 31. März eines Jahres gezahlte Einmalzahlungen beitragsrechtlich dem Vorjahr zuzuordnen sind, wenn sie im Monat der Auszahlung nicht in vollem Umfang beitragspflichtig werden."
      />

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Am besten erklärt sich die Märzklausel, indem Du die folgenden Berechnungen ohne Anwendung der Märzklausel durchführst und anschließend die Fragen beantwortest.
          </Typography>

        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h4" color="primary">
              Beitragssätze 2020
            </Typography>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderTop:'solid', borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             KV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderTop:'solid', borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            14,60 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             RV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            18,60 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             AV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            2,40 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             PV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderRight:"solid", height:'30px'}}>
          <Typography>
            3,05 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderLeft:'solid', borderBottom:'solid', borderRight:"solid", height:'60px'}}>
          <Typography>
             Beitragszuschlag für kinderlose Arbeitnehmer
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:'solid', height:'60px'}}>
          <Typography>
            0,25 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                  Verwende für die Berechnungen folgende BBG
              </Typography>
          </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',height:'30px' }}>
          </Grid>
          <Grid item xs={2} style={{border:'solid',height:'30px'}}>
            <Typography>
              Monats-BBG
            </Typography>
          </Grid>
          <Grid item xs={4} style={{}}>
          </Grid>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              KV
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              4.687,50€
            </Typography>
          </Grid>
          <Grid item xs={4}>        
          </Grid>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              RV
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              6.900,00€
            </Typography>
          </Grid>
          <Grid item xs={4} >
          </Grid>
        </Grid>

        <Verdiensttabelle aufgabe='2' aufgabentext='Aufgabe 2' text='Der Verdienst einer Mitarbeiterin sieht wie folgt aus: '/>

        <TabelleEGA 
          aufgabe='2.1' 
          text='Die Mitarbeiterin soll zur ihrem Gehalt im Dezember eine Sonderzahlung in Höhe von 4000,- Euro erhalten. 
                Da die Mitarbeiterin eigene Kinder hat, ist der Beitragszuschlag zur PV nicht zu berücksichtigen. 
                Berechne die Sozialversicherungsbeiträge im Dezember.' 
          tabellenKey="1" 
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}/>

     
        <Grid item xs={12} style={{marginTop:'20px'}}>
            <Typography style={{textAlign:"right"}}>
                Vervollständige den Brutto-Netto-Block mit den Sozialversicherungsbeiträgen des Arbeitnehmers und dem sich nach Abzug von Steuern und SV-Beiträgen ergebendem Netto. 
            </Typography>
        </Grid>

        <BruttoNettoBlock 
              svFarbe="#71ff00"
              tabellenKey="1" 
              bruttolfd='4000,00' 
              bruttoega='4000,00' 
              bruttogesamt='8000,00' 
              steuern="2417,39"
              sozvers=""
              agantAusz="0,00" 
              netto="" 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>

        <TabelleEGA 
            aufgabe='2.2' text='Anstatt der Vergütung der Sonderzahlung im Dezember soll die Zahlung erst im Januar zu ihrem Gehalt erfolgen.
                  Berechne bitte die SV-Beiträge für Januar unter der Annahme, dass der Verdienst, die BBG und die Beitragssätze sich nicht verändern.' 
            tabellenKey="2" 
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}
        />


        <Grid item xs={12} style={{marginTop:'20px'}}>
            <Typography style={{textAlign:"right"}}>
                Vervollständige den Brutto-Netto-Block mit den Sozialversicherungsbeiträgen des Arbeitnehmers und dem sich nach Abzug von Steuern und SV-Beiträgen ergebendem Netto. 
            </Typography>
        </Grid>


        <BruttoNettoBlock 
            svFarbe="#71ff00"
            tabellenKey="2" 
            bruttolfd='4000,00' 
            bruttoega='4000,00' 
            bruttogesamt='8000,00' 
            steuern="2417,39"
            sozvers=""
            agantAusz="0,00" 
            netto="" 
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}/>


        <Grid item xs={10}style={{textAlign:''}} >

        <ListItem style={{marginTop:'50px'}}>
              <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                      3
                  </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <Typography variant="h4"  color="primary">
                     Aufgabe 3 <br/>
                  </Typography>
                  <Typography variant="h6">
                     Beantworte die folgenden Fragen um das Thema Märzklausel abzuschließen.
                  </Typography>
              </ListItemText>
        </ListItem>
        
        <TabelleWissen 
              tabellenkey='1'
              aufgabe="3.1"
              text='Welcher Auszahlungszeitraum (Dezember oder Januar) ist für die Sonderzahlung aus Sicht der Arbeitnehmerin rentabler?' 
              antwort1='Auszahlung der Sonderzahlung im Dezember' 
              antwort2='Auszahlung der Sonderzahlung im Januar' 
              antwort3='Es spielt keine Rolle in welchem Monat die Sonderzahlung erfolgt' 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        />

        <TabelleWissen 
              tabellenkey='2' 
              aufgabe="3.2"
              text='In welchem Auszahlungszeitraum (Dezember oder Januar) bekommt die Sozialversicherung die meisten Beiträge?' 
              antwort1='Auszahlung der Sonderzahlung im Dezember' 
              antwort2='Auszahlung der Sonderzahlung im Januar' 
              antwort3='Es spielt keine Rolle in welchem Monat die Sonderzahlung erfolgt' 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        />

      </Grid>

        {(anzFehler === '0') && 
          <Fragment>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={8}>
                  <Typography variant="h5">
                      Mit den richtigen Antworten bei den Fragen 3.1 und 3.2 hast Du bestätigt, die unterschiedliche Interessen verstanden zu haben. 
                      Sicherlich kannst Du Dir nun sehr gut vorstellen, dass die Sozialversicherung ein großes Interesse daran hat, 
                      soviel Beiträge wie möglich zu bekommen. <br/> <br/>
                      Damit aus den Sonderzahlungen, welche im Zeitraum Januar bis März vergütet werden, die höchstmöglichen Beiträge berechnet werden können, 
                      wurde die "Märzklausel" als gesetzliche Regelung eingeführt: <br/><br/>
                      <strong>Die Märzklausel besagt, dass in der Zeit vom 1. Januar bis 31. März eines Jahres gezahlte Einmalzahlungen beitragsrechtlich dem Vorjahr zuzuordnen sind, 
                      wenn sie im Monat der Auszahlung nicht in vollem Umfang beitragspflichtig werden.</strong>
                  </Typography>
                </Grid>   
              </Grid>
          </Fragment>
        }

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

</div>
)
}


export default Maerzklausel;