import React, {useState, useCallback} from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Grid,Box, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {Tab_BBG_2Jahre} from '../../components/Rahmendaten/Tab_BBG_2Jahre';
import {Tab_JAE_2Jahre} from '../../components/Rahmendaten/Tab_JAE_2Jahre';
import {V_Status_Tabelle} from '../../components/Rahmendaten/V_Status_Tabelle';
import {V_Status_Tabelle_Beispiel} from '../../components/Rahmendaten/V_Status_Tabelle_Beispiel';
import {VerdiensttabelleSVStatus} from '../../components/Rahmendaten/VerdiensttabelleSVStatus';
import {BetragEingeben} from '../../components/Rahmendaten/BetragEingeben';
const VIDEO = '/media/Chat_Tutorial_Versicherungspflichtgrenze.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';

function Versicherungsstatus(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0');
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('036');
  const [merkmal]=useState("Versicherungsstatus");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values)
    setAntworten(values);
    console.log(antworten)
    forceUpdate();   
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }
  
  const kurseCb = (response) => {
    //   console.log(response.data.message);
   
       if (response.status === 200) {
           console.log(response.data.anzFehler)
           setTestabgabe(true);
           setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
           setAbgabezeit(response.data.abgabezeit);
           fehlerAusLesen(response.data.fehler);
           setAnzFehler(response.data.anzfehler);
           setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
            showErrorMessage(response.data.message);
         } else {
            showErrorMessage('Keine Internetverbindung');
         }
       }
     }


  return(
   <Box p={2}>
      <Briefkopf  
          ueberschrift1="Versicherungsstatus" 
          ueberschrift2="Wann ist ein Arbeitnehmer in der gesetzlichen Krankenversicherung, ^
                        wann freiwillig in der gesetzlichen Krankenversicherung und wann in der privaten Krankenversicherung versichert? " 
      />

    <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
      <Grid item xs={12} >
          <Typography variant="h3"  color="primary">
              Welche Kriterien sind maßgebend für die Beurteilung eines Versicherungsstatus? <br/>
          </Typography>

      </Grid>


    </Grid>
    

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
      <Grid Item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
              <h4>
                  Schaue Dir das Tutorial an und erhalte dadurch wichtige Informationen zur Beurteilung eines Versicherungsstatus? <br/>
              </h4>
          </ListItemText>    
        </ListItem>
      </Grid>
      <Grid item xs={10} style={{}}>
          <Player
              poster={POSTER}
              src={VIDEO}
              >
              <ControlBar autoHide={false} />
          </Player>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1} >
        <Typography align="left" variant="h2">
          1. 
        </Typography>
      </Grid>
      <Grid item xs={11} >
        <Typography align="left" variant="h5">
          Beginn der Versicherungsfreiheit:<br/>
          Wird die Jahresarbeitsentgeltgrenze (JAG) überschritten, endet die Versicherungspflicht mit Ablauf des Kalenderjahres, in dem sie überschritten wird. (§ 6 Abs. 4 Satz 1 SGB V)<br/>
          Dies gilt nicht, wenn das Entgelt die vom Beginn des nächsten Jahres an geltende Jahresarbeitsentgeltgrenze (JAG) nicht übersteigt. (§ 6 Abs. 4 Satz 2 SGB V)<br/>
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'20px' }}>
      <Grid item xs={1} >
        <Typography align="left" variant="h2">
          2. 
        </Typography>
      </Grid>
      <Grid item xs={11} >
        <Typography align="left" variant="h5">
          Ende der Versicherungsfreiheit:<br/>
          Die Versicherungsfreiheit endet, wenn das regelmäßige Jahresarbeitsentgelt (JAE) die JAG nicht mehr übersteigt. <br/>
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'20px' }}>
      <Grid item xs={1} >
        <Typography align="left" variant="h2">
          3. 
        </Typography>
      </Grid>
      <Grid item xs={11} >
        <Typography align="left" variant="h5">
          Ende der Versicherungsfreiheit bei zeitlich befristeter Entgeltminderung:<br/>
          Die Versicherungsfreiheit endet grundsätzlich auch dann, wenn die Entgeltminderung ihrem Anschein nach nur vorübergehender Natur oder zeitlich befristet ist,<br/>
          <b>es sei denn, die Entgeltminderung ist nur von kurzer Dauer.</b><br/>
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'20px' }}>
      <Grid item xs={1} >
        <Typography align="left" variant="h2">
          4. 
        </Typography>
      </Grid>
      <Grid item xs={11} >
        <Typography align="left" variant="h5">
          <b>Entgeltminderung nur von kurzer Dauer:</b><br/>
          Für eine Entgeltminderung von nur kurzer Dauer kann nicht auf starre Zeitgrenzen zurückgegriffen werden;<br/>
          sie ist in aller Regel jedoch anzunehmen, wenn die vorübergehende Minderung des Arbeitsentgelts <b>nicht mehr als drei Monate ausmacht</b><br/>
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'20px' }}>
      <Grid item xs={1} >
        <Typography align="left" variant="h2">
          5. 
        </Typography>
      </Grid>
      <Grid item xs={11} >
        <Typography align="left" variant="h5">
          <b>Ausnahme Teilzeitbeschäftigung während Elternzeit oder Pflegezeit:</b><br/>
          Bei einer Entgeltminderung infolge Ausübung einer Teilzeitbeschäftigung während der Elternzeit oder im Ramhen einer Freistellung nach §3 des Pflegezeitgesetztes endet die Versicherungsfreiheit sofort,<br/>
          wenn das Jahresarbeitsentgelt aus der Teilzeitbeschäftigung die JAG <b>nicht</b> übersteigt. <br/>
        </Typography>
      </Grid>
    </Grid>




    <Grid container spacing={2} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={5} >
            <Tab_BBG_2Jahre
              jahr1="2020"  
              bbgkvjahr1="56.250,00"
              bbgkvmonat1="4.687,50"
              bbgrvjahr1="82.800,00"
              bbgrvmonat1="6.900,00"
              jahr2="2021"  
              bbgkvjahr2="58.050,00"
              bbgkvmonat2="4.837,50"
              bbgrvjahr2="85.200,00"
              bbgrvmonat2="7.100,00"
            />
        </Grid>
        <Grid item xs={5} >
            <Tab_JAE_2Jahre
              jahr1="2020"  
              jaejahr1="62.550,00"
              jaemonat1="5.212,50"
              jahr2="2021"  
              jaejahr2="64.350,00"
              jaemonat2="5.362,50"
            />

        </Grid>

    </Grid>


    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                B1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Beispiel 1:</h4>
                  <Typography>Beschäftigungsbeginn 1.1.2020: Gehalt 3500,-€ </Typography>
                  <Typography>
                    Gehaltserhöhung am 01.08.2020: neues Gehalt 3900,-€ <br/> <br/>
                    Die erste Prüfung des Versicherungsstatus ist zu Beginn der Beschäftigung durchzuführen.<br/>
                    Da das Entgelt hochgerechnet auf das Jahr kleiner ist als die JAE, ist der Versicherungsstatus "Gesetzlich Versichert"<br/><br/>
                    Bei Verdiensterhöhungen sind generell keine Prüfungen des Versicherungsstatus durchzuführen, deshalb ist zum Zeitpunkt 01.08. "keine erneute Prüfung notwendig"<br/><br/>
                    Zum Beginn eines jeden neuen Jahres ist die Prüfung des Versicherungsstatus generell durchzuführen. <br/>
                    Da das Entgelt hochgerechnet auf das Jahr sowohl im alten wie auch im neuen Jahr unter der JAE bleibt, ist der Versicherungsstatus ab 01.01.21 weiterhin "Gesetzlich Versichert"<br/><br/>
                    Dementsprechend sieht die Tabelle für die Beurteilung des Versicherungsstatus wie folgt aus:
                  </Typography>
              </ListItemText>  
          </ListItem>
          <V_Status_Tabelle_Beispiel 
              zeitp1="01.01.2020" 
              zeitperg1="Gesetzlich Versichert"
              zeitp2="01.08.2020" 
              zeitperg2="keine erneute Prüfung notwendig"
              zeitp3="01.01.2021" 
              zeitperg3="Gesetzlich Versichert"
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Aufgabe 1:</h4>
                  <Typography>Beschäftigungsbeginn 1.1.2020: Gehalt 3700,-€ </Typography>
              </ListItemText>  
          </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={2} datum={["1.1.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="1"/>
         

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Aufgabe 2:</h4>
                  <Typography>Beschäftigungsbeginn 1.2.2020: Gehalt 4900,-€ </Typography>
                  <Typography>Im Arbeitsvertrag zugesicherte Sonderzahlung in Höhe von 4900,-€. Die Auszahlung erfolgt im November jeden Jahres. </Typography>
              </ListItemText>  
          </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={2} datum={["1.2.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="2"/>


          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A3
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 3:</h4>
                  <Typography>Beschäftigungsbeginn: 1.2.2020.</Typography>
                  <Typography>Monatliches Gehalt 6000,-€</Typography>
                  <Typography>Am 1.8.2020 Reduzierung der Stunden um 50%, neues mtl. Gehalt = 3000,-€</Typography>
              </ListItemText>
            </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={3} datum={["1.2.2020","1.8.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="3"/>
          
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A4
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 4:</h4>
                  <Typography>Beschäftigungsbeginn: 1.3.2020</Typography>
                  <Typography>Monatsgehalt: 6000,-€</Typography>
                  <Typography>Am 1.8.2020 Reduzierung der Arbeitszeit um 50%, neues mtl. Gehalt = 3000,-€</Typography>
                  <Typography>Am 1.1.2021 Aufhebung der Reduzierung und wieder 100% Arbeitszeit. Mntl. Gehalt wieder 6000,-€</Typography>
              </ListItemText>
            </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={3} datum={["1.3.2020","1.8.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="4"/>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A5
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 5:</h4>
                  <Typography>Beschäftigungsbeginn: 1.3.2020</Typography>
                  <Typography>Monatsgehalt: 6000,-€</Typography>
                  <Typography>Am 1.8.2020 Reduzierung der Arbeitszeit um 50%, neues mtl. Gehalt = 3000,-€</Typography>
                  <Typography>Am 1.12.2020 Aufhebung der Reduzierung und wieder 100% Arbeitszeit. Mntl. Gehalt wieder 6000,-€</Typography>
              </ListItemText>
            </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={4} datum={["1.3.2020","1.8.2020","1.12.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="5"/>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A6
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 6:</h4>
              <Typography>Arbeitsbeginn: 1.1.2020</Typography>
              <Typography>Monatliches Gehalt = 6000€</Typography>
              <Typography>Arbeitnehmer geht am 1.4.2020 für einen Monat in der Form in Elternzeit, dass er im Monat April seine Arbeitszeit halbiert.</Typography>
              <Typography>Verdienst für den Monat April : 3000,-€</Typography>
              <Typography>Verdienst ab Mai nach der Elternzeit wieder 6000,- Euro.</Typography>
            </ListItemText>  
          </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={4} datum={["1.1.2020","1.4.2020","1.5.2020","1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="6"/>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A7
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 7:</h4>
              <Typography>Arbeitsbeginn: 1.4.2020</Typography>
              <Typography>Monatliches Gehalt = 3000€</Typography>
              <Typography>Im Arbeitsvertrag zugesicherte Sonderzahlung in Höhe eines aktuellen Monatsgehalts. Die Auszahlung erfolgt im November jeden Jahres. </Typography>
              <Typography>Am 01.12.2020 erhöht sich das monatliche Gehalt auf 5000,- Euro.</Typography>
              <Typography>Vom 01.04.2021 bis 31.05.2021 halbiert der Arbeitnehmer seine Arbeitszeit um 40%. Dadurch verringert sich in dieser Zeit sein monatliches Gehalt auf 3000,-€. Für die Halbierung der Arbeitszeit wurden keine besonderen Gründe genannt.</Typography>
              <Typography>Ab 01.06.2021 arbeitet der Arbeitnehmer wieder zu 100%</Typography>
            </ListItemText>  
          </ListItem>
          <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={5} datum={["1.4.2020","1.12.2020","1.1.2021","1.4.2021","1.6.2021"]} fehlerliste={fehlerliste} tabellenKey="7"/>


          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A8
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 8:</h4>
              <Typography>Arbeitsbeginn: irgendwann in der Vergangenheit</Typography>
              <Typography>Du befindest dich im Abrechnungsmont Januar 2021 und musst eine Beurteilung über den Versicherungsstatus eines Arbeitnehmers für das aktuelle Jahr treffen.</Typography>
              <Typography>Der Arbeitnehmer ist in der GKV versichert.</Typography>
              <Typography>Sein Verdienst in 2020 war wie folgt:</Typography>
            </ListItemText>
          </ListItem>

          <Grid item xs={12}>
            <VerdiensttabelleSVStatus
              bez1="Gehalt"
              zahl1="43.200,-"
              bez2="Quartalsprovisionen"
              zahl2="13.310,-"
              bez3="Überstunden monatlich variabel"
              zahl3="2.930,-"
              bez4="Freiwillige Zulage (monatliche Vergütung)"
              zahl4="7.200,-"
              bez5="Tarifliche Zulage (monatliche Vergütung)"
              zahl5="1.800,-"
              bez6="Jahresbonus (vom Firmengewinn abhängig)"
              zahl6="7.000,-"
            />
          </Grid>

          <Grid item xs={12} style={{marginTop:"25px"}}>
            <Typography variant="h6">Ermittle das regelmäßige Jahresarbeitsentgelt des Arbeitnehmers und lege den Versicherungsstatus für das Jahr 2021 fest. </Typography>
          </Grid>

          <Grid item xs={5} >
            <BetragEingeben
              tabellenKey="8"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste} 
              betragbez="Regelmäßiges Jahresarbeitsentgelt"
              betragswert=""
            />
          </Grid>

          <Grid item xs={3} >
                <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={1} datum={["1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="8"/>
          </Grid>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A9
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <h4>Aufgabe 9:</h4>
              <Typography>Arbeitsbeginn: irgendwann in der Vergangenheit</Typography>
              <Typography>Du befindest dich im Abrechnungsmont Januar 2021 und musst eine Beurteilung über den Versicherungsstatus eines Arbeitnehmers für das aktuelle Jahr treffen.</Typography>
              <Typography>Der Arbeitnehmer war bereits in 2002 in der privaten Krankenversicherung versichert.</Typography>
              <Typography>Sein Verdienst in 2020 war wie folgt:</Typography>
            </ListItemText>
          </ListItem>

          <Grid item xs={12}>
            <VerdiensttabelleSVStatus
              bez1="Gehalt"
              zahl1="48.000,-"
              bez2="Quartalsprovisionen"
              zahl2="14.000,-"
              bez3="Pauschale Überstundenvergütung (monatlich)"
              zahl3="3.000,-"
              bez4="Freiwillige Zulage (monatliche Vergütung)"
              zahl4="7.200,-"
              bez5="Jahresbonus (vom Firmengewinn abhängig)"
              zahl5="8.000,-"
              bez6=""
              zahl6=""
            />
          </Grid>

          <Grid item xs={12} style={{marginTop:"25px"}}>
            <Typography variant="h6">Ermittle das regelmäßige Jahresarbeitsentgelt des Arbeitnehmers und lege den Versicherungsstatus für das Jahr 2021 fest. </Typography>
          </Grid>

          <Grid item xs={5} >
            <BetragEingeben
              tabellenKey="9"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste} 
              betragbez="Regelmäßiges Jahresarbeitsentgelt"
              betragswert=""
            />
          </Grid>

          <Grid item xs={3} >
                <V_Status_Tabelle  setzeAntworten={setzeAntworten} antworten={antworten} anzReihen={1} datum={["1.1.2021"]} fehlerliste={fehlerliste} tabellenKey="9"/>
          </Grid>



    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

   </Box>
    )
  }   


export default Versicherungsstatus;
