import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function VerdiensttabelleSVStatus (props) {
  const {bez1, bez2, bez3, bez4, bez5, bez6, zahl1, zahl2, zahl3, zahl4, zahl5, zahl6} = props;


return(
    <div>

    <Grid container spacing={2} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={5}>
           <TableContainer>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Bezeichnung</TableCell>
                            <TableCell>Summe Jahr</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez1}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl1} 
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez2}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl2} 
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez3}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl3} 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez4}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl4} 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez5}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl5} 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{fontSize:"20px"}}>
                                {bez6}
                            </TableCell>
                            <TableCell align="right" style={{fontSize:"20px"}}>
                               {zahl6} 
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    </Grid>

    </div>
 
  )
}

export {VerdiensttabelleSVStatus};