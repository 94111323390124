import React, {useState, useCallback} from 'react';
import {MenuItem,Avatar,Typography,Button, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const NICKELSTAMM = '/media/Nickel Stammblatt.pdf';
const VIDEO = '/media/Aufbau Entgeltabrechnung 1-3.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
const TAETIGKEITSCODE = '/media/Aufbau Taetigkeitscode.pdf';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function Entgelt1(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [stelle1, setStelle1] = useState("");
  const [stelle2, setStelle2] = useState("");
  const [stelle3, setStelle3] = useState("");
  const [stelle4, setStelle4] = useState("");
  const [brGruppe, setBrGruppe] = useState("");
  const [fehlerliste,setFehlerliste]=useState([]);
  const [open,setOpen]=useState(false);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('001');
  const [merkmal]=useState('Entgelt1');


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBrGruppe(stelle1.toString()+stelle2.toString()+stelle3.toString()+stelle4.toString())
  };

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;       
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    values["UEBERGANG"] = "nein";
    values["MEHRFACHBESCHAEFTIGT"] = "nein";
    values["GERINGVERDIENER"] = "nein";
    values["FAMILIENSTAND"] = "ledig";
    values["BUNDESLAND"] = "08 BW";
    values["RENTENSTATUS"] = "nein";
    values["BETRGRUPPE"] = brGruppe ;

    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }
  

  return(
    <div>
      <Briefkopf  
          ueberschrift1="Die personenbezogenen Daten auf einer Entgeltbescheinigung" 
          ueberschrift2=" In diesem Bereich lernst Du, welche personenbezogenen Daten auf der Entgeltbescheinigung stehen und welche Bedeutungen diese haben." 
      />

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Dieses Video erklärt Dir die personenbezogenen Daten und Informationen, welche aus der Entgeltbescheinigung hervorgehen"/>
          </ListItem>
          <Grid item xs={12} >
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Bitte verwende dieses Muster-Stammblatt für die folgenden Aufgaben "/>
          </ListItem>
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={NICKELSTAMM}download>
                Stammblatt Herr Nickel
              </Button>
          </Grid>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Diese Erklärung hilft Dir beim Ausfüllen des Tätigkeitscodes auf der Entgeltbescheinigung"/>
            </ListItem>
            <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={TAETIGKEITSCODE}download>
                Erklärung Tätigkeitscode
              </Button>
            </Grid>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  4
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Fülle nun die Felder der u. a. Entgeltabrechnung anhand des Stammblattes von Herrn Nickel aus."/>
            </ListItem>
        </Grid>

      </Grid>

      <div>
      <Grid container spacing={0}>
          <Grid item xs={12} style={{marginTop:"30px"}}>
             <Typography>Entgeltabrechnung Teil 1</Typography>
          </Grid>
          <Grid item xs={4}style={{borderLeft:"solid",borderRight:"solid",borderTop:"solid"}}>
            <Typography>Adresse</Typography>  
            <TextField id="NAME" label="Name" error={fehlerliste["NAME"]} style={{display:"inline-block"}} onChange={(event) => setzeAntworten('NAME',event.target.value)}></TextField>
            <TextField id="VORNAME" label="Vorname" error={fehlerliste["VORNAME"]} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('VORNAME',event.target.value)}></TextField>
            <TextField id="STRASSE" label="Strasse" error={fehlerliste["STRASSE"]} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('STRASSE',event.target.value)}></TextField>
            <TextField id="HAUSNR" label="Nummer" error={fehlerliste["HAUSNR"]} style={{display:""}}onChange={(event) => setzeAntworten('HAUSNR',event.target.value)}></TextField>
            <TextField id="PLZ" label="PLZ" error={fehlerliste["PLZ"]} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('PLZ',event.target.value)}></TextField>
            <TextField id="STADT" label="Stadt" error={fehlerliste["STADT"]} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('STADT',event.target.value)}></TextField>
          </Grid>

          <Grid item xs={4}style={{borderTop:"solid", borderBottom:""}}> 
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{backgroundColor:"grey",borderRight:"solid", float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Steuer-Identifikationsnummer 
              </Typography>                                                                                             
              <TextField  id="STEUERIDENT" error={fehlerliste["STEUERIDENT"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["STEUERIDENT"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid" }} onChange={(event) => setzeAntworten('STEUERIDENT',event.target.value)}/>  
            </div>
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Geburtsdatum 
              </Typography>
              <TextField  id="GEBURTSDATUM" label="" error={fehlerliste["GEBURTSDATUM"]} placeholder="Eingabe"  style={{backgroundColor:(fehlerliste["GEBURTSDATUM"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}onChange={(event) => setzeAntworten('GEBURTSDATUM',event.target.value)}> </TextField>       
            </div>
    
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Versicherungsnummer
              </Typography>
              <TextField  id="VERSICHNR" label="" error={fehlerliste["VERSICHNR"]} placeholder="Eingabe"  style={{backgroundColor:(fehlerliste["VERSICHNR"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}onChange={(event) => setzeAntworten('VERSICHNR',event.target.value)}></TextField>       
            </div>
            
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px",borderBottom:""}}>
                Beitragsgruppe 
              </Typography>
              <Button   style={{textTransform:"none",height:"32px",width:"50%",display:"flex",borderRight:"solid",fontSize:"1vw",backgroundColor:(fehlerliste["BETRGRUPPE"]===true)?"red":""}} onClick={(event) => handleClickOpen()}>{brGruppe||"auswählen"}</Button>       
            </div>
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}
              >
                Personengruppe
              </Typography>
              <TextField  
                select
                id="PERSGRUPPE" 
                label="" 
                error={fehlerliste["PERSGRUPPE"]}
                value={antworten["PERSGRUPPE"]||''}
                placeholder="Eingabe" 
                style={{backgroundColor:(fehlerliste["PERSGRUPPE"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}
                onChange={(event) => setzeAntworten('PERSGRUPPE',event.target.value)}
                >  
                  <MenuItem value={101}>101 keine besonderen Merkmale</MenuItem>
                  <MenuItem value={102}>102 Auszubildene</MenuItem>
                  <MenuItem value={103}>103 Altersteilzeit</MenuItem>

              </TextField>       
            </div>

            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                 Tätigkeitscode
              </Typography>
              <TextField  id="TAETIGKEITSCODE" label="" error={fehlerliste["TAETIGKEITSCODE"]} placeholder="Eingabe"  style={{backgroundColor:(fehlerliste["TAETIGKEITSCODE"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}onChange={(event) => setzeAntworten('TAETIGKEITSCODE',event.target.value)}/>
            </div>

            <div style={{borderBottom:""}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Staatsangehörigkeit 
              </Typography>
              <TextField  
                select
                id="STAATSANG" 
                label="" 
                error={fehlerliste["STAATSANG"]}
                value={antworten["STAATSANG"]||''}
                placeholder="Eingabe" 
                style={{backgroundColor:(fehlerliste["STAATSANG"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}
                onChange={(event) => setzeAntworten('STAATSANG',event.target.value)}
                >  
                  <MenuItem value={"deutsch"}>000 deutsch</MenuItem>
                  <MenuItem value={"französisch"}>129 französisch</MenuItem>
                  <MenuItem value={"niederländisch"}>148 niederländisch</MenuItem>      
                  <MenuItem value={"spanisch"}>161 spanisch</MenuItem>
                  <MenuItem value={"türkisch"}>163 türkisch</MenuItem>
              </TextField>       
            </div>
          </Grid>
     
          <Grid item xs={4}style={{borderTop:"solid"}}>
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Übergangsbereich
              </Typography>
              <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
                Nein
              </Typography>
    {/*
              <TextField
                    id="UEBERGANG" 
                    error={fehlerliste["UEBERGANG"]} 
                    value="Nein" 
                    label="" 
                    disabled={true}  
                    style={{backgroundColor:fehlerliste["UEBERGANG"], width:"50%", display:"flex", borderRight:"solid"}} 
                    onChange={(event) => setzeAntworten('UEBERGANG',event.target.value)}/>
    */}
    
            </div>
            <div style={{borderBottom:"solid"}}>
              <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
                Mehrfachbeschäftigt
              </Typography>
              <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
                Nein
              </Typography>
            </div>

      <div style={{borderBottom:"solid"}}>
       <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
         Geringverdiener
       </Typography>
       <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
                Nein
       </Typography>
      </div>
      <div style={{borderBottom:"solid"}}>
         <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
           Familienstand
         </Typography>
         <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
                ledig
        </Typography>
      </div>
      <div style={{borderBottom:"solid"}}>
         <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
              Bundesland 
         </Typography>
         <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
              08 BW
        </Typography>
      </div>
      <div style={{borderBottom:"solid"}}>
         <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
             Rentenstatus 
         </Typography>
         <Typography  style={{borderRight:"solid", width:"50%", display:"flex", height:"32px",lineHeight:"32px"  }}>
                Nein
        </Typography>
      </div>
      <div style={{borderBottom:""}}>
       <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"50%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
         Kategorie </Typography>
         <TextField  
                select
                value={antworten["KATEGORIE"]||''}
                id="KATEGORIE" 
                error={fehlerliste["KATEGORIE"]}
                label="" 
                placeholder="Eingabe" 
                style={{backgroundColor:(fehlerliste["KATEGORIE"]===true)?"red":"",width:"50%",display:"flex",borderRight:"solid"}}
                onChange={(event) => setzeAntworten('KATEGORIE',event.target.value)}
                >  
                  <MenuItem value={"ANG. GEHALT"}>Angestellter Gehalt</MenuItem>
                  <MenuItem value={"ANG. LOHN"}>Angestellter Stundenlohn</MenuItem>
                  <MenuItem value={"ARB. GEHALT"}>Arbeiter Gehalt </MenuItem>
                  <MenuItem value={"ARB. LOHN"}>Arbeiter Stundenlohn</MenuItem>
              </TextField>       
      </div>
     </Grid>
     <Grid item xs={4} style={{borderRight:"solid",borderLeft:"solid"}}></Grid>
     <Grid item xs={8} >
       <div style={{borderBottom:"solid",borderTop:"solid"}}>
        <Typography  style={{ backgroundColor:"grey",borderRight:"solid",float:"left",width:"25%",fontSize:"0.8em", height:"32px",lineHeight:"32px"}}>
          Krankenkasse 
        </Typography>
        <TextField  
                select
                value={antworten["KRANKENKASSE"]||''}
                error={fehlerliste["KRANKENKASSE"]}
                id="KRANKENKASSE" 
                label="" 
                placeholder="Eingabe" 
                style={{backgroundColor:(fehlerliste["KRANKENKASSE"]===true)?"red":"",width:"%",display:"flex",borderRight:"solid"}}
                onChange={(event) => setzeAntworten('KRANKENKASSE',event.target.value)}
                >  
                  <MenuItem value={"AOK BAWUE"}>AOK Baden-Württemberg</MenuItem>
                  <MenuItem value={"AOK BAY"}>AOK Bayern</MenuItem>
                  <MenuItem value={"BKK PLUS"}>BKK Plus</MenuItem>
                  <MenuItem value={"AOK PLUS"}>AOK Plus</MenuItem>

              </TextField>       
      </div>
      <div style={{borderBottom:"solid"}}>
        <Typography style={{display:"inline-block",width:"33%",borderRight:"solid"}}>Std./Woche </Typography>
        <Typography style={{display:"inline-block",width:"33%",borderRight:"solid"}}>Norm.Std.Tag</Typography>
        <Typography style={{display:"inline-block",width:"34%",borderRight:"solid"}}>Tage/Woche</Typography>
        <TextField  id="STDWOCHE" placeholder="Eingabe" error={fehlerliste["STDWOCHE"]} type="number" inputProps={{style:{textAlign:"center"}}} style={{backgroundColor:(fehlerliste["STDWOCHE"]===true)?"red":"",width:"33%",display:"inline-block",borderRight:"solid",paddingBottom:"1px"}}onChange={(event) => setzeAntworten('STDWOCHE',event.target.value)}></TextField>       
        <TextField  id="STDTAG" placeholder="Eingabe" error={fehlerliste["STDTAG"]} type="number" inputProps={{style:{textAlign:"center"}}} style={{backgroundColor:(fehlerliste["STDTAG"]===true)?"red":"",width:"33%",display:"inline-block",borderRight:"solid",paddingBottom:"1px"}}onChange={(event) => setzeAntworten('STDTAG',event.target.value)}></TextField>       
        <TextField  id="TAGEWOCHE" placeholder="Eingabe" error={fehlerliste["TAGEWOCHE"]} type="number" inputProps={{style:{textAlign:"center"}}} style={{backgroundColor:(fehlerliste["TAGEWOCHE"]===true)?"red":"",width:"34%",display:"inline-block",borderRight:"solid",paddingBottom:"1px"}}onChange={(event) => setzeAntworten('TAGEWOCHE',event.target.value)}></TextField>       

      </div>
      <Typography style={{borderBottom:"",borderRight:"solid",backgroundColor:"lightblue"}}> Lohnsteuerabzugsmerkmale</Typography>
      <div style={{borderBottom:"solid",borderTop:"solid"}}>
        <Typography style={{display:"inline-block",width:"14%",borderRight:"solid", borderTop:""}}>Steuerklasse</Typography>
        <Typography style={{display:"inline-block",width:"14%", borderRight:"solid", borderTop:""}}>Steuerfaktor</Typography>
        <Typography style={{display:"inline-block",width:"12%", borderRight:"solid", borderTop:""}}>Kinder</Typography>
        <Typography style={{display:"inline-block",width:"20%",borderRight:"", borderTop:""}}>Kirchensteuerkennung</Typography>
        <Typography style={{display:"inline-block",width:"40%",borderLeft:"solid",borderRight:"solid", borderTop:""}}>Steuerfreibetrag Monat/Jahr</Typography>
        <TextField 
            id="STEUERKLASSE" 
            placeholder="Eingabe" 
            error={fehlerliste["STEUERKLASSE"]} 
            inputProps={{style:{textAlign:"center"}}} 
            style={{backgroundColor:(fehlerliste["STEUERKLASSE"]===true)?"red":"",display:"inline-block",width:"14%",borderRight:"solid",paddingBottom:"1px"}}
            onChange={(event) => setzeAntworten('STEUERKLASSE',event.target.value)}
            >
        </TextField>
        <TextField id="STEUERFAKTOR" placeholder="Eingabe" error={fehlerliste["STEUERFAKTOR"]} inputProps={{style:{textAlign:"center"}}} style={{backgroundColor:(fehlerliste["STEUERFAKTOR"]===true)?"red":"",display:"inline-block",width:"14%",borderRight:"solid",paddingBottom:"1px"}}onChange={(event) => setzeAntworten('STEUERFAKTOR',event.target.value)}></TextField>
        <TextField id="KINDER" placeholder="Eingabe" error={fehlerliste["KINDER"]} inputProps={{style:{textAlign:"center"}}} style={{backgroundColor:(fehlerliste["KINDER"]===true)?"red":"",display:"inline-block",width:"12%",borderRight:"solid",paddingBottom:"1px"}}onChange={(event) => setzeAntworten('KINDER',event.target.value)}></TextField>
        <TextField 
          select
          value={antworten["KIRCHE"]||''}
          id="KIRCHE"
          error={fehlerliste["KIRCHE"]}
          style={{backgroundColor:fehlerliste["KIRCHE"],display:"inline-block",width:"20%",borderRight:"",paddingBottom:"1px"}}
          onChange={(event) => setzeAntworten('KIRCHE',event.target.value)}
        >
          <MenuItem value={"k"}>Keine</MenuItem>
          <MenuItem value={"E"}>Evangelisch</MenuItem>
          <MenuItem value={"A"}>Katholisch</MenuItem>
        </TextField>
        <Typography style={{display:"inline-block",width:"40%",borderLeft:"solid",borderRight:"solid",paddingBottom:"px",height:"32px"}}>0€/0€</Typography>
      </div>
     </Grid>

    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </div>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Beitragsgruppe</DialogTitle>
        <DialogContent>
          <TextField
            select
            value={stelle1||''}
            margin="dense"
            id="1.Stelle"
            label="Krankenversicherung"
            fullWidth
            onChange={(event)=>setStelle1(event.target.value.toString())}
            autoFocus={true}
          >
            <MenuItem value={0}>0 kein Beitrag</MenuItem>
            <MenuItem value={1}>1  allgemeiner Beitrag</MenuItem>
            <MenuItem value={2}>2  erhöhter Beitrag (nur für Meldezeitraum bis 31.12.2008 zulässig)</MenuItem>
            <MenuItem value={3}>3  ermäßigter Beitrag</MenuItem>
            <MenuItem value={4}>4 Beitrag zur landwirtschaftlichen KV</MenuItem>
            <MenuItem value={5}>5 Arbeitgeberbeitrag zur landwirtschaftlichen KV</MenuItem>
            <MenuItem value={6}>6 Pauschalbetrag für gerinfügig Beschäftigte</MenuItem>
            <MenuItem value={9}>9 Firmenzahler</MenuItem>

          </TextField>
          <TextField
            select
            autoFocus
            value={stelle2||''}
            margin="dense"
            id="2.Stelle"
            label="Rentenversicherung"
            fullWidth
            onChange={(event)=>setStelle2(event.target.value.toString())}
          >
            <MenuItem value={0}>0 kein Beitrag</MenuItem>
            <MenuItem value={1}>1 voller Beitrag</MenuItem>
            <MenuItem value={3}>3 halber Beitrag</MenuItem>
            <MenuItem value={5}>5 Pauschalbetrag für gerinfügugig Beschäftigte</MenuItem>

          </TextField>
          <TextField
            select
            autoFocus
            margin="dense"
            id="3.Stelle"
            value={stelle3||''}

            label="Arbeitslosenversicherung"
            fullWidth
            onChange={(event)=>setStelle3(event.target.value.toString())}
          >
            <MenuItem value={0}>0 kein Beitrag</MenuItem>
            <MenuItem value={1}>1 voller Beitrag</MenuItem>
            <MenuItem value={2}>2 halber Beitrag</MenuItem>
          </TextField>
          <TextField
            select
            autoFocus
            value={stelle4||''}
            margin="dense"
            id="4.Stelle"
            label="Pflegeversicherung"
            fullWidth
            onChange={(event)=>setStelle4(event.target.value.toString())}
          >
            <MenuItem value={0}>0 kein Beitrag</MenuItem>
            <MenuItem value={1}>1 voller Beitrag</MenuItem>
            <MenuItem value={2}>2 halber Beitrag</MenuItem>
          </TextField>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            bestätigen
          </Button>
        </DialogActions>
      </Dialog>
     </div>
    )
}


export default Entgelt1;