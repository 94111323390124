import React, { useEffect } from 'react';

import {Grid, Typography, TextField, Avatar, MenuItem, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';



function TabelleLohnartenErfassung (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten, aufgabe, titel} = props;

  return(
// Bruttolohnarten
      <Grid container spacing={1} style={{marginTop:'30px', justify:"center",  alignItems:"center", textAlign:'left'}} >      

            <Grid Item xs={12}>
                <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{backgroundColor:"#1fbba6"}}>
                        {aufgabe}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      {titel}
                    </ListItemText>    
                </ListItem>
            </Grid>


            <Grid item xs={1}>
            </Grid>

            <Grid item xs={11}>
              <Typography>
                Bruttolohnarten
              </Typography>
            </Grid>

            <Grid container spacing={0} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={1}>
            </Grid>

            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Bezeichnung
              </Typography>
            </Grid>
    
            <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Steuerpflichtig
              </Typography>
            </Grid>
    
            <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Sozialvers.pflichtig
              </Typography>
            </Grid>
    
            <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Betrag 
              </Typography>
            </Grid>

            <Grid item xs={2}>
            </Grid>



            <Grid item xs={1}>
            </Grid>

            <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
              <TextField
                select // durch select wird DropDown initialisiert
                id={'loatext'+tabellenKey} 
                label="Bezeichnung auswählen "
                fullWidth
                value={antworten['loatext'+tabellenKey]||''}
                error={fehlerliste["loatext"+tabellenKey]}
                style={{display:"flex"}}
                onChange={(event) => setzeAntworten('loatext'+tabellenKey,event.target.value)}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}       
              >
              <MenuItem value={'Gehalt'}>Gehalt</MenuItem>
              <MenuItem value={'Kontrollzulage'}>Kontrollzulage</MenuItem>
              <MenuItem value={'Nachtarbeit'}>Nachtarbeit</MenuItem>
              <MenuItem value={'Nachtzuschlag'}>Nachtzuschlag</MenuItem>
              <MenuItem value={'Prämie'}>Prämie</MenuItem>
              <MenuItem value={'Provision'}>Provision</MenuItem>
              <MenuItem value={'Q-Provision'}>Q-Provision</MenuItem>
              <MenuItem value={'Sachbezug'}>Sachbezug</MenuItem>
              <MenuItem value={'Sonntagsarbeit'}>Sonntagsarbeit</MenuItem>
              <MenuItem value={'Sonntagszuschlag'}>Sonntagszuschlag</MenuItem>
              <MenuItem value={'Urlaubsgeld'}>Urlaubsgeld</MenuItem>
              <MenuItem value={'Überstunden'}>Überstunden</MenuItem>
              <MenuItem value={'VWL-Zulage'}>VWL-Zulage</MenuItem>
              <MenuItem value={'Zulage'}>Zulage</MenuItem>
            </TextField> 
        </Grid>

        <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
          <TextField
            type="number"
            id={'stpfl'+tabellenKey} 
            label="Steuerpflichtigen Betrag eingeben"
            fullWidth
            error={fehlerliste['stpfl'+tabellenKey]}
            variant="outlined"
            inputProps={{style:{textAlign:"center"}}}
            style={{display:"flex"}}
            onChange={(event) => setzeAntworten('stpfl'+tabellenKey,event.target.value)} 
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            > 
          </TextField>             
        </Grid>

        <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
          <TextField
            type="number"
            id={'svpfl'+tabellenKey} 
            label="Sozialversicherungsbeitrag eingeben"
            fullWidth
            error={fehlerliste['svpfl'+tabellenKey]}
            variant="outlined"
            inputProps={{style:{textAlign:"center"}}}
            style={{display:"flex"}}
            onChange={(event) => setzeAntworten('svpfl'+tabellenKey,event.target.value)} 
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}   
            >
          </TextField>
        </Grid>

        <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
          <TextField
            type="number"
            id={'betrag'+tabellenKey} 
            label="Betrag eingeben"
            fullWidth
            error={fehlerliste['betrag'+tabellenKey]}
            variant="outlined"
            inputProps={{style:{textAlign:"center"}}}
            style={{display:"flex"}}
            onChange={(event) => setzeAntworten('betrag'+tabellenKey,event.target.value)} 
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}   
          >
          </TextField>
        </Grid>

        <Grid item xs={2}>
        </Grid>
  </Grid>


  <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>

        <Grid item xs={5}>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            Nettolohnarten
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
        </Grid>


        <Grid item xs={5}>
        </Grid>

        <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Bezeichnung
              </Typography>
        </Grid>

        <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",backgroundColor:"#BDBDBD"}}>
              <Typography>
                Betrag
              </Typography>
        </Grid>

        <Grid item xs={2}>
        </Grid>



        <Grid item xs={5}>
        </Grid>

        <Grid item xs={3} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
            <TextField
                select // durch select wird DropDown initialisiert
                id={'text'+tabellenKey} 
                label="Bezeichnung auswählen "
                fullWidth
                value={antworten['nettotext'+tabellenKey]||''}
                error={fehlerliste["nettotext"+tabellenKey]}
                style={{display:"flex"}}
                onChange={(event) => setzeAntworten('nettotext'+tabellenKey,event.target.value)}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}       
              >
              <MenuItem value={'Keine'}>(keine Lohnart)</MenuItem>
              <MenuItem value={'BAV'}>BAV</MenuItem>
              <MenuItem value={'Sachbezug'}>Sachbezug</MenuItem>
              <MenuItem value={'VWL-Abzug'}>VWL-Abzug</MenuItem>
              <MenuItem value={'Vorschuss'}>Vorschuss</MenuItem>
            </TextField> 
        </Grid>

        <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid"}}>
          <TextField
            type="number"
            id={'stpfl'+tabellenKey} 
            label="Betrag eingeben"
            fullWidth
            error={fehlerliste['nettobetrag'+tabellenKey]}
            variant="outlined"
            inputProps={{style:{textAlign:"center"}}}
            style={{display:"flex"}}
            onChange={(event) => setzeAntworten('nettobetrag'+tabellenKey,event.target.value)} 
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            > 
          </TextField>             
        </Grid>

        <Grid item xs={2}>
        </Grid>
      
      </Grid>


  </Grid>
   
  )
}

export {TabelleLohnartenErfassung};