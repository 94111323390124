import React, {useState, useCallback} from 'react';
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Aufbau Entgeltabrechnung Clip 3-3.mp4';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';

function Entgelt3(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('003');
  const [merkmal]=useState('Entgelt3');


  const setRedirect = (path) => {
    navigate(path);
  }

  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Die Entgeltabrechnungsdaten und -werte auf einer Entgeltbescheinigung Teil 3" 
          ueberschrift2="In diesem Kurs lernst Du die statistischen Daten einer Entgeltgeltabrechnung. ^
          Wo werden Dir die genommenen und übrig gebliebenen Urlaubstage angezeigt? ^
          Welche Nettoabzüge sind auf der Entgeltabrechnung vorhanden? ^
          Wo erkennst Du die aufgelaufenen Jahreswerte einer Entgeltabrechnung?" 
      />

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>


      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} bezeichnung="Erklärung angeschaut und verstanden" antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </Grid>
     </div>
    )
}


export default Entgelt3;