import React, {createContext, useState} from "react";

const GlobalStateContext = createContext();

const initialState = {
    firstLogin: true,
    forgotPassword: false,
    title: ''
}

const GlobalStateProvider = ({children}) => {
    const [state, setState] = useState(initialState);

    const setStateForKey = (key, newState) => {
        var tempState = Object.assign(state);
        tempState[key] = newState;
        setState(tempState);
    }

    return (
        <GlobalStateContext.Provider value={[state, setStateForKey]}>
            {children}
        </GlobalStateContext.Provider>
    )
};

const useGlobalState = () => React.useContext(GlobalStateContext);

export {GlobalStateProvider, useGlobalState};