import React, {Fragment, useEffect, useState} from 'react';
import apiRequest from '../../functions/api';
import {Avatar, Button, ListItem, ListItemAvatar, ListItemText, Typography, Grid, Paper} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
const lsttabpdf = '/media/lohnsteuertabelle.pdf';
import { Player, ControlBar } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"
const tutorial = '/media/TutorialEignungstestOnline.mp4';
import { useMessage } from '../../context/message-context';




function Eignungstest(props) {
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [okay, setOkay] = useState(true);
  const [, updateState] = useState();
  const {showErrorMessage} = useMessage();
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')

  const setRedirect = (path) => {
    navigate(path);
  }

  const holeDatenCb = (response) => {   
    if (response.status === 200) {
      if (response.data.abgabedat !== '') {
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setOkay(false);
        setTestabgabe(true);
      }

      } else {
      if (response) {
        showErrorMessage(response.data.message);
        navigate(-1);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }


  return(
    <div>
    {(testabgabe === false) && 
     <Fragment>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="250" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Eignungstest</Typography> 
                  <Typography variant="h4" style={{margin:"15px"}}>
                     Prima, dass Du den Eignungstest machst und <br />
                     Deine Fähigkeiten unter Beweis stellst. <br />
                  </Typography> 
                  <Typography>
                     Informationen zur Datenverarbeitung im Rahmen Ihrer Bewerbung entnimmst Du bitte <br />
                     der "Allgemeinen Information nach DS-GVO für Bewerber der Firma Eckhardt GmbH, <br />
                     die Du mit der Eingangsbestätigung Deiner Bewerbung per E-Mail erhalten hast.<br />
                  </Typography> 

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der komplette Test besteht aus 3 Einzeltests. <br />
                  Für die ersten zwei Tests hast Du jeweils 45 Minuten Zeit. <br />
                  Für den dritten Test hast Du 15 Minunten Zeit. <br />
                  Jeder Test kann nur einmal aktiviert werden.<br />
                  Erst wenn der erste Test abgegeben wurde, folgt der zweite Test. <br />
                  Nach Abgabe von Test 2 folgt Test 3. <br />
                  Zwischen den Tests kannst Du kurze Pausen machen. <br />
                  Fange erst mit dem Test an, wenn Du mindestens 105 Minuten dran bleiben kannst,<br />
                  da ein erneutes Einloggen nicht möglich ist.<br />
              </ListItemText>  
          </ListItem>
      </Grid>
      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Beginnen wir mit dem ersten Test.  <br />
                  Für diesen Test hast Du 45 Minuten Zeit. <br />
                  Der Countdown beginnt, sobald Du den Button "MIT DEM TEST BEGINNEN" anklickst.  <br />
                  Nehme Dir am besten ein Stift, Papier und Taschenrechner zur Hand, um die Werte strukturiert addieren zu können.<br />
                  Gerne kannst Du Dir auch ein Tabellenprogramm öffnen, um die Berechnungen durchzuführen.<br />
              </ListItemText>  
          </ListItem>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                3
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Schaue Dir als erstes das Tutorial an, damit Du darüber informiert bist, was zu tun ist.<br />
                  Das Tutorial gilt gleichermaßen für Entgeltabrechner/innen und Backend-Developer/innen <br />
            </ListItemText>  

          </ListItem>
          <Grid item xs={8}>
            <Player
                src={tutorial}
   //           src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                4
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Klicke auf Lohnsteuersteuertabelle, um sie zu öffnen. Du brauchst sie, um die Lohnsteuer in der Aufgabe zu bestimmen."/>
          </ListItem>
          <Grid item xs={8}>
              <Button 
                fullWidth 
                alignItems="center"
                color="#4F4F4F"
                variant="contained"
                href={lsttabpdf}
                download
                >
                Lohnsteuertabelle öffnen
              </Button>
          </Grid>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
              <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    5
                  </Avatar>
              </ListItemAvatar>
              <ListItemText>
                  Vergiss nicht am Ende des Tests den Button "Test abgeben" anzuklicken, <br />
                  um den Test auch wirklich abzugeben. <br />
              </ListItemText>
          </ListItem>
      </Grid>

      <Grid container spacing={1} style={{margin:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <Grid item xs={8}>
              <Button 
    //              fullWidth 
                  alignItems="left"
                  backgroundColor="#4F4F4F"
                  variant="contained"
                  onClick={() => navigate('/011/teil1')} 
                  >
                  Mit dem Test beginnen
              </Button>
          </Grid>
      </Grid>
    </Fragment>
    }
    {(testabgabe === true) && 
    <Fragment>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} alignItems={"center"} >
          <Grid item xs={2}>
              <img src={acadjpg} height="250" alt="ectool" loading="lazy"/>
          </Grid>

          <Grid item xs={1}>
          </Grid>
          <Grid item xs={8}>
              <Typography variant="h5" align='left'>
                Du hast Deinen kompletten Test am {abgabedat} um {abgabezeit} Uhr bei ecschool abgegeben. <br /> 
                Ein weiteres Mal brauchst Du den Eignungstest nicht durchzuführen. <br />
                Hast Du weitere Fragen?<br />
                Dann schreibe einfach eine eMail an bewerbung@ectool.de<br />

              </Typography>
          </Grid>
        </Grid>
      </Paper>
      </Fragment>
    }
  </div>
  )
}     


export default Eignungstest;
