import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Kursabgeben } from '../../components/Rahmendaten/Kursabgeben';
import { Briefkopf } from '../../components/Rahmendaten/Briefkopf';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import ForwardIcon from '@mui/icons-material/Forward';
import FeedIcon from '@mui/icons-material/Feed';
import CalculateIcon from '@mui/icons-material/Calculate';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';
import { Tabelle2spaltig } from './../Rahmendaten/Tabelle2spaltig'

function BAV(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate = useNavigate();
  const [antworten, setAntworten] = useState({});
  const [testabgabe, setTestabgabe] = useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste, setFehlerliste] = useState([]);
  const [anzFehler, setAnzFehler] = useState('99')
  const { showErrorMessage } = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [ausgewaehlteLohnarten, setAusgewaehlteLohnarten] = useState([]);
  const [reactnummer] = useState('048');
  const [merkmal] = useState("BAV");
  const einrueck = "50px";

  var gehalt = '2700.00', bav100 = "-100.00", summe_stpfl = "2600.00", summe_svpfl = "2600.00"
  var gehalt20 = '2700.00', bav20 = "-80.00", summe_stpfl20 = "2620.00", summe_svpfl20 = "2620.00"
  const setzeAntworten = (key, value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen = (fehler) => {
    console.log("fehler: " + fehler)
    let array = fehler.split(";");
    let fehlerzwis = []
    array.map(
      // eslint-disable-next-line
      (objekt) => {
        fehlerzwis[objekt] = true;
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "ausgewaehlteLoas": ausgewaehlteLohnarten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    }
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }


  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6, 8) + "." + response.data.abgabedat.slice(4, 6) + "." + response.data.abgabedat.slice(0, 4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const linkCb = (response) => {
    if (response.status === 200) {
      if (response.data.rehmlink !== "") {
        window.open(response.data.rehmlink)
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }

  }

  const LexikonLohnbüro = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": "getLink",
      "reactnummer": "046"
    }
    apiRequest('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
  }

  const kug_tabelle = () => {
    setRedirect();
  }

  return (
    <div>
      <Briefkopf
        ueberschrift1="Die betriebliche Altersvorsorge (bAV)"
        ueberschrift2="Mit Hilfe der Firma für die Rente sparen"
      />

      <Grid container spacing={0} style={{ marginTop: '15px', display: "flex" }} >
        <Grid item xs={12} style={{ textAlign: "left" }} >
          <Typography variant="h5" color="primary" >
            Die betriebliche Altersvorsorge (bAV)
          </Typography>
          <Typography variant='h5'>
            Unter <b>betrieblicher Altersvorsorge (bAV)</b> versteht man den Aufbau einer Zusatzrente über den Arbeitgeber<br />
            Sie ist eine Form der Alterssicherung, die von Arbeitgebern für ihre Mitarbeiter angeboten wird. Sie soll den Arbeitnehmern eine zusätzliche Einkommensquelle im Ruhestand bieten,
            um die gesetzliche Rente aufzustocken. <br />
            Die bAV ist im Betriebsrentengesetz (BetrAVG) geregelt und bietet verschiedene Durchführungswege,<br />
            wie zum Beispiel:
          </Typography>

          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Direktversicherung
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Pensionskasse
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Pensionfond
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Unterstützungskasse
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
          <Typography variant='h5'>
            Die wesentlichen Vorteile der betrieblichen Altersvorsorge sind:
          </Typography>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Steuerliche Vorteile<br />
                Beiträge zur bAV können steuer- und sozialversicherungsfrei sein, solange bestimmte Grenzen eingehalten werden. Das bedeutet, dass das Bruttoeinkommen des Arbeitnehmers reduziert wird und somit weniger Steuern und Sozialversicherungsbeiträge anfallen.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Arbeitgeberzuschuss (Der Arbeitgeber beteiligt sich an der betrieblichen Altersvorsorge) <br />
                Gem. dem Betriebsrentengesetz ist der Arbeitgeber dazu verpflichtet, sich an der betrieblichen Altersvorsorge mit einem Zuschuss zu beteiligen. <br />
                Dies erhöht die Rentenanwartschaften der Arbeitnehmer.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Absicherung im Alter<br />
                Die bAV bietet Schutz vor Altersarmut und kann eine wichtige Ergänzung zur gesetzlichen Rente sein.
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
          <Typography variant='h5'>
            Für die Finanzierung (Einzahlung) der betrieblichen Altersvorsorge gibt es zwei verschiedene Finanzierungsmöglichkeiten.
          </Typography>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Arbeitgeberfinanzierte bAV <br />
                Bei der arbeitgeberfinanzierten bAV trägt der Arbeitgeber die Beiträge allein oder übernimmt zumindest einen Teil der Beiträge. <br />
                Der Arbeitnehmer profitiert von dieser Form der bAV, da er zusätzliche Rentenansprüche aufbaut, ohne selbst Geld einzahlen zu müssen.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Arbeitnehmerfinanzierte bAV<br />
                Bei der arbeitnehmerfinanzierten bAV zahlt der Arbeitnehmer selbst Beiträge aus seinem Bruttogehalt in die Altersvorsorge ein. <br />
                Diese Beiträge werden in der Regel steuer- und sozialversicherungsfrei gestellt, wodurch der Arbeitnehmer seine Abgabenlast reduziert. <br />
                Der Arbeitgeber kann optional einen Zuschuss leisten, um die Altersvorsorge des Arbeitnehmers zu unterstützen.
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>

        <ListItem>
          <ListItemAvatar style={{ marginLeft: einrueck }}>
            <Avatar style={{ backgroundColor: "#1fbba6" }}>
              <ForwardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5">
              Es ist auch möglich, dass sowohl der Arbeitgeber als auch der Arbeitnehmer Beiträge zur betrieblichen Altersvorsorge leisten. Die genauen Modalitäten werden in einer Betriebsvereinbarung oder einem Versicherungsvertrag festgelegt.
            </Typography>
          </ListItemText>
        </ListItem>

      </Grid>

      <Grid container spacing={0} style={{ marginTop: '30px', display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }} >
        <Grid >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                R
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Eigenrecherche im Lexikon für das Lohnbüro
              </Typography>
              <Typography style={{ textAlign: "left" }}>
                Mit Klick auf den Button gelangst Du auf das digitale Lexikon für das Lohnbüro. <br />
                Benutze das Lexikon, um Dich auf dem Gebiet der betrieblichen Altersvorsorge schlau zu machen.<br />
                Mit dem Stichwort "Zukunftssicherung" steht Dir reichlich Informationsmaterial im Lexikon zur Verfügung.<br />
              </Typography>
              <Button
                color="primary"
                variant="contained"
                alignItems="center"
                backgroundColor="#4F4F4F"
                style={{ align: "center" }}
                onClick={() => LexikonLohnbüro()}
              >
                Lexikon für das Lohnbüro
              </Button>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "60px", height: "60px" }}>
                <CalculateIcon style={{ width: "50px", height: "50px" }}></CalculateIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                <strong>Legen wir los und zeigen den Brutto- und Nettoblock bei der Anwendung einer einfachen bAV-Regelung.</strong><br />
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                Beispiel 1<br />
                Ein Arbeitnehmer möchte monatlich 100,- Euro in eine bAV einzahlen. <br />
                Die bAV wird alleine vom Arbeitnehmer finanziert. Der Arbeitgeber bezahlt hierfür keinen Zuschuss.<br />
                Der u. a. Bruttoblock zeigt Dir auf, wie die steuer- und beitragsrechtliche Behandlung der bAV auf der Entgeltabrechnung ausgewiesen wird.
              </Typography>

            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={3}
            Lohnart={['1029', '0760']}
            Intern={['029', '760']}
            Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00']}
            Stunden={["174,00", '0,00']}
            Faktor={[gehalt, bav100]}
            Kennung={["LFD", 'LFD']}
            Prozent={["0,00", '0,00']}
            Steuerpflichtig={[gehalt, bav100, summe_stpfl]}
            Sozialverspflichtig={[gehalt, bav100, summe_svpfl]}
            Betrag={[gehalt, "0,00", gehalt]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
          <Typography align="left">
            Bei 100,- Euro monatlicher bAV werden die Grenzen für die Steuer- und Sozialversicherungsfreiheit eingehalten. <br />
            Somit wird die arbeitnehmerfinanzierte bAV bei dem zu versteuernden und verbeitragenden Verdienst abgezogen. <br />
            Das bedeutet, dass in diesem Beispiel nur aus 2600,- Euro Steuern und Sozialversicherungsbeiträge zu bezahlen sind.
            Der Verdienst bleibt aber dennoch bei 2700,- Euro. <br />
          </Typography>
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={1}
            text=''
            NLohnart={['2301']}
            NIntern={['301']}
            NBezeichnung={["Bezahlung bAV"]}
            NBetrag={["100,00"]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography align="left">
            Die tatsächliche Bezahlung der bAV erfolgt über eine Nettolohnart. Hierbei gibt es zwei unterschiedliche Fälle der Bezahlung:
          </Typography>

          <ListItem>
            <ListItemAvatar >
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h7">
                Werden die Beiträge zur bAV per Einzugsermächtigung (Lastschriftverfahren) beim Arbeitgeber abgebucht, ist eine Lohnart zu verwenden, bei welcher nur ein Abzug stattfindet.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar >
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h7">
                Soll hingegen die bAV mit der Lohnabrechnung an das jeweilige Versicherungsinstitut überwiesen werden, ist eine Lohnart zu verwenden, bei welcher eine Bankverbindung und Verwendungszweck erfasst werden kann.
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" align='left' style={{ marginLeft: "10px" }}>
            Soweit, so gut!<br />
            Nun zeigen wir Dir auf, wie sich der Brutto- und Nettoblock darstellt, wenn die bAV vom Arbeitgeber bezuschusst wird!<br />
          </Typography>
        </Grid>

        <ListItem >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
              <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              Erweiterung Beispiel 1<br />
              Die bAV des Arbeitnehmers über 100,- Euro wird vom Arbeitgeber mit 20% monatlich bezuschusst.<br />
            </Typography>
          </ListItemText>
        </ListItem>


        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={3}
            Lohnart={['1029', '0760']}
            Intern={['029', '760']}
            Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00']}
            Stunden={["174,00", '0,00']}
            Faktor={[gehalt, bav20]}
            Kennung={["LFD", 'LFD']}
            Prozent={["0,00", '0,00']}
            Steuerpflichtig={[gehalt, bav20, summe_stpfl20]}
            Sozialverspflichtig={[gehalt, bav20, summe_svpfl20]}
            Betrag={[gehalt, "0,00", gehalt20]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
          <Typography align="left">
            Die bAV wird nun mit 20,- Euro (20%) bezuschusst. Dadurch ändert sich der arbeitnehmerfinanzierte Betrag zwangsläufig auf 80,- Euro. Somit werden auch nur 80,- Euro bei dem zu versteuernden und verbeitragenden Verdienst abgezogen. <br />
            Das bedeutet, dass in diesem Beispiel aus 2620,- Euro Steuern und Sozialversicherungsbeiträge zu bezahlen sind.
            Da sich der  bAV-Betrag nicht geändert hat, werden die Grenzen für die Steuer- und Sozialversicherungsfreiheit weiterhin eingehalten. <br />
          </Typography>
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={2}
            text=''
            NLohnart={['0761', '2301']}
            NIntern={['761', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bezahlung bAV"]}
            NBetrag={["-20,00", "100,00"]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography align="left">
            Da sich der  bAV-Betrag nicht geändert hat, werden die Grenzen für die Steuer- und Sozialversicherungsfreiheit für den Arbeitgeberzuschuss weiterhin eingehalten. <br />
            Demnach ist der 20%-ige Arbeitgeberzuschuss auch steuer- und sv-frei und wird im Nettoblock vergütet.<br />
            In der Regel werden Nettolohnarten vom Netto abgezogen und werden deshalb nicht explizit mit einem vorstehenden Minuszeichen dargestellt.
            Wird entgegen der Regel eine Nettolohnart nicht abgezogen, sondern vergütet, wird sie mit einem vorangestellten Minuszeichen hervorgehoben.
            Deshalb wird die Auszahlung des Arbeitgeberzuschusses mit einem Minuszeichen dargestellt.
            Die Nettolohnart "Bezahlung bAV" verändert sich in diesem Beispiel nicht, da sich der Betrag der bAV auch nicht verändert hat.
          </Typography>
        </Grid>


        <Grid item xs={6}> </Grid>
        <Grid item xs={6} style={{ marginTop: "30px" }}>
          <Typography align="left">
            <b>Wieviel Aufwand entsteht nun eigentlich dem Arbeitnehmer bei einer bAV?</b> <br />
            In der folgenden Berechnung wird der eigentliche Aufwand für den Arbeitnehmer dargestellt:
          </Typography>

          <Tabelle2spaltig
            anzReihen="4"
            ueberschrift="Kosten der bAV"
            uebschr_spalte1="Bezeichnung"
            spalte1Platz="left"
            uebschr_spalte2="Wert"
            spalte2Platz="right"
            spalten1={["bAV-Betrag =", "Steuer- SV-Ersparniss aus 80,- Euro (hierbei wird von circa 20% Steuer und 20% SV ausgegangen) =", "Arbeitgeberzuschuss 20,00 Euro =", "errechneter (effektiver) Aufwand ="]}
            spalten2={["100,00", "-36,00", "-20,00", "44,00"]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
          <Typography align="left" style={{ marginTop: "10px" }}>
            Bei einer Einzahlung in eine bAV von 100,- Euro entstehen dem Arbeitnehmer kosten in Höhe von ca. 44,- Euro.<br />
            Dieser Betrag kann nicht verallgemeinert werden, da die Kosten bzw. die Ersparniss von unterschiedlichen Faktoren (Steuerklasse, Beitragssätze zur SV) abhängen.
          </Typography>

        </Grid>


        <ListItem style={{ marginTop: "30px" }}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#1fbba6", width: "60px", height: "60px" }}>
              <CalculateIcon style={{ width: "50px", height: "50px" }}></CalculateIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              <strong>Nun genug der Theorie!<br />
                Lege am besten gleich los und erstelle folgenden Sachverhalt auf der Entgeltabrechnung: </strong><br />
              Ein Arbeitnehmer möchte monatlich in eine bAV 200,- Euro einzahlen. Der Arbeitgeber bezuschusst die bAV mit einem festen monatlichen Betrag
              in Höhe von 50,- Euro.
            </Typography>

          </ListItemText>
        </ListItem>

        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Bruttoblock</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={3}
            tabellenKey="1"
            Lohnart={['1029', '0760']}
            Intern={['029', '760']}
            Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00']}
            Stunden={["174,00", '0,00']}
            Faktor={[gehalt, ""]}
            Kennung={["LFD", 'LFD']}
            Prozent={["0,00", '0,00']}
            Steuerpflichtig={[gehalt, "", ""]}
            Sozialverspflichtig={[gehalt, "", ""]}
            Betrag={[gehalt, "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={2}
            tabellenKey="1"
            text=''
            NLohnart={['0761', '2301']}
            NIntern={['761', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bezahlung bAV"]}
            NBetrag={["", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

      </Grid>


      <Kursabgeben anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />

    </div>
  )
}


export default BAV;
