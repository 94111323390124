import React, {useState, useCallback} from 'react';
import {Avatar, Typography, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
import { TabelleEGA} from './tabelleega';
import {KvRvTabelle} from './KvRv Tabelle';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
const VIDEOSVLUFT = '/media/Chat-Tutorial_Sozialversicherungsluft.m4v';
const VIDEOBERECHNUNG = '/media/Berechnung von SV-Beiträgen.m4v';
const brutto1 = '/media/Bruttozeilen1.png';
const brutto2 = '/media/Bruttozeilen 2.png';
const POSTER = '/media/Grafik_Filmrolle.png';


function Bbgega(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('014');
  const [merkmal]=useState('Bbgega');


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      'hsutkey': hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    console.log(response.data.message);

    if (response.status === 200) {
      setTestabgabe(true);
      fehlerAusLesen(response.data.fehler)
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
            ueberschrift1="Beitragsbemessungsgrenze" 
            ueberschrift2="Ermittlung der SV-Luft und Berechnung der Beiträge" 
      />

      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEOSVLUFT}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
      </Grid>


      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={10} style={{}}>
            <Typography variant="h3"  color="primary">
              So berechnen sich die Beiträge der Sozialversicherung
            </Typography>     
            <Player
                poster={POSTER}
                src={VIDEOBERECHNUNG}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h4"  color="primary">
              Beitragssätze 2020
            </Typography>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderTop:'solid', borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             KV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderTop:'solid', borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            14,60 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             RV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            18,60 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             AV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:"solid", height:'30px'}}>
          <Typography>
            2,40 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderLeft:"solid", borderRight:"solid", height:'30px'}}>
          <Typography>
             PV Allgemein
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderRight:"solid", height:'30px'}}>
          <Typography>
            3,05 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={4} style={{borderLeft:'solid', borderBottom:'solid', borderRight:"solid", height:'60px'}}>
          <Typography>
             Beitragszuschlag für kinderlose Arbeitnehmer
          </Typography>
        </Grid>
        <Grid item xs={4} style={{borderBottom:'solid', borderRight:'solid', height:'60px'}}>
          <Typography>
            0,25 %
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>



      </Grid>

      <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>

          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h4"  color="primary">
                  Aufgabe 1 <br/>
                  Berechne die jeweilige Tages-BBG
                </Typography>
              </ListItemText>
          </ListItem>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'10px',backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={2} style={{borderBottom:'solid',height:'30px' }}>
        
        </Grid>
        <Grid item xs={2} style={{border:'solid',height:'30px'}}>
          <Typography>
            Monats-BBG
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',borderTop:'solid', height:'30px'}}>
          <Typography>
            Tages-BBG
          </Typography>
        </Grid>
       
        <Grid item xs={6} style={{}}>
          
        </Grid>

        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             KV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
            4.687,50€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
        <TextField  
             id='tagesKVw' 
             type='number'
             error={fehlerliste["tagesKVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('tagesKVw',event.target.value)}/>
        </Grid>
        <Grid item xs={6}>
          
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             RV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
            6.900,00€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid'}}>
        <TextField  
             id='tagesRVw' 
             type='number'
             error={fehlerliste["tagesRVw"]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('tagesRVw',event.target.value)}/>
        </Grid>
        <Grid item xs={6} >
          
        </Grid>
    </Grid>



    <Grid container spacing={0} style={{marginTop:'50px',backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
        <ListItem style={{marginTop:'50px'}}>
              <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                      2
                  </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <Typography variant="h4"  color="primary">
                     Aufgabe 2 <br/>
                  </Typography>
                  <Typography variant="h6">
                     Abrechnungsmonat September <br/>
                     Der Arbeitnehmer ist kinderlos <br/>
                     Der kassenindividuelle Beitragssatz (kiBs) beträgt 0,9%<br/>
                  </Typography>
                  <Typography variant="h5" style={{marginTop:'15px'}} >
                     Erstelle alle notwendigen Berechnungen und fülle die Tabellen und den grünen SV-Block vollständig aus.
                  </Typography>
              </ListItemText>
        </ListItem>
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px',backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
          <Grid item >
              <img src={brutto1} height="140" alt="ectool" loading="lazy"/>
          </Grid>
    </Grid>


    <KvRvTabelle tabellenKey="1" setzeAntworten={setzeAntworten} fehlerliste={fehlerliste}/>
    <TabelleEGA tabellenKey="1" aufgabe='2.1' setzeAntworten={setzeAntworten} fehlerliste={fehlerliste}/>

    <Grid item xs={1}>
    </Grid>
     
    <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
      <ListItem style={{marginTop:'50px'}}>
          <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                  3
              </Avatar> 
          </ListItemAvatar>
          <ListItemText>
              <Typography variant="h4"  color="primary">
                 Aufgabe 3 <br/>
              </Typography>
              <Typography variant="h6">
               Abrechnungsmonat September <br/>
               Der Arbeitnehmer ist kinderlos <br/>
               Der kassenindividuelle Beitragssatz (kiBs) beträgt 1,1%<br/>
             </Typography>
             <Typography variant="h5" style={{marginTop:'15px'}} >
               Erstelle alle notwendigen Berechnungen und fülle die Tabellen und den grünen SV-Block vollständig aus.
             </Typography>
          </ListItemText>
      </ListItem>


        <Grid item >
              <img src={brutto2} height="140" alt="ectool" loading="lazy"/>
        </Grid>
    </Grid>


    <KvRvTabelle tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleEGA tabellenKey="2" aufgabe='3.1' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>


     </div>
    )
}


export default Bbgega;