import { Paper, TableCell, TextField } from '@material-ui/core'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { Eingabe } from './Eingaben'

const Lohnsteuerbescheinigung = ({ fehlerliste, setzeAntworten, antworten, daten, tabellenKey, ueberschrift1, ueberschrift2, ...props }) => {

  // const daten = {Kirchensteuermerkmale:"", Jahreshinzurechnungsbetrag:"", SteuerfreierJahr:"", Kinderfreibeträge:"145", Steuerklasse:"", Transferticket:"", Geburtsdatum:"", PersNr:"", id: "", eTIN: "", datum: "", d1: "1245", d2: "1447", d3: "", d4: "", d5: "", d6: "", d7: "", d8: "", d9: "", d10: "", d11: "", d12: "", d13: "", d14: "", d15: "", d15: "", d16a: "",d16b: "45527454", }

  if (daten.Kirchensteuermerkmale !== '') antworten["Kirchensteuermerkmale" +tabellenKey]=daten.Kirchensteuermerkmale;
  if (daten.Jahreshinzurechnungsbetrag !== '') antworten["Jahreshinzurechnungsbetrag"+tabellenKey]=daten.Jahreshinzurechnungsbetrag;
  if (daten.SteuerfreierJahr !== '') antworten["SteuerfreierJahr"+tabellenKey]=daten.SteuerfreierJahr;
  if (daten.Kinderfreibeträge !== '') antworten["Kinderfreibeträge"+tabellenKey]=daten.Kinderfreibeträge;
  if (daten.Steuerklasse !== '') antworten["Steuerklasse"+tabellenKey]=daten.Steuerklasse;
  if (daten.Transferticket !== '') antworten["Transferticket"+tabellenKey]=daten.Transferticket;
  if (daten.Geburtsdatum !== '') antworten["Geburtsdatum"+tabellenKey]=daten.Geburtsdatum;
  if (daten.PersNr !== '') antworten["PersNr"+tabellenKey]=daten.PersNr;
  if (daten.id !== '') antworten["id"+tabellenKey]=daten.id;
  if (daten.eTIN !== '') antworten["eTIN"+tabellenKey]=daten.eTIN;
  if (daten.datum !== '') antworten["datum"+tabellenKey]=daten.datum;
  if (daten.d1a !== '') antworten["d01a"+tabellenKey]=daten.d1a;
  if (daten.d1b !== '') antworten["d01b"+tabellenKey]=daten.d1b;
  if (daten.d2 !== '') antworten["d02"+tabellenKey]=daten.d2;
  if (daten.d3 !== '') antworten["d03"+tabellenKey]=daten.d3;
  if (daten.d4 !== '') antworten["d04"+tabellenKey]=daten.d4;
  if (daten.d5 !== '') antworten["d05"+tabellenKey]=daten.d5;
  if (daten.d6 !== '') antworten["d06"+tabellenKey]=daten.d6;
  if (daten.d7 !== '') antworten["d07"+tabellenKey]=daten.d7;
  if (daten.d8 !== '') antworten["d08"+tabellenKey]=daten.d8;
  if (daten.d9 !== '') antworten["d09"+tabellenKey]=daten.d9;
  if (daten.d10 !== '') antworten["d10"+tabellenKey]=daten.d10;
  if (daten.d11 !== '') antworten["d11"+tabellenKey]=daten.d11;
  if (daten.d12 !== '') antworten["d12"+tabellenKey]=daten.d12;
  if (daten.d13 !== '') antworten["d13"+tabellenKey]=daten.d13;
  if (daten.d14 !== '') antworten["d14"+tabellenKey]=daten.d14;
  if (daten.d15 !== '') antworten["d15"+tabellenKey]=daten.d15;
  if (daten.d16 !== '') antworten["d16"+tabellenKey]=daten.d16;
  if (daten.d17 !== '') antworten["d17"+tabellenKey]=daten.d17;
  if (daten.d18 !== '') antworten["d18"+tabellenKey]=daten.d18;
  if (daten.d19 !== '') antworten["d19"+tabellenKey]=daten.d19;
  if (daten.d20 !== '') antworten["d20"+tabellenKey]=daten.d20;
  if (daten.d21 !== '') antworten["d21"+tabellenKey]=daten.d21;
  if (daten.d22a !== '') antworten["d22a"+tabellenKey]=daten.d22a;
  if (daten.d22b !== '') antworten["d22b"+tabellenKey]=daten.d22b;
  if (daten.d23a !== '') antworten["d23a"+tabellenKey]=daten.d23a;
  if (daten.d23b !== '') antworten["d23b"+tabellenKey]=daten.d23b;
  if (daten.d24a !== '') antworten["d24a"+tabellenKey]=daten.d24a;
  if (daten.d24b !== '') antworten["d24b"+tabellenKey]=daten.d24b;
  if (daten.d24c !== '') antworten["d24c"+tabellenKey]=daten.d24c;
  if (daten.d25 !== '') antworten["d25"+tabellenKey]=daten.d25;
  if (daten.d26 !== '') antworten["d26"+tabellenKey]=daten.d26;
  if (daten.d27 !== '') antworten["d27"+tabellenKey]=daten.d27;
  if (daten.d28 !== '') antworten["d28"+tabellenKey]=daten.d28;
  if (daten.d29 !== '') antworten["d29"+tabellenKey]=daten.d29;
  if (daten.d30 !== '') antworten["d30"+tabellenKey]=daten.d30;
  if (daten.d31 !== '') antworten["d31"+tabellenKey]=daten.d31;
  if (daten.d32 !== '') antworten["d32"+tabellenKey]=daten.d32;
  if (daten.d33 !== '') antworten["d33"+tabellenKey]=daten.d33;
  if (daten.d34 !== '') antworten["d34"+tabellenKey]=daten.d34;

  return (
    <div>
      <h1> {ueberschrift1}</h1>
      <h3>{ueberschrift2}</h3>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%", marginRight: "30px" }}>
          <TableContainer component={Paper} >
            <Table  >
              <TableBody>
                <TableRow   >
                  <TableCell align="left"  >
                    Datum
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe
                      datum
                      disabled={daten?.datum !== ""}
                      error={fehlerliste["datum" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["datum" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["datum" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('datum' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    eTIN
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe
                      disabled={daten?.eTIN !== ""}
                      error={fehlerliste["eTIN" + tabellenKey]}
                      style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["eTIN" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["eTIN" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('eTIN' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Identifikationsnummer:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe
                      disabled={daten?.id !== ""}
                      error={fehlerliste["id" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["id" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["id" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('id' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Personalnummer:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.PersNr !== ""}
                      error={fehlerliste["PersNr" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["PersNr" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["PersNr" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('PersNr' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Geburtsdatum:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Geburtsdatum !== ""}
                      error={fehlerliste["Geburtsdatum" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Geburtsdatum" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Geburtsdatum" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Geburtsdatum' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>

                <TableRow   >
                  <TableCell align="left"  >
                    Transferticket:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Transferticket !== ""}
                      error={fehlerliste["Transferticket" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Transferticket" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Transferticket" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Transferticket' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>


          <h3 align="left" style={{ marginTop: "40px", marginBottom: "40px" }}>Dem Lohnsteuerabzug wurden im letzten Lohnzahlungszeitraum zugrunde gelegt:</h3>

          <TableContainer component={Paper}>
            <Table>
              <TableBody>

                <TableRow   >
                  <TableCell align="left"  >
                    Steuerklasse/Faktor
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Steuerklasse !== ""}
                      error={fehlerliste["Steuerklasse" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Steuerklasse" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Steuerklasse" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Steuerklasse' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Zahl der Kinderfreibeträge:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Kinderfreibeträge !== ""}
                      error={fehlerliste["Kinderfreibeträge" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Kinderfreibeträge" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Kinderfreibeträge" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Kinderfreibeträge' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Steuerfreier Jahresbetrag:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe
                      disabled={daten?.SteuerfreierJahr !== ""}
                      error={fehlerliste["SteuerfreierJahr" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["SteuerfreierJahr" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["SteuerfreierJahr" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('SteuerfreierJahr' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow   >
                  <TableCell align="left"  >
                    Jahreshinzurechnungsbetrag:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Jahreshinzurechnungsbetrag !== ""}
                      error={fehlerliste["Jahreshinzurechnungsbetrag" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Jahreshinzurechnungsbetrag" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Jahreshinzurechnungsbetrag" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Jahreshinzurechnungsbetrag' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>

                <TableRow   >
                  <TableCell align="left"  >
                    Kirchensteuermerkmale:
                  </TableCell>
                  <TableCell align="right">
                    <Eingabe

                      disabled={daten?.Kirchensteuermerkmale !== ""}
                      error={fehlerliste["Kirchensteuermerkmale" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["Kirchensteuermerkmale" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["Kirchensteuermerkmale" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('Kirchensteuermerkmale' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

        </div>
        <div style={{ width: "50%" }}>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow style={{ height: '33px' }} >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    1. Bescheinigungszeitraum
                  </TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    von
                    <Eingabe
                      datum
                      disabled={daten?.d1a !== ""}
                      error={fehlerliste["d01a" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d1" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d01a" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d01a' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    bis
                    <Eingabe
                      datum
                      disabled={daten?.d1b !== ""}
                      error={fehlerliste["d01b" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d1" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d01b" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d01b' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>
                </TableRow>

                <TableRow style={{ height: '33px' }} >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >2. Zeiträume ohne Anspruch auf Arbeitslohn</TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>Anzahl „U“</TableCell>
                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} > Großbuchstaben (E, S, M, F, FR)</TableCell>

                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      text
                      disabled={daten?.d2 !== ""}
                      error={fehlerliste["d02" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d2" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d02" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d02' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '33px' }}  >

                </TableRow>
                <TableCell></TableCell>
                <TableCell>EUR,Ct</TableCell>


                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    3. Bruttoarbeitslohn einschl. Sachbezüge ohne 9.
                    und 10.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      
                      disabled={daten?.d3 !== ""}
                      error={fehlerliste["d03" + tabellenKey]}
                   //   style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d3" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d03" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d03' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>

                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    4. Einbehaltene Lohnsteuer von 3
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d4 !== ""}
                      error={fehlerliste["d04" + tabellenKey]}
                      style={{textAlign: "right", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d4" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right"} }}
                      value={antworten["d04" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d04' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>
                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    5. Einbehaltener Solidaritätszuschlag von 3.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d5 !== ""}
                      error={fehlerliste["d05" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d5" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d05" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d05' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    6. Einbehaltene Kirchensteuer des Arbeitnehmers
                    von 3
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d6 !== ""}
                      error={fehlerliste["d06" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d6" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d06" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d06' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    7. Einbehaltene Kirchensteuer des Ehegatten/Lebenspartners
                    von 3. (nur bei Konfessionsverschiedenheit)
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d7 !== ""}
                      error={fehlerliste["d07" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d7" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d07" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d07' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    8. In 3. enthaltene Versorgungsbezüge
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d8 !== ""}
                      error={fehlerliste["d08" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d8" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d08" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d08' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    9. Ermäßigt besteuerte Versorgungsbezüge für
                    mehrere Kalenderjahre
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d9 !== ""}
                      error={fehlerliste["d09" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d9" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d09" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d09' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    10. Ermäßigt besteuerter Arbeitslohn für mehrere Kalenderjahre
                    (ohne 9.) und ermäßigt besteuerte Entschädigungen
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d10 !== ""}
                      error={fehlerliste["d10" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d10" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d10" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d10' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    11. Einbehaltene Lohnsteuer von 9. und 10.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d11 !== ""}
                      error={fehlerliste["d11" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d11" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d11" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d11' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    12. Einbehaltener Solidaritätszuschlag von 9. und 10.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d12 !== ""}
                      error={fehlerliste["d12" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d12" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d12" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d12' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    13. Einbehaltene Kirchensteuer des Arbeitnehmers
                    von 9. und 10.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d13 !== ""}
                      error={fehlerliste["d13" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d13" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d13" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d13' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    14. Einbehaltene Kirchensteuer des Ehegatten/Lebenspartners
                    von 9. und 10. (nur bei Konfessionsverschiedenheit)
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d14 !== ""}
                      error={fehlerliste["d14" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d14" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d14" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d14' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    15. (Saison-)Kurzarbeitergeld, Zuschuss zum Mutterschaftsgeld,
                    Verdienstausfallentschädigung (Infektionsschutzgesetz),
                    Aufstockungsbetrag und Altersteilzeitzuschlag
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d15 !== ""}
                      error={fehlerliste["d15" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d15" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d15" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d15' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    <TableCell style={{ width: "10px" }} align="left" > 16. Steuerfreier Arbeitslohn nach</TableCell>
                    <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                      <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >a) Doppelbesteuerungsabkommen (DBA) </TableCell> </TableRow>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >b) Auslandstätigkeitserlass </TableCell> </TableRow>
                    </TableCell>
                  </TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    <TableRow>

                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d16a !== ""}
                          error={fehlerliste["d16a" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d16a" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d16a" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d16a' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d16b !== ""}
                          error={fehlerliste["d16b" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d16b" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d16b" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d16b' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>

                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    17. Steuerfreie Arbeitgeberleistungen, die auf die
                    Entfernungspauschale anzurechnen sind
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d17 !== ""}
                      error={fehlerliste["d17" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d17" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d17" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d17' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    18. Pauschal mit 15 % besteuerte Arbeitgeberleistungen für
                    Fahrten zwischen Wohnung und erster Tätigkeitsstätte
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d18 !== ""}
                      error={fehlerliste["d18" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d18" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d18" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d18' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    19. Steuerpfichtige Entschädigungen und Arbeitslohn für mehrere
                    Kalenderjahre, die nicht ermäßigt besteuert wurden - in 3.
                    enthalten
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d19 !== ""}
                      error={fehlerliste["d19" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d19" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d19" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d19' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    20. Steuerfreie Verpfegungszuschüsse bei Auswärtstätigkeit
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d20 !== ""}
                      error={fehlerliste["d20" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d20" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d20" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d20' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    21. Steuerfreie Arbeitgeberleistungen bei doppelter
                    Haushaltsführung
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d21 !== ""}
                      error={fehlerliste["d21" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d21" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d21" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d21' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    <TableCell style={{ width: "10px" }} align="left" > 22. Arbeitgeberanteil/-zuschuss</TableCell>
                    <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                      <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >a) zur gesetzlichen Rentenversicherung</TableCell> </TableRow>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >b) an berufsständische Versorgungseinrichtungen </TableCell> </TableRow>
                    </TableCell>
                  </TableCell>

                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    <TableRow>
                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d22a !== ""}
                          error={fehlerliste["d22a" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d22a" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d22a" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d22a' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d22b !== ""}
                          error={fehlerliste["d22b" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d22b" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d22b" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d22b' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>

                  </TableCell>


                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    <TableCell style={{ width: "10px" }} align="left" >23. Arbeitnehmeranteil</TableCell>
                    <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                      <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >a) zur gesetzlichen Rentenversicherung</TableCell> </TableRow>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >b) an berufsständische Versorgungseinrichtungen </TableCell> </TableRow>
                    </TableCell>

                  </TableCell>

                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    <TableRow>
                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d23a !== ""}
                          error={fehlerliste["d23a" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d23a" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d23a" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d23a' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                        <Eingabe
                          disabled={daten?.d23b !== ""}
                          error={fehlerliste["d23b" + tabellenKey]}
                          // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d23b" + tabellenKey] === true) ? "red" : "" }}
                          inputProps={{ style: { textAlign: "right" } }}
                          value={antworten["d23b" + tabellenKey] || ''}
                          onChange={(event) => setzeAntworten('d23b' + tabellenKey, event.target.value)}
                        >
                        </Eingabe>
                      </TableCell>
                    </TableRow>

                  </TableCell>


                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    <TableCell style={{ width: "10px" }} align="left" >24. Steuerfreie Arbeitgeberzuschüsse</TableCell>
                    <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>a) zur gesetzlichen Krankenversicherung</TableCell> </TableRow>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>b) zur privaten Krankenversicherung </TableCell> </TableRow>
                      <TableRow> <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>c) zur gesetzlichen Pfegeversicherung </TableCell> </TableRow>

                    </TableCell>
                  </TableCell>
                  {/* <TableCell  align="left" > */}


                  {/* </TableCell> */}
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>
                    <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                      <Eingabe
                        disabled={daten?.d24a !== ""}
                        error={fehlerliste["d24a" + tabellenKey]}
                        // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d24a" + tabellenKey] === true) ? "red" : "" }}
                        inputProps={{ style: { textAlign: "right" } }}
                        value={antworten["d24a" + tabellenKey] || ''}
                        onChange={(event) => setzeAntworten('d24a' + tabellenKey, event.target.value)}
                      >
                      </Eingabe>
                    </TableCell> </TableRow>
                    <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                      <Eingabe
                        disabled={daten?.d24b !== ""}
                        error={fehlerliste["d24b" + tabellenKey]}
                        // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d24b" + tabellenKey] === true) ? "red" : "" }}
                        inputProps={{ style: { textAlign: "right" } }}
                        value={antworten["d24b" + tabellenKey] || ''}
                        onChange={(event) => setzeAntworten('d24b' + tabellenKey, event.target.value)}
                      >
                      </Eingabe>
                    </TableCell> </TableRow>
                    <TableRow>  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                      <Eingabe
                        disabled={daten?.d24c !== ""}
                        error={fehlerliste["d24c" + tabellenKey]}
                        // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d24c" + tabellenKey] === true) ? "red" : "" }}
                        inputProps={{ style: { textAlign: "right" } }}
                        value={antworten["d24c" + tabellenKey] || ''}
                        onChange={(event) => setzeAntworten('d24c' + tabellenKey, event.target.value)}
                      >
                      </Eingabe>
                    </TableCell> </TableRow>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    25. Arbeitnehmerbeiträge zur gesetzlichen Krankenversicherung
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d25 !== ""}
                      error={fehlerliste["d25" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d25" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d25" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d25' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    26. Arbeitnehmerbeiträge zur sozialen Pfegeversicherung
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d26 !== ""}
                      error={fehlerliste["d26" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d26" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d26" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d26' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    27. Arbeitnehmerbeiträge zur Arbeitslosenversicherung
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d27 !== ""}
                      error={fehlerliste["d27" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d27" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d27" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d27' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    28. Beiträge zur privaten Kranken- und Pfege-Pfichtversicherung
                    oder Mindestvorsorgepauschale
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d28 !== ""}
                      error={fehlerliste["d28" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d28" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d28" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d28' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    29. Bemessungsgrundlage für den Versorgungsfreibetrag zu 8
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d29 !== ""}
                      error={fehlerliste["d29" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d29" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d29" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d29' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    30. Maßgebendes Kalenderjahr des Versorgungsbeginns zu 8.
                    und/oder 9.
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d30 !== ""}
                      error={fehlerliste["d30" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d30" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d30" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d30' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    31. Zu 8. bei unterjähriger Zahlung: Erster und letzter Monat, für
                    den Versorgungsbezüge gezahlt wurden
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d31 !== ""}
                      error={fehlerliste["d31" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d31" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d31" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d31' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    32. Sterbegeld; Kapitalauszahlungen/Abndungen und Nachzahlungen von Versorgungsbezügen - in 3. und 8. enthalten
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d32 !== ""}
                      error={fehlerliste["d32" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d32" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d32" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d32' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    33. Ausgezahltes Kindergeld
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d33 !== ""}
                      error={fehlerliste["d33" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d33" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d33" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d33' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>
                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}} >
                    34. Freibetrag DBA Türkei
                  </TableCell>
                  <TableCell align="right" padding='none' style={{paddingLeft:"8px"}}>
                    <Eingabe
                      disabled={daten?.d34 !== ""}
                      error={fehlerliste["d34" + tabellenKey]}
                      // style={{ textAlign: "center", color: "!important", display: "inline-block", marginTop: "7px", backgroundColor: (fehlerliste["d34" + tabellenKey] === true) ? "red" : "" }}
                      inputProps={{ style: { textAlign: "right" } }}
                      value={antworten["d34" + tabellenKey] || ''}
                      onChange={(event) => setzeAntworten('d34' + tabellenKey, event.target.value)}
                    >
                    </Eingabe>
                  </TableCell>

                </TableRow>

                <TableRow style={{ height: '33px' }}  >
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}  >
                    Finanzamt, an das die Lohnsteuer abgeführt wurde (Name und vierstellige Nr.)
                  </TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>

                  </TableCell>
                  <TableCell align="left" padding='none' style={{paddingLeft:"8px"}}>


                  </TableCell>
                </TableRow>





              </TableBody>
            </Table>

          </TableContainer>
        </div>




      </div>
    </div>
  )
  // } 
}

export default Lohnsteuerbescheinigung