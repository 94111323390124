import React, {useState , Fragment} from 'react';
import {Backdrop, Button, CircularProgress, useTheme, Typography, TextField,Grid} from '@material-ui/core';
import {useAsync} from '../utils/hooks'
import Autocomplete from '@material-ui/lab/Autocomplete';
import apiRequest from '../functions/api';
import {useLocalStorage} from '../utils/hooks';
import { LOCAL_STORAGE_KEY } from '../constants/localStorage';
import { useMessage } from '../context/message-context';

const FullPageSpinner = () => {
  const theme = useTheme();
  return(
    <Backdrop open style={{color: theme.palette.common.white, zIndex: theme.zIndex.modal + 1}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

const FullPageErrorFallback = (props) => {
  const theme = useTheme();
  console.log(props.error);
  return(
    <Backdrop open style={{color: theme.palette.common.white, zIndex: theme.zIndex.drawer + 1}}>
      <Typography variant="body1">{props.error.message}</Typography>
    </Backdrop>
  )
}

const AsyncButton = (props) => {
  const {isLoading, run} = useAsync();


  function onButtonClick () {
    run(props.asyncFunction());
  }

  return(
    <Button
      onClick={() => onButtonClick()}
      {...props}
      endIcon={isLoading && <CircularProgress color="inherit" />}
      disabled={isLoading}
    >
      {props.children}
    </Button>
  )
}

const Mitarbeiterauswahl = (props) => {
  const [mitarbeiter,setMItarbeiter] = useState([]);
  const [antwort,setAntwort] = useState("");
  const {programm,client,}=props;
  const [issending,setIsSending] =useState(false);
  const [accessToken, ] = useLocalStorage(LOCAL_STORAGE_KEY.accessToken);
  const {showErrorMessage} = useMessage();

  const pruefe_an = (value) => {
    setIsSending(true)
    const payload={
      "mandant": client,
      "program": programm,
      "mitarbeiter":value,
    }
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    apiRequest('POST', 'mitarbeiter', payload, headers, anCb); 

  }

  const anCb = (response) => {
    setIsSending(false)
    if (response.status === 200) {
      if (response.data.mitarbeiter) {
        setMItarbeiter(response.data.mitarbeiter)
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }      
  }


  return(
    <Grid item xs={12}>
    <Autocomplete
      noOptionsText={antwort!=="" ? "kein Eintrag gefunden" : "Bitte geben Sie einen Suchbegriff ein"}
      openOnFocus
      onChange={(event, newValue) => {
        if (newValue!==null) {
          setAntwort(newValue.key);
          props.onChange(newValue.key);
        } else {
          setAntwort("")
        }
      }}
      loading={issending}
      onInputChange={
        (event, newValue)  => {
          pruefe_an(newValue)
        }
      }
      options={mitarbeiter}
      getOptionLabel={(option) => option.anzeige}
      defaultValue={
        antwort!=="" ? mitarbeiter.find(antwort=> antwort.key===antwort)  : antwort
      }   
      renderInput={(params) => 
          <TextField 
            autoFocus
              {...params} 
              label={props.label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <Fragment>
                {issending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                </Fragment>
                ),
              }}
              InputLabelProps={{
                  shrink: true,
              }}                
          />}
    />
    </Grid>
  )
}

const Zeitraum = (props) => {
  const {antw}=props;
  const [antwort,setAntwort] = useState(antw);
    
  return (
    <Grid item xs={12}>
    <TextField
      type="month"
      variant="outlined"
      fullWidth
      autoFocus
      value={antwort}
      onBlur={  (event) => props.onBlur(antwort) }
      onKeyDown={
          (taste) => 
          taste.key==="Enter" &&
          taste.target.blur()
      }
      onChange={ (event) => setAntwort(event.target.value)}
      InputLabelProps={{
          shrink: true,
      }}
    >
    </TextField>
    </Grid>
  )

}

function istInteger(string){
  return /^\d+$/.test(string)||/^$/.test(string);
}

export {
  FullPageSpinner,
  FullPageErrorFallback,
  AsyncButton,
  istInteger,
  Mitarbeiterauswahl,
  Zeitraum,
}