import React, { useState } from "react";
import BEA_Component from "./BEA_COMPONENT";




const BEA2 = (props) => {
    const { accessToken, hsutkey } = props;
    const [reactnummer] = useState("846")
    const [merkmal] = useState("BEA2")
    

    return (
        <div>
            <BEA_Component reactNummer={reactnummer} merkMal={merkmal} token={accessToken} hsut={hsutkey} ></BEA_Component>
        </div>
    );
}

export default BEA2