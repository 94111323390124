import React, {useState, useCallback} from 'react';
import {Avatar, Button, Checkbox, ListItem, ListItemAvatar, ListItemText, Typography, Grid} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const Abrechnungsozial1 = '/media/Gekavau_sozial.pdf';
const Abrechnungsozial2 = '/media/Naumann_sozial.pdf';
const Abrechnungsozial3 = '/media/kunstvoll-sozial.pdf';
const MUSTERBNW = '/media/BeitragsnachweisMuster.png';
const POSTER = '/media/Grafik_Filmrolle.png';
const VIDEO = '/media/Chat-Tutorial_Beitragsnachweis.m4v';
import { Player, ControlBar } from 'video-react';
import TextField from '@material-ui/core/TextField';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function Beitragsnachweis(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [reactnummer]=useState('008');
  const [merkmal]=useState('Beitragsnachweis');

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };

  const setRedirect = (path) => {
    navigate(path);
  }

  const antwortenSenden = () => {
    console.log({antworten})
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
          props.showError(response.data.message);
      }else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    
    <div>  
      <Briefkopf  
          ueberschrift1="Der Beitragsnachweis" 
          ueberschrift2="Was steht auf einem Beitragsnachweis und wozu wird ein Beitragsnachweis benötigt?" 
      />


      <Typography variant="h5" color="primary">
          Muster
      </Typography>

      <Grid item >
          <img src={MUSTERBNW} height="1500" alt="ectool" loading="lazy"/>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Dieses Chat-Video erklärt Dir das Thema Beitragsnachweise in der Sozialversicherung"/>
          </ListItem>
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
        </Grid>
      </Grid>


     <box p={2}>
      <Grid container spacing={1}  style={{marginTop:'30px'}}  alignItems={"center"}>
      <Grid item xs={8}>
         
         <ListItem>
             <ListItemAvatar>
               <Avatar style={{backgroundColor:"#1fbba6"}}>
                 
               </Avatar> 
             </ListItemAvatar>
             <ListItemText primary="Bitte verwende die drei Abrechnungen "/>
           </ListItem>
           <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
               <Button 
                 color="primary"  
                 variant="contained"
   //              fullWidth
                 href={Abrechnungsozial1}download>
                 Abrechnung von Gekavau
               </Button>
           </Grid>
 
 
           
           <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
               <Button 
                 color="primary"  
                 variant="contained"
   //              fullWidth
                 href={Abrechnungsozial2}download>
                 Abrechnung von Naumann
               </Button>
           </Grid>
            
             <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
               <Button 
                 color="primary"  
                 variant="contained"
   //              fullWidth
                 href={Abrechnungsozial3}download>
                 Abrechnung von Kunstvoll
               </Button>
             </Grid>
             <ListItem>
               <ListItemAvatar>
                 <Avatar style={{backgroundColor:"#1fbba6"}}>
                   A
                 </Avatar> 
               </ListItemAvatar>
               <ListItemText>
                  Erstelle den Beitragsnachweis aufgrund der 3 vorliegenden Entgeltabrechnungen.
                  Gehe bei Deinen Berechnungen davon aus, dass der gelb markierte Satz gewählt wurde. Trage alle Beitragsgruppen in dem Beitragsnachweis ein, unabhängig davon, ob sich Beiträge ergeben haben.
               </ListItemText>
             </ListItem>
         </Grid>
 
       </Grid>
      <Grid container spacing={0} style={{}} justifyContent="center">
        <Grid item xs={7} style={{border:"solid"}} alignItems="left">
          <Typography  style={{borderBottom:"solid",width:"100%", backgroundColor:"#1fbba6"}}> Umlage 1 - Prozentsätze</Typography>
          <div style={{backgroundColor:"grey"}}>
            <Typography style={{display:"inline-block",width:"14%",borderRight:"solid",backgroundColor:"grey"}}> Zeitraum</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:"grey"}}> Satz 1</Typography>
            <Typography style={{display:"inline-block",width:"18%",borderRight:"solid",backgroundColor:"grey"}}> Erstattung 1</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:"grey"}}> Satz 1</Typography>
            <Typography style={{display:"inline-block",width:"18%",borderRight:"solid",backgroundColor:"grey"}}> Erstattung 1</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:"grey"}}> Satz 1</Typography>
            <Typography style={{display:"inline-block",width:"20%",borderRight:"",backgroundColor:"grey"}}> Erstattung 1</Typography>
          </div>
          <div style={{backgroundColor:"white"}}>
            <Typography style={{display:"inline-block",width:"14%",borderTop:"solid"}}> 09/2020</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderTop:"solid"}}> 4,10%</Typography>
            <Typography style={{display:"inline-block",width:"18%",borderTop:"solid"}}> 80,00%</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderTop:"solid",backgroundColor:"yellow"}}> 2,20%</Typography>
            <Typography style={{display:"inline-block",width:"18%",borderTop:"solid",backgroundColor:"yellow"}}> 60,00%</Typography>
            <Typography style={{display:"inline-block",width:"10%",borderTop:"solid"}}> 1,80%</Typography>
            <Typography style={{display:"inline-block",width:"20%",borderTop:"solid"}}> 50,00% </Typography>
          </div>
        </Grid>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={3}style={{marginTop:"10px",borderBottom:"solid"}}>
            <Typography  style={{border:"solid",width:"100%",backgroundColor:"#1fbba6"}}> Umlage 2 - Prozentsatz</Typography>
            <div style={{backgroundColor:"grey"}}>
              <Typography style={{display:"inline-block",width:"33%",borderLeft:"solid",borderRight:"solid",backgroundColor:"grey"}}> Zeitraum</Typography>
              <Typography style={{display:"inline-block",width:"33%",borderRight:"solid",backgroundColor:"grey"}}> Satz 1</Typography>
              <Typography style={{display:"inline-block",width:"34%",borderRight:"solid",backgroundColor:"grey"}}> Erstattung 1</Typography>
            </div>
            <div style={{backgroundColor:"white"}}>
              <Typography style={{display:"inline-block",width:"33%",borderTop:"solid",borderLeft:"solid"}}> 09/2020</Typography>
              <Typography style={{display:"inline-block",width:"33%",borderTop:"solid",borderRight:"",backgroundColor:"yellow"}}> 0,44%</Typography>
              <Typography style={{display:"inline-block",width:"34%",borderTop:"solid",borderRight:"solid",backgroundColor:"yellow"}}> 100,00%</Typography>
            </div>

          </Grid>
          <Grid item xs={3}style={{marginTop:"10px",marginLeft:"10px",borderBottom:"solid"}}>
            <Typography  style={{border:"solid",width:"100%",backgroundColor:"#1fbba6"}}> Insolvenz-Abgabesatz</Typography>
            <div style={{backgroundColor:"grey"}}>
              <Typography style={{display:"inline-block",width:"67%",borderLeft:"solid",borderRight:"solid",backgroundColor:"grey"}}> Zeitraum</Typography>
              <Typography style={{display:"inline-block",width:"33%",borderRight:"solid",backgroundColor:"grey"}}> Satz 1</Typography>
            </div>
            <div style={{backgroundColor:"white"}}>
              <Typography style={{display:"inline-block",width:"67%",borderTop:"solid",borderLeft:"solid"}}> 09/2020</Typography>
              <Typography style={{display:"inline-block",width:"33%",borderTop:"solid",borderRight:"solid",backgroundColor:"yellow"}}> 0,06%</Typography>
            </div>

          </Grid>
        </Grid>


      </Grid>
      <Grid container spacing={1}  style={{marginTop:'30px', textAlign:'left',borderTop:'solid', borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}  alignItems={"center"}>
        <Grid item xs={4} style={{}}>
          <Typography>
            <strong>Arbeitgeber</strong><br/>
          </Typography>
        </Grid>
        <Grid item xs={3} style={{borderRight:'solid'}}>
          <Typography>
            <strong>Mand.-Nr.</strong> 777/169
          </Typography>
        </Grid>
        <Grid item xs={5} style={{}}>
          <Typography>
            <strong>Betriebs-/Beitragskonto-Nr. des Arbeitgeber</strong><br/>
          </Typography>
        </Grid>
        <Grid item xs={7} style={{borderRight:'solid'}}>
          <Typography>
            ectool (Lohn macht Spass) <br/>
            Max-Eyth-Str.<br/>
          </Typography>
        </Grid>
        <Grid item xs={5} style={{}}>
          <Typography>
            Betr.-Nr. : 99999011<br/> 
            Mitgl -Nr. : <br/> 
          </Typography>
        </Grid>
        <Grid item xs={7} style={{marginTop:'-7px',borderRight:'solid'}}>
          <Typography>
            Waiblingen
          </Typography>
        </Grid>
        <Grid item xs={5} style={{ }}>
          <Typography>
          
          </Typography>
        </Grid>

      </Grid>

    <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={4}>
        
        </Grid>
        <Grid item xs={1}>
          <Typography >
            Zeitraum:
          </Typography>
        </Grid>
        <Grid item xs={2} style={{textAlign:'left' }}>
          <TextField 
              id="von" label="Von" style={{display:"inline-block"}} 
              onChange={(event) => setzeAntworten('von',event.target.value)}
              error={fehlerliste["von"]}

              >
          </TextField><br/> 
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={1}>
          <Typography >
            
          </Typography>
        </Grid>
        <Grid item xs={2} style={{textAlign:'left' }}>
          <TextField 
              id="bis" label="Bis" style={{display:"inline-block"}} 
              onChange={(event) => setzeAntworten('bis',event.target.value)}
              error={fehlerliste["bis"]}
              >
          </TextField><br/> 
        </Grid>
        <Grid item xs={3}>

        </Grid>

        <Grid item xs={6}style={{ textAlign:'left'}}>
            <TextField id="krankenkasse"
                disabled
                label="Krankenkasse" 
                defaultValue="mhplus BKK"
                style={{display:"inline-block"}}
                error={fehlerliste["krankenkasse"]}
                onChange={(event) => setzeAntworten('krankenkasse',event.target.value)}
                >
            </TextField><br/> 
         <TextField disabled id="Straße" 
          label="Straße" style={{display:"inline-block"}}
          error={fehlerliste["strasse"]}
          onChange={(event) => setzeAntworten('STRASSE',event.target.value)}
         ></TextField>´

         <TextField disabled id="standard-basic "
          label="Nummer" style={{display:""}}
          error={fehlerliste["HAUSNR"]}
          onChange={(event) => setzeAntworten('HAUSNR',event.target.value)}
         ></TextField><br/>

         <TextField disabled id="standard-basic"
          label="PLZ" style={{display:"inline-block"}}
          error={fehlerliste["PLZ"]}
          onChange={(event) => setzeAntworten('PLZ',event.target.value)}
         ></TextField>

         <TextField disabled id="standard-basic"
          label="Stadt" style={{display:"inline-block"}}
          error={fehlerliste["STADT"]}
          onChange={(event) => setzeAntworten('STADT',event.target.value)}
         ></TextField>
        </Grid>
        
        <Grid item xs={6}style={{textAlign:'left'}}>
         <ListItem>
            <ListItemText>
                  Rechtskreis *) <br />
            </ListItemText>  
            <ListItemText>
                Ost
                <Checkbox
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    style={{marginLeft:"5px",border:(fehlerliste["Ost"]===true)?"solid red":""}}
                    onChange={(event) => setzeAntworten('Ost',event.target.checked)}
                />
            </ListItemText> 
            <ListItemText>
                West
                <Checkbox
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    style={{marginLeft:"5px",border:(fehlerliste["West"]===true)?"solid red":""}}
                    onChange={(event) => setzeAntworten('West',event.target.checked)}
                />
            </ListItemText>
         </ListItem>
         <ListItem>
            <ListItemText>
                  Fälligkeit am 25. des lfd. Monats*) <br />
                  Dauer-Beitragsnachweis *)<br />
                  Bisheriger Dauer-Beitragsnachweis  <br/> 
                  gilt erneut abnächsten Monat *) <br />
                  Beitragsnachweis enthält Beiträge <br />
                  aus Wertguthaben, das abgelaufenen <br />
                  Kalenderjahren zuzuordnen ist *)<br />
                  Korrektur-Beitragsnachweis <br />
                  für abgelaufenen Kalenderjahre *)<br />
                  Verarbeitungsmerkmal: <br />
                  "S"= Stornierung <br />
                  "E"= Ersetzen des für diesen Zeitraum einge- <br />
                        reichten Beitragsnachweis<br />
                  "X"= Differenzbeitragsnachweis


              </ListItemText>  
              
          </ListItem>
      
        </Grid>
    </Grid>
    

    <Grid container spacing={1}  style={{marginTop:'30px', textAlign:'left'}}  alignItems={"center"}>
      <Grid item xs={8} >
        <Typography>
          <strong>Beitragsnachweis</strong>
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderTop:'solid',borderLeft:'solid', borderRight:'solid',height:'55px'}}>
        <Typography >
          <strong>Beitrags-<br/> gruppen</strong>
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderTop:'solid',borderRight:'solid', height:'54px'}}>
        <Typography>
          <strong>Euro</strong>
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderTop:'solid', borderRight:'solid',height:'55px'}} >
        <Typography>
          <strong>Cent</strong>
        </Typography>
      </Grid>
      <Grid item xs={8} style={{borderTop:'solid',borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge zur Krankenversicherung - allgemeiner Beitrag (ohne Zusatzbeitrag)
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="Beitrag1" label="" inputProps={{style:{textAlign:"center"}}} placeholder=""  style={{display:"flex"}}
           onChange={(event) => setzeAntworten('Beitrag1',event.target.value)}
           error={fehlerliste["Beitrag1"]}
           />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="euro1" label="" inputProps={{style:{textAlign:"right"}}} placeholder=""  style={{display:"flex"}}
            onChange={(event) => setzeAntworten('euro1',event.target.value)}
            error={fehlerliste["euro1"]}
            />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="cent1" label="" placeholder=""  style={{display:"flex"}}
            onChange={(event) => setzeAntworten('cent1',event.target.value)}
            error={fehlerliste["cent1"]}
            />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge zur Krankenversicherung - ermäßigten Beitrag (ohne Zusatzbeitrag)
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}}
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag2',event.target.value)}
            error={fehlerliste["Beitrag2"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label=""
             inputProps={{style:{textAlign:"right"}}} placeholder=""
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro2',event.target.value)}
             error={fehlerliste["euro2"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent2',event.target.value)}
              error={fehlerliste["cent2"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
            Beiträge zur Krankenversicherung für geringfügig Beschäftigung
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}}
             style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag3',event.target.value)}
             error={fehlerliste["Beitrag3"]}
             />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""  inputProps={{style:{textAlign:"right"}}}
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro3',event.target.value)}
             error={fehlerliste["euro3"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent3',event.target.value)}
              error={fehlerliste["cent3"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Zusatzbeitrag zur Krankenversicherung
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"center"}}}
             style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag4',event.target.value)}
             error={fehlerliste["Beitrag4"]}
             />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}}
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro4',event.target.value)}
             error={fehlerliste["euro4"]}
            />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent4',event.target.value)}
              error={fehlerliste["cent4"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge zur Rentenversicherung der Arbeiter- voller Beitrag
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag5',event.target.value)}
            error={fehlerliste["Beitrag5"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro5',event.target.value)}
             error={fehlerliste["euro5"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent5',event.target.value)}
              error={fehlerliste["cent5"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
            Beiträge zur Rentenversicherung der Arbeiter- halber Beitrag
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag6',event.target.value)}
            error={fehlerliste["Beitrag6"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}}  
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro6',event.target.value)}
             error={fehlerliste["euro6"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent6',event.target.value)}
              error={fehlerliste["cent6"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
           Beiträge zur Rentenversicherung der Arbeiter für geringfügig Beschäftigte
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag7',event.target.value)}
            error={fehlerliste["Beitrag7"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro7',event.target.value)}
             error={fehlerliste["euro7"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent7',event.target.value)}
              error={fehlerliste["cent7"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge zur Arbeitsförderung- voller Beitrag
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag8',event.target.value)}
            error={fehlerliste["Beitrag8"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro8',event.target.value)}
             error={fehlerliste["euro8"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent8',event.target.value)}
              error={fehlerliste["cent8"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
           Beiträge zur Arbeitsförderung- halber Beitrag
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag9',event.target.value)}
            error={fehlerliste["Beitrag9"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro9',event.target.value)}
             error={fehlerliste["euro9"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent9',event.target.value)}
              error={fehlerliste["cent9"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge zur sozialen Pflegeversicherung
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"center"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag10',event.target.value)}
             error={fehlerliste["Beitrag10"]}
             />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro10',event.target.value)}
             error={fehlerliste["euro10"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent10',event.target.value)}
              error={fehlerliste["cent10"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Umlage für Krankheitsaufwendungen
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"center"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag11',event.target.value)}
             error={fehlerliste["Beitrag11"]}
             />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro11',event.target.value)}
             error={fehlerliste["euro11"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent11',event.target.value)}
              error={fehlerliste["cent11"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Umlage für Mutterschaftsaufwendungen
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}}  
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag12',event.target.value)}
            error={fehlerliste["Beitrag12"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro12',event.target.value)}
             error={fehlerliste["euro12"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent12',event.target.value)}
              error={fehlerliste["cent12"]}
              />
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
          Insolvenzgeldumlage
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
           <TextField  id="standard-basic" label="" placeholder=""
            inputProps={{style:{textAlign:"center"}}} 
            style={{display:"flex"}}onChange={(event) => setzeAntworten('Beitrag13',event.target.value)}
            error={fehlerliste["Beitrag13"]}
            />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder=""
             inputProps={{style:{textAlign:"right"}}} 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('euro13',event.target.value)}
             error={fehlerliste["euro13"]}
             />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('cent13',event.target.value)}
             error={fehlerliste["cent13"]}
             />
      </Grid>      

      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
        <Typography>
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
      </Grid>      
      <Grid item xs={8} style={{borderBottom:"solid", borderLeft:'solid',height:'55px'}}>
        <Typography>
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderLeft:'solid', borderRight:'solid',height:'55px'}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid', height:'55px'}}>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
      </Grid>      
      <Grid item xs={9} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}}>
        <Typography>
          <strong>Gesamtsumme</strong>
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:'solid',height:'55px'}}>
            <TextField  id="standard-basic" label="" placeholder="" inputProps={{style:{textAlign:"right"}}} style={{display:"flex"}}
            onChange={(event) => setzeAntworten('euro14',event.target.value)}
            error={fehlerliste["euro14"]}
            />
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent14',event.target.value)}
              error={fehlerliste["cent14"]}
              />
      </Grid>  
      <Grid item xs={7} style={{}}>
        <Typography>
          
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderRight:'solid',borderLeft:'solid',height:'55px'}}>
        <Typography>
          Beiträge für<br/> freiwillig
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
        <Typography>
          zur Kranken- <br/> versicherung
        </Typography>
      </Grid>  
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" inputProps={{style:{textAlign:"right"}}}
              style={{display:"flex"}}onChange={(event) => setzeAntworten('euro15',event.target.value)}
              error={fehlerliste["euro15"]}
              />
      </Grid>      
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" 
             style={{display:"flex"}}onChange={(event) => setzeAntworten('cent16',event.target.value)}
             error={fehlerliste["cent16"]}
             />
      </Grid>  
      <Grid item xs={7} style={{}}>
        <Typography>
          Es wird bestätigt, dass die Angaben mit denen der Lohn- und Gehaltsunterlagen
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderRight:'solid',borderLeft:'solid',height:'30px'}}>
        <Typography>
          Kranken-
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'30px'}} >
        <Typography>
          Zusatzbeitrag
        </Typography>
      </Grid>  
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'30px'}} >
            <TextField  id="standard-basic" label="" placeholder=""  style={{display:"flex",height:'20px'}} inputProps={{style:{textAlign:"right"}}} onChange={(event) => setzeAntworten('euto16',event.target.value)}/>
      </Grid>      
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'30px'}} >
            <TextField  id="standard-basic" label="" placeholder=""  style={{display:"flex",height:'20px'}} onChange={(event) => setzeAntworten('cent16',event.target.value)}/>
      </Grid>        
      <Grid item xs={7} style={{}}>
        <Typography>
          übereinstimmen und in diesen sämtliche Entgelte enthalten sind.
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:'solid',borderLeft:'solid',height:'55px'}}>
        <Typography>
          versicherte <br/> **)
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
        <Typography>
          zur Pflege- <br/> versicherung
        </Typography>
      </Grid>  
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" inputProps={{style:{textAlign:"right"}}}
              style={{display:"flex"}}onChange={(event) => setzeAntworten('euro17',event.target.value)}
              error={fehlerliste["euro17"]}
              />
      </Grid>      
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" 
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent17',event.target.value)}
              error={fehlerliste["cent17"]}
              />
      </Grid>     
      <Grid item xs={7} style={{height:'40px',width:"50%",lineHeight:"32px"}}>
           <TextField  id="datum unterschrift" disabled label="" placeholder=""  style={{display:"flex",width:"50%"}} />
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid', borderLeft:'solid',height:'40px'}} >
        <Typography>
          abzüglich Erstattung
        </Typography>
      </Grid>  
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'40px'}} >
            <TextField  id="standard-basic" label="" placeholder="" inputProps={{style:{textAlign:"right"}}}
              style={{display:"flex"}}onChange={(event) => setzeAntworten('euro18',event.target.value)}
              error={fehlerliste["euro18"]}
              />
      </Grid>      
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'40px'}} >
            <TextField  id="standard-basic" label="" placeholder="" 
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent18',event.target.value)}
              error={fehlerliste["cent18"]}
              />
      </Grid>
      <Grid item xs={7} style={{}}>
        <Typography>
          Datum, Unterschrift
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid', borderLeft:'solid',height:'55px'}} >
        <Typography>
          zu zahlender Betrag/<br/> Guthaben
        </Typography>
      </Grid>  
      <Grid item xs={2} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder="" inputProps={{style:{textAlign:"right"}}}
              style={{display:"flex"}}onChange={(event) => setzeAntworten('euro19',event.target.value)}
              error={fehlerliste["euro19"]}
              />
      </Grid>      
      <Grid item xs={1} style={{borderBottom:"solid", borderRight:'solid',height:'55px'}} >
            <TextField  id="standard-basic" label="" placeholder=""
              style={{display:"flex"}}onChange={(event) => setzeAntworten('cent19',event.target.value)}
              error={fehlerliste["cent19"]}
              />
      </Grid>

      <Grid item xs={7} style={{}}>
        <Typography>
          
        </Typography>
      </Grid>
      <Grid item xs={5} style={{}} >
        <Typography>
          <small>*) Zutreffendes ankreuzen</small> <br/>
          <small>**) freiwillige Angaben des Arbeitgebers </small>

        </Typography>
      </Grid>  

  

    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </box>
  </div>

  )
}




export default Beitragsnachweis;
