import React from 'react';
import {Grid, TextField,Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from '../../components/Rahmendaten/Eingaben';



function Tabelle (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,Zeitraum1, Kalendertage1,Zeitraum2, Kalendertage2,Zeitraum3, Kalendertage3,Zeitraum4, Kalendertage4, Gesamttage,EndeLohn,text} = props;

  if (Zeitraum1 !== '') {
      antworten["zeit1"+tabellenKey]=Zeitraum1;
      var zeit1blockiert=true;
  }
  if (Kalendertage1 !== '') {
    antworten["tage1"+tabellenKey]=Kalendertage1;
    var tage1blockiert=true;
  }
  if (Zeitraum2 !== '') {
    antworten["zeit2"+tabellenKey]=Zeitraum2;
    var zeit2blockiert=true;
}
if (Kalendertage2 !== '') {
  antworten["tage2"+tabellenKey]=Kalendertage2;
  var tage2blockiert=true;
}

if (Zeitraum3 !== '') {
    antworten["zeit3"+tabellenKey]=Zeitraum3;
    var zeit3blockiert=true;
}
if (Kalendertage3 !== '') {
  antworten["tage3"+tabellenKey]=Kalendertage3;
  var tage3blockiert=true;
}
if (Zeitraum4 !== '') {
  antworten["zeit4"+tabellenKey]=Zeitraum4;
  var zeit4blockiert=true;
}
if (Kalendertage4 !== '') {
antworten["tage4"+tabellenKey]=Kalendertage4;
var tage4blockiert=true;
}
if (Gesamttage !== '') {
    antworten["gesamt"+tabellenKey]=Gesamttage;
    var gesamtblockiert=true;
}
if (EndeLohn !== '') {
    antworten["ende"+tabellenKey]=EndeLohn;
    var endeblockiert=true;
}


return(
    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
        <Grid item xs={4} style={{textAlign:"left"}}>
            <Typography>
                {text}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
    
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Zeitraum</TableCell>
                            <TableCell style={{textAlign:"center"}}>Kalendertage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell key="Zeitraum1">
                                <Eingabe 
                                    disabled={zeit1blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["zeit1"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["zeit1"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["zeit1"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('zeit1'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kalendertage1" >
                                <Eingabe 
                                    disabled={tage1blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tage1"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["tage1"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["tage1"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tage1'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell key="Zeitraum2">
                                <Eingabe 
                                    disabled={zeit2blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["zeit2"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["zeit2"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["zeit2"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('zeit2'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kalendertage2" >
                                <Eingabe 
                                    disabled={tage2blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tage2"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["tage2"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["tage2"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tage2'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell key="Zeitraum3">
                                <Eingabe 
                                    disabled={zeit3blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["zeit3"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["zeit3"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["zeit3"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('zeit3'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kalendertage3" >
                                <Eingabe 
                                    disabled={tage3blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tage3"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["tage3"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["tage3"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tage3'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell key="Zeitraum4">
                                <Eingabe 
                                    disabled={zeit4blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["zeit4"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["zeit4"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["zeit4"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('zeit4'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kalendertage4" >
                                <Eingabe 
                                    disabled={tage4blockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tage4"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["tage4"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["tage4"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tage4'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                        <TableCell style={{textAlign:"center"}}>Gesamttage:</TableCell>
                            <TableCell key="Gesamttage" >
                                <Eingabe 
                                    disabled={gesamtblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["gesamt"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["gesamt"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["gesamt"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('gesamt'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            
                        </TableRow>
                        <TableRow >
                        <TableCell style={{textAlign:"center"}}>Ende der Lohnfortzahlung: </TableCell>
                            <TableCell key="EndeLohn" >
                                <Eingabe 
                                    datum={true}
                                    disabled={endeblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["ende"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["ende"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["ende"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('ende'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography style={{textAlign:"left"}}>
                Wenn die Lohnfortzahlung nicht endet, bitte folgendes Datum eintragen: "01.01.0001" <br/> 
                *  = Arbeitnehmer hat am Beginn der Erkrankung nicht gearbeitet. <br/> 
                ** = Arbeitnehmer hat am Beginn der Erkrankung gearbeitet. <br/> 
            </Typography>
        </Grid>
    </Grid>
)
}

export {Tabelle};