import React, {useState, useCallback} from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Grid,Box} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
const grün = '/media/gruen_Abrechnung_Erstattung.png';
const stundenlohn = '/media/Stundenlohn_Abrechnung(Erstattung).png';
import { TabelleUmlage1Erstattung} from './tabU1E';
import { Tabelle2Umlage1Erstattung} from './tab2U1E';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';

function Umlage1Erstattung(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('017');
  const [merkmal]=useState("Umlage1Erstattung");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }
  
  const kurseCb = (response) => {
    //   console.log(response.data.message);
   
       if (response.status === 200) {
            setTestabgabe(true);
            setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
            setAbgabezeit(response.data.abgabezeit);
            fehlerAusLesen(response.data.fehler);
            setAnzFehler(response.data.anzfehler);
            setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
            showErrorMessage(response.data.message);
         } else {
            showErrorMessage('Keine Internetverbindung');
         }
       }
     }


  return(
   <Box p={2}>
      <Briefkopf  
          ueberschrift1="Umlage 1 - Erstattungsantrag" 
          ueberschrift2="Was zählt zum erstattungsfähigen Entgelt?" 
      />

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
      <Grid item xs={8}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              H1
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
              <h4>Was zählt zum erstattungsfähigen Entgelt bei Stundenlohnempfängern?</h4>
                    Bei einem Stundenlohnempfänger ist die Kontrolle des zu erstattenden Betrages einfach. 
                    Denn in der Regel gibt es eine extra Lohnart für die Lohnfortzahlung im Krankheitsfall, welche nur für die betreffenden Stunden/Tage erfasst wird.
                    Erhält der Mitarbeiter neben seinem Stundenlohn noch Lohnbestandteile, die für den gesamten Monat gezahlt werden, 
                    müssen diese auch  <strong>anteilig</strong> für die Zeit der Lohnfortzahlung berücksichtigt werden. 
                    Dazu zählen z.B. Sachbezüge, Prämien, Zulagen, Zuschüsse zur VWL / BAV, etc.<br/>
          </ListItemText>    
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
      <Grid item xs={8}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              H2
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
              <h4>Was zählt zum erstattungsfähigen Entgelt bei Gehaltsempfängern?</h4>
                  Bei den Gehaltsempfängern muss das weiter gezahlte Gehalt auf die Zeiten der Anwesenheit und auf die Zeiten der Arbeitsunfähigkeit verteilt werden.
                  Dies erfolgt, in dem das Gehalt für die Lohnfortzahlung <strong>anteilig (Kürzung nach Arbeitstagen)</strong> berechnet wird, wie bereits oben bei anderen für den ganzen Monat bezahlten Entgeltbestandteilen beschrieben.
          </ListItemText>    
        </ListItem>
      </Grid>
    </Grid>


    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={8}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                I
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Info:</h4>
                    Bei der Beurteilung, ob das Entgelt erstattungsfähig ist, sollte somit bei jeder einzelnen Vergütung (Lohnart) folgende Fragen gestellt werden:
              </ListItemText>  
          </ListItem>
        </Grid>
        <Grid item xs={7}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                F1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <h4>
                    Ist die Vergütung (Lohnart) nur für die Anwesenheit?<br/>
                    Zum Beispiel nur für tatsächlich geleistete Arbeitsstunden?<br/>
                    Wenn ja, zählt die Lohnart nicht zum erstattungsfähigen Entgelt
                </h4>
              </ListItemText>  
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                F2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <h4>
                    Ist die Vergütung (Lohnart) für Anwesenheit und Abwesenheit?<br/>
                    Der Arbeitnehmer bekommt sein Gehalt für den ganzen Monat vergütet, unabhängig davon, ob der Arbeitnehmer krank war oder Urlaub hatte. 
                </h4>
              </ListItemText>  
          </ListItem>

        </Grid>

    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left'}}  alignItems={"center"}>
    </Grid>
        <Grid item xs={8}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>1. Aufgabe:</h4>
                  Der betroffene Arbeitnehmer arbeitet an 5 Tagen pro Woche von Montag bis Freitag. <br/>
                  Der gewählte Erstattungssatz bei der Krankenkasse des Arbeitnehmers beträgt 70%. <br/>
                  Erstelle den Erstattungsantrag mit der vorliegenden Krankmeldung und den vorliegenden Entgeltdaten. <br/>
            </ListItemText>  
          </ListItem>
        </Grid>

        <Grid item >
              <img src={grün} height="200" alt="ectool" loading="lazy"/>
          </Grid>

        <TabelleUmlage1Erstattung  name='Grün,Alex' gbdatum='17.10.1960' strasse='Max-Eyth-Str.18' plz='Waiblingen' 
                           datum='19.10.2020' datumbis='21.10.2020' datumseit='19.10.2020'  setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        
        <Tabelle2Umlage1Erstattung   tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>



    <Grid container spacing={0} style={{marginTop:'60px', textAlign:'left'}}  alignItems={"center"}>

        <Grid item xs={8}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>2. Aufgabe:</h4>
                    Der betroffene Arbeitnehmer arbeitet an 5 Tagen pro Woche von Montag bis Freitag. <br/>
                    Der gewählte Erstattungssatz bei der Krankenkasse des Arbeitnehmers beträgt 70%. <br/>
                    Erstelle den Erstattungsantrag mit der vorliegenden Krankmeldung        
            </ListItemText>  
          </ListItem>
        </Grid>
    </Grid>

    <TabelleUmlage1Erstattung  name='Stundenlohn, Karl' gbdatum='10.01.2004' strasse='Krumme Halde 63' plz='89081 Ulm' 
                           datum='19.10.2020' datumbis='23.10.2020' datumseit='19.10.2020'  setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left'}}  alignItems={"center"}>
          <Grid item >
              <img src={stundenlohn} height="200" alt="ectool" loading="lazy"/>
          </Grid>
        </Grid>

        <Tabelle2Umlage1Erstattung   tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

   </Box>
    )
  }   


export default Umlage1Erstattung;
