import React from 'react';
import { useNavigate } from 'react-router-dom';
import PulseLoader from "react-spinners/PulseLoader";
import { Button, Typography, Box, Grid } from "@material-ui/core";

function LoadingComponent() {
  const navigate = useNavigate();
  return(
    <Box p={2}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h5">Seite wird geladen</Typography>
          <Box p={2}>
            <PulseLoader
              css=""
              size={10}
              color="#22bbaa"
              loading="true"
            />
          </Box>
          <Button color="primary" onClick={() => navigate('/')}>Zurück zum Verteiler</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoadingComponent;
