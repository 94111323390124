import React from 'react';
import {Grid, MenuItem, TextField, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function PersonengruppenAuswahl (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, pgrBlockiert, } = props;



return(
    <div>
    <Grid container spacing={2} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={3}>
           <TableContainer>
                <Table>
                        <TableRow>
                            <TableCell><h2>Personengruppe</h2></TableCell>
                            <TableCell>
                            <TextField 
                                select // durch select wird DropDown initialisiert
                                disabled={pgrBlockiert}
                                id="PGR"
//*                                style={{display:"inline-block",marginTop:"1px",backgroundColor:(fehlerliste["pgr"+tabellenKey]===true)?"red":""}}
                                label="Auswahl1"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                placeholder="Auswahl"
                                error={fehlerliste["pgr"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"1px",backgroundColor:(fehlerliste["pgr"+tabellenKey]===true)?"red":""}}                                
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["pgr"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('pgr'+tabellenKey,event.target.value)}

                                InputLabelProps={{
                                    shrink: true,
                                    style:{color:"#1fbba6"}
                                }} >
                                <MenuItem value={101}>101</MenuItem>
                                <MenuItem value={102}>102</MenuItem>
                                <MenuItem value={103}>103</MenuItem>
                                <MenuItem value={104}>104</MenuItem>
                                <MenuItem value={105}>105</MenuItem>
                                <MenuItem value={106}>106</MenuItem>
                                <MenuItem value={107}>107</MenuItem>
                                <MenuItem value={108}>108</MenuItem>
                                <MenuItem value={109}>109</MenuItem>
                                <MenuItem value={110}>110</MenuItem>
                                <MenuItem value={111}>111</MenuItem>
                                <MenuItem value={112}>112</MenuItem>
                                <MenuItem value={113}>113</MenuItem>
                                <MenuItem value={114}>114</MenuItem>
                                <MenuItem value={116}>116</MenuItem>
                                <MenuItem value={117}>117</MenuItem>
                                <MenuItem value={118}>118</MenuItem>
                                <MenuItem value={119}>119</MenuItem>
                                <MenuItem value={120}>120</MenuItem>
                                <MenuItem value={121}>121</MenuItem>
                                <MenuItem value={122}>122</MenuItem>
                                <MenuItem value={123}>123</MenuItem>
                                <MenuItem value={124}>124</MenuItem>
                                <MenuItem value={127}>127</MenuItem>
                                <MenuItem value={190}>190</MenuItem>
                            </TextField>                    
                            </TableCell>
                        </TableRow>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>

    </div>
 
  )
}

export {PersonengruppenAuswahl};