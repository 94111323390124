import React, {useState, useCallback} from 'react';
import {Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {TabelleWissen} from './tabelleWissenstestE1';
import {Tabelle2Wissen} from './tabelle2WissentestE1';
/* Tabelle für die 4er Fragen */
import {Tabelle3Wissen} from './tabelle3WissentestE1';
/*Tabelle für die 5er Fragen*/

function Wissenstest_Entgeltabr_T1(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0');
  const [reactnummer]=useState('022');
  const [merkmal]=useState("WTestEntgAbrT1");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line 
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);

    setAntworten(values);
    forceUpdate();   
//    console.log("antworten: ", antworten) ,antworten => dann wird die Liste angezeigt; nicht +antworten

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
        <Typography variant="h4" color="primary">Wissenstest zur Entgeltabrechnung Teil 1</Typography> 
      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
            <Typography variant="h6">
                  Teste dein Wissen über die Entgeltabrechnung
                 
            </Typography>

        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:'10px'}} justifyContent={"center"} >

        <TabelleWissen tabellenkey='1' frage='Wofür wird die Indetifikationsnummer benötigt?' antwort1='Anmeldung bei der Krankenkasse' antwort2='Abruf der Steuerdaten beim Finanzamt' antwort3='Änderung der Anschrift' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <Tabelle3Wissen tabellenkey='2' frage='Wer muss einen Zuschlag zur Pflegeversicherung bezahlen?' antwort1='Alle Mitarbeiter' antwort2='Alle kinderlosen Mitarbeiter' antwort3='Alle kinderlosen Mitarbeiter über 23' antwort4='Alle Mitarbeiter mit Kindern' antwort5='Alle kinderlosen Mitarbeiter über 25' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <TabelleWissen  tabellenkey='3' frage='Welchen Beitragsatz bestimmt jede Krankenkasse individuell?' antwort1='Beitragssatz des Zusatzbeitrags' antwort2='Beitragssatz der Rentenversicherung' antwort3='Beitragssatz der Pflegeversicherung' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
          
        <TabelleWissen  tabellenkey='4' frage='Welchen Informationen stehen auf einer Lohnsteueranmeldung?' antwort1='Zahl der Arbeitnehmer' antwort2='Lohnsteuer' antwort3='Solidaritätszuschlag' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        
        <Tabelle2Wissen tabellenkey='5' frage='Wie ermittelt sich das Nettoentgelt?' antwort1='Bruttoentgelt abzüglich Steuer und Nettoabzüge' antwort2='Bruttoentgelt abzüglich Sozialversicherungsbeiträge zuzüglich Steuer' antwort3='Bruttoentgelt abzüglich Steuer und Sozialversicherungsbeiträge' antwort4='Bruttoentgelt abzüglich Steuer, Sozialversicherungsbeiträge und Nettoabzüge' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    
        <TabelleWissen tabellenkey='6' frage='Welche Informationen gibt mir der Beitragsgruppenschlüssel?' antwort1='Welcher Zweig der Sozialversicherung pflichtig ist' antwort2='Bei welcher Krankenkasse der Arbeitnehmer versichert ist' antwort3='Wie der Arbeitnehmer versichert ist' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
         
        <Tabelle2Wissen tabellenkey='7' frage='An welche Institution übermittelt der Arbeitgeber die DEÜV Meldung?' antwort1='Finanzamt' antwort2='Deutsche Rentenversicherung' antwort3='Krankenkasse' antwort4='Arbeitgeber' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
         
        <Tabelle3Wissen tabellenkey='8' frage='Welche Informationen stehen auf einer DEÜV Meldung?' antwort1='Arbeitszeit' antwort2='Beitragsgruppen' antwort3='Beginn einer Beschäftigung' antwort4='Elterneigenschaft' antwort5='Krankenkasse' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
 
        <TabelleWissen tabellenkey='9' frage='Welche Eigenschaften haben Stammlohnarten?' antwort1='Monatlich wiederkehrender gleichbleibender Bezug' antwort2='Monatlich wiederkehrender abweichender Bezug' antwort3='Monatlich individueller Bezug' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <Tabelle3Wissen tabellenkey='10' frage='Welche Änderungen können über das Programm Entgeltabrechnung Übersicht vorgenommen werden?' antwort1='Erfassung variabler Lohnarten' antwort2='Änderung der Anschrift' antwort3='Erfassung Stammlohnarten' antwort4='Änderung Krankenkasse' antwort5='Änderung des Kinderfreibetrags' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
                        

      </Grid>


      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
        </Grid>


      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} bezeichnung="Erklärung angeschaut und verstanden" antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
}


export default Wissenstest_Entgeltabr_T1;