import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function NettoLoa2 (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,text, NLohnart, NIntern, NBezeichnung, NBetrag, anzReihen} = props;
  let nloaBlockiert=[""]
  let ninternBlockiert=[""]
  let nbezeichnungBlockiert=[""]
  let nbetragBlockiert=[""]

for (let B = 0; B < anzReihen; B++) {

    if (NLohnart[B] !== '') {
        antworten["nloa"+tabellenKey+B]=NLohnart[B];
        nloaBlockiert[B]=true;
    }

    if (NIntern[B] !== '') {
        antworten["nintern"+tabellenKey+B]=NIntern[B];
        ninternBlockiert[B]=true;
    }

    if (NBezeichnung[B] !== '') {
        antworten["nbez"+tabellenKey+B]=NBezeichnung[B];
        nbezeichnungBlockiert[B]=true;
    }

    if (NBetrag[B] !== '') {
        nbetragBlockiert[B]=true;
        antworten["nbetr"+tabellenKey+B]=NBetrag[B];
    }
}    


  var svFarbe="#71ff00";
      let reihen=[];
    for (let i = 0; i < anzReihen; i++) {
        reihen.push("A")
    }

    const reihenEinfügen  = reihen.map((value, i) => {
            return (    
                       <TableRow >
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={nloaBlockiert[i]}
                                    placeholder={"Eingabe"}
                                    error={fehlerliste["nloa"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nloa"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nloa"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('nloa'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={ninternBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nintern"+tabellenKey+i]}     
                                    style={{display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["nintern"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nintern"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('nintern'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell >
                                <Eingabe 
                                    disabled={nbezeichnungBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nbez"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nbez"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["nbez"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('nbez'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell style={{}} style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={nbetragBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["nbetr"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["nbetr"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["nbetr"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('nbetr'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
        );})

        return(
    <Grid container spacing={0} style={{marginTop:'30px'}} >
        
        <Grid item xs={12}>
            <Typography>
                {text}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Nettoabzüge</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Lohnart</TableCell>
                            <TableCell style={{textAlign:"center"}}>Intern</TableCell>
                            <TableCell style={{textAlign:"left"}}>Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"right"}}>Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reihenEinfügen}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {NettoLoa2};