import React, {useState, useCallback } from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText, Grid, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Chat-Tutorial_Lohnfortzahlung_Krankheit.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {SVBlockneu} from '../../components/Rahmendaten/SVBlockneu';
import {BruttoNeu} from '../../components/Rahmendaten/BruttoNeu';



function FehlzeitKrank2(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('033');
  const [merkmal]=useState("FehlzeitKrank2");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Besonderheiten bei Erkrankung des Arbeitnehmers zu Beginn einer Beschäftigung" 
          ueberschrift2="Was ist zu beachten, wenn der Arbeitnehmer zu Beginn einer neuen Beschäftigung erkrankt?" 
      />

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Schaue Dir zuerst das Tutorial dazu an.
          </Typography>

        </Grid>
      </Grid>
      
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
      </Grid>

      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                I1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <Typography>
                <strong>Informationen</strong> <br/>
                Folgende Regelungen sind im Arbeitsvertrag vereinbart:<br/>
                - Eintritt gem. Arbeitsvertrag: 01.11.2020 <br/>
                - Monatsgehalt 2400,- Euro. <br/>
                - Arbeitszeit: Montag-Freitag, jeweils 7 Stunden. <br/>
                Leider erkrankt der Arbeitnehmer vom 27.10.2020 bis 11.11.2020 und kann deshalb seine Beschäftigung erst am 12.11.2020 beginnen.<br/>
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid> 

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A1.1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                <b>Wann ist der SV-rechtliche Eintritt?</b> <br/>
                <TextField 
                type="date"
                id="SVEintritt" 
                label="" 
                value={antworten['SVEintritt1']||''} 
                error={fehlerliste["SVEintritt1"]} 
                InputLabelProps={{ style: {color:'#1fbba6'},}} borderColor="green"style={{display:"inline-block"}} 
                onChange={(event) => setzeAntworten('SVEintritt1',event.target.value)}
                >
              </TextField>
            </ListItemText>
          </ListItem>
          
        </Grid> 

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A1.2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                <b>Trage die richtige Werte in der Lohnart und in den SV-Block ein?</b><br/>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
            </ListItemAvatar>
            <ListItemText>
              <BruttoNeu
                    tabellenKey="1"
                    Lohnart='1029'
                    Intern='029'
                    Bezeichnung='Gehalt'
                    Tage=''
                    Stunden=''
                    Faktor=''
                    Kennung='LFD'
                    Prozent='0,00'
                    Steuerpflichtig=''
                    Sozialverspflichtig=''
                    Betrag =''
                    setzeAntworten={setzeAntworten} 
                    antworten={antworten} 
                    fehlerliste={fehlerliste}
              />  
            </ListItemText>
          </ListItem>
          <SVBlockneu
              tabellenKey="1"
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>
        </Grid> 
      </Grid>

      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                I2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <Typography>
                <strong>Informationen</strong> <br/>
                Folgende Regelungen sind im Arbeitsvertrag vereinbart:<br/>
                - Eintritt gem. Arbeitsvertrag: 01.11.2020 <br/>
                - Monatsgehalt 2400,- Euro. <br/>
                - Arbeitszeit: Montag-Freitag, jeweils 7 Stunden. <br/>
                Der Arbeitnehmer erkrankt vom 10.11.2020 bis 13.11.2020.<br/>
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid> 

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A2.1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                <b>Wann ist der SV-rechtliche Eintritt?</b> <br/>
                <TextField 
                type="date"
                id="SVEintritt2" 
                label="" 
                value={antworten['SVEintritt2']||''} 
                error={fehlerliste["SVEintritt2"]} 
                InputLabelProps={{ style: {color:'#1fbba6'},}} borderColor="green"style={{display:"inline-block"}} 
                onChange={(event) => setzeAntworten('SVEintritt2',event.target.value)}
                >
              </TextField>
            </ListItemText>
          </ListItem>
          
        </Grid> 

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A2.2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
                <b>Trage die richtige Werte in der Lohnart und in den SV-Block ein?</b><br/>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
            </ListItemAvatar>
            <ListItemText>
              <BruttoNeu
                    tabellenKey="2"
                    Lohnart='1029'
                    Intern='029'
                    Bezeichnung='Gehalt'
                    Tage=''
                    Stunden=''
                    Faktor=''
                    Kennung='LFD'
                    Prozent='0,00'
                    Steuerpflichtig=''
                    Sozialverspflichtig=''
                    Betrag =''
                    setzeAntworten={setzeAntworten} 
                    antworten={antworten} 
                    fehlerliste={fehlerliste}
              />  
            </ListItemText>
          </ListItem>
          <SVBlockneu
              tabellenKey="2"
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>
        </Grid> 
      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
</div>
)
}


export default FehlzeitKrank2;