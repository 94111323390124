import React from 'react';
import {Grid, MenuItem, Typography,TextField} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



function V_Status_Tabelle_Beispiel (props) {
  const {zeitp1, zeitperg1, zeitp2, zeitperg2, zeitp3, zeitperg3} = props;

return(
    <div>
        <Grid container spacing={0} style={{border:'solid', marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
           <TableContainer>
               <Typography style={{marginLeft:"5px",marginRight:"5px",textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE'}}>
                    Beurteilung Versicherungsstatus
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Zeitpunkt</TableCell>
                            <TableCell style={{textAlign:'center'}}>Versicherungsstatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {zeitp1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                                {zeitperg1}
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {zeitp2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {zeitperg2}
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {zeitp3}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {zeitperg3}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    </div>
  )
}

export {V_Status_Tabelle_Beispiel};