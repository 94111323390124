import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



function Tab_JAE_2Jahre (props) {
  const {jahr1, jaejahr1, jaemonat1, jahr2, jaejahr2, jaemonat2} = props;

return(
    <div>

    <Grid container spacing={0} style={{border:'solid', marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
           <TableContainer>
               <Typography style={{textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE'}}>
                    Tabelle der Jahresarbeitsentgeltgrenzen (JAE-Grenze) <br/> bzw. Verscherungspflichtgrenze
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Jahr</TableCell>
                            <TableCell style={{textAlign:'center'}}>JAE-Grenze Monat</TableCell>
                            <TableCell style={{textAlign:'center'}}>JAE-Grenze Jahr</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {jahr1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {jaemonat1}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {jaejahr1}
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:'center'}} >
                                {jahr2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {jaemonat2}
                            </TableCell>
                            <TableCell style={{textAlign:'center'}}>
                               {jaejahr2}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    </Grid>

    </div>
 
  )
}

export {Tab_JAE_2Jahre};