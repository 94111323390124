import React, { useState, useCallback, useEffect } from "react";
import { Avatar, Typography, Button, TextField, ListItem, Select, ListItemAvatar, ListItemText, Box, FormControlLabel, Container, MenuItem, DialogTitle, DialogContent, Dialog } from "@material-ui/core";
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from "../../functions/api";
import Grid from "@material-ui/core/Grid";
import { CheckBoxSingle, CheckBoxDouble, SimpleTextField } from "../EasyComponents/EasyComponents"
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useMessage } from "../../context/message-context";
import { Kursabgeben } from "../Rahmendaten/Kursabgeben";
import { Briefkopf } from "../Rahmendaten/Briefkopf";
import axios from 'axios';
import { API_BASE_URL } from '../../constants/apiConstants';
import openFile from "../shared/functions/download";
import { useLocalStorage } from '../../utils/hooks';
import { LOCAL_STORAGE_KEY } from "../../constants/localStorage";




function BEA_Component({ reactNummer, merkMal, token }) {


    const [hsutkey, setHsutkey] = useState(useLocalStorage(LOCAL_STORAGE_KEY.hsutkey)[0])
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [accessToken] = useState(token);
    const navigate = useNavigate();
    const [antworten, setAntworten] = useState({});
    const [testabgabe, setTestabgabe] = useState(false);
    const [abgabedat, setAbgabedat] = useState("");
    const [abgabezeit, setAbgabezeit] = useState("");
    const [brGruppe, setBrGruppe] = useState("");
    const [fehlerliste, setFehlerliste] = useState([]);
    const { showErrorMessage } = useMessage();
    const [anzFehler, setAnzFehler] = useState("0");
    const [bemerkungen, setBemerkungen] = useState("");
    const [reactnummer] = useState(reactNummer);
    const [merkmal] = useState(merkMal);
    const [open, setOpen] = useState(false);
    const [kurskennung] = useState("");
    const [lohndaten, setLohndaten] = useState([]);
    const localStorageData = localStorage.getItem('dataCourse')
    const BuchDateneingabe = '/media/Buch_Dateneingabe.png';
    

    useEffect(() => {

        if (localStorageData && localStorageData.length > 0) {
            setAntworten(JSON.parse(localStorageData))
        }

    }, [])

    const setzeAntworten = (key, value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        localStorage.setItem("dataCourse", JSON.stringify(values))
        forceUpdate();
    };

    const setRedirect = (path) => {
        navigate(path);
    };

    const fehlerAusLesen = (fehler) => {
        let array = fehler.split(";");
        let fehlerzwis = [];
        array.map(
            // eslint-disable-next-line
            (objekt) => {
                fehlerzwis[objekt] = true;
            }
        );
        setFehlerliste(fehlerzwis);
    };

    const bruttoEintragung5_5 = () => {
        const numbers = Array.from({ length: 25 }, (_, i) => i + 1);

        const tmpReturn = numbers.map((id) => {
            return (
                <div style={{ width: "100%", float: "left" }}>
                    <SimpleTextField id={"TEXT_5_1_" + id} placeholder="MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} style={{ borderLeft: "solid" }} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                    <SimpleTextField id={"TEXT_5_2_" + id} placeholder="W = West (z.B.)" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} style={{ borderLeft: "solid" }} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                    <SimpleTextField id={"TEXT_5_3_" + id} placeholder="00000.00" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} style={{ borderLeft: "solid" }} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                    <SimpleTextField id={"TEXT_5_4_" + id} placeholder="0000.00" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} style={{ borderLeft: "solid" }} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                    <SimpleTextField id={"TEXT_5_5_" + id} placeholder="00000.00" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} style={{ borderLeft: "solid", borderRight: "solid" }} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                </div>
            )
        }
        )

        return tmpReturn
    }

    const antwortenSenden = () => {
        let values = Object.assign(antworten);
        values["UEBERGANG"] = "nein";
        values["MEHRFACHBESCHAEFTIGT"] = "nein";
        values["GERINGVERDIENER"] = "nein";
        values["FAMILIENSTAND"] = "ledig";
        values["BUNDESLAND"] = "08 BW";
        values["RENTENSTATUS"] = "nein";
        values["BETRGRUPPE"] = brGruppe;

        setAntworten(values);
        forceUpdate();
        const headers = {
            Authorization: "Bearer " + accessToken,
        };
        const payload = {
            kennung: "kursabgabe",
            merkmal: merkmal,
            daten: antworten,
            reactnummer: reactnummer,
            hsutkey: hsutkey,
        };
        apiRequest("POST", "kursabgabe", payload, headers, kurseCb, setRedirect);
    };

    const kurseCb = (response) => {
        if (response.status === 200) {
            setTestabgabe(true);
            setAbgabedat(
                response.data.abgabedat.slice(6, 8) +
                "." +
                response.data.abgabedat.slice(4, 6) +
                "." +
                response.data.abgabedat.slice(0, 4)
            );
            setAbgabezeit(response.data.abgabezeit);
            fehlerAusLesen(response.data.fehler);
            setAnzFehler(response.data.anzfehler);
            setBemerkungen(response.data.besonderheiten);
        } else {
            if (response) {
                showErrorMessage(response.data.message);
            } else {
                showErrorMessage("Keine Internetverbindung");
            }
        }
    };




    function SelectFunction(id, number) {
        const [selected, setSelected] = useState("");

        const handleChange = (event) => {
            const value = event.target.value;
            setzeAntworten(id + number, event.target.value)
            setSelected(value);
        };

        return (
            <div>
                <Select style={{ backgroundColor: fehlerliste[id + number] === true ? "red" : "", height: "32px", width: "25%", display: "flex", float: "left", borderLeft: "solid", borderRight: "solid" }} value={selected} onChange={handleChange}>
                    <MenuItem value={""}>Leer</MenuItem>
                    <MenuItem value={"Sonderzahlung"}>Sonderzahlung</MenuItem>
                    <MenuItem value={"Energiepauschale"}>Energiepauschale</MenuItem>
                    <MenuItem value={"Urlaubsgeld"}>Urlaubsgeld</MenuItem>
                    <MenuItem value={"Weihnachtsgeld"}>Weihnachtsgeld</MenuItem>
                    <MenuItem value={"13. Gehalt"}>13. Gehalt</MenuItem>
                </Select>
            </div>
        );
    }


    const arbeitsEntgeld = () => {
        const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

        const tmpReturn = numbers.map((id, i) => {
            return (
                <div style={{ width: "100%", float: "left" }}>

                    <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                        von:
                    </Typography>
                    <SimpleTextField id={"VON2.2." + id} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"15%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                    <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                        bis:
                    </Typography>
                    <SimpleTextField id={"BIS2.2." + id} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"15%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                    <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                        Gründe:
                    </Typography>
                    <SimpleTextField id={"GRUENDE2.2." + id} placeholder="z.B. 01 = Krankheit ohne LFZ" label="" fehlerliste={fehlerliste} width={"40%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                </div>)
        }
        )

        return tmpReturn

    }


    useEffect(() => {
      console.log(fehlerliste)
    }, [fehlerliste])
    


    useEffect(() => {
        rahmendaten_holen();
    }, [])

    const rahmendaten_holen = () => {
        forceUpdate();
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        const payload = {
            "kennung": "kursdaten",
            "reactnummer": reactnummer,
            "kurskennung": kurskennung,
            "hsutkey": hsutkey
        }
        apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
        if (response.status === 200) {
            if (response.data.links) {
                setLinks(response.data.links)
            }
            if (response.data.lohndaten) {
                setLohndaten(response.data.lohndaten);

            }
        }
    }

    const DokumentOeffnen = (docnr, docbez, docendung) => {
        const payload = {
            "kennung": "kursdaten",
            "kurskennung": "download_lohndaten",
            "reactnummer": reactnummer,
            "docnr": docnr,
            "docname": docbez,
            "hsutkey": hsutkey
        }
        const headers = {
            "Authorization": "Bearer " + accessToken,
            "Accept": "application/pdf"
        }
        axios.post(API_BASE_URL + 'kursdaten', payload, { headers: headers, responseType: 'blob' })
            .then((response) => {
                if (docendung === undefined) { var fileName = docbez } else { var fileName = docbez + docendung };
                var contentFile = response.data;
                const mimeType = response.headers['content-type'] || 'application/pdf'
                openFile(fileName, contentFile, mimeType);
            })
            .catch(function (error) {
                showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
            })
    }


    return (
        <div>
            <Briefkopf
                ueberschrift1="Arbeitsbescheinigung"
                ueberschrift2=" In diesem Bereich übst du eine Arbeitsbescheinigung auszufüllen"
            />

            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#1fbba6" }}>
                        1
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Bitte verwende dieses Dokument zum Ausfüllen der Arbeitsbescheinigung " />
            </ListItem>

            <Grid container style={{}}>
                <Grid item xs={12} style={{ marginTop: "15px", marginBottom: "15px" }}>
                    {
                        lohndaten?.map((inhalt, index) => {
                            return (

                                <Card sx={{ display: 'flex', marginTop: '10px', alignItems: "center" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 200 }}
                                        image={BuchDateneingabe}
                                        alt="Handbücher"
                                    />

                                    <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="h5" >
                                                {inhalt.bez}
                                            </Typography>
                                        </CardContent>

                                    </Box>

                                    <Button
                                        color="primary"
                                        variant="text"
                                        key={index}
                                        target="_blank"
                                        alignItems="left"
                                        onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez, inhalt.endung)}
                                    >
                                        Zu erfassende Lohndaten in einem neuen Tab anzeigen
                                    </Button>

                                </Card>
                            )
                        })
                    }
                </Grid>
            </Grid>

            <br /><br />
            {/* <Button onClick={()=> {console.table(antworten)}}>Tmp btton</Button> */}

            <ListItem style={{ paddingBottom: "30px" }}>
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#1fbba6" }}>
                        2
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Fülle nun die Arbeitsbescheinigung aus. Achte dabei auf das vorgegebene Format (wenn vorhanden) " />
            </ListItem>

            <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                Kundennummer:
            </Typography>            
            <SimpleTextField id={"KUNDENNUMMER"} placeholder="Nur Großbuchstaben, keine Leerzeichen" label="" fehlerliste={fehlerliste} width={"25%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

            <br></br><br></br><br></br>

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Typography style={{ paddingLeft: "4px", textAlign: "left", borderRight: "solid", borderLeft: "solid", borderTop: "solid", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                        Bitte Beachten Sie:
                    </Typography>
                    <Typography multiline={true} style={{ paddingLeft: "4px", textAlign: "left", borderLeft: "solid", borderBottom: "solid", borderRight: "solid", width: "100%", fontSize: "1.0em", height: "224px", lineHeight: "32px", }}>
                        Diese Bescheinigung ist eine Urkunde, zu deren Ausstellung der Arbeitgeber auf Verlangen der Agentur für Arbeit verpflichtet ist (§ 57 SGB II). Dies
                        trifft selbst dann zu, wenn noch ein Arbeitsgerichtsverfahren anhängig ist. Wer eine Auskunft nicht, nicht richtig, nicht vollständig oder nicht
                        rechtzeitig erteilt, handelt ordnungswidrig (§ 63 Abs. 1 Nr. 1 SGB II). Außerdem ist sie/er dem zuständigen Leistungsträger zum Ersatz des daraus
                        entstandenen Schadens verpflichtet (§ 62 SGB II). Eine unvollständig ausgefüllte Arbeitsbescheinigung erfordert Rückfragen oder eine
                        Rückgabe zur Ergänzung. Achten Sie deshalb bitte darauf, dass alle Felder ausgefüllt werden. Die Hinweise bei den Fragen sollen Ihnen das
                        Ausfüllen erleichtern. Etwaige Änderungen oder Ergänzungen der Eintragungen bestätigen Sie bitte mit Unterschrift. Informationen zur erstellung der Bescheinigung per EDV erhalten Sie bei der Agentur für Arbeit. Diese Bescheinigung finden Sie
                        auch im Internet unter www.jobcenter.digital.
                    </Typography>

                </div>
            </Grid>
            <br></br>
            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            1. Angaben zu den Persönlichen Daten des Arbeitnehmers
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "25%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Familienname/ggf. Geburtsname
                        </Typography>

                        <SimpleTextField id={"NACHNAME"} placeholder="" label="" fehlerliste={fehlerliste} width={"75%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "25%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Vorname
                        </Typography>
                        <SimpleTextField id={"VORNAME"} placeholder="" label="" fehlerliste={fehlerliste} width={"75%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Straße
                        </Typography>
                        <SimpleTextField id={"STRASSE"} placeholder="" label="" fehlerliste={fehlerliste} width={"50%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Hausnummer
                        </Typography>
                        <SimpleTextField id={"HAUSNUMMER"} placeholder="" label="" fehlerliste={fehlerliste} width={"30%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Postleitzahl
                        </Typography>
                        <SimpleTextField id={"POSTLEITZAHL"} placeholder="" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingLeft: "10px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Ort
                        </Typography>
                        <SimpleTextField id={"ORT"} placeholder="" label="" fehlerliste={fehlerliste} width={"60%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "35%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Rentenversicherungsnummer(wenn nicht bekannt, Geburtsdatum)
                        </Typography>
                        <SimpleTextField id={"RENTENVERSICHERUNGSNUMMER"} placeholder="" label="" fehlerliste={fehlerliste} width={"65%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            1.1 Eintragungen in der Lohnsteuerkarte zu Beginn des Jahres, in dem das Beschäftigungsverhältnis endete
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Jahr
                        </Typography>
                        <SimpleTextField id={"JAHR"} placeholder="" label="" fehlerliste={fehlerliste} width={"23.23%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Lohnsteuerklasse
                        </Typography>
                        <SimpleTextField id={"LOHNSTEUERKLASSE"} placeholder="" label="" fehlerliste={fehlerliste} width={"23.23%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "15%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Zahl der Kinderfreibeträge
                        </Typography>
                        <SimpleTextField id={"ZAHLKINDERFREI"} placeholder="" label="" fehlerliste={fehlerliste} width={"18%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Erfolgten spätere Änderungen der Eintragungen?
                        </Typography>
                        <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK1_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten} ></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Mit wirkung ab:
                        </Typography>
                        <SimpleTextField id={"TEXT_1_1_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"18%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "15%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Lohnsteuerklase:
                        </Typography>
                        <SimpleTextField id={"TEXT_1_1_2"} placeholder="" label="" fehlerliste={fehlerliste} width={"18%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "15%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Zahl der Kinderfreibeträge:
                        </Typography>
                        <SimpleTextField id={"TEXT_1_1_3"} placeholder="" label="" fehlerliste={fehlerliste} width={"18%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                    
                    </Grid>
                </div>
            </Grid>
            <br></br>
            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "50%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            2. Angaben zum Beschäftigungsverhältniss
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "50%", fontSize: "0.8em", height: "32px", lineHeight: "16px", }}>
                            (bei den Fragen 2.1-2.6 sind Angaben für die letzten 5 Jahre vor dem Ende des Beschäftigungsverhältnisses erforderlich)
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            2.1 Der Arbeitnehmer war innerhalb der letzten 5 Jahre beschäftigt
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"VON2.1.1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"BIS2.1.1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            zuletzt als:
                        </Typography>
                        <SimpleTextField id={"BESCHAEFTIGTALS1"} placeholder="Tätigkeitscode" label="" fehlerliste={fehlerliste} width={"50%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"VON2.1.2"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"BIS2.1.2"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            zuletzt als:
                        </Typography>
                        <SimpleTextField id={"BESCHAEFTIGTALS2"} placeholder="Tätigkeitscode" label="" fehlerliste={fehlerliste} width={"50%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            letzter Beschäftigungsort:
                        </Typography>
                        <SimpleTextField id={"BESCHORT"} placeholder="" label="" fehlerliste={fehlerliste} width={"80%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                        <Typography style={{ paddingTop: "10px", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "16px", }}>
                            Mehrere Eintragungen sind nur erforderlich, wen das Beschäftigungsverhältnis zwischenzeitlich beendet war (z.B. be Wiedereinstellung). Bitte Dauer und Art der Beschäftigung genau bezeichnen (z.B. Verkäufer, Geschäftsführer, Auszubildender).
                            Das versicherungsrechtliche
                            Beschäftigungsverhältnis endet mit dem Tag, für den letztmalig Arbeitsentgelt gezahlt wird.
                        </Typography>

                        <br></br><br></br><br></br>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "80%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            2.2 Hat der Arbeitehmer für eine zusammenhängende Zeit von mehr als einem Monat kein Arbeitsentgelt erhalten?
                        </Typography>
                        <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK2_2" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "96px", lineHeight: "32px", }}>
                            Für jeden der folgenden Zeiträume wurde die Zahlung von Arbeitsentgeld-für sich alleine betrachtet-länger als einen Monat unterbrochen (bitte jeweils gesamten Unterbrechungszeitraum
                            eintragen). Ausnahme: Unterbrechungen gem. §3 Abs. 1 Satz 1 PflegeZG sind auch zu bescheinigen, wenn sie weniger als einen Monat umfassen. Dies git auch für unterbrechungszeiten im
                            Anschluss an Pflegezeiten
                        </Typography>

                        {arbeitsEntgeld()}

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Gründe:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "96px", lineHeight: "32px", }}>
                            Mutterschaft, Krankheit ohne Lohnfortzahlung, Krankheit des Kindes, Freistellung seitens des Arbeitgebers, Wehrdienst,
                            Erwerbsminderungs-Rente auf Zeit, Elternzeit, Pflegezeit nach §3 Abs. 1 Satz 1 PflegeZG oder unbezahlte Fehlzeiten
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Hinweis:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "96px", lineHeight: "32px", }}>
                            Freistellungen, für die versicherungspflichtiges Wertguthaben ausbezahlt wird, sind nicht als Unterbrechungszeit einzutragen.
                        </Typography>

                    </Grid>

                </div>
            </Grid>
            <br></br>


            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "80%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            2.3 Wurde der Beschäftigte als arbeitslosenversicherungsfreier Arbeitnehmer geführt?
                        </Typography>
                        <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK2_3" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"VON2.3.1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"VON2.3.2"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"VON2.3.3"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"VON2.3.4"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "80%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            2.4 War der Arbeitnehmer zuletzt in der gesetzlichen Rentenversicherung versichert?
                        </Typography>
                        <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK2_4" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ fontWeight: "bold", float: "left", width: "95%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK2_4_1" setzeAntworten={setzeAntworten} label="Der Arbeitnehmer gehörte wegen seiner Beschäftigung der Knappschaftlichen Rentenversicherung an" savedInput={antworten}></CheckBoxSingle>
                        </Typography> 

                    </Grid>
                </div>
            </Grid>
            <br></br>

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            3. Angaben zur Beendigung des Beschäftigungs/Arbeitsverhältnisses
                            <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "16px", lineHeight: "16px", }}>
                                Angaben sind auch erforderlich, wenn ein befristetes Arbeitsverhältnis vorzeitig beendet wurde.
                            </Typography>
                        </Typography>
                        <Grid container >
                            <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "5%", fontSize: "1.2em", height: "38px", lineHeight: "38px", }}>
                                3.1
                            </Typography>
                            <Typography style={{ fontWeight: "bold", float: "left", width: "35%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                                <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_1_1" setzeAntworten={setzeAntworten} label="Das Arbeitsverhältniss wurde gekündigt/beendet" savedInput={antworten}></CheckBoxSingle>
                            </Typography>
                            <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "38px", lineHeight: "38px", }}>
                                am:
                            </Typography>
                            <SimpleTextField id={"DATUM_3_1_1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"15%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                            <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "38px", lineHeight: "38px", }}>
                                zum:
                            </Typography>
                            <SimpleTextField id={"DATUM_3_1_2"} placeholder="MM.YYYY" label="" fehlerliste={fehlerliste} width={"15%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        </Grid>

                        <Grid container >
                            <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "38px", lineHeight: "38px", }}>
                                durch
                            </Typography>
                            <Typography style={{ fontWeight: "bold", float: "left", width: "30%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                                <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_1_2" setzeAntworten={setzeAntworten} label="Den Arbeitgeber" savedInput={antworten}></CheckBoxSingle>
                            </Typography>
                            <Typography style={{ fontWeight: "bold", float: "left", width: "30%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                                <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_1_3" setzeAntworten={setzeAntworten} label="Aufhebungsvertrag" savedInput={antworten}></CheckBoxSingle>
                            </Typography>
                            <Typography style={{ fontWeight: "bold", float: "left", width: "30%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                                <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_1_4" setzeAntworten={setzeAntworten} label="den Arbeitnehmer" savedInput={antworten}></CheckBoxSingle>
                            </Typography>
                        </Grid>

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Wenn durch den Arbeitgeber:
                        </Typography>

                        <Grid container >
                            <List style={{ width: "100%" }} >

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                        Die Kündigung/Beendigung erfolgte schriftlich.
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_5" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "48px", lineHeight: "16px", }}>
                                        <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                        Es handelt sich um eine betriebsbedingte Kündigung gem §1a KschG mit Abfindungsangebot <br></br>
                                        Hinweis: Im Kündigungsschreiben muss angegeben sen, dass die Kündigung auf dringende betriebliche Erfordernisse gestützt wrid und der Arbeitnehmer bei verstreichen lassen der Klagefrist eine
                                        Abfindung beanspruchen kann.
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_6" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                        Der Arbeitnehmer hat Kündigungsschutzklage innerhalb der Frist gem §4 KSchG erhoben.
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_7" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "96px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                            Vertragswidriges Verhalten des Arbeitnehmers war Anlass.
                                            Bitte ggf. das vertragswidrige Vehalten kurz schildern (ggf. unterZiffer 9 fortsetzen)
                                        </Typography>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_8" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                        </Typography>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "60%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Der Arbeitnehmer war wegen desselben Verhaltens bereits abgemahnt worden
                                            Ggf Datum der Abmahnung:

                                        </Typography>
                                        <SimpleTextField id={"DATUM_3_1_3"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>

                                        </Typography>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_9" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                        </Typography>

                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                        Vor oder nach der Kündigung wurden zusätzliche Vereinbarungen getroffen (z.B. Abwicklungsvertrag)
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_10" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "96px", lineHeight: "32px", }}>
                                        <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                        Eine Sozialauswahl wurde vorgenommen:
                                        <Typography style={{ float: "left", paddingLeft: "40px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_1_11" setzeAntworten={setzeAntworten} label={"entfällt bei personenbedingter Kündigung"} savedInput={antworten} ></CheckBoxSingle>
                                        </Typography>
                                        <Typography style={{ float: "left", paddingLeft: "8px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "16px", }}>
                                            Hinweis: Diese Tatsachenerklärung durch den Arbeitgeber bewertet nicht, ob eine Sozialauswahl vorzunehmen war oder die Sozialauswahl den Vorschriften des KSchG entspricht
                                        </Typography>

                                    </Typography>

                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_12" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>

                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Wenn ja: Die Sozialauswahl wurde von der Agentur für Arbeit geprüft (Sammelentscheidug)
                                        </Typography>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Wenn ja: von der Agentur für Arbeit
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_1_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"50%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                                    </Typography>


                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_13" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "32%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                                            Wie wurde die Kündigung zugestellt?
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_1_2"} placeholder="" label="" fehlerliste={fehlerliste} width={"40%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Bei Lösung des Arbeitsverhältnisses durch Aufhebungsvertrag oder Kündigung durch den Arbeitsnehmer:
                                        </Typography>
                                    </Typography>
                                </ListItem>


                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Der Arbeitgeber hätte das Arbeitsverhältnis gekündigt:
                                        </Typography>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_14" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "9%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Wenn ja:    am
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_1_3"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            zum:
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_1_4"} placeholder="MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "40px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        betriebsbedingt:
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_15" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "40px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        wegen vertragswiedrigen Verhaltens:
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_1_16" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                    </Typography>
                                </ListItem>
                            </List>



                            <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "5%", fontSize: "1.2em", height: "38px", lineHeight: "38px", }}>
                                3.2
                            </Typography>
                            <Grid container style={{ paddingLeft: "40px" }}>
                                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_2_1" setzeAntworten={setzeAntworten} label={""}  savedInput={antworten}></CheckBoxSingle>
                                    </Typography>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>

                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "50%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Das Arbeitsverhältniss war bei Abschluss des Arbeitsvertrages befristet bis zum
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_2_1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                    </Typography>

                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "70px", textAlign: "left", width: "50%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Der befristete Arbeitsvertrag wurde abgeschlossen am
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_2_2"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                    </Typography>
                                </Typography>

                                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                    <Typography style={{ float: "left", paddingLeft: "70px", textAlign: "left", width: "30%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        Ggf. Verlängerung am
                                    </Typography>
                                    <SimpleTextField id={"TEXT_3_2_3"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                    <Typography style={{ float: "left", paddingLeft: "70px", textAlign: "left", width: "30%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        Die befristung erfolgte schriftlich:
                                    </Typography>

                                </Typography>
                                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                    <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_2_2" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                </Typography>


                                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                    <Typography style={{ float: "left", paddingLeft: "70px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "16px", }}>
                                        Die befristete Beschäftigung war für mind. 2 Monate vorgesehen und eine Mögöicheit der Weiterbeschäftigung wurde durch den Arbeitgeber bei Abschluss des Vertrages in Aussicht gestellt.
                                    </Typography>

                                </Typography>
                                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                    <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK3_2_3" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                                </Typography>
                            </Grid>

                            <List style={{ width: "100%" }} >
                                <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "5%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                                    3.3
                                </Typography>
                                <ListItem sx={{ gap: 1 }}  >
                                    <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_3_1" setzeAntworten={setzeAntworten} label="" savedInput={antworten}></CheckBoxSingle>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                        Es bestand ein Ausbildungsverhältnis, das mit Bestehen der Abschlussprüfung endete.
                                    </Typography>
                                </ListItem>

                                <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "5%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                                    3.4
                                </Typography>
                                <ListItem sx={{ gap: 1 }}  >
                                    <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK3_4_1" setzeAntworten={setzeAntworten} label="" savedInput={antworten}></CheckBoxSingle>
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "16px", }}>
                                        Das Beschäftigungsverhältnis ist beendet, weil die Beschäftigung nicht mehr ausgeübt wird, das
                                        Arbeitsverhältnis besteht jedoch fort (z.B. bei einvernehmlicher unwiderruflicher oder sonstiger Freistellung
                                        während der Kündigungsfrist oder Aussteuerung aus dem Krankengeldbezug)
                                    </Typography>
                                </ListItem>

                                <ListItem sx={{ gap: 1 }}  >
                                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                                Grund:
                                            </Typography>
                                            <SimpleTextField id={"TEXT_3_4_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"90%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                            
                                        </Typography>
                                        <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                                            Zeitraum:
                                        </Typography>
                                        <SimpleTextField id={"TEXT_3_4_2"} placeholder="DD.MM.YYYY-DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"90%"} display={"flex"}  float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                                    </Typography>
                                </ListItem>
                            </List  >
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <br></br>

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            4. Angaben zur Wöchentlichen Arbeitszeit
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Die durchschnittliche regelmäßige Arbeitszeit betrug:
                        </Typography>
                        <SimpleTextField id={"TEXT_4_1_1"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Stunden/Woche
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_1" setzeAntworten={setzeAntworten} label="Vollzeitarbeit" savedInput={antworten}></CheckBoxSingle>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_2" setzeAntworten={setzeAntworten} label="Teilzeitarbeit" savedInput={antworten}></CheckBoxSingle>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Abweichung von der vereinbarten Arbeitszeit in den letzten 42 Monaten der Beschäftigung.
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}></Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"TEXT_4_1_2"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"TEXT_4_1_3"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}></Typography>
                        <SimpleTextField id={"TEXT_4_1_4"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Stunden/Woche
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            Grund:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "95%", fontSize: "1.0em", height: "120px", lineHeight: "32px", float: "left" }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_3" setzeAntworten={setzeAntworten} label="Altersteilzeitvereinbarung" savedInput={antworten}></CheckBoxSingle>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_4" setzeAntworten={setzeAntworten} label="Sonstige Teilzeitvereinbarungen" savedInput={antworten}></CheckBoxSingle>
                            <br></br>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_5" setzeAntworten={setzeAntworten} label="Vereinbarungen über flexible Arbeitszeiten mit Arbeitsphasen und Freizeitphasen (§7 Abs. 1a Viertes Buch Sozialgesetzbuch)" savedInput={antworten}></CheckBoxSingle>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK4_1_6" setzeAntworten={setzeAntworten} label="Verminderung der Arbeitszeit gem. §3 Abs. 1 Satz 1 PfleeZG*)s. hierzu Erläuterungshinweis" savedInput={antworten}></CheckBoxSingle>

                        </Typography>

                        <Typography style={{ paddingLeft: "70px", textAlign: "left", width: "100%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            Bei Vorliegen einer solchen Vereinbarung:
                        </Typography>
                        <Typography style={{ paddingLeft: "70px", textAlign: "left", width: "80%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            Die durchschnittliche regelmäßige Arbeitszeit eines vergleichbaren Vollzeitbeschäftigten beträgt:
                        </Typography>
                        <SimpleTextField id={"TEXT_4_1_5"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "32px", lineHeight: "32px", }}>
                            Stunden/Woche
                        </Typography>

                    </Grid>
                </div>
            </Grid>
            <br></br>

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            5. Angaben zum Arbeitsentgelt
                        </Typography>

                        <Grid item xs={12} style={{ borderTop: "solid", borderBottom: "solid" }}>
                            <div style={{ borderBottom: "solid" }}>
                                <Typography style={{ backgroundColor: "lightgrey", borderBottom: "solid", borderLeft: "solid", float: "left", width: "20%", fontSize: "1em", height: "48px", lineHeight: "16px" }}>
                                    Abbrechnungsmonat
                                </Typography>
                                <Typography style={{ backgroundColor: "lightgrey", borderBottom: "solid", borderLeft: "solid", float: "left", width: "20%", fontSize: "1em", height: "48px", lineHeight: "16px" }}>
                                    Rechtskreis
                                </Typography>
                                <Typography style={{ backgroundColor: "lightgrey", borderBottom: "solid", borderLeft: "solid", float: "left", width: "20%", fontSize: "1em", height: "48px", lineHeight: "16px" }}>
                                    SV Brutto Ifd
                                </Typography>
                                <Typography style={{ backgroundColor: "lightgrey", borderBottom: "solid", borderLeft: "solid", float: "left", width: "20%", fontSize: "1em", height: "48px", lineHeight: "16px" }}>
                                    SV Brutto Ega
                                </Typography>
                                <Typography style={{ backgroundColor: "lightgrey", borderBottom: "solid", borderRight: "solid", borderLeft: "solid", float: "left", width: "20%", fontSize: "1em", height: "48px", lineHeight: "16px" }}>
                                    fiktives Entgelt
                                </Typography>



                                {bruttoEintragung5_5()}


                                <Typography style={{ backgroundColor: "", borderTop: "solid", fontWeight: "bold", borderLeft: "solid", float: "left", width: "80%", fontSize: "0.8em", height: "34px", lineHeight: "32px" }}>
                                    Summe des beitragspflichtigen Arbeitsentgelts (Eintragung erwünscht) =
                                </Typography>
                                <SimpleTextField id={"TEXT_5_6_1"} placeholder="00000.00" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} float={"left"} style={{ borderRight: "solid", borderTop: "solid", borderLeft: "solid" }} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <br></br>
            {/* 
            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            6.
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Zusätzliche Angaben zum oben bescheinigten Arbeitsentgelt
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            6.1
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "77%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Sind im bescheinigten Arbeitsentgeld beitragspflichtige Einmalzahlungen enthalten?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "20%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK6_1_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            6.2
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "77%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            Wurde das Arbeitsentgelt in einem Beschäftigungsverhältnis in den neuen Bundesländern
                            einschl. des ehemaligen Ostteils von Berlin erzielt, ohne dass es sich um eine Entsendung
                            handelte?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "20%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK6_2_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            6.3
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            Zusatzangaben nur für Heimarbeiter für die bescheinigten Abrechnungszeiträume
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "15%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Urlaubsanspruch/Jahr:
                        </Typography>
                        <SimpleTextField id={"TEXT_5_4_1"} placeholder="00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "15%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Tage
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Bescheinigte tatsächliche Urlaubstage:
                        </Typography>
                        <SimpleTextField id={"TEXT_5_4_1"} placeholder="00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Im bescheiigten Bruttoarbeitsentgelt enthaltenes Urlaubsentgelt:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Betrag EUR
                        </Typography>
                        <SimpleTextField id={"TEXT_5_4_1"} placeholder="0000.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}></Typography>

                        <Typography style={{ paddingLeft: "4px", fontWeight: "bold", textAlign: "left", width: "10%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            gezahlt:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK6_2_1" setzeAntworten={setzeAntworten} label1={"bei Urlaubsantritt"} label2={"als lfd. Entgeltzuschlag"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", fontWeight: "bold", textAlign: "left", width: "50%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}></Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            6.4
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "77%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            Wurde das Arbeitsentgelt wegen einer Vereinbarung gem §3 Abs. 1 Satz 1 des PflegeZG vermindert?
                        </Typography>
                        <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK6_4_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>

                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "10%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"TEXT_6_4_1"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"TEXT_6_4_2"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", fontWeight: "bold", textAlign: "left", width: "60%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}></Typography>



                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1.0em", height: "64px", lineHeight: "32px", }}></Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            von:
                        </Typography>
                        <SimpleTextField id={"TEXT_6_4_3"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1.0em", height: "32px", lineHeight: "32px", float: "left" }}>
                            bis:
                        </Typography>
                        <SimpleTextField id={"TEXT_6_4_4"} placeholder="00.00" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>


                    </Grid>
                </div>
            </Grid>
            <br></br> */}

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            7.
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Leistungen im Zusammenhang mit der Beendigung des Arbeits/Beschäftigungsverhältnisses
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            7.1
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "77%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            Wurden Leistungen im Zusammenhang mit der Beendigung des Arbeits/Beschäftigungs bzw. Heimarbeitsverhältnisses gezahlt oder bestet hierauf noch ein Anspruch?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "20%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_1_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK7_1_2" setzeAntworten={setzeAntworten} label="ist ungewiss," savedInput={antworten}></CheckBoxSingle>
                            grund:
                        </Typography>
                        <SimpleTextField id={"TEXT_7_1_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"50%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "30%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}></Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            7.2
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", fontWeight: "bold", width: "27%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "70%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                            Es wurde gezahlt oder ist noch zu zahlen
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "60%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            -Arbeitsentgelt über das Ende des Beschäftigungsverhältnisses hinaus
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_2_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textDecoration: "underline", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            für die Zeit bis einschließlich
                        </Typography>
                        <SimpleTextField id={"TEXT_7_2_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"15%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "45%", fontSize: "1em", height: "32px", lineHeight: "32px", }}></Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            7.3
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", fontWeight: "bold", width: "57%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            -Urlaubsabgeltung wegen der Beendigung des Beschäftigungs-/Arbeits-/Heimarbeitsverältnisses
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_3_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textDecoration: "underline", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "40%", fontSize: "1em", height: "80px", lineHeight: "16px", }}>
                            Wäre der noch zustehende Urlaub im Anschluss an das Arbeits-/Beschäftigungsverhältnis genommen worden, hätte er nach den gesetzlichen (tarif-) vertraglichen Bestimmungen gedauert bis einschließlich
                            <SimpleTextField id={"TEXT_7_3_1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"70%"} display={"flex"} float={"right"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1em", height: "80px", lineHeight: "16px", }}></Typography>



                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            7.4
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "57%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            -Abfindung, Entschädigung oder ähnliche Leistungen wegen der Beendigung des Beschäftigungs-/Arbeits- bzw. Heimarbeitsverhältnisses.
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_4_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textDecoration: "underline", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "40%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Leistung:
                            <SimpleTextField id={"TEXT_7_4_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"70%"} display={"flex"} float={"right"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "80%", fontSize: "1em", height: "96px", lineHeight: "32px", }}>
                            Höhe Brutto (auch bei Nettoabfindungen) ohne Beiträge, die der Arbeitgeber für die Rentenversicherung des Arbeitnehmers nach §187a Abs. 1SGB VI
                            oder vergeichbare Beiträge für berufsständische Versorgungseinrichtungen aufwendet, wenn das Arbeitsverhältnis frühestens mit Vollendung des 55.
                            Lebensjahres des Arbeitnehmers beendet worden ist.
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "20%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                            <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>

                                Betrag EUR <SimpleTextField id={"TEXT_7_4_2"} placeholder="00000.00" label="" fehlerliste={fehlerliste} width={"70%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                            </Typography>
                            <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                                <SimpleTextField id={"TEXT_7_4_3"} placeholder="0" label="" fehlerliste={fehlerliste} width={"70%"} display={"flex"} float={"right"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                                Jahre
                            </Typography>
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "60%", fontSize: "1em", height: "96px", lineHeight: "32px", }}>
                            Dauer der Betriebs-/Unternehmenszugehörgkeit (auf volle Jahre nach unten Abgerundet). Im Falle eines Aufhebungsvertrages oder der Arbeitnehmerkündigung:
                            Wäre die Leistung auch bei einer Arbeitsgeberseitigen Kündigung gezahlt worden, die an Stelle des Aufhebungsvertrages oder der Arbeitehmerkündigung erfolgt wäre?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_4_2" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "100%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                            
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "60%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                            Entspricht die Abfindung einem Betrag von bis zu .5 Monatsgehälter für jedes Jahr des Arbeitsverhältnisses (§1a Abs. 2 KSchG)?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_4_3" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            7.5
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "57%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            -Vorruhestandsgeld oder eine vergleichbare Leistung
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "40%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK7_5_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "40px", textDecoration: "underline", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "5%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            ab
                        </Typography>
                        <SimpleTextField id={"TEXT_7_5_1"} placeholder="DD.MM.YYYY" label="" fehlerliste={fehlerliste} width={"10%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            in v.H. des Bruttoarbeitsentgelts
                        </Typography>
                        <SimpleTextField id={"TEXT_7_5_2"} placeholder="" label="" fehlerliste={fehlerliste} width={"20%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "25%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            v.H.
                        </Typography>

                    </Grid>
                </div>
            </Grid>
            <br></br>

            <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            8.
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Angaben zur Kündigungsfrist
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            8.1
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Die maßgebende (gesetzliche, tarifvertragliche, vertragliche) Kündigungsfrist des Arbeitgebers beträgt
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}></Typography>
                        <SimpleTextField id={"TEXT_8_1_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"12%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "12%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Kalendertage
                        </Typography>
                        <SimpleTextField id={"TEXT_8_1_2"} placeholder="" label="" fehlerliste={fehlerliste} width={"12%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "12%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Werktage
                        </Typography>
                        <SimpleTextField id={"TEXT_8_1_3"} placeholder="" label="" fehlerliste={fehlerliste} width={"12%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "12%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wochen
                        </Typography>
                        <SimpleTextField id={"TEXT_8_1_4"} placeholder="" label="" fehlerliste={fehlerliste} width={"12%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "12%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Monate
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "38px", }}>
                            zum
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "18%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK8_1_1" setzeAntworten={setzeAntworten} label="Ende der WOche" savedInput={antworten}></CheckBoxSingle>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "18%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK8_1_2" setzeAntworten={setzeAntworten} label="15. des Monats" savedInput={antworten}></CheckBoxSingle>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "18%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK8_1_3" setzeAntworten={setzeAntworten} label="Monatsende" savedInput={antworten}></CheckBoxSingle>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "18%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK8_1_4" setzeAntworten={setzeAntworten} label="Ende des Vierteljahres" savedInput={antworten}></CheckBoxSingle>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "18%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxSingle fehlerliste={fehlerliste} id="CHECK8_1_5" setzeAntworten={setzeAntworten} label="ohne festes Ende" savedInput={antworten}></CheckBoxSingle>
                        </Typography>


                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            8.2
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "64px", lineHeight: "32px", }}>
                            War die ordentliche Kündigung des Arbeitsverhältnisses durch den Arbeitgeber/Auftragsgeber/Zwischenmeister gesetzlich oder (tarif-) vertraglich zeitlich unbegrenzt ausgeschlossen?
                        </Typography>

                        <Typography style={{ paddingLeft: "40px", textDecoration: "underline", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "50%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Erfolgte eine fristgebundene Kündigung aus wichtigem Grund?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK8_2_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}></Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Grund:
                        </Typography>
                        <SimpleTextField id={"TEXT_8_2_1"} placeholder="" label="" fehlerliste={fehlerliste} width={"40%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "40px", textAlign: "left", fontWeight: "bold", width: "80%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            War die ordentliche Kündigung zeitlich begrenzt ausgeschlossen?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK8_2_2" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}></Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Grund:
                        </Typography>
                        <SimpleTextField id={"TEXT_8_2_2"} placeholder="" label="" fehlerliste={fehlerliste} width={"40%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "30%", fontSize: "1em", height: "32px", lineHeight: "32px", }}></Typography>

                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            8.3
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "77%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            War die ordentliche Kündigung (tarif-)vertraglich nur bei einer Abfindung, Entschädigung oder ähnlichen Leistung zulässig?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK8_3_1" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}></Typography>
                        <Typography style={{ paddingLeft: "4px", textDecoration: "underline", textAlign: "left", width: "10%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            Wenn ja:
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "40%", fontSize: "1em", height: "96px", lineHeight: "32px", }}>
                            Liegen gleichzeitig die Vorraussetzungen für eine fristgebundene Kündigung aus wichtigem Grund vor oder wären diese ohne besondere (tarif-)vertragliche Kündigungsregelung gegeben gewesen?
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", width: "20%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                            <CheckBoxDouble fehlerliste={fehlerliste} id="CHECK8_3_2" setzeAntworten={setzeAntworten} label1={"Ja"} label2={"Nein"} savedInput={antworten}></CheckBoxDouble>
                        </Typography>

                    </Grid>
                </div>
            </Grid>
            <br></br>

            {/* <Grid item xs={12} style={{}}>
                <div style={{}}>
                    <Grid container style={{ border: "solid", padding: "20px" }}>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "3%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            9.
                        </Typography>
                        <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "97%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                            Sonstige Hinweise des Arbeitgebers an die Agentur für Arbeit
                        </Typography>
                        <SimpleTextField id={"TEXT_9_1_1"} placeholder="Hinweise" label="" fehlerliste={fehlerliste} width={"100%"} display={"flex"} float={"left"} setzeAntworten={setzeAntworten} savedInput={antworten} >{""}</SimpleTextField>

                    </Grid>
                </div>
            </Grid>
            <br></br> */}



            <div style={{ padding: "20px" }}></div>

            <Kursabgeben
                anzahlFehler={anzFehler}
                antwortenSenden={antwortenSenden}
                testabgabeken={testabgabe}
                abgabedat={abgabedat}
                abgabezeit={abgabezeit}
                bemerkungen={bemerkungen}
                reactnummer={reactnummer}
                merkmal={merkmal}
            />

        </div>
    );
}

export default BEA_Component