import React, {useState, useCallback} from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {KursAufgabenErledigt} from '../Rahmendaten/KursAufgabenErledigt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function ScheckDez5_Präsentation(props) {
  const [antworten,setAntworten]=useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('820');
  const [anzFehler, setAnzFehler]=useState('0')
  const [kurskennung, setKurskennung]=useState("ScheckDez5Präsentation");
  const [merkmal]=useState('');


  const setRedirect = (path) => {
    navigate(path);
  }

      const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "aufgabgeerl",
          "reactnummer": reactnummer,
          "kurskennung": kurskennung,
          "merkmal": merkmal,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'aufgabeerl', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

  return(
    
  <div >
    <Briefkopf  
      ueberschrift1="Themen-Präsentation" 
      ueberschrift2="" 
    />

    <Grid container spacing={0} style={{marginTop:'30px' }} justifyContent={"center"}>

  </Grid>
  
  <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b/>A<b/>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant='h4'>
                    Erstelle bitte eine kleine Dokumentation in Power Point über die Themen
                </Typography>
             </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                DEÜV Meldung
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Lohnsteuerbescheinigung
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                Lohnsteueranmeldung
            </Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"50px" }} >
                <ArrowForwardIcon/>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText style={{marginLeft:"20px"}}>
            <Typography variant='h5'>
                ELStAM-Daten
            </Typography>
        </ListItemText>

      </ListItem>


      <ListItem style={{marginTop:"50px"}}>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
            </Avatar> 
        </ListItemAvatar>
        <ListItemText>
            <Typography variant='h5'>
                Verwende dafür bitte jeweils ein Vordruck und versuche die 3 W-Fragen in der Präsentation aufzuzeigen<br/>
            	- Wer benötigt das Formular oder Dokument<br/>
                - Warum/Wofür wird das Formular oder Dokument benötigt<br/>
	            - Wann wird das Formular oder Dokument benötigt<br/>
            </Typography>
        </ListItemText>
      </ListItem>

    </Grid>
  </Grid>

  <KursAufgabenErledigt  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

  </div>
  )
}


export default ScheckDez5_Präsentation;