import React, { useEffect, useState } from 'react';
import {Grid, TextField,Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function BruttoNeu (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,Lohnart, Intern,Bezeichnung, Tage, Stunden, Faktor,Kennung, Prozent, Steuerpflichtig, Sozialverspflichtig, Betrag} = props;

    if (Lohnart !== '') {
        antworten["loa"+tabellenKey]=Lohnart;
        var loablockiert=true;
    }

    if (Intern !== '') {
        antworten["intern"+tabellenKey]=Intern;
        var internblockiert=true;
    }

    if (Bezeichnung !== '') {
        antworten["bez"+tabellenKey]=Bezeichnung;
        var bezblockiert=true;
    }
    if (Tage !== '') {
      antworten["tag"+tabellenKey]=Tage;
      var tagblockiert=true;
    }

    if (Stunden !== '') {
      antworten["stunde"+tabellenKey]=Stunden;
      var stundeblockiert=true;
    }
    if (Faktor !== '') {
      antworten["fak"+tabellenKey]=Faktor;
      var fakblockiert=true;
    }
    if (Kennung !== '') {
      antworten["kenn"+tabellenKey]=Kennung;
      var kennblockiert=true;
    }
    if (Prozent !== '') {
      antworten["pro"+tabellenKey]=Prozent;
      var problockiert=true;
    }
  
    if (Steuerpflichtig !== '') {
          antworten["steuerpfl"+tabellenKey]=Steuerpflichtig;
          var steuerpflblockiert=true;
    }
    if (Sozialverspflichtig  !== '') {
        antworten["sozial"+tabellenKey]=Sozialverspflichtig;
        var sozialblockiert=true;
    }
    if (Betrag !== '') {
        antworten["betr"+tabellenKey]=Betrag;
        var betrblockiert=true;
    }


  

return(
    <Grid container spacing={0} style={{marginTop:'30px'}} >
        
        <Grid item xs={12}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Lohnart</TableCell>
                            <TableCell style={{textAlign:"center"}}>Intern</TableCell>
                            <TableCell style={{textAlign:"left"}}>Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"right"}}>Tage</TableCell>
                            <TableCell style={{textAlign:"right"}}>Stunden</TableCell>
                            <TableCell style={{textAlign:"right"}}>Faktor</TableCell>
                            <TableCell style={{textAlign:"center"}}>Kennung</TableCell>
                            <TableCell style={{textAlign:"center"}}>%</TableCell>
                            <TableCell style={{textAlign:"right"}}>Steuerpflichtig</TableCell>
                            <TableCell style={{textAlign:"right"}}>Sozialvers.pflichtig</TableCell>
                            <TableCell style={{textAlign:"right"}}>Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell key="Lohnart" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={loablockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["loa"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["loa"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["loa"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('loa'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Intern" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={internblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["intern"+tabellenKey]}     
                                    style={{display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["intern"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["intern"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('intern'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Bezeichnung" >
                                <Eingabe 
                                    disabled={bezblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["bez"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["bez"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["bez"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('bez'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Tage" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={tagblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tag"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["tag"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["tag"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tag'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Stunden" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={stundeblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["stunde"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["stunde"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["stunde"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('stunde'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Faktor" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={fakblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["fak"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["fak"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["fak"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('fak'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kennung" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={kennblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["kenn"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kenn"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kenn"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('kenn'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Prozent" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={problockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["pro"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["pro"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["pro"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('pro'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Steuerpflichtig" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={steuerpflblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["steuerpfl"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["steuerpfl"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["steuerpfl"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('steuerpfl'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Sozialverspflichtig" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={sozialblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["sozial"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["sozial"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["sozial"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('sozial'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Betrag" style={{}} style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={betrblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["betr"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["betr"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["betr"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('betr'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {BruttoNeu};