import { Typography, Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useNavigate } from "react-router-dom";

function NotFound(props) {
  const navigate = useNavigate();

  return(
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h4">Nicht gefunden</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">Scheint als wäre dieses Programm noch nicht entwickelt.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => navigate('/', {replace: true})}>Zum Verteiler</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NotFound;
