import React, {useState, useCallback} from 'react';
import {Avatar, Grid, ListItem, ListItemAvatar, ListItemText, TableContainer, TableBody, TableRow, TableCell, Table, Typography, Paper} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Chat_Tutorial_§23cSGV_IV.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import {Kursabgeben} from '../Rahmendaten/Kursabgeben';
import {BruttoNeu2} from '../Rahmendaten/BruttoNeu2';
import {BruttoNettoBlock} from './../Rahmendaten/BruttoNettoBlock'
import {Beitragssaetze} from './../Rahmendaten/Beitragssaetze'
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'


function SonstEinnahme(props) {
  const { accessToken, hsutkey } = props;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [testabgabe, setTestabgabe]=useState(false);
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('029');
  const [merkmal]=useState("§23cSGBIV");

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
//    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
//    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
//   console.log({antworten})

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
 //   console.log(response.data.message);

    if (response.status === 200) {
        setTestabgabe(true);
        fehlerAusLesen(response.data.fehler,response.data.anzfehler)
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
} else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  return(    
  <div>
    <Briefkopf  
        ueberschrift1="§23c SGB IV - Sonstige beitragspflichtige Einnahme" 
        ueberschrift2="Welche besondere Regelung beinhaltet § 23c SGB IV" 
    />

    <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'40px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Info
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Ansicht des Bruttoblocks einer Entgeltabrechnung in welcher der § 23c SGB IV angewendet wurde</strong><br/>
            In diesem Bruttoblock-Beispiel wird ein anteiliges Gehalt ausgewiesen, da der Arbeitnehmer einen Teilmonat in Krankengeldbezug ist.
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog Entgeltabrechnung Mai 2020
        </Typography>
        <BruttoNeu2
              anzReihen={4}
              tabellenKey="1" 
              Lohnart={['1029','1780','1781','1783']}
              Intern={['029','780','781','783']}
              Bezeichnung={["Gehalt","Krankengeldzs*","KK-Leistung*","Freibtr.gem23c*"]}
              Tage={["6,92","--","--","--"]}
              Stunden={["48,44","--","--","--"]}
              Faktor={["824,89","162,95","56,29","-162,95"]}
              Kennung={["LFD","LFD","--","--"]}
              Prozent={["0,00","0,00","0,00","0,00","0,00"]}
              Steuerpflichtig={["824,89","162,95","--","--"]}
              Sozialverspflichtig={["824,89","162,95","--","-162,95"]}
              Betrag={["824,89","162,95","--","--"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={1}>*</Grid>
      <Grid item xs={10} >
        <Typography align="left">
            Lohnart 1780 Krankengeldzs = der vom Arbeitgeber vergütete Zuschuss zum Krankengeld (Krankengeldzuschuss) <br/>
            Lohnart 1781 KK-Leistung = Leistung der Krankenkasse (Kalendertägliches Nettokrankengeld) <br/>
            Lohnart 1783 Freibtr.gem23c = der ermittelte Freibetrag gem. § 23c SGB IV <br/>
            Die Anzeige in 3 Lohnarten dient der übersichtlichen Transparenz und der besseren Nachvollziehbarkeit.
        </Typography>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Info
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Vorgehensweise bei der Ermittlung des SV-Freibetrags bei Zahlung eines Krankengeldzuschusses</strong><br/>
            <TableContainer component={Paper} style={{marginTop:"25px", backgroundColor:"#ecece7"}}>
                <Table aria-label="customized table">
                    <TableBody>
                        <TableRow >
                            <TableCell align="center" >
                                1.
                            </TableCell>
                            <TableCell align="left">
                              Ermittlung des Vergleichsnettos für den Vormonat zum Beginn der Lohnfortzahlung.<br/>
                              Beispiel: Der Arbeitnehmer erkrankte ab 10. Februar. Nach 42 Kalendertage Lohnfortzahlung bekommt der Arbeitnehmer im März Krankengeld von der Krankenkasse.<br/>
                              Da die Krankheit im Februar begann ist der Vormonat Januar für die Vergleichsnettoberechnung zu nehmen.
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell align="center" >
                                2.
                            </TableCell>
                            <TableCell align="left">
                                Danach ist die Summe der Krankenkassenleistung (Krankengeld) zu berechnen.<br/>
                                Die Berechnung erfolgt immer für den ganzen Monat. <br/>
                                Die Formel für die Berechnung der Krankenkassenleistung lautet:<br/>
                                <b>Nettokrankentagegeld x 30 SV-Tage</b>
                                <strong>Das bedeutet, ohne Mitteilung des Krankengelds der Krankenkasse kann keine Freibetragsermittlung erfolgen!!!</strong><br/><br/>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
          </ListItemText> 
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Info
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
              <strong>Beitragssätze 2020</strong><br/>

          </ListItemText> 
        </ListItem>
        <Beitragssaetze 
              tabellenKey="1" 
              kvbeitragssatz="14,60 %"
              rvbeitragssatz="18,60 %"
              avbeitragssatz="2,40 %"
              pvbeitragssatz="3,05 %"
              pvelternsatz="0,25 %"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />

      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Bsp
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Informationen des betreffenden Arbeitnehmers</strong><br/>
            Bundesland der Beschäftigung: Baden-Württemberg
            Geburtsjahr: 1980 <br/> 
            Steuerklasse: 1<br/> 
            Anzahl Kinder: Keine<br/>
            Konfession: keine<br/>
            Krankenkasse: BKK mhplus (Zusatzbeitrag = 0,98 %)<br/> <br/>
            Arbeitnehmer ist ab 09.06.2020 durchgehend aufgrund der selben Krankheit krank.
            Ab 21.07.2020 bekommt der Arbeitnehmer Krankengeld von der Krankenkasse und keine Lohnfortzahlung durch den Arbeitgeber.<br/>
            Der Arbeitgeber zahlt aber einen Zuschuss zum Krankengeld in Höhe des höchstmölichen Freibetrages.
          </ListItemText> 
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              1.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Ermittlung des Vergleichsnettos</strong><br/>
          </ListItemText> 
        </ListItem>
      </Grid>

      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog Entgeltabrechnung Mai 2020
        </Typography>
        <BruttoNeu2
              anzReihen={5}
              tabellenKey="1" 
              Lohnart={['1029','1311','1312','1760','1076']}
              Intern={['029','311','311','760','076']}
              Bezeichnung={["Gehalt","Frw. Zulage","Tarifl.Zulage","bAV","Urlaubsgeld"]}
              Tage={["21,75","--","--","--","--"]}
              Stunden={["174,00","--","--","--","--"]}
              Faktor={["2300,00","300,00","170,00","-200,00","1500,00"]}
              Kennung={["LFD","LFD","LFD","LFD","EGA"]}
              Prozent={["0,00","0,00","0,00","0,00","0,00"]}
              Steuerpflichtig={["2300,00","300,00","170,00","-200,00","1500,00"]}
              Sozialverspflichtig={["2300,00","300,00","170,00","-200,00","1500,00"]}
              Betrag={["2300,00","300,00","170,00","--","1500,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Ermittle das Vergleichsnetto für die spätere Freibetragsermittlung. <br/>
          Für die Ermittlung der Lohnsteuer verwende bitte die Homepage "https://www.bmf-steuerrechner.de/index.xhtml" des Bundesministerium für Finanzen. <br/>
        </Typography>
        <BruttoNettoBlock 
              svFarbe=""
              tabellenKey="1" 
              bruttolfd='' 
              bruttoega='' 
              bruttogesamt='' 
              steuern=""
              sozvers=""
              agantAusz="0,00" 
              netto="" 
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              2.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Die Krankenkasse übermittelt und bescheinigt dem Arbeitgeber dadurch folgende Informationen:</strong><br/>
              Kalendertägliches Bruttokrankengeld = 59,87 Euro <br/>
              Kalendertägliches Nettokrankengeld = 52,08 Euro<br/>
          </ListItemText> 
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              3.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Berechne nun den Freibetrag und somit den höchstmöglichen sv-freien Zuschuss zum Krankengeld</strong><br/>
            Verwende für die Berechnung immer einen ganzen Monat
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={7} >
        <Tabelle2spaltig 
          tabellenKey="1" 
          anzReihen="3"
          ueberschrift="Ermittlung Monatsfreibetrag"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Betrag"
          spalte2Platz="right"
          spalten1={["ermitteltes Vergleichsnetto (Monat Mai)","abzgl. ermittelte KK-Leistung (Nettokrankengeld x 30","=Monatsfreibetrag"]}
          spalten2={["","",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              3.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Wenn das Krankengeld nicht für den ganzen Monat, sondern nur für einen Teilmonat bezahlt wurde, ist der Freibetrag für den Teilmonat entsprechend zu kürzen</strong><br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1} ></Grid>
      <Grid item xs={7} >
        <Tabelle2spaltig 
          tabellenKey="2" 
          anzReihen="4"
          ueberschrift="Freibetragsermittlung nach Teilmonat"
          uebschr_spalte1="Bezeichnung"
          spalte1Platz="center"
          uebschr_spalte2="Tage"
          spalte2Platz="right"
          spalten1={["Monatsfreibetrag","multipliziert mit SV-Tage des Krankengeldbezuges"," geteilt durch SV-Tage des Monats","tatsächlicher (anteiliger) Freibetrag"]}
          spalten2={[antworten["spalt212"],"","",""]}
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
        />
      </Grid>
    </Grid>


    <Grid container spacing={1} style={{marginTop:'25px' }} justifyContent={"left"} alignItems={"left"} >
      <Grid item xs={12} >
        <ListItem style={{marginTop:"40px"}}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              4.0
            </Avatar> 
          </ListItemAvatar>
          <ListItemText variant="h6">
            <strong>Vervollständige die Lohnarten des dargestellten Bruttoblocks der Entgeltabrechnung für Juli 2020</strong><br/>
          </ListItemText> 
        </ListItem>
      </Grid>
      <Grid item xs={1}> </Grid>
      <Grid item xs={11} >
        <Typography align="left">
          Bruttoblog Entgeltabrechnung Juli 2020<br/>
        </Typography>
        <BruttoNeu2
              anzReihen={8}
              tabellenKey="2" 
              Lohnart={['1029','1311','1312','1780','1760','1410','1781','1783']}
              Intern={['029','311','311','780','760','410','0781','0783']}
              Bezeichnung={["Gehalt","Frw. Zulage","Tarifl.Zulage","Krankengeld Zuschuss","bAV","LFZ-Tage",'KK-Leistung',"Freibetr.gem23c"]}
              Tage={["13,24","--","--","--","--","14,00",'--','--']}
              Stunden={["105,91","--","--","--","--","--","--",'--']}
              Faktor={["1400,00","182,61","103,48","","-200,00","--","",""]}
              Kennung={["LFD","LFD","LFD","LFD","--","--","--"]}
              Prozent={["0,00","0,00","0,00","0,00","--","--","--","--"]}
              Steuerpflichtig={["1400,00","182,61","103,48","","-200,00","--","--",""]}
              Sozialverspflichtig={["1400,00","182,61","103,48","","-200,00","--","--",""]}
              Betrag={["1400,00","182,61","103,48","","--","--","--",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
        /> 
      </Grid>
    </Grid>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>      
  </div>

    )



  }

export default SonstEinnahme;