import React, {useState, useCallback} from 'react';
import {Avatar,Typography,Button,MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import { Deuev } from '../Rahmendaten/Deuev';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function ScheckDez4(props) {
  const [antworten,setAntworten]=useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('815');
  const [merkmal]=useState('ScheckDez4');


  const setRedirect = (path) => {
    navigate(path);
  }
  const fehlerAusLesen=(fehler)=>{
    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    console.log("antworten: ",antworten)
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
         } else {
         if (response) {
           props.showError(response.data.message);
         } else {
           props.showError('Keine Internetverbindung');
         }
       }
     }


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  

  return(
    
  <div >
    <Briefkopf  
      ueberschrift1="Die DEÜV Jahresmeldung" 
      ueberschrift2="Die DEÜV Anmeldung hast Du bereits in einem anderen Kurs kennengelernt.^Nun lernst Du die DEÜV-Jahresmeldung kennen, indem Du vier Jahresmeldungen von Hand erstellst." 
    />

    <Grid container spacing={0} style={{marginTop:'30px' }} justifyContent={"center"}>

  </Grid>
  
  <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b/>A1.1<b/>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant='h5'>
                    Beginne mit der DEUEV-Meldung von Bruno Biermann
                </Typography>
              </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="1"
        Grund=""
        Name="Biermann"
        Vorname="Bruno"
        Strasse="Neusser Str."
        HausNr="342"
        PLZ="50733"
        Ort="Köln"
        VersNr=""
        GebDat=""
        VonDat=""
        BisDat=""
        PG=""
        Mb=""
        BS=""
        Währung="Euro"
        Betrag=""
        kkname="DAK-Gesundheit"

        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>
    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'150px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1.2<b/>
          </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Erstelle nun die DEUEV-Meldung von Helmut Nickel
            </Typography>
          </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="2"
        Grund=""
        Name="Nickel"
        Vorname="Helmut"
        Strasse="Welzheimer Str."
        HausNr="10"
        PLZ="71554"
        Ort="Weissach im Tal"
        VersNr=""
        GebDat="1985-09-16"
        VonDat=""
        BisDat=""
        PG=""
        Mb=""
        BS=""
        Währung="Euro"
        Betrag=""
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>
    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1.3<b/>
          </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Bitte jetzt noch die DEUEV-Meldung von Gustav Gans erstellen
            </Typography>
          </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="3"
        Grund=""
        Name="Gans"
        Vorname="Gustav"
        Strasse="Buschkante"
        HausNr="60"
        PLZ="45472"
        Ort="Mülheim an der Ruhr"
        VersNr=""
        GebDat="2002-09-16"
        VonDat=""
        BisDat=""
        PG=""
        Mb=""
        BS=""
        Währung="Euro"
        Betrag=""
        kkname="IKK-Classic"
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>
    </Grid>
  </Grid> 

  <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1.4<b/>
          </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Bitte jetzt noch die DEUEV-Meldung von Alex Grün erstellen
            </Typography>
          </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="4"
        Grund=""
        Name="Grün"
        Vorname="Alex"
        Strasse="Korber Str."
        HausNr="3"
        PLZ="71334"
        Ort="Waiblingen"
        VersNr=""
        GebDat="1960-10-17"
        VonDat=""
        BisDat=""
        PG=""
        Mb=""
        BS=""
        Währung="Euro"
        Betrag=""
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>
    </Grid>
  </Grid> 

  <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

  </div>
  )
}


export default ScheckDez4;