import React, {useState, useCallback} from 'react';
import {Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {TabelleWissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelleWissenstestE1';
import {Tabelle2Wissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelle2WissentestE1';
import {Tabelle3Wissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelle3WissentestE1';



function Wissenstest_Entgeltabr_T2(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0');
  const [reactnummer]=useState('023');
  const [merkmal]=useState("WTestEntgAbrT2");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Typography variant="h4" color="primary">Wissenstest zur Entgeltabrechnung Teil 2</Typography> 
      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
            <Typography variant="h6">
                  Teste dein Wissen über die Entgeltabrechnung
                 
            </Typography>

        </Grid>
      </Grid>

      
      <Grid container spacing={2} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>

        <Tabelle2Wissen tabellenkey='1' frage='Welche Infromationen über einen Mitarbeiter können wir anhand der Rentenversicherungsnummer erkennen?' antwort1='Anfangsbuchstabe des Geburtsnamen' antwort2='Geburtsdatum' antwort3='Geschlecht' antwort4='Familienstand' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        <Tabelle2Wissen tabellenkey='2' frage='Wie ist bei der Beitragsgruppe die Reihenfolge der Sozialversicherungszweige?' antwort1='Arbeitslosenversicherung, Krankenversicherung, Pflegeversicherung, Rentenversicherung' antwort2='Krankenversicherung, Pflegeversicherung, Rentenversicherung,Arbeitslosenversicherung' antwort3='Krankenversicherung, Rentenversicherung, Arbeitslosenversicherung, Pflegeversicherung ' antwort4='Rentenversicherung, Arbeitslosenversicherung, Krankenversicherung, Pflegeversicherung' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        <Tabelle2Wissen tabellenkey='3' frage='Wie lautet die Personengruppe für einen normal SV-Pflichtigen Beschäftigten?' antwort1='100' antwort2='101' antwort3='102' antwort4='103' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        <Tabelle2Wissen tabellenkey='4' frage='Wie lautet der Versicherungsstatus eines Mitarbeiters mit der Beitragsgruppe 9111?' antwort1='Freiwillig Versicherter Selbstzahler' antwort2='Gesetzlich Versicherter' antwort3='Freiwillig Versicherter Firmenzahler' antwort4='Sozialversicherungsfreier' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <TabelleWissen tabellenkey='5' frage='Wenn Du eine jährliche Terminaufgabe im Januar erstellen möchtest, welchen Intervall gibst Du vor?' antwort1='01' antwort2='12' antwort3='11'  setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

        <Tabelle3Wissen tabellenkey='6' frage='Gibt es Begrenzungen bei der Beitragspflicht?' antwort1='Nein, Beiträge sind immer aus voller Höhe des Entgelts zu berechnen' antwort2='Ja, Begrenzung auf die Jahresarbeitsentgeltgrenze' antwort3='Ja, Begrenzung auf die Beitragsbemessungsgrenze' antwort4='Ja, aber nur für Auszubildende' antwort5='Ja, aber nur für Mitarbeiter mit Elterneigenschaft' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
        
        <Tabelle2Wissen tabellenkey='7' frage='Ist die Beitragsverteilung von Arbeitnehmer und Arbeitgeber im Jahr 2019 identisch?' antwort1='Ja, jeweils 50%' antwort2='Nein, Arbeitnehmer trägt 60%, Arbeitgeber 40%' antwort3='Ja, außer in der Rentenversicherung wegen des Zusatzbeitrags' antwort4='Ja, außer in der Pflegeversicherung bei kinderlosen Mitarbeitern über 23 Jahren' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
          
        <Tabelle2Wissen tabellenkey='8' 
            frage='Gibt es in der Beitragsabrechnung Unterschiede zwischen laufendem und einmalig gezahlten Entgelt' 
            antwort1='Nein, Beitragsberechnung erfolgt immer über die volle Höhe des Entgelts' 
            antwort2='Ja, bei laufendem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei einmalig gezahltem Entgelt die jährliche Beitragsbemessungsgrenze' 
            antwort3='Ja, bei laufendem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei einmalig gezahltem Entgelt die anteilige jährliche Beitragsbemessungsgrenze' 
            antwort4='Ja, bei einmalig gezahltem Entgelt ist die monatlicher Beitragsbemessungsgrenze zu berücksichtigen bei laufende gezahltem Entgelt die jährliche Beitragsbemessungsgrenze' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />
        
        <TabelleWissen tabellenkey='9' 
            frage='Wo findet man im Programm Scheck mandantenspezifische Besonderheiten, die bei der Bearbeitung zu berücksichtigen sind?' 
            antwort1='In den Terminaufgaben' 
            antwort2='Im Lohnprotokoll' 
            antwort3='In den Verarbeitungsläufen'  
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />        
        
        <Tabelle2Wissen 
            tabellenkey='10' 
            frage='Wann wird eine DEÜV Meldung erstellt?' 
            antwort1='Bei Eintritt des Mitarbeiters' 
            antwort2='Bei Austritt eines Mitarbeiters' 
            antwort3='Bei Wechsel der Steuerklasse' 
            antwort4='Bei Änderung der Bankverbindung' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />
          
        <Tabelle2Wissen 
            tabellenkey='11' 
            frage='Welche Besonderheit gibt es bei der Steuerberechnung und wie wird dieses Prinzip genannt?' 
            antwort1='Das Quartalsprinzip d.h. die Steuer wird generell quartalsweise berechnet' 
            antwort2='Das Fiktionsprinzip d.h. die Steuer wird auch dann berechnet, wenn der Arbeitnehmer nur ein Recht auf eine Vergütung hat, diese Zahlung aber noch nicht bekommen hat' 
            antwort3='Das Zuflussprinzip d.h. die Steuer wird immer dann berechnet, wenn der Arbeitnehmer die Vergütung tatsächlich bekommt' 
            antwort4='Das "Entstehungsprinzip"- d.h. die Steuer wird immer dann berechnet, wenn der Anspruch auf die Zahlung entsteht' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />
          
        <Tabelle2Wissen 
            tabellenkey='12' 
            frage='Welche Informationen sind im Tätigkeitsschlüssel zu finden?' 
            antwort1='Befristung' 
            antwort2='Schulabschluss' 
            antwort3='Berufsbezeichnung' 
            antwort4='Elterneigenschaft' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />
          
        <Tabelle2Wissen 
            tabellenkey='13' 
            frage='Mit welcher Berechnungsformel werden die Monatsstunden in der Regel berechnet?' 
            antwort1='Stunden pro Tag * 5' 
            antwort2='Stunden pro Woche * 4,35' 
            antwort3='Tage pro Woche * Stunden/Tag' 
            antwort4='21,75* Stunden pro Tag' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />
          
        <TabelleWissen 
            tabellenkey='14' 
            frage='Woran kann auf der DEÜV Meldung erkannt werden, ob es sich um einen normalen SV-pflichtigen oder einen AUszubildenen handelt?' 
            antwort1='Tätigkeitsschlüssel' 
            antwort2='Personengruppe' 
            antwort3='Beitragsgruppe ' 
            setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
        />        
        
    </Grid>



      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
        </Grid>


      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} bezeichnung="Erklärung angeschaut und verstanden" antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
}


export default Wissenstest_Entgeltabr_T2;