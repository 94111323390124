import React, {useState, useCallback} from 'react';
import {Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/SVModelle.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {PersonengruppenAuswahl} from '../../components/Rahmendaten/PersonengruppenAuswahl';
import {BeitragsgruppenAuswahl} from '../../components/Rahmendaten/BeitragsgruppenAuswahl';

function SVModelle(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('035');
  const [merkmal]=useState("SVModelle");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="Die verschiedenen Personengruppen (Beschäftigungsmodelle) in der Sozialversicherung" 
            ueberschrift2="Nach dem Kurs kannst Du Fragen wie zum Beispiel^Was ist ein Minijob?^Was bedeutet kurzfristige Beschäftigung?^Wann ist ein Werkstudent ein Werkstudent?^problemlos beantworten." 
        />

        <Grid container spacing={2} justifyContent="center">

          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h6">
                  In der Sozialversicherung gibt es unterschiedliche Personengruppen.<br/>
                  Jeder Personengruppe liegt ein gesondertes Beschäftigungsmodell zugrunde.<br/>
                  Diese haben wiederum unterschiedliche Kriterien, die zu beachten bzw. zu erfüllen sind.<br/> 
                  Das Video zeigt Dir die am häufigsten vorkommenden Personengruppen (Modelle) mit den dazugehörigen und zu beachtenden Kriterien.
                  </Typography>
              </ListItemText> 
            </ListItem>
            <Player
                  poster={POSTER}
                  src={VIDEO}
                  >
                  <ControlBar autoHide={false} />
            </Player>

            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  2
                </Avatar> 
              </ListItemAvatar>

              <ListItemText>
                <Typography variant="h6">
                  Hast Du das Video verinnerlicht?<br/>
                  Wenn ja, sehr gut.<br/>
                  Wenn nicht, dann schaue es Dir gern mehrmals an. <br/>
                 </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} >
          <Grid item xs={12} >
            <Typography align="left" variant="h6">
              Lade dir aus dem Internet die Tabellen der Personen- und Beitragsgruppen herunter.<br/>
              Diese benötigst du für die Lösung der u. a. Aufgaben. <br/><br/>
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography align="left" variant="h6">
              Wenn bei den Aufgaben keinerlei Angaben zu etwaigen Befreiungen hervorgehen, dann betrachte dies, als liegen die Befreiungen zum Zeitpunkt der Beurteilung nicht vor 
              und berücksichtige die Befreiungsmöglichkeiten nicht.<br/>
              Verwende bitte für die Lösungen die ab 2023 gültige Verdienst-Minijobgrenze.  
            </Typography>
          </Grid>

        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 1</b><br/>
                  Eine Frau ist als Verkäuferin hauptberuflich tätig. <br/>
                  Ab 01. August nimmt sie bei einer anderen Firma eine weitere Beschäftigung auf, bei der eine Wochenarbeitszeit von 8 Stunden und eine Vergütung von 13,- Euro pro Stunde vereinbart wurde.<br/>
                  Beurteile die neu aufgenommene Beschäftigung und bestimme die korrekte Personen- und Beitragsgruppe.<br/>
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="1"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="1"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1.1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 1.1</b><br/>
                  Die selbe Verkäuferin fängt ab 01.10. zu ihrer hauptberuflichen Tätigkeit und ihrem bereits aufgenommenen Job einen weiteren Job an.<br/>
                  Der weitere Job findet ausschließlich Samstags von 8 Uhr bis 12 Uhr zu einem Stndenlohn von 12,50 Euro statt. <br/>
                  Beurteile die weitere Beschäftigung und bestimme die korrekte Personen- und Beitragsgruppe.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="13"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="13"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 2</b><br/>
                  Eine 16 jährige Schülerin arbeitet Samstags regelmäßig 5 Stunden in einer Getränkehandlung. <br/>
                  Dafür bekommt sie pro Samstag 50 Euro vergütet. <br/>
                  Die Dauer der Beschäftigung ist unbefristet. <br/>
                  Die Schülerin hat sich von der Rentenversicherung per unterschriebenen Antrag befreien lassen. <br/>
                  Beurteile die Beschäftigung und bestimme die korrekte Personen- und Beitragsgruppe.<br/>
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="2"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="2"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 3</b><br/>
                  Nach Abschluß seiner mittleren Reife weiß Hans noch nicht so Recht was er beruflich machen möchte. Deshalb fängt er für einen Zeitraum von
                  3 Monaten einen Aushilfsjob in einer Produktionsfirma an.<br/>
                  Dafür bekommt er 2000,- Euro pro Monat.<br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung, so dass Hans so wenig Beiträge wie möglich zu zahlen hat.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="3"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="3"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>



        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A4
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 4</b><br/>
                  Nun ist sich Hans sicher, dass er Dachdecker werden möchte. <br/>
                  Deshalb fängt er im Anschluß seines 3 monatigen Aushilfsjobs eine Ausbildung als Dachdecker in einer anderen Firma an. <br/>
                  Als Ausbildungsvergütung bekommt er im ersten Ausbildungsjahr monatlich 970,- Euro.<br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="4"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="4"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A5
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 5</b><br/>
                  Nach erfolgreichem Abschluß der Ausbildung wird Hans als Facharbeiter in seiner Firma übernommen. <br/>
                  Sein Gesellenlohn ist 2300,- Euro pro Monat. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe seiner Beschäftigung als Facharbeiter.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="5"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="5"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A6
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 6</b><br/>
                  Ein ordentlich studierender Vollzeitstudent mit gültiger Immatrikulationsbescheinigung arbeitet neben seinem Studium immer Samstags 8 Stunden als Barkeeper in einer Disco. <br/>
                  Dafür bekommt er monatlich 700,- Euro vergütet.<br/>
                  Die Beschäftigung ist auf 6 Monate befristet. <br/>
                  Der Student übt keine weiteren Beschäftigungen aus und hat auch in diesem Jahr keine weiteren Beschäftigungen ausgeübt. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung, so dass der Student so wenig Beiträge wie möglich zu zahlen hat.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="6"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="6"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A7
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 7</b><br/>
                  Dieter ist seit kurzem arbeitslos und bei der Agentur für Arbeit als arbeitssuchend gemeldetet. Er nimmt ab 01.09.2021 einen unbefristeten Aushilfsjob (Job1) mit einem Verdienst von 250,- Euro pro Monat an. <br/>
                  Dieter hat sich von der Rentenversicherung per unterschriebenen Antrag befreit. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="7"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="7"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A8
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 8</b><br/>
                  Ab 01.10.2021 nimmt Dieter einen zweiten unbefristeten Aushilfsjob (Job2) bei einer anderen Firma an. In diesem zweiten Job verdient er monatlich 280,- Euro.<br/>
                  Auch in diesem Job hat sich Dieter von der Rentenversicherungspflicht befreit.<br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser zweiten Beschäftigung.<br/>
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="8"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="8"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A9
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 9</b><br/>
                  Wie wirkt sich die Aufnahme der Zweitbeschäftigung auf die Personen- und Beitragsgruppe der Erstbeschäftigung aus?<br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe der ersten Beschäftigung nach Aufnahme der zweiten Beschäftigung. <br/>
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="9"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="9"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A10
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 10</b><br/>
                  Ein ordentlich studierender Vollzeitstudent mit gültiger Immatrikulationsbescheinigung arbeitet an drei Nachmittagen in der Woche jeweils 3 Stunden in einem Office, um
                  sich ein Zubrot zu verdienen.<br/>
                  Dafür bekommt er monatlich 670,- Euro brutto.<br/>
                  Die Beschäftigung ist unbefristet. <br/>
                  Der Student übt keine weiteren Beschäftigungen aus. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung, so dass der Student so wenig Beiträge wie möglich zu zahlen hat.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="10"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="10"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A11
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 11</b><br/>
                  Ein anderer Vollzeitstudent mit gültiger Immatrikulationsbescheinigung arbeitet an zwei Nachmittagen in der Woche jeweils 3 Stunden in einem Office, um
                  sich ein Zubrot zu verdienen.<br/>
                  Dafür bekommt er monatlich 445,- Euro brutto.<br/>
                  Die Beschäftigung ist unbefristet. <br/>
                  Der Student übt keine weiteren Beschäftigungen aus. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung, so dass der Student so wenig Beiträge wie möglich zu zahlen hat.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="11"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="11"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A12
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 12</b><br/>
                  Ein anderer Vollzeitstudent mit gültiger Immatrikulationsbescheinigung arbeitet an fünf Nachmittagen in der Woche jeweils 4,5 Stunden in einem Office, um
                  sich ein Zubrot zu verdienen.<br/>
                  Dafür bekommt er monatlich 1660,- Euro brutto.<br/>
                  Die Beschäftigung ist unbefristet. <br/>
                  Der Student übt keine weiteren Beschäftigungen aus. <br/>
                  Bestimme die korrekte Personen- und Beitragsgruppe dieser Beschäftigung, so dass der Student so wenig Beiträge wie möglich zu zahlen hat.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="12"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="12"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A13
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 13</b><br/>
                  Norbert ist Hausmann und hat seit längeren einen Minijob als Verkaufshilfe und erhält dafür monatlich 200,- Euro.<br/>
                  Seit 01.10.2022 hat er einen zweiten Minijob angenommen, bei dem er monatlich 280,- Euro erhält.<br/>
                  Bitte bestimme die korrekte Personen- und Beitragsgruppe dieser zweiten Beschäftigung.<br/>
                  Bei beiden Beschäftigungen hat Norbert sich von der Rentenversicherung per unterschriebenen Antrag befreien lassen. <br/>
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="14"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="14"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

      </div>
    )
  }
  

export default SVModelle;
