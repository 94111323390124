import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Avatar, Box, Button, Card, CardContent, CardMedia, Container, Typography, FormGroup, FormControlLabel, Checkbox, MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';


function ScheckAbschlussTest(props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, } = props;
    const [merkmal]=useState('ScheckAbschlussTest');
    const [kurskennung, setKurskennung]=useState("ScheckAbschlussTest");
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [fragenzaehler, setFragenzaehler]=useState(0)
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);
    const [reactnummer]=useState('837');
    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);
        }
      }
    }

    const setzeAntworten = ( key,value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        forceUpdate();   
      }
    
      const fehlerAusLesen=(fehler)=>{
        let array=fehler.split(";");
        let fehlerzwis=[]
        array.map(
          // eslint-disable-next-line
          (objekt)=> {
            fehlerzwis[objekt]=true;    
          }
        );
        setFehlerliste(fehlerzwis);
      };
    
    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "kursabgabe",
          "merkmal": merkmal,
          "daten": antworten,
          "reactnummer": reactnummer,
          "frage":fragenzaehler,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
          fehlerAusLesen(response.data.fehler);

        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

      const DokumentOeffnen = (docnr, docbez, docendung) => {
        console.log("DokumentOeffnen - docnr: ",docnr)
        const payload={
          "kennung": "kursdaten",
          "kurskennung": "download_lohndaten",
          "reactnummer": reactnummer,
          "docnr":docnr,
          "docname": docbez,
          "hsutkey": hsutkey
        }
        const headers={
          "Authorization": "Bearer "+accessToken,
          "Accept": "application/pdf"
        }
        axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
          .then((response) => {
            if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
            var contentFile = response.data;
            const mimeType = response.headers['content-type'] || 'application/pdf'
            openFile(fileName, contentFile,mimeType);
          })
          .catch(function (error) {
            showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
          })
      }
  

return(
  <div>
 <Briefkopf  
    ueberschrift1="Entgeltabrechnung Abschlusstest"
    ueberschrift2="Alles verstanden? Dann beantwortest Du die Fragen im handumdrehen." 
 />

<Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

  {
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={12}>
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <img
                  src={BuchDateneingabe}
                  style={{width:"250px", height:"150px"}}
                  alt='Handbücher'>
                </img>

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Download Stammblatt mit Mausklick
                </Button>
                </div>
              </Card>
            </Grid>
          )
        })
    }

</Grid>

 <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
   <ListItem>
     <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
            Welche Personengruppe hat ein geringfügig Beschäftigter?
            </Typography>
          </ListItemText>
        </ListItem>

      <VariableCheckBox
        tabellenKey="1" 
        ueberschrift=""
        anzReihen={3}
        Checktexte={["106",
                     "109",
                     "110"]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    </Grid>


    <Grid style={{marginTop:'40px' }} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
                In welcher Höhe wird der GWV für die Privatfahrten eines Firmenwagen angesetzt?
            </Typography>
          </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="2" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["0,03% vom BLP",
                     "1% vom Kaufpreis",
                     "0,03% vom Kaufpreis",
                     "1% vom BLP"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F3
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Welche Grenze wird für die jährliche KV Prüfung verwendet?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="3" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Beitragsbemessungsgrenze RV-West",
                     "Jahresarbeitsentgeltgrenze",
                     "Bezugsgröße",
                     "Geringfügigkeitsgröße"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>


    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F4
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Wie werden die pauschal versteuerten KM zwischen Wohnung und Arbeit ermittelt?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="4" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Km x 15 x 0,30 Euro",
                     "Km x BLP x 0,03%",
                     "Km x 0,30 Euro",
                     "Km x BLP x 0,002%"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F5
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
          Bitte lege anhand des Stammblattes 600 Wilma Waage die Personengruppe, Beitragsgruppe und Beschäftigungsart fest."
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="5" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 0110 \r\n Versicherungsstatus: privat versichert \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 0111 \r\n Versicherungsstatus: freiwillig versicherter Selbstzahler \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personenguppe: 101 \r\n Beitragsgruppe: 9111 \r\n Versicherungsstatus: freiwillig versicherter Firmentzahler \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F6
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Bitte lege anhand des Stammblattes 700 Walter Widder die Personengruppe, Beitragsgruppe und Beschäftigungsart fest.
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="6"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 106 \r\n Beitragsgruppe: 0100 \r\n Versicherungsstatus: RV Pflichtig \r\n Beschäftigungsart: Werkstudent",
                     "Personengruppe: 109 \r\n Beitragsgruppe: 6100 \r\n Versicherungsstatus: geringfügig ohne RV Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung",
                     "Personengruppe: 110 \r\n Beitragsgruppe: 0000 \r\n Versicherungsstatus: sv-frei \r\n Beschäftigungsart: Kurzfristige Beschäftigung",]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F7
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Bitte lege anhand des Stammblattes 800 Stefan Stier die Personengruppe, Beitragsgruppe und Beschäftigungsart fest
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="7"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 0110 \r\n Versicherungsstatus: privat versichert \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 0111 \r\n Versicherungsstatus: freiwillig versicherter Selbstzahler \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personenguppe: 101 \r\n Beitragsgruppe: 9111 \r\n Versicherungsstatus: freiwillig versicherter Firmentzahler \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F8
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wer muß einen Zuschlag zur Pflegeversicherung bezahlen?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="8"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Alle Mitarbeiterinnen und Mitarbeiter",
                     "Alle kinderlosen Arbeitnehmer",
                     "Alle kinderlosen Arbeitnehmer über 23 Jahre",
                     "Alle Arbeitnehmer mit Kindern",
                     "Alle kinderlose Arbeitnehmer über 25 Jahre",]}
        checked={[false,false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F9
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Welche Informationen gibt mir der Beitragsgruppenschlüssel?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="9"
        ueberschrift=""
        anzReihen={3}
        Checktexte={["Welcher Zweig der Sozialversicherung pflichtig ist",
                     "Bei welcher Krankenkasse der Arbeitnehmer versichert ist",
                     "Wie der Arbeitnehmer in der Sozialversicherung versichert ist"]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F10
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Welche Besonderheit gibt es bei der Steuerberechnung und wie wird dieses Prinzip genannt?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="10"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Das „Quartalsprinzip“ – d. h. die Steuer wird generell quartalsweise berechnet",
                     "Das „Fiktionsprinzip“ – d. h. die Steuer wird auch dann berechnet, wenn der Arbeitnehmer nur ein Recht auf eine Vergütung hat, diese Zahlung aber noch nicht bekommen hat. ",
                     "Das „Zuflussprinzip“ – d. h. die Steuer wird immer dann berechnet, wenn der Arbeitnehmer die Vergütung tatsächlich bekommt (zufließt)",
                     "Das „Entstehungsprinzip“ - d.h. die Steuer wird immer dann berechnet, wenn der Anspruch auf die Zahlung entsteht"]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F11
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Wie lautet der Versicherungsstatus eines Mitarbeiters mit der Beitragsgruppe 9111?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="11"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Freiwillig Versicherter Selbstzahler",
                     "Gesetzlich Versicherter ",
                     "Freiwillig Versicherter Firmenzahler",
                     "Sozialversicherungsfreie Person"]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F12
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Gibt es Begrenzungen bei der Beitragspflicht?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="12"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Nein, Beiträge sind immer aus voller Höhe des Entgelts zu berechnen",
                     "Ja, Begrenzung auf die Jahresarbeitsentgeltgrenze",
                     "Ja, Begrenzung auf die Beitragsbemessungsgrenze",
                     "Ja, aber nur für Auszubildenden",
                     "Ja, aber nur für Mitarbeiter mit Elterneigenschaft"]}
        checked={[false,false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F13
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Gibt es in der Beitragsberechnung Unterschiede zwischen laufendem und einmalig gezahltem Entgelt?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="13"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Nein, Beitragsberechnung erfolgt immer über die volle Höhe des Entgelts",
                     "Ja, bei laufendem Entgelt ist die monatliche Beitragsbemessungsgrenze zu berücksichtigen   bei einmalig gezahltem Entgelt die jährliche Beitragsbemessungsgrenze",
                     "Ja, bei laufendem Entgelt ist die monatliche Beitragsbemessungsgrenze zu berücksichtigen bei einmalig gezahltem Entgelt die anteilige jährliche Beitragsbemessungsgrenze.",
                     "Ja, bei einmalig gezahltem Entgelt die sie monatliche Beitragsbemessungsgrenze zu berücksichtigen bei laufend gezahltem Entgelt die jährliche Beitragsbemessungsgrenze ",
                    ]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F14
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Ein Mitarbeiter bekommt einen Firmenwagen zum 22.05.2023. <br/>
            Wie wird der GWV abgerechnet?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="14"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["1% vom BLP",
                     "1% vom Kaufpreis",
                     "1% vom BLP anteilig für die Arbeitstage ab 22.05.",
                     "1% vom Kaufpreis anteilig für die Arbeitstage ab 22.05.",
                    ]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F15
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Wozu dienen die ELStAM Daten?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="15"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Um die Versicherungsnummer abzufragen",
                     "Für den Lohnsteuerabzug",
                     "Zur Prüfung der postalisch richtigen Adresse",
                     "Für die Berechnung der Sozialversicherungsbeiträge"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F16
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Wie lange dauert der normale Mutterschutz (ohne Früh- oder Mehrlingsgeburt)?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="16"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["8 Wochen vor und 6 Wochen nach Geburt",
                     "6 Wochen vor und 12 Wochen nach Geburt",
                     "6 Wochen vor und 8 Wochen nach Geburt",
                     "8 Wochen vor und 12 Wochen nach Geburt"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F17
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Was wird gezahlt, wenn ein Mitarbeiter länger als 42 Tage wegen eines Arbeitsunfall krank ist und von wem?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="17"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Arbeitsentgelt vom Arbeitgeber",
                     "Krankengeld von der Krankenkasse",
                     "Übergangsgeld von der Deutschen Rentenversicherung",
                     "Verletztengeld von der Unfallversicherung"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F18
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Was wird gezahlt, wenn ein Mitarbeiter länger als 42 Tage arbeitsunfäig ist und in Reha geht?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="18"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Arbeitsentgelt vom Arbeitgeber",
                     "Krankengeld von der Krankenkasse",
                     "Übergangsgeld von der Deutschen Rentenversicherung",
                     "Verletztengeld von der Krankenkasse"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F19
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Bitte entscheide, ob die folgenden Lohnarten laufend oder als EGA abgerechnet werden können. Überstunden eines Monats, Quartalsprovision, monatliches Urlaubsgeld
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="19"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Überstunden eines Monats - laufend \r\n Quartalsprovision - EGA \r\n monatliches Urlaubsgeld - laufend",
                     "Überstunden eines Monats - EGA \r\n Quartalsprovision - laufend \r\n monatliches Urlaubsgeld - EGA",
                     "Überstunden eines Monats - EGA \r\n Quartalsprovision - EGA \r\n monatliches Urlaubsgeld - EGA",
                     "Überstunden eines Monats - laufend \r\n Quartalsprovision - EGA \r\n monatliches Urlaubsgeld - EGA"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'40px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F20
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
            Überstunden aus mehreren Monaten sind laufend oder als EGA abzurechnen?
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="20"
        ueberschrift=""
        anzReihen={2}
        Checktexte={["als laufendes Entgelt",
                     "als einmalig gezahltes Entgelt (EGA)"]}
        checked={[false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />
    </div>
    )
}
export default ScheckAbschlussTest;
