import { saveAs } from 'file-saver';


const openFile = (fileName, fileContent, mimeType='application/pdf') => {
  if (window.cordova) {
    download(fileName.replace('/','-').replace('\\','-'), fileContent, mimeType);
  } else {
      const url = window.URL.createObjectURL(fileContent);
      const link = document.createElement('a');
      link.href = url;
      link.download=fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}

const download = (filename, data, mimeType) => {
  var blob = new Blob([data], {
    type: mimeType
  });
  if (window.cordova && window.cordova.platformId !== "browser") {
    document.addEventListener("deviceready", function() {
      var storageLocation = "";
      switch (window.cordova.platformId) {
        case "android":
          storageLocation = window.cordova.file.externalDataDirectory;
          break;
        case "ios":
          storageLocation = window.cordova.file.documentsDirectory;
          break;
        default:
          storageLocation = window.cordova.file.externalDataDirectory;
          break;
      }

      var folderPath = storageLocation;

      window.resolveLocalFileSystemURL(
        folderPath,
        function(dir) {
          dir.getFile(
            filename,
            {
              create: true
            },
            function(file) {
              file.createWriter(
                function(fileWriter) {
                  fileWriter.write(blob);

                  fileWriter.onwriteend = function() {
                    var url = file.toURL();
                    window.cordova.plugins.fileOpener2.open(url, mimeType, {
                      error: function error(err) {
                        console.error(err);
                        alert("Datei konnte nicht heruntergeladen werden");
                      },
                      success: function success() {
                        console.log("success with opening the file");
                      }
                    });
                  };

                  fileWriter.onerror = function(err) {
                    alert("Datei konnte nicht heruntergeladen werden");
                    console.error(err);
                  };
                },
                function(err) {
                  // failed
                  alert("Datei konnte nicht heruntergeladen werden");
                  console.error(err);
                }
              );
            },
            function(err) {
              alert("Datei konnte nicht heruntergeladen werden");
              console.error(err);
            }
          );
        },
        function(err) {
          alert("Datei konnte nicht heruntergeladen werden");
          console.error(err);
        }
      );
    });
  } else {
    saveAs(blob, filename);
  }
}



export default openFile;