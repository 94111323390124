import React, {useState, useCallback} from 'react';
import {Avatar,Typography,Button,MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const NICKELSTAMM = '/media/Nickel Stammblatt.pdf';
const MUSTERDEUEV = '/media/MeldebescheinigungDEUEV.png';
const TAETIGKEITSCODE = '/media/Aufbau Taetigkeitscode.pdf';
const DEUEV_ANM = '/media/DEÜV Anmeldung.mp4';
import { Player, ControlBar } from 'video-react';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import { Deuev } from '../Rahmendaten/Deuev';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function ScheckDEUEV(props) {
  const [antworten,setAntworten]=useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('801');
  const [merkmal]=useState('DEUEVAnm');


  const setRedirect = (path) => {
    navigate(path);
  }
  const fehlerAusLesen=(fehler)=>{
    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    console.log("antworten: ",antworten)
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": "DEUEVAnmScheck",
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
         } else {
         if (response) {
           props.showError(response.data.message);
         } else {
           props.showError('Keine Internetverbindung');
         }
       }
     }


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  

  return(
    
  <div >
    <Briefkopf  
      ueberschrift1="Die DEÜV Meldung" 
      ueberschrift2="In diesem Bereich lernst Du, ^welche personenbezogenen Daten auf der DEÜV-Meldung stehen und welche Bedeutungen diese haben." 
    />

    <Grid container spacing={0} style={{marginTop:'30px' }} justifyContent={"center"}>
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              V1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText primary="In dem Video wird anschaulich erklärt, welchen Sinn und Zweck eine DEÜV-Meldung hat"/>
        </ListItem>
        <Grid item xs={12}style={{marginTop:"15px",marginBottom:"55px"}}>
          <Player
            poster={POSTER}
            src={DEUEV_ANM}
          >
            <ControlBar autoHide={false} />
          </Player>
        </Grid>

        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              M1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText primary="Wo steht was auf der DEÜV-Anmeldung?"/>
        </ListItem>
        <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
          Mit jeder DEÜV-Meldung werden folgende Fragen beantwortet: <br/>
          1. Was ist der Grund der DEÜV-Meldung (Anmeldung, Abmeldung, usw.)?<br/>
          2. Für welches Startdatum bzw. welchen Zeitraum gilt die Meldung?<br/>
          3. Welche Personengruppe ist zu melden?<br/>
          4. Welche Beitragsgruppe ist zu melden?<br/>
          5. Welches beitragspflichtige Bruttoarbeitsentgelt wurde in dem Meldezeitraum verbeitragt?<br/>
          6. Name der Einzugsstelle?<br/>
          Des weiteren sind noch folgende weitere Angaben in jeder DEÜV-Meldung enthalten:<br/>
            - Angaben zur Tätigkeit<br/>
            - Staatsangehörigkeit<br/>
            - ob der Arbeitnehmer in Ost- oder West-Deutschland beschäftigt ist<br/>
            - ob es sich um eine Mehrfachbeschäftigung handelt <br/>
        </Typography>
      <Grid item >
        <img src={MUSTERDEUEV} height="1500" alt="ectool" loading="lazy"/>
      </Grid>


      <ListItem style={{marginTop:"40px"}}>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            H1
          </Avatar> 
        </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Diese Erklärung hilft Dir beim Ausfüllen des Tätigkeitscodes auf der DEÜV-Anmeldung.
            </Typography>
          </ListItemText>
            <Button 
              color="primary"  
              variant="contained"
  //            fullWidth
              href={TAETIGKEITSCODE}download>
               Erklärung Tätigkeitscode
            </Button>
      </ListItem>
      <ListItem style={{marginTop:"40px"}}>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1<b/>
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant='h5'>
            Erstelle für die 3 Arbeitnehmer des Schulungsmandanten die jeweiligen DEUEV-Anmeldung.
          </Typography>
        </ListItemText>
      </ListItem>
    </Grid>
  </Grid>
  
  <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b/>A1.1<b/>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant='h5'>
                    Beginne mit der DEUEV-Meldung von Frau Plettenberg
                </Typography>
              </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="1"
        Grund=""
        Name="Plettenberg"
        Vorname=""
        Strasse=""
        HausNr=""
        PLZ=""
        Ort=""
        VersNr=""
        GebDat=""
        VonDat=""
        BisDat=""
        PG=""
        Währung="Euro"
        Betrag="--"
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>

    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1.2<b/>
          </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Erstelle nun die DEUEV-Meldung von Alex Grün
            </Typography>
          </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="2"
        Grund=""
        Name="Grün"
        Vorname=""
        Strasse=""
        HausNr=""
        PLZ=""
        Ort=""
        VersNr=""
        GebDat=""
        VonDat=""
        BisDat=""
        PG=""
        Währung="Euro"
        Betrag="--"
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
        >
      </Deuev>
    </Grid>
  </Grid>

  <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            <b/>A1.3<b/>
          </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Bitte jetzt noch die DEUEV-Meldung von Helmut Nickel erstellen
            </Typography>
          </ListItemText>
      </ListItem>
      <Deuev
        tabellenKey="3"
        Grund=""
        Name="Nickel"
        Vorname=""
        Strasse=""
        HausNr=""
        PLZ=""
        Ort=""
        VersNr=""
        GebDat=""
        VonDat=""
        BisDat=""
        PG=""
        Währung="Euro"
        Betrag="--"
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      >
      </Deuev>
    </Grid>
  </Grid> 


  <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

  </div>
  )
}


export default ScheckDEUEV;