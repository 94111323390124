import React, { useEffect } from 'react';

import {Grid, Typography,Checkbox, ListItem, ListItemText} from '@material-ui/core';



function TabelleUmlage1Erstattung (props) {
  const {fehlerliste, setzeAntworten, name, gbdatum,strasse,plz,datum, datumbis,datumseit} = props;


  return(


    <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left', backgroundColor:'#FCF1BE'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={8}style={{border:'solid', borderColor:'red',  textAlign:'left'}}>
            <Typography style={{color:'red'}}>
              Krankenkasse bzw. Kostenträger <br/> 
            </Typography>  
            <Typography style={{ marginTop:'8px',borderBottom:'solid',borderColor:'red', height:'40px'}}>
              AOK Baden-Württemberg
            </Typography>  
            <Typography style={{color:'red'}}>
              Name, Vorname des Versicherten
            </Typography> 

           <Grid item xs={12}style={{}}> 
            <div style={{}}>
              <Typography  style={{float:"left",width:"50%"}}>
                {name}
              </Typography>
              <Typography  style={{float:"right",width:"50%",color:'red'}}>
                geb. am
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"50%"}}>
                {strasse} 
              </Typography>
              <Typography  style={{float:"left",width:"50%"}}>
                {gbdatum}
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"50%"}}>
                {plz} 
              </Typography>
              <Typography  style={{float:"left",width:"50%"}}>
                ---
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"33%",borderTop:'solid', borderColor:'red', color:'red'}}>
                Kassen Nr.
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderTop:'solid', borderColor:'red',color:'red'}}>
                Versicherten Nr.
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderTop:'solid', borderColor:'red', color:'red'}}>
                Status
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"33%",borderRight:'solid',borderBottom:'solid', borderColor:'red'}}>
                ---
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderRight:'solid',borderBottom:'solid', borderColor:'red'}}>
                ---
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderBottom:'solid', borderColor:'red'}}>
                ---
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"33%", color:'red'}}>
                Betriebsstätten-Nr.
              </Typography>
              <Typography  style={{float:"left",width:"33%", color:'red'}}>
                Artz-Nr.
              </Typography>
              <Typography  style={{float:"left",width:"33%", color:'red'}}>
                Datum
              </Typography>
           </div>
           <div style={{}}>
              <Typography  style={{float:"left",width:"33%",borderRight:'solid',borderBottom:'solid', borderColor:'red'}}>
                ---
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderRight:'solid',borderBottom:'solid', borderColor:'red'}}>
                ---
              </Typography>
              <Typography  style={{float:"left",width:"33%",borderBottom:'solid', borderColor:'red'}}>
                {datum}
              </Typography>
           </div>
           </Grid>
        </Grid>
        <Grid item xs={3}style={{marginLeft:'10px'}}> 
           <Typography style={{fontWeight:'bold', color:'red', textAlign:'center'}} variant="h6" >
              Arbeitsunfähigkeits- bescheinigung
           </Typography>  
           <Typography style={{border:'solid', borderColor:'red',color:'red', textAlign:'center'}}>
            Ausfertigung zur Vorlage <br/>
            bei der Krankenkasse
           </Typography>
        </Grid>
        <Grid item xs={8}style={{textAlign:'left', color:'red'}}>
           <ListItem>
                <ListItemText>
                    <Checkbox
                        checked={true}
                        disabled
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                        style={{marginLeft:"5px", border:(fehlerliste["erstb."]===true)?"solid red":""}}
                        onChange={(event) => setzeAntworten('erstb.',event.target.checked)}
                    />
                    Erstbescheinigung
                </ListItemText> 
                <ListItemText>
                    <Checkbox
                        disabled
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                        style={{marginLeft:"5px",border:(fehlerliste["folgeb."]===true)?"solid red":""}}
                        onChange={(event) => setzeAntworten('folgeb',event.target.checked)}
                    />
                    Folgebescheinigung
                </ListItemText> 
              </ListItem>
              <ListItem>
                <ListItemText>
                    <Checkbox
                        label='X'
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                        disabled
                        
                    />
                    Arbeitsunfall, Arbeits<br/>unfallfolgen, Berufskrankheit
                </ListItemText> 
                <ListItemText>
                    <Checkbox
                        disabled
                        />
                    dem Durchgangsarzt <br/> zugewiesen
                </ListItemText> 
          </ListItem>
          <Grid item xs={12}style={{}}>
            <div style={{}}>
                <Typography  style={{float:"left",width:"50%"}}>
                  arbeitsunfähig seit
                </Typography>
                <Typography  style={{float:"right",width:"50%", color:'black'}}>
                  {datumseit}
                </Typography>
            </div>
            <div style={{}}>
                <Typography  style={{float:"left",width:"50%"}}>
                   vorraussichtlich arbeitsunfähig bis einschließlich
                </Typography>
                <Typography  style={{float:"left",width:"50%",color:'black'}}>
                  {datumbis}
                </Typography>
            </div>
            <div style={{}}>
                <Typography  style={{float:"left",width:"50%"}}>
                  festgestellt am
                </Typography>
                <Typography  style={{float:"left",width:"50%",color:'black'}}>
                  {datum}
                </Typography>
            </div>
          </Grid>

          </Grid>
          <Grid item xs={3}style={{marginLeft:'10px',border:'solid', borderColor:'red',backgroundColor:'white', height:'200px'}}> 
            </Grid>
        </Grid>




   
  )
}






export {TabelleUmlage1Erstattung};