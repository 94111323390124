import React, { useEffect } from 'react';

import {Grid, Typography,Checkbox, TextField, MenuItem, ListItem, ListItemAvatar, ListItemText,Avatar, FormControlLabel} from '@material-ui/core';



function Tabelle3Wissen (props) {
    const { tabellenkey, fehlerliste, antworten, setzeAntworten, frage, antwort1,antwort2,antwort3, antwort4, antwort5} = props;


  return(

        

        <Grid item xs={8}style={{textAlign:'center'}}>
          <ListItem>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor:"#1fbba6"}}>
                          {tabellenkey}
                    </Avatar> 
                </ListItemAvatar>
                <ListItemText>

            <Typography color="primary" variant='h5' style={{textDecoration:'underline', textAlign:'center'}}>
                        {frage}
            </Typography>
            <Grid>
                <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".1"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["frage"+tabellenkey+".1"]}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+".1",event.target.checked)}
                    label={antwort1}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".2"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["frage"+tabellenkey+".2"]}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+'.2',event.target.checked)}
                    label={antwort2}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".3"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["frage"+tabellenkey+".3"]}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+'.3',event.target.checked)}
                    label={antwort3}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".4"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["frage"+tabellenkey+".4"]}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+'.4',event.target.checked)}
                    label={antwort4}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".5"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["frage"+tabellenkey+".5"]}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+'.5',event.target.checked)}
                    label={antwort5}
                    labelPlacement="end"
                />
            </Grid>
            
            </ListItemText>
           </ListItem>
        </Grid>






   
  )
}






export {Tabelle3Wissen};