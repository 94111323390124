import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';




function FahrtenWhngArbPausch (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, km, ergpausch, tage, kmsatz} = props;

    if (km !== '') {
        antworten["km"+tabellenKey]=km;
        var kmblockiert=true;
    }

    if (ergpausch !== '') {
        antworten["ergpausch"+tabellenKey]=ergpausch;
        var ergpauschblockiert=true;
    }

    if (tage !== '') {
        antworten["tage"+tabellenKey]=tage;
        var tageblockiert=true;
    }

    if (kmsatz !== '') {
        antworten["kmsatz"+tabellenKey]=kmsatz;
        var kmsatzblockiert=true;
    }


return(
    <div>
    <Grid container spacing={0} style={{marginTop:'15px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <TableContainer style={{marginTop:"10px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableHead>
                        <TableRow  >
                            <TableCell style={{textAlign:"center" }}>Kilometersatz</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">x</TableCell>
                            <TableCell style={{textAlign:"center"}}>Tage</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">x</TableCell>
                            <TableCell style={{textAlign:"center"}}>Entfernungs-kilometer</TableCell>
                            <TableCell style={{textAlign:"center"}} width="2px">=</TableCell>
                            <TableCell style={{textAlign:"center"}} >monatlicher pauschal zu <br/>versteuernder gwV für <br/>Km Whng-Arbeit</TableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>
                                Der gesetzlich festgelegte Kilometersatz beträgt 0,30 Euro.
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{textAlign:"left"}}>
                                Wie oft der Arbeitnehmer tatsächlich in die Firma gefahren ist, braucht nicht explizit dokumentiert zu werden, 
                                wenn bei einer <b>Arbeitszeit von 5 Tagen pro Woche</b> pauschal mit <b>15 Tagen</b> pro Monat gerechnet wird.<br/>
                                In den 15 Tagen sind Urlaubs-, Feier- und Kranktage, an denen der Arbeitnehmer nicht in die Firma fährt, prozentual bereits berücksichtigt. Deshalb auch 15 Tage und nicht durchschnittlich 21,75 Tage.<br/>
                                Bei anderen Arbeitszeiten sind die 15 Tage entsprechend ins Verhältnis zu setzen. Bsp.: Arbeitszeit 6 Tage pro Woche ergeben dann (15 : 5 x 6 =) 18 pauschal anzusetzende Tage pro Monat.<br/>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>



                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={kmsatzblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["kmsatz"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kmsatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kmsatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('kmsatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell>x</TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={tageblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tagepauschal"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["tagepauschal"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["tagepauschal"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('tagepauschal'+tabellenKey,event.target.value)}
                                    >
                                </Eingabe>
                            </TableCell>
                            <TableCell>x</TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={kmblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["km"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["km"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["km"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('km'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell>=</TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={ergpauschblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["ergpausch"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["ergpausch"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["ergpausch"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('ergpausch'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography style={{marginTop:"20px", textAlign:"left"}}>
                Der Teil, der pauschal versteuert wird, mindert den persönlich zu versteuernden gwV für Fahrten Wohnung-Arbeitsstätte.<br/>
            </Typography>
        </Grid>

    </Grid>

    </div>
 
  )
}

export {FahrtenWhngArbPausch};