import React, {useState, useCallback} from 'react';
import {Avatar,Typography, Grid, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
const AusweisAbrechnung = '/media/Ausweis auf der Abrechnung.png';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';



function Durchschnittstage(props) {
  const { accessToken, hsutkey } = props;
  const [antworten,setAntworten]=useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [testabgabe, setTestabgabe]=useState(false);
  const navigate=useNavigate();
  const [fehlerliste,setFehlerliste]=useState([]);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const {showErrorMessage} = useMessage();
  const [reactnummer]=useState('005');
  const [merkmal]=useState('Durchschnittstage');


  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;       
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
//    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
//   console.log({antworten})

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
    if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        fehlerAusLesen(response.data.fehler);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
       } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  return(   
  <div>
      <Briefkopf  
          ueberschrift1="Berechnung von Durchschnittstagen und -stunden" 
          ueberschrift2="Wie werden bei Gehaltsempfängern die auf der Entgeltabrechnung ausgewiesenen Durchschnittstage und Durchschnittsstunden berechnet?" 
      />

    <Grid container spacing={1}  style={{marginTop:'30px',textAlign:'left'}}  alignItems={"center"}>
      <Grid item xs={12}>
        <Typography variant='h5'color='primary'>
          1. Berechnung durchschnittlicher Wochen, Tage und Stunden
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Auf der Entgeltabrechnung können, je nach Mandantenwunsch, statistische Arbeitstage und - stunden aufgeführt werden.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>
          1.1 Durchschnittliche Wochen
        </Typography>
        <Typography>
          Über einen Zeitraum von 4 Jahren berechnet (inklusive einem Schaltjahr) hat jeder Monat durchschnittlich <strong> 4,35 </strong> Wochen.<br/> 
        </Typography>  
      </Grid>
      <Grid item xs={2}>
        <Typography>
          Berechnung: <br/> 
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography>
          365 Tage + 365 Tage + 365 Tage+ 366 Tage = 1461 Tage<br/> 
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>
           
        </Typography>
      </Grid>
      <Grid item xs={10} style={{marginTop:'-8px'}}>
        <Typography>
          1461 Tage / 4 Jahre = 365,25 Tage/Jahr<br/> 
          365,25 Tage/Jahr / 7 Tage/Woche = 52,18 Wochen/Jahr <br/> 
          52,18 Wochen/Jahr / 12 Monate/Jahr = <strong>4,35 Wochen/Monat</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>
          1.2 Durchschnittliche Tage
        </Typography>
        <Typography>
          Bei einer Arbeitszeit von 5 Tagen pro Woche ergeben sich durchschnittlich <strong> 21,75 </strong> Tage pro Monat.<br/> 
        </Typography> 
      </Grid>
      <Grid item xs={2}>
        <Typography>
          Berechnung:
        </Typography> 
      </Grid>
      <Grid item xs={10}>
        <Typography>
          4,35 Wochen/Monat * 5 Tage/Woche = <strong> 21,75 Tage/Monat </strong>
        </Typography> 
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>
          1.3 Durchschnittliche Stunden
        </Typography>
        <Typography>
         Bei einer wöchentlichen Arbeitszeit von 40 Stunden ergeben sich durchschnittlich <strong> 174 </strong> Stunden pro Monat.         
        </Typography> 
      </Grid>
      <Grid item xs={2}>
        <Typography>
          Berechnung:
        </Typography> 
      </Grid>
      <Grid item xs={10}>
        <Typography>
          4,35 Wochen/Monat * 40 Stunden/Woche = <strong> 174 Stunden/Monat </strong>
        </Typography> 
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>
          1.4 Ausweis auf der Abrechnung 
        </Typography>
      </Grid>
      <Grid item >
              <img src={AusweisAbrechnung} height="150" alt="ectool" loading="lazy"/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>
          1.5 Stundenlohn
        </Typography>
        <Typography>
          Bei einer wöchentlichen Arbeitszeit von 40 Stunden und einem Gehalt von 2500,-€ ergibt sich ein Stundenlohn von <strong>14,37 </strong> Euro/Stunde
        </Typography> 
      </Grid>
      <Grid item xs={2}>
        <Typography>
          Berechnung:
        </Typography> 
      </Grid>
      <Grid item xs={10}>
        <Typography>
          2500,-€ : (4,35 Wochen/Monat * 40 Stunden/Woche) =14,37€/Stunde
         
        </Typography> 
      </Grid>
    </Grid>   

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              H
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
              <h4>Hinweis:</h4>
                Bei älteren Tarifverträgen kommt es vor, dass ein Wochenfaktor von 4,33 oder 4,34 oder 4,325 zu grunde gelegt wurde.<br/>
                In den Steuerrichtlinien ist der einheiltiche Wochenfaktor von 4,35 Wochen definiert. <br/>
                Deshalb sind alle u. a. Aufgaben mit dem Wochenfaktor 4,35 zu lösen. 
          </ListItemText>  
        </ListItem>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Aufgabe:</h4>
                  Berechne, gem. den in den Aufgaben dargestellten Vereinbarungen, die durchschnittlichen Tage und Stunden pro Monat und den daraus resultierenden Stundenlohn.
                  Trage die errechneten Werte in die dafür vorgesehenen Spalten ein.
                  Alle Zahlen sind auf 2 Nachkommastellen kaufmännisch zu runden. 
              </ListItemText>  
          </ListItem>
    </Grid>
 
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Die vertragliche Arbeitszeit des Arbeitnehmers beträgt 30 Stunden pro Woche von Montag bis Freitag.
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography style={{textAlign:'left'}}>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1FBBA6"}} >
        <Typography style={{textAlign:'left'}}>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography style={{textAlign:'left'}}>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography style={{textAlign:'left'}}>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography style={{textAlign:'left'}}>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px',textAlign:'center'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px',textAlign:'center'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField 
        type='number' 
        id="tage1" 
        error={fehlerliste["tage1"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage1',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde1" 
        type='number'
        error={fehlerliste["stunde1"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde1',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           2000,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           2000,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           2000,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn1" 
        error={fehlerliste["stundenlohn1"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn1',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          2000,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Die Arbeitszeit des Arbeitnehmers ist wie folgt geregelt:<br/>
                  Dienstag = 8 Stunden<br/>
                  Mittwoch = 8 Stunden<br/>
                  Donnerstag = 4 Stunden
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage2" 
        type='number'
        error={fehlerliste["tage2"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage2',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde2" 
        type='number'
        error={fehlerliste["stunde2"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde2',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn2" 
        error={fehlerliste["stundenlohn2"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn2',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          1500,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                3
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Die Arbeitszeit des Arbeitnehmers ist wie folgt geregelt:<br/>
              Dienstag = 8 Stunden<br/>
              Mittwoch = 8 Stunden
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage3" 
        type='number'
        error={fehlerliste["tage3"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage3',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde3" 
        type='number'
        error={fehlerliste["stunde3"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde3',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1440,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}> 
           1440,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1440,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn3" 
        error={fehlerliste["stundenlohn3"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn3',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          1440,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>



    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                4
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Die Arbeitszeit des Arbeitnehmers beträgt 10 Stunden an zwei Arbeitstagen pro Woche
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#1fbba6"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage4" 
        type='number'
        error={fehlerliste["tage4"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage4',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde4" 
        type='number'
        error={fehlerliste["stunde4"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde4',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1500,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn4" 
        error={fehlerliste["stundenlohn4"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn4',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          1500,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                5
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Die Arbeitszeit des Arbeitnehmers beträgt 18 Stunden an drei Arbeitstagen pro Woche:
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage5" 
        type='number'
        error={fehlerliste["tage5"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage5',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde5" 
        type='number'
        error={fehlerliste["stunde5"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde5',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1100,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1100,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           1100,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn5" 
        error={fehlerliste["stundenlohn5"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn5',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          1100,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                6
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Die Arbeitszeit des Arbeitnehmers beträgt 40 Stunden an fünf Arbeitstagen pro Woche:
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage6" 
        type='number'
        error={fehlerliste["tage6"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage6',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde6" 
        type='number'
        error={fehlerliste["stunde6"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde6',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           3200,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           3200,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           3200,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn6" 
        error={fehlerliste["stundenlohn6"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn6',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          3200,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                7
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Der Arbeitnehmer arbeitet von Montag bis Donnerstag 32 Stunden.
              </ListItemText>  
          </ListItem>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid", borderTop:'solid',backgroundColor:"#BDBDBD"}}>
        <Typography>
          Lohnart
        </Typography>
      </Grid>
      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Bezeichnung
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Tage
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}} >
        <Typography>
          Stunden
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography>
          Steuerpflichtig
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Sozialvers.pflichtig
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}}>
        <Typography>
          Betrag 
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          0001
        </Typography>
      </Grid>

      <Grid item xs={1} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <Typography style={{marginTop:'15px'}}>
          Gehalt
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="tage7" 
        type='number'
        error={fehlerliste["tage7"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('tage7',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        <TextField  
        id="stunde7" 
        type='number'
        error={fehlerliste["stunde7"]}
        label="Eintrag" 
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stunde7',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           2300,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           2300,-
         </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px'}}>
         <Typography style={{marginTop:'15px'}}>
           2300,-
         </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={4} style={{borderLeft:"solid",borderRight:"solid", borderTop:'solid'}}>
        <Typography>
          Stand der Summenzeiten
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#1fbba6"}}>
        <Typography style={{textAlign:'left'}}>
          Std. Lohn
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Tarifgruppe
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          Grundlohn
       </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid",backgroundColor:"#BDBDBD"}} >
        <Typography style={{textAlign:'left'}}>
          ant. Tarifentgelt
       </Typography>
      </Grid>
      <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid",height:'60px'}}>
        
      </Grid>

      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:""}}>
      <TextField 
        type='number' 
        id="stundenlohn7" 
        error={fehlerliste["stundenlohn7"]}
        label="Eintrag"
        inputProps={{style:{textAlign:"center"}}}
        placeholder=""  
        style={{display:"flex"}}onChange={(event) => setzeAntworten('stundenlohn7',event.target.value)}/>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
        <Typography style={{marginTop:'15px'}}>
          2300,-
        </Typography>
      </Grid>
      <Grid item xs={2} style={{borderBottom:"solid",borderRight:"solid",height:'60px',backgroundColor:"#BDBDBD"}}>
         <Typography style={{marginTop:'15px',textAlign:'center'}}>
           0.00
         </Typography>
      </Grid>

    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>


  </div>

    )



}

export default Durchschnittstage;