
import React, { useState } from "react";
import VerdienstbescheinigungArbeitgebers from "./VerdienstbescheinigungArbeitgebers";




const VerdienstbescheinigungArbeitgebers2 = (props) => {
    const { accessToken, hsutkey } = props;
    const [reactnummer] = useState("843")
    const [merkmal] = useState("VerdienstbescheinigungArbeitgeber2")
    

    return (
        <div>
            <VerdienstbescheinigungArbeitgebers reactNummer={reactnummer} merkMal={merkmal} token={accessToken} hsut={hsutkey} von="08/2022" bis="01/2023" ></VerdienstbescheinigungArbeitgebers>
        </div>
    );
}

export default VerdienstbescheinigungArbeitgebers2