import React, {useState, useCallback} from 'react';
import {Typography, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';

function Lohnsteuerbescheinigung(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('021');
  const [merkmal]=useState("Lohnsteuerbescheinigung");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
    // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
        <Grid container spacing={0} justifyContent="center">
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{border:"solid"}}>
                <Typography>
                1. Bescheinigungszeitraum
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:"solid"}}>
            <TextField  fullWidth id="STEUERIDENT" error={fehlerliste["STEUERIDENT"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["STEUERIDENT"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('STEUERIDENT',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                2. Zeiträume ohne Anspruch auf Arbeitslohn
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="STEUERIDENT" error={fehlerliste["STEUERIDENT"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["STEUERIDENT"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('STEUERIDENT',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                Großbuchstaben (S, M, F, FR)
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
                <Typography>Betrag</Typography>
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                    3. Bruttoarbeitslohn einschl. Sachbezüge ohne 9. und 10.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG1" error={fehlerliste["BETRAG1"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG1"]===true)?"red":"",display:"inline-block",borderRight:""}} onChange={(event) => setzeAntworten('BETRAG1',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                5. Einbehaltener Solidaritätszuschlag von 3.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                6. Einbehaltene Kirchensteuer des Arbeitnehmers von 3.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                7. Einbehaltene Kirchensteuer des Ehegatten/Lebenspartners von 3. (nur bei Konfessionsverschiedenheit)
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                8. In 3. enthaltene Versorgungsbezüge
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Korrektur/Stornierung</Typography>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                9. Ermäßigt besteuerte Versorgungsbezüge für mehrere Kalenderjahre
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Datum:</Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" ,width:"30%"}} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                10. Ermäßigt besteuerter Arbeitslohn für mehrere Kalenderjahre (ohne 9.) und ermäßigt besteuerte Entschädigungen
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:""} } onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>eTin: </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",width:"30%" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                11. Einbehaltene Lohnsteuer von 9. und 10.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Idenfikationsnummer: </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",width:"30%" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                12. Einbehaltener Solidaritätszuschlag von 9. und 10.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Personalnummer: </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",width:"30%" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                13. Einbehaltene Kirchensteuer des Arbeitnehmers von 9. und 10.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Geburtsdatum: </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",width:"30%" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                14. Einbehaltene Kirchensteuer des Ehegatten/Lebenspartners von 9. und 10. (nur bei Konfessionsverschiedenheit)
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Transferticket: </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",width:"30%" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                15. (Saison-)Kurzarbeitergeld, Zuschuss zum Mutterschaftsgeld, Verdienstausfallentschädigung (Infektionsschutzgesetz), Aufstockungsbetrag und Altersteilzeitzuschlag
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
                <Typography style={{textAlign:"left"}}>Dem Lohnsteuerabzug wurden im letzten Lohnzahlungszeitraum zugrunde gelegt:</Typography>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                16. Steuerfreier Arbeitslohn
                nach <br/>a) Doppelbesteuerungsabkommen (DBA)
                    <br/>b) Auslandstätigkeitserlass
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe(a)" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",marginTop:"8px"}} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe(b)" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  

            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                17. Steuerfreie Arbeitgeberleistungen, die auf die Entfernungspauschale anzurechnen sind
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>

            <Grid item xs={3} style={{border:""}}>
                <Typography style={{textAlign:"left"}}>Steuerklasse / Faktor </Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",borderTop:"solid" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>

            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                18. Pauschal mit 15 % besteuerte Arbeitgeberleistungen für Fahrten zwischen Wohnung und erster Tätigkeitsstätte
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
                <Typography style={{textAlign:"left"}}>Zahl der Kinderfreibeträge</Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",borderTop:"solid" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                19. Steuerpflichtige Entschädigungen und Arbeitslohn für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden - in 3. enthalten
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
                <Typography style={{textAlign:"left"}}>Steuerfreier Jahresbetrag</Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",borderTop:"solid" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                20. Steuerfreie Verpflegungszuschüsse bei Auswärtstätigkeit
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
                <Typography style={{textAlign:"left",marginTop:"15px"}}>Jahreshinzurechnungsbetrag</Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",borderTop:"solid" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                21. Steuerfreie Arbeitgeberleistungen bei doppelter Haushaltsführung
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
                <Typography style={{textAlign:"left",marginTop:"15px"}}>Kirchensteuermerkmale</Typography>
                <TextField   id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",borderTop:"solid" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                    22. Arbeitgeber- anteil/-zuschuss
                    <br/>a) zur gesetzlichen Rentenversicherung
                    <br/>b) an berufsständische Versorgungs- einrichtungen
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe(a)" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",marginTop:"8px"}} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe(b)" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  

            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                19. Steuerpflichtige Entschädigungen und Arbeitslohn für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden - in 3. enthalten
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                19. Steuerpflichtige Entschädigungen und Arbeitslohn für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden - in 3. enthalten
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                25. Arbeitnehmerbeiträge zur gesetzlichen Krankenversicherung
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={3} style={{border:""}}>
            </Grid>
            <Grid item xs={3} style={{}}>
            </Grid>

            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                26. Arbeitnehmerbeiträge zur sozialen Pflegeversicherung
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                27. Arbeitnehmerbeiträge zur Arbeitslosenversicherung
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                28. Beiträge zur privaten Kranken- und Pflege-Pflichtversicherung oder Mindestvorsorgepauschale
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                29. Bemessungsgrundlage für den Versorgungsfreibetrag zu 8.
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                30. Maßgebendes Kalenderjahr des Versorgungsbeginns zu 8. und/oder 9.                
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                31. Zu 8. bei unterjähriger Zahlung: Erster und letzter Monat, für den Versorgungsbezüge gezahlt wurden
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
                
            <TextField  fullWidth  id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"",verticalAlign:"bottom"}} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                32. Sterbegeld; Kapitalauszahlungen/Abfindungen und Nach- zahlungen von Versorgungsbezügen - in 3. und 8. enthalten
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                33. Ausgezahltes Kindergeld
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={3} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                34. Freibetrag DBA Türkei
                </Typography>
            </Grid>
            <Grid item xs={3}style={{borderBottom:"solid",borderRight:"solid",borderTop:""}}>
            <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
            <Grid item xs={6} style={{}}>
            </Grid>
            <Grid item xs={6} style={{borderBottom:"solid",borderRight:"solid",borderTop:"",borderLeft:"solid"}}>
                <Typography>
                Finanzamt, an das die Lohnsteuer abgeführt wurde (Name und vierstellige Nr.)
                </Typography>
                <TextField  fullWidth id="BETRAG2" error={fehlerliste["BETRAG2"]} label="" placeholder="Eingabe" style={{backgroundColor:(fehlerliste["BETRAG2"]===true)?"red":"",display:"flex",borderRight:"" }} onChange={(event) => setzeAntworten('BETRAG2',event.target.value)}/>  
            </Grid>
        </Grid>
        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
     </div>
    )
}


export default Lohnsteuerbescheinigung;