import React, {useState, useCallback} from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Grid, Box} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const POSTER = '/media/Grafik_Filmrolle.png';
const GeldwerterV = '/media/Chat_Tutorial_Geldwerter Vorteil.M4V';
import {TabelleGelwerterVorteil} from './tabelleGV';
import {AntwortenCheckBox} from './checkboxAnwort';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function GwVorteil(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [bemerkungen, setBemerkungen] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0')
  const {showErrorMessage} = useMessage();
  const [reactnummer]=useState('015');
  const [merkmal]=useState("GwVorteil");


  const setzeAntworten = ( key,value) => {
    console.log("antwortenkey: "+key)
    console.log("antwortenvalue: "+value)
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values+key)
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler)=>{
    console.log("Fehler: "+fehler)
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    

    return(
      <Box p={2}>
      <div>
      <Briefkopf  
            ueberschrift1="Geldwerter Vorteil" 
            ueberschrift2="Was versteht man unter einem geldwerten Vorteil?" 
      />
      
      <Grid container spacing={0} style={{marginTop:'30px', justify:"center",  alignItems:"center"  }} >
        <Grid item xs={12} style={{}}>
              <Player
                poster={POSTER}
                  src={GeldwerterV}
              >
              <ControlBar autoHide={false} />
              </Player>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  E
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                    <h4>Erklärung:</h4>
                      Als geldwerter Vorteil gilt immer jener Betrag, den der Arbeitnehmer in eine Sachleistung investieren müsste, 
                      wenn er diese selbst finanziert.<br/> 
                </ListItemText>  
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                    <h3>Aufgabe:</h3>
                      Entscheide, ob die jeweilige Annehmlichkeit steuerfrei ist. <br/>
                      Gerne kannst Du das Internet für weitere Recherchen benutzen. Versichere Dich aber, dass es Internetseiten sind, welchen Du vertrauen kannst.  
                </ListItemText>  
            </ListItem>
      </Grid>


      <TabelleGelwerterVorteil  aufgabe1='1' aufgabe2='2'
                                titel1='Als kleine Aufmerksamkeit bekommt eine Arbeitnehmerin einen Blumenstrauß im Wert von 35.- Euro überreicht.'
                                titel2='Zum Firmenjubiläum bekommt eine Arbeitnehmerin eine Uhr im Wert von 400,- Euro überreicht.' 
                                tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <TabelleGelwerterVorteil  aufgabe1='3' aufgabe2='4'
                                titel1='Der Arbeitgeber übernimmt die Parkplatzgebühren des Arbeitnehmers in Höhe von 70,- Euro monatlich.'
                                titel2='Der Arbeitgeber übernimmt für seinen Arbeitnehmer die Gebühren für das Fitnessstudios in Höhe von 40 Euro monatlich.' 
                                tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


      <TabelleGelwerterVorteil  aufgabe1='5' aufgabe2='6'
                                titel1='Ein Arbeitgeber verteilt an seine Arbeitnehmer 30,- Euro "Volksfestgeld" in bar.'
                                titel2='Der Arbeitgeber verteilt an seine Mitarbeiterin zweimal im Monat einen Gutschein für einen ortsansässigen Getränkehandel in Höhe von jeweils 30 Euro.' 
                                tabellenKey="3" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <TabelleGelwerterVorteil  aufgabe1='7' aufgabe2='8'
                                titel1='Ein Arbeitgeber gibt seinem Arbeitnehmer monatlich einen Tankgutschein im Wert von 42,- Euro. Dafür wird sein Gehalt monatlich um 42,- Euro gekürzt.'
                                titel2='Zum Geburtstag bekommt eine Arbeitnehmerin einen Warengutschein in Höhe von 60 Euro für den ortsansässigen Bäcker'
                                tabellenKey="4" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <TabelleGelwerterVorteil  aufgabe1='9' aufgabe2='10'
                                titel1='Aufgrund einer hervorragenden Abschlußprüfung erhält ein Arbeitnehmer von seinem Arbeitgeber einen Amazon-Gutschein in Höhe von 40,- Euro.'
                                titel2='Eine Arbeitnehmerin bekommt zur Geburt ihres Kindes von ihrem Arbeitgeber einen Gutschein in Höhe von 60,- von einem Babyfachmarkt.'
                                tabellenKey="5" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


      <ListItem style={{marginTop:"35px"}}>
          <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                  11
              </Avatar>
            </ListItemAvatar>
            <ListItemText >
                <strong>
                  Der Arbeitnehmer, für den der Arbeitgeber bereits die Monatsgebühren eines Fitnessstudio in Höhe von 35,- Euro bezahlt, bekommt zusätzlich von seinem Arbeitgeber einmalig eine Sporttasche in Höhe von 38,- Euro.<br/>
                  Für die Überreichung der Sporttasche gibt es keinen persönlichen Anlass.<br/>
                  Wie ist die steuerliche Behandlung der Gebühren und der Sporttasche in dem Monat, in dem der Arbeitnehmer die Sporttasche bekommt?
                </strong>
            </ListItemText>  
      </ListItem>
      <AntwortenCheckBox  tabellenKey="11" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <ListItem style={{marginTop:"35px"}}>
          <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                  12
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <strong>
                  Der selbe Sachverhalt wie bei Aufgabe 11. Mit dem Unterschied, dass der Arbeitnehmer aufgrund seines Geburtstages die Sporttasche überreicht bekommt. <br/>
                  Wie ist die steuerliche Behandlung der Gebühren und der Sporttasche in dem Monat, in dem der Arbeitnehmer die Sporttasche bekommt?
                </strong>
            </ListItemText>  
      </ListItem>
      <AntwortenCheckBox  tabellenKey="12" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <ListItem style={{marginTop:"35px"}}>
          <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                  13
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <strong>
                  Der Arbeitnehmer, für den der Arbeitgeber bereits die Monatsgebühren eines Fitnessstudios in Höhe von 35,- Euro bezahlt, 
                  bekommt zusätzlich von seinem Arbeitgeber einmalig eine Sporttasche in Höhe von 72,- Euro.<br/>
                  Die Sporttasche wird dem Arbeitnehmer anlässlich seines Geburtstages überreicht. <br/>
                  Wie ist die steuerliche Behandlung der Gebühren und der Sporttasche in dem Monat, in dem der Arbeitnehmer die Sporttasche bekommt?
                </strong>
            </ListItemText>  
      </ListItem>
      <AntwortenCheckBox  tabellenKey="13" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
     </Box>
    )
  }
  

export default GwVorteil;
