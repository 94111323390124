import {client} from './utils/ApiClient'
import {LOCAL_STORAGE_KEY} from './constants/localStorage'

async function getToken() {
  // TODO: on mobile Device do DeviceAuth and try to get AccessToken
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.accessToken);
  return(token);
}

function handleUserResponse({user, deviceId}) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY.accessToken, user.token);
  if (user.refreshToken) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY.refreshToken, user.refreshToken);
  }
  if (deviceId) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY.deviceId, deviceId);
  }
  return user
}

async function login(form) {
  const result_1 = await client('login/ecschool', { data: form });
  return handleUserResponse(result_1);
}

async function loginAdmincode(form) {
  const result_1 = await client('login/admincode', { data: form });
  return handleUserResponse(result_1);
}

async function refreshToken(form) {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.refreshToken);
  const result = await client('token/refresh', { data: form, token: token });
  console.log(result);
  return handleUserResponse(result);
}

async function logout() {
  const saveUsername = localStorage.getItem(LOCAL_STORAGE_KEY.saveUsername);
  window.localStorage.removeItem(LOCAL_STORAGE_KEY.accessToken);
  if (saveUsername === 'false') {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY.username);
  } 
}

export {getToken, login, loginAdmincode, logout, refreshToken}