import React, {useState, useCallback } from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText, Grid, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Chat_Tutorial_Mutterschutzgesetz.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {VerdienstTab } from './VerdienstTab';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import {Erstattungsantrag } from './Erstattungsantrag';
import {ErmittlungMutterschutzFrist } from '../Rahmendaten/ErmittlungMutterschutzfrist';



function FehlzeitMutterschutz(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('032');
  const [merkmal]=useState("FehlzeitMutterschutz");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Mutterschutz" 
          ueberschrift2="Wie berechnet sich die Mutterschutzfrist ^und wie wirkt sich der Mutterschutz auf die Entgeltabrechnung aus?" 
      />

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Schaue Dir zuerst das Tutorial dazu an.
          </Typography>
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{}}>
            <ListItem>
              <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    H1
                  </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Hinweis Berechnung Mutterschaftsgeld"/>
            </ListItem>
            <ListItem>
              <Typography>
                Die Höhe des Mutterschaftsgeldes bestimmt sich nach dem um die gesetzlichen Abzüge verminderten durchschnittlichen kalendertäglichen Arbeitsentgelt der letzten drei abgerechneten Kalendermonate vor der  
                Schutzfrist (bei wöchentlicher Abrechnung die letzten 13 abgerechneten Wochen). Maßgebend für die Höhe des Mutterschaftsgeldes ist also das <b>Nettoarbeitsentgelt</b> (umgerechnet auf einen Kalendertag). <br/>  
                <b>Einmalig gezahltes Arbeitsentgelt bleibt außer Betracht </b> <br/>
                Bei der Berechnung des kalendertäglichen Nettoarbeitsentgelt ist der Monat mit 30 Tagen anzusetzen. Abzuziehen sind Tage der Entgeltminderung oder ohne Entgelt.
              </Typography>
            </ListItem>
            <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    M1
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Muster"/>
          </ListItem>
          <ListItem>
              <Typography>
                Hier ein Muster für eine Gehalts- und Mutterschaftsgeldvergütung. <br/>
                Bei dem Muster wurde der Beginn des Mutterschutzes zum 10.10.2020 ermittelt. Somit wurden in dem Abrechnungsmonat 22 Kalendertage Mutterschaftsgeld zugrunde gelegt.
              </Typography>
          </ListItem>
          <BruttoNeu2
              anzReihen={2}

              Lohnart={['1029','1198']}
              Intern={['029','198']}
              Bezeichnung={["Gehalt","Muttersch. Geld"]}
              Tage={["6.92","22.00"]}
              Stunden={["55.36","0.00"]}
              Faktor={["700.00","29.97"]}
              Kennung={["LFD","LFD"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["700.00","0.00"]}
              Sozialverspflichtig={["700.00","0.00"]}
              Betrag={["700.00","659.34"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

        </Grid>
      </Grid>
      

      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    A1.1
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Aufgabe 1.1"/>
          </ListItem>
          <ListItem>
            
            <Typography>
                Eine Arbeitnehmerin legt dem Arbeitgeber eine Schwangerschaftsbescheinigung mit dem errechneten Geburtstermin vor: <br/>  
                Berechne die vorläufige Mutterschutzfrist. Der errechnete Geburtstermin ist am 20.10.2020  <br/>  
            </Typography>
          </ListItem>

          <ErmittlungMutterschutzFrist
              tabellenKey="1"
              von=""
              bis=""
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>
      
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
            <ListItem style={{marginTop:"20px"}}>
              <Typography>
                In der Vergangenheit hatte die Arbeitnehmerin folgenden Verdienst: <br/>
                Die Verdienstangaben setzen sich aus einem Gehalt in Höhe von 2100,- Euro und einer monatlicher variablen Provision zusammen. <br/>
                Deshalb ist der monatliche Verdienst unterschiedlich hoch.
              </Typography>
          </ListItem>

          <VerdienstTab/>

          <ListItem style={{marginTop:"20px"}}>
              <Typography>
                Wir befinden uns im Abrechnungsmonat September <br/>  
                Die Arbeitnehmerin bekommt lt. aktuellem Arbeitsvertrag ein monatliches Gehalt in Höhe 2100,- Euro. <br/> 
                Eine Provision wird im September nicht bezahlt. <br/>
                Die Arbeitszeit ist von Montag bis Freitag jeweils 8 Stunden.<br/> 
                Eine etwaige Entgeltkürzung erfolgt immer nach Arbeitstagen.<br/> <br/> 
                Berechne das anteilige Gehalt und das kalendertägliche Mutterschaftsgeld und erfasse die richtigen Werte bei den Lohnarten. <br/>
              </Typography>
          </ListItem>

          <BruttoNeu2
              anzReihen={2}
              tabellenKey="1" 
              Lohnart={['1029','1198']}
              Intern={['029','198']}
              Bezeichnung={["Gehalt","Muttersch. Geld"]}
              Tage={["",""]}
              Stunden={["","---"]}
              Faktor={["",""]}
              Kennung={["LFD","LFD"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["",""]}
              Sozialverspflichtig={["",""]}
              Betrag={["",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    A1.3
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Aufgabe 1.3"/>
          </ListItem>
          <ListItem>
              <Typography>
                  Erstelle den entsprechenden Erstattungsantrag
              </Typography>
          </ListItem>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Erstattungsantrag
            tabellenKey="1" 
            kkname="Beispiel BKK"
            kkstrasse="Versuchsgasse 7"
            kkort="71636 Ludwigsburg"
            vonerstattung="01.09.2020"
            biserstattung="30.09.2020"
            brutto=""
            netto=""
            kalnetto=""
            zuschuss=""
            erstattung=""
            entbindung=""
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}/>
          </Grid>
      </Grid>


      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    A2
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Aufgabe 2"/>
          </ListItem>
          <ListItem>
            
            <Typography>
                Die Arbeitnehmerin hat Ihr Kind am 17.10.2020 zur Welt gebracht. <br/>  
                Berechne die Mutterschutzfrist mit dem tatsächlichen Geburtstermin.<br/>  
            </Typography>
          </ListItem>

          <ErmittlungMutterschutzFrist
              tabellenKey="2"
              von=""
              bis=""
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    A3
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Aufgabe 3"/>
          </ListItem>
          <ListItem>
            
            <Typography>
                1. Abwandlung: <br/>
                Berechne die Mutterschutzfrist, wenn der tatsächliche Geburtstermin am 23.10.2020 war. <br/>  
            </Typography>
          </ListItem>

          <ErmittlungMutterschutzFrist
              tabellenKey="3"
              von=""
              bis=""
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    A4
                  </Avatar> 
              </ListItemAvatar>
            <ListItemText primary="Aufgabe 4"/>
          </ListItem>
          <ListItem>
            
            <Typography>
                2. Abwandlung: <br/>
                Welche Mutterschutzfrist steht der Arbeitnehmerin zu, wenn der tatsächlich Geburtstermin der 01.10.2020 war und es sich um eine medizinische Frühgeburt handelte. <br/>  
            </Typography>
          </ListItem>

          <ErmittlungMutterschutzFrist
              tabellenKey="4"
              von=""
              bis=""
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
        </Grid>
      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

</div>
)
}

export default FehlzeitMutterschutz;