import React, { useEffect } from 'react';
import {Grid, Typography, TextField, MenuItem} from '@material-ui/core';


function TabelleUmlage1 (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten, typ, arbz,} = props;


  useEffect(() => {
      setzeAntworten('arbeitszeiten'+tabellenKey,arbz)
    }, []);

    useEffect(() => {
      setzeAntworten('typ'+tabellenKey,typ)
    }, []);


  return(

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>

 
     <Grid item xs={4} style={{border:'solid',backgroundColor:"#BDBDBD"}}>
      <Typography>
        Typ
      </Typography>
    </Grid>
    <Grid item xs={4} style={{borderBottom:"solid",borderRight:"solid", borderTop:'solid', backgroundColor:"#BDBDBD"}}>
      <Typography>
        Wöchentlich Arbeitszeit
     </Typography>
    </Grid>

    <Grid item xs={4} style={{borderBottom:"solid",borderRight:"solid", borderTop:'solid', backgroundColor:"#BDBDBD"}}>
      <Typography>
        Anrechnungsfaktor
     </Typography>
    </Grid>


    <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
     <TextField
        id='typ'
//        label="Arbeitnehmer"
        fullWidth
        value={typ}
//        error={fehlerliste["anrechnungsfaktor1"+tabellenKey]}
//        style={{display:"flex"}}
//        onChange={(event) => setzeAntworten('anrechnungsfaktor'+tabellenKey,event.target.value)}
//        variant="inlined"
//        margin="normal"
        InputLabelProps={{
            shrink: true,
            readOnly: true,
        }}
        inputProps={{style:{textAlign:"center"}}}       
        >
      </TextField>             

    </Grid>

    <Grid item xs={4} style={{borderBottom:"solid",borderRight:"solid"}}>
    <TextField
        id='arbzeit'
//        label="Arbeitnehmer"
        fullWidth
        value={arbz+' h/Woche'}

//        error={fehlerliste["anrechnungsfaktor1"+tabellenKey]}
//        style={{display:"flex"}}
//        variant="outlined"
//        margin="normal"
        InputLabelProps={{
            shrink: true,
            readOnly: true,
        }}
        inputProps={{style:{textAlign:"center"}}}       
        >
      </TextField>             
    </Grid>
  
    <Grid item xs={4} style={{borderBottom:"solid",borderRight:"solid"}}>
      <TextField
        select // durch select wird DropDown initialisiert
        id={'anrechnungsfaktor'+tabellenKey} 
        fullWidth
        value={antworten['anrechnungsfaktor'+tabellenKey]||''}
        error={fehlerliste["anrechnungsfaktor"+tabellenKey]}
//        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('anrechnungsfaktor'+tabellenKey,event.target.value.toString())}
//        variant="filled"
//        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}       
        >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={25}>0,25</MenuItem>
        <MenuItem value={50}>0,5</MenuItem>
        <MenuItem value={75}>0,75</MenuItem>
        <MenuItem value={100}>1,00 </MenuItem>
      </TextField>             
    </Grid>

  </Grid>
   
  )
}






export {TabelleUmlage1};