import React from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


function VerdienstTab (props) {
  const { tabellenKey, fehlerliste, setzeAntworten} = props;

  return(

        <Grid container spacing={0} style={{}}>
            <Grid item xs={8}>
             <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:"center", textfloat:'center'}}>Monat</TableCell>
                            <TableCell style={{textAlign:"center", textfloat:'center'}}>lfd. Entgelt</TableCell>
                            <TableCell style={{textAlign:"center", textfloat:'center'}}>EGA</TableCell>
                            <TableCell style={{textAlign:"center", textfloat:'center'}}>Nettoentgelt</TableCell>
                            <TableCell style={{textAlign:"center", textfloat:'center'}}>Nettoentgelt ohne EGA</TableCell>
                        </TableRow> 
                    </TableHead>
                    <TableBody>
                       <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Januar</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,300.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,518.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Februar</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,170.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,432.20€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>März</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,340.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,544.40€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>April</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,290.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,511.40€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Mai</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,490.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,643.40€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Juni</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,400.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}>700.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,046.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}>1,584.00€</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Juli</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,500.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,650.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>August</TableCell>
                            <TableCell style={{textAlign:"center"}}>2,490.00€</TableCell>
                            <TableCell style={{textAlign:"center"}}> --- </TableCell>
                            <TableCell style={{textAlign:"center"}}>1,643.40€</TableCell>
                            <TableCell style={{textAlign:"center"}}>---</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        </Grid>



  )
}


export {VerdienstTab};