import React, {useState, useCallback} from 'react';
import {Avatar, Box, Button, Card, CardContent, CardMedia, Container, Typography, FormGroup, FormControlLabel, Checkbox, MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';


function ScheckFE1(props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('806');
    const [kurskennung, setKurskennung]=useState("ScheckMZ1");
    const [merkmal]=useState('FragenEntgelt1');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [fragenzaehler, setFragenzaehler]=useState(0)
    const Figur1 = '/media/Figur1.png';
    const ecschoolRahmen ='/media/ecschool Rahmen.png';
    const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';

    const setRedirect = (path) => {
      navigate(path);
    }




    const setzeAntworten = ( key,value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        forceUpdate();   
      }
    
      const fehlerAusLesen=(fehler)=>{
        let array=fehler.split(";");
        let fehlerzwis=[]
        array.map(
          // eslint-disable-next-line
          (objekt)=> {
            fehlerzwis[objekt]=true;    
          }
        );
        setFehlerliste(fehlerzwis);
      };
    
    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "kursabgabe",
          "merkmal": merkmal,
          "daten": antworten,
          "reactnummer": reactnummer,
          "frage":fragenzaehler,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
          fehlerAusLesen(response.data.fehler);

        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

return(
 <div>

 <Briefkopf  
    ueberschrift1="Fragen zur Entgeltabrechnung"
    ueberschrift2="Alles verstanden? Dann beantwortest Du die Fragen im handumdrehen." 
 />

 <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
   <ListItem>
     <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
                Wofür wird die Identifikationsnummer benötigt?"
            </Typography>
          </ListItemText>
        </ListItem>

    {/* <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}> */}
      <VariableCheckBox
        tabellenKey="1" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Anmeldung bei der Krankenkasse.",
                     "Abruf der Steuerdaten beim Finanzamt.",
                     "Für die Änderung der Anschrift.",
                     "Für die Übermittlung von Informationen an die Agentur für Arbeit"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    {/* </div> */}
    </Grid>


    <Grid style={{marginTop:'10px' }} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
                Wer muss einen Zuschlag zur Pflegeversicherung bezahlen?"
            </Typography>
          </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="2" 
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Alle Arbeitnehmerinnen und Arbeitnehmer",
                     "Alle kinderlosen Arbeitnehmerinnen und Arbeitnehmer",
                     "Alle kinderlosen Arbeitnehmerinnen und Arbeitnehmer, welche 23 Jahre und älter sind",
                     "Alle Arbeitnehmerinnen und Arbeitnehmer mit eingetragenen Kindern",
                     "Alle kinderlosen Arbeitnehmerinnen und Arbeitnehmer, welche 25 Jahre und älter sind"]}
        checked={[false,false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    </Grid>


    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F3
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welchen Beitragssatz bestimmt jede Krankenkasse individuell?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="3" 
        ueberschrift=""
        anzReihen={3}
        Checktexte={["Zusatzbeitrag zur Rentenversicherung",
                     "Zusatzbeitrag zur Pflegeversicherung",
                     "Zusatzbeitrag zur Krankenversicherung",]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F4
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welche Informationen stehen auf einer Lohnsteueranmeldung?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="4" 
        ueberschrift=""
        anzReihen={3}
        Checktexte={["Zahl der Arbeitnehmer",
                     "Lohnsteuer",
                     "Solidaritätszuschlag",]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F5
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wie ermittelt sich das Nettoentgelt?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="5"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Bruttoentgelt abzüglich Steuer und Nettoabzüge",
                     "Bruttoentgelt abzüglich Sozialversicherungsbeiträge zuzüglich Steuern",
                     "Bruttoentgelt abzüglich Steuer und Sozialversicherungsbeiträge",
                     "Bruttoentgelt abzüglich Steuer, Sozialversicherungsbeiträge und Nettoabzüge",]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F6
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welche Informationen gehen aus dem Beitragsgruppenschlüssel hervor?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="6"
        ueberschrift=""
        anzReihen={3}
        Checktexte={["Welcher Zweig der Sozialversicherung pflichtig ist",
                     "Bei welcher Krankenkasse der Beschäftigte versichert ist",
                     "Wie der Beschäftigte in der Sozialversicherung versichert ist",]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F7
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              An welche Institution übermittelt der Arbeitgeber die DEÜV Meldung?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="7"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Finanzamt",
                     "Deutsche Rentenversicherung",
                     "Krankenkasse",
                     "Agentur für Arbeit,"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F8
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welche Informationen stehen auf einer DEÜV Meldung?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="8"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Arbeitszeit",
                     "Beitragsgruppe",
                     "Beginn einer Beschäftigung",
                     "Elterneigenschaft",
                     "Krankenkasse",
                    ]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F9
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welche Eigenschaften haben Stammlohnarten?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="9"
        ueberschrift=""
        anzReihen={3}
        Checktexte={["Monatlich wiederkehrender und gleichbleibender Bezug",
                     "Monatlich wiederkehrender und abweichender Bezug",
                     "Monatlich variabler Bezug",]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />
    </div>
    )
}
export default ScheckFE1;
