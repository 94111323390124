import React, { useEffect } from 'react';
import {Grid, InputAdornment, Typography, TextField, MenuItem} from '@material-ui/core';



function Tabelle2Umlage1Erstattung (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten, name, gbdatum,strasse} = props;

  useEffect(() => {
    setzeAntworten('name'+tabellenKey,name)
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setzeAntworten('gbdatum'+tabellenKey,gbdatum)
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setzeAntworten('strasse'+tabellenKey,strasse)
    // eslint-disable-next-line
  }, []);

  return(


    <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left', border:'solid'}}  alignItems={"center"}>
          <Grid item xs={12} style={{}}>
              <Typography style={{textDecoration:'underline'}} variant="h6">
                  Angaben zur Erstattung
              </Typography>
          </Grid>
          <Grid item xs={12} style={{}}>
              <Typography style={{}}>
                  Grund der Abgabe
              </Typography>
          </Grid>
          <Grid item xs={7} style={{border:'solid'}}>
              <TextField
                select // durch select wird DropDown initialisiert
                id="Grund"
                style={{}}
                label="Grund der Abgabe auswählen "
                fullWidth
                error={fehlerliste["Grund"+tabellenKey]}
                value={antworten['Grund'+tabellenKey]||''}
                onChange={(event) => setzeAntworten('Grund'+tabellenKey,event.target.value)}
                variant="outlined"
                margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
                InputLabelProps={{
                    shrink: true,
                    style:{color:"#1fbba6"}
                }} >
                    <MenuItem value={1}>1. Erstattung der Aufwendungen bei Beschäftigungsverbot</MenuItem>
                    <MenuItem value={2}>2. Erstattung der Aufwendungen bei Lohnfortzahlung</MenuItem>
                    <MenuItem value={3}>3. Erstattung der Aufwendungen bei Mutterschutz</MenuItem>
                </TextField>          
          </Grid>
          <Grid item xs={5}>

          </Grid>
          <Grid item xs={3}>
            <Typography >
              Erstattungszeitraum von:
            </Typography>
          </Grid>
          <Grid item xs={2} style={{textAlign:'left' }}>
            <TextField 
                id="er-von" 
                label="von" style={{display:"inline-block"}} 
                onChange={(event) => setzeAntworten('er-von'+tabellenKey,event.target.value)}
                error={fehlerliste["er-von"+tabellenKey]}

                >
            </TextField><br/> 
          </Grid>
          <Grid item xs={7}>
          </Grid>
          <Grid item xs={3}>
            <Typography >
              Erstattungszeitraum bis:
            </Typography>
          </Grid>
          <Grid item xs={2} style={{textAlign:'left' }}>
            <TextField
              id="er-bis" 
              label="bis" style={{display:"inline-block"}} 
              onChange={(event) => setzeAntworten('er-bis'+tabellenKey,event.target.value)}
              error={fehlerliste["er-bis"+tabellenKey]}
                        >
            </TextField><br/> 
          </Grid>
          <Grid item xs={7}>
          </Grid>
        <Grid item xs={8} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
          <Typography style={{marginTop:'7px'}}>
            Fortgezahltes Bruttoarbeitsentgelt (ohne Einmalzahlungen)
          </Typography>
        </Grid>
        <Grid item xs={2} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
            <TextField  id="FortgezahltesBrutto" 
            type='number' 
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}} 
            placeholder=""  style={{display:"flex"}}
            onChange={(event) => setzeAntworten('FortgezahltesBrutto'+tabellenKey,event.target.value)}
            error={fehlerliste["FortgezahltesBrutto"+tabellenKey]}
            />
        </Grid>
        
        <Grid item xs={12} style={{}}> 
        </Grid>
        <Grid item xs={8} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
          <Typography style={{marginTop:'7px'}}>
            AG - Anteil zur SV
          </Typography>
        </Grid>
        
        <Grid item xs={2} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
            <TextField  
              disabled={true}
              id="AGanteil" 
              type='number' 
              label="nicht relevant" 
              inputProps={{style:{textAlign:"center"}}} 
              placeholder=""  style={{display:"flex"}}
 //             onChange={(event) => setzeAntworten('AGanteil'+tabellenKey,event.target.value)}
 //             error={fehlerliste["AGanteil"+tabellenKey]}
            />
        </Grid>

        <Grid item xs={12} style={{}}> 
        </Grid>
        <Grid item xs={8} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
          <Typography style={{marginTop:'7px'}}>
            Prozentsatz der Erstattung 
          </Typography>
        </Grid>
        
        <Grid item xs={2} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
            <TextField  
              id="prozerstattung" 
              type='number' 
              label="Eintrag" 
              inputProps={{style:{textAlign:"center"} }} 
              placeholder=""  style={{display:"flex"}}
              onChange={(event) => setzeAntworten('prozerstattung'+tabellenKey,event.target.value)}
              error={fehlerliste["prozerstattung"+tabellenKey]}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment> 
              }}
            />
        </Grid>



        <Grid item xs={12} style={{}}>
      
        </Grid>
        <Grid item xs={8} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
          <Typography style={{marginTop:'7px'}}>
            Erstattungsbetrag
          </Typography>
        </Grid>
      
        <Grid item xs={2} style={{height:'45px', backgroundColor:'#E8E8E9'}}>
            <TextField  
            type='number' 
            id="erstattung" 
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}} 
            placeholder=""  style={{display:"flex"}}
            onChange={(event) => setzeAntworten('erstattung'+tabellenKey,event.target.value)}
            error={fehlerliste["erstattung"+tabellenKey]}
            />
        </Grid>
    </Grid>
  
  )
}






export {Tabelle2Umlage1Erstattung};