import React from 'react';
import {Grid, Typography, TextField, MenuItem,Avatar} from '@material-ui/core';


function TabelleGelwerterVorteil (props) {
  const { tabellenKey, fehlerliste, antworten, setzeAntworten, aufgabe1, aufgabe2, titel1, titel2} = props;

  return(

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>

        
        <Grid item xs={1} style={{}}>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              {aufgabe1}
            </Avatar>
        </Grid>

    <Grid item xs={4} style={{border:'solid',backgroundColor:"#BDBDBD", height:'78px'}}>
      <Typography>
          {titel1}
      </Typography>
    </Grid>
    <Grid item xs={1} style={{}}>
    </Grid>
    <Grid item xs={1} style={{}}>
        <Avatar style={{backgroundColor:"#1fbba6",textAlign:'center'}}>
              {aufgabe2}
        </Avatar>
    </Grid>
    <Grid item xs={4} style={{border:'solid',backgroundColor:"#BDBDBD", height:'78px'}}>
      <Typography>
          {titel2}
      </Typography>
    </Grid>
    
    <Grid item xs={1} style={{}}>
           
    </Grid>
    <Grid item xs={1} style={{}}>
           
    </Grid>
   
    <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <TextField


        select // durch select wird DropDown initialisiert
        id={'antwort1'+tabellenKey} 
      //  label=" "
        fullWidth
        error={fehlerliste["antwort1"+tabellenKey]}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('antwort1'+tabellenKey,event.target.value)}
        variant="outlined"
        value={antworten['antwort1'+tabellenKey]||''}
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}       
        >
        <MenuItem value={'Steuerpflichtig'}>Steuerpflichtig</MenuItem>
        <MenuItem value={'Steuerfrei'}>Steuerfrei</MenuItem>
        
      </TextField> 
    </Grid>
    <Grid item xs={2} style={{}}>
   
    </Grid>
        <Grid item xs={4} style={{borderLeft:"solid",borderBottom:"solid",borderRight:"solid"}}>
      <TextField


        select // durch select wird DropDown initialisiert
        id={'antwort2'+tabellenKey} 
      //  label="Bezeichnung auswählen "
        fullWidth
        error={fehlerliste["antwort2"+tabellenKey]}
        style={{display:"flex"}}
        onChange={(event) => setzeAntworten('antwort2'+tabellenKey,event.target.value)}
        variant="outlined"
        value={antworten['antwort2'+tabellenKey]||''}  
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}       
        >
        <MenuItem value={'Steuerpflichtig'}>Steuerpflichtig</MenuItem>
        <MenuItem value={'Steuerfrei'}>Steuerfrei</MenuItem>
       
      </TextField> 
    </Grid>
    <Grid item xs={1} style={{}}>
    </Grid>


          

  </Grid>


   
  )
}






export {TabelleGelwerterVorteil};