import React from 'react';
import {Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';


function BruttoNettoBlock (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, bruttolfd, bruttoega, bruttogesamt, steuern, sozvers, agantAusz, netto, svFarbe} = props;

  var nettoFarbe=svFarbe

  if (bruttolfd !== '') {
      antworten["BRUTSUMLFD"+tabellenKey]=bruttolfd;
      var BRUTSUMLFDblockiert=true;
  }

  if (bruttoega !== '') {
    antworten["BRUTSUMEGA"+tabellenKey]=bruttoega;
    var BRUTSUMEGAblockiert=true;
  }

  if (bruttogesamt !== '') {
    antworten["BRUTGESAMT"+tabellenKey]=bruttogesamt;
    var BRUTGESAMTblockiert=true;
  }

  if (steuern !== '') {
    antworten["STEUERN"+tabellenKey]=steuern;
    var STEUERNblockiert=true;
  }

  if (sozvers !== '') {
    antworten["SVBETRAG"+tabellenKey]=sozvers;
    var SVBETRAGblockiert=true;
  }

  if (agantAusz !== '') {
    antworten["AUSZ"+tabellenKey]=agantAusz;
    var AUSZblockiert=true;
  }

  if (netto !== '') {
    antworten["NETTO"+tabellenKey]=netto;
    var NETTOblockiert=true;
  }

return(
    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Bezeichnung</TableCell>
                            <TableCell align="right">Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key="BruttoLfd">
                            <TableCell >
                                Summe Brutto LFD
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={BRUTSUMLFDblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["BRUTSUMLFD"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTSUMLFD"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["BRUTSUMLFD"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('BRUTSUMLFD'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow key="BruttoEGA" style={{borderBottom:'solid'}} >
                            <TableCell >
                                Summe Brutto EGA
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={BRUTSUMEGAblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["BRUTSUMEGA"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTSUMEGA"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["BRUTSUMEGA"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('BRUTSUMEGA'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
                        <TableRow key="BruttoGESAMT" >
                            <TableCell >
                                = Gesamtbrutto
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={BRUTGESAMTblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["BRUTGESAMT"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTGESAMT"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["BRUTGESAMT"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('BRUTGESAMT'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow key="STEUERN" >
                            <TableCell >
                                - Steuern
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={STEUERNblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["STEUERN"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["STEUERN"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["STEUERN"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('STEUERN'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow key="SVBETRAG" >
                            <TableCell >
                                - Sozialvers.
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe
                                    disabled={SVBETRAGblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["SVBETRAG"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["SVBETRAG"+tabellenKey]===true)?"red":svFarbe}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["SVBETRAG"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('SVBETRAG'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow key="AUSZ" style={{borderBottom:'solid'}} >
                            <TableCell >
                                + Auszahlung AG Anteil (*)
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={AUSZblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["AUSZ"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AUSZ"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["AUSZ"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('AUSZ'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow key="NETTO" >
                            <TableCell >
                                = NETTO
                            </TableCell>
                            <TableCell align="right" >
                                <Eingabe 
                                    disabled={NETTOblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["NETTO"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["NETTO"+tabellenKey]===true)?"red":nettoFarbe}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["NETTO"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('NETTO'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {BruttoNettoBlock};