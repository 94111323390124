import * as React from 'react'
import * as auth from '../auth-provider'
import {client} from '../utils/ApiClient'
import {useAsync} from '../utils/hooks'
import {FullPageSpinner, FullPageErrorFallback} from '../components/lib'
import {LOCAL_STORAGE_KEY} from '../constants/localStorage'

async function bootstrapAppData() {
  let user = null

  // TODO: Bei mobile device Refresh Token anfordern
  const token = await auth.getToken()
  const deviceId = localStorage.getItem(LOCAL_STORAGE_KEY.deviceId);
  if (token) {
    try {
      const data = await client('bootstrap', {data: {deviceId}, token})
      user = data.user
    } catch {
      console.log('Fehler')
    }
  }
  return user
}

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData()
    run(appDataPromise)
  }, [run])

  const login = React.useCallback(
    form => auth.login(form).then(user => setData(user)),
    [setData],
  )

  const loginAdmincode = React.useCallback(
    form => auth.loginAdmincode(form).then(user => setData(user)),
    [setData],
  )

  const tokenRefresh = React.useCallback(
    form => auth.refreshToken(form).then(user => setData(user)),
    [setData],
  )

  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
  }, [setData])

  const value = React.useMemo(() => ({user, login, loginAdmincode, logout, tokenRefresh}), [
    login,
    loginAdmincode,
    logout,
    user,
    tokenRefresh,
  ])

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context
}

function useClient() {
  const {user} = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, {...config, token})
    , [token],
  );
}

export {AuthProvider, useAuth, useClient}