import React, {useState, useCallback} from 'react';
import {Avatar,Typography,Button, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Aufbau Entgeltabrechnung Clip 2-3.mp4';
const MUSTERABRECHNUNG = '/media/Musterabrechnung.pdf';
import { TabelleLohnarten} from './tabelleL';
const SOZIWERTE = '/media/Sozialversicherungswerte 2020.pdf';
const POSTER = '/media/Grafik_Filmrolle.png';
const NICKELSTAMM = '/media/Nickel Stammblatt.pdf';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
const svblockbild = '/media/SvTabelleAlt.png';

function Entgelt2(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('002');
  const [merkmal]=useState('Entgelt2');


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        fehlerAusLesen(response.data.fehler);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Die Entgeltabrechnungsdaten und -werte auf einer Entgeltbescheinigung" 
          ueberschrift2="In diesem Kurs lernst Du den Brutto-Lohnarten-Block, den Lohnsteuer- und Sozialversicherungsblock, sowie den Netto-Ergebnisblock kennen." 
      />

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Dieses Video erklärt Dir folgende Themen der Entgeltbescheinigung: Bruttoverdienst anhand von Lohnarten, die sich ergebende Lohnsteuer und die Berechnung der Sozialversicherungsbeiträge"/>
          </ListItem>
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>

          <ListItem style={{marginTop:"35px"}}>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1.1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText >
               <b>Update Berechnung kassindividueller Zusatzbeitrag</b><br/>
               Zum Zeitpunkt der Erstellung des Videos wurde der kassindividueller Zusatzbeitrag allein vom Arbeitnehmer getragen.<br/>
               Ab 01.01.2019 werden die Beiträge zur Krankenversicherung wieder paritätisch finanziert. <br/>
               Das bedeutet, dass Arbeitnehmer und Arbeitgeber zu gleichen Teilen die kompletten Beiträge für die gesetzliche Krankenversicherung tragen.<br/>
               Somit teilen sich ab 01.01.2019 Arbeitnehmer und Arbeitgeber die Beitragskosten der GKV (Gesetzliche Krankenversicherung) zu genau gleichen Teilen.<br/><br/>
               <b>Der Beitragsblock zur Sozialversicherung sieht demnach ab 01.01.2019 wie folgt aus:</b>
            </ListItemText>
          </ListItem>
          <img src={svblockbild} height="240" alt="ectool" loading="lazy"/>

          <ListItem style={{marginTop:"35px"}}>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Berechnung der Lohnsteuer "/>
          </ListItem>
          <Typography 
              style={{paddingLeft:"70px",textAlign:"left"}}>
                Die Berechnung der Lohnsteuer erfolgt maschinell und kann heutzutage kaum noch von Hand nachgerechnet werden. Auf den Internetseiten des Bundesministerium für Finanzen
                werden Ablaufpläne für die Berechnung der Lohnsteuer zur Verfügung gestellt. Die Ablaufpläne sind in den jeweiligen Entgeltabrechnungsprogrammen zu implementieren.
                Um dennoch die Lohnsteuer manuell ermitteln zu können, empfiehlt es sich, dies über die Internetseite <strong>www.bmf-steuerrechner.de</strong> zu tun.
          </Typography>
          <ListItem style={{marginTop:"35px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Die Sozialversicherungswerte zeigen Dir Prozentsätze auf, die Du zur Berechnung der Beiträge für die Kranken-,
              Renten-,Arbeitslosen- und Pflegeversicherung benötigst."/>
            </ListItem>
            <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={SOZIWERTE}download>
                Sozialversicherungswerte 2020
              </Button>
            </Grid>

            <Grid item xs={12}style={{marginTop:"35px",marginBottom:"15px"}}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{backgroundColor:"#1fbba6"}}>
                    4
                  </Avatar> 
                </ListItemAvatar>
                <ListItemText>
                     Nimm Dir bitte das Stammblatt von Herrn Nickel zur Hand und entscheide dann, welche Lohnart Du verwendest.<br/> 
                     Fülle anschließend die erste Zeile des Lohnartenblocks aus. In der %-Spalte brauchst Du nichts eintragen. <br/> 
                     Zur Hilfe kannst Du Dir gerne die Musterabrechnung anschauen. <br/> 
                </ListItemText>
              </ListItem>
            </Grid>

            <Grid item xs={12} style={{marginTop:"5px",marginBottom:"5px"}}>
              <Button 
                  style={{marginRight:"15px"}}
                  color="primary"  
                  variant="contained"
  //              fullWidth
                  href={NICKELSTAMM}download>
                  Stammblatt Herr Nickel
              </Button>

              <Button 
                  color="primary"  
                  variant="contained"
  //              fullWidth
                  href={MUSTERABRECHNUNG}download>
                  Musterabrechnung
              </Button>
            </Grid>

        </Grid>

      </Grid>
  <div>
    <Grid container spacing={0}>
      <Grid item xs={12}>
      <TabelleLohnarten tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
      </Grid>

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              5
            </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <h3>Lohnsteuer- und Sozialversicherungsblock</h3>
                  Die Werte der Lohnsteuer sind in dem gelben Lohnsteuer-Block einzutragen. Ermittle die entsprechenden Beträge der Lohn- und Kirchensteuer und dem Solidaritätszuschlag über die in Punkt 2 aufgeführte Internetseite.
                  Verwende bei der Berechnung der Lohnsteuer das Jahr 2020.<br/> 
                  Die Beiträge zur Sozialversicherung sind im grünen Sozialversicherungsblock zu erfassen. Verwende für die Berechnung bitte die Sozialversicherungswerte 2020.<br/> 
                  Bei Zahlen mit mehreren Nachkommastellen führe die kaufmännische Rundung auf zwei Nachkommastellen durch.  <br/> 
            </ListItemText>
        </ListItem>

  {/*gelbes Berechnungsblock*/}

     </Grid>
        <Grid container spacing={1} style={{marginTop:"7px"}}>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div style={{borderTop:"solid",borderLeft:"solid"}}>
              <Typography style={{display:"inline-block",width:"40%",borderRight:"groove",backgroundColor:"yellow"}}>Bezeichnung</Typography>
              <Typography style={{display:"inline-block",width:"20%",borderRight:"groove",backgroundColor:"yellow"}}>LFD</Typography>
              <Typography style={{display:"inline-block",width:"20%",borderRight:"groove",backgroundColor:"yellow"}}>EGA</Typography>
              <Typography style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:"yellow"}}>PST</Typography>
            </div>
        <div style={{borderTop:"solid",borderLeft:"solid",}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Steuertage</Typography>
         <TextField
            style={{display:"inline-block",width:"20%",borderRight:"solid", backgroundColor:(fehlerliste["STAGELFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["STAGELFD"]||''}
            error={fehlerliste["STAGELFD"]}
            onChange={(event) => setzeAntworten('STAGELFD',event.target.value)}
         > </TextField>
         <TextField 
            style={{display:"inline-block",width:"20%",borderRight:"solid", backgroundColor:(fehlerliste["STAGEEGA"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["STAGEEGA"]||''}
            onChange={(event) => setzeAntworten('STAGEEGA',event.target.value)}
            > 
          </TextField>
          <TextField 
            style={{display:"inline-block",width:"20%",borderRight:"solid"}}
            > 
          </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid",}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Steuerpflichtig</Typography>
         <TextField  
            error={fehlerliste["STPFLLFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["STPFLLFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["STPFLLFD"]||''}
            onChange={(event) => setzeAntworten('STPFLLFD',event.target.value)}
            > 
            </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid",}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Steuerfreibetr.</Typography>
         <TextField  
            error={fehlerliste["STFREILFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["STFREILFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["STFREILFD"]||''}
            onChange={(event) => setzeAntworten('STFREILFD',event.target.value)}
            >
            </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid",}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Lohnsteuer</Typography>
         <TextField  
            error={fehlerliste["LSTLFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["LSTLFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["LSTLFD"]||''}
            onChange={(event) => setzeAntworten('LSTLFD',event.target.value)}
            >
         </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid",}}>
         <Typography 
          style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",minHeight:"32px",lineHeight:"32px"}}>Kirchenst.1</Typography>
         <TextField
            error={fehlerliste["KIST1LFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["KIST1LFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KIST1LFD"]||''}
            onChange={(event) => setzeAntworten('KIST1LFD',event.target.value)}
            >
         </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>

        <div style={{borderTop:"solid",borderLeft:"solid"}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Kirchenst.2</Typography>
         <TextField  
            error={fehlerliste["KIST2LFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["KIST2LFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KIST2LFD"]||''}
            onChange={(event) => setzeAntworten('KIST2LFD',event.target.value)}
            >
         </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Solz. </Typography>
         <TextField  
            error={fehlerliste["SOLZLFD"]}
            style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:(fehlerliste["SOLZLFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["SOLZLFD"]||''}
            onChange={(event) => setzeAntworten('SOLZLFD',event.target.value)}
            >
         </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid",borderBottom:"solid"}}>
         <Typography style={{display:"inline-block",width:"40%",borderRight:"solid",backgroundColor:"yellow",height:"",lineHeight:"32px"}}>Freibetr.mtl.</Typography>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
         <Typography style={{display:"inline-block",width:"20%",borderRight:"solid",backgroundColor:"yellow",height:"32px",lineHeight:"32px"}}>Jahr</Typography>
         <TextField  style={{display:"inline-block",width:"20%",borderRight:"solid"}}> </TextField>
        </div>
      </Grid>
     
      {/*Grünes berechnungs Fach*/}

      <Grid item xs={4}>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
         <Typography style={{display:"inline-block",width:"21%",borderRight:"groove",backgroundColor:"lightgreen"}}>Bez.</Typography>
         <Typography style={{display:"inline-block",width:"10%",borderRight:"groove",backgroundColor:"lightgreen"}}>Tage</Typography>
         <Typography style={{display:"inline-block",width:"23%",borderRight:"groove",backgroundColor:"lightgreen"}}>Pflichtig</Typography>
         <Typography style={{display:"inline-block",width:"23%",borderRight:"groove",backgroundColor:"lightgreen"}}>AN-Ant.</Typography>
         <Typography style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:"lightgreen"}}>AG-Ant.</Typography>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
         <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>KV LFD </Typography>
         <TextField  
            error={fehlerliste["KVLFDTAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["KVLFDTAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVLFDTAGE"]||''}
            onChange={(event) => setzeAntworten('KVLFDTAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVLFDTAGE"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVLFDTAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVLFDPFLICHT"]||''}
            onChange={(event) => setzeAntworten('KVLFDPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVLFDAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVLFDAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVLFDAN"]||''}
            onChange={(event) => setzeAntworten('KVLFDAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVLFDAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVLFDAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVLFDAG"]||''}
            onChange={(event) => setzeAntworten('KVLFDAG',event.target.value)}
            >
         </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>KV EGA </Typography>
         <TextField  
            error={fehlerliste["KVEGATAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["KVEGATAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVEGATAGE"]||''}
            onChange={(event) => setzeAntworten('KVEGATAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVEGAPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVEGAPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVEGAPFLICHT"]||''}
            onChange={(event) => setzeAntworten('KVEGAPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVEGAAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVEGAAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVEGAAN"]||''}
            onChange={(event) => setzeAntworten('KVEGAAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["KVEGAAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["KVEGAAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["KVEGAAG"]||''}
            onChange={(event) => setzeAntworten('KVEGAAG',event.target.value)}
            >
         </TextField>
        </div>

        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>RV LFD </Typography>
         <TextField  
            error={fehlerliste["RVLFDTAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["RVLFDTAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVLFDTAGE"]||''}
            onChange={(event) => setzeAntworten('RVLFDTAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVLFDPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVLFDPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVLFDPFLICHT"]||''}
            onChange={(event) => setzeAntworten('RVLFDPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVLFDAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVLFDAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVLFDAN"]||''}
            onChange={(event) => setzeAntworten('RVLFDAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVLFDAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVLFDAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVLFDAG"]||''}
            onChange={(event) => setzeAntworten('RVLFDAG',event.target.value)}
            >
         </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>RV EGA </Typography>
         <TextField  
            error={fehlerliste["RVEGATAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["RVEGATAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVEGATAGE"]||''}
            onChange={(event) => setzeAntworten('RVEGATAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVEGAPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVEGAPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVEGAPFLICHT"]||''}
            onChange={(event) => setzeAntworten('RVEGAPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVEGAAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVEGAAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVEGAAN"]||''}
            onChange={(event) => setzeAntworten('RVEGAAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["RVEGAAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["RVEGAAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["RVEGAAG"]||''}
            onChange={(event) => setzeAntworten('RVEGAAG',event.target.value)}
            >
         </TextField>
        </div>

        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>AV LFD </Typography>
         <TextField  
            error={fehlerliste["AVLFDTAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["AVLFDTAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVLFDTAGE"]||''}
            onChange={(event) => setzeAntworten('AVLFDTAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVLFDPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVLFDPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVLFDPFLICHT"]||''}
            onChange={(event) => setzeAntworten('AVLFDPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVLFDAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVLFDAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVLFDAN"]||''}
            onChange={(event) => setzeAntworten('AVLFDAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVLFDAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVLFDAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVLFDAG"]||''}
            onChange={(event) => setzeAntworten('AVLFDAG',event.target.value)}
            >
         </TextField>
        </div>
        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>AV EGA </Typography>
         <TextField  
            error={fehlerliste["ABEGATAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["AVEGATAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVEGATAGE"]||''}
            onChange={(event) => setzeAntworten('AVEGATAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVEGAPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVEGAPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVEGAPFLICHT"]||''}
            onChange={(event) => setzeAntworten('AVEGAPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVEGAAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVEGAAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVEGAAN"]||''}
            onChange={(event) => setzeAntworten('AVEGAAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["AVEGAAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["AVEGAAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AVEGAAG"]||''}
            onChange={(event) => setzeAntworten('AVEGAAG',event.target.value)}
            >
         </TextField>
        </div>

        <div style={{borderTop:"solid",borderLeft:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>PV LFD </Typography>
         <TextField  
            error={fehlerliste["PVLDFTAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["PVLFDTAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVLFDTAGE"]||''}
            onChange={(event) => setzeAntworten('PVLFDTAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVLFDPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVLFDPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVLFDPFLICHT"]||''}
            onChange={(event) => setzeAntworten('PVLFDPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVLFDAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVLFDAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVLFDAN"]||''}
            onChange={(event) => setzeAntworten('PVLFDAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVLFDAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVLFDAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVLFDAG"]||''}
            onChange={(event) => setzeAntworten('PVLFDAG',event.target.value)}
            >
         </TextField>
        </div>

        <div style={{borderTop:"solid",borderLeft:"solid",borderBottom:"solid"}}>
        <Typography style={{display:"inline-block",width:"21%",borderRight:"solid",backgroundColor:"lightgreen",height:"32px"}}>PV EGA </Typography>
         <TextField  
            error={fehlerliste["PVEGATAGE"]}
            style={{display:"inline-block",width:"10%",borderRight:"solid",backgroundColor:(fehlerliste["PVEGATAGE"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVEGATAGE"]||''}
            onChange={(event) => setzeAntworten('PVEGATAGE',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVEGAPFLICHT"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVEGAPFLICHT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVEGAPFLICHT"]||''}
            onChange={(event) => setzeAntworten('PVEGAPFLICHT',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVEGAAN"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVEGAAN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVEGAAN"]||''}
            onChange={(event) => setzeAntworten('PVEGAAN',event.target.value)}
            >
         </TextField>
         <TextField  
            error={fehlerliste["PVEGAAG"]}
            style={{display:"inline-block",width:"23%",borderRight:"solid",backgroundColor:(fehlerliste["PVEGAAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["PVEGAAG"]||''}
            onChange={(event) => setzeAntworten('PVEGAAG',event.target.value)}
            >
         </TextField>

        </div>
      </Grid>
      <Grid item xs={2}></Grid>


      {/*BRUTTO-NETTO-BLOCK*/}

      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              6
            </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <h3>Brutto - Netto - Block</h3>
                  Fülle zu guter Letzt den Brutto-Netto-Block mit Deinen errechneten Werten aus.<br/> 
            </ListItemText>
        </ListItem>

      <Grid item xs={4}></Grid>
      <Grid item xs ={4} style={{border:"solid",marginTop:"4px"}}>
        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left"}}>Summe Brutto LFD: </Typography>
        <TextField 
            error={fehlerliste["BRUTSUMLFD"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTSUMLFD"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["BRUTSUMLFD"]||''}
            onChange={(event) => setzeAntworten('BRUTSUMLFD',event.target.value)}
            >
         </TextField>

         <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left"}}>Summe Brutto EGA: </Typography>
        <TextField 
            error={fehlerliste["BRUTSUMEGA"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTSUMEGA"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["BRUTSUMEGA"]||''}
            onChange={(event) => setzeAntworten('BRUTSUMEGA',event.target.value)}
            >
         </TextField>

        <div style={{border:"solid",width:"100%",marginTop:"15px"}}></div>


        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left"}}>= Gesamtbrutto: </Typography>
        <TextField 
            error={fehlerliste["BRUTGESAMT"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["BRUTGESAMT"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["BRUTGESAMT"]||''}
            onChange={(event) => setzeAntworten('BRUTGESAMT',event.target.value)}
            >
         </TextField>

        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left"}}>- Steuern: </Typography>
        <TextField 
            error={fehlerliste["STEUERN"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["STEUERN"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["STEUERN"]||''}
            onChange={(event) => setzeAntworten('STEUERN',event.target.value)}
            >
         </TextField>

        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left",marginTop:"7px"}}>- Sozialversich. </Typography>
        <TextField 
            error={fehlerliste["SVBETRAG"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["SVBETRAG"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["SVBETRAG"]||''}
            onChange={(event) => setzeAntworten('SVBETRAG',event.target.value)}
            >
         </TextField>

        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left",marginTop:"7px"}}>+ Auszahlung <br/>AG Anteil (*)</Typography>
        <TextField 
            error={fehlerliste["AUSZ"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["AUSZ"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["AUSZ"]||''}
            onChange={(event) => setzeAntworten('AUSZ',event.target.value)}
            >
         </TextField>

        <div style={{border:"solid",width:"100%",marginTop:"15px"}}></div>

        <Typography style={{display:"inline-block",width:"40%",height:"32px",float:"left",marginTop:"5px"}}>= NETTO </Typography>
        <TextField 
            error={fehlerliste["NETTO"]}     
            style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["NETTO"]===true)?"red":""}}
            inputProps={{style:{textAlign:"center"}}}
            value={antworten["NETTO"]||''}
            onChange={(event) => setzeAntworten('NETTO',event.target.value)}
            >
         </TextField>
      </Grid>
    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
    </div>
     </div>
    )
}


export default Entgelt2;