import React from 'react';
import {Button, Grid, Typography } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { SupportButton } from '../Support/support';



function Kursabgeben (props) {
  const {testabgabeken, abgabedat, abgabezeit, antwortenSenden, anzahlFehler, bezeichnung, andererText, bemerkungen, reactnummer, merkmal} = props;
  const navigate=useNavigate();

  let text=""
  let tips=""
  let dkennung=false
  let textBtn="Testaufgaben abgeben"
  let zusatztxt=''


    if (bemerkungen !== undefined) {
      zusatztxt = bemerkungen
    }

    const ausgabeZusatz=zusatztxt.split("^").map((objekt, count)=>{
      return (
          <Typography key={count} style={{textAlign:"left"}}>
              {objekt}
          </Typography>
      )
  }
  )

    if (bezeichnung !== undefined) {
      textBtn=bezeichnung
    }
    
    if (anzahlFehler==='0'){
       if (testabgabeken === true) {dkennung=true} 
       text=<strong>Prima! Du hast keinen Fehler gemacht!</strong>
       tips=""
       if (andererText !== undefined) {
          text=andererText
       }
      }else{
       text=<strong>Du hast {anzahlFehler} Fehler gemacht.</strong>
       tips="Deine Fehler erkennst Du an den rot markierten Eingabefeldern."
    }

return(

    <Grid container spacing={0} style={{marginTop:"25px",marginBottom:"25px"}} justifyContent="center">
        {(testabgabeken === true) && 
            <Grid item xs={12} style={{border:"solid", borderColor:"#1fbba6"}}>
                <Typography>
                    Du hast den Kurs am {abgabedat} um {abgabezeit} übermittelt. <br />
                </Typography>        
                <Typography>
                  {text}<br/>
                  {tips}
                </Typography>
          </Grid>
        }

        {(zusatztxt !== '') && 
            <Grid item xs={12} style={{marginTop:"25px"}} >
                {ausgabeZusatz}
            </Grid>
        }

        <Grid item xs={2} >
        </Grid>
        <Grid item xs={8}style={{marginTop:"15px",marginBottom:"15px"}}>
            <Button 
                disabled={dkennung}
                color="primary"  
                variant="contained"
                fullWidth
                onClick={(event)=> antwortenSenden()}
                >
                {textBtn}
              </Button>
        </Grid>
        <Grid item xs={2} >
        </Grid>


        {(dkennung === true) &&
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={()=>{
                localStorage.removeItem('dataCourse'); 
                navigate(-1)
              }}
              >
              Zurück zum Verteiler
            </Button>
          </Grid>
        }
        <Grid item xs={12} style={{marginTop:"15px",marginBottom:"15px"}}>
            <SupportButton reactnummer={reactnummer} merkmal={merkmal}/>
        </Grid>
    </Grid>
)
}


export {Kursabgeben};