import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../Rahmendaten/Kursabgeben';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckDez1 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('811');
    const [kurskennung, setKurskennung]=useState("ScheckDez1");
    const [merkmal]=useState('Urlaubsabgeltung');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);

        }
      }
    }


    const setzeAntworten = ( key,value) => {
      let values = Object.assign(antworten);
      values[key] = value;
      setAntworten(values);
      forceUpdate();   
    }

    const antwortenSenden = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursabgabe",
        "merkmal": merkmal,
        "daten": antworten,
        "reactnummer": reactnummer,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  
    }
  
    const kurseCb = (response) => {
      if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
        fehlerAusLesen(response.data.fehler);

      } else {
        if (response) {
          props.showError(response.data.message);
        } else {
          props.showError('Keine Internetverbindung');
        }
      }
    }

    const fehlerAusLesen=(fehler)=>{
      let array=fehler.split(";");
      let fehlerzwis=[]
      array.map(
        // eslint-disable-next-line
        (objekt)=> {
          fehlerzwis[objekt]=true;    
        }
      );
      setFehlerliste(fehlerzwis);
    };


    const DokumentOeffnen = (docnr, docbez, docendung) => {
      const payload={
        "kennung": "kursdaten",
        "kurskennung": "download_lohndaten",
        "reactnummer": reactnummer,
        "docnr":docnr,
        "docname": docbez,
        "hsutkey": hsutkey
      }
      const headers={
        "Authorization": "Bearer "+accessToken,
        "Accept": "application/pdf"
      }
      axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
        .then((response) => {
          if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
          var contentFile = response.data;
          const mimeType = response.headers['content-type'] || 'application/pdf'
          openFile(fileName, contentFile,mimeType);
        })
        .catch(function (error) {
          showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
        })
    }

return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im vierten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h5' align="left">
            In diesem Kurs lernst Du die Abgeltung von Resturlaub zu berechnen, falls der Mandant den Auftrag erteilt, den restlichen Urlaub eines Arbeitnehmers abzugelten.
        </Typography>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>

    <Fragment>
    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Hw
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Abrechnungsmonat 04 Dezember-1
            </Typography>

          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              H1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Was hat es mit Resturlaub auf sich und wie wird Resturlaub ausbezahlt bzw. vergütet?<br/>
                Löse die Aufgaben mit Hilfe der Dir zur Verfügung stehenden Beschreibung. <br/>
                Viel Erfolg!
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'20px'} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Aufgabe 1<br/>
                Eine Arbeitnehmerin scheidet zum 31.12. aus der Firma aus.<br/>
                Aus persönlichen Gründen konnte sie den ihr noch zustehenden Urlaub von 3 Tagen vor dem Ausscheiden aus der Firma nicht mehr nehmen.<br/>
                Der Resturlaub soll mit der Dezember Entgeltabrechnung abgegolten werden.<br/>
                Ein zusätzliches Urlaubsgeld wird in der Firma generell nicht vergütet. <br/>
                Berechne die Urlaubsabgeltung anhand dem vorliegenden Bruttoblocks und fülle die Lohnart mit den richtigen Werten. <br/>
                Beachte dabei, dass die Lohnart Urlaubsabgeltung einen Tagesfaktor benötigt. Somit ist der zu vergütende Tagesfaktor zu berechnen und diesen mit den abzugeltenden Tagen zu multiplizieren.<br/>
                Der Tagesfaktor ist auf zwei Stellen kaufmännisch zu runden.
            </Typography>
          </ListItemText>
        </ListItem>
        <BruttoNeu2
              tabellenKey="1" 
              anzReihen={2}
              Lohnart={['1029','1417']}
              Intern={['029','417']}
              Bezeichnung={["Gehalt","Urlaubsabgelt."]}
              Tage={["21,75",""]}
              Stunden={["174,00","---"]}
              Faktor={["2900,00",""]}
              Kennung={["LFD","EGA"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2900,00",""]}
              Sozialverspflichtig={["2900,00",""]}
              Betrag={["2900,00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'50px'} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Aufgabe 2<br/>
                Eine Arbeitnehmer scheidet zum 30.11. aus der Firma aus.<br/>
                Aus persönlichen Gründen konnte er den ihm noch zustehenden Urlaub von 2 Tagen vor dem Ausscheiden aus der Firma nicht mehr nehmen.<br/>
                Der Resturlaub soll mit der November Entgeltabrechnung abgegolten werden.<br/>
                Ein zusätzliches Urlaubsgeld wird in der Firma generell mit dem Abrechnungsmonat Juni vergütet. <br/>
                Berechne die Urlaubsabgeltung anhand dem vorliegenden Bruttoblocks und fülle die Lohnart mit den richtigen Werten. <br/>
            </Typography>
          </ListItemText>
        </ListItem>
        <BruttoNeu2
              tabellenKey="2" 
              anzReihen={2}
              Lohnart={['1029','1417']}
              Intern={['029','417']}
              Bezeichnung={["Gehalt","Urlaubsabgelt."]}
              Tage={["21,75",""]}
              Stunden={["174,00","---"]}
              Faktor={["2850,00",""]}
              Kennung={["LFD","EGA"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2850,00",""]}
              Sozialverspflichtig={["2850,00",""]}
              Betrag={["2850,00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'50px'} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A3
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Aufgabe 3<br/>
                Eine Arbeitnehmerin scheidet zum 30.11. aus der Firma aus.<br/>
                Aus persönlichen Gründen konnte sie den ihr noch zustehenden Urlaub von 4 Tagen vor dem Ausscheiden aus der Firma nicht mehr nehmen.<br/>
                Der Resturlaub soll mit der November Entgeltabrechnung abgegolten werden.<br/>
                Mit jedem genommenen Urlaubstag bekommt sie 50% Urlaubsgeld vergütet.<br/>
                Berechne die Urlaubsabgeltung anhand dem vorliegenden Bruttoblocks und fülle die Lohnart mit den richtigen Werten. 
            </Typography>
          </ListItemText>
        </ListItem>
        <BruttoNeu2
              tabellenKey="3" 
              anzReihen={2}
              Lohnart={['1029','1417']}
              Intern={['029','417']}
              Bezeichnung={["Gehalt","Urlaubsabgelt."]}
              Tage={["21,75",""]}
              Stunden={["174,00","---"]}
              Faktor={["3100,00",""]}
              Kennung={["LFD","EGA"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["3100,00",""]}
              Sozialverspflichtig={["3100,00",""]}
              Betrag={["3100,00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'50px'} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A4
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Aufgabe 4<br/>
                Eine Arbeitnehmerin scheidet zum 31.10. aus der Firma aus und wird nach tatsächlich gearbeiteten Stunden abgerechnet.<br/>
                Im September hat die Arbeitnehmerin 5 Tage Urlaub genommen. Dieser wurde auch über die Entgeltabrechnung September abgerechnet. Der September hatte folgende Abrechnungswerte:<br/>
                22 Arbeitstage, davon<br/>
                - an 17 Tagen gearbeitet<br/>
                - 5 Tage Urlaub<br/>
                Der Bruttoblock auf der Entgeltabrechnung sieht wie folgt aus:
            </Typography>
          </ListItemText>
        </ListItem>
        <BruttoNeu2
              tabellenKey="4" 
              anzReihen={3}
              Lohnart={['1009','1073','1075']}
              Intern={['009','073','075']}
              Bezeichnung={["Stundenlohn",'Urlaubslohn','Urlaubszuschl.']}
              Tage={["17,00","5,00","5,00"]}
              Stunden={["136,00","40,00","40,00"]}
              Faktor={["19,50","19,50","19,50"]}
              Kennung={["LFD","LFD","EGA"]}
              Prozent={["0,00","0,00","50,00"]}
              Steuerpflichtig={["2652,00","780,00","390,00"]}
              Sozialverspflichtig={["2652,00","780,00","390,00"]}
              Betrag={["2652,00","780,00","390,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

      </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'50px'} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A4.1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Aufgabe 4.1<br/>
                Die Entgeltdaten für die Oktober Entgeltabrechnung lauten wie folgt:
                Erstelle den Bruttoblock für die Oktober Abrechnung.
                Die Arbeitnehmerin scheidet zum 31.10. aus der Firma aus und wird nach tatsächlich gearbeiteten Stunden abgerechnet.<br/>
                Aus persönlichen Gründen konnte sie den ihr noch zustehenden Urlaub von 3 Tagen vor dem Ausscheiden aus der Firma nicht mehr nehmen.<br/>
                Der Resturlaub soll mit der Oktober Entgeltabrechnung abgegolten werden.<br/>
                Der Oktober hat folgende Abrechnungswerte:<br/>
                21 Arbeitstage, davon<br/>
                - an 20 Tagen gearbeitet<br/>
                - 1 Feiertag<br/>
                Zusätzlich sollen 3 Tage Resturlaub abgegolten werden<br/>
                Bitte ergänze den Bruttoblock auf der Oktober Entgeltabrechnung.
            </Typography>
          </ListItemText>
        </ListItem>
        <BruttoNeu2
              tabellenKey="5" 
              anzReihen={3}
              Lohnart={['1009','1052','1417']}
              Intern={['009','052','417']}
              Bezeichnung={["Stundenlohn","Feiertagslohn",'Urlaubsabgelt.']}
              Tage={["","","",""]}
              Stunden={["","","---"]}
              Faktor={["19,50","19,50",""]}
              Kennung={["LFD","LFD","EGA"]}
              Prozent={["0,00","0,00","0,00"]}
              Steuerpflichtig={["","",""]}
              Sozialverspflichtig={["","","",""]}
              Betrag={["","","",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 
      </Grid>
    </Grid>



    </Fragment>

  {
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={6}>
              <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Zu erfassende Lohndaten in einem neuen Tab anzeigen!
                </Button>

              </Card>
            </Grid>
          )
        })
    }

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>      

    </div>
 
    )
  }

export default ScheckDez1;