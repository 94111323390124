import React, { useEffect, useState } from 'react';
import {Grid, TextField,Typography, Avatar, MenuItem, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {Eingabe} from './Eingaben';



function Deuev (props) {
  const {tabellenKey, Grund, Name, Vorname, Strasse, HausNr, PLZ, Ort, VersNr, GebDat, VonDat, BisDat, PG, Währung, Betrag, setzeAntworten, antworten, fehlerliste, kkname} = props;
  const [grund, setGrund]=useState('');

  const [grundblockiert, setGrundblockiert]=useState(false);
  const [nameblockiert, setNameblockiert]=useState(false);
  const [vornameblockiert, setVornameblockiert]=useState(false);
  const [strasseblockiert, setStrasseblockiert]=useState(false);
  const [nummerblockiert, setNummerblockiert]=useState(false);
  const [plzblockiert, setPlzblockiert]=useState(false);
  const [ortblockiert, setOrtblockiert]=useState(false);
  const [vnrblockiert, setVnrblockiert]=useState(false);
  const [gdatumblockiert, setGdatumblockiert]=useState(false);
  const [vonDatblockiert, setVonDatblockiert]=useState(false);
  const [bisDatblockiert, setBisDatblockiert]=useState(false);
  const [pgblockiert, setPGblockiert]=useState(false);
  const [waehrungblockiert, setWaehrungblockiert]=useState(false);
  const [betragblockiert, setBetragblockiert]=useState(false);

  console.log("versnr: ",VersNr)
  console.log("gebdat: ",GebDat)

  useEffect(()=>{
    if (Grund !== '') {
        antworten["Grund"+tabellenKey]=Grund;
        setGrund(Grund);
        setGrundblockiert(true);
    }

    if (Name !== '') {
        antworten["Name"+tabellenKey]=Name;
        setNameblockiert(true);
    }
    if (Vorname !== '') {
        antworten["Vorname"+tabellenKey]=Vorname;
        setVornameblockiert(true);
    }
    if (Strasse !== '') {
        antworten["Strasse"+tabellenKey]=Strasse;
        setStrasseblockiert(true);
    }
    if (HausNr !== '') {
        antworten["HausNr"+tabellenKey]=HausNr;
        setNummerblockiert(true);
    }
    if (PLZ !== '') {
        antworten["PLZ"+tabellenKey]=PLZ;
        setPlzblockiert(true);
    }
    if (Ort !== '') {
        antworten["Ort"+tabellenKey]=Ort;
        setOrtblockiert(true);
    }
    if (VersNr !== '') {
      antworten["VersNr"+tabellenKey]=VersNr;
      console.log("versnr: ",antworten["VersNr"+tabellenKey])
      setVnrblockiert(true);
    }
    if (GebDat !== '') {
      antworten["GebDat"+tabellenKey]=GebDat;
      setGdatumblockiert(true);
    }

    if (VonDat !== '') {
      antworten["VonDat"+tabellenKey]=VonDat;
      setVonDatblockiert(true);
    }

    if (BisDat !== '') {
      antworten["BisDat"+tabellenKey]=BisDat;
      setBisDatblockiert(true);
    }

    if (PG !== '') {
      antworten["PG"+tabellenKey]=PG;
      setPGblockiert(true);
    }

    if (Währung !== '') {
      antworten["Waehrung"+tabellenKey]=Währung;
      setWaehrungblockiert(true);
    }

    if (Betrag !== '') {
      antworten["Betrag"+tabellenKey]=Betrag;
      setBetragblockiert(true);
    }


  },[])
  

return(
  <dev>
    <Grid item xs={12} style={{border:"solid", marginTop:'30px'}} >
        <Typography> 
            Meldebescheinigung nach §25 DEÜV zur Sozialversicherung für den Arbeitnehmer
        </Typography>
    </Grid>
    <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left',borderTop:'solid', borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}  alignItems={"center"}>
        <Grid item xs={2} style={{}}>
          <Typography style={{}}>
              Grund der Abgabe
          </Typography>
        </Grid>
        <Grid item xs={10} style={{}}>
           
            <TextField
                select // durch select wird DropDown initialisiert
                disabled={grundblockiert}
                id="Grund"
                style={{color:"black"}}
                label="Grund der Abgabe auswählen "
                fullWidth
                error={fehlerliste["Grund"+tabellenKey]}
                value={antworten['Grund'+tabellenKey]||''}
                onChange={(event) => setzeAntworten('Grund'+tabellenKey,event.target.value)}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                    style:{color:"#1fbba6"}
                }} >
                <MenuItem value={10}>10 = Anmeldung wegen Beginn einer Beschäftigung</MenuItem>
                <MenuItem value={11}>11 = Anmeldung wegen Krankenkassenwechsel</MenuItem>
                <MenuItem value={12}>12 = Anmeldung wegen Beitragsgruppenwechsel</MenuItem>
                <MenuItem value={13}>13 = Anmeldung wegen sonstigen Gründe</MenuItem>
                <MenuItem value={30}>30 = Ende der Beschäftigung</MenuItem>
                <MenuItem value={31}>31 = Abmeldung wegen Krankenkassenwechsel</MenuItem>
                <MenuItem value={32}>32 = Abmeldung wegen Beitragsgruppenwechsel</MenuItem>
                <MenuItem value={33}>33 = Abmeldung wegen Sonstige Gründe</MenuItem>
                <MenuItem value={50}>50 = Jahresmeldung</MenuItem>
                <MenuItem value={51}>51 = Unterbrechnung Bezug von Entgeltersatzleistungen</MenuItem>
                <MenuItem value={52}>52 = Unterbrechung wegen Elternzeit</MenuItem>

            </TextField>          
        </Grid>
    </Grid>
    <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
  <Grid item xs={5}style={{borderBottom:'solid', borderRight:'solid', borderLeft:'solid',borderTop:'solid',  textAlign:'left'}}>
   <Typography >
    Herrn/ Frau <br/> 
   </Typography>  

    <TextField 
        disabled={nameblockiert}
        id="NAME" 
        label="Name" 
        value={antworten['Name'+tabellenKey]||''} 
        error={fehlerliste["Name"+tabellenKey]} 
        InputLabelProps={{ style: {color:'#1fbba6'},}} borderColor="green"
        style={{display:"inline-block", backgroundColor:(fehlerliste["Name"+tabellenKey]===true)?"red":""}} 
        onChange={(event) => setzeAntworten('NAME'+tabellenKey,event.target.value)}
        >
    </TextField>

    <TextField 
        disabled={vornameblockiert}
        id="VORNAME" 
        label="Vorname" 
        value={antworten['Vorname'+tabellenKey]||''} 
        error={fehlerliste["Vorname"+tabellenKey]}
        InputLabelProps={{ style: {color:'#1fbba6'},}}
        style={{display:"inline-block"}}
        onChange={(event) => setzeAntworten('Vorname'+tabellenKey,event.target.value)}
        >
    </TextField><br/> 
    <TextField 
        disabled={strasseblockiert}
        id="STRASSE" 
        label="Strasse" 
        value={antworten['Strasse'+tabellenKey]||''} 
        error={fehlerliste["Strasse"+tabellenKey]} 
        InputLabelProps={{ style: {color:'#1fbba6'},}}style={{display:"inline-block"}}
        onChange={(event) => setzeAntworten('Strasse'+tabellenKey,event.target.value)}
        >
    </TextField>
    <TextField 
        disabled={nummerblockiert}
        id="NUMMER"  
        label="HausNr"  
        value={antworten['HausNr'+tabellenKey]||''} 
        error={fehlerliste["HausNr"+tabellenKey]} 
        InputLabelProps={{ style: {color:'#1fbba6'},}} style={{display:""}}
        onChange={(event) => setzeAntworten('HausNr'+tabellenKey,event.target.value)}
        >
    </TextField><br/>
    <TextField 
        disabled={plzblockiert}
        id="PLZ" 
        label="PLZ" 
        value={antworten['PLZ'+tabellenKey]||''} 
        error={fehlerliste["PLZ"+tabellenKey]} InputLabelProps={{ style: {color:'#1fbba6'},}} 
        style={{display:"inline-block"}}
        onChange={(event) => setzeAntworten('PLZ'+tabellenKey,event.target.value)}
        >
    </TextField>
    <TextField 
      disabled={ortblockiert}
      id="ORT" 
      label="Ort" 
      value={antworten['Ort'+tabellenKey]||''} 
      error={fehlerliste["Ort"+tabellenKey]} InputLabelProps={{ style: {color:'#1fbba6'},}} 
      style={{display:"inline-block"}}
      onChange={(event) => setzeAntworten('Ort'+tabellenKey,event.target.value)}
      >
    </TextField>
  </Grid>
  <Grid item xs={1}>

  </Grid>
  <Grid item xs={6}style={{}}>
   <Typography style={{fontWeight:'bold'}}>
    Name und Anschrift des Arbeitgebers
   </Typography>  
   <Typography>
    Firma <br/>
    ectool (Lohn macht Spaß)<br/>
    Max-Eyth-Str. 8 <br/>
    71332 Waiblingen <br/>
   </Typography>
   <Typography style={{marginTop:'20px'}}>
     <strong>Erstelldatum</strong> 20.01.2020
   </Typography>

 
   <Grid item xs={12}style={{}}>
   <div style={{borderBottom:"solid",borderTop:'solid',borderLeft:'solid', borderRight:'solid'}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
        Arbeitgeber-Betriebsnummer:
      </Typography>
      <TextField
        disabled
        id="standard-basic" 
        label="" 
        placeholder="99999011" 
        style={{width:"50%",display:"flex",bordertop:"solid"}}
//                onChange={(event) => setzeAntworten('AgB',event.target.value)}
      />
   </div>
   <div style={{borderLeft:'solid', borderRight:'solid'}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px",backgroundColor:"#1fbba6"}}>
        Versicherungsnummer:
      </Typography>
      <TextField
        disabled={vnrblockiert}
        id="standard-basic"
        label="" 
        value={antworten["VersNr"+tabellenKey]||''}
        placeholder="Eingabe"  
        style={{width:"50%",display:"flex",bordertop:"solid", backgroundColor:(fehlerliste["VersNr"+tabellenKey]===true)?"red":""}}
        onChange={(event) => setzeAntworten('VersNr'+tabellenKey,event.target.value)}
        error={fehlerliste["VersNr"]}
      />
   </div>
   <div style={{borderBottom:"solid",borderTop:'solid',borderLeft:'solid', borderRight:'solid'}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px", backgroundColor:"#1fbba6"}}>
        Geburtsdatum:
      </Typography>
      <TextField
        disabled={gdatumblockiert}
        type='date'
        id="standard-basic" 
        label=""
        value={antworten["GebDat"+tabellenKey]||''}
        error={fehlerliste["GebDat"+tabellenKey]}
        placeholder="Eingabe"
        style={{width:"50%",display:"flex", backgroundColor:(fehlerliste["GebDat"+tabellenKey]===true)?"red":""}}
        onChange={(event) => setzeAntworten('GebDat'+tabellenKey,event.target.value)}
      />
   </div>

    </Grid>
  </Grid>
</Grid>
  <Grid item xs={12} style={{}}>
    <Typography style={{float:'left',lineHeight:"32px"}}>
      
    </Typography>
  </Grid>
<Grid container spacing={1} style={{marginTop:'30px',  textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
  <Grid item xs={2} style={{}}>
    <Typography style={{float:'left',lineHeight:"32px"}}>
      Besonderer Meldehinweis:   
    </Typography>
  </Grid>
  <Grid item xs={10} style={{}}>
    <TextField disabled id="standard-basic" label="" placeholder="" fullWidth  style={{display:"flex",borderTop:"solid",borderRight:"solid",borderLeft:'solid', borderBottom:'solid'}}onChange={(event) => setzeAntworten('bMHw'+tabellenKey,event.target.value)}/>
  </Grid>
  <Grid item xs={12} style={{fontWeight:'bold'}}>
    <p style={{float:'left',fontSize:"1,5em"}}>
        Beschäftigungszeit
    </p>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography >
       Von
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
   <Typography>
     Bis
   </Typography>
  </Grid>
  <Grid item xs={6} style={{}}>
   <Typography>
     Personengruppe 
   </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
   <Typography>
     Mehrfachbeschäftigt 
   </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
  <div style={{}}>
  <TextField 
      disabled={vonDatblockiert}
      type='date' 
      id="von" 
      variant="outlined" 
      label="Von " 
      error={fehlerliste["VonDat"+tabellenKey]}
      margin='normal'
      placeholder=""  
      style={{display:"", backgroundColor:(fehlerliste["VonDat"+tabellenKey]===true)?"red":""}}
      onChange={(event) => setzeAntworten('VonDat'+tabellenKey,event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}
        }}/>              </div>
  </Grid>
  <Grid item xs={2} style={{}}>
  <div style={{}}>
  <TextField  
      disabled={bisDatblockiert}
      type='date' 
      id="bis" 
      variant="outlined" 
      error={fehlerliste["BisDat"+tabellenKey]}
      label="Bis" 
      margin='normal'
      placeholder=""  
      style={{display:""}}
      onChange={(event) => setzeAntworten('BisDat'+tabellenKey,event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}/>    
   </div>
  </Grid>
  <Grid item xs={6} style={{}}>
    <TextField
      select // durch select wird DropDown initialisiert
      disabled={pgblockiert}
      id="PG"
      label="Personengruppe auswählen "
      fullWidth
      error={fehlerliste["PG"+tabellenKey]}
      value={antworten['PG'+tabellenKey]||''}
      style={{display:"", backgroundColor:(fehlerliste["PG"+tabellenKey]===true)?"red":""}}
      onChange={(event) => setzeAntworten('PG'+tabellenKey,event.target.value)}
      variant="outlined"
      margin="normal"

      InputLabelProps={{
         shrink: true,
         style:{color:"#1fbba6"}

      }} >
        <MenuItem value={101}>101= SV-pfl. Beschäftigte ohne bes. Merkmal</MenuItem>
        <MenuItem value={102}>102= Auszubildende ohne besondere Merkmale bzw. Auszubildende ohne Arbeitsentgelt</MenuItem>
        <MenuItem value={103}>103= Beschäftigte in Altersteilzeit</MenuItem>
        <MenuItem value={105}>105= Praktikanten</MenuItem>
        <MenuItem value={106}>106= Werkstudenten</MenuItem>
        <MenuItem value={109}>109= Geringfügig entlohnte Beschäftigte nach § 8 Absatz 1 Nummer 1 SGB IV</MenuItem>
        <MenuItem value={110}>110= Kurzfristig Beschäftigte nach § 8 Absatz 1 Nummer 2 SGB IV</MenuItem>
        <MenuItem value={119}>119= Versicherungsfreie Altersvollrentner und Versorgungsbezieher wegen Alters</MenuItem>
        <MenuItem value={120}>120= Versicherungspflichtige Altersvollrentner 1)</MenuItem>
        <MenuItem value={121}>121= Auszubildende, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={122}>122= Auszubildende in einer außerbetrieblichen Einrichtung (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={123}>123= Personen, die ein freiwilliges soziales, ein freiwilliges ökologisches Jahr oder einen Bundesfreiwilligendienst leisten (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={124}>124= Heimarbeiter ohne Anspruch auf Entgeltfortzahlung im Krankheitsfall</MenuItem>
        <MenuItem value={140}>140= Seeleute</MenuItem>
        <MenuItem value={144}>144= Auszubildende in der Seefahrt, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
        <MenuItem value={150}>150= In der Seefahrt beschäftigte versicherungspflichtige Altersvollrentner 1)</MenuItem>
        <MenuItem value={190}>190= Beschäftigte, die ausschließlich in der gesetzlichen Unfallversicherung versichert sind</MenuItem>
    </TextField>          
  </Grid>
  <Grid item xs={2} style={{}}>
    <TextField
      select // durch select wird DropDown initialisiert
      id="Mb"
      label="Mehrbeschäftigung?"
      fullWidth
      error={fehlerliste["Mb"+tabellenKey]}
      value={antworten['Mb'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('Mb'+tabellenKey,event.target.value)}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={10}>JA</MenuItem>
      <MenuItem value={20}>NEIN</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={4} style={{}}>
    <Typography style={{}}>
      Beitragsgruppen
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography style={{}}>
      Angaben zur Tätigkeit
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography style={{}}>
      Schlüssel der Staatsangehörigkeit
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography style={{}}>
      Betriebsstätte
    </Typography>
  </Grid>          
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      KV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      RV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      AV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      PV
    </Typography>
  </Grid>
  <Grid item xs={8}>

  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="KV"
      label="KV auswählen "
      fullWidth
      error={fehlerliste["KV"+tabellenKey]}
      value={antworten['KV'+tabellenKey]||''}              
      onChange={(event) => setzeAntworten('KV'+tabellenKey,event.target.value.toString())}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={0}>0 kein Beitrag</MenuItem>
      <MenuItem value={1}>1 allgemeiner Beitrag</MenuItem>
      <MenuItem value={2}>2 erhöhter Beitrag (nur für Meldezeitraum bis 31.12.2008 zulässig)</MenuItem>
      <MenuItem value={3}>3 ermäßigter Beitrag</MenuItem>
      <MenuItem value={4}>4 Beitrag zur landwirtschaftlichen KV</MenuItem>
      <MenuItem value={5}>5 Arbeitgeberbeitrag zur landwirtschaftlichen KV</MenuItem>
      <MenuItem value={6}>6 Pauschalbetrag für gerinfügig Beschäftigte</MenuItem>
      <MenuItem value={9}>9 Firmenzahler</MenuItem>

    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="RV"
      label="RV auswählen "
      fullWidth
      error={fehlerliste["RV"+tabellenKey]}
      value={antworten['RV'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('RV'+tabellenKey,event.target.value.toString())}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
        <MenuItem value={0}>0 kein Beitrag</MenuItem>
        <MenuItem value={1}>1 voller Beitrag</MenuItem>
        <MenuItem value={3}>3 halber Beitrag</MenuItem>
        <MenuItem value={5}>5 Pauschalbetrag für gerinfügugig Beschäftigte</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="AV"
      label="AV auswählen "
      fullWidth
      error={fehlerliste["AV"+tabellenKey]}
      value={antworten['AV'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('AV'+tabellenKey,event.target.value.toString())}
      variant="outlined"
      margin="normal"
//         error={ErrorLiga !==""}
//         helperText={ErrorLiga}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
        <MenuItem value={0}>0 kein Beitrag</MenuItem>
        <MenuItem value={1}>1 voller Beitrag</MenuItem>
        <MenuItem value={2}>2 halber Beitrag</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={1} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="PV"
      label="PV auswählen "
      fullWidth
      error={fehlerliste["PV"+tabellenKey]}
      value={antworten['PV'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('PV'+tabellenKey,event.target.value.toString())}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={0}>0 kein Beitrag</MenuItem>
      <MenuItem value={1}>1 voller Beitrag</MenuItem>
      <MenuItem value={2}>2 halber Beitrag</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={3} >
  
    <TextField  
      id="angabenT" 
      variant="outlined" 
      label="Tätigkeit angeben" 
      error={fehlerliste["angabenT"+tabellenKey]}
      margin='normal'
      fullWidth
      placeholder=""  style={{display:""}}onChange={(event) => setzeAntworten('angabenT'+tabellenKey,event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}/>       
  
  </Grid>
  <Grid item xs={3} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="StaatA"
      label="Staatsangehörigkeit auswählen "
      fullWidth
      error={fehlerliste["StaatA"+tabellenKey]}
      value={antworten['StaatA'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('StaatA'+tabellenKey,event.target.value)}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={"000"}>000 = deutsch</MenuItem>
      <MenuItem value={"129"}>129 = französisch</MenuItem>
      <MenuItem value={"148"}>148 = niederländisch</MenuItem>      
      <MenuItem value={"161"}>161 = spanisch</MenuItem>
      <MenuItem value={"163"}>163 = türkisch</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={2} >
    <TextField
      select // durch select wird DropDown initialisiert
      id="BS"
      label="Betriebsstätte auswählen "
      fullWidth
      error={fehlerliste["BS"+tabellenKey]}
      value={antworten['BS'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('BS'+tabellenKey,event.target.value)}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}       
      >
      <MenuItem value={10}>West</MenuItem>
      <MenuItem value={20}>Ost</MenuItem>
    </TextField>           
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Beitragspflichtiges Bruttoarbeitsentgelt</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Währung</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>
      Betrag
     </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>
       Statuskennzeichen
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>beitragspflichtiges Bruttoarbeitsentgelt</Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Euro</Typography>

  </Grid>
  <Grid item xs={3} style={{}}>

  <TextField  
      id="betrag" 
      disabled={betragblockiert}
      type="number"
      variant="outlined" 
      label="Betrag angeben" 
      error={fehlerliste["Betrag"+tabellenKey]}
      value={antworten['Betrag'+tabellenKey]||''}
      margin='normal'
      fullWidth
      placeholder=""  style={{display:""}}
      onChange={(event) => setzeAntworten('Betrag'+tabellenKey,event.target.value)}
      InputLabelProps={{
          shrink: true,
          style:{color:"#1fbba6"}

      }}/>       

  </Grid>
  <Grid item xs={3} style={{}}>
      <TextField disabled id="Statusk" label="" error={fehlerliste["Statusk"+tabellenKey]} placeholder=""  style={{display:"flex",marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('Statusk'+tabellenKey,event.target.value)}/>
  </Grid>
</Grid>
<Grid container spacing={1} style={{marginTop:'30px', backgroundColor:'#BDBDBD', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
  <Grid item xs={6} style={{textAlign:'left'}}>
    <Typography>
      Stornierung einer bereits abgebebenen Meldung 
    </Typography>
  </Grid>
  <Grid item xs={6} style={{}}>
    <Typography>
      Grund der Abgabe
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography>
       Von
    </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
   <Typography>
     Bis
   </Typography>
  </Grid>
  <Grid item xs={5} style={{}}>
   <Typography>
     Betriebsnummer des Arbeitgeber
   </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
   <Typography>
     Personengruppe
   </Typography>
  </Grid>
  <Grid item xs={12}>
  </Grid>
  <Grid item xs={6} style={{marginTop:'30px'}} >
   <Typography>
     Mehrfachbeschäftigt
   </Typography>
  </Grid>
  <Grid item xs={6} style={{marginTop:'30px'}}>
   <Typography>
     Personengruppe
   </Typography>
  </Grid>
  <Grid item xs={4} style={{}}>
    <Typography style={{}}>
      Beitragsgruppen
    </Typography>
  </Grid>
  <Grid item xs={4} style={{}}>
    <Typography style={{}}>
      Angaben zur Tätigkeit
    </Typography>
  </Grid>
  <Grid item xs={4} style={{}}>
    <Typography style={{}}>
      Schlüssel der Staatsangehörigkeit
    </Typography>
  </Grid>      
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      KV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      RV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      AV
    </Typography>
  </Grid>
  <Grid item xs={1} style={{}}>
    <Typography style={{}}>
      PV
    </Typography>
  </Grid>
  <Grid item xs={8}>
  </Grid>
  <Grid item xs={3} style={{marginTop:'40px'}}>
    <Typography>Beitragspflichtiges Bruttoarbeitsentgelt</Typography>
  </Grid>
  <Grid item xs={3} style={{marginTop:'40px'}}>
    <Typography>Währung</Typography>
  </Grid>
  <Grid item xs={3} style={{marginTop:'40px'}}>
    <Typography>
      Betrag
     </Typography>
  </Grid>
  <Grid item xs={3}>

  </Grid>
  <Grid item xs={3} style={{}}>
    <Typography>Beitragspflichtige Bruttoarbeitsentgelt</Typography>
  </Grid>
</Grid>
<Grid container spacing={1} style={{marginTop:'30px', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
  <Grid item xs={12}>
    <Typography style={{fontWeight:'bold'}}>
        Namensänderung (Bisheriger Name)
    </Typography>
  </Grid>
  <Grid item xs={3} style={{}}>
      <Typography>Name:</Typography>
  </Grid>
  <Grid item xs={9} style={{}}>
      <TextField  id="name" label="" disabled placeholder=""  style={{display:"flex", marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('name'+tabellenKey,event.target.value)}/>
  </Grid>
</Grid>
<Grid container spacing={1} style={{marginTop:'30px', backgroundColor:'#BDBDBD',borderTop:'solid',borderRight:'solid', borderLeft:'solid', borderBottom:'solid', textalign:'left'}} justifyContent={"center"} alignItems={"center"}>
    <Grid item xs={3} style={{fontWeight:'bold'}}>
        <Typography>
            Änderung der Staatsangehörigkeit
        </Typography>
    </Grid>
    <Grid item xs={9} style={{fontWeight:'bold'}}>
        <Typography>
            Schlüssel der<strong> neuen</strong> Staatsangehörigkeit
        </Typography>
    </Grid>
</Grid>
<Grid container spacing={1} style={{marginTop:'30px',textAlign:'left'}} justifyContent={"center"} >
<Grid item xs={12} sytle={{ float:'left'}}>
      <Typography>
        <strong>Wenn keine Versicherungs Nr. angegeben werden kann</strong>
      </Typography>
</Grid>
<Grid item xs={6} style={{}}>
    <Typography style={{float:'left',lineHeight:"32px", marginLeft:'10px'}}>
      Geburtsname:    
    </Typography>
    <TextField  id="standard-basic" label="" disabled placeholder="" fullWidth  style={{width:"50%",display:"flex"}} onChange={(event) => setzeAntworten('nameaenderung'+tabellenKey,event.target.value)}/>
  </Grid>
  <Grid item xs={6} style={{}}>
    <Typography style={{float:'left',lineHeight:"32px"}}>
      Geburtsort:   
    </Typography>
    <TextField  id="standard-basic" label="" disabled placeholder="" fullWidth  style={{width:"50%",display:"flex"}}onChange={(event) => setzeAntworten('geburtsort'+tabellenKey,event.target.value)}/>
  </Grid>
  <Grid item xs={2} style={{}}>
    <Typography>
       Geburtsdatum:
    </Typography>
  </Grid>
  <Grid item xs={10} style={{}}>
   <Typography>
     Geschlecht
   </Typography>
  </Grid>
  <Grid item xs={2} style={{}}>
  <div style={{}}>
  <TextField  
      id="Gdatum" 
      variant="outlined" 
      label="Geburtsdatum " 
      disabled
      margin='normal'
      placeholder=""  style={{display:""}}
//              onChange={(event) => setzeAntworten('Gdatum',event.target.value)}
      InputLabelProps={{
          shrink: true,
      }}/>              </div>
  </Grid>
  <Grid item xs={10} style={{}}>
    <TextField
      select // durch select wird DropDown initialisiert
      id="geschlecht"
      label="Geschlecht:"
      disabled
      error={fehlerliste["geschlecht"+tabellenKey]}
      fullWidth
      value={antworten['geschlecht'+tabellenKey]||''}
      onChange={(event) => setzeAntworten('geschlecht'+tabellenKey,event.target.value)}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
          shrink: true,
      }}       
      >
      <MenuItem value={10}>weiblich</MenuItem>
      <MenuItem value={20}>männlich</MenuItem>
      <MenuItem value={20}>divers</MenuItem>

    </TextField>           
  </Grid>
  <Grid item xs={12} sytle={{fontWeight:'bold'}}>
      <Typography>
          Nur bei erstmaliger Aufnahme einer Beschäftigung von nichtdeutschen Bürgern des Europäischen Wirtschaftsraumes:
      </Typography>
  </Grid>
  <Grid item xs={5}>
      <Typography>
          Geburtsland (Schlüssel der Staatsangehörigkeit)
      </Typography>
  </Grid>
  <Grid item xs={7}>
      Versicherungsnummer des Staatsangehörigkeitslandes
  </Grid>
  <Grid item xs={12}>
      <Typography>
          {antworten['StaatA'+tabellenKey]}
      </Typography>
  </Grid>
  <Grid item xs={6}style={{}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
        Name der Einzugsstelle:
      </Typography>
      <Typography style={{width:"50%",borderBottom:"solid"}}>
       {kkname? kkname : "AOK-Baden Württemberg"}
      </Typography>
  </Grid>
  <Grid item xs={6}style={{}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
        Betriebsnummer der Einzugsstelle:
      </Typography>
      <Typography style={{width:"50%",borderBottom:"solid"}}>
      674 506 65
      </Typography>
  </Grid>
  <Grid item xs={6}style={{}}>

  </Grid>
  <Grid item xs={6}style={{}}>
      <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
        Betriebsnummer der UV:
      </Typography>
  </Grid>



</Grid>



<Grid container spacing={1} style={{marginTop:'30px',borderBottom:'solid', borderTop:'solid',borderLeft:'solid',borderRight:'solid'}} justifyContent={"center"} alignItems={"center"} >
  <ListItem>
    <ListItemAvatar>
      <Avatar style={{}}>
        !
      </Avatar>
    </ListItemAvatar>
    <ListItemText>
          WICHTIGES DOKUMENT - sorgfältig aufbewahren! <br />
          IMPORTANT DOCUMENT - keep it for future reference! <br />
          DOCUMENT IMPORTANT - a conserver prècieusement! <br />
          ÖNEMLI BELGE - titizlikle saklayiniz!
      </ListItemText>  
      <ListItemText>
          DOCUMENTO IMPORTANTE - conservare accuratamente!<br />
          IMPORTANTE DOCUMENTO - guàrdelo por favor cuidadosamente!<br />
          DOCUMENTO IMPORTANTE - guardar cuidadosamente!<br />
          VAZAN DOKUMENT - brizljivo cuvati!
      </ListItemText>  
      <ListItemAvatar>
      <Avatar style={{}}>
        !
      </Avatar>
    </ListItemAvatar>
  </ListItem>
</Grid>   
</dev>

)
}

export {Deuev};