import React from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';


function KvRvTabelle (props) {
    const {tabellenKey, fehlerliste, setzeAntworten} = props;
    
    return(
<div>
    <Grid container spacing={0} style={{marginTop:'30px',backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={1} >
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid',borderTop:'solid',borderLeft:'solid',height:'54px' }}>
        </Grid>
        <Grid item xs={2} style={{border:'solid',height:'54px'}}>
          <Typography>
            laufendes Entgelt
          </Typography>
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid', borderRight:'solid',borderTop:'solid',height:'54px'}}>
          <Typography>
            IST SV-Tage des Monats
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',borderTop:'solid'}}>
          <Typography>
            BBG gem. SV-Tage des Monats
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderTop:'solid', borderBottom:'solid'}}>
          <Typography>
            zu verbeitragendes laufendes Entgelt
          </Typography>
        </Grid>
        <Grid item xs={2} style={{border:'solid',height:'54px'}}>
          <Typography>
            SV-Luft aktueller Monat
          </Typography>
        </Grid>
        <Grid item xs={1} >
        </Grid>


        <Grid item xs={1} >
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             KV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
         <TextField  
             id='kvlaufend' 
             type='number'
             error={fehlerliste["kvlaufend"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('kvlaufend'+tabellenKey,event.target.value)}/>
        </Grid>

        <Grid item xs={1} style={{borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
            30
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid',borderRight:'solid', height:'50px'}}>
        <TextField  
             id='kvbbgmonat' 
             type='number'
             error={fehlerliste["kvbbgmonat"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('kvbbgmonat'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{ borderBottom:'solid',  height:'50px'}}>
          <TextField  
             id='kvverbeitragtLFD' 
             type='number'
             error={fehlerliste["kvverbeitragtLFD"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('kvverbeitragtLFD'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}>
        <TextField  
             id='kvluftmonat' 
             type='number'
             error={fehlerliste["kvluftmonat"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('kvluftmonat'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={1} >
        </Grid>


        <Grid item xs={1} >
        </Grid>
        <Grid item xs={1} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             RV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
         <TextField  
             id='rvlaufend' 
             type='number'
             error={fehlerliste["rvlaufend"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('rvlaufend'+tabellenKey,event.target.value)}/>
        </Grid>

        <Grid item xs={1} style={{borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
            30
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid',borderRight:'solid', height:'50px'}}>
          <TextField  
             id='rvbbgmonat' 
             type='number'
             error={fehlerliste["rvbbgmonat"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('rvbbgmonat'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{ borderBottom:'solid',  height:'50px'}}>
          <TextField  
             id='rvverbeitragtLFD' 
             type='number'
             error={fehlerliste["rvverbeitragtLFD"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('rvverbeitragtLFD'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}>
        <TextField  
             id='rvluft' 
             type='number'
             error={fehlerliste["rvluftmonat"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('rvluftmonat'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={1} >
        </Grid>
    </Grid>


    <Grid container spacing={0} style={{marginTop:'30px',backgroundColor:'white'}} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={1} style={{borderBottom:'solid',borderTop:'solid',borderLeft:'solid',height:'54px' }}>
        </Grid>
        <Grid item xs={2} style={{border:'solid',height:'54px'}}>
          <Typography>
            Summe SV-Tage<br/>Januar bis August
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',borderTop:'solid',height:'54px'}}>
          <Typography>
            BBG-Grenze<br/>Januar bis August
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',borderTop:'solid'}}>
          <Typography>
            Verbeitragtes Entgelt<br/>Januar bis August
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderTop:'solid', borderBottom:'solid'}}>
          <Typography>
            Summe SV-Luft<br/>Januar-August
          </Typography>
        </Grid>
        <Grid item xs={2} style={{border:'solid',height:'54px'}}>
        <Typography>
            Summe SV-Luft inkl.<br/>aktuellem Monat
          </Typography>
        </Grid>
        <Grid item xs={1} >
        </Grid>




        <Grid item xs={1} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             KV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <TextField  
             id='summeKvTageJanAug' 
             type='number'
             error={fehlerliste["summeKvTageJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeKvTageJanAug'+tabellenKey,event.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <TextField  
             id='BBGgrenzeKvJanAug' 
             type='number'
             error={fehlerliste["BBGgrenzeKvJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('BBGgrenzeKvJanAug'+tabellenKey,event.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid',borderRight:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
              34.691,69€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ borderBottom:'solid',  height:'50px'}}>
          <TextField  
             id='summeKvLuftJanAug' 
             type='number'
             error={fehlerliste["summeKvLuftJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeKvLuftJanAug'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}>
        <TextField  
             id='summeKvLuftGesamt' 
             type='number'
             error={fehlerliste["summeKvLuftGesamt"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeKvLuftGesamt'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={1} >
        </Grid>


        <Grid item xs={1} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
             RV
          </Typography>
        </Grid>
        <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <TextField  
             id='summeRvTageJanAug' 
             type='number'
             error={fehlerliste["summeRvTageJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeRvTageJanAug'+tabellenKey,event.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderRight:'solid',height:'50px'}}>
          <TextField  
             id='BBGgrenzeRvJanAug' 
             type='number'
             error={fehlerliste["BBGgrenzeRvJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('BBGgrenzeRvJanAug'+tabellenKey,event.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid',borderRight:'solid', height:'50px'}}>
          <Typography style={{marginTop:'10px'}}>
              34.691,69€
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ borderBottom:'solid',  height:'50px'}}>
          <TextField  
             id='summeRvLuftJanAug' 
             type='number'
             error={fehlerliste["summeRvLuftJanAug"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeRvLuftJanAug'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}>
        <TextField  
             id='summeRvLuftGesamt' 
             type='number'
             error={fehlerliste["summeRvLuftGesamt"+tabellenKey]}
            label="Eintrag" 
            inputProps={{style:{textAlign:"center"}}}
             placeholder=""  
             style={{display:"flex"}}
             onChange={(event) => setzeAntworten('summeRvLuftGesamt'+tabellenKey,event.target.value)}/>
        </Grid>
        <Grid item xs={1} >
        </Grid>
    </Grid>

  </div>
    
    )
}



export {KvRvTabelle};


