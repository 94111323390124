import React from 'react';
import {Grid, MenuItem, Typography,TextField} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



function V_Status_Tabelle (props) {
  const {antworten,fehlerliste,setzeAntworten, tabellenKey,datum,anzReihen} = props;
  let reihen=[];
  for (let i = 0; i < anzReihen; i++) {
    reihen.push("A")
  }

  const reihenEinfügen  = reihen.map((value, i) => {
      return(
        <TableRow >
        <TableCell style={{textAlign:'center'}} >
            <Typography>{datum[i]}</Typography>
        </TableCell>
        <TableCell style={{textAlign:'center'}}>
            <TextField
              select
              fullWidth
              value={antworten['vStatus'+tabellenKey+i]||''}
              error={fehlerliste["vStatus"+tabellenKey+i]}     
              style={{backgroundColor:(fehlerliste["vStatus"+tabellenKey+i]===true)?"red":""}}
              onChange={(event) => setzeAntworten('vStatus'+tabellenKey+i,event.target.value)}
            >
              <MenuItem value={"0"}>
                Gesetzlich Versichert
              </MenuItem>
              <MenuItem value={"1"}>
                Freiwillig Versichert/Privat
              </MenuItem>
              <MenuItem value={"2"}>
                keine erneute Prüfung notwendig
              </MenuItem>
            </TextField>
        </TableCell>
        </TableRow>

      );
      ;})
return(
    <div>
        <Grid container spacing={0} style={{border:'solid', marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
           <TableContainer>
               <Typography style={{marginLeft:"5px",marginRight:"5px",textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE'}}>
                    Beurteilung Versicherungsstatus
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:'center'}}>Zeitpunkt</TableCell>
                            <TableCell style={{textAlign:'center'}}>Versicherungsstatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reihenEinfügen}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    </div>
  )
}

export {V_Status_Tabelle};