import React, {useState, useCallback} from 'react';
import {Avatar,Typography,Button,MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const NICKELSTAMM = '/media/Nickel Stammblatt.pdf';
const MUSTERDEUEV = '/media/MeldebescheinigungDEUEV.png';
const TAETIGKEITSCODE = '/media/Aufbau Taetigkeitscode.pdf';
const { useMessage } = '../../context/message-context';
const DEUEV_ANM = '/media/DEÜV Anmeldung.mp4';
import { Player, ControlBar } from 'video-react';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function DEUEVAnm(props) {
  const [antworten,setAntworten]=useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('006');
  const [merkmal]=useState('DEUEVAnm');


console.log("reactnummer: "+{reactnummer})

  const setRedirect = (path) => {
    navigate(path);
  }
  const fehlerAusLesen=(fehler)=>{
    console.log(fehler);
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": "DEUEVAnm",
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
         } else {
         if (response) {
           props.showError(response.data.message);
         } else {
           props.showError('Keine Internetverbindung');
         }
       }
     }


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  

  return(
    
      <div>
        <Briefkopf  
            ueberschrift1="Die DEÜV Meldung" 
            ueberschrift2="In diesem Bereich lernst Du, ^welche personenbezogenen Daten auf der DEÜV-Meldung stehen und welche Bedeutungen diese haben." 
        />

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>

        <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                V1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="In dem Video wird anschaulich erklärt, welchen Sinn und Zweck eine DEÜV-Meldung hat"/>
          </ListItem>
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"55px"}}>
            <Player
                poster={POSTER}
                src={DEUEV_ANM}
            >
              <ControlBar autoHide={false} />
            </Player>
          </Grid>

          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  M1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Wo steht was auf der DEÜV-Anmeldung?"/>
          </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
                  Mit jeder DEÜV-Meldung werden folgende Fragen beantwortet: <br/>
                  1. Was ist der Grund der DEÜV-Meldung (Anmeldung, Abmeldung, usw.)?<br/>
                  2. Für welches Startdatum bzw. welchen Zeitraum gilt die Meldung?<br/>
                  3. Welche Personengruppe ist zu melden?<br/>
                  4. Welche Beitragsgruppe ist zu melden?<br/>
                  5. Welches beitragspflichtige Bruttoarbeitsentgelt wurde in dem Meldezeitraum verbeitragt?<br/>
                  6. Name der Einzugsstelle?<br/>
                  Des weiteren sind noch folgende weitere Angaben in jeder DEÜV-Meldung enthalten:<br/>
                  - Angaben zur Tätigkeit<br/>
                  - Staatsangehörigkeit<br/>
                  - ob der Arbeitnehmer in Ost- oder West-Deutschland beschäftigt ist<br/>
                  - ob es sich um eine Mehrfachbeschäftigung handelt <br/>
                 </Typography>
          <Grid item >
              <img src={MUSTERDEUEV} height="1500" alt="ectool" loading="lazy"/>
          </Grid>


          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  H1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Diese Erklärung hilft Dir beim Ausfüllen des Tätigkeitscodes auf der DEÜV-Anmeldung"/>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={TAETIGKEITSCODE}download>
                Erklärung Tätigkeitscode
              </Button>

            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText primary="Fülle nun die Felder der DEÜV-Meldung anhand des Stammblattes von Herrn Nickel aus."/>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={NICKELSTAMM}download>
                Stammblatt Herr Nickel
              </Button>
            </ListItem>
        </Grid>

      </Grid>


        <Grid item xs={12} style={{border:"solid", marginTop:'30px'}} >
            <Typography> 
                Meldebescheinigung nach §25 DEÜV zur Sozialversicherung für den Arbeitnehmer
            </Typography>
          </Grid>
        <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left',borderTop:'solid', borderBottom:'solid', borderLeft:'solid',borderRight:'solid'}}  alignItems={"center"}>
          <Grid item xs={2} style={{}}>
              <Typography style={{}}>
                  Grund der Abgabe
              </Typography>
          </Grid>
          <Grid item xs={10} style={{}}>
              <TextField
                select // durch select wird DropDown initialisiert
                id="Grund"
                style={{}}
                label="Grund der Abgabe auswählen "
                fullWidth
                
                error={fehlerliste["Grund"]}
                value={antworten['Grund']||''}
                onChange={(event) => setzeAntworten('Grund',event.target.value)}
                variant="outlined"
                margin="normal"
        //         error={ErrorLiga !==""}
        //         helperText={ErrorLiga}
                InputLabelProps={{
                    shrink: true,
                    style:{color:"#1fbba6"}
                }} >
                    <MenuItem value={10}>10 = Anmeldung wegen Beginn einer Beschäftigung</MenuItem>
                    <MenuItem value={11}>11 = Anmeldung wegen Krankenkassenwechsel</MenuItem>

                </TextField>          
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{marginTop:'30px', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={5}style={{borderBottom:'solid', borderRight:'solid', borderLeft:'solid',borderTop:'solid',  textAlign:'left'}}>
           <Typography >
            Herrn/ Frau <br/> 
           </Typography>  
           <TextField id=" NAME" label="Name" error={fehlerliste["NAME"]}  InputLabelProps={{ style: {color:'#1fbba6'},}} borderColor="green"style={{display:"inline-block"}} onChange={(event) => setzeAntworten('NAME',event.target.value)}></TextField>
           <TextField id="VORNAME" label="Vorname" error={fehlerliste["VORNAME"]} InputLabelProps={{ style: {color:'#1fbba6'},}}style={{display:"inline-block"}}onChange={(event) => setzeAntworten('VORNAME',event.target.value)}></TextField><br/> 
           <TextField id="STRASSE" label="Strasse" error={fehlerliste["STRASSE"]} InputLabelProps={{ style: {color:'#1fbba6'},}}style={{display:"inline-block"}}onChange={(event) => setzeAntworten('STRASSE',event.target.value)}></TextField>
           <TextField id="NUMMER" label="Nummer"  error={fehlerliste["HAUSNR"]} InputLabelProps={{ style: {color:'#1fbba6'},}} style={{display:""}}onChange={(event) => setzeAntworten('HAUSNR',event.target.value)}></TextField><br/>
           <TextField id="PLZ" label="PLZ" error={fehlerliste["PLZ"]} InputLabelProps={{ style: {color:'#1fbba6'},}} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('PLZ',event.target.value)}></TextField>
           <TextField id="STADT" label="Stadt" error={fehlerliste["STADT"]} InputLabelProps={{ style: {color:'#1fbba6'},}} style={{display:"inline-block"}}onChange={(event) => setzeAntworten('STADT',event.target.value)}></TextField>
          </Grid>
          <Grid item xs={1}>

          </Grid>
          <Grid item xs={6}style={{}}>
           <Typography style={{fontWeight:'bold'}}>
            Name und Anschrift des Arbeitgebers
           </Typography>  
           <Typography>
            Firma <br/>
            ectool (Lohn macht Spaß)<br/>
            Max-Eyth-Str. 8 <br/>
            71332 Waiblingen <br/>
           </Typography>
           <Typography style={{marginTop:'20px'}}>
             <strong>Erstelldatum</strong> 20.01.2020
           </Typography>

         
           <Grid item xs={12}style={{}}>
           <div style={{borderBottom:"solid",borderTop:'solid',borderLeft:'solid', borderRight:'solid'}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
                Arbeitgeber-Betriebsnummer:
              </Typography>
              <TextField
                disabled
                id="standard-basic" 
                label="" 
                placeholder="99999011" 
                style={{width:"50%",display:"flex",bordertop:"solid"}}
//                onChange={(event) => setzeAntworten('AgB',event.target.value)}
              />
           </div>
           <div style={{borderLeft:'solid', borderRight:'solid'}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px",backgroundColor:"#1fbba6"}}>
                Versicherungsnummer:
              </Typography>
              <TextField
                id="standard-basic"
                label="" 
                placeholder="Eingabe"  
                style={{width:"50%",display:"flex",bordertop:"solid", backgroundColor:(fehlerliste["VNr"]===true)?"red":""}}
                onChange={(event) => setzeAntworten('VNr',event.target.value)}
                error={fehlerliste["VNr"]}
              />
           </div>
           <div style={{borderBottom:"solid",borderTop:'solid',borderLeft:'solid', borderRight:'solid'}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px", backgroundColor:"#1fbba6"}}>
                Geburtsdatum:
              </Typography>
              <TextField
                id="standard-basic" 
                label="" 
                error={fehlerliste["Gdatum"]}
                placeholder="Eingabe"
                style={{width:"50%",display:"flex", backgroundColor:(fehlerliste["Gdatum"]===true)?"red":""}}
                onChange={(event) => setzeAntworten('Gdatum',event.target.value)}
              />
           </div>

            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12} style={{}}>
            <Typography style={{float:'left',lineHeight:"32px"}}>
              
            </Typography>
          </Grid>
        <Grid container spacing={1} style={{marginTop:'30px',  textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={2} style={{}}>
            <Typography style={{float:'left',lineHeight:"32px"}}>
              Besonderer Meldehinweis:   
            </Typography>
          </Grid>
          <Grid item xs={10} style={{}}>
            <TextField disabled id="standard-basic" label="" placeholder="" fullWidth  style={{display:"flex",borderTop:"solid",borderRight:"solid",borderLeft:'solid', borderBottom:'solid'}}onChange={(event) => setzeAntworten('bMHw',event.target.value)}/>
          </Grid>
          <Grid item xs={12} style={{fontWeight:'bold'}}>
            <p style={{float:'left',fontSize:"1,5em"}}>
                Beschäftigungszeit
            </p>
          </Grid>
          <Grid item xs={2} style={{}}>
            <Typography>
               Von
            </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
           <Typography>
             Bis
           </Typography>
          </Grid>
          <Grid item xs={6} style={{}}>
           <Typography>
             Personengruppe 
           </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
           <Typography>
             Mehrfachbeschäftigt 
           </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
          <div style={{}}>
          <TextField  
              id="von" 
              variant="outlined" 
              label="Von " 
              error={fehlerliste["von"]}
              margin='normal'
              placeholder=""  style={{display:"", backgroundColor:(fehlerliste["von"]===true)?"red":""}}onChange={(event) => setzeAntworten('von',event.target.value)}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}
                }}/>              </div>
          </Grid>
          <Grid item xs={2} style={{}}>
          <div style={{}}>
          <TextField  
              id="bis" 
              variant="outlined" 
              error={fehlerliste["bis"]}
              label="Bis" 
              margin='normal'
              placeholder=""  style={{display:""}}onChange={(event) => setzeAntworten('bis',event.target.value)}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}/>    
           </div>
          </Grid>
          <Grid item xs={6} style={{}}>
            <TextField
              select // durch select wird DropDown initialisiert
              id="PG"
              label="Personengruppe auswählen "
              fullWidth
              error={fehlerliste["PG"]}
              value={antworten['PG']||''}
              onChange={(event) => setzeAntworten('PG',event.target.value)}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                 shrink: true,
                 style:{color:"#1fbba6"}
 
              }} >
                <MenuItem value={101}>101= SV-pfl. Beschäftigte ohne bes. Merkmal</MenuItem>
                <MenuItem value={102}>102= Auszubildende ohne besondere Merkmale bzw. Auszubildende ohne Arbeitsentgelt</MenuItem>
                <MenuItem value={103}>103= Beschäftigte in Altersteilzeit</MenuItem>
                <MenuItem value={105}>105= Praktikanten</MenuItem>
                <MenuItem value={106}>106= Werkstudenten</MenuItem>
                <MenuItem value={109}>109= Geringfügig entlohnte Beschäftigte nach § 8 Absatz 1 Nummer 1 SGB IV</MenuItem>
                <MenuItem value={110}>110= Kurzfristig Beschäftigte nach § 8 Absatz 1 Nummer 2 SGB IV</MenuItem>
                <MenuItem value={119}>119= Versicherungsfreie Altersvollrentner und Versorgungsbezieher wegen Alters</MenuItem>
                <MenuItem value={120}>120= Versicherungspflichtige Altersvollrentner 1)</MenuItem>
                <MenuItem value={121}>121= Auszubildende, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
                <MenuItem value={122}>122= Auszubildende in einer außerbetrieblichen Einrichtung (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
                <MenuItem value={123}>123= Personen, die ein freiwilliges soziales, ein freiwilliges ökologisches Jahr oder einen Bundesfreiwilligendienst leisten (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
                <MenuItem value={124}>124= Heimarbeiter ohne Anspruch auf Entgeltfortzahlung im Krankheitsfall</MenuItem>
                <MenuItem value={140}>140= Seeleute</MenuItem>
                <MenuItem value={144}>144= Auszubildende in der Seefahrt, deren Arbeitsentgelt die Geringverdienergrenze nach § 20 Absatz 3 Satz 1 Nummer 1 SGB IV nicht übersteigt (nur für Meldezeiträume ab 1. Januar 2012 zulässig)</MenuItem>
                <MenuItem value={150}>150= In der Seefahrt beschäftigte versicherungspflichtige Altersvollrentner 1)</MenuItem>
                <MenuItem value={190}>190= Beschäftigte, die ausschließlich in der gesetzlichen Unfallversicherung versichert sind</MenuItem>
            </TextField>          
          </Grid>
          <Grid item xs={2} style={{}}>
            <TextField
              select // durch select wird DropDown initialisiert
              id="Mb"
              label="Mehrbeschäftigung?"
              fullWidth
              error={fehlerliste["Mb"]}
              value={antworten['Mb']||''}
              onChange={(event) => setzeAntworten('Mb',event.target.value)}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
              <MenuItem value={10}>JA</MenuItem>
              <MenuItem value={20}>NEIN</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={4} style={{}}>
            <Typography style={{}}>
              Beitragsgruppen
            </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography style={{}}>
              Angaben zur Tätigkeit
            </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography style={{}}>
              Schlüssel der Staatsangehörigkeit
            </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
            <Typography style={{}}>
              Betriebsstätte
            </Typography>
          </Grid>          
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              KV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              RV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              AV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              PV
            </Typography>
          </Grid>
          <Grid item xs={8}>

          </Grid>
          <Grid item xs={1} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="KV"
              label="KV auswählen "
              fullWidth
              error={fehlerliste["KV"]}
              value={antworten['KV']||''}              
              onChange={(event) => setzeAntworten('KV',event.target.value.toString())}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
              <MenuItem value={0}>0 kein Beitrag</MenuItem>
              <MenuItem value={1}>1 allgemeiner Beitrag</MenuItem>
              <MenuItem value={2}>2 erhöhter Beitrag (nur für Meldezeitraum bis 31.12.2008 zulässig)</MenuItem>
              <MenuItem value={3}>3 ermäßigter Beitrag</MenuItem>
              <MenuItem value={4}>4 Beitrag zur landwirtschaftlichen KV</MenuItem>
              <MenuItem value={5}>5 Arbeitgeberbeitrag zur landwirtschaftlichen KV</MenuItem>
              <MenuItem value={6}>6 Pauschalbetrag für gerinfügig Beschäftigte</MenuItem>
              <MenuItem value={9}>9 Firmenzahler</MenuItem>

            </TextField>           
          </Grid>
          <Grid item xs={1} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="RV"
              label="RV auswählen "
              fullWidth
              error={fehlerliste["RV"]}
              value={antworten['RV']||''}
              onChange={(event) => setzeAntworten('RV',event.target.value.toString())}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
                <MenuItem value={0}>0 kein Beitrag</MenuItem>
                <MenuItem value={1}>1 voller Beitrag</MenuItem>
                <MenuItem value={3}>3 halber Beitrag</MenuItem>
                <MenuItem value={5}>5 Pauschalbetrag für gerinfügugig Beschäftigte</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={1} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="AV"
              label="AV auswählen "
              fullWidth
              error={fehlerliste["AV"]}
              value={antworten['AV']||''}
              onChange={(event) => setzeAntworten('AV',event.target.value.toString())}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
                <MenuItem value={0}>0 kein Beitrag</MenuItem>
                <MenuItem value={1}>1 voller Beitrag</MenuItem>
                <MenuItem value={2}>2 halber Beitrag</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={1} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="PV"
              label="PV auswählen "
              fullWidth
              error={fehlerliste["PV"]}
              value={antworten['PV']||''}
              onChange={(event) => setzeAntworten('PV',event.target.value.toString())}
              variant="outlined"
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
              <MenuItem value={0}>0 kein Beitrag</MenuItem>
              <MenuItem value={1}>1 voller Beitrag</MenuItem>
              <MenuItem value={2}>2 halber Beitrag</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={3} >
          
            <TextField  
              id="angabenT" 
              variant="outlined" 
              label="Tätigkeit angeben" 
              error={fehlerliste["angabenT"]}
              margin='normal'
              fullWidth
              placeholder=""  style={{display:""}}onChange={(event) => setzeAntworten('angabenT',event.target.value)}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}/>       
          
          </Grid>
          <Grid item xs={3} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="StaatA"
              label="Staatsangehörigkeit auswählen "
              fullWidth
              error={fehlerliste["StaatA"]}
              value={antworten['StaatA']||''}
              onChange={(event) => setzeAntworten('StaatA',event.target.value)}
              variant="outlined"
    //         onChange={(event) => setLiga(event.target.value)} 
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
              <MenuItem value={"000"}>000 = deutsch</MenuItem>
              <MenuItem value={"129"}>129 = französisch</MenuItem>
              <MenuItem value={"148"}>148 = niederländisch</MenuItem>      
              <MenuItem value={"161"}>161 = spanisch</MenuItem>
              <MenuItem value={"163"}>163 = türkisch</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={2} >
            <TextField
              select // durch select wird DropDown initialisiert
              id="BS"
              label="Betriebsstätte auswählen "
              fullWidth
              error={fehlerliste["BS"]}
              value={antworten['BS']||''}
              onChange={(event) => setzeAntworten('BS',event.target.value)}
              variant="outlined"
    //         onChange={(event) => setLiga(event.target.value)} 
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
                  style:{color:"#1fbba6"}

              }}       
              >
              <MenuItem value={10}>West</MenuItem>
              <MenuItem value={20}>Ost</MenuItem>
            </TextField>           
          </Grid>
          <Grid item xs={3} style={{}}>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography>Währung</Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography>
              Betrag
             </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography>
               Statuskennzeichen
            </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography>beitragspflichtiges Bruttoarbeitsentgelt</Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
              <TextField disabled id="waehrung" label="" error={fehlerliste["waehrung"]} placeholder=""  style={{display:"flex", marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('waehrung',event.target.value)}/>
          </Grid>
          <Grid item xs={3} style={{}}>
              <TextField disabled id="betrag" label="" placeholder="" error={fehlerliste["betrag"]}  style={{display:"flex",marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('betrag',event.target.value)}/>
          </Grid>
          <Grid item xs={3} style={{}}>
              <TextField disabled id="Statusk" label="" error={fehlerliste["Statusk"]} placeholder=""  style={{display:"flex",marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('Statusk',event.target.value)}/>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'30px', backgroundColor:'#BDBDBD', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={6} style={{textAlign:'left'}}>
            <Typography>
              Stornierung einer bereits abgebebenen Meldung 
            </Typography>
          </Grid>
          <Grid item xs={6} style={{}}>
            <Typography>
              Grund der Abgabe
            </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
            <Typography>
               Von
            </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
           <Typography>
             Bis
           </Typography>
          </Grid>
          <Grid item xs={5} style={{}}>
           <Typography>
             Betriebsnummer des Arbeitgeber
           </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
           <Typography>
             Personengruppe
           </Typography>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={6} style={{marginTop:'30px'}} >
           <Typography>
             Mehrfachbeschäftigt
           </Typography>
          </Grid>
          <Grid item xs={6} style={{marginTop:'30px'}}>
           <Typography>
             Personengruppe
           </Typography>
          </Grid>
          <Grid item xs={4} style={{}}>
            <Typography style={{}}>
              Beitragsgruppen
            </Typography>
          </Grid>
          <Grid item xs={4} style={{}}>
            <Typography style={{}}>
              Angaben zur Tätigkeit
            </Typography>
          </Grid>
          <Grid item xs={4} style={{}}>
            <Typography style={{}}>
              Schlüssel der Staatsangehörigkeit
            </Typography>
          </Grid>      
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              KV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              RV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              AV
            </Typography>
          </Grid>
          <Grid item xs={1} style={{}}>
            <Typography style={{}}>
              PV
            </Typography>
          </Grid>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={3} style={{marginTop:'40px'}}>
            <Typography>Beitragspflichtiges Bruttoarbeitsentgelt</Typography>
          </Grid>
          <Grid item xs={3} style={{marginTop:'40px'}}>
            <Typography>Währung</Typography>
          </Grid>
          <Grid item xs={3} style={{marginTop:'40px'}}>
            <Typography>
              Betrag
             </Typography>
          </Grid>
          <Grid item xs={3}>

          </Grid>
          <Grid item xs={3} style={{}}>
            <Typography>Beitragspflichtige Bruttoarbeitsentgelt</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'30px', textAlign:'left'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography style={{fontWeight:'bold'}}>
                Namensänerung (Bisheriger Name)
            </Typography>
          </Grid>
          <Grid item xs={3} style={{}}>
              <Typography>Name:</Typography>
          </Grid>
          <Grid item xs={9} style={{}}>
              <TextField  id="name" label="" disabled placeholder=""  style={{display:"flex", marginLeft:'10px',marginRight:'10px'}}onChange={(event) => setzeAntworten('name',event.target.value)}/>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'30px', backgroundColor:'#BDBDBD',borderTop:'solid',borderRight:'solid', borderLeft:'solid', borderBottom:'solid', textalign:'left'}} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={3} style={{fontWeight:'bold'}}>
                <Typography>
                    Änderung der Staatsangehörigkeit
                </Typography>
            </Grid>
            <Grid item xs={9} style={{fontWeight:'bold'}}>
                <Typography>
                    Schlüssel der<strong> neuen</strong> Staatsangehörigkeit
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'30px',textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12} sytle={{ float:'left'}}>
              <Typography>
                <strong>Wenn keine Versicherungs Nr. angegeben werden kann</strong>
              </Typography>
        </Grid>
        <Grid item xs={6} style={{}}>
            <Typography style={{float:'left',lineHeight:"32px", marginLeft:'10px'}}>
              Geburtsname:    
            </Typography>
            <TextField  id="standard-basic" label="" disabled placeholder="" fullWidth  style={{width:"50%",display:"flex"}} onChange={(event) => setzeAntworten('nameaenderung',event.target.value)}/>
          </Grid>
          <Grid item xs={6} style={{}}>
            <Typography style={{float:'left',lineHeight:"32px"}}>
              Geburtsort:   
            </Typography>
            <TextField  id="standard-basic" label="" disabled placeholder="" fullWidth  style={{width:"50%",display:"flex"}}onChange={(event) => setzeAntworten('geburtsort',event.target.value)}/>
          </Grid>
          <Grid item xs={2} style={{}}>
            <Typography>
               Geburtsdatum:
            </Typography>
          </Grid>
          <Grid item xs={10} style={{}}>
           <Typography>
             Geschlecht
           </Typography>
          </Grid>
          <Grid item xs={2} style={{}}>
          <div style={{}}>
          <TextField  
              id="Gdatum" 
              variant="outlined" 
              label="Geburtsdatum " 
//              error={fehlerliste["Gdatum"]}
              disabled
              margin='normal'
              placeholder=""  style={{display:""}}
//              onChange={(event) => setzeAntworten('Gdatum',event.target.value)}
              InputLabelProps={{
                  shrink: true,
              }}/>              </div>
          </Grid>
          <Grid item xs={10} style={{}}>
            <TextField
              select // durch select wird DropDown initialisiert
              id="geschlecht"
              label="Geschlecht:"
              disabled
              error={fehlerliste["geschlecht"]}
              fullWidth
              value={antworten['geschlecht']||''}
              onChange={(event) => setzeAntworten('geschlecht',event.target.value)}
              variant="outlined"
    //         onChange={(event) => setLiga(event.target.value)} 
              margin="normal"
    //         error={ErrorLiga !==""}
    //         helperText={ErrorLiga}
              InputLabelProps={{
                  shrink: true,
              }}       
              >
              <MenuItem value={10}>weiblich</MenuItem>
              <MenuItem value={20}>männlich</MenuItem>
              <MenuItem value={20}>divers</MenuItem>

            </TextField>           
          </Grid>
          <Grid item xs={12} sytle={{fontWeight:'bold'}}>
              <Typography>
                  Nur bei erstmaliger Aufnahme einer Beschäftigung von nichtdeutschen Bürgern des Europäischen Wirtschaftsraumes:
              </Typography>
          </Grid>
          <Grid item xs={5}>
              <Typography>
                  Geburtsland (Schlüssel der Staatsangehörigkeit)
              </Typography>
          </Grid>
          <Grid item xs={7}>
              Versicherungsnummer des Staatsangehörigkeitslandes
          </Grid>
          <Grid item xs={12}>
              <Typography>
                  {antworten['StaatA']}
              </Typography>
          </Grid>
          <Grid item xs={6}style={{}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
                Name der Einzugsstelle:
              </Typography>
              <Typography style={{width:"50%",borderBottom:"solid"}}>
               AOK-Baden-Württemberg
              </Typography>
          </Grid>
          <Grid item xs={6}style={{}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
                Betriebsnummer der Einzugsstelle:
              </Typography>
              <Typography style={{width:"50%",borderBottom:"solid"}}>
              674 506 65
              </Typography>
          </Grid>
          <Grid item xs={6}style={{}}>

          </Grid>
          <Grid item xs={6}style={{}}>
              <Typography  style={{float:"left",width:"50%",fontSize:"1em",lineHeight:"32px"}}>
                Betriebsnummer der UV:
              </Typography>
          </Grid>



    </Grid>



    <Grid container spacing={1} style={{marginTop:'30px',borderBottom:'solid', borderTop:'solid',borderLeft:'solid',borderRight:'solid'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{}}>
                !
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  WICHTIGES DOKUMENT - sorgfältig aufbewahren! <br />
                  IMPORTANT DOCUMENT - keep it for future reference! <br />
                  DOCUMENT IMPORTANT - a conserver prècieusement! <br />
                  ÖNEMLI BELGE - titizlikle saklayiniz!
              </ListItemText>  
              <ListItemText>
                  DOCUMENTO IMPORTANTE - conservare accuratamente!<br />
                  IMPORTANTE DOCUMENTO - guàrdelo por favor cuidadosamente!<br />
                  DOCUMENTO IMPORTANTE - guardar cuidadosamente!<br />
                  VAZAN DOKUMENT - brizljivo cuvati!
              </ListItemText>  
              <ListItemAvatar>
              <Avatar style={{}}>
                !
              </Avatar>
            </ListItemAvatar>
          </ListItem>
    </Grid>   


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>


    </div>

    )
}


export default DEUEVAnm;