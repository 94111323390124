import React from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';


function TabelleBBG (props) {
    const {tabellenKey, fehlerliste, setzeAntworten} = props;
    
    return(
        <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              Bezeichnung
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',borderTop:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
            Tage
            </Typography>
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              Pflichtig
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',borderTop:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              AN-Anteil
            </Typography>
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              AG-Anteil
            </Typography>
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
            <Typography style={{marginTop:'13px'}}>
                KV LFD
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid'}}>
            <TextField  
                id={'tageKV'} 
                type='number'
                error={fehlerliste["tageKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex", backgroundColor:(fehlerliste["tageKV"+tabellenKey]===true)?"red":""}}
                onChange={(event) => setzeAntworten('tageKV'+tabellenKey,event.target.value)}/>

          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
            <TextField  
                id={'pflichtigKV'} 
                type='number'
                error={fehlerliste["pflichtigKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex", backgroundColor:(fehlerliste["pflichtigKV"+tabellenKey]===true)?"red":""}}
                onChange={(event) => setzeAntworten('pflichtigKV'+tabellenKey,event.target.value)}/>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <Typography>
                
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <Typography>
                
            </Typography>
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  KV EGA
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  RV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid'}}>
              <TextField  
                  id={'tageRV'} 
                  type='number'

                  error={fehlerliste["tageRV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex", backgroundColor:(fehlerliste["tageRV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('tageRV'+tabellenKey,event.target.value)}/>
  
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
              <TextField  
                  id={'pflichtigRV'} 
                  type='number'
                  error={fehlerliste["pflichtigRV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex", backgroundColor:(fehlerliste["pflichtigRV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('pflichtigRV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    RV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={1}>
                
              </Grid>
              <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  AV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid'}}>
              <TextField  
                  id={'tageAV'+tabellenKey} 
                  type='number'
                  error={fehlerliste["tageAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex", backgroundColor:(fehlerliste["tageAV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('tageAV'+tabellenKey,event.target.value)}/>
  
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
              <TextField  
                  id={'pflichtigAV'+tabellenKey} 
                  type='number'
                  error={fehlerliste["pflichtigAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex", backgroundColor:(fehlerliste["pflichtigAV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('pflichtigAV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    AV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={1}>
                
              </Grid>
              <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  PV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid'}}>
              <TextField  
                  id={'tagePV'+tabellenKey} 
                  type='number'
                  error={fehlerliste["tagePV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex", backgroundColor:(fehlerliste["tagePV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('tagePV'+tabellenKey,event.target.value)}/>
  
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
              <TextField  
                  id={'pflichtigPV'+tabellenKey} 
                  type='number'
                  error={fehlerliste["pflichtigPV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex",backgroundColor:(fehlerliste["pflichtigPV"+tabellenKey]===true)?"red":""}}
                  onChange={(event) => setzeAntworten('pflichtigPV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <Typography>
                  
              </Typography>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    PV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
                <Typography>
                    
                </Typography>
              </Grid>
              <Grid item xs={1}>                
              </Grid>
      </Grid>
    
      



    
    )
}



export {TabelleBBG};


