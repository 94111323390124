import React, {useState, useCallback, useEffect} from 'react';
import {Avatar, Box, Button, Card, CardContent, CardMedia, Container, Typography, FormGroup, FormControlLabel, Checkbox, MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {KursAufgabenErledigt} from '../../components/Rahmendaten/KursAufgabenErledigt';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';


function ScheckMaerz2(props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, } = props;
    const [merkmal]=useState('ScheckTestStamblPGR');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [fragenzaehler, setFragenzaehler]=useState(0)
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);
    const [kurskennung, setKurskennung]=useState("ScheckTestStamblPGR");
    const [reactnummer]=useState('826');

    const setRedirect = (path) => {
      navigate(path);
    }


    useEffect(() => {
        rahmendaten_holen();
      },[])
  
      const rahmendaten_holen = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "kursdaten",
          "reactnummer": reactnummer,
          "kurskennung": kurskennung,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
      }
  
      const kursinhalt = (response) => {
        if (response.status === 200) {
          if (response.data.links) {
            setLinks(response.data.links)
          }
          if (response.data.lohndaten) {
            setLohndatenwerte(true);
            setLohndaten(response.data.lohndaten);
          }
        }
      }

    const setzeAntworten = ( key,value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        forceUpdate();   
      }
    
      const fehlerAusLesen=(fehler)=>{
        let array=fehler.split(";");
        let fehlerzwis=[]
        array.map(
          // eslint-disable-next-line
          (objekt)=> {
            fehlerzwis[objekt]=true;    
          }
        );
        setFehlerliste(fehlerzwis);
      };
    
    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "aufgabeerl",
          "merkmal": merkmal,
          "daten": antworten,
          "reactnummer": reactnummer,
          "frage":fragenzaehler,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'aufgabeerl', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
          fehlerAusLesen(response.data.fehler);

        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

      const DokumentOeffnen = (docnr, docbez, docendung) => {
        const payload={
          "kennung": "kursdaten",
          "kurskennung": "download_lohndaten",
          "reactnummer": reactnummer,
          "docnr":docnr,
          "docname": docbez,
          "hsutkey": hsutkey
        }
        const headers={
          "Authorization": "Bearer "+accessToken,
          "Accept": "application/pdf"
        }
        axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
          .then((response) => {
            if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
            var contentFile = response.data;
            const mimeType = response.headers['content-type'] || 'application/pdf'
            openFile(fileName, contentFile,mimeType);
          })
          .catch(function (error) {
            showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
          })
      }
  

return(
 <div>

 <Briefkopf  
    ueberschrift1="Fragen zur Entgeltabrechnung"
    ueberschrift2="Alles verstanden? Dann beantwortest Du die Fragen im handumdrehen." 
 />

<Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center", alignItems:"center"}} >
{
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={6}>
              <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 200, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Stammblatt in einem neuen Tab anzeigen!
                </Button>

              </Card>
            </Grid>
          )
        })
    }
</Grid>



 <Grid style={{marginTop:'60px',  backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
   <ListItem>
     <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
            Bitte stelle anhand des Stammblatt 100 Fridolin Fisch die Personengruppe, Beitragsgruppe und die Beschäftigungsart fest!
            </Typography>
          </ListItemText>
        </ListItem>

    {/* <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}> */}
      <VariableCheckBox
        tabellenKey="1" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 101 \r\n Beitragsgruppe: 0110 \r\n Versicherungsstatus: private KV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 0110 \r\n Versicherungsstatus: private KV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in der Gleitzone",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in der Gleitzone"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    {/* </div> */}
    </Grid>


    <Grid style={{marginTop:'60px' }} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
                Bitte legen Sie anhand des Stammblattes von Winifred Wassermann die Personengruppe, Beitragsgruppe und Beschäftigungsart fest."
            </Typography>
          </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="2" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 109 \r\n Beitragsgruppe: 6500 \r\n Versicherungsstatus: geringfügig mit RV Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Teilzeit",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                    "Personengruppe: 109 \r\n Beitragsgruppe: 6100 \r\n Versicherungsstatus: geringfügig ohne RV Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    </Grid>


    <Grid style={{marginTop:'60px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F3
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Bitte legen Sie anhand des Stammblattes von Lenny Löwe die Personengruppe, Beitragsgruppe und Beschäftigungsart fest."
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="3" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 109 \r\n Beitragsgruppe: 6100 \r\n Versicherungsstatus: geringfügig ohne RV Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung",
                    "Personengruppe: 106 \r\n Beitragsgruppe: 0100 \r\n Versicherungsstatus: RV pflichtig \r\n Beschäftigungsart: Werkstudent",
                    "Personengruppe: 110 \r\n Beitragsgruppe: 0000 \r\n Versicherungsstatus: sv-frei \r\n Beschäftigungsart: Kurzfriste Beschäftigung",
                    "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'60px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F4
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Bitte legen Sie anhand des Stammblattes von Knut Krebs die Personengruppe, Beitragsgruppe und Beschäftigungsart fest.
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="4" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Personengruppe: 101 \r\n Beitragsgruppe: 9111 \r\n Versicherungsstatus: freiwillige KV - Firmenzahler \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 0110 \r\n Versicherungsstatus: private KV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit",
                     "Personengruppe: 101 \r\n Beitragsgruppe: 1111 \r\n Versicherungsstatus: gesetzlich versichert in allen Zweigen der SV \r\n Beschäftigungsart: sv-pflichtige Beschäftigung in Teilzeit"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'60px',  backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F5
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Bitte legen Sie anhand des Stammblattes von Julia Jungfrau die Personengruppe, Beitragsgruppe und die Beschäftigungsart fest."
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="5"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Personengruppe: 109 \r\n Beitragsgruppe: 6500 \r\n Versicherungsstatus: geringfügig mit RV-Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung",
                     "Personengruppe: 110 \r\n Beitragsgruppe: 0000 \r\n Versicherungsstatus: sv-frei \r\n Beschäftigungsart: kurzfristige Beschäftigung",
                     "Personengruppe: 106 \r\n Beitragsgruppe: 0100 \r\n Versicherungsstatus: RV-pflichtig \r\n Beschäftigungsart: Werkstudent",
                     "Personengruppe: 109 \r\n Beitragsgruppe: 6100 \r\n Versicherungsstatus: geringfügig ohne RV-Befreiung \r\n Beschäftigungsart: Geringfügige Beschäftigung"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <KursAufgabenErledigt  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
    </div>
    )
}
export default ScheckMaerz2;
