import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function Tabelle8spaltig (props) {
   const {tabellenKey, fehlerliste, setzeAntworten, antworten, anzReihen, ueberschrift, uebschr_spalte1, uebschr_spalte2, uebschr_spalte3, uebschr_spalte4, uebschr_spalte5, uebschr_spalte6, uebschr_spalte7, uebschr_spalte8, 
          spalten1, spalten2, spalten3, spalten4, spalten5, spalten6, spalten7, spalten8, spalte1Platz, spalte2Platz, spalte3Platz, spalte4Platz, spalte5Platz, spalte6Platz, spalte7Platz, spalte8Platz, tabhoehe} = props;

   let spalten1Blockiert=[""];
   let spalten2Blockiert=[""];
   let spalten3Blockiert=[""];
   let spalten4Blockiert=[""];
   let spalten5Blockiert=[""];
   let spalten6Blockiert=[""];
   let spalten7Blockiert=[""];
   let spalten8Blockiert=[""];

    let ausrichtung = "center"
   for (let B = 0; B < anzReihen; B++) {
        if (spalten1[B] !== '') {
            antworten["spalt1"+tabellenKey+B]=spalten1[B];
            spalten1Blockiert[B]=true;
        }
     }    
     for (let B = 0; B < anzReihen; B++) {
        if (spalten2[B] !== '') {
            antworten["spalt2"+tabellenKey+B]=spalten2[B];
            spalten2Blockiert[B]=true;
        }
     }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten3[B] !== '') {
            antworten["spalt3"+tabellenKey+B]=spalten3[B];
            spalten3Blockiert[B]=true;
        }
    }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten4[B] !== '') {
            antworten["spalt4"+tabellenKey+B]=spalten4[B];
            spalten4Blockiert[B]=true;
        }
    }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten5[B] !== '') {
            antworten["spalt5"+tabellenKey+B]=spalten5[B];
            spalten5Blockiert[B]=true;
        }
    }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten6[B] !== '') {
            antworten["spalt6"+tabellenKey+B]=spalten6[B];
            spalten6Blockiert[B]=true;
        }
    }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten7[B] !== '') {
            antworten["spalt7"+tabellenKey+B]=spalten7[B];
            spalten7Blockiert[B]=true;
        }
    }    

    for (let B = 0; B < anzReihen; B++) {
        if (spalten8[B] !== '') {
            antworten["spalt8"+tabellenKey+B]=spalten8[B];
            spalten8Blockiert[B]=true;
        }
    }    

    let reihen=[];
    for (let i = 0; i < anzReihen; i++) {
         reihen.push("A")
    }
        
    const reihenEinfügen  = reihen.map((value, i) => {
        return (    
            <TableRow >
                <TableCell key="Spalte1" style={{textAlign:spalte1Platz}}>
                    <Eingabe 
                        disabled={spalten1Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt1"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["spalt1"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte1Platz}}}
                        value={antworten["spalt1"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt1'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>
                <TableCell key="Spalte2" style={{textAlign:spalte2Platz}}>
                    <Eingabe 
                        disabled={spalten2Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt2"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt2"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte2Platz}}}
                        value={antworten["spalt2"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt2'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>
                <TableCell key="Spalte3" style={{textAlign:spalte3Platz}}>
                    <Eingabe 
                        disabled={spalten3Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt3"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt3"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte3Platz}}}
                        value={antworten["spalt3"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt3'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>

                <TableCell key="Spalte4" style={{textAlign:spalte4Platz}}>
                    <Eingabe 
                        disabled={spalten4Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt4"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt4"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte5Platz}}}
                        value={antworten["spalt4"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt4'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>

                <TableCell key="Spalte5" style={{textAlign:spalte5Platz}}>
                    <Eingabe 
                        disabled={spalten5Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt5"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt5"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte5Platz}}}
                        value={antworten["spalt5"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt5'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>

                <TableCell key="Spalte6" style={{textAlign:spalte6Platz}}>
                    <Eingabe 
                        disabled={spalten6Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt6"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt6"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte6Platz}}}
                        value={antworten["spalt6"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt6'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>

                <TableCell key="Spalte7" style={{textAlign:spalte4Platz}}>
                    <Eingabe 
                        disabled={spalten7Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt7"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt7"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte7Platz}}}
                        value={antworten["spalt7"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt7'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>

                <TableCell key="Spalte8" style={{textAlign:spalte8Platz}}>
                    <Eingabe 
                        disabled={spalten8Blockiert[i]}
                        placeholder={"Eingabe"}
                        error={fehlerliste["spalt8"+tabellenKey+i]}     
                        style={{display:"inline-block",marginTop:"7px", backgroundColor:(fehlerliste["spalt8"+tabellenKey+i]===true)?"red":""}}
                        inputProps={{style:{textAlign:spalte8Platz}}}
                        value={antworten["spalt8"+tabellenKey+i]||''}
                        onChange={(event) => setzeAntworten('spalt8'+tabellenKey+i,event.target.value)}
                        >
                    </Eingabe>
                </TableCell>
            </TableRow>
    );})

 
return(
 
    <div>
    <Grid container spacing={0} style={{border:'solid', marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
            <Typography style={{textAlign:'center', fontWeight:"bold", backgroundColor:'#EDEEEE', width:"100%" }} >
                {ueberschrift}
            </Typography>

           <TableContainer style={{height:tabhoehe}}>
                <Table stickyHeader >
                    <TableHead >
                        <TableRow>
                            <TableCell style={{textAlign:spalte1Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte1}</TableCell>
                            <TableCell style={{textAlign:spalte2Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte2}</TableCell>
                            <TableCell style={{textAlign:spalte3Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte3}</TableCell>
                            <TableCell style={{textAlign:spalte4Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte4}</TableCell>
                            <TableCell style={{textAlign:spalte5Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte5}</TableCell>
                            <TableCell style={{textAlign:spalte6Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte6}</TableCell>
                            <TableCell style={{textAlign:spalte7Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte7}</TableCell>
                            <TableCell style={{textAlign:spalte8Platz, backgroundColor:'#EDEEEE'}}>{uebschr_spalte8}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reihenEinfügen}
                    </TableBody>
                </Table>
            </TableContainer>
    </Grid>
    </div>


 
  )
}

export {Tabelle8spaltig};