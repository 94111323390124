import React, {useState, useCallback } from 'react';
import {Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {TabelleWissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelleWissenstestE1';



function Maerzklausel2(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('027');
  const [merkmal]=useState("Maerzklausel2");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Die Märzklausel - Zweiter Teil" 
          ueberschrift2="Die Märzklausel besagt, dass in der Zeit vom 1. Januar bis 31. März eines Jahres gezahlte Einmalzahlungen beitragsrechtlich dem Vorjahr zuzuordnen sind, 
                        wenn sie im Monat der Auszahlung nicht in vollem Umfang beitragspflichtig werden."
      />


      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                  Verwende für die Berechnungen folgende BBG
              </Typography>
          </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',height:'30px' }}>
          </Grid>
          <Grid item xs={2} style={{border:'solid',height:'30px'}}>
            <Typography>
              Monats-BBG 2020
            </Typography>
          </Grid>
          <Grid item xs={4} style={{}}>
          </Grid>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              KV
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              4.687,50€
            </Typography>
          </Grid>
          <Grid item xs={4}>        
          </Grid>

          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', borderLeft:'solid', height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              RV
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderLeft:'solid', borderBottom:'solid', borderRight:'solid',height:'50px'}}>
            <Typography style={{marginTop:'10px'}}>
              6.900,00€
            </Typography>
          </Grid>
          <Grid item xs={4} >
          </Grid>
      </Grid>


      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 1
            </Typography>
            <Typography>
              Abrechnungsmonat Februar 2020 <br/>
              Gehalt: 4000,- €<br/>
              Sonderzahlung: 1000,- € <br/>
              Der Verdienst im Januar betrug 4000,- € <br/>
              Der Eintritt war im Februar 2019 <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen 
        tabellenkey='1'  
        antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' 
        antwort2='Da die Sonderzahlung komplett im Februar verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' 
        antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' 
        setzeAntworten={setzeAntworten} 
        antworten={antworten} fehlerliste={fehlerliste}
      />
      
      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 2
            </Typography>
            <Typography>
              Abrechnungsmonat Januar 2020 <br/>
              Gehalt: 3500,- €<br/>
              Sonderzahlung: 1750,- € <br/>
              Der Eintritt war am 01.06.2017 <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen 
        tabellenkey='2' 
        antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' 
        antwort2='Da die Sonderzahlung komplett im Januar verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' 
        antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' 
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 3
            </Typography>
            <Typography>
              Abrechnungsmonat März 2020 <br/>
              lfd. Entgelt im März: 3500,-€ <br/>
              Sonderzahlung im März = 3500,- € <br/> <br/>
              Die Vergütung im Januar betrug 3000,- €<br/> 
              Die Vergütung im Februar betrug 3300,- €<br/>
              Der Eintritt war am 01.07.2018 <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen tabellenkey='3'  antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' antwort2='Da die Sonderzahlung komplett im März verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 4
            </Typography>
            <Typography>
              Abrechnungsmonat Februar 2020 <br/>
              Arbeitnehmer ist in der KV freiwillig versichert <br/>
              lfd. Entgelt im Februar: 5000,-€ <br/>
              Sonderzahlung im Februar = 3000,- € <br/> <br/>
              Die Vergütung im Januar betrug 5000,- €<br/> 
              Der Eintritt war am 01.10.2019  <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen tabellenkey='4'  
        antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' 
        antwort2='Da die Sonderzahlung komplett im Februar verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' 
        antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' 
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 5
            </Typography>
            <Typography>
              Abrechnungsmonat März 2020 <br/>
              lfd. Entgelt März: 3200,-€ <br/>
              Sonderzahlung März: 3200,- € <br/> <br/>
              Vergütung Januar: 2900,- € <br/> 
              Vergütung Februar: 3200,- € <br/> 
              Eintritt: 01.01.2019  <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen 
          tabellenkey='5'
          antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' 
          antwort2='Da die Sonderzahlung komplett im März verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' 
          antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' 
          setzeAntworten={setzeAntworten} 
          antworten={antworten} fehlerliste={fehlerliste}
      />

      <Grid container spacing={0} style={{marginTop:'20px', textAlign:'left'}} justifyContent={"center"} >
        <Grid item xs={12}>
            <Typography variant="h4">
                Aufgabe 6
            </Typography>
            <Typography>
              Abrechnungsmonat März 2020 <br/>
              lfd. Entgelt März: 2700,-€ <br/>
              Sonderzahlung März: 8000,- € <br/> <br/>
              Vergütung Januar: 2970,- €<br/> 
              Vergütung Februar: 3100,- €
              Eintritt: 01.01.2020  <br/>
            </Typography>
        </Grid>
      </Grid>

      <TabelleWissen 
          tabellenkey='6'  
          antwort1='In dem Abrechnungsmonat greift die Märzklausel und die Sonderzahlung ist im Vorjahr abzurechnen.' 
          antwort2='Da die Sonderzahlung komplett im März verbeitragt werden kann, ist die Märzklausel nicht anzuwenden.' 
          antwort3='Da der Arbeitnehmer in 2020 in die Firma eingetreten ist, kann keine Märzklausel angewendet werden.' 
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
      />

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />



</div>
)
}


export default Maerzklausel2;