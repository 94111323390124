import React from 'react';

import {Grid, Typography, TextField, Avatar, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';



function TabelleGwVorteilBerechnung (props) {
  const { tabellenKey, fehlerliste, setzeAntworten, aufgabe,titel} = props;



  

  return(

    <Grid container spacing={1} style={{justify:"center",  alignItems:"center", textAlign:'left'}} >      
        <Grid Item xs={12}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  {aufgabe}
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                {titel}
              </ListItemText>    
          </ListItem>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                Kaufpreis des Gegenstands
            </Typography>
        </Grid>
        <Grid item xs={1}>
            <TextField 
                type='number' 
                id={"kaufpreis"+tabellenKey}  
                error={fehlerliste["kaufpreis"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}onChange={(event) => setzeAntworten('kaufpreis'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                abzüglich 4% pauschaler Abschlag
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"pauschalA"+tabellenKey}  
                error={fehlerliste["pauschalA"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}onChange={(event) => setzeAntworten('pauschalA'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3} style={{borderBottom:"solid"}}>
        </Grid>
        <Grid item xs={1} style={{borderBottom:"solid"}}>
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={4}>
        </Grid>
        <Grid item xs={3}>
            <Typography >
                verbleibender, steuerlich maßgebender Wert des Sachbezugs
            </Typography>
        </Grid>
        <Grid item xs={1} >
            <TextField 
                type='number' 
                id={"wert"+tabellenKey}  
                error={fehlerliste["wert"+tabellenKey]}
                label=""
                inputProps={{style:{textAlign:"right"}}}
                placeholder="Eintrag"  
                style={{display:"flex",height:'50px'}}onChange={(event) => setzeAntworten('wert'+tabellenKey,event.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
        </Grid>

  </Grid>
   
  )
}

export {TabelleGwVorteilBerechnung};