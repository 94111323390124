import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, TextField, Typography, Grid, Avatar, MenuItem, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";
import {BruttoNeu2} from '../../components/Rahmendaten/BruttoNeu2';
import ForwardIcon from '@mui/icons-material/Forward';

const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckAbfindung (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('838');
    const [kurskennung, setKurskennung]=useState("ScheckAbfindung");
    const [merkmal]=useState('ScheckAbfindung');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [fehlerliste,setFehlerliste]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);
        }
      }
    }

    const antwortenSenden = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursabgabe",
        "merkmal": merkmal,
        "daten": antworten,
        "reactnummer": reactnummer,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  
    }
  
    const kurseCb = (response) => {
      if (response.status === 200) {
        setTestabgabe(true);
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
        fehlerAusLesen(response.data.fehler);
      } else {
        if (response) {
          props.showError(response.data.message);
        } else {
          props.showError('Keine Internetverbindung');
        }
      }
    }

    const fehlerAusLesen=(fehler)=>{
      let array=fehler.split(";");
      let fehlerzwis=[]
      array.map(
        // eslint-disable-next-line
        (objekt)=> {
          fehlerzwis[objekt]=true;    
        }
      );
      setFehlerliste(fehlerzwis);
    };
    
    const DokumentOeffnen = (docnr, docbez, docendung) => {
      const payload={
        "kennung": "kursdaten",
        "kurskennung": "download_lohndaten",
        "reactnummer": reactnummer,
        "docnr":docnr,
        "docname": docbez,
        "hsutkey": hsutkey
      }
      const headers={
        "Authorization": "Bearer "+accessToken,
        "Accept": "application/pdf"
      }
      axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
        .then((response) => {
          if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
          var contentFile = response.data;
          const mimeType = response.headers['content-type'] || 'application/pdf'
          openFile(fileName, contentFile,mimeType);
        })
        .catch(function (error) {
          showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
        })
    }

    const setzeAntworten = ( key,value) => {
      let values = Object.assign(antworten);
      values[key] = value;
  //    console.log(values)
      setAntworten(values);
      forceUpdate();   
    }

return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im neunten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h4' align="left">
            <b>A</b>bfindungen und <b>F</b>ünftelregelung <br/>
            Wie gehört das zusammen?<br/>
        </Typography>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>
    
    <Fragment>
    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
            <ForwardIcon/>
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                <b>Wissen selbst erarbeiten</b> <br/>
                Lese Dir alle zur Verfügung gestellten Erklärungen und Beschreibungen intensiv durch und eigne Dir dadurch Wissen an. 
                Dieses Wissen musst Du dann abrufen können, wenn es um Abfindungen geht.<br/>
                Und glaube mir, das kommt häufiger vor als Du denkst.<br/><br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>


    {
       lohndaten?.map((inhalt,index) => {
          return(
            <Fragment>
            <Grid item xs={10}>
            <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchLeseUndLerne}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{width: 600, display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{ flex: '1 0 auto'}}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Klicke darauf und lade die Ausarbeitung herunter
                </Button>

              </Card>
            </Grid>
            </Fragment>
          )
        })
    }

    <Grid style={{display: 'flex', marginTop:'20px'}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                <b>Aufgabe 1</b> <br/>
                Der Mitarbeiter erhält eine Vergütung von monatlich 4000,00 Euro und hatte im Monat Mai ein Anteiliges Urlaubsgeld von 2000,00 Euro.<br/>
                Durch einen Aufhebungsvertrag endet das Beschäftigungsverhältnis zum 30.06.2022.<br/>
                Im letzten Beschäftigungsmonat soll eine Abfindung in Höhe von 30.000,00 Euro bezahlt werden.<br/><br/>
                Bitte ergänze die Bruttolohnart "Abfindung" und wähle bei der Kennung die korrekte Versteuerungsregel (Laufen (LFD), Einmalzahlung (EGA) oder Fünftelregelung (MKJ mehrere Kalenderjahre)).
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>
    <Typography variant='h5' style={{marginTop:"20px"}}>Abrechnungsmonat Juni</Typography>
    <BruttoNeu2
              tabellenKey="1" 
              anzReihen={2}
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Abfindung"]}
              Tage={["21.75","---"]}
              Stunden={["174.00","---"]}
              Faktor={["4000.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["4000.00",""]}
              Sozialverspflichtig={["4000.00",""]}
              Betrag={["4000.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
    /> 


<Grid style={{display: 'flex', marginTop:'80px'}} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
              A2
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant='h5'>
              <b>Aufgabe 2</b> <br/>
              Der Mitarbeiter erhält eine Vergütung von monatlich 3500,00 Euro.<br/>
              Durch eine Kündigung des Arbeitgebers endet das Beschäftigungsverhältnis zum 31.10.2022.<br/>
              Im letzten Beschäftigungsmonat soll eine Abfindung in Höhe von 10.000,00 Euro bezahlt werden.<br/><br/>
              Bitte ergänze die Bruttolohnart "Abfindung" und wähle bei der Kennung die korrekte Versteuerungsregel (Laufen (LFD), Einmalzahlung (EGA) oder Fünftelregelung (MKJ mehrere Kalenderjahre)).
          </Typography>
        </ListItemText>
      </ListItem>
    </Grid>
</Grid>
<Typography variant='h5' style={{marginTop:"20px"}}>Abrechnungsmonat Oktober</Typography>
<BruttoNeu2
              tabellenKey="2" 
              anzReihen={2}
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Abfindung"]}
              Tage={["21.75","---"]}
              Stunden={["174.00","---"]}
              Faktor={["3500.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["3500.00",""]}
              Sozialverspflichtig={["3500.00",""]}
              Betrag={["3500.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
    /> 

<Grid style={{display: 'flex', marginTop:'80px'}} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
              A3
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant='h5'>
              <b>Aufgabe 3</b> <br/>
              Der Mitarbeiter erhält eine Vergütung von monatlich 4500,00 Euro.<br/>
              Durch eine Kündigung des Arbeitgebers  endet das Beschäftigungsverhältnis zum 30.04.2022.<br/>
              Im letzten Beschäftigungsmonat soll eine Abfindung in Höhe von 35.000,00 Euro bezahlt werden.<br/><br/>
              Bitte ergänze die Bruttolohnart "Abfindung" und wähle bei der Kennung die korrekte Versteuerungsregel (Laufen (LFD), Einmalzahlung (EGA) oder Fünftelregelung (MKJ mehrere Kalenderjahre)).
          </Typography>
        </ListItemText>
      </ListItem>
    </Grid>
</Grid>
<Typography variant='h5' style={{marginTop:"20px"}}>Abrechnungsmonat April</Typography>
<BruttoNeu2
              tabellenKey="3" 
              anzReihen={2}
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Abfindung"]}
              Tage={["21.75","---"]}
              Stunden={["174.00","---"]}
              Faktor={["4500.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["4500.00",""]}
              Sozialverspflichtig={["4500.00",""]}
              Betrag={["4500.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
    /> 


<Grid style={{display: 'flex', marginTop:'80px'}} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
              A4
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant='h5'>
              <b>Aufgabe 4</b> <br/>
              Der Mitarbeiter erhält eine Vergütung von monatlich 5000,00 Euro.<br/>
              Durch eine Kündigung des Arbeitgebers  endet das Beschäftigungsverhältnis zum 31.01.2022.<br/>
              Im letzten Beschäftigungsmonat soll eine Abfindung in Höhe von 50.000,00 Euro bezahlt werden.<br/><br/>
              Bitte ergänze die Bruttolohnart "Abfindung" und wähle bei der Kennung die korrekte Versteuerungsregel (Laufen (LFD), Einmalzahlung (EGA) oder Fünftelregelung (MKJ mehrere Kalenderjahre)).
          </Typography>
        </ListItemText>
      </ListItem>
    </Grid>
</Grid>
<Typography variant='h5' style={{marginTop:"20px"}}>Abrechnungsmonat Januar</Typography>
<BruttoNeu2
              tabellenKey="4" 
              anzReihen={2}
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Abfindung"]}
              Tage={["21.75","---"]}
              Stunden={["174.00","---"]}
              Faktor={["5000.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["5000.00",""]}
              Sozialverspflichtig={["5000.00",""]}
              Betrag={["5000.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
    /> 


<Grid style={{display: 'flex', marginTop:'80px'}} >
    <Grid item xs={12}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
              A5
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant='h5'>
              <b>Aufgabe 5</b> <br/>
              Der Mitarbeiter erhält eine Vergütung von monatlich 5000,00 Euro.<br/>
              Durch eine Kündigung des Arbeitgebers  endet das Beschäftigungsverhältnis zum 31.08.2022.<br/>
              Im Januar 2023 soll eine Abfindung in Höhe von 50.000,00 Euro bezahlt werden.<br/><br/>
              Bitte ergänze die Bruttolohnart "Abfindung" und wähle bei der Kennung die korrekte Versteuerungsregel (Laufen (LFD), Einmalzahlung (EGA) oder Fünftelregelung (MKJ mehrere Kalenderjahre)).
          </Typography>
        </ListItemText>
      </ListItem>
    </Grid>
</Grid>
<Typography variant='h5' style={{marginTop:"20px"}}>Abrechnungsmonat Januar</Typography>
<BruttoNeu2
              tabellenKey="5" 
              anzReihen={2}
              Lohnart={['1029','3111']}
              Intern={['029','111']}
              Bezeichnung={["Gehalt","Abfindung"]}
              Tage={["21.75","---"]}
              Stunden={["174.00","---"]}
              Faktor={["5000.00",""]}
              Kennung={["LFD",""]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["5000.00",""]}
              Sozialverspflichtig={["5000.00",""]}
              Betrag={["5000.00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
    /> 

</Fragment>

<Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />
</div>
)
}

export default ScheckAbfindung;