import React, {useState, useCallback} from 'react';
import {Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';


function FirmenwagenZuzahlung(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [ anzFehler,setAnzFehler]=useState(0);
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('043');
  const [merkmal]=useState("FirmenwagenZuzahlung");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="Zuzahlungen zum Firmenwagen" 
            ueberschrift2="Wie sieht die Entgeltabrechnung aus, wenn der Arbeitnehmer für seinen Firmenwagen Zuzahlungen leistet?" 
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  Bsp
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  <strong>Beispiel 1</strong><br/>
                </Typography>
                <Typography>
                  Die Arbeitstage des Arbeitnehmers betragen 5 Tage (Mo-Fr) pro Woche.<br/>
                  Der Bruttolistenpreis seines Firmenfahrzeugs beträgt 35600,- Euro.<br/>
                  Da der Arbeitnehmer zu 90 % im Homeoffice arbeitet und von dort aus seine Dienstfahrten tätigt, fällt kein geldwerter Vorteil für Fahrten zwischen Wohnung und regelmässiger Arbeitsstätte an. <br/>
                  <strong>Der Arbeitnehmer leistet monatlich an seinen Arbeitgeber eine Zuzahlung zu seinem Firmenfahrzeug von 100,- Euro.</strong> <br/><br/>
                </Typography>
              </ListItemText>
            </ListItem>
          
            <ListItem style={{marginTop:"40px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  <strong>Aufgabe 1</strong><br/>
                </Typography>
                <Typography>
                  Recherchiere im LohnLexikon und stelle die monatliche Zuzahlung zum Firmenwagen entsprechend dem Beispiel 1 auf der Entgeltabrechnung richtig dar.
                </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={11} >
            <BruttoNeu2
                tabellenKey="1" 
                anzReihen={3}
                Lohnart={['1029','1350',"1345"]}
                Intern={['029','350','345']}
                Bezeichnung={["Gehalt","Firmenwagen 1%","Zuzahlung Firmenwagen"]}
                Tage={["21,75","--","--"]}
                Stunden={["174,00","--","--"]}
                Faktor={["2700,00","356,00",""]}
                Kennung={["LFD","LFD","LFD"]}
                Prozent={["0,00","0,00","0,00"]}
                Steuerpflichtig={["2700,00","356,00",""]}
                Sozialverspflichtig={["2700,00","356,00",""]}
                Betrag={["2700,00","356,00",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6} >
            <NettoLoa2
              anzReihen={2}
              tabellenKey="1" 
              text=''
              NLohnart={['2067','2068']}
              NIntern={['067','068']}
              NBezeichnung={["Firmenwagen 1%","Zuzahlung Firmenwagen"]}
              NBetrag={["356,00",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} >
          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  Bsp
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  <strong>Beispiel 2</strong><br/>
                </Typography>
                <Typography>
                  In einer Firma existiert eine Firmenwagen-Betriebsvereinbarung.<br/>
                  Aus dieser geht unter anderen hervor, dass Vertriebsmitarbeiter ihren Firmenwagen bis zu einem Preis von 35.000,- Euro brutto selbst aussuchen dürfen. <br/>
                  Eine Mitarbeiterin wählt einen Firmenwagen mit einem Preis von 40.000,- Euro und 
                  ist bereit die Differenz von 5000,- Euro der Firma an dem Tag des Erhalts des Fahrzeuges in einem Betrag zu überweisen.<br/>
                  Der Bruttolistenpreis für das Fahrzeug beträgt 43.000,- Euro.
                  Die Entfernungskilometer von Wohnung zur regelmässigen Arbeitsstätte betragen 7 Km. <br/>
                  Da die Mitarbeiterin an 5 Tagen die Woche in die Firma fährt, sind die Fahrten Wohnung/Arbeit als geldwerten Vorteil zu versteuen.<br/>
                  Das Fahrzeug wird der Arbeitnehmerin am 01. Juni übergeben. <br/>
                  Es wird kein Fahrtenbuch geführt.<br/>
                </Typography>
              </ListItemText>
            </ListItem>
          
            <ListItem style={{marginTop:"40px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5">
                  <strong>Aufgabe 2</strong><br/>
                </Typography>
                <Typography>
                  Recherchiere im LohnLexikon und stelle die Verrechnung der einmaligen Zuzahlung zum Firmenwagen entsprechend dem Beispiel 2 auf der Entgeltabrechnung Juni richtig dar.
                </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={11} >
            <BruttoNeu2
                tabellenKey="2" 
                anzReihen={4}
                Lohnart={['1029','1350',"1350","1345"]}
                Intern={['029','350','350','345']}
                Bezeichnung={["Gehalt","Firmenwagen 1%","Fahrten Whng-Arb","Zuzahlung Firmenwagen"]}
                Tage={["21,75","--","--","--"]}
                Stunden={["174,00","--","--","--"]}
                Faktor={["2700,00","","",""]}
                Kennung={["LFD","LFD","LFD","LFD"]}
                Prozent={["0,00","0,00","0,00","0,00"]}
                Steuerpflichtig={["2700,00","","",""]}
                Sozialverspflichtig={["2700,00","","",""]}
                Betrag={["2700,00","","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <NettoLoa2
              anzReihen={2}
              tabellenKey="2" 
              text=''
              NLohnart={['2067','2068','2069']}
              NIntern={['067','068','068']}
              NBezeichnung={["Firmenwagen 1%","Fahrten Whng-Arb",]}
              NBetrag={["","",]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={12} >
            <VariableCheckBox
                tabellenKey="1" 
                ueberschrift="Wie erklärst Du, warum die Nettolohnart 'Zuzahlung Firmenwagen' in dem Beispiel 2 nicht benötigt wird? Wähle deiner Meinung nach die richtige(n) Antwort(en) aus"
                anzReihen={3}
                Checktexte={["Der Betrag wird im Brutto in allen 3 Spalten (Steuerpflichtig, Sozialvers.pflichtig und Betrag) berücksichtigt. Somit darf der Betrag nicht nochmals im Netto berücksichtigt werden.",
                            "Die Nettolohnart wurde schlichtweg vergessen anzugeben. Eigentlich sollte die Nettolohnart <Zuzahlung Firmenwagen> auf der Entgeltabrechnung erscheinen. ",
                            "Entsprechend dem Beispiel 2 soll der Betrag durch die Arbeitnehmerin am Tage der Übergabe des Firmenfahrzeugs einmalig bezahlt werden. Dadurch ist die Zuzahlung bereits durch die Arbeitnehmerin erfolgt. Bei einem weiteren Abzug über die Nettolohnart würde dann die Zuzahlung ein weiteres Mal durch die Arbeitnehmerin erfolgen. Das wäre falsch!"]}
                checked={[false,false,false]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

      </div>
    )
  }
  

export default FirmenwagenZuzahlung;
