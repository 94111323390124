import * as auth from '../auth-provider'
import {API_BASE_URL} from '../constants/apiConstants';
//const apiURL = process.env.REACT_APP_API_URL

async function client(
  endpoint,
  {data, token, headers: customHeaders, ...customConfig} = {},
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': data ? 'application/json' : '',
      Origin: window.location.origin,
      ...customHeaders,
    },
    ...customConfig,
  }
  return window.fetch(API_BASE_URL+endpoint, config).then(async response => {
    if (response.status === 401) {
      await auth.logout();
      // refresh the page for them
      // window.location.assign(window.location)
      window.location.reload();
      return Promise.reject({message: 'Please re-authenticate.'})
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export {client}