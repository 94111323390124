import React, {useState, useCallback} from 'react';
import {Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEOBLP = '/media/FirmenwagenHypridElektro.mp4';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';

function FirmenwagenStrom(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [ anzFehler,setAnzFehler]=useState(0);
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('041');
  const [merkmal]=useState("FirmenwagenStrom");



  const setzeAntworten = ( key,value) => {
    console.log("step key: "+key)
    console.log("step value: "+value)
    console.log("step antworten: "+antworten)

    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="Firmenwagen Hybrid- und Elektrofahrzeuge" 
            ueberschrift2="Lerne in diesem Kurs die Besonderheiten bei Hybrid- und Elektrofahrzeugen kennen" 
        />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>I</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <Typography variant="h5" >
                      <b>Einleitung</b><br/>
                      In dem vorherigen Kurs hast Du gelernt, wie man den Bruttolistenpreis eines Fahrzeugs ermittelt bzw. berechnet.<br/>
                      An dieser Ermittlung und Berechnung des Bruttolistenpreises ändert sich bei Hybrid- und Elektrofahrzeugen nichts. <br/>
                      Was sich allerdings ändert, ist, dass unter bestimmten Voraussetzungen nur 50 % oder 25 % des Bruttolistenpreises anzusetzen sind.<br/>
                      Erfahre in dem Tutorial, wann das der Fall ist!
                  </Typography>
              </ListItemText>
            </ListItem>
            <Player
                  poster={POSTER}
                  src={VIDEOBLP}
                  >
                  <ControlBar autoHide={false} />
            </Player>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A1</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe Anwendung Voll- und Teil-Bruttolistenpreis bei einem Elektroauto</strong><br/> 
                  In diesem Beispiel beträgt der korrekt ermittelte Bruttolistenpreis eines in 2021 angeschafften Elektroautos 38.563,45 Euro.<br/>
                  Die Versteuerung des geldwerten Vorteils erfolgt mit der 1%-Methode.<br/>
                  Die Entfernungskilometer zwischen der Wohnung und der Firma betragen 8 Km.<br/>
                  Die pauschale Versteuerung der Fahrten Wohnung / Arbeitsstätte wird nicht angewendet.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={4} >
            <Tabelle2spaltig 
                tabellenKey="1" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["100","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={4} >
            <Tabelle2spaltig 
                tabellenKey="2" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["50","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={4} >
            <Tabelle2spaltig 
                tabellenKey="9" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["25","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{marginTop:'15px'}} justifyContent={"center"} alignItems={"center"}>

          <Grid item xs={12} >
            <Typography align="left" variant="h6">
              Der Ausweis des geldwerten Vorteils für den Firmenwagen wird wie folgt im Brutto- und im Nettoblock auf der Entgeltabrechnung dargestellt.
            </Typography>
            <BruttoNeu2
                anzReihen={4}
                Lohnart={['1029','1350',"1350","1349"]}
                Intern={['029','350','350','349']}
                Bezeichnung={["Gehalt","Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb.","Förd.E-Mobil."]}
                Tage={["21,75","--","--","--"]}
                Stunden={["174,00","--","--","--"]}
                Faktor={["2700,00","385,00","92,40","-358,05"]}
                Kennung={["LFD","LFD","LFD","LFD"]}
                Prozent={["0,00","0,00","0,00","0,00"]}
                Steuerpflichtig={["2700,00","385,00","92,40","-358,05"]}
                Sozialverspflichtig={["2700,00","385,00","92,40","-358,05"]}
                Betrag={["2700,00","385,00","92,40","--"]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6} >
            <NettoLoa2
              anzReihen={2}
              text=''
              NLohnart={['2067','2068']}
              NIntern={['067','068']}
              NBezeichnung={["Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb."]}
              NBetrag={["385,00","92,40"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={12} >
            <Typography align="left" variant="h6">
              Kannst Du Dir erklären, warum diese Art der Darstellung, die am geeignetsten ist?
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <VariableCheckBox
                tabellenKey="1" 
                ueberschrift="Welche der folgenden Antwort(en) treffen Deiner Meinung nach zu?"
                anzReihen={3}
                Checktexte={["Diese Darstellung dient einer besseren Transparenz innerhalb der Bruttolohnarten. Die Entgeltabrechnung wird deswegen in der Gesamtheit übersichtlicher.",
                            "Der geldwerte Vorteil eines Firmenwagens unterliegt der Umsatzsteuer. Für die Umsatzsteuer gibt es keine Sonderregelung für Hybrid und Elektrofahrzeuge. Somit ist die Umsatzsteuer aus 100 % des steuerlichen Bruttolistenpreises zu berechnen und abzuführen. Mit dieser Art der Darstellung weisen die Nettolohnarten 1% des 100%igen-Bruttolistenpreises aus und die Umsatzsteuer kann weiterhin korrekt aus den Nettolohnarten ermittelt werden. ",
                            "Die Komplexität der Sonderregelungen bzgl. Hybrid- und Elektrofahrzeugen lassen sich am besten so darstellen, dass die Ersparnis durch die Anwendung von 50% bzw. 25% des Bruttolistenpreises mit einem Minusbetrag hervorgehoben wird."]}
                checked={[false,false,false]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>


      </Grid>

      <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} >
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A2</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe 2</strong><br/> 
                  Einer Arbeitnehmerin mit einer Arbeitszeit von 5-Tagen pro Woche wird ab 01.Februar 2021 ein im Januar 2021 angeschafftes Elektroauto (ohne Kohlendioxidemission) als Firmenfahrzeug zur Verfügung gestellt.<br/>
                  Der Bruttolistenpreis dieses Fahrzeuges beträgt 62835,59 Euro.<br/>
                  Die Versteuerung des geldwerten Vorteils erfolgt mit der 1%-Methode.<br/>
                  Die Entfernungskilometer zwischen der Wohnung und der Firma betragen 10 Km.<br/>
                  Die pauschale Versteuerung der Fahrten Wohnung-Arbeitsstätte soll nicht angewendet werden.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="3" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["100","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="4" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil für das Fahrzeug zu berechnen ist","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>

      </Grid>

      <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A3</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe 3</strong><br/> 
                  Ein Arbeitnehmer mit einer Arbeitszeit von 4-Tagen pro Woche bekommt ab 01.Februar 2021 ein im Dezember 2020 von der Firma angeschafftes Hybridauto als Firmenfahrzeug.<br/>
                  Gem. der Zulassungsbescheinigung entspricht das Hybridfahrzeug den Voraussetzungen des steuerbegünstigten geldwerten Vorteils.<br/>
                  Der Bruttolistenpreis dieses Fahrzeuges beträgt 52723,69 Euro.<br/>
                  Die Versteuerung des geldwerten Vorteils erfolgt mit der 1%-Methode.<br/>
                  Die Entfernungskilometer zwischen der Wohnung und der Firma betragen 11 Km.<br/>
                  Die pauschale Versteuerung der Fahrten Wohnung-Arbeitsstätte soll nicht angewendet werden.
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="5" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["100","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="6" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil für das Fahrzeug zu berechnen ist","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>

          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A3.1</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe 3.1</strong><br/> 
                Trage die korrekten Werte bei den jeweiligen Lohnarten ein.
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={11} >
            <BruttoNeu2
                tabellenKey="1" 
                anzReihen={4}
                Lohnart={['1029','1350',"1350","1349"]}
                Intern={['029','350','350','349']}
                Bezeichnung={["Gehalt","Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb.","Förd.E-Mobil."]}
                Tage={["21,75","--","--","--"]}
                Stunden={["174,00","--","--","--"]}
                Faktor={["2700,00","","",""]}
                Kennung={["LFD","LFD","LFD","LFD"]}
                Prozent={["0,00","0,00","0,00","0,00"]}
                Steuerpflichtig={["2700,00","","",""]}
                Sozialverspflichtig={["2700,00","","",""]}
                Betrag={["2700,00","","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6} >
            <NettoLoa2
              anzReihen={2}
              tabellenKey="1" 
              text=''
              NLohnart={['2067','2068']}
              NIntern={['067','068']}
              NBezeichnung={["Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb."]}
              NBetrag={["",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>
      </Grid>


      <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A4</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe 4</strong><br/> 
                  Eine Arbeitnehmerin mit einer Arbeitszeit von 5-Tagen pro Woche erhält ab 01.März 2021 ein Elektroauto als Firmenwagen.<br/>
                  Bei dem Firmenfahrzeug handelt es sich um ein Leasingfahrzeug. Der Leasingvertrag beginnt ebenfalls am 01.März 2021. Die monatliche Leasingrate beträgt 170,- Euro. <br/>
                  Die Leasingfirma bestätigt einen Bruttolistenpreis in Höhe von 37.809,74 Euro. <br/>
                  Die Entfernungskilometer zwischen der Wohnung und der Firma betragen 17 Km.<br/>
                  Die Versteuerung des geldwerten Vorteils erfolgt mit der 1%-Methode.<br/>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="7" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil berechnet wird","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["100","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={5} >
            <Tabelle2spaltig 
                tabellenKey="8" 
                anzReihen="3"
                ueberschrift="Ermittlung geldwerter Vorteil Firmenwagen"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Wert"
                spalte2Platz="right"
                spalten1={["Angabe, mit wieviel Prozent des Bruttolistenpreises der geldwerte Vorteil für das Fahrzeug zu berechnen ist","Geldwerter Vorteil nach der 1%-Methode","Geldwerter Vorteil für Fahrten Wohnung-Arbeitsstätte"]}
                spalten2={["","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  <b>A4.1</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <strong>Aufgabe 4.1</strong><br/> 
                Trage die korrekten Werte bei den jeweiligen Lohnarten ein.
              </ListItemText> 
            </ListItem>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} >
            <BruttoNeu2
                tabellenKey="2" 
                anzReihen={4}
                Lohnart={['1029','1350',"1350","1349"]}
                Intern={['029','350','350','349']}
                Bezeichnung={["Gehalt","Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb.","Förd.E-Mobil."]}
                Tage={["21,75","--","--","--"]}
                Stunden={["174,00","--","--","--"]}
                Faktor={["2700,00","","",""]}
                Kennung={["LFD","LFD","LFD","LFD"]}
                Prozent={["0,00","0,00","0,00","0,00"]}
                Steuerpflichtig={["2700,00","","",""]}
                Sozialverspflichtig={["2700,00","","",""]}
                Betrag={["2700,00","","",""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            /> 
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6} >
            <NettoLoa2
              anzReihen={2}
              tabellenKey="2" 
              text=''
              NLohnart={['2067','2068']}
              NIntern={['067','068']}
              NBezeichnung={["Priv.Kfz-Nutzg 1%","Fahrten Whng-Arb."]}
              NBetrag={["",""]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
            /> 
          </Grid>
      </Grid>

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

      </div>
    )
  }
  

export default FirmenwagenStrom;
