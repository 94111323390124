import React, {useState, useCallback} from 'react';
import {Typography, Button } from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Tabelle2Wissen} from '../../components/Wissenstest_Entgeltabr_T1/tabelle2WissentestE1';
const Stammblatt1 = '/media/Fisch_Stammblatt.pdf';
const Stammblatt2 = '/media/Wassermann_Stammblatt.pdf';
const Stammblatt3 = '/media/Loewe_Stammblatt.pdf';
const Stammblatt4 = '/media/Krebs_Stammblatt.pdf';
const Stammblatt5 = '/media/Jungfrau_Stammblatt.pdf';


function Wissenstest_BeschArt(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('024');
  const [merkmal]=useState("WTestBeschArt");




  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };



  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {

    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Typography variant="h4" color="primary">Wissenstest zur Beschäftigungsart durch Stammblätter festlegen</Typography> 
      <Grid container spacing={0} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={8}>
              <Typography variant="h6">
                  Teste dein Wissen!
              </Typography>

          </Grid>
      
          <Tabelle2Wissen 
              tabellenkey='1' 
              frage='Bitte lege anhand des Stammblatt 100 Fridolin Fisch die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' 
              antwort1='Personengruppe:101; Beitragsgruppe:0110; Versicherungsstatuts: privat versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
              antwort2='Personengruppe:101; Beitragsgruppe:0110; Versicherungsstatuts: privat versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in der Gleitzone' 
              antwort3='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
              antwort4='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in der Gleitzone' 
              setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
          />
          <Grid item xs={12}style={{marginBottom:"45px"}}>
              <Button 
                color="secondary"  
                variant="contained"
  //              fullWidth
                href={Stammblatt1}download>
                Stammblatt 100 Fridolin Fisch
              </Button>
          </Grid>
          <Tabelle2Wissen 
              tabellenkey='2' 
              frage='Bitte lege anhand des Stammblatt 200 Winifred Wassermann die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' 
              antwort1='Personengruppe:109; Beitragsgruppe:6500; Versicherungsstatuts: geringfügig mit RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' 
              antwort2='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Teilzeit' 
              antwort3='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' 
              antwort4='Personengruppe:109; Beitragsgruppe:6100; Versicherungsstatuts: geringfügig ohne RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' 
              setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
          />
          <Grid item xs={12}style={{marginBottom:"45px"}}>
              <Button 
                color="secondary"  
                variant="contained"
  //              fullWidth
                href={Stammblatt2}download>
                Stammblatt 200 Winifred Wassermann
              </Button>
          </Grid>
          <Tabelle2Wissen tabellenkey='3' frage='Bitte lege anhand des Stammblatt 300 Lenny Löwe die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' antwort1='Personengruppe:109; Beitragsgruppe:6100; Versicherungsstatuts: geringfügig ohne RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' antwort2='Personengruppe:106; Beitragsgruppe:0100; Versicherungsstatuts: RV pflichtig; Beschäftigungsart: Werkstudent' antwort3='Personengruppe:110; Beitragsgruppe:0000; Versicherungsstatuts: sv-frei; Beschäftigungsart: Kurzfristige Beschäftigung' antwort4='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
          <Grid item xs={12}style={{marginBottom:"45px"}}>
              <Button 
                color="secondary"  
                variant="contained"
  //              fullWidth
                href={Stammblatt3}download>
                Stammblatt 300 Lenny Löwe
              </Button>
          </Grid>
          <Tabelle2Wissen tabellenkey='4' frage='Bitte lege anhand des Stammblatt 400 Knut Krebs die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' antwort1='Personengruppe:101; Beitragsgruppe:9111; Versicherungsstatuts: freiwillig versichert Firmenzahler; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' antwort2='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' antwort3='Personengruppe:101; Beitragsgruppe:0110; Versicherungsstatuts: privat versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Vollzeit' antwort4='Personengruppe:101; Beitragsgruppe:1111; Versicherungsstatuts: gesetzlich versichert; Beschäftigungsart: sv-pflichtige Beschäftigung in Teilzeit' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
          <Grid item xs={12}style={{marginBottom:"45px"}}>
              <Button 
                color="secondary"  
                variant="contained"
  //              fullWidth
                href={Stammblatt4}download>
                Stammblatt 400 Knut Krebs
              </Button>
          </Grid>
          <Tabelle2Wissen tabellenkey='5' frage='Bitte lege anhand des Stammblatt 500 Julia Jungfrau die Personengruppe, Beitragsgruppe und Beschäftigungsart fest' antwort1='Personengruppe:109; Beitragsgruppe:6500; Versicherungsstatuts: geringfügig mit RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' antwort2='Personengruppe:110; Beitragsgruppe:0000; Versicherungsstatuts: sv-frei; Beschäftigungsart: Kurzfristige Beschäftigung' antwort3='Personengruppe:106; Beitragsgruppe:0100; Versicherungsstatuts: RV-pflichtig; Beschäftigungsart: Werkstudent' antwort4='Personengruppe:109; Beitragsgruppe:6100; Versicherungsstatuts: geringfügig ohne RV Befreiung; Beschäftigungsart: Geringfügige Beschäftigung' setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
          <Grid item xs={12}style={{marginBottom:"45px"}}>
              <Button 
                color="secondary"  
                variant="contained"
  //              fullWidth
                href={Stammblatt5}download>
                Stammblatt 500 Julia Jungfrau
              </Button>
          </Grid>
        </Grid>
        
        <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={8}>
            </Grid>
        </Grid>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

  </div>
    )
}


export default Wissenstest_BeschArt;