import React from 'react';

import {Grid, Typography, ListItem, ListItemAvatar, ListItemText,Avatar } from '@material-ui/core';



function Verdiensttabelle (props) {
    const {text, aufgabentext, aufgabe} = props;


  return(

        

    <Grid container spacing={0} style={{marginTop:'20px', textAlign:'center'}} justifyContent={"center"} alignItems={"center"}>
    <Grid item xs={3}style={{marginTop:"30px"}}>
      <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                {aufgabe}
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h4"  color="primary">
                {aufgabentext} <br/>
              </Typography>
             
            </ListItemText>
      </ListItem>
    </Grid>
    <Grid item xs={9}>

    </Grid>
    <Grid item xs={1}>

    </Grid>
    <Grid item xs={4} style={{textAlign:'center', borderBottom:'dotted'}}>
      <Typography style={{textAlign:'center'}}>
        {text}
      </Typography>

    </Grid>
    <Grid item xs={7}>

    </Grid>

    <Grid item xs={1}>
      
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Monat
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Verdienst
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Januar
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Februar
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        März
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        April
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Mai
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Juni
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Juli
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        August
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        September
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Oktober
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        November
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        Dezember
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        4.000,00€
      </Typography>
    </Grid>
    <Grid item xs={9}>
    </Grid>
  
   
  </Grid>






   
  )
}






export {Verdiensttabelle};