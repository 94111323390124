import React, { useState, useEffect } from 'react';
import {useLocalStorage} from '../utils/hooks';
import {useNavigate} from 'react-router-dom';
import {LOCAL_STORAGE_KEY} from '../constants/localStorage';

const NotificationContext = React.createContext();

const NotificationProvider = (props) => {
  const [notificationToken, setNotificationToken] = useState('');
  const [, setClient] = useLocalStorage(LOCAL_STORAGE_KEY.client);
  const navigate = useNavigate();

  useEffect(() => {

    function firebaseSetup() {
      //const isOniOS = typeof window.device !== "undefined" && !!window.device.platform && window.device.platform.toUpperCase() === "IOS";
    
      // We use FCM only for Android. For iOS - we use plain APNS
        window.FirebasePlugin.onTokenRefresh(fcmToken => {
          setNotificationToken(fcmToken);
          console.log(fcmToken);
        }, error => {
          console.error(error);
        });
      /*
      // iOS device token
      window.FirebasePlugin.onApnsTokenReceived(apnsToken => {
        setNotificationToken(apnsToken);
        console.log(apnsToken);
      }, error => {
        console.error(error);
      });
      */
      window.FirebasePlugin.onMessageReceived(message => {
        console.log(message.client);
        console.log(message.path);
        if (message.tap) {
          if (message.client) {
            setClient(message.client);
          }
          if (message.path) {
            navigate(message.path);
          }  
        }
      }, error => {
        console.error(error);
      });  
    }

    if (typeof window.device !== 'undefined') {
      if (window.device.platform === 'iOS') {
        window.FirebasePlugin.setBadgeNumber(0);
        try {
          window.FirebasePlugin.grantPermission(hasPermission => {
            console.log("Permission was " + (hasPermission ? "granted" : "denied"));
          });
          firebaseSetup();
        } catch (e) {
          console.error("FirebasePlugin grantPermission error", e);
        }
      } else {
        firebaseSetup();
      }
    }
  }, [navigate, setClient]);


  return <NotificationContext.Provider value={notificationToken}>{props.children}</NotificationContext.Provider>
}

const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
    return context;
}

export {NotificationProvider, useNotification}