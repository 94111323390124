import React, {useState, useCallback, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import './Home.css';
import {withStyles, TextField, Paper, Toolbar, Typography, Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
//import iconHutKreis from '/media/Icon_Hut_100_100.svg';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
//import {ReactComponent as LogoAkademie} from '/media/Logo_ectool_Akademie.svg';
//import BannerHeader from '/media/Akademie_Header_Banner.svg';
import apiRequest from '../../functions/api';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(1),
    flex: 1
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    backgroundColor: theme.palette.secondary.light,
  }
});

function Home(props) {
  const {classes, kurse, updateKurse, updateHsutkey, updateName, accessToken } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const [bearbeitet, setBearbeitet]=useState('');

useEffect(() => {
  holeKurse();
  // eslint-disable-next-line
}, []);

  const holeKurse = useCallback(() => {
    if (accessToken==='') {
      navigate('/login/user');
      return
    }
    const headers={
        "Authorization": "Bearer "+accessToken,
    }
    setIsLoading(true);
    const kurseCb = (response) => {
      if (response.status === 200) {

        if (response.data.kurse) {
          updateKurse(response.data.kurse);
          if (response.data.bearbeitet!==undefined){
            setBearbeitet(response.data.bearbeitet);
          }else{
            setBearbeitet("A")
          }
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    apiRequest('GET', 'kurse', null, headers, kurseCb);
    // eslint-disable-next-line
  }, [updateKurse, accessToken, navigate]);
  
  if (isLoading) {
    return(<LoadingComponent/>);
  }


  const displayKurse = kurse.length>0 ? kurse.filter((element) => element.name.toLowerCase().includes(filter.toLowerCase())).map((dataObject, count) => {
    const name = dataObject.name;

    return(
          <Fragment key={'f'+count.toString()}>
            <Divider key={'d'+count.toString()} variant="inset" component="li" light />

            <ListItem 
                key={'v'+count.toString()}
                button onClick={() => {
                  localStorage.setItem("hsutkey", dataObject.hsutkey)
                  updateHsutkey(dataObject.hsutkey);
                  updateName(name);
                  navigate('/'+dataObject.key);}}
              >
              <ListItemText >
                <Typography variant="h3" style={{ color:(bearbeitet.includes(dataObject.key)===true)?"green":"grey"}} >
                    {name}
                </Typography>
              </ListItemText> 
                <img src={'/Icon_Hut_100_100.svg'} height="100" alt="ectool" loading="lazy"/> 
  	          </ListItem>

          </Fragment>



        );
  })
  :
  <ListItem key={'nocourses'} >
  <ListItemAvatar>
    <Avatar className={classes.avatar}>
      <NotInterestedIcon/>
    </Avatar>
  </ListItemAvatar>
  <ListItemText primary={"Sie haben keine Kurse freigeschalten"} />
</ListItem>
  ;
    return(
      <Fragment>

      <Grid container spacing={1} style={{marginTop:'5px', marginBottom:"5px", borderBottom:"solid", borderColor:"#1fbba6" }} justifyContent={"center"} alignItems={"center"} >
        <Grid item xs={12} >
          <img src={'/Logo_ectool_Akademie.svg'} height="150" width="700" alt="ectool" loading="lazy"/>
        </Grid> 
        <Grid item xs={12}>
            <img src={'/Akademie_Header_Banner.svg'} alt="ectool" loading="lazy"/>
        </Grid> 
      </Grid>
      
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <form className={classes.form}>
              <TextField
                className={classes.search}
                fullWidth
                label="Suche"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                autoComplete="off"
                variant="outlined"
                type="search"
                aria-label="Search"
                id="filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </form>
          </Toolbar>
          <List>
            {displayKurse}
          </List>
        </Paper>
      </Fragment>
    )
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
