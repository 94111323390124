import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, Checkbox, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import Lohnsteuerbescheinigung from '../Rahmendaten/Lohnsteuerbescheinigung';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';



const Figur1 = '/media/Figur1.png';
const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const ecschoolRahmen ='/media/ecschool Rahmen.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckDez3 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('813');
    const [kurskennung, setKurskennung]=useState("ScheckDez3");
    const [merkmal]=useState('ScheckDez3');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setzeAntworten = ( key,value) => {
      let values = Object.assign(antworten);
      values[key] = value;
      setAntworten(values);
      forceUpdate();   
    }
  
    const fehlerAusLesen=(fehler)=>{
      let array=fehler.split(";");
      let fehlerzwis=[]
      array.map(
        // eslint-disable-next-line
        (objekt)=> {
          fehlerzwis[objekt]=true;
        }
      );
      setFehlerliste(fehlerzwis)
    };
  

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);

        }
      }
    }

    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "kursabgabe",
          "reactnummer": reactnummer,
          "kurskennung": kurskennung,
          "daten": antworten,
          "merkmal": merkmal,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          fehlerAusLesen(response.data.fehler)
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im vierten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"60px"}}>
                  <b>A</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
              <Typography variant="h4" style={{marginLeft:"20px"}} >    
                Die Lohnsteuerbescheinigung <br/>
               </Typography>

                <Typography variant="h5" style={{marginLeft:"30px"}} >    
                    Eine Lohnsteuerbescheinigung ist ein offizielles Dokument, das jedes Jahr von einem Arbeitgeber an seine Arbeitnehmer ausgestellt wird. 
                    Es enthält Informationen über die Höhe des vom Arbeitnehmer im vergangenen Kalenderjahr erhaltenen Lohns und die darauf gezahlte Lohnsteuer sowie Sozialversicherungsabgaben.
                    Somit ist die Lohnsteuerbescheinigung ein Nachweis für die gezahlte Lohnsteuer und die Sozialversicherungsabgaben.<br/>
                    Dieses Dokument dient der Finanzverwaltung als Nachweis dafür, dass der Arbeitnehmer seine steuerlichen Pflichten erfüllt hat. <br/><br/>
                    Bitte erstelle für folgende Arbeitnehmer des Schulungsmandanten die Lohnsteuerbescheinigungen.
                </Typography>
              </ListItemText>
            </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <Lohnsteuerbescheinigung
          tabellenKey="1"
          ueberschrift1="Darstellung der elektronischen Lohnsteuerbescheinigung für 2022 von Bruno Biermann"
          ueberschrift2=""
          daten = {{Kirchensteuermerkmale:"--", Jahreshinzurechnungsbetrag:"", SteuerfreierJahr:"", Kinderfreibeträge:"", Steuerklasse:"1", 
                     Transferticket:"--", Geburtsdatum:"30.04.1965", PersNr:"000001", id: "", eTIN: "BRMNBRNO55D30Y", datum: "31.12.2022", d1a: "", d1b: "", d2: "S", d3: "", 
                     d4: "", d5: "", d6: "", d7: "", d8: "", d9: "", d10: "", d11: "", d12: "", d13: "", d14: "", d15: "", d15: "", d16a: "",d16b: "", d17: "",
                     d18: "", d19: "", d20: "", d21: "", d22a: "", d22b: "", d23a: "", d23b: "", d24a: "", d24b: "", d24c: "", d25: "", d26: "", d27: "",d28: "", d29: "",
                     d30: "", d31: "", d32: "", d33: "", d34: "" }}
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}
        >
        </Lohnsteuerbescheinigung>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'100px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <Lohnsteuerbescheinigung
          tabellenKey="2"
          ueberschrift1="Darstellung der elektronischen Lohnsteuerbescheinigung für 2022 von Helmut Nickel"
          ueberschrift2=""
          daten = {{Kirchensteuermerkmale:"--", Jahreshinzurechnungsbetrag:"", SteuerfreierJahr:"", Kinderfreibeträge:"", Steuerklasse:"1", 
                     Transferticket:"--", Geburtsdatum:"16.09.1985", PersNr:"000002", id: "", eTIN: "NCKLHLMT85I16D", datum: "31.12.2022", d1a: "", d1b: "", d2: "--", d3: "", 
                     d4: "", d5: "", d6: "", d7: "", d8: "", d9: "", d10: "", d11: "", d12: "", d13: "", d14: "", d15: "", d15: "", d16a: "",d16b: "", d17: "",
                     d18: "", d19: "", d20: "", d21: "", d22a: "", d22b: "", d23a: "", d23b: "", d24a: "", d24b: "", d24c: "", d25: "", d26: "", d27: "",d28: "", d29: "",
                     d30: "", d31: "", d32: "", d33: "", d34: "" }}
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}
        >
        </Lohnsteuerbescheinigung>
      </Grid>
    </Grid>


    <Grid style={{display: 'flex', marginTop:'100px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <Lohnsteuerbescheinigung
          tabellenKey="3"
          ueberschrift1="Darstellung der elektronischen Lohnsteuerbescheinigung für 2022 von Gustav Gans"
          ueberschrift2=""
          daten = {{Kirchensteuermerkmale:"--", Jahreshinzurechnungsbetrag:"", SteuerfreierJahr:"", Kinderfreibeträge:"", Steuerklasse:"5", 
                     Transferticket:"--", Geburtsdatum:"16.09.2002", PersNr:"000003", id: "", eTIN: "GNSAGSTV85I16L", datum: "31.12.2022", d1a: "", d1b: "", d2: "S", d3: "", 
                     d4: "", d5: "", d6: "", d7: "", d8: "", d9: "", d10: "", d11: "", d12: "", d13: "", d14: "", d15: "", d15: "", d16a: "",d16b: "", d17: "",
                     d18: "", d19: "", d20: "", d21: "", d22a: "", d22b: "", d23a: "", d23b: "", d24a: "", d24b: "", d24c: "", d25: "", d26: "", d27: "",d28: "", d29: "",
                     d30: "", d31: "", d32: "", d33: "", d34: "" }}
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}
        >
        </Lohnsteuerbescheinigung>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'100px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <Lohnsteuerbescheinigung
          tabellenKey="4"
          ueberschrift1="Darstellung der elektronischen Lohnsteuerbescheinigung für 2022 von Alex Grün"
          ueberschrift2=""
          daten = {{Kirchensteuermerkmale:"--", Jahreshinzurechnungsbetrag:"", SteuerfreierJahr:"", Kinderfreibeträge:"", Steuerklasse:"2", 
                     Transferticket:"--", Geburtsdatum:"17.10.1960", PersNr:"000005", id: "", eTIN: "GRNELXEA60J17Q", datum: "31.12.2022", d1a: "", d1b: "", d2: "--", d3: "", 
                     d4: "", d5: "", d6: "", d7: "", d8: "", d9: "", d10: "", d11: "", d12: "", d13: "", d14: "", d15: "", d15: "", d16a: "",d16b: "", d17: "",
                     d18: "", d19: "", d20: "", d21: "", d22a: "", d22b: "", d23a: "", d23b: "", d24a: "", d24b: "", d24c: "", d25: "", d26: "", d27: "",d28: "", d29: "",
                     d30: "", d31: "", d32: "", d33: "", d34: "" }}
            setzeAntworten={setzeAntworten} 
            antworten={antworten} 
            fehlerliste={fehlerliste}
        >
        </Lohnsteuerbescheinigung>
      </Grid>
    </Grid>
 
    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </div>
 
    )
  }

export default ScheckDez3;