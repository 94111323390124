import React, {useState, useCallback} from 'react';
import {Avatar, Button, ListItem, ListItemAvatar, ListItemText, Grid,Box} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { TabelleteilentNeu} from './tabelle';
import { Player, ControlBar } from 'video-react';
const Erklärung = '/media/Berechnung von anteiligen Monatswerten.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
const Kalender = '/media/2020 Kalender.pdf';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';


function Teilentgeltberechnung(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('010');
  const [merkmal]=useState("Teilentgeltberechnung");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }

  const setRedirect = (path) => {
    navigate(path);
  }
  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
            showErrorMessage(response.data.message);
         } else {
            showErrorMessage('Keine Internetverbindung');
         }
       }
  }


  return(
   <Box p={2}>
      <Briefkopf  
          ueberschrift1="Teilentgeltberechnung" 
          ueberschrift2="Wann und wie wird ein Teilentgelt berechnet?" 
      />

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                H
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary=" Hinweis: Dieses Video erklärt Dir die verschiedenen Berechnungen"/>
          </ListItem>
        <Grid item xs={8} style={{}}>
            <Player
              poster={POSTER}
                src={Erklärung}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
        <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                T
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary=" Tipp: Ein Kalender an der Seite hilft"/>
          </ListItem>
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={Kalender}download>
                Kalender 2020
              </Button>
          </Grid>
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Aufgabe:</h4>
                  Berechnung der anteiligen durchschnittlichen Tage, Stunden und des anteiligen Gehalts. <br />
                  Bitte wende für die Ermittlung der durchschnittlichen Tage und Stunden den bekannten Wochenfaktor an. <br/>
                  Anschließend werden die Tage und Stunden gem. der anteiligen Anwesenheit gekürzt. <br/>
                  Das anteilige Gehalt ist für alle 3 Methoden zu berechnen.
              </ListItemText>  
          </ListItem>
    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                Vertragsdaten des Arbeitnehmers:<br />
                Eintritt: 09.01.2020<br />
                Monatsgehalt: 1000,- Euro<br />
                Arbeitszeit: <br />Dienstag 8 Stunden<br />Donnerstag 8 Stunden<br />
                  
              </ListItemText>  
          </ListItem>
    </Grid>
 
    <TabelleteilentNeu tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                Vertragsdaten des Arbeitnehmers:<br />
                Eintritt: 14.02.2020<br />
                Monatsgehalt: 2500,- Euro<br />
                Arbeitszeit: 40 Stundenwoche bei einer 5 Tage/Woche (Montag - Freitag)
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                3
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Vertragsdaten des Arbeitnehmers:<br />
                  Austritt: 09.04.2020<br />
                  Monatsgehalt: 2400,- Euro<br />
                  Arbeitszeit: 8 Stunden pro Tag von Montag bis Freitag
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="3" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                4
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Vertragsdaten des Arbeitnehmers:<br />
                  Eintritt: 11.05.2020<br />
                  Monatsgehalt: 1100,- Euro<br />
                  Arbeitszeit:<br /> Montag 8 Stunden<br />Dienstag 8 Stunden<br />Mittwoch 4 Stunden
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="4" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>


    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                5
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Vertragsdaten des Arbeitnehmers:<br />
              Austritt: 26.06.2020<br />
              Monatsgehalt: 2000,- Euro<br />
              Arbeitszeit: 35 Stundenwoche, gearbeitet wird an 5 Tagen die Woche (Montag - Freitag)
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="5" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                6
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              Vertragsdaten des Arbeitnehmers:<br />
              Austritt: 07.07.2020<br />
              Monatsgehalt: 3000,- Euro<br />
              Arbeitszeit: 45 Stundenwoche, gearbeitet wird an 5 Tagen die Woche (Montag - Freitag)
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="6" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>



    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                7
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  Vertragsdaten des Arbeitnehmers: <br />
                  Eintritt: 25.08.2020 <br />
                  Monatsgehalt: 700,- Euro <br />
                  Arbeitszeit:<br /> Montag 3 Stunden <br />Dienstag 3 Stunden <br />Mittwoch 3 Stunden <br /> Donnerstag 3 Stunden<br />Freitag 3 Stunden
              </ListItemText>  
          </ListItem>
    </Grid>

    <TabelleteilentNeu tabellenKey="7" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
   
   </Box>
    )
  }   


export default Teilentgeltberechnung;
