// theme.js
import { responsiveFontSizes } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

// colors
const primary = "#1fbba6";
const secondary = "#6381A8"; //"#557299";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const backgroundLight = "#121212";//"#f5f5f5";
const backgroundDark = "#000000";//"#f5f5f5";
const backgroundDim = "#222222";
const backgroundDim2 = "#565656";
const highlight="#1fbba677";
const lightred="#b44646"
const red="#b43232"
const lightgreen="#46b446"
const green="#32b432"
const lightblue="#4646b4"
const blue="#3232b4"
const lightyellow="#b4b446"
const yellow="#b4b432"
const lightpurple="9b46b4"
const purple="#8832b4"
const lightorange="b49b46"
const orange="#b48832"
const back="#ffffe6"
const hyellow="#666630"

//const warningLight = "rgba(253, 200, 69, .3)";
//const warningMain = "rgb(253, 200, 69, .5)";
//const warningDark = "rgba(253, 200, 69, .7)";

//const errorMain = "rgb(248, 100, 73, .5)";1
//const errorDark = "rgb(248, 100, 73, .7)";

// border
const borderWidth = 1;
const borderColor = "rgba(255, 255, 255, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const darktheme = createTheme({
    palette: {
      type: 'dark',
      primary: { main: primary },
      secondary: { main: secondary },
      common: {
        black,
        darkBlack
      },
      background: {
        paper: backgroundLight,
        light: backgroundLight,
        default: backgroundDark,
        dark: backgroundDark,
        dim:backgroundDim,
        dim2:backgroundDim2,
        highlight:highlight,
      },
      blocks: {
        lightred: lightred,
        red: red,
        lightgreen: lightgreen,
        green: green,
        lightblue: lightblue,
        blue: blue,
        lightyellow: lightyellow,
        yellow: yellow,
        lightpurple: lightpurple,
        purple: purple,
        lightorange: lightorange,
        orange: orange,
        back:back,
        hyellow: hyellow,
      },
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      spacing
    },
    
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xl,
        lg,
        md,
        sm,
        xs
      }
    },
    border: {
      borderColor: borderColor,
      borderWidth: borderWidth
    },
    overrides: {
      MuiExpansionPanel: {
        root: {
          position: "static"
        }
      },
      MuiTableCell: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing
          }
        }
      },
      /*
      div: {
          [`&.rbc-off-range-bg`]: {
            backgroundColor: backgroundLight,
          }
      },
      */
      MuiPaper: {
        root: {
          backgroundColor: backgroundLight,
        }
      },
      MuiDivider: {
        light: {
          backgroundColor: borderColor,
          height: borderWidth
        },
        root: {
          backgroundColor: borderColor,
          height: borderWidth
        }
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderWidth: borderWidth
        }
      },
      MuiListItem: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`
        }
      },
      MuiInputBase: {
        root: {
        },
        input: {
          [`&::-webkit-calendar-picker-indicator`]: {
            filter: "invert(1)",
            cursor: "pointer"
          }
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: backgroundLight,
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkBlack
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing
          }
        }
      }
    },
    typography: {
      useNextVariants: true
    }
  });

  export default responsiveFontSizes(darktheme);
