import React from 'react';
import {Checkbox, FormControlLabel, ListItem, Box, Grid} from "@material-ui/core";


function AntwortenCheckBox (props) {
  const { tabellenKey, fehlerliste, setzeAntworten} = props;

  console.log("fehler sporttasche112 : "+fehlerliste["sporttasche112"]);

  return(
    <Box>

        <ListItem style={{marginTop:"1px",marginBottom:"1px"}}>
            <Grid item xs={1}></Grid>
            
            <Grid item xs={11} >
                <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["sporttasche"+tabellenKey+"1"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["sporttasche"+tabellenKey+"1"]}
                    onChange={(event) => setzeAntworten('sporttasche'+tabellenKey+"1",event.target.checked)}
                    label="Monatsgebühren und Sporttasche können steuerfrei ausgegeben werden."
                    labelPlacement="end"
                />
            </Grid>
        </ListItem>

        <ListItem>
            <Grid item xs={1}></Grid>
            <Grid item xs={11} >
                <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["sporttasche"+tabellenKey+"2"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["sporttasche"+tabellenKey+"2"]}
                    onChange={(event) => setzeAntworten("sporttasche"+tabellenKey+"2",event.target.checked)}
                    label="Monatsgebühren und Sporttasche müssen mit der Entgeltabrechnung versteuert werden."
                    labelPlacement="end"
                />
            </Grid>
        </ListItem>

        <ListItem>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["sporttasche"+tabellenKey+"3"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["sporttasche"+tabellenKey+"3"]}
                    onChange={(event) => setzeAntworten("sporttasche"+tabellenKey+"3",event.target.checked)}
                    label="Die Monatsgebühren können steuerfrei bleiben, die Sporttasche hingegen sollte versteuert werden."
                    labelPlacement="end"
                />
            </Grid>
        </ListItem>

        <ListItem>
            <Grid item xs={1}></Grid>

            <Grid item xs={11} >
                <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["sporttasche"+tabellenKey+"4"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    error={fehlerliste["sporttasche"+tabellenKey+"4"]}
                    onChange={(event) => setzeAntworten("sporttasche"+tabellenKey+"4",event.target.checked)}
                    label="Die Sporttasche kann steuerfrei bleiben, die Monatsgebühren hingegen sollten versteuert werden." 
                    labelPlacement="end"
                />
            </Grid>
        </ListItem>
    </Box>
  
  )
}


export {AntwortenCheckBox};