import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Box, Button, Typography, Grid, Avatar, Checkbox, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {KursAufgabenErledigt} from '../Rahmendaten/KursAufgabenErledigt';
import {Briefkopf} from '../Rahmendaten/Briefkopf';
import { element } from 'prop-types';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import openFile from "../shared/functions/download";


const Figur1 = '/media/Figur1.png';
const BuchDateneingabe ='/media/Buch_Dateneingabe.png';
const ecschoolRahmen ='/media/ecschool Rahmen.png';
const BuchLeseUndLerne ='/media/BuchLeseUndLerne.png';
const BuchDraufHast ='/media/BuchDraufHast.png';


function ScheckMK2 (props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, ueberschrift_ausb } = props;
    const {showErrorMessage,showSuccessMessage} = useMessage();
    const [reactnummer]=useState('807');
    const [kurskennung, setKurskennung]=useState("ScheckM02_1");
    const [merkmal]=useState('');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [links, setLinks]=useState([]);
    const [lohndaten, setLohndaten]=useState([]);
    const [lohndatenwerte, setLohndatenwerte]=useState(false);

    const setRedirect = (path) => {
      navigate(path);
    }

    useEffect(() => {
      rahmendaten_holen();
    },[])

    const rahmendaten_holen = () => {
      forceUpdate();   
      const headers={
        "Authorization": "Bearer "+accessToken,
      }
      const payload={
        "kennung": "kursdaten",
        "reactnummer": reactnummer,
        "kurskennung": kurskennung,
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
    }

    const kursinhalt = (response) => {
      if (response.status === 200) {
        if (response.data.links) {
          setLinks(response.data.links)
        }
        if (response.data.lohndaten) {
          setLohndatenwerte(true);
          setLohndaten(response.data.lohndaten);

        }
      }
    }

    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "aufgabgeerl",
          "reactnummer": reactnummer,
          "kurskennung": kurskennung,
          "merkmal": merkmal,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'aufgabeerl', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

      const DokumentOeffnen = (docnr, docbez, docendung) => {
        const payload={
          "kennung": "kursdaten",
          "kurskennung": "download_lohndaten",
          "reactnummer": reactnummer,
          "docnr":docnr,
          "docname": docbez,
          "hsutkey": hsutkey
        }
        const headers={
          "Authorization": "Bearer "+accessToken,
          "Accept": "application/pdf"
        }
        axios.post(API_BASE_URL+'kursdaten', payload, {headers: headers, responseType: 'blob'})
          .then((response) => {
            if (docendung === undefined) {var fileName = docbez} else {var fileName = docbez+docendung};
            var contentFile = response.data;
            const mimeType = response.headers['content-type'] || 'application/pdf'
            openFile(fileName, contentFile,mimeType);
          })
          .catch(function (error) {
            showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
          })
      }
      
      return(
    <div>

    <Briefkopf  
          ueberschrift1={ueberschrift_ausb}
          ueberschrift2="Du befindest Dich im zweiten Abrechnungsmonat." 
    />

    <Grid style={{marginTop:'20px'}} justifyContent={"left"} >
      <Grid item xs={12}>
        <Typography variant='h5' align="left">
            Im zweiten Abrechnungsmonat sollen folgende Lernziele erreicht werden:
        </Typography>

          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6", marginLeft:"60px"}}>
                  <b>I</b>
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <Typography variant="h5" style={{marginLeft:"20px"}} >
                      Welche Informationen sollten auf einem Personalstammblatt stehen bzw. welche Daten eines neuen Arbeitnehmers werden benötigt, um eine korrekte Entgeltabrechnung erstellen zu können.
                  </Typography>
              </ListItemText>
            </ListItem>



        <Typography variant='h5' align="left" style={{marginLeft:"60px"}}>
          
        </Typography>
      </Grid>
    </Grid>

    <Grid style={{display: 'grid', gap:"16px", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", marginTop:'10px', justifyContent:"center"}} >

      {
       links?.map((inhalt,index) => {
          var grafik = BuchLeseUndLerne
          if (index === 3) {
            grafik = BuchDraufHast
          }
          return(
//            <Grid style={{display: 'flex', marginTop:'10px', justifyContent:"center", alignItems: "center"}} >
              <Card sx={{ display: 'flex', marginTop:'30px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={grafik}
                    alt="Handbücher"
                />

                <Box sx={{  display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  href={inhalt.pfad}
                >
                  Handbuch in einem neuen Tab anzeigen
                </Button>

              </Card>
          )
        })
    }
    </Grid>

  {
    lohndatenwerte && 
    <Fragment>
    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"}} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              Hw
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
              Abrechnungsmonat 02 Oktober
            </Typography>

          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>

    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center" }} >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Bitte erstelle anhand dem "Biermann Arbeitnehmerauszug" von Hand ein Stammblatt in Word oder Excel für den Mitarbeiter Biermann und gib eine Kopie davon bei Deinem Teamleiter ab.
                Danach legst Du bitte Herrn Biermann mit Hilfe des von Dir erstellten Stammblattes über das Interview „Arbeitnehmer anlegen über Interview“ an, ohne den Auszug des Arbeitnehmerstammes zu verwenden.<br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', marginTop:'20px', justifyContent:"center"} } >
      <Grid item xs={12}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              A2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant='h5'>
                Bitte erstelle anhand der beiliegenden "Abrechnung eines Auszubildenden" von Hand ein Stammblatt für den Auszubildenden Gans und gib eine Kopie davon bei Deinem Teamleiter ab.
                Bitte lege den Mitarbeiter Gans anhand des selbst erstellten Stammblattes über das Interview „Arbeitnehmer anlegen über Interview“ an, ohne die Abrechnung zu verwenden.<br/>
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
    </Grid>
    </Fragment>
  }

  {
       lohndaten?.map((inhalt,index) => {
          return(
            <Grid item xs={6}>
              <Card sx={{ display: 'flex', marginTop:'10px', alignItems:"center" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
 //*                   onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                    />

                <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" >
                      {inhalt.bez}
                    </Typography>
                  </CardContent>

                </Box>

                <Button 
                  color="primary"  
                  variant="text"
                  key = {index}
                  target="_blank"
                  alignItems="left"
          //              fullWidth
                  onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez)}
                >
                  Zu erfassende Lohndaten in einem neuen Tab anzeigen!
                </Button>

              </Card>
            </Grid>
          )
        })
    }


    <KursAufgabenErledigt  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

    </div>
 
    )
  }

export default ScheckMK2;