import React, { useEffect } from 'react';

import {Grid, Typography,Checkbox, ListItem, ListItemAvatar, ListItemText,Avatar, FormControlLabel } from '@material-ui/core';



function TabelleWissen (props) {
    const { aufgabe, tabellenkey, fehlerliste, setzeAntworten, frage, antwort1,antwort2,antwort3, text} = props;
    var zwis=aufgabe
    if (!aufgabe) {
      // !aufgabe bedeutet, dass wenn Aufgabe leer bzw. undefined ist
      zwis = tabellenkey
    }


  return(

        

        <Grid item xs={8}style={{textAlign:''}} >


          <ListItem style={{textAlign:''}} justifyContent={'center'}>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                {zwis}
              </Avatar> 
            </ListItemAvatar>
            <ListItemText>

            <Typography color="primary" variant='h5' style={{textDecoration:'underline', textAlign:'center'}}>
                  {frage}
            </Typography>
            <Typography  variant='h6' style={{textDecoration:'underline', textAlign:'left'}}>
                  {text}
            </Typography>
            
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".1"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+".1",event.target.checked)}
                    label={antwort1}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".2"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+".2",event.target.checked)}
                    label={antwort2}
                    labelPlacement="end"
                />
            </Grid>
            <Grid>
                 <FormControlLabel
                    value=""
                    style={{color:(fehlerliste["frage"+tabellenkey+".3"]===true)?"red":"",}}
                    control={<Checkbox color="primary" />}
                    onChange={(event) => setzeAntworten("frage"+tabellenkey+".3",event.target.checked)}
                    label={antwort3}
                    labelPlacement="end"
                />
            </Grid>
          
            

            </ListItemText> 
          </ListItem>

      

        </Grid>






   
  )
}






export {TabelleWissen};