import React, { Suspense } from 'react';
import './App.css';
import {ErrorBoundary} from './components';
import {useAuth} from './context/auth-context'
import { FullPageSpinner } from './components/lib';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))

function App() {
  const {user} = useAuth();

  return (
    <Suspense fallback={<FullPageSpinner/>}>
      <ErrorBoundary >
        {user ? <AuthenticatedApp/> : <UnauthenticatedApp/>}
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
