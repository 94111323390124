import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Avatar, Button, ListItem, ListItemAvatar, ListItemText, TextField, Typography, Grid, Paper} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
import { deepPurple,red,brown,amber,blue} from '@material-ui/core/colors';
const erklärung = '/media/Entgeltgrenze Erklaerung Entgeltgrenze.pdf';
import { useMessage } from '../../context/message-context';

import "../../../node_modules/video-react/dist/video-react.css"


function Eignungstest2(props) {
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [zeit, setZeit] = useState();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [antworten, setAntworten] = useState({});
  const [uhrzeit, ] = useState(new Date().toLocaleTimeString('de-DE'));  
  const heute = new Date().toLocaleDateString('de-DE');
  const [okay, setOkay] = useState(true);
  const rot=red[300];
  const braun=brown[400];
  const hellpink=red[100];
  const gelb=amber["A100"];
  const lila=deepPurple[200];
  const blau=blue[400];
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const {showErrorMessage} = useMessage();
  const [bereitsabgabe, setBereitsabgabe]=useState(false);
  const [zwangabgabe, setZwangabgabe]=useState(false);


  const setRedirect = (path) => {
    navigate(path);
  }

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }

  // eslint-disable-next-line
  useEffect(() => {
    const timer=setTimeout(() => {
        setZeit(zeit-1);
    }, 1000);
    if (zeit < 0) {
      setOkay(false);
      if (testabgabe === false) {
        antwortenSenden()
        setZwangabgabe(true);
      };
      clearTimeout(timer);
    } 
  return () => clearTimeout(timer);
  });

  useEffect(() => {
    setOkay(false);
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "sperren",
      "merkmal": "Eignung2",
      "reactnummer": "011",
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'eignungstest', payload, headers, holeDatenCb, setRedirect);
    //eslint-disable-next-line
  }, [])

  const holeDatenCb = (response) => {   
    if (response.status === 200) {
    
      setZeit(2700);
      if (response.data.Eignung2_zeitstempel !== 0) {
        var zwiszeit = response.data.Eignung2_zeitstempel;

        var startzeit = parseInt(zwiszeit.slice(0,2))*60*60
                    + parseInt(zwiszeit.slice(3,5))*60
                    + parseInt(zwiszeit.slice(6,8));


        var endzeit = parseInt(uhrzeit.slice(0,2))*60*60
                    + parseInt(uhrzeit.slice(3,5))*60
                    + parseInt(uhrzeit.slice(6,8));

        var differenz =  endzeit-startzeit; 

        if (differenz < 0) {
          differenz = 0;
        }
        zwiszeit = 2700 - differenz

        setZeit(zwiszeit);
        if (zwiszeit > 0) {
          setOkay(true);
        }
        if (response.data.Eignung2_tagesdat === "FALSE") {
          setOkay(false);
          setZeit(0);
        }

      }

      if (response.data.Eignung2_abgabedat !== '') {
        setAbgabedat(response.data.Eignung2_abgabedat.slice(6,8)+"."+response.data.Eignung2_abgabedat.slice(4,6)+"."+response.data.Eignung2_abgabedat.slice(0,4));
        setAbgabezeit(response.data.Eignung2_abgabezeit);
        setZeit(0);
        setOkay(false);
        setTestabgabe(true);
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "testabgabe",
      "merkmal": "Eignung2",
      "daten": antworten,
      "reactnummer": "011",
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'eignungstest', payload, headers, kurseCb, setRedirect);
}

const kurseCb = (response) => {
  if (response.status === 200) {
    setTestabgabe(true);
    setOkay(false);
    setZeit(0);
    setAbgabedat(response.data.Eignung2_abgabedat.slice(6,8)+"."+response.data.Eignung2_abgabedat.slice(4,6)+"."+response.data.Eignung2_abgabedat.slice(0,4));
    setAbgabezeit(response.data.Eignung2_abgabezeit);
    if (response.data.Eignung2_bereitsabgabe === "ja") {setBereitsabgabe(true)}
  } else {
    if (response) {
      showErrorMessage(response.data.message);
    } else {
      showErrorMessage('Keine Internetverbindung');
    }
  }
}



  return(
    <div>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="150" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Eignungstest</Typography> 
                  <Typography color="error">
                      Der Test kann nur einmal aktiviert werden.<br />
                      Das Schließen des Fensters, ohne vorher den Test abzugeben, wird als Testabbruch gewertet.<br />
                      Der Test wird mit dem Anklicken des Buttons "Test abgeben" abgegeben. <br /> <br />
                  </Typography>
                    <h5>Verbleibende Zeit: </h5>
                  {zeit>0 ? 
                    <h5 style={{width:"30%",display:"inline-block",textAlign:"center"}}>{
                       Math.floor((zeit / 60) % 60)+' Minuten ' + Math.floor((zeit) % 60)+' Sekunden'
                      }</h5>
                      : 
                      bereitsabgabe && !zwangabgabe?
                        <h4>
                          Der Test wurde bereits abgegeben
                        </h4> 
                      :
                      bereitsabgabe && zwangabgabe ?
                        <h4>
                          Die Zeit ist abgelaufen! Dein Test wurde an ectool übermittelt!
                        </h4> 
                      :
                      <h4>
                        Test erfolgreich abgegeben
                      </h4> 
                  }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>


    {(okay === true) && 
    <div>
      <Grid container spacing={0} >
        <Grid item xs={12}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Schaue Dir die Erklärung zur Entgeltgrenze an."/>
          </ListItem>
        </Grid>
        <Grid item xs={4}>
              <Button 
    //            fullWidth 
                color="rgb(220, 220, 220)" 
                variant="contained"
                href={erklärung}
                download
                >
                Erklärung öffnen
              </Button>
          </Grid>
          <Grid item xs={12}>
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Bearbeiten nun die Tabelle. Viel Erfolg!" />
          </ListItem>
        </Grid>
      </Grid>
      <Grid container spacing={0}justifyContent="center" style={{marginTop:"5px",borderTop:"solid"}}>
        <Grid item xs={12}>
        <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block"}}>Zeitraum</Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:braun,width:"8.33%",display:"inline-block"}}>Entgelt A</Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:hellpink,width:"16.66%",display:"inline-block"}}>Entgeltgrenze </Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:gelb,width:"16.66%",display:"inline-block"}}>Basis Entgelt A </Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:lila,width:"16.66%",display:"inline-block"}}>Sammel Wert</Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:blau,width:"16.66%",display:"inline-block"}}>Entgelt B</Typography>
        <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"left",backgroundColor:rot,width:"16.66%",display:"inline-block"}}>Basis Entgelt B </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Januar</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.500,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA1',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW1',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" variant="standard"style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}} onChange={(event) => setzeAntworten('BEB1',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Februar</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.400,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA2',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW2',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB2',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>März</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.200,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA3',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW3',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB3',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>April</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.950,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA4',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW4',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB4',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Mai</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.100,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA5',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW5',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>1.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB5',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Juni</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.700,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA6',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW6',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB6',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Juli</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.700,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA7',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW7',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB7',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>August</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.070,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA8',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW8',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>800,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB8',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>September</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.950,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA9',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW9',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB9',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Oktober</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.100,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA10',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW10',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB10',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>November</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.200,00</Typography>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA11',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW11',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"solid",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>250,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB11',event.target.value)} > </TextField>
        </Grid>
        <Grid item xs={12}style={{borderBottom:"solid"}}>
          <Typography style={{borderBottom:"",borderRight:"solid",borderLeft:"solid",width:"8.33%",display:"inline-block",height:"32px",lineHeight:"32px"}}>Dezember</Typography>
          <Typography style={{borderBottom:"",borderRight:"solid",textAlign:"right",backgroundColor:braun,width:"8.33%",height:"32px",lineHeight:"32px",display:"inline-block"}}>2.900,00</Typography>
          <Typography style={{borderBottom:"",borderRight:"solid",textAlign:"right",backgroundColor:hellpink,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>3.000,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:gelb,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEA12',event.target.value)} > </TextField>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:lila,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('SW12',event.target.value)} > </TextField>
          <Typography style={{borderBottom:"",borderRight:"solid",textAlign:"right",backgroundColor:blau,width:"16.66%",height:"32px",lineHeight:"32px",display:"inline-block"}}>0,00</Typography>
          <TextField fullWidth id="standard-basic" type="" style={{borderBottom:"",backgroundColor:rot,display:"inline-block",width:"16.66%",borderRight:"solid"}} inputProps={{style:{textAlign:"right"}}}onChange={(event) => setzeAntworten('BEB12',event.target.value)} > </TextField>
        </Grid>

      </Grid>

    <Grid container spacing={2}justifyContent="center" style={{marginTop:"5px"}}>
      <Grid item xs={4}>
      <Button 
  //              fullWidth 
                color="rgb(220, 220, 220)" 
                variant="contained"
                onClick={() => antwortenSenden()}                
                >
                Test Abgeben
              </Button>
      </Grid>
    </Grid>
  </div>
  }

  {(testabgabe === true) && 
      <Fragment>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
              {zwangabgabe & !bereitsabgabe && 
                <Typography variant="h6">
                  Dein 2.Test wurde wegen der Zeitüberschreitung automatisch am {abgabedat} um {abgabezeit} Uhr abgegeben. <br /> <br />
                </Typography>
              }

              {!zwangabgabe & bereitsabgabe && 
                <Typography variant="h6">
                  Dein 2.Test wurde bereits am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br /> <br />
                </Typography>
              }

              {!zwangabgabe & !bereitsabgabe && 
                <Typography variant="h6">
                  Dein 2.Test wurde am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br /> <br />
              </Typography>
              }

              <Typography variant="h6">
                Nun kommst du zu Test Nr. 3 <br />
                Für den Test Nr.3 hast Du 15 Minuten Zeit.  <br />
                Sobald Du auf den Button "MIT TEST 3 BEGINNEN" klickst, fläuft die Zeit. <br />
              </Typography>
          </Grid>   
          <Grid item xs={8}>
              <Button 
                  fullWidth color="primary" 
                  variant="contained"
                  onClick={() => navigate('/011/teil3')} 
                  >
                  Mit Test 3 beginnen
              </Button>
          </Grid>
        </Grid>
      </Fragment>
  }




  </div>
  )
}

export default Eignungstest2;