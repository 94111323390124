import React, {useState, useCallback} from 'react';
import {Typography, Grid} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {TabelleGwVorteilBerechnung} from './tabelleGwVorteilB';
import {TabelleLohnartenErfassung} from './tabelleLohnarten';
import {TabelleRabatt} from './tabelleRabatt';
const beispiel1 = '/media/berechnung_stpfl_sachbezuegen1.png';
const beispiel2 = '/media/berechnung_stpfl_sachbezuegen2.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';

function GwVorteilBerechnung(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('0')
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('018');
  const [merkmal]=useState("GwVorteilBerechnung");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values)
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
 //   console.log({antworten})
  
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          fehlerAusLesen(response.data.fehler);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    
  

    return(
      <div>
        <Briefkopf  
            ueberschrift1="Berechnung von steuerpflichtigen Sachbezügen" 
            ueberschrift2="Mit welchem Betrag muss man einen steuerpflichtigen Sachbezug ansetzen?" 
        />

        <Grid container spacing={0} style={{marginTop:'15px', display:"flex"}} >
           
            <Grid item xs={12} style={{textAlign:"left"}} >
              <Typography variant="h5" color="primary" >
                  Ortsüblicher Preis (96%- Regelung)
              </Typography>
              <Typography >
                  Sachbezüge, für die <b>keine amtlichen Sachbezugswerte festgesetzt sind</b>, sind nach § 8 Abs. 2 Satz 1 EStG mit dem <b>üblichen Endpreis am Abgabeort</b> anzusetzen. 
                  Der übliche Endpreis einer Ware oder Dienstleistung ist der Preis, der für diese Ware oder Dienstleistung im allgemeinen Geschäftsverkehr von Endverbrauchern 
                  in der Mehrzahl der Verkaufsfälle am Abgabeort für gleichartige Waren oder Dienstleistungen tatsächlich gezahlt wird. 
                  Er schließt also die <b>Umsatzsteuer</b> und sonstige Preisbestandteile ein. Andererseits bedeutet "üblicher Endpreis" aber auch, dass bei der 
                  Ermittlung dieses Preises die üblichen Preisnachlässe abgezogen werden. (§ 8 Abs. 2 Satz 1 EStG). Der sich hiernach ergebende Preis ist ggf. 
                  nach objektiven Gesichtspunkten zu schätzen. Nach R 8.1 Abs. 2 Satz 3 LStR kann aus <b>Vereinfachungsgründen</b> auf die Ermittlung der üblichen 
                  Preisnachlässe verzichtet werden und dafür der übliche Endpreis um 4 % gemindert werden (sog. <b>96 %-Regelung</b>).<br/>
                  <b>Kein Bewertungsabschlag von 4 % wird vorgenommen, wenn die Höhe des Vorteils für den Arbeitnehmer auf den ersten Blick ersichtlich ist.</b><br/>
                  Dies ist z. B. der Fall bei betragsmäßig begrenzten Gutscheinen.
              </Typography>
            </Grid>
        </Grid>

      <Grid container spacing={1} style={{display:"flex", marginTop:'25px', textAlign:'left'}} >
          <Grid item xs={12}>
              <Typography style={{}}>
                  <strong>Beispiel:</strong> <br/>
                  Ein Automechaniker in einer Autoreparaturwerkstatt erhält von seinem Arbeitgeber einen Jogginganzug, weil er den PKW eines Kunden besonders gut repariert hat. <br/>
                  Der Arbeitgeber hat den Jogginganzug für 45€ (inklusive Umsatzsteuer) gekauft. In Anwendung der 96%-Regelung ergibt sich folgende Bewertung des dem Arbeitnehmer zugewendeten Sachbezugs:
              </Typography>
          </Grid>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={4}>
            <Typography >
              Kaufpreis des Jogginganzugs
            </Typography>
          </Grid>
          <Grid item xs={2} style={{textAlign:"right"}}>
            <Typography>
              45,00 Euro
            </Typography>
          </Grid>
          <Grid item xs={3}>
          </Grid>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={4} style={{borderBottom:'solid'}}>
            <Typography>
              abzüglich 4% pauschaler Abschlag
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', textAlign:"right"}}>
            <Typography>
              -1,80 Euro
            </Typography>
          </Grid>
          <Grid item xs={3}>
          </Grid>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={4} style={{borderBottom:'solid'}}>
            <Typography>
              verbleibender, steuerlich maßgebender <br/>
              Wert des Sachbezugs
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid', textAlign:"right"}}>
            <Typography>
              43,20 Euro
            </Typography>
          </Grid>
          <Grid item xs={3}>
          </Grid>
      </Grid>
      
      <Grid container spacing={1} style={{marginTop:"35px", justify:"center",  alignItems:"center"}} >
          <Grid item xs={12} >
            <Typography variant="h5" color="primary" style={{textAlign:'left'}}>
              Methode 1 - Versteuerungsmethode
            </Typography> 
              <img src={beispiel1} height="800" alt="ectool" style={{marginTop:"15px"}}
            />
            <Typography variant="h5" color="primary" style={{marginTop:"35px", textAlign:'left'}}>
              Methode 2 - Einbehaltungsmethode
            </Typography>
              <img src={beispiel2} height="800" alt="ectool" style={{marginTop:"15px"}}
            />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{marginTop:"35px", justify:"center",  alignItems:"center"}} >
          <Grid item xs={12} >
            <Typography variant="h4" color="primary" style={{textAlign:'left'}}>
                Aufgaben
            </Typography> 
          </Grid>
      </Grid>


      <TabelleGwVorteilBerechnung
          aufgabe='1a' 
          titel="Eine Friseurin erhält von ihrem Arbeitgeber eine neue Handtasche, weil sie den höchsten Monatsumsatz erreichte. Der Arbeitgeber hat die Handtasche für 59€ (inklusive Umsatzsteuer) gekauft.
                Welche Bewertung des zugewendeten Sachbezugs ergibt sich in Anwendung der 96%-Regelung?"
          tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />

      <TabelleLohnartenErfassung
          aufgabe='1b'
          titel="Bitte verwende bei der Brutto-Netto-Erfassung die Versteuerungsmethode."
          tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />

      <Grid container spacing={1} style={{marginTop:"55px", justify:"center",  alignItems:"center"}} >
      </Grid>
      <TabelleGwVorteilBerechnung
          aufgabe='2a' 
          titel="Ein Verkäufer bekommt von seinem Arbeitgeber zum 10 jährigen Firmenjubiläum eine Uhr im Wert von 370,- Euro (inkl. USt) geschenkt. Welche Bewertung des zugewendeten Sachbezugs ergibt sich in Anwendung der 96%-Regelung?"
          tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />

      <TabelleLohnartenErfassung
          aufgabe='2b' 
          titel="Bitte verwende bei der Brutto-Netto-Erfassung die Einbehaltungsmethode."
          tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />


      <Grid container spacing={1} style={{marginTop:"55px", justify:"center",  alignItems:"center", textAlign:'left'}} >
      </Grid>
      
      <Grid item xs={12} >
          <Typography variant="h4" color="primary" style={{textAlign:'left'}}>
                Rabattfreibetrag
          </Typography> 
          <Typography style={{textAlign:'left'}}>
              Recherchiere im Internet die Versteuerung von Belegschaftsrabatten. <br/>
              Löse anschließend folgende Aufgaben: <br/>
          </Typography>
      </Grid>

      <Grid container spacing={1} style={{marginTop:"25px", justify:"center",  alignItems:"center"}} >
      </Grid>

      <TabelleRabatt
            aufgabe='1'
            titel="Arbeitnehmer können Waren, welche der Arbeitgeber selbst herstellt, mit 25 % Rabatt beim Arbeitgeber kaufen. 
                  Ein Arbeitnehmer kauft Waren im Wert von 7000 € für 5250 € ein. Ermittle den steuerpflichtigen geldwerten Vorteil."
            tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />

      <Grid container spacing={1} style={{marginTop:"35px", justify:"center",  alignItems:"center"}} >
      </Grid>

      <TabelleRabatt
            aufgabe='2' 
            titel="In einer Bäckerei dürfen die Mitarbeiterinnen und Mitarbeiter monatlich Backwaren in Höhe von 93,- Euro (Bruttoverkaufspreis) mit nach Hause nehmen.
                  Ermittle den steuerpflichtigen geldwerten Vorteil für die Mitarbeiterinnen und Mitarbeiter."
            tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}
      />

      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

     </div>
    )
  }
  

export default GwVorteilBerechnung;
