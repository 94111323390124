import React, {useState, useCallback} from 'react';
import {Avatar, Typography, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import apiRequest from '../../functions/api';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {BruttoNeu2} from '../Rahmendaten/BruttoNeu2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const VIDEOAMTLSACHBZEUG = '/media/Chat_Tutorial_Amtliche_Sachbezugswerte.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';

function AmtlSachbezug(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('0')
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer, setReactnummer]=useState('028');
  const [merkmal, setMerkmal]=useState('AmtlSachbezug');


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
   
    setAntworten(values);
    forceUpdate();   
  }
  

  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis)
  };

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }

    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      'hsutkey': hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    console.log(response.data.message);

    if (response.status === 200) {
        setTestabgabe(true);
        fehlerAusLesen(response.data.fehler)
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
            ueberschrift1="Amtliche Sachbezugswerte" 
            ueberschrift2="Worin unterscheiden sich die amtlichen Sachbezugswerte von den anderen Sachbezugswerten?" 
      />

      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEOAMTLSACHBZEUG}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
      </Grid>


      <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  B1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5"  color="primary">
                  Beispiel 1 <br/>
                </Typography>
                <Typography >
                  Der Arbeitgeber gewährt seiner Arbeitnehmerin kostenlose Mahlzeiten in der Kantine.<br/>
                  Die Arbeitnehmer isst einen Salatteller im Wert von 2,70 €. <br/>
                  Der Wert der Mahlzeit ist mit dem amtlichen Sachbezugswert von  <b>3,47 €</b> anzusetzen.<br/>
                  <b>Ein Wahlrecht zwischen tatsächlichem Wert und amtlichem Sachbezugswert besteht nicht.</b>
                </Typography>
              </ListItemText>
          </ListItem>
      </Grid>

      <BruttoNeu2
              anzReihen={2}
              tabellenKey="1" 
              Lohnart={['1029','1344']}
              Intern={['029','344']}
              Bezeichnung={["Gehalt","Sachbezug"]}
              Tage={["21,75","1,00"]}
              Stunden={["174,00","--"]}
              Faktor={["2300,00","3,47"]}
              Kennung={["LFD","LFD"]}
              Prozent={["0,00","0,00"]}
              Steuerpflichtig={["2300,00","3,47"]}
              Sozialverspflichtig={["2300,00","3,47"]}
              Betrag={["2300,00","0,00"]}
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 


        <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  B2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5"  color="primary">
                  Beispiel 2 <br/>
                </Typography>
                <Typography >
                  In der Betriebskantine variieren die Preise für die angebotenen Essen von 5,30 € bis 7,80 €. <br/>
                  Die Arbeitnehmer zahlen pro Essen einen Eigenanteil in Höhe von 2,- Euro. <br/>
                  Angenommen ein Arbeitnehmer hat im Abrechnungsmonat folgende Essen in Anspruch genommen. <br/>
                </Typography>

              </ListItemText>
          </ListItem>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={1}></Grid>
          <Grid item xs={2} style={{}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Einzelpreis</TableCell>
                            <TableCell>Anzahl</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell >
                                5,30 Euro
                            </TableCell>
                            <TableCell >
                               4 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell >
                                6,50 Euro
                            </TableCell>
                            <TableCell >
                               8 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell >
                                7,80 Euro
                            </TableCell>
                            <TableCell >
                               3 
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={9}></Grid>
      </Grid>
      <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  L2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5"  color="primary">
                  Lösung Beispiel 2 <br/>
                </Typography>
                <Typography >
                  Um den Geldwerten Vorteil für den kompletten Abrechnungsmonat zu berechnen, empfiehlt es sich die Tabelle wie folgt fortzuführen: <br/>
                  In der Tabelle wurde der im Jahr 2021 geltende amtliche Sachbezugswert verwendet.<br/>
                </Typography>

              </ListItemText>
          </ListItem>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={1}></Grid>
          <Grid item xs={7} style={{}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign:"right"}}>Einzelpreis</TableCell>
                            <TableCell style={{textAlign:"right"}}>Anzahl</TableCell>
                            <TableCell style={{textAlign:"right"}}>Amtl.<br/>Sachbezugswert</TableCell>
                            <TableCell style={{textAlign:"right"}}>Eigenanteil<br/> pro Essen</TableCell>
                            <TableCell style={{textAlign:"right"}}>Differenz</TableCell>
                            <TableCell style={{textAlign:"right"}}>mutlipliziert <br/>mit der Anzahl</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"right"}} >
                                5,30 Euro
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               4 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               3,47 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               2,00 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               1,47
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               5,88 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"right"}}>
                                6,50 Euro
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               8 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               3,47 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               2,00 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               1,47
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               11,76 
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"right"}}>
                                7,80 Euro
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               3 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               3,47 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               2,00 
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               1,47
                            </TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               4,41 
                            </TableCell>
                        </TableRow>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell style={{textAlign:"right"}}>
                               22,05 
                            </TableCell>

                    </TableBody>
                </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={4}></Grid>
      </Grid>



      <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>


    </div>
    )
}


export default AmtlSachbezug;