export const LOCAL_STORAGE_KEY = {
    useDeviceAuth: 'useDeviceAuth',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    client: 'client',
    deviceId: 'deviceId',
    theme: 'theme',
    username: 'username',
    saveUsername: 'saveUsername',
    private: 'private',
    hsutkey: 'hsutkey',
    dataCourse: 'dataCourse',
}