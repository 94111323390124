import React from 'react';
import {Grid, MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';





function Beitragssaetze (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, kvbeitragssatz, pvelternsatz, rvbeitragssatz, avbeitragssatz, pvbeitragssatz } = props;

    if (kvbeitragssatz !== '') {
        antworten["kvbeitragssatz"+tabellenKey]=kvbeitragssatz;
        var kvbeitragssatzblockiert=true;
    }

    if (rvbeitragssatz !== '') {
        antworten["rvbeitragssatz"+tabellenKey]=rvbeitragssatz;
        var rvbeitragssatzblockiert=true;
    }

    if (avbeitragssatz !== '') {
        antworten["avbeitragssatz"+tabellenKey]=avbeitragssatz;
        var avbeitragssatzblockiert=true;
    }

    if (pvbeitragssatz !== '') {
        antworten["pvbeitragssatz"+tabellenKey]=pvbeitragssatz;
        var pvbeitragssatzblockiert=true;
    }
    if (pvelternsatz !== '') {
        antworten["pvelternsatz"+tabellenKey]=pvelternsatz;
        var pvelternsatzgblockiert=true;
    }

return(
    <div>

    <Grid container spacing={2} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={5}>
           <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" >Bezeichnung</TableCell>
                            <TableCell align="center" >Beitragssatz</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell align="center" >
                                Krankenversicherung (KV)
                            </TableCell>
                            <TableCell align="center">
                                <Eingabe 
                                    disabled={kvbeitragssatzblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["kvbeitragssatz"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kvbeitragssatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kvbeitragssatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('kvbeitragssatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>

                        </TableRow>

                        <TableRow >
                            <TableCell align="center">
                                Rentenversicherung (RV)
                            </TableCell>
                            <TableCell align="center">
                                <Eingabe 
                                    disabled={rvbeitragssatzblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["rvbeitragssatz"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["rvbeitragssatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["rvbeitragssatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('rvbeitragssatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>


                        <TableRow >
                            <TableCell align="center">
                                Arbeitslosenversicherung (AV)
                            </TableCell>
                            <TableCell align="center">
                                <Eingabe 
                                    disabled={avbeitragssatzblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["avbeitragssatz"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["avbeitragssatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["avbeitragssatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('avbeitragssatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell align="center">
                                Pflegeversicherung (PV) <br/>
                                Zuschlag kinderlos
                            </TableCell>
                            <TableCell align="center">
                                <Eingabe 
                                    disabled={pvbeitragssatzblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["pvbeitragssatz"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["pvbeitragssatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["pvbeitragssatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('pvbeitragssatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                                <Eingabe 
                                    disabled={pvelternsatzgblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["pvelternsatz"+tabellenKey]}     
                                    style={{textAlign:"center",color:"!important", display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["pvelternsatz"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["pvelternsatz"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('pvelternsatz'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>


    </Grid>

    </div>
 
  )
}

export {Beitragssaetze};