import { Typography, Button, Box } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';
import {useNavigate} from 'react-router-dom';
// import * as Sentry from "@sentry/react";
import apiRequest from '../../functions/api';
import {DEBUG_FLAG} from '../../constants/debug';
import {VERSION} from '../../constants/version';

const ErrorBoundaryWithNavigate = ({children, ...props}) => {
  const navigate = useNavigate();
  return(<ErrorBoundary navigate={navigate} {...props}>{children}</ErrorBoundary>)
}

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
          hasError: false,
          redirectTo: null,
          mounted: false,
          error: null
        };
    }
    
    componentDidMount() {
        this.setState({mounted: true});
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }
    
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      console.log(error);
      this.logErrorToMyService(error, info);
    }

    errorCb = (response) => {
      if (response.status === 200) {
        console.log('successfully sent error log');
      } else {
        console.log('failed to send error log');
      }
    }
    
    setRedirect = (path) => {
      const {navigate} = this.props;
      this.setState({hasError: false, error: null});
      if (navigate) navigate(path);
    }
    
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return(
            <Fragment>
              <div className="ios-padding"></div>
              <Box p={2}>
              <Box p={1}>
              <Typography variant="h4" color="error">Unerwarteter Fehler</Typography>
              </Box>
              <Box p={1}>
              <Typography variant="body1">Es ist ein Fehler aufgetreten. Dieser wurde automatisch an ectool übermittelt.</Typography>
              </Box>
              <Box p={1}>
              <Button variant="contained" color="primary" onClick={() => this.setRedirect('/')}>Zum Verteiler</Button>
              </Box>
              </Box>
            </Fragment>
        );
      }
      return this.props.children;
    }

    logErrorToMyService(error, info) {
      //Sentry.captureException(error);
      if (!DEBUG_FLAG) {
        const payload={
          "mandant": this.props.client,
          "error": error.toString(),
          "program": location.pathname,
          "version": VERSION,
          "errorInfo": info.componentStack
        }
        var headers={};
        if (this.props.accessToken !== '') {
          headers={
            "Authorization": "Bearer "+this.props.accessToken,
          }
        }
        apiRequest('POST', 'error', payload, headers, this.errorCb);  
      }
    }
  }

  export default ErrorBoundaryWithNavigate;
