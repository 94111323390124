import React, {useState} from 'react';
import axios from 'axios';
import {API_BASE_URL} from '../../constants/apiConstants';
import { TextField, Box, Card, CardContent, CardMedia, Button, Grid, Typography} from "@material-ui/core";
import {VERSION} from '../../constants/version';
const headerbild = '/media/ecschool_header1.png';
import {
  isBrowser,
  isMobile,
  osName,
  osVersion,
  browserVersion,
  browserName,
  mobileModel,
  mobileVendor
} from "react-device-detect";
import { useMessage } from '../../context/message-context';
import { useLocalStorage } from '../../utils/hooks';
import { LOCAL_STORAGE_KEY } from '../../constants/localStorage';

function LoginUser(props) {
    const [, setUsername] = useLocalStorage(LOCAL_STORAGE_KEY.username);
    const [usernameValue, setUsernameValue] = useState('');
    const [deviceId, setDeviceId] = useLocalStorage(LOCAL_STORAGE_KEY.deviceId);
    const [error, setError] = useState('');
    const {showErrorMessage} = useMessage();
          
    const handleSubmitClick = (e) => {
      e.preventDefault();
      var payload = {};
      if (isMobile) {
        payload={
          "username": usernameValue,
          "deviceId": deviceId,
          "device": mobileVendor+' '+mobileModel,
          "os": osName,
          "osBez": mobileVendor+' '+mobileModel+' '+osName+' '+osVersion,
          "clientVersion": VERSION
        }
      }
      if (isBrowser) {
        payload={
          "username": usernameValue,
          "deviceId": deviceId,
          "browser": browserName+' '+browserVersion,
          "os": osName,
          "osBez": osName+' '+osVersion+' '+browserName+' '+browserVersion,
          "clientVersion": VERSION
        }
      }

      axios.post(API_BASE_URL+'login/user', payload)
      .then(function (response) {
        setError('');
        setUsername(usernameValue);
        if (response.data.deviceId) {
          setDeviceId(response.data.deviceId);
        }
      })
      .catch(function (err) {
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
          console.log(err.response.data.message);
          setError(err.response.data.message);
        } else {
          showErrorMessage('Netzwerkfehler. Sind Sie mit dem Internet verbunden?');
        }
      });
    }

    return(
      <Box p={2}>
        <Grid container >
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
               <Grid item xs={12} sm={8} md={6}>
                <Card >
                <CardMedia
                style={{paddingTop:'60.25%'}} 
                image={headerbild}
                title="ecakademie Titelbild"
                />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Herzlich Willkommen bei der ectool - Akademie</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">Geben Sie Ihren Benutzernamen ein um sich in der Akademie anzumelden.</Typography>
                      </Grid>
                    </Grid>
                    <form >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                          fullWidth
                          required
                          error={error !== ''}
                          helperText={error}
                          autoComplete="off"
                          type="text"
                          label="Benutzername"
                          variant="outlined"
                          id="username"
                          aria-describedby="emailHelp"
                          placeholder="Benutzername eingeben ..."
                          value={usernameValue}
                          onChange={(e) => setUsernameValue(e.target.value)}
                          inputProps={{
                            autoCapitalize: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'false'
                          }}
                          />
                          <Typography variant="body2">Benutzername</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSubmitClick}
                          >Weiter</Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
}

export default LoginUser;
