import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function BLP_Tabelle (props) {
//  const {tabellenKey, fehlerliste, setzeAntworten, antworten, bruttolfd, bruttoega, bruttogesamt, steuern, sozvers, agantAusz, netto} = props;



return(
    <div>

    <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
    

        <Grid item xs={6}>
            <Typography style={{textAlign:'right', backgroundColor:'#EDEEEE'}}>
                Zurechnung zum Bruttolistenpreis
            </Typography>

           <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Ja </TableCell>
                            <TableCell>Nein</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell >
                                ABS
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Airbag
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Allradantrieb
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Anhängerkupplung
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Autoradio
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Autotelefon
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Diebstahlsicherung
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Einparkhilfe
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Elektronisches Fahrtenbuch
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Feuerlöscher
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Freisprechanlage
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Gasantrieb
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Katalysator
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Klimaanlage/Klimaautomatik
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Multifunktionslenkrad
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Musikanlage
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Navigationsgerät (Werkseitig eingebaut)
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Preisnachlass
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Standheizung
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Tempomat
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Überführungskosten
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Umsatzsteuer
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Xenonlicht 
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Winterreifen mit Felgen (zusätzlich zur Normalbereifung)
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Zulassungskosten
                            </TableCell>
                            <TableCell >
                               
                            </TableCell>
                            <TableCell >
                               x
                            </TableCell>
                        </TableRow>
                        

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>

    </div>
 
  )
}

export {BLP_Tabelle};