import React from 'react';
import {Grid, MenuItem, TextField, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function BeitragsgruppenAuswahl (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, kvBlockiert, rvBlockiert, avBlockiert, pvBlockiert} = props;


return(
    <div>

    <Grid container spacing={2} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={10}>
           <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" >KV</TableCell>
                            <TableCell align="center" >RV</TableCell>
                            <TableCell align="center" >AV</TableCell>
                            <TableCell align="center" >PV</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell align="center">
                                <TextField 
                                    select // durch select wird DropDown initialisiert
                                    disabled={kvBlockiert}
                                    id="KVKEY"
//*                                style={{display:"inline-block",marginTop:"1px",backgroundColor:(fehlerliste["pgr"+tabellenKey]===true)?"red":""}}
                                    style={{display:"inline-block",marginTop:"2px",backgroundColor:(fehlerliste["kv"+tabellenKey]===true)?"red":""}}                                
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    placeholder="Auswahl"
                                    error={fehlerliste["kv"+tabellenKey]}     
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kv"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('kv'+tabellenKey,event.target.value)}
                                    >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </TextField>                    
                            </TableCell>
                            <TableCell align="center">
                                <TextField 
                                    select // durch select wird DropDown initialisiert
                                    disabled={rvBlockiert}
                                    id="RVKEY"
                                    style={{display:"inline-block",marginTop:"2px",backgroundColor:(fehlerliste["rv"+tabellenKey]===true)?"red":""}}                                
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    placeholder="Auswahl"
                                    error={fehlerliste["rv"+tabellenKey]}     
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["rv"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('rv'+tabellenKey,event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                        style:{color:"#1fbba6"}
                                    }} >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </TextField>                    
                            </TableCell>
                            <TableCell align="center">
                                <TextField 
                                    select // durch select wird DropDown initialisiert
                                    disabled={avBlockiert}
                                    id="AVKEY"
                                    style={{display:"inline-block",marginTop:"2px",backgroundColor:(fehlerliste["av"+tabellenKey]===true)?"red":""}}                                
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    placeholder="Auswahl"
                                    error={fehlerliste["av"+tabellenKey]}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["av"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('av'+tabellenKey,event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                        style:{color:"#1fbba6"}
                                    }} >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </TextField>                    
                            </TableCell>
                            <TableCell align="center">
                                <TextField 
                                    select // durch select wird DropDown initialisiert
                                    disabled={pvBlockiert}
                                    id="PVKEY"
                                    style={{display:"inline-block",marginTop:"2px",backgroundColor:(fehlerliste["pv"+tabellenKey]===true)?"red":""}}                                
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    placeholder="Auswahl"
                                    error={fehlerliste["pv"+tabellenKey]}     
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["pv"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('pv'+tabellenKey,event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                        style:{color:"#1fbba6"}
                                    }} >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </TextField>                    
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>


    </Grid>

    </div>
 
  )
}

export {BeitragsgruppenAuswahl};