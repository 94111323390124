import React, {useState, useCallback } from 'react';
import {Avatar,Typography, ListItem, ListItemAvatar, ListItemText, Grid, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { Player, ControlBar } from 'video-react';
const VIDEO = '/media/Chat-Tutorial_Lohnfortzahlung_Krankheit.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Tabelle} from './Tabelle'




function FehlzeitKrank(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('031');
  const [merkmal]=useState("LFZKrank");

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }
  
  const setRedirect = (path) => {
    navigate(path);
  }
 

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
       // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    
    setAntworten(values);
    forceUpdate();   
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);

  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
      } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError('Keine Internetverbindung');
      }
    }
  }
  
  return(
    <div>
      <Briefkopf  
          ueberschrift1="Lohnfortzahlung bei Arbeitsunfähigkeit (Krankheit)" 
          ueberschrift2="Wie lange hat der Arbeitgeber Lohnfortzahlung nach dem Lohnfortzahlungsgesetz zu leisten?" 
      />

      <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Schaue Dir das Tutorial dazu an.
          </Typography>

        </Grid>
      </Grid>
      
      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
          <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEO}
            >
            <ControlBar autoHide={false} />
            </Player>
          </Grid>
      </Grid>


      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                1
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Aufgabe 1"/>
          </ListItem>
         
          <Tabelle
             tabellenKey='1'
             text='Bei den angegebenen Zeiträumen handelt es sich um die gleiche Krankheit. Fülle die Tabelle vollständig aus.'
              Zeitraum1='* 10.01.2020 - 23.01.2020'
              Kalendertage1=''
              Zeitraum2='* 05.03.2020 - 15.03.2020'
              Kalendertage2=''
              Zeitraum3='* 25.03.2020 - 30.03.2020'
              Kalendertage3=''
              Zeitraum4='* 03.04.2020 - 30.04.2020'
              Kalendertage4=''
              Gesamttage=''
              EndeLohn=''

              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />  
        </Grid> 
       </Grid>
       

       <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        
        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                2
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Aufgabe 2"/>
          </ListItem>
         
          <Tabelle
             tabellenKey='2'
             text='Bei den angegebenen Zeiträumen handelt es sich um die gleiche Krankheit. Fülle die Tabelle vollständig aus.'
             Zeitraum1='* 10.03.2020 - 07.04.2020'
             Kalendertage1=''
             Zeitraum2='** 17.04.2020 - 24.04.2020'
             Kalendertage2=''
             Zeitraum3='* 30.11.2020 - 12.12.2020'
             Kalendertage3=''
             Zeitraum4='** 22.12.2020 - 05.01.2021'
             Kalendertage4=''
             Gesamttage=''
             EndeLohn=''

             setzeAntworten={setzeAntworten} 
             antworten={antworten} 
             fehlerliste={fehlerliste}
          />  
        </Grid> 
      </Grid>
      <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                3
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Aufgabe 3"/>
          </ListItem>
         
          <Tabelle
             tabellenKey='3'
             text='Bei den angegebenen Zeiträumen handelt es sich um verschiedene Krankheiten. Fülle die Tabelle vollständig aus.'
             Zeitraum1='* 20.05.2020 - 01.06.2020'
             Kalendertage1=''
             Zeitraum2='** 05.06.2020 - 15.06.2020'
             Kalendertage2=''
             Zeitraum3='* 30.07.2020 - 18.08.2020'
             Kalendertage3=''
             Zeitraum4='* 20.08.2020 - 02.09.2020'
             Kalendertage4=''
             Gesamttage=''
             EndeLohn=''
             setzeAntworten={setzeAntworten} 
             antworten={antworten} 
             fehlerliste={fehlerliste}/>  
        </Grid> 
       </Grid>
       <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={12} style={{}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                4
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Aufgabe 4"/>
          </ListItem>
         
          <Tabelle
             tabellenKey='4'
             text='Bei den angegebenen Zeiträumen handelt es sich um die gleiche Krankheit. Fülle die Tabelle vollständig aus.'
             Zeitraum1='* 08.04.2020 - 17.04.2020'
             Kalendertage1=''
             Zeitraum2='* 13.08.2020 - 26.08.2020'
             Kalendertage2=''
             Zeitraum3='* 12.12.2020 - 27.12.2020'
             Kalendertage3=''
             Zeitraum4='** 01.06.2021 - 16.06.2021'
             Kalendertage4=''
             Gesamttage=''
             EndeLohn=''
             setzeAntworten={setzeAntworten} 
             antworten={antworten} 
             fehlerliste={fehlerliste}/>  
        </Grid> 
       </Grid>
         
       

       <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

</div>
)
}


export default FehlzeitKrank;