import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Kursabgeben } from '../../components/Rahmendaten/Kursabgeben';
import { Briefkopf } from '../../components/Rahmendaten/Briefkopf';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import ForwardIcon from '@mui/icons-material/Forward';
import FeedIcon from '@mui/icons-material/Feed';
import CalculateIcon from '@mui/icons-material/Calculate';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';
import { Tabelle2spaltig } from './../Rahmendaten/Tabelle2spaltig'

function BAV3(props) {
    const forceUpdate = useCallback(() => updateState({}), []);
    const [, updateState] = useState();
    const { accessToken, hsutkey } = props;
    const navigate = useNavigate();
    const [antworten, setAntworten] = useState({});
    const [testabgabe, setTestabgabe] = useState(false);
    const [abgabedat, setAbgabedat] = useState('')
    const [abgabezeit, setAbgabezeit] = useState('')
    const [fehlerliste, setFehlerliste] = useState([]);
    const [anzFehler, setAnzFehler] = useState('99')
    const { showErrorMessage } = useMessage();
    const [bemerkungen, setBemerkungen] = useState('')
    const [ausgewaehlteLohnarten, setAusgewaehlteLohnarten] = useState([]);
    const [reactnummer] = useState('050');
    const [merkmal] = useState("BAV3");
    const einrueck = "50px";

    var gehalt = '2700.00'
    const setzeAntworten = (key, value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        forceUpdate();
    }

    const setRedirect = (path) => {
        navigate(path);
    }


    const fehlerAusLesen = (fehler) => {
        console.log("fehler: " + fehler)
        let array = fehler.split(";");
        let fehlerzwis = []
        array.map(
            // eslint-disable-next-line
            (objekt) => {
                fehlerzwis[objekt] = true;
            }
        );
        setFehlerliste(fehlerzwis);
    };


    const antwortenSenden = () => {
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        const payload = {
            "kennung": "kursabgabe",
            "merkmal": merkmal,
            "daten": antworten,
            "ausgewaehlteLoas": ausgewaehlteLohnarten,
            "reactnummer": reactnummer,
            "hsutkey": hsutkey
        }
        apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
    }


    const kurseCb = (response) => {
        if (response.status === 200) {
            setTestabgabe(true);
            setAbgabedat(response.data.abgabedat.slice(6, 8) + "." + response.data.abgabedat.slice(4, 6) + "." + response.data.abgabedat.slice(0, 4));
            setAbgabezeit(response.data.abgabezeit);
            fehlerAusLesen(response.data.fehler);
            setAnzFehler(response.data.anzfehler);
            setBemerkungen(response.data.besonderheiten);
        } else {
            if (response) {
                showErrorMessage(response.data.message);
            } else {
                showErrorMessage('Keine Internetverbindung');
            }
        }
    }

    const linkCb = (response) => {
        if (response.status === 200) {
            if (response.data.rehmlink !== "") {
                window.open(response.data.rehmlink)
            }
        } else {
            if (response) {
                showErrorMessage(response.data.message);
            } else {
                showErrorMessage('Keine Internetverbindung');
            }
        }

    }

    const LexikonLohnbüro = () => {
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        const payload = {
            "kennung": "kursabgabe",
            "merkmal": "getLink",
            "reactnummer": "046"
        }
        apiRequest('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
    }

    const kug_tabelle = () => {
        setRedirect();
    }

    return (
        <div>
            <Briefkopf
                ueberschrift1="Die betriebliche Altersvorsorge-3 (bAV)"
                ueberschrift2="Ausnutzung der bAV-Grenzen"
            />

            <Grid container spacing={0} style={{ marginTop: '15px', display: "flex" }} >
                <Grid item xs={12} style={{ textAlign: "left" }} >
                    <Typography variant="h5" color="primary" >
                        Wie verhält es sich, wenn die bAV-Grenzen von Beginn an, bzw. vom Januar an vollständig ausgenutzt werden? <br />
                    </Typography>
                    <Typography variant='h5'>
                        Diese Konstellation sollte bei Überschreitung der bAV-Grenzen eigentlich immer angewendet werden,
                        um bei einem Austritt innerhalb eines Jahres die bAV-Grenzen bestmöglich angewendet zu haben.<br />
                        Die Darstellung auf der Entgeltabrechnung ist ein wenig kompliziert und für einen Unwissenden verwirrend. <br />
                        Deshalb ist es wichtig, dass Du die Anwendung, verbunden mit der Darstellung auf Entgeltabrechnung verstehst.
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
                    <Typography variant='h5'>
                        Die Grenze der bAV für die Steuer- und SV-Freiheit ist
                    </Typography>
                    <ListItem>
                        <ListItemAvatar style={{ marginLeft: einrueck }}>
                            <Avatar style={{ backgroundColor: "#1fbba6" }}>
                                <ForwardIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h5">
                                <b>4 % der jährlichen Beitragsbemessungsgrenze West der Rentenversicherung</b><br />
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6} style={{ textAlign: "left", marginLeft: einrueck }} >
                    <Tabelle2spaltig
                        anzReihen="3"
                        ueberschrift="Ermittlung der Jahres-Freibetragsgrenze für 2023"
                        uebschr_spalte1="Bezeichnung"
                        spalte1Platz="left"
                        uebschr_spalte2="Wert"
                        spalte2Platz="right"
                        spalten1={["Beitragsbemessungsgrenze West der Rentenversicherung", "4% von 87600,00 = SV-Freibetrag für die bAV", "weitere 4% (also 8%) von 87600,00 = Steuerfreibetrag für die bAV"]}
                        spalten2={["87600,00", "3504,00", "7008,00"]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                    <Typography variant="h6" style={{ marginTop: "30px" }}>
                        Zur Wiederholung bzw. Vertiefung nochmals die Hauptkriterien der bAV-Grenze.
                    </Typography>

                </Grid>

                <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
                    <ListItem>
                        <ListItemAvatar style={{ marginLeft: einrueck }}>
                            <Avatar style={{ backgroundColor: "#1fbba6" }}>
                                <ForwardIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h5" >
                                <b>Es spielt keine Rolle, ob der Betrag in einem Monat vollständig oder in mehreren Monaten teilweise verwendet wird.</b>
                            </Typography>
                            <Typography variant="h5" >
                                Somit kann auch 1/12 pro Monat monatlich in eine oder mehrere bAV's steuer- und sv-frei eingezahlt werden.
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar style={{ marginLeft: einrueck }}>
                            <Avatar style={{ backgroundColor: "#1fbba6" }}>
                                <ForwardIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h5">
                                Die Grenze gilt pro erstes Beschäftigungsverhältnis pro Jahr.<br />
                                Das bedeutet, dass ein Arbeitnehmer, der innnerhalb eines Jahres sein Beschäftigungsverhältnis wechselt, zweimal die Grenzen zur bAV anwenden kann.
                                Wichtig dabei ist, dass es sich jedesmal um sein erstes Beschäftigungsverhältnis handeln muss.
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar style={{ marginLeft: einrueck }}>
                            <Avatar style={{ backgroundColor: "#1fbba6" }}>
                                <ForwardIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h5">
                                Man kann auch gleichzeitig mehrere Durchführungswege bedienen.<br />
                                Solange die Summe der Beträge aller zu bedienenden Durchführungswege nicht höher ist, als 4% der BBG-RV West, greift SV-Freiheit. <br />
                                Solange die Summe der Beträge aller zu bedienenden Durchführungswege nicht höher ist, als 8% der BBG-RV West, greift die Steuer-Freiheit. <br />
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </Grid>
            </Grid>

            <Grid container spacing={0} style={{ marginTop: '30px', display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }} >
                <Grid >
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: "#1fbba6" }}>
                                R
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h5">
                                Eigenrecherche im Lexikon für das Lohnbüro
                            </Typography>
                            <Typography style={{ textAlign: "left" }}>
                                Mit Klick auf den Button gelangst Du auf das digitale Lexikon für das Lohnbüro. <br />
                                Benutze das Lexikon, um Dich auf dem Gebiet der betrieblichen Altersvorsorge schlau zu machen.<br />
                                Mit dem Stichwort "Zukunftssicherung" steht Dir reichlich Informationsmaterial im Lexikon zur Verfügung.<br />
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                alignItems="center"
                                backgroundColor="#4F4F4F"
                                style={{ align: "center" }}
                                onClick={() => LexikonLohnbüro()}
                            >
                                Lexikon für das Lohnbüro
                            </Button>
                        </ListItemText>
                    </ListItem>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
                <Grid item xs={12} >
                    <ListItem >
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                                <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                Beispiel 1<br />
                                Ab Januar 2023 zahlt ein Arbeitnehmer monatlich in eine Pensionskasse als bAV einen Betrag in Höhe von 400,- Euro ein. <br />
                                Wie erfolgt nun der Ausweis auf der Entgeltabrechnung unter der Vorgabe, dass die 400,- Euro bis zu dem Monat, an dem die Summe der bezahlten und umgewandelten
                                bAV die bAV-Grenze erreicht hat, vollständig ausgenutzt wird.<br />
                                Der Arbeitgeberzuschuss zur bAV ist ein monatlich gleichbleibender Betrag in Höhe von 70,- Euro.
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} style={{ marginTop: "20px" }} >
                    <Typography align="left">
                        <strong>Steuer- und beitragsrechtlicher Ausweis der bAV auf der Entgeltabrechnung Januar</strong>
                    </Typography>
                    <BruttoNeu2
                        anzReihen={3}
                        tabellenKey="1"
                        Lohnart={['1029', '0760']}
                        Intern={['029', '760']}
                        Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
                        Tage={["21,75", '0,00']}
                        Stunden={["174,00", '0,00']}
                        Faktor={[gehalt, ""]}
                        Kennung={["LFD", 'LFD']}
                        Prozent={["0,00", '0,00']}
                        Steuerpflichtig={[gehalt, "", ""]}
                        Sozialverspflichtig={[gehalt, "", ""]}
                        Betrag={[gehalt, "", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <NettoLoa2
                        anzReihen={2}
                        tabellenKey="1"
                        text=''
                        NLohnart={['0761', '2301']}
                        NIntern={['761', '301']}
                        NBezeichnung={["bAV AG Zusch.", "Bez.Pensionska"]}
                        NBetrag={["", "", "", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
                <ListItem >
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                            <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <Typography variant="h6" style={{ marginLeft: "10px" }}>
                            Wie sieht nun der steuer- und beitragsrechtliche Ausweis der bAV auf der Entgeltabrechnung September aus, wenn der bAV-Betrag sich in den letzten 8 Monaten nicht verändert hat?
                        </Typography>
                    </ListItemText>
                </ListItem>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} style={{ marginTop: "20px" }} >
                    <Typography align="left">
                        <strong>Steuer- und beitragsrechtlicher Ausweis der bAV auf der Entgeltabrechnung September</strong>
                    </Typography>
                    <BruttoNeu2
                        anzReihen={3}
                        tabellenKey="2"
                        Lohnart={['1029', '0760']}
                        Intern={['029', '760']}
                        Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
                        Tage={["21,75", '0,00']}
                        Stunden={["174,00", '0,00']}
                        Faktor={[gehalt, ""]}
                        Kennung={["LFD", 'LFD']}
                        Prozent={["0,00", '0,00']}
                        Steuerpflichtig={[gehalt, "", ""]}
                        Sozialverspflichtig={[gehalt, "", ""]}
                        Betrag={[gehalt, "", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <NettoLoa2
                        anzReihen={2}
                        tabellenKey="2"
                        text=''
                        NLohnart={['0761', '2301']}
                        NIntern={['761', '301']}
                        NBezeichnung={["bAV AG Zusch.", "Bez.Pensionska"]}
                        NBetrag={["", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
                <ListItem >
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                            <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <Typography variant="h6" style={{ marginLeft: "10px" }}>
                            Wie sieht nun der steuer- und beitragsrechtliche Ausweis der bAV auf der Entgeltabrechnung Oktober aus,
                            wenn der bAV-Betrag sich in den letzten 9 Monaten nicht verändert hat und davon ausgegangen wird, dass alle Monate richtig ausgewiesen wurden?<br/>
                            <b>Wichtiger Hinweis:</b><br/>
                            Dass nun plötzlich auch im Brutto der AG-Anteil zur bAV ausgewiesen wird, hängt mit dem Erreichen des Freibetrages zusammen.
                            Da der AG-Anteil Steuer- und SV-Frei vergütet wird und in dem Monat eine Freigrenze überschritten ist, muss in der Sparte, in der die Freigrenze überschritten ist,
                            der Betrag hinzugerechnet werden, damit die Auszahlung in der jeweiligen Sparte nicht mehr frei, sondern pflichtig abgerechnet wird.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} style={{ marginTop: "20px" }} >
                    <Typography align="left">
                        <strong>Steuer- und beitragsrechtlicher Ausweis der bAV auf der Entgeltabrechnung Oktober</strong>
                    </Typography>
                    <BruttoNeu2
                        anzReihen={4}
                        tabellenKey="3"
                        Lohnart={['1029', '0760', '0761']}
                        Intern={['029', '760', '761']}
                        Bezeichnung={["Gehalt", "bAV Eigenant.", "bAV AG Anteil", "Summen"]}
                        Tage={["21,75", '0,00', '0,00']}
                        Stunden={["174,00", '0,00', '0,00']}
                        Faktor={[gehalt, "","70,00"]}
                        Kennung={["LFD", 'LFD', 'LFD']}
                        Prozent={["0,00", '0,00', '0,00']}
                        Steuerpflichtig={[gehalt, "", "", ""]}
                        Sozialverspflichtig={[gehalt, "", "", ""]}
                        Betrag={[gehalt, "", "", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <NettoLoa2
                        anzReihen={2}
                        tabellenKey="3"
                        text=''
                        NLohnart={['0761', '2301']}
                        NIntern={['761', '301']}
                        NBezeichnung={["bAV AG Zusch.", "Bez.Pensionska"]}
                        NBetrag={["", ""]}
                        setzeAntworten={setzeAntworten}
                        antworten={antworten}
                        fehlerliste={fehlerliste}
                    />
                </Grid>
            </Grid>

            <Kursabgeben anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />

        </div>
    )
}

export default BAV3;
