import React, {useState, useCallback} from 'react';
import {Typography, Grid,ListItem, ListItemAvatar, ListItemText,Avatar} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEOFEHLZEIT = '/media/Chat_Tutorial_Fehlzeit.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {FehlzeitenBlock} from '../../components/Rahmendaten/Fehlzeitenblock'
import {SVBlockneu} from '../../components/Rahmendaten/SVBlockneu';
import {BruttoNeu} from '../../components/Rahmendaten/BruttoNeu';


function Fehlzeiten(props) {

  const { accessToken, hsutkey } = props;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [testabgabe, setTestabgabe]=useState(false);
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('016');
  const [merkmal]=useState('Fehlzeiten');



  const setRedirect = (path) => {
    navigate(path);
  }

  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
//    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }
  
  const antwortenSenden = () => {
//   console.log({antworten})

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
 //   console.log(response.data.message);

    if (response.status === 200) {
        setTestabgabe(true);
        fehlerAusLesen(response.data.fehler,response.data.anzfehler)
        setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
        setAbgabezeit(response.data.abgabezeit);
        setAnzFehler(response.data.anzfehler);
        setBemerkungen(response.data.besonderheiten);
} else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  return(    
  <div>
    <Briefkopf  
        ueberschrift1="Fehlzeiten" 
        ueberschrift2="Die Auswirkungen von Fehlzeiten auf der Entgeltabrechnung" 
    />

    <Grid container spacing={0} style={{marginTop:'10px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} style={{}}>
            <Player
                poster={POSTER}
                src={VIDEOFEHLZEIT}
            >
            <ControlBar autoHide={false} />
            </Player>
        </Grid>
    </Grid>

<Grid container spacing={0} style={{marginTop:'30px'}} >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             Fz1
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h5">
            Fehlzeitentabelle 
          </Typography>  
        </ListItemText> 
      </ListItem>
      <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
            Anbei eine Tabelle der meist verwendeten Fehlzeiten.<br/>
            In dieser Tabelle erkennst Du, ob durch die Fehlzeit das Entgelt und die SV-Tage gekürzt werden.<br/>
      </Typography>    
    </Grid>

    <FehlzeitenBlock />

    <Grid container spacing={0} style={{marginTop:'30px'}} >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             Bsp.
          </Avatar> 
        </ListItemAvatar>
        <ListItemText primary="Beispiel 1"/>
      </ListItem>
      <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
          Ein Arbeitnehmer bekommt ein monatliches Gehalt in Höhe von 2200,- Euro.<br/>
          Seine Arbeitszeit ist von Montag bis Freitag, jeweils 7 Stunden.<br/>
          Im Monat Oktober hat der Arbeitnehmer Urlaub vom 19.10. bis 31.10.2020.<br/>
      </Typography>

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             Lö
          </Avatar> 
        </ListItemAvatar>
        <ListItemText primary="Lösung 1"/>
      </ListItem>
      <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
          Für die Fehlzeit "Urlaub" wird weder das Entgelt noch die SV-Tage gekürzt<br/>
          Somit ist sein Gehalt auf der Oktober-Abrechnung unverändert
      </Typography>


      <BruttoNeu
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage='21,75'
              Stunden='152,25'
              Faktor='2200,00'
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig='2200,00'
              Sozialverspflichtig='2200,00'
              Betrag ='2200,00'
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>  

          <SVBlockneu
              kvlfdtage='30' 
              kvlfdPflichtig='2200,00' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='30' 
              rvlfdPflichtig='2200,00' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='30' 
              avlfdPflichtig='2200,00' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='30' 
              pvlfdPflichtig='2200,00' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/>

          <Grid item xs={2}></Grid>
          
          <Grid item xs={8}>
             <Typography style={{textAlign:"left"}}>
                Auf die Anzeige der AN-Anteile und AG-Anteile wurde verzichtet, da bei diesen Aufgaben das Hauptaugenmerk auf den SV-Tagen und das sich ergebende SV-pflichtige Entgelt liegt.
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          
    </Grid>

    <Grid container spacing={0} style={{marginTop:'35px'}} >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             Bsp.
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Beispiel 2"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Ein Arbeitnehmer bekommt ein monatliches Gehalt in Höhe von 2200,- Euro.<br/>
              Seine Arbeitszeit ist von Montag bis Freitag, jeweils 8 Stunden.<br/>
              Im Monat Oktober befindet sich der Arbeitnehmer von 22.10.2020 bis 31.10.2020 in Krankengeldbezug. <br/>
          </Typography>


      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             Lö
          </Avatar> 
        </ListItemAvatar>
        <ListItemText primary="Lösung Bsp.2"/>
      </ListItem>
      <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
          Für die Fehlzeit "Krankengeldbezug (Krank ohne Lohn)" wird das Entgelt und die SV-Tage gekürzt.<br/>
          Die Entgeltkürzung erfolgt nach Arbeitstagen.<br/>
          Somit wird die Lohnart und der SV-Block wie folgt angezeigt:
      </Typography>
      <BruttoNeu
          Lohnart='1029'
          Intern='029'
          Bezeichnung='Gehalt'
          Tage='14,83'
          Stunden='118,64'
          Faktor='1500,00'
          Kennung='LFD'
          Prozent='0,00'
          Steuerpflichtig='1500,00'
          Sozialverspflichtig='1500,00'
          Betrag ='1500,00'
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
      />  
         
      <SVBlockneu
          kvlfdtage='21' 
          kvlfdPflichtig='1500,00' 
          kvlfdANanteil='--' 
          kvlfdAGanteil="--"
          kvegatage='0' 
          kvegaPflichtig='0,00' 
          kvegaANanteil='--' 
          kvegaAGanteil="--"
          rvlfdtage='21' 
          rvlfdPflichtig='1500,00' 
          rvlfdANanteil='--' 
          rvlfdAGanteil="--"
          rvegatage='0' 
          rvegaPflichtig='0,00' 
          rvegaANanteil='--' 
          rvegaAGanteil="--"
          avlfdtage='21' 
          avlfdPflichtig='1500,00' 
          avlfdANanteil='--' 
          avlfdAGanteil="--"
          avegatage='0' 
          avegaPflichtig='0,00' 
          avegaANanteil='--' 
          avegaAGanteil="--"
          pvlfdtage='21' 
          pvlfdPflichtig='1500,00' 
          pvlfdANanteil='--' 
          pvlfdAGanteil="--"
          pvegatage='0' 
          pvegaPflichtig='0,00' 
          pvegaANanteil='--' 
          pvegaAGanteil="--"
          setzeAntworten={setzeAntworten} 
          antworten={antworten} 
          fehlerliste={fehlerliste}
      />
    </Grid>
           
                  
    
    <Grid container spacing={0} style={{marginTop:'30px'}}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             A1
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Aufgabe 1"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Ein Arbeitnehmer bekommt ein monatliches Gehalt in Höhe von 2350,- Euro.<br/>
              Seine Arbeitszeit ist von Montag bis Freitag, jeweils 8 Stunden.<br/>
              Die Elternzeit des Arbeitnehmers beginnt am 18.10.2020 und endet am 17.11.2020. <br/> 
              Trage für den Monat Oktober das ermittelte Gehalt in den Bruttoblock und die ermittelten SV-Tage in den SV-Block ein.<br/>
          </Typography>
          <BruttoNeu
              tabellenKey="1" 
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage=''
              Stunden=''
              Faktor=''
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig=''
              Sozialverspflichtig=''
              Betrag =''
              
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/> 

          <SVBlockneu
              tabellenKey="1" 
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px'}}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             A2
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Aufgabe 2"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Eine Arbeitnehmerin bekommt ein monatliches Gehalt in Höhe von 1950,- Euro.<br/>
              Ihre Arbeitszeit ist von Montag bis Freitag, jeweils 6 Stunden.<br/>
              Im Monat Oktober pflegt sie ihr erkranktes Kind vom 07.10.2020 bis 08.10.2020. <br/> 
              Trage das ermittelte Gehalt in den Bruttoblock und die ermittelten SV-Tage in den SV-Block ein.<br/>
          </Typography>
          <BruttoNeu
              tabellenKey="2" 
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage=''
              Stunden=''
              Faktor=''
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig=''
              Sozialverspflichtig=''
              Betrag =''
              
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}/> 

          <SVBlockneu
              tabellenKey="2" 
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px'}}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             A3
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Aufgabe 3"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Eine Arbeitnehmerin bekommt ein monatliches Gehalt in Höhe von 2600,- Euro.<br/>
              Ihre Arbeitszeit ist von Montag bis Donnerstag, jeweils 8 Stunden.<br/>
              Der Mutterschutz beginnt am 19.10.2020<br/> 
              Trage das ermittelte Gehalt in den Bruttoblock und die ermittelten SV-Tage in den SV-Block ein.<br/>
          </Typography>
          <BruttoNeu
              tabellenKey="3" 
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage=''
              Stunden=''
              Faktor=''
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig=''
              Sozialverspflichtig=''
              Betrag =''
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

          <SVBlockneu
              tabellenKey="3" 
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px', marginBottom:'40px'}}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             A4
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Aufgabe 4"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Ein Arbeitnehmer bekommt ein monatliches Gehalt in Höhe von 2430,- Euro.<br/>
              Seine Arbeitszeit ist von Montag bis Freitag, jeweils 8 Stunden.<br/>
              Der Arbeitnehmer hat in der Zeit vom 11.10.2020 bis 31.10.2020 unbezahlten Urlaub.<br/> 
              Trage das ermittelte Gehalt in den Bruttoblock und die ermittelten SV-Tage in den SV-Block ein.<br/>
          </Typography>
          <BruttoNeu
              tabellenKey="4" 
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage=''
              Stunden=''
              Faktor=''
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig=''
              Sozialverspflichtig=''
              Betrag =''
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

          <SVBlockneu
              tabellenKey="4" 
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px', marginBottom:'40px'}}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
             A5
          </Avatar> 
        </ListItemAvatar>
          <ListItemText primary="Aufgabe 5"/>
      </ListItem>
          <Typography style={{paddingLeft:"70px",textAlign:"left"}}>
              Eine Arbeitnehmerin bekommt ein monatliches Gehalt in Höhe von 5000,- Euro.<br/>
              Ihre Arbeitszeit ist von Montag bis Freitag, jeweils 8 Stunden.<br/>
              Ihr Krankengeldbezug endet am 10.10.2020.<br/> 
              Trage das ermittelte Gehalt in den Bruttoblock und die ermittelten SV-Tage in den SV-Block ein.<br/>
          </Typography>
          <BruttoNeu
              tabellenKey="5" 
              Lohnart='1029'
              Intern='029'
              Bezeichnung='Gehalt'
              Tage=''
              Stunden=''
              Faktor=''
              Kennung='LFD'
              Prozent='0,00'
              Steuerpflichtig=''
              Sozialverspflichtig=''
              Betrag =''
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          /> 

          <SVBlockneu
              tabellenKey="5" 
              kvlfdtage='' 
              kvlfdPflichtig='' 
              kvlfdANanteil='--' 
              kvlfdAGanteil="--"
              kvegatage='0' 
              kvegaPflichtig='0,00' 
              kvegaANanteil='--' 
              kvegaAGanteil="--"
              rvlfdtage='' 
              rvlfdPflichtig='' 
              rvlfdANanteil='--' 
              rvlfdAGanteil="--"
              rvegatage='0' 
              rvegaPflichtig='0,00' 
              rvegaANanteil='--' 
              rvegaAGanteil="--"
              avlfdtage='' 
              avlfdPflichtig='' 
              avlfdANanteil='--' 
              avlfdAGanteil="--"
              avegatage='0' 
              avegaPflichtig='0,00' 
              avegaANanteil='--' 
              avegaAGanteil="--"
              pvlfdtage='' 
              pvlfdPflichtig='' 
              pvlfdANanteil='--' 
              pvlfdAGanteil="--"
              pvegatage='0' 
              pvegaPflichtig='0,00' 
              pvegaANanteil='--' 
              pvegaAGanteil="--"
              setzeAntworten={setzeAntworten} 
              antworten={antworten} 
              fehlerliste={fehlerliste}
          />
    </Grid>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>
  </div>

    )



  }

export default Fehlzeiten;