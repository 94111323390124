import React, {useState, useEffect, Fragment} from "react";
import { useNavigate } from "react-router-dom";
import apiRequest from '../../functions/api';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { TextField, Button, withStyles, MenuItem, Paper, Avatar, Typography, CardActions, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMessage } from "../../context/message-context";

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(9),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  form: {
    margin: theme.spacing(1),
    flex: 1
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    backgroundColor: theme.palette.secondary.light,
    marginRight: theme.spacing(1)
  },
  typo: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  card: {
    display: 'flex',
    alignItems: 'center'
  },
  sendButton: {
    marginLeft: 'auto'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
});

function FileUpload(props) {
  const {accessToken, client, location, classes} = props;
  const [paths, setPaths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [register, setRegister] = useState('');
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const {hideMessage, showErrorMessage, showSuccessMessage} = useMessage();

  useEffect(() => {
    setIsLoading(true);
    const payload={
      "mandant": client,
      "program": "CWARCH",
      "statement": "ABLAGEORTE",
    }
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    apiRequest('POST', 'daten', payload, headers, pathsCb, setRedirect);  
  // eslint-disable-next-line
  }, [])


  const pathsCb = (response) => {
    if (response.status === 200) {
      setPaths(response.data.paths);
      setIsLoading(false);
      hideMessage();
    } else {
      if (response) {
        showErrorMessage(response.data.message)
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const dataCb = (response) => {
    if (response.status === 200) {
      setIsSending(false);
      if (response.data.message === 'ok') {
        showSuccessMessage("Upload erfolgreich.");
      }
    } else {
      setIsSending(false);
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage("Es ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.");
      }
    }
  }


  const setRedirect = (path) => {
    hideMessage();
    navigate(path);
  }

  const onClickHandler = () => {
    if (isSending) return;
    if (!selectedFile) {
      showErrorMessage('Keine Datei ausgewählt');
      return
    }
    const data = new FormData();
    hideMessage()
    setIsSending(true);
    data.append('file', selectedFile);
    console.log(selectedFile.name);
    const headers={
        "Authorization": "Bearer "+accessToken,
        "Register": register,
        "FileName": selectedFile.name,
        "Keywords": "",
        "Mandant": client, // this.props.client,
        "Program": "CWARCH",
        "Statement": "DATEI_IN_DMS_ABLEGEN"
    }
    apiRequest('POST', 'upload', data, headers, dataCb, setRedirect);    
    
}

const getDataUrl = (img) => {
  // Create canvas
  return window.URL.createObjectURL(img);
}

const onChangeHandler= (event) => {
    setSelectedFile(event.target.files[0]);
  }

  if (isLoading) {
    return <LoadingComponent path={location}/>
  }
    const displayPaths = paths.map((dataObject, count) => {
      const name = dataObject.name;
      const key = dataObject.key;
      return(
        <MenuItem key={key} value={key}>
          {name}
        </MenuItem>
      );
    });
    return (
      <Fragment>
        <Typography variant="h4" color="primary" >Dateiupload</Typography> 
        <Paper className={classes.paper}>
        <form>
            <div className="form-group text-left">
            <TextField
                      id="folder"
                      label="Ordner"
                      select
                      fullWidth
                      value={register}
                      onChange={(e) => {setRegister(e.target.value)}}
                      variant="outlined"
                    >
                      {displayPaths}
                    </TextField>
            </div>
            <div className={classes.row}>
            <input
              color="primary"
              type="file"
              id="file-input"
              onChange={onChangeHandler}
              style={{display: 'none'}}
            />
            <label htmlFor="file-input" className="mr-2">
              <Button
                variant="contained"
                component="span"
                color="primary"
                label="Dateiupload"
              >
                Datei auswählen
              </Button>
            </label>           
            {selectedFile?<span className={classes.row}><Avatar className={classes.avatar} alt={selectedFile.name} src={getDataUrl(selectedFile)}/><Typography className={classes.typo}>{selectedFile.name}</Typography></span>:<Typography color="textSecondary" className={classes.typo}>Keine Datei</Typography>}
          </div>
          </form>
          <CardActions disableSpacing>
            <Button className={classes.sendButton} color="primary" onClick={onClickHandler} endIcon={isSending?<CircularProgress />:null}>Senden</Button>
          </CardActions>
        </Paper>
        </Fragment>
    );
}

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileUpload);
