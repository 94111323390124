import React, {useState, useCallback} from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Grid,Box, TextField} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { Player, ControlBar } from 'video-react';
const videoUmlage = '/media/Chat-Tutorial_U1_Verfahren.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import { TabelleUmlage1} from './TabelleU';
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';

function Umlage1(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [reactnummer]=useState('013');
  const [merkmal]=useState("Umlage1");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    console.log(values)
    setAntworten(values);
    forceUpdate();   
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;    
      }
    );
    setFehlerliste(fehlerzwis);
  };

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }
  
  const kurseCb = (response) => {
    //   console.log(response.data.message);
   
       if (response.status === 200) {
           setTestabgabe(true);
           setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
           setAbgabezeit(response.data.abgabezeit);
           fehlerAusLesen(response.data.fehler);
           setAnzFehler(response.data.anzfehler);
           setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
            showErrorMessage(response.data.message);
         } else {
            showErrorMessage('Keine Internetverbindung');
         }
       }
     }


  return(
   <Box p={2}>
      <Briefkopf  
          ueberschrift1="Umlage 1" 
          ueberschrift2="Zuordnung von Arbeitnehmern zur Feststellung, ob der Betrieb Umlage 1 pflichtig ist? " 
      />

    <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
      <Grid item xs={12} >
         <Typography variant="h2" color="primary" style={{fontWeight:"bold"}}>
           
          </Typography> 

          <Typography variant="h3"  color="primary">
              Zuordnung von Arbeitnehmern zur Feststellung, <br/>
              ob der Betrieb "Umlage 1" pflichtig ist?<br/>
          </Typography>

      </Grid>


    </Grid>
    

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
      <Grid Item xs={8}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              H
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
              <h4>
                  Was ist die Umlage (U1)? <br/>
                  Die Umlage U1 ist eine Pflichtversicherung für Arbeitgeber,  <br/>
                  die nicht mehr als 30 Mitarbeiter beschäftigen. Details der Entgeltfortzahlungsversicherung sind im Aufwendungsausgleichsgesetz (AAG) geregelt. <br/><br/>
                  Arbeitgeber, die an der Entgeltfortzahlungsversicherung (Umlage U1) teilnehmen müssen, 
                  zahlen eine monatliche Umlage an die jeweilige gesetzliche Krankenkasse des Beschäftigten. <br/>
                  Im Gegenzug erstattet sie ihnen bei Krankheit ihres Beschäftigten einen prozentualen Anteil des fortgezahlten Arbeitsentgelts.
              </h4>
          </ListItemText>    
        </ListItem>
      </Grid>
      <Grid item xs={10} style={{}}>
          <Player
              poster={POSTER}
              src={videoUmlage}
              >
              <ControlBar autoHide={false} />
          </Player>
      </Grid>

    </Grid>

    <Grid container spacing={1} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
                  <h4>Aufgabe:</h4>
                  Führe die Zählung der Beschäftigten gem. den Kriterien für die Beurteilung der Umlage-1-Pflicht durch und bestimme den jeweiligen Anrechnungsfaktor.
              </ListItemText>  
          </ListItem>
    </Grid>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='35' tabellenKey="1" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='20' tabellenKey="2" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Minijobber" arbz='3' tabellenKey="3" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Auszubildender" arbz='35' tabellenKey="4" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='35' tabellenKey="5" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Praktikant" arbz='35' tabellenKey="6" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Aushilfe" arbz='4' tabellenKey="7" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='15' tabellenKey="8" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='25' tabellenKey="9" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Minijobber" arbz='3' tabellenKey="10" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Werkstudenten" arbz='18' tabellenKey="11" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Bezieher von Vorruhestandsgeld" arbz='' tabellenKey="12" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='40' tabellenKey="13" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Auszubildender" arbz='35' tabellenKey="14" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='28' tabellenKey="15" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='18' tabellenKey="16" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Minijobber" arbz='8' tabellenKey="17" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Auszubildender" arbz='35' tabellenKey="18" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='40' tabellenKey="19" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Praktikant" arbz='20' tabellenKey="20" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Aushilfe" arbz='5' tabellenKey="21" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='16' tabellenKey="22" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Schwerbeh. Arbeitnehmer" arbz='32' tabellenKey="23" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Minijobber" arbz='7' tabellenKey="24" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='35' tabellenKey="25" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Volontär" arbz='35' tabellenKey="26" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Aushilfe" arbz='5' tabellenKey="27" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='27' tabellenKey="28" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='30' tabellenKey="29" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Minijobber" arbz='3' tabellenKey="30" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Werkstudenten" arbz='18' tabellenKey="31" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>
    <TabelleUmlage1 typ="Arbeitnehmer" arbz='40' tabellenKey="32" setzeAntworten={setzeAntworten} antworten={antworten} fehlerliste={fehlerliste}/>



    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={'center'} alignItems={'center'}>

        <Grid item xs={4} >
        </Grid>

        <Grid item xs={4} style={{textAlign:"center"}} >
          <Typography>
            Anzahl Beschäftigter gem. Umlage 1 = 
          </Typography>

        </Grid>
  
        <Grid item xs={4} style={{borderBottom:"solid",borderRight:"solid",borderLeft:"solid",borderTop:"solid"}}>
          <TextField
            id='summe' 
            fullWidth
            value={antworten['summe']||''}
            error={fehlerliste["summe"]}
  //        style={{display:"flex"}}
            onChange={(event) => setzeAntworten('summe',event.target.value)}
  //        variant="filled"
  //        margin="normal"
            inputProps={{style:{textAlign:"center"}}}       

            InputLabelProps={{
                shrink: true,
            }}        
          >
          </TextField>             
        </Grid>
    </Grid>

    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

   </Box>
    )
  }   


export default Umlage1;
