import React from 'react';
import {
  HashRouter as Router,
} from "react-router-dom";
import darktheme from "../theme/darkTheme";
import lighttheme from "../theme/lightTheme";
import { MuiThemeProvider, CssBaseline, useMediaQuery } from "@material-ui/core";
import GlobalStyles from "../GlobalStyles";
import { MessageProvider, MessageViewer } from '../context/message-context';
import { AuthProvider } from './auth-context';
import { NotificationProvider } from './notification-context';
import { useLocalStorage } from '../utils/hooks';
import { LOCAL_STORAGE_KEY } from '../constants/localStorage';
import {GlobalStateProvider} from './global-state-context';

function AppProviders (props) {
  const [theme, setTheme] = useLocalStorage(LOCAL_STORAGE_KEY.theme);

  /*
  useEffect(() => {
    window.addEventListener('pagehide', (e) => console.log('hide', e));
    window.addEventListener('pageshow', (e) => console.log('show', e));
    window.addEventListener('online', (e) => console.log('online', e));
    window.addEventListener('offline', (e) => console.log('offline', e));
    return () => {
      window.removeEventListener('pagehide');
      window.removeEventListener('pageshow');
      window.removeEventListener('online');
      window.removeEventListener('offline');
      }
  }, []);
  */

  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
  
  const getTheme = () => {
  if (theme === '') setTheme('system');
  if (theme === 'dark') return (darktheme);
  if (theme === 'system' && isDarkModeEnabled) return (darktheme);
  if (theme === '' && isDarkModeEnabled) return (darktheme);
  return (lighttheme);
  }


  return(
    <Router>
      <MuiThemeProvider theme={getTheme()}>
        <AuthProvider>
          <NotificationProvider>
            <CssBaseline />
            <GlobalStyles />
            <GlobalStateProvider>
              <MessageProvider>
                {props.children}
                <MessageViewer/>
              </MessageProvider>
            </GlobalStateProvider>
          </NotificationProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </Router>
  )    
}

export default AppProviders;