import React, { useEffect } from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';



function BruttoNeu2 (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten,text, Lohnart, Intern,Bezeichnung, Tage, Stunden, Faktor,Kennung, Prozent, Steuerpflichtig, Sozialverspflichtig, Betrag,anzReihen} = props;


  let loaBlockiert=[""]
  let internBlockiert=[""]
  let bezeichnungBlockiert=[""]
  let tageBlockiert=[""]
  let stundenBlockiert=[""]
  let faktorBlockiert=[""]
  let kennnungBlockiert=[""]
  let prozentBlockiert=[""]
  let steuerpflichtigBlockiert=[""]
  let sozialverspflichtigBlockiert=[""]
  let betragBlockiert=[""]

for (let B = 0; B < anzReihen; B++) {

  if (Lohnart[B] !== '') {
      antworten["loa"+tabellenKey+B]=Lohnart[B];
       loaBlockiert[B]=true;
  }
  if (Intern[B] !== '') {
    antworten["intern"+tabellenKey+B]=Intern[B];
    internBlockiert[B]=true;
}
  if (Bezeichnung[B] !== '') {
    antworten["bez"+tabellenKey+B]=Bezeichnung[B];
     bezeichnungBlockiert[B]=true;
  }
  if (Tage[B] !== '') {
    antworten["tag"+tabellenKey+B]=Tage[B];
     tageBlockiert[B]=true;
  }
  if (Stunden[B] !== '') {
    antworten["stunde"+tabellenKey+B]=Stunden[B];
    stundenBlockiert[B]=true;
  }
  if (Faktor[B] !== '') {
    antworten["fak"+tabellenKey+B]=Faktor[B];
    faktorBlockiert[B]=true;
  }
  if (Kennung[B] !== '') {
    antworten["kenn"+tabellenKey+B]=Kennung[B];
    kennnungBlockiert[B]=true;
  }
    if (Prozent[B] !== '') {
    antworten["pro"+tabellenKey+B]=Prozent[B];
    prozentBlockiert[B]=true;
    }

    if (Steuerpflichtig[B] !== '') {
        antworten["steuerpfl"+tabellenKey+B]=Steuerpflichtig[B];
        steuerpflichtigBlockiert[B]=true;
    }
    if (Sozialverspflichtig[B]  !== '') {
      antworten["sozial"+tabellenKey+B]=Sozialverspflichtig[B];
      sozialverspflichtigBlockiert[B]=true;
    }
    if (Betrag[B] !== '') {
      antworten["betr"+tabellenKey+B]=Betrag[B];
      betragBlockiert[B]=true;
    }
}    


  var svFarbe="#71ff00";
      let reihen=[];
    for (let i = 0; i < anzReihen; i++) {
        reihen.push("A")
    }
    const reihenEinfügen  = reihen.map((value, i) => {
            return (    
                       <TableRow >
                            <TableCell key="Lohnart" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={loaBlockiert[i]}
                                    placeholder={"Eingabe"}
                                    error={fehlerliste["loa"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["loa"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["loa"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('loa'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Intern" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={internBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["intern"+tabellenKey+i]}     
                                    style={{display:"inline-block", marginTop:"7px",backgroundColor:(fehlerliste["intern"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["intern"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('intern'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Bezeichnung" >
                                <Eingabe 
                                    disabled={bezeichnungBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["bez"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["bez"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["bez"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('bez'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Tage" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={tageBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["tag"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["tag"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["tag"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('tag'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Stunden" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={stundenBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["stunde"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["stunde"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["stunde"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('stunde'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Faktor" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={faktorBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["fak"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["fak"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["fak"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('fak'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Kennung" style={{textAlign:"center"}}>
                                <Eingabe 
                                    dropd={true}
                                    kenndrops={["LFD","EGA","MKJ",]}
                                    disabled={kennnungBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["kenn"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kenn"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kenn"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('kenn'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Prozent" style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={prozentBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["pro"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["pro"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["pro"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('pro'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Steuerpflichtig" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={steuerpflichtigBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["steuerpfl"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["steuerpfl"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["steuerpfl"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('steuerpfl'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Sozialverspflichtig" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={sozialverspflichtigBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["sozial"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["sozial"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["sozial"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('sozial'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                            <TableCell key="Betrag" style={{textAlign:"right"}}>
                                <Eingabe 
                                    disabled={betragBlockiert[i]}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["betr"+tabellenKey+i]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["betr"+tabellenKey+i]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={antworten["betr"+tabellenKey+i]||''}
                                    onChange={(event) => setzeAntworten('betr'+tabellenKey+i,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>
        );})

return(
    <Grid container spacing={0} style={{marginTop:'10px'}} >
        
        <Grid item xs={12}>
            <Typography>
                {text}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>  
                            <TableCell style={{textAlign:"center"}}>Lohnart</TableCell>
                            <TableCell style={{textAlign:"center"}}>Intern</TableCell>
                            <TableCell style={{textAlign:"left"}}>Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"right"}}>Tage</TableCell>
                            <TableCell style={{textAlign:"right"}}>Stunden</TableCell>
                            <TableCell style={{textAlign:"right"}}>Faktor</TableCell>
                            <TableCell style={{textAlign:"center"}}>Kennung</TableCell>
                            <TableCell style={{textAlign:"center"}}>%</TableCell>
                            <TableCell style={{textAlign:"right"}}>Steuerpflichtig</TableCell>
                            <TableCell style={{textAlign:"right"}}>Sozialvers.<br/>pflichtig</TableCell>
                            <TableCell style={{textAlign:"right"}}>Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reihenEinfügen}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)
}

export {BruttoNeu2};