import React, { useState, useEffect } from "react";
import BEA_Component from "./BEA_COMPONENT";




const BEA1 = (props) => {

    const { accessToken, hsutkey } = props;
    const [reactnummer] = useState("845")
    const [merkmal] = useState("BEA1")
    
    console.log(props)
    return (
        <div>
            <BEA_Component reactNummer={reactnummer} merkMal={merkmal} token={accessToken} hsut={hsutkey}  ></BEA_Component>
        </div>
    );
}

export default BEA1