import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';




function ErmittlungMutterschutzFrist (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, von, bis} = props;
  const [vonblockiert, setVonblockiert]=useState(false);
  const [bisblockiert, setBisblockiert] = useState(false);


  useEffect(()=>{
    if (von !== '') {
        setzeAntworten("von"+tabellenKey,von);
        setVonblockiert(true);
      }
  
      if (bis !== '') {
        setzeAntworten("bis"+tabellenKey,bis);
        setBisblockiert(true);
      }
  },[])

return(
    <div>
    <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={8}>
        <TableContainer>
          <Table>
              <TableHead>
                  <TableRow  >
                      <TableCell style={{textAlign:"center"}}>Beginn</TableCell>
                      <TableCell style={{textAlign:"center"}}>Ende</TableCell>
                  </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell style={{textAlign:"center"}}>
                      <Eingabe 
                          datum="true"
                          disabled={vonblockiert}
                          placeholder="Eintrag" 
                          error={fehlerliste["von"+tabellenKey]}     
                          style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["von"+tabellenKey]===true)?"red":""}}
                          inputProps={{style:{textAlign:"center"}}}
                          value={antworten["von"+tabellenKey]||''}
                          onChange={(event) => setzeAntworten('von'+tabellenKey,event.target.value)}
                          >
                      </Eingabe>
                  </TableCell>
                  <TableCell style={{textAlign:"center"}}>
                      <Eingabe 
                          datum="true"
                          disabled={bisblockiert}
                          placeholder="Eintrag" 
                          error={fehlerliste["bis"+tabellenKey]}     
                          style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["bis"+tabellenKey]===true)?"red":""}}
                          inputProps={{style:{textAlign:"center"}}}
                          value={antworten["bis"+tabellenKey]||''}
                          onChange={(event) => setzeAntworten('bis'+tabellenKey,event.target.value)}
                          >
                      </Eingabe>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    </div>
 
  )
}

export {ErmittlungMutterschutzFrist};