import axios from 'axios';
import {API_BASE_URL} from '../constants/apiConstants';
import * as auth from '../auth-provider';

async function apiRequest(method, path, payload, headers, callback) {
  var retries = 0;
  axios.defaults.withCredentials = true;
  while (retries < 2) {
    let success = false;
    retries += 1;
    if (method === 'POST') {
      await axios.post(API_BASE_URL+path, payload, {withCredentials: true, headers: headers})
      .then(async function (response) {
          success = true;
          callback(response);
      })
      .catch(async function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log('Token abgelaufen');
            await auth.logout();
            // refresh the page for them
            window.location.reload();
            success = true;
          }
          success = true;
          callback(error.response);
        } else {
          console.log(error);
          //callback({status: 500, data: {message: 'Server Error'}});
        }
      });
    } else if (method === 'GET') {
      await axios.get(API_BASE_URL+path, {withCredentials: true, headers: headers})
      .then(async function (response) {
          success = true;
          callback(response);
      })
      .catch(async function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log('Token abgelaufen');
            await auth.logout();
            // refresh the page for them
            window.location.reload();
            success = true;
          }
          success = true;
          callback(error.response);
        } else {
          console.log(error);
          //callback({status: 500, data: {message: 'Server Error'}});
        }
      });
    } else {
      console.log('Nothing');
      break;
    }
    if (success) {break;}
    await sleep(1000);
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default apiRequest;