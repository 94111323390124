import React, {useState, useCallback} from 'react';
import {Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Player, ControlBar } from 'video-react';
const VIDEOBLP = '/media/3_Chat-Tutorial_Firmenwagen_Berechnung_BLP.m4v';
const POSTER = '/media/Grafik_Filmrolle.png';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {BLP_Tabelle} from '../../components/Rahmendaten/BLP_Tabelle';
import {PKW_Rechnung} from './../Rahmendaten/PKW_Rechnung'
import {Tabelle2spaltig} from './../Rahmendaten/Tabelle2spaltig'


function FirmenwagenBLP(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [ anzFehler,setAnzFehler]=useState(0);
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [reactnummer]=useState('034');
  const [merkmal]=useState("FirmenwagenBLP");



  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="Firmenwagen Berechnung des Bruttolistenpreises" 
            ueberschrift2="In diesem Kurs lernst Du, wie der Bruttolistenpreis eines Firmenwagens berechnet wird." 
        />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText variant="h6" primary="Einstieg"/>
            </ListItem>
            <Player
                  poster={POSTER}
                  src={VIDEOBLP}
                  >
                  <ControlBar autoHide={false} />
            </Player>
          
            <ListItem style={{marginTop:"40px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  H1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  Der <b>Bruttolistenpreis</b> entspricht in den meisten Fällen <b>nicht dem Rechnungspreis</b>. <br/>
                  Oftmals werden beim Kauf eines Fahrzeugs Rabatte gewährt, die zum Beispiel bei der Ermittlung des Bruttolistenpreises nicht mit einbezogen werden dürfen.<br/>
                  Sofern der Bruttolistenpreis nicht bereits auf der Fahrzeugrechnung explizit ausgewiesen ist, muss er manuell ermittelt werden.<br/>
                  Die folgende Tabelle zeigt Dir, welche der gängisten Ausstattungs- und Auslieferungsmerkmale zum Bruttolistenpreis hinzugerechnet  
                  und welche nicht berücksichtigt werden müssen.
              </ListItemText> 
            </ListItem>
          </Grid>
        </Grid>

        <BLP_Tabelle/>
        <PKW_Rechnung
          tabellenKey='1' 
          kdnr="K039536"
          fgnr="298480"
          rgnr="R20214911"
          datum="07.07.2021"
          setzeAntworten={setzeAntworten} 
          antworten={antworten} fehlerliste={fehlerliste}      
        />
        
        <Grid container spacing={0} style={{marginTop:'25px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} >
            <ListItem >
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText variant="h6">
                <strong>Aufgabe <br/> </strong>
                Ermittle den Bruttolistenpreis aus der dargestellten Rechnung. <br/>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={8} >
            <Tabelle2spaltig 
                tabellenKey="1" 
                anzReihen="2"
                ueberschrift="Ermittlung Bruttolistenpreis"
                uebschr_spalte1="Bezeichnung"
                spalte1Platz="center"
                uebschr_spalte2="Euro"
                spalte2Platz="right"
                spalten1={["Bruttolistenpreis, abgerundet auf volle hundert Euro"]}
                spalten2={[""]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>

        </Grid>

        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

      </div>
    )
  }
  

export default FirmenwagenBLP;
