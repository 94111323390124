import React, { useState, useCallback, useEffect } from "react";
import { Avatar, Typography, Button, TextField, ListItem, Select, ListItemAvatar, ListItemText, Checkbox, Box, FormControlLabel, Container, MenuItem, DialogTitle, DialogContent, Dialog } from "@material-ui/core";
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import apiRequest from "../../functions/api";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useMessage } from "../../context/message-context";
import { Kursabgeben } from "../Rahmendaten/Kursabgeben";
import { Briefkopf } from "../Rahmendaten/Briefkopf";
import axios from 'axios';
import { API_BASE_URL } from '../../constants/apiConstants';
import openFile from "../shared/functions/download";

function VerdienstbescheinigungArbeitgebers({ reactNummer, merkMal, token, hsut, von, bis  }) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [accessToken] = useState(token);
  const [hsutkey] = useState(hsut);
  const navigate = useNavigate();
  const [antworten, setAntworten] = useState({});
  const [testabgabe, setTestabgabe] = useState(false);
  const [abgabedat, setAbgabedat] = useState("");
  const [abgabezeit, setAbgabezeit] = useState("");
  const [ezJaChecked, setEzJaChecked] = useState(false);
  const [ezNeinChecked, setEzNeinChecked] = useState(false);
  const [brGruppe, setBrGruppe] = useState("");
  const [fehlerliste, setFehlerliste] = useState([]);
  const { showErrorMessage } = useMessage();
  const [anzFehler, setAnzFehler] = useState("0");
  const [bemerkungen, setBemerkungen] = useState("");
  const [reactnummer] = useState(reactNummer);
  const [merkmal] = useState(merkMal);
  const [open, setOpen] = useState(false);
  const [kurskennung] = useState("");
  const [lohndaten, setLohndaten] = useState([]);


  const BuchDateneingabe = '/media/Buch_Dateneingabe.png';



  const setzeAntworten = (key, value) => {
    if (key === "EZJA" || key === "EZNEIN") {
      let values = Object.assign(antworten);
      if (key === "EZJA") {
        setEzJaChecked(!ezJaChecked);
        setEzNeinChecked(false);
        values[key] = !ezJaChecked;
        values["EZNEIN"] = false;
      }
      if (key === "EZNEIN") {
        setEzJaChecked(false);
        setEzNeinChecked(!ezNeinChecked);
        values[key] = !ezNeinChecked;
        values["EZJA"] = false;
      }
      setAntworten(values);
      forceUpdate();
      return
    }

    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  };

  const setRedirect = (path) => {
    navigate(path);
  };

  const fehlerAusLesen = (fehler) => {
    let array = fehler.split(";");
    let fehlerzwis = [];
    array.map(
      // eslint-disable-next-line
      (objekt) => {
        fehlerzwis[objekt] = true;
      }
    );
    setFehlerliste(fehlerzwis);
  };

  const antwortenSenden = () => {
    let values = Object.assign(antworten);
    values["UEBERGANG"] = "nein";
    values["MEHRFACHBESCHAEFTIGT"] = "nein";
    values["GERINGVERDIENER"] = "nein";
    values["FAMILIENSTAND"] = "ledig";
    values["BUNDESLAND"] = "08 BW";
    values["RENTENSTATUS"] = "nein";
    values["BETRGRUPPE"] = brGruppe;

    setAntworten(values);
    forceUpdate();
    console.log(antworten)
    const headers = {
      Authorization: "Bearer " + accessToken,
    };
    const payload = {
      kennung: "kursabgabe",
      merkmal: merkmal,
      daten: antworten,
      reactnummer: reactnummer,
      hsutkey: hsutkey,
    };
    apiRequest("POST", "kursabgabe", payload, headers, kurseCb, setRedirect);
  };

  const kurseCb = (response) => {
    console.log(response)
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(
        response.data.abgabedat.slice(6, 8) +
        "." +
        response.data.abgabedat.slice(4, 6) +
        "." +
        response.data.abgabedat.slice(0, 4)
      );
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage("Keine Internetverbindung");
      }
    }
  };

  const gezahltesEntgeld = () => {
    const numbers = [1, 2, 3, 4, 5, 6]

    const tmpReturn = numbers.map((id, i) => {
      return (
        <div style={{ width: "100%", borderBottom: "solid", float: "left" }}>
          <Typography style={{ borderRight: "solid", borderLeft: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>{id}.</Typography>

          <TextField id={"AUSZAHLUNGMONAT" + id} placeholder="MM/JJJJ" label="" error={fehlerliste["AUSZAHLUNGMONAT" + id]} style={{ backgroundColor: fehlerliste["AUSZAHLUNGMONAT" + id] === true ? "red" : "", width: "23.75%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "center" } }} onChange={(event) => setzeAntworten("AUSZAHLUNGMONAT" + id, event.target.value)}>
            {" "}
          </TextField>

          <TextField id={"BRUTTOARBEITSENTGELD" + id} placeholder="0,00" label="" error={fehlerliste["BRUTTOARBEITSENTGELD" + id]} style={{ backgroundColor: fehlerliste["BRUTTOARBEITSENTGELD" + id] === true ? "red" : "", width: "18.75%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("BRUTTOARBEITSENTGELD" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>

          <TextField id={"LEISTUNGEN" + id} label="" placeholder="0,00" error={fehlerliste["LEISTUNGEN" + id]} style={{ backgroundColor: fehlerliste["LEISTUNGEN" + id] === true ? "red" : "", width: "18.75%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("LEISTUNGEN" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>

          <TextField id={"BEITRAEGE" + id} label="" placeholder="0,00" error={fehlerliste["BEITRAEGE" + id]} style={{ backgroundColor: fehlerliste["BEITRAEGE" + id] === true ? "red" : "", width: "18.75%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("BEITRAEGE" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", width: "5%", float: "left", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>
        </div>)
    }
    )

    return tmpReturn
  }


  function SelectFunction( id, number ) {
    const [selected, setSelected] = useState("");

    const handleChange = (event) => {
      const value = event.target.value;
      setzeAntworten(id+number, event.target.value)
      setSelected(value);
    };
  
    return (
      <div>
        <Select style={{backgroundColor: fehlerliste[id+ number] === true ? "red" : "", height: "32px", width: "25%", display: "flex", float: "left", borderLeft: "solid", borderRight: "solid" }} value={selected} onChange={handleChange}>
        <MenuItem value={""}>Leer</MenuItem>
          <MenuItem value={"Sonderzahlung"}>Sonderzahlung</MenuItem>
          <MenuItem value={"Energiepauschale"}>Energiepauschale</MenuItem>
          <MenuItem value={"Urlaubsgeld"}>Urlaubsgeld</MenuItem>
          <MenuItem value={"Weihnachtsgeld"}>Weihnachtsgeld</MenuItem>
          <MenuItem value={"13. Gehalt"}>13. Gehalt</MenuItem>
        </Select>
      </div>
    );
  }


  const nichtGezahltesEntgeld = () => {
    const numbers = [1, 2, 3]

    const tmpReturn = numbers.map((id, i) => {
      return (
        <div style={{ width: "100%", borderBottom: "solid", float: "left" }}>


          {SelectFunction("ARTEINMALZAHLUNG", id)}
          <TextField id={"AUSZAHLUNGMONATNICHTLAUFEND" + id} placeholder="MM/JJJJ" label="" error={fehlerliste["AUSZAHLUNGMONATNICHTLAUFEND" + id]} style={{ backgroundColor: fehlerliste["AUSZAHLUNGMONATNICHTLAUFEND" + id] === true ? "red" : "", width: "25%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "center" } }} onChange={(event) => setzeAntworten("AUSZAHLUNGMONATNICHTLAUFEND" + id, event.target.value)}>
            {" "}
          </TextField>

          <TextField id={"BRUTTOBETRAG" + id} label="" placeholder="0,00" error={fehlerliste["BRUTTOBETRAG" + id]} style={{ backgroundColor: fehlerliste["BRUTTOBETRAG" + id] === true ? "red" : "", width: "20%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("BRUTTOBETRAG" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>

          <TextField id={"EINMALBEITRAEGE" + id} placeholder="0,00" label="" error={fehlerliste["EINMALBEITRAEGE" + id]} style={{ backgroundColor: fehlerliste["EINMALBEITRAEGE" + id] === true ? "red" : "", width: "20%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("EINMALBEITRAEGE" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", width: "5%", float: "left", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>





        </div>)
    }
    )

    return tmpReturn
  }

  const laufendeLeistungen = () => {
    const numbers = [1, 2, 3, 4, 5, 6]

    const tmpReturn = numbers.map((id, i) => {
      return (
        <div style={{ width: "100%", borderBottom: "solid", float: "left" }}>
          <Typography style={{ borderRight: "solid", borderLeft: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>{id}.</Typography>

          <TextField id={"LEISTUNGSART" + id} label="" error={fehlerliste["LEISTUNGSART" + id]} style={{ backgroundColor: fehlerliste["LEISTUNGSART" + id] === true ? "red" : "", width: "31.667%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "center" } }} onChange={(event) => setzeAntworten("LEISTUNGSART" + id, event.target.value)}>
            {" "}
          </TextField>

          <TextField id={"LEISTUNGMONAT" + id} placeholder="MM/JJJ" label="" error={fehlerliste["LEISTUNGMONAT" + id]} style={{ backgroundColor: fehlerliste["LEISTUNGMONAT" + id] === true ? "red" : "", width: "31.667%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "center" } }} onChange={(event) => setzeAntworten("LEISTUNGMONAT" + id, event.target.value)}>
            {" "}
          </TextField>

          <TextField id={"ZAHLBETRAG" + id} label="" placeholder="0,00" error={fehlerliste["ZAHLBETRAG" + id]} style={{ backgroundColor: fehlerliste["ZAHLBETRAG" + id] === true ? "red" : "", width: "26.667%", display: "flex", float: "left", borderRight: "solid" }} inputProps={{ style: { textAlign: "right" } }} onChange={(event) => setzeAntworten("ZAHLBETRAG" + id, event.target.value)}>
            {" "}
          </TextField>
          <Typography style={{ borderRight: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            Euro
          </Typography>

        </div>)
    }
    )

    return tmpReturn

  }


  useEffect(() => {
    rahmendaten_holen();
  }, [])

  const rahmendaten_holen = () => {
    forceUpdate();
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursdaten",
      "reactnummer": reactnummer,
      "kurskennung": kurskennung,
      "hsutkey": hsutkey
    }
    apiRequest('POST', 'kursdaten', payload, headers, kursinhalt, setRedirect);
  }

  const kursinhalt = (response) => {
    console.log(response)
    if (response.status === 200) {
      if (response.data.links) {
        setLinks(response.data.links)
      }
      if (response.data.lohndaten) {
        setLohndaten(response.data.lohndaten);

      }
    }
  }

  const DokumentOeffnen = (docnr, docbez, docendung) => {
    const payload = {
      "kennung": "kursdaten",
      "kurskennung": "download_lohndaten",
      "reactnummer": reactnummer,
      "docnr": docnr,
      "docname": docbez,
      "hsutkey": hsutkey
    }
    const headers = {
      "Authorization": "Bearer " + accessToken,
      "Accept": "application/pdf"
    }
    axios.post(API_BASE_URL + 'kursdaten', payload, { headers: headers, responseType: 'blob' })
      .then((response) => {
        if (docendung === undefined) { var fileName = docbez } else { var fileName = docbez + docendung };
        var contentFile = response.data;
        const mimeType = response.headers['content-type'] || 'application/pdf'
        openFile(fileName, contentFile, mimeType);
      })
      .catch(function (error) {
        showErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie den download erneut.');
      })
  }


  return (
    <div>
      <Briefkopf
        ueberschrift1="Die Verdienstbescheinigung des Arbeitgebers"
        ueberschrift2=" In diesem Bereich übst du eine Verdienstbescheinigung auszufüllen"
      />

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#1fbba6" }}>
            1
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Bitte verwende dieses Dokument zum Ausfüllen der Verdienstbescheinigung " />
      </ListItem>


      <Grid container style={{}}>
        <Grid item xs={12} style={{ marginTop: "15px", marginBottom: "15px" }}>
          {
            lohndaten?.map((inhalt, index) => {
              return (

                <Card sx={{ display: 'flex', marginTop: '10px', alignItems: "center" }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 200 }}
                    image={BuchDateneingabe}
                    alt="Handbücher"
                  />

                  <Box sx={{ width: 300, display: 'flex', flexDirection: 'column', }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" variant="h5" >
                        {inhalt.bez}
                      </Typography>
                    </CardContent>

                  </Box>

                  <Button
                    color="primary"
                    variant="text"
                    key={index}
                    target="_blank"
                    alignItems="left"
                    onClick={() => DokumentOeffnen(inhalt.key, inhalt.bez, inhalt.endung)}
                  >
                    Zu erfassende Lohndaten in einem neuen Tab anzeigen
                  </Button>

                </Card>
              )
            })
          }
        </Grid>
      </Grid>

      <br /><br />
      <ListItem style={{ paddingBottom: "30px" }}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#1fbba6" }}>
            2
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Fülle nun die Verdienstbescheinigung aus. Achte dabei auf das vorgegebene Format (wenn vorhanden) " />
      </ListItem>
      <Grid container style={{ border: "solid", padding: "20px" }}>
        <Container maxWidth={false}>
          <Grid style={{ float: "left", width: "40%", border: "solid" }} item xs={12}>
            <TextField id="NAMEKINDERGELD" label="" error={fehlerliste["NAMEKINDERGELD"]} placeholder="Name und Vorname der kindergeldbeziehenden Person" style={{ backgroundColor: fehlerliste["NAMEKINDERGELD"] === true ? "red" : "", borderBottom: "solid", textAlign: "left", width: "100%", display: "flex" }} onChange={(event) => setzeAntworten("NAMEKINDERGELD", event.target.value)}>
              {" "}
            </TextField>
            <TextField id="KINDERGELDNR" label="" error={fehlerliste["KINDERGELDNR"]} placeholder="Kindergeld-Nr." style={{ backgroundColor: fehlerliste["KINDERGELDNR"] === true ? "red" : "", textAlign: "left", width: "100%", display: "flex" }} onChange={(event) => setzeAntworten("KINDERGELDNR", event.target.value)}>
              {" "}
            </TextField>
          </Grid>
          <Grid style={{ display: "flex", float: "right", flexDirection: "col", justifyContent: "flex-end", width: "60%" }} item xs={12}>
            <img src="/media/Familienkasse.jpg" style={{ height: "60px" }} alt="ectool" loading="lazy" />
            <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "1.2em", height: "64px", lineHeight: "64px", }}>Familienkasse</Typography>
          </Grid>
        </Container>


        <Grid container spacing={6} style={{ marginTop: '30px' }} justifyContent={"center"} alignItems={"center"}>

          <Typography style={{ fontWeight: "bold", float: "left", width: "80%", fontSize: "1.6em", height: "32px", lineHeight: "32px", }}>
            Verdienstbescheinigung des Arbeitgebers
          </Typography>

          <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
            <Checkbox
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              style={{ marginLeft: "5px", border: (fehlerliste["Ost"] === true) ? "solid red" : "" }}
              checked={true}
            />Bitte Zutreffendes ankreuzen
          </Typography>


          <Typography style={{ fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
            zur Vorlage bei der Familienkasse für Zwecke des Kinderzuschlages (§ 6a Bundeskindergesetz)
          </Typography>

          <Grid item xs={12} style={{ width: "100%", borderTop: "", borderBottom: "" }}>

            <div style={{ float: "left", width: "100%" }}>
              <Typography style={{ fontWeight: "bold", float: "left", width: "25%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                für die Monate {von} MM/JJJJ 
              </Typography>
              {/* <TextField id="DATUMVON" label="" error={fehlerliste["DATUMVON"]} placeholder="MM/JJJJ" style={{ backgroundColor: fehlerliste["DATUMVON"] === true ? "red" : "", width: "20%", display: "flex", float: "left" }} onChange={(event) => setzeAntworten("DATUMVON", event.target.value)}>
                {" "}
              </TextField> */}
              <Typography style={{ fontWeight: "bold", float: "left", width: "20%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                bis {bis} MM/JJJJ 
              </Typography>
              {/* <TextField id="DATUMBIS" label="" error={fehlerliste["DATUMBIS"]} placeholder="MM/JJJJ" style={{ backgroundColor: fehlerliste["DATUMBIS"] === true ? "red" : "", width: "20%", display: "flex", float: "left" }} onChange={(event) => setzeAntworten("DATUMBIS", event.target.value)}>
                {" "}
              </TextField> */}
              <Typography style={{ fontWeight: "bold", float: "left", width: "15%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                (Bemessungszeitraum)
              </Typography>
            </div>

          </Grid>


          <Grid item xs={12} style={{ width: "100%", borderTop: "", borderBottom: "" }}>
            <div style={{ borderBottom: "solid" }}>
              <Typography style={{ paddingLeft: "4px", textAlign: "left", borderLeft: "solid", borderBottom: "solid", fontWeight: "bold", borderRight: "solid", float: "left", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                1. Angaben zum/zur Arbeitnehmer(in), für den die Bescheinigung ausgestellt wird
              </Typography>
            </div>
            <div style={{ borderBottom: "solid" }}>
              <TextField id="NAME" label="" error={fehlerliste["NAME"]} placeholder="Name, Vorname" style={{ backgroundColor: fehlerliste["NAME"] === true ? "red" : "", width: "80%", display: "flex", float: "left", borderRight: "solid", borderLeft: "solid", }} onChange={(event) => setzeAntworten("NAME", event.target.value)}>
                {" "}
              </TextField>
              <TextField id="GEBURTSDATUM" label="" error={fehlerliste["GEBURTSDATUM"]} placeholder="Geburtsdatum" style={{ backgroundColor: fehlerliste["GEBURTSDATUM"] === true ? "red" : "", width: "20%", display: "flex", borderRight: "solid", }} onChange={(event) => setzeAntworten("GEBURTSDATUM", event.target.value)}>
                {" "}
              </TextField>
            </div>
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <Typography style={{ paddingLeft: "4px", textAlign: "left", border: "solid", borderBottom: "solid", fontWeight: "bold", borderRight: "solid", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              2. Angaben zum Arbeitsentgelt, das im maßgeblichen Bemessungszeitraum
              zugeflossen ist (vom Arbeitgeber auszufüllen)
            </Typography>
          </Grid>


          <Grid item xs={12} style={{}}>
            <div style={{}}>
              <Typography style={{ paddingLeft: "4px", textAlign: "left", border: "solid", fontWeight: "bold", borderRight: "solid", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                2.1 Laufend gezahltes Arbeitsentgelt
              </Typography>
              <Typography multiline={true} style={{ paddingLeft: "4px", textAlign: "left", borderLeft: "solid", borderBottom: "solid", borderRight: "solid", width: "100%", fontSize: "1.0em", height: "224px", lineHeight: "32px", }}>
                Das laufende Bruttoarbeitsentgelt beträgt <br />

                <List sx={{ '& li': { paddingBottom: 0 } }}>
                  <ListItem sx={{ gap: 1 }} >
                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                      <Box component="span" fontWeight="bold"><FiberManualRecordIcon sx={{ fontSize: 12, marginRight: 1 }} />einschließlich </Box>Sachbezügen und sonstigen zusätzlichen Leistungen des Arbeitgebers (z.B. Überstundenvergütungen,
                      Mehrarbeits-/Nachtzuschläge, Auslöse, Altersvorsorgeaufwendung in Form von Entgeltumwandlung nach dem BetrAVG
                      [Direktversicherung, Pensionskasse, Pensionsfonds, nicht Eigenbeiträge], Arbeitgeberzuschüsse zr VBL, vermögenswirksame Leistungen), jedoch
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ gap: 1 }} >
                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                      <Box component="span" fontWeight="bold"><FiberManualRecordIcon sx={{ fontSize: 12, marginRight: 1 }} />ohne </Box> Einmalzahlungen(Diese bitte unter Punkt 2.2 eintragen)
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ gap: 1 }} >
                    <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
                      <Box component="span" fontWeight="bold"><FiberManualRecordIcon sx={{ fontSize: 12, marginRight: 1 }} />ohne </Box> Kindergeld
                    </Typography>
                  </ListItem>
                </List>


              </Typography>

              <Typography style={{ borderLeft: "solid", borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "64px", lineHeight: "64px", }}>
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "23.75%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Auszahlung im Monat/Jahr
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "23.75%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Bruttoarbeitsentgeld
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "23.75%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Davon vermögenswirksame Leistungen
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "23.75%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                Steuern und Sozialbeiträge (nur Arbeitnehmeranteil)
              </Typography>

              {gezahltesEntgeld()}
            </div>
          </Grid>

          <Grid item xs={12} style={{}}>
            <div style={{}}>
              <Typography style={{ paddingLeft: "4px", textAlign: "left", borderLeft: "solid", borderTop: "solid", borderRight: "solid", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                2.2 Nicht laufend gezahltes Arbeitsentgelt (Einmalzahlungen)
              </Typography>
              <Grid item xs={12} style={{ border: "solid", width: "100%" }}>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "80%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                  Der/de Arbeitnehmer(in) hat om oben genannten Bemessungszeitraum zusätzlich zum laufend gezahlten <br />
                  Arbeitsgeld  Einmalzahlungen erhalten. (z.B. 13. Monatsgehalt, Weihnachtsgeld, zustätzliches Urlaubsgeld)
                </Typography>
                <Typography style={{ paddingLeft: "4px", textAlign: "left", fontWeight: "bold", width: "80%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                  Wenn ja:
                </Typography>
                <div style={{ textAlign: "right" }}>
                  <FormControlLabel control={<Checkbox style={{ backgroundColor: fehlerliste["EZJA"] === true ? "red" : "", }} error={fehlerliste["EZJA"]} checked={ezJaChecked} id="EZJA" onChange={(event) => setzeAntworten("EZJA", event.target.value)} />} labelPlacement="end" label="Ja" />
                  <FormControlLabel control={<Checkbox style={{ backgroundColor: fehlerliste["EZNEIN"] === true ? "red" : "", }} error={fehlerliste["EZNEIN"]} checked={ezNeinChecked} id="EZNEIN" onChange={(event) => setzeAntworten("EZNEIN", event.target.value)} />} labelPlacement="end" label="Nein" />
                </div>
              </Grid>
              <Typography style={{ borderLeft: "solid", borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "25%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Art der Einmalzahlung
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "25%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Auszahlung im Monat/Jahr
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "25%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Bruttobetrag
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "25%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
                Steuern und Sozialbeiträge (nur Arbeitnehmeranteil)
              </Typography>

              {nichtGezahltesEntgeld()}


            </div>
          </Grid>

          <Grid item xs={12} style={{}}>
            <div style={{}}>
              <Typography style={{ paddingLeft: "4px", textAlign: "left", border: "solid", fontWeight: "bold", borderRight: "solid", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                2.3 Weitere laufend gezahlte Leistungen
              </Typography>

              <Typography style={{ borderLeft: "solid", borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "5%", fontSize: "0.8em", height: "64px", lineHeight: "64px", }}>
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "31.667%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Art der Leistung
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "31.667%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Auszahlung im mont/Jahr
              </Typography>
              <Typography style={{ borderRight: "solid", borderBottom: "solid", fontWeight: "bold", float: "left", width: "31.667%", fontSize: "1em", height: "64px", lineHeight: "64px", }}>
                Zahlbetrag
              </Typography>
              {laufendeLeistungen()}

            </div>
          </Grid>

          <Grid item xs={12} style={{}}>
            <div style={{ borderBottom: "solid", width: "100%", float: "left" }}>
              <Typography style={{ paddingLeft: "4px", textAlign: "left", border: "solid", fontWeight: "bold", borderRight: "solid", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
                3. Ergänzende Hinweise
              </Typography>

              <TextField id="HINWEISE1" label="" error={fehlerliste["HINWEISE1"]} style={{ backgroundColor: fehlerliste["HINWEISE1"] === true ? "red" : "", width: "100%", borderLeft: "solid", display: "flex", borderBottom: "solid", float: "left", borderRight: "solid" }} onChange={(event) => setzeAntworten("HINWEISE1", event.target.value)}>
                {" "}
              </TextField>
              <TextField id="HINWEISE2" label="" error={fehlerliste["HINWEISE2"]} style={{ backgroundColor: fehlerliste["HINWEISE2"] === true ? "red" : "", width: "100%", borderLeft: "solid", display: "flex", borderBottom: "solid", float: "left", borderRight: "solid" }} onChange={(event) => setzeAntworten("HINWEISE2", event.target.value)}>
                {" "}
              </TextField>
              <TextField id="HINWEISE3" label="" error={fehlerliste["HINWEISE3"]} style={{ backgroundColor: fehlerliste["HINWEISE3"] === true ? "red" : "", width: "100%", borderLeft: "solid", display: "flex", float: "left", borderRight: "solid" }} onChange={(event) => setzeAntworten("HINWEISE3", event.target.value)}>
                {" "}
              </TextField>

            </div>
          </Grid>
          <Grid item xs={12} style={{}}>
            <Typography style={{ paddingTop: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              Hinweis zum Datenschutz:
            </Typography>
            <Typography style={{ padding: "4px", textAlign: "left", width: "100%", fontSize: "1em", height: "96px", lineHeight: "32px", }}>
              Die Daten werden aufgrund des Bundeskindergesetzes und des Sozialgesetzbuches verarbeitet. Zweck der Verarbeitung der Daten ist die Prüfung des Anspruchs
              auf Kinderzuschlag. Nähere Informationen über die Verarbeitung personenbezogener Daten durch die Familienkasse erhalten Sie im internet af der Seite der
              zuständigen Familienkasse (zu finden unter www.arbeitsagentur.de/datenschutz-familienkasse),
              auf der auch die Kontaktdaten der/des Datenschutzbeauftragten bereitgestellt sind. Kindergeldakten werden in der Regel nach dem Ende der letzten
              Kinderzuschlagszahlungen noch für 6 Jahre aufbewahrt.
            </Typography>
            <div style={{ width: "100%", paddingTop: "20px", }}>
              <TextField placeholder="Datum" label="" style={{ width: "30%", borderBottom: "solid", display: "flex", float: "left", borderRight: "solid" }} >
                {" "}
              </TextField>
              <TextField multiline={true} rows={10} id="FIRMENDATEN" placeholder="Anschrift, Telefonnummer, Ansprechpartner, soweit nicht im Firmenstempel enthalten" label="" error={fehlerliste["FIRMENDATEN"]} style={{ backgroundColor: fehlerliste["FIRMENDATEN"] === true ? "red" : "", width: "30%", border: "solid", display: "flex", float: "right" }}>
                {" "}
              </TextField>
              <div style={{ paddingBottom: "140px", float: "left", width: "50%", }}></div>
              <div style={{ float: "left", width: "50%", }}>
                <TextField id="FIRMENSTEMPEL" placeholder="Firmenstempel des Arbeitgebers, Unterschrift" label="" error={fehlerliste["FIRMENSTEMPEL"]} style={{ backgroundColor: fehlerliste["FIRMENSTEMPEL"] === true ? "red" : "", width: "100%", display: "flex", float: "left", borderTop: "dashed" }}>
                  {" "}
                </TextField>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} style={{}}>
            <InfoOutlinedIcon fontSize="large" style={{ float: "left" }}></InfoOutlinedIcon>
            <Typography style={{ paddingTop: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.4em", height: "32px", lineHeight: "32px", }}>
              Ausfüllhinweise zur Verdienstbescheinigung des Arbeitgebers
            </Typography>

            <Typography style={{ paddingTop: "4px", textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              Zu Punkt 2: Angaben zum Arbeitsentgelt
            </Typography>

            <Typography style={{ textAlign: "left", width: "100%", fontSize: "1em", height: "64px", lineHeight: "32px", }}>
              Neben dem steuerpflichtigen Arbeitsentgelt sind grundsötzlich auch steuerfreie Lohnanteile zu bescheinigen.
              0Bei Verdiensten, die innerhalb der Gleitzone liegen, ist nicht das reduzierte beitragspflichtige, sodern das tatsächliche Bruttoentgelt zu bescheinigen
            </Typography>

            <Typography style={{ textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              Zu Punkt 2.3: Weitere laufend gezahlte Leistungen
            </Typography>

            <Typography style={{ textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
              Leistungen, die nicht dem Bruttoarbeitsentgelt zuzuordnen sind, müssen gesondert bescheinigt werden (Punkt 2.3)
            </Typography>

            <Typography style={{ textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              Zu Punkt 3: Ergänzende Hinweise
            </Typography>

            <Typography style={{ textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
              Wird eine freie Unterkunft zur Verfügung gestellt, ist dies im Punkt 3 "Ergänzende Hinweise" zu vermerken.
            </Typography>

            <Typography style={{ textAlign: "left", fontWeight: "bold", width: "100%", fontSize: "1.2em", height: "32px", lineHeight: "32px", }}>
              Nicht zu bescheinigende Arbeitsentgeltbestandteile
            </Typography>

            <Typography style={{ textAlign: "left", width: "100%", fontSize: "1em", height: "32px", lineHeight: "32px", }}>
              Leistungen, die einen Aufwand abgelten, sind nicht zu bescheinigen, dazu gehören z.B.
            </Typography>

            <List  >
              <ListItem sx={{ gap: 1 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Arbeitskleidung
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Auslagenersatz
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Dienstwohnung
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Freifahrten mit Werkbussen und anderen Sammeltransportmitteln einschl. Flugzeugen
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Kindergartenplatz
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Kraftfahrzeugüberlassung zum Privaten gebrauch
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Reisekostenvergütung
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 2 }} >
                <FiberManualRecordIcon fontSize="inherit"></FiberManualRecordIcon>
                <Typography style={{ float: "left", paddingLeft: "4px", textAlign: "left", width: "100%", fontSize: "0.8em", height: "32px", lineHeight: "32px", }}>
                  Werkzeuggeld
                </Typography>
              </ListItem>


            </List>

          </Grid>

          <div style={{ padding: "20px" }}></div>

          <Kursabgeben
            anzahlFehler={anzFehler}
            antwortenSenden={antwortenSenden}
            testabgabeken={testabgabe}
            abgabedat={abgabedat}
            abgabezeit={abgabezeit}
            bemerkungen={bemerkungen}
            reactnummer={reactnummer}
            merkmal={merkmal}
          />
        </Grid>
      </Grid>
    </div>


  );
}

export default VerdienstbescheinigungArbeitgebers;
