import React, {useState, useCallback, useEffect} from 'react';
import {Button, Paper, Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Quizabgeben} from '../../components/Rahmendaten/Quizabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';
const lohni = '/media/Lohni.png';
const HELLOGIF = '/media/Gif_hello.gif';



function QuizFirmenwagen(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste,setFehlerliste]=useState([]);
  const [anzFehler,setAnzFehler]=useState(0);
  const {showErrorMessage} = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [hinweis, setHinweis] = useState('')
  const [wert, setWert]=useState(false);
  const [startzeitrechnung, setStartzeitrechnung]=useState(false);
  const [zeit, setZeit] = useState(0);
  const [zustand, setZustand]=useState(false);
  const [strafzeit, setStrafzeit]=useState(0)
  const [quizzaehler, setQuizzaehler]=useState(0)
  const [reactnummer]=useState('044');
  const [merkmal]=useState("QuizFirmenwagen");


  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  useEffect(() => {
    if (startzeitrechnung === true) {
      const timer=setTimeout(() => {
          setZeit(zeit+strafzeit+1);
          setStrafzeit(0);
          setHinweis("Zeitdauer:"+zeit);
        }, 1000)
      return () => clearTimeout(timer);
      }},[zeit, startzeitrechnung]);
    
  

  const setRedirect = (path) => {
    navigate(path);
  }



  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "hinweis":hinweis,
      "frage":quizzaehler,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  const starteQuiz = () => {
    setStartzeitrechnung(true);
    setZeit(0);
    setZustand(true);
    setQuizzaehler(1);
  }
  
  const kurseCb = (response) => {
      if (response.status === 200) {
//*         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
         if (response.data.anzfehler === "0"){
            var zwisfrage = parseInt(quizzaehler)+1;
            setQuizzaehler(zwisfrage);
         } else {
            setStrafzeit(120);
         }
    } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
      }
  }

  const changeswitch = (values) => {
     setWert(values);
  }

  return(
    <div>
      <Briefkopf  
            ueberschrift1="Quiz Firmenwagen" 
            ueberschrift2="Viel Spass damit!"
      />

    {(startzeitrechnung === false) && 

      <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
                <img src={HELLOGIF}></img>
          </Grid>

          <Grid item xs={12}>
            <Button 
                color="primary"  
                variant="contained"
                onClick={starteQuiz} 
                disabled={zustand}

  //              fullWidth
                >

                <Typography variant="h5" >
                  Klicke hier, <br/>um das Quiz zu beginnen<br/>
                </Typography>
            </Button>
          </Grid>
      </Grid>
    }

    {(startzeitrechnung === true) && 
      <div>     
      <Paper>
        <Grid container spacing={0} style={{marginTop:'5px'}} justifyContent={"center"} alignItems={"center"}>
          <h2>benötigte Zeit: </h2>
                    <h2>{
                       Math.floor((zeit / 60) % 60)+' Minuten ' + Math.floor((zeit) % 60)+' Sekunden'
                      }
                      </h2>
    
        </Grid>
      </Paper>
      </div> }
    
    {(quizzaehler > 0) &&
      <div>
      <Paper>
        <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <img src={lohni} height="250" alt="ectool" loading="lazy"/>
              </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5"  color="primary">
                  Frage 1 <br/>
              </Typography>
              <Typography >
                Der komplette geldwerte Vorteil für die Nutzung eines Firmenwagens (inkl. Fahrten Wohnung-Arbeit) beträgt 540,70 Euro.<br/>
                Der Arbeitnehmer leistete eine einmalige Zuzahlung in Höhe von 2000,00 Euro.<br/>
                Darf die komplette Zuzahlung in einem Abrechnungsmonat steuer- und sv-mindernd in Abzug gebracht werden?
              </Typography>
              <VariableCheckBox
                tabellenKey="1" 
                ueberschrift=""
                anzReihen={2}
                Checktexte={["Ja, der Arbeitnehmer hat schließlich die Anzahlung komplett geleistet und darf sie deshalb auch komplett in einem Monat in Abzug bringen",
                            "Nein, es darf höchstens nur der Betrag steuer- und sv-mindernd in Abzug gebracht werden, welcher dem geldwerten Vorteil für den Firmenwagen entspricht."]}
                checked={[false,false]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
              />
           </ListItemText>
          </ListItem>
        </Grid>
      </Paper>
      </div>
    }

    {(quizzaehler > 1) &&
      <div>
      <Paper>
        <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <img src={lohni} height="250" alt="ectool" loading="lazy"/>
            </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5"  color="primary">
                  Frage 2 <br/>
                </Typography>
                <Typography >
                  Kann die Zuzahlung des Arbeitnehmers bei Frage 1 auch über mehrere Jahre verteilt den geldwerten Vorteil mindern oder muss <br/>
                  die Zuzahlung im dem Jahr, in welchem sie geleistet wurde, vollständig verrechnet werden?<br/>
                </Typography>
                <VariableCheckBox
                tabellenKey="2" 
                ueberschrift=""
                anzReihen={2}
                Checktexte={["Ja, der Arbeitnehmer kann die Zuzahlung auch über mehrere Kalenderjahre verrechnen und den geldwerten Vorteil entsprechend mindern. ",
                            "Nein, die Zuzahlung darf nur in dem Jahr der Zuzahlung mit dem geldwerten Vorteil für den Firmenwagen verrechnet werden."]}
                checked={[false,false]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />

              </ListItemText>
          </ListItem>
        </Grid>
      </Paper>

      </div>
    }

{(quizzaehler > 2) &&
      <div>
      <Paper>
        <Grid container spacing={0} style={{marginTop:'50px'}} justifyContent={"center"} alignItems={"center"}>
          <ListItem>
              <ListItemAvatar>
                <img src={lohni} height="250" alt="ectool" loading="lazy"/>
            </ListItemAvatar>
              <ListItemText>
                <Typography variant="h5"  color="primary">
                  Frage 3 <br/>
                </Typography>
                <Typography >
                    Der komplette geldwerte Vorteil für die Nutzung eines Firmenwagens (inkl. Fahrten Wohnung-Arbeit) beträgt 430,60 Euro.<br/>
                    Der Arbeitnehmer leistete eine einmalige Zuzahlung in Höhe von 4000,00 Euro.<br/>
                    Nach 7 Monaten der Nutzung des Firmenwagens bekommt der Arbeitnehmer einen anderen Firmenwagen. <br/>
                    Somit sind nach 7 Monaten (7 x 430,60 =) 3014,20 Euro von der Zuzahlung in Höhe von 4000,- Euro 'verbraucht'.
                    Für den anderen Firmenwagen leistete der Arbeitnehmer keine Zuzahlung.
                    Kann der Arbeitnehmer nun für den anderen Firmenwagen, den Restbetrag vom dem ersten Firmenwagen in Höhe von (4000 - 3014,20 =) 985,80 Euro verwenden?
                </Typography>
                <VariableCheckBox
                tabellenKey="3" 
                ueberschrift=""
                anzReihen={2}
                Checktexte={["Ja, der Arbeitnehmer kann den Restbetrag auf den anderen Firmenwagen übertragen. Schließlich hat der Arbeitnehmer ja diesen Betrag bezahlt.",
                            "Nein, die Zuzahlung darf nur für den Firmenwagen verwendet werden, für welchen sie auch geleistet wurde."]}
                checked={[false,false]}
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />

              </ListItemText>
          </ListItem>
        </Grid>
      </Paper>

      </div>
    }

    {(quizzaehler > 0) &&
          <Quizabgeben  
          anzahlFehler={anzFehler} 
          antwortenSenden={antwortenSenden} 
          testabgabeken={testabgabe} 
          abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} hinweis={hinweis} frage={quizzaehler} reactnummer={reactnummer} merkmal={merkmal} />
    }
    

    </div>
    )
}


export default QuizFirmenwagen;