import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Button, MenuItem, TextField, Typography, Grid, Paper } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
const textaufg = '/media/Textaufgabe_1.pdf';
import "../../../node_modules/video-react/dist/video-react.css"
const lsttabpdf = '/media/lohnsteuertabelle.pdf';
import { useMessage } from '../../context/message-context';
import LoadingComponent from '../LoadingComponent/LoadingComponent';


function Eignungstest1(props) {
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [zeit, setZeit] = useState();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [antworten, setAntworten] = useState({});
  const [uhrzeit, ] = useState(new Date().toLocaleTimeString('de-DE'));  
  const [okay, setOkay] = useState(true);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const {showErrorMessage} = useMessage();
  const [isLoading, setIsLoading]=useState(false);
  const [bereitsabgabe, setBereitsabgabe]=useState(false);
  const [zwangabgabe, setZwangabgabe]=useState(false);

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }


  const setRedirect = (path) => {
    navigate(path);
  }

  useEffect(() => {
    setIsLoading(true);
    setOkay(false);
    const headers={
      "Authorization": "Bearer "+accessToken,
    }

    const payload={
      "kennung": "sperren",
      "merkmal": "Eignung1",
      "reactnummer": "011",
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'eignungstest', payload, headers, holeDatenCb, setRedirect);
    // eslint-disable-next-line
  }, [])

  const holeDatenCb = (response) => { 
    setIsLoading(false);
    if (response.status === 200) {
      setZeit(2700);
      if (response.data.Eignung1_zeitstempel !== 0) {
        var zwiszeit = response.data.Eignung1_zeitstempel;
        var startzeit = parseInt(zwiszeit.slice(0,2))*60*60
                    + parseInt(zwiszeit.slice(3,5))*60
                    + parseInt(zwiszeit.slice(6,8));


        var endzeit = parseInt(uhrzeit.slice(0,2))*60*60
                    + parseInt(uhrzeit.slice(3,5))*60
                    + parseInt(uhrzeit.slice(6,8));

        var differenz =  endzeit-startzeit; 
        if (differenz < 0) {
          differenz = 0;
        }
        zwiszeit = 2700 - differenz
        
        setZeit(zwiszeit);
        if (zwiszeit > 0) {
          setOkay(true);
        }
        if (response.data.Eignung1_tagesdat === "FALSE") {
          setOkay(false);
          setZeit(0);
        }
      }

      if (response.data.Eignung1_abgabedat !== '') {
        setAbgabedat(response.data.Eignung1_abgabedat.slice(6,8)+"."+response.data.Eignung1_abgabedat.slice(4,6)+"."+response.data.Eignung1_abgabedat.slice(0,4));
        setAbgabezeit(response.data.Eignung1_abgabezeit);
        setZeit(0);
        setOkay(false);
        setTestabgabe(true);
      }
      } else {
      if (response) {
        showErrorMessage(response.data.message);
        navigate(-1);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  //eslint-disable-next-line
  useEffect(() => {
    const timer=setTimeout(() => {
        setZeit(zeit-1);
    }, 1000);
    if (zeit < 0) {
      setOkay(false);
      if (testabgabe === false) {
        antwortenSenden()
        setZwangabgabe(true);
      };
      clearTimeout(timer);
    } 
  return () => clearTimeout(timer);
});


  const antwortenSenden = () => {
      const headers={
        "Authorization": "Bearer "+accessToken,
      }

      const payload={
        "kennung": "testabgabe",
        "merkmal": "Eignung1",
        "daten": antworten,
        "reactnummer": "011",
        "hsutkey": hsutkey
      } 
      apiRequest('POST', 'eignungstest', payload, headers, kurseCb, setRedirect);
  }

  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setOkay(false);
      setZeit(0);
      setAbgabedat(response.data.Eignung1_abgabedat.slice(6,8)+"."+response.data.Eignung1_abgabedat.slice(4,6)+"."+response.data.Eignung1_abgabedat.slice(0,4));
      setAbgabezeit(response.data.Eignung1_abgabezeit);
      if (response.data.Eignung1_bereitsabgabe === "ja") {setBereitsabgabe(true)}
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  if (isLoading) {
    return(<LoadingComponent path={location}/>)
  }

  return(
    <div>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="150" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Eignungstest</Typography> 
                  <Typography color="error">
                      Der Test kann nur einmal aktiviert werden.<br />
                      Der Test wird mit dem Anklicken des Buttons "Test abgeben" abgegeben. <br /> <br />
                  </Typography>
                    <h5>Verbleibende Zeit: </h5>
                  {zeit > 0 ?
                    <h5>{
                       Math.floor((zeit / 60) % 60)+' Minuten ' + Math.floor((zeit) % 60)+' Sekunden'
                        }
                    </h5>
                    : 
                    bereitsabgabe && !zwangabgabe?
                   <h4>
                      Der Test wurde bereits abgegeben
                    </h4> 
                    :
                    bereitsabgabe && zwangabgabe ?
                   <h4>
                      Die Zeit ist abgelaufen! Dein Test wurde an ectool übermittelt!
                    </h4> 
                    :
                    <h4>
                      Test erfolgreich abgegeben
                    </h4> 
                  }

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      

      {(okay === true) && 
      <div>     
        <Grid container spacing={1} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={2} > </Grid>
            <Grid item xs={8} style={{border:"solid", backgroundColor: "#1fbba6"}} >
                <Typography variant="h6">
                  Lohnartenstamm
                </Typography> 
            </Grid>
            <Grid item xs={2} > </Grid>


            <Grid item xs={2} > </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>Lohnart</Typography> 
            </Grid>

            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>Bezeichnung</Typography> 
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>Faktor pro Stunde</Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>


            <Grid item xs={2} >
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>10</Typography> 
            </Grid>

            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>Normalstunden</Typography> 
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid"}} >
              <Typography>10,50</Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>


            <Grid item xs={2} >
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>20</Typography> 
            </Grid>

            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>Ausarbeit.-Std.</Typography> 
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid"}} >
              <Typography>11,00</Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>


            <Grid item xs={2} >
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>30</Typography> 
            </Grid>

            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>Prämie</Typography> 
            </Grid>

            <Grid item xs={8} sm={2} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid"}} >
            <Typography > ---- </Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>
        </Grid>


        <Grid container spacing={3} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={2} >
            </Grid>
            <Grid item xs={8} style={{border:"solid", backgroundColor: "#1fbba6"}} >
              <Typography variant="h3" color="primary"></Typography> 
            </Grid>
            <Grid item xs={2} >
            </Grid>


            <Grid item xs={2} >
            </Grid>
            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>Sozialversicherungsbeiträge</Typography> 
            </Grid>
            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>KV *</Typography> 
            </Grid>
            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" , backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>RV *</Typography> 
            </Grid>
            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" , backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>AV *</Typography> 
            </Grid>
            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography>PV *</Typography> 
            </Grid>
            <Grid item xs={2} >
            </Grid>



            <Grid item xs={2} >
            </Grid>
            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>Arbeitnehmeranteil</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>8,20%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>9,45%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>1,50%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid" }} >
              <Typography>1,175%</Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>


            <Grid item xs={2} >
            </Grid>

            <Grid item xs={8} sm={4} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>Arbeitgeberanteil</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>7,30%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>9,45%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" }} >
              <Typography>1,50%</Typography> 
            </Grid>

            <Grid item xs={8} sm={1} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid" }} >
              <Typography>1,175%</Typography> 
            </Grid>

            <Grid item xs={2} >
            </Grid>
        </Grid>

        <Grid container spacing={1} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"} >
            <Grid item xs={8} >
              <Button 
      //            fullWidth 
                  color="primary" 
                  variant="contained"
                  href={textaufg}
                  download
                  >
                  Textaufgabe öffnen
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button 
      //          fullWidth 
                color="primary"
                variant="contained"
                href={lsttabpdf}
                download
                >
                Lohnsteuertabelle öffnen
              </Button>
          </Grid>
        </Grid>

      <Grid container spacing={1} style={{marginTop:'30px'}} >
          <Grid item xs={12} style={{border:"solid", backgroundColor:"#1fbba6"}} >
            <Typography variant="h6"  >Entgeltbescheinigung</Typography> 
          </Grid>
    

          <Grid item xs={1} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography>Lohnart</Typography> 
          </Grid>

          <Grid item xs={4} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography>Bezeichnung</Typography> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography>Stunden</Typography> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography>Faktor</Typography> 
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" , backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography>Betrag</Typography> 
          </Grid>


          <Grid item xs={1} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
                select // durch select wird DropDown initialisiert
                id="Loa1"
                label="Lohnart auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Loa1']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Loa1',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={4} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
                select // durch select wird DropDown initialisiert
                id="Bez1"
                label="Bezeichnung auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Bez1']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Bez1',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>Normalstunden</MenuItem>
                <MenuItem value={20}>Ausarb.-Std.</MenuItem>
                <MenuItem value={30}>Prämie</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid"  }} >
            <TextField
                id="Std1"
                label="errechnete Stunden eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Std1']}
                inputProps={{style: {textAlign:'center'}}}

                defaultValue={''}
                onChange={(event) => setzeAntworten('Std1',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , }} >
            <TextField
                select
                id="Fak1"
                label="Faktor auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Fak1']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Fak1',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10,50</MenuItem>
                <MenuItem value={20}>11,00</MenuItem>
                <MenuItem value={30}>--,--</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
          <TextField
                id="Betrag1"
                label="errechneter Betrag eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Betrag1']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Betrag1',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>



          <Grid item xs={1} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
              // zweite Zeile
                select // durch select wird DropDown initialisiert
                id="Loa2"
                label="Lohnart auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Loa2']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Loa2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={4} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
                select // durch select wird DropDown initialisiert
                id="Bez2"
                label="Bezeichnung auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Bez2']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Bez2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>Normalstunden</MenuItem>
                <MenuItem value={20}>Ausarb.-Std.</MenuItem>
                <MenuItem value={30}>Prämie</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , }} >
            <TextField
                id="Std2"
                label="errechnete Stunden eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Std2']}
                defaultValue={''}
                inputProps={{style: {textAlign:'center'}}}
                onChange={(event) => setzeAntworten('Std2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , }} >
            <TextField
                select
                id="Fak2"
                label="Faktor auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Fak2']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Fak2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10,50</MenuItem>
                <MenuItem value={20}>11,00</MenuItem>
                <MenuItem value={30}>--,--</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
          <TextField
                id="Betrag2"
                label="errechneter Betrag eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Betrag2']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Betrag2',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>


          <Grid item xs={1} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
              // dritte Zeile
                select // durch select wird DropDown initialisiert
                id="Loa3"
                label="Lohnart auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Loa3']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Loa3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={4} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
                select // durch select wird DropDown initialisiert
                id="Bez3"
                label="Bezeichnung auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Bez3']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Bez3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>Normalstunden</MenuItem>
                <MenuItem value={20}>Ausarb.-Std.</MenuItem>
                <MenuItem value={30}>Prämie</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , }} >
            <TextField
                id="Std3"
                label="errechnete Stunden eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Std3']}
                defaultValue={''}
                inputProps={{style: {textAlign:'center'}}}
                onChange={(event) => setzeAntworten('Std3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>

          <Grid item xs={2} style={{borderLeft:"solid", borderBottom:"solid" , }} >
            <TextField
                select
                id="Fak3"
                label="Faktor auswählen"
                fullWidth
                variant="outlined"
                value={antworten['Fak3']}
                defaultValue={''}
                onChange={(event) => setzeAntworten('Fak3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
                <MenuItem value={10}>10,50</MenuItem>
                <MenuItem value={20}>11,00</MenuItem>
                <MenuItem value={30}>--,--</MenuItem>
            </TextField> 
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid" , borderRight:"solid" }} >
          <TextField
                id="Betrag3"
                label="errechneter Betrag eingeben"
                fullWidth
                variant="outlined"
                value={antworten['Betrag3']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Betrag3',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>



          <Grid item xs={9} style={{borderLeft:"solid", borderBottom:"solid", }} >
            <TextField
              // Summenzeile vierte Zeile
                disabled 
                id="Leer"
                label=""
                fullWidth
//                value={Liga}
                variant="outlined"
//                onChange={(event) => setLiga(event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
          <TextField
                id="SummeBrutto"
                label="Summme eingeben"
                fullWidth
                variant="outlined"
                value={antworten['SummeBrutto']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('SummeBrutto',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
      </Grid>


      <Grid container spacing={1} style={{marginTop:'30px'}} >
          <Grid item xs={6} style={{border:"solid", backgroundColor: "#1fbba6"}} >
            <Typography variant="h6" >Steuerberechnung</Typography> 
          </Grid>
          <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)"}} >
            <Typography variant="h6">Steuerklasse</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid",  backgroundColor: "rgb(220, 220, 220)"}} >
            <Typography variant="h6" >Vier (IV)</Typography> 
          </Grid>
          <Grid item xs={6} >
          </Grid>

           <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography variant="h6" >Lohnsteuer</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="LSt"
                label="Lohnsteuer eingeben"
                variant="outlined"
                value={antworten['LSt']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('LSt',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
        </Grid>
          <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >KiSt *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="KiSt"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['KiSt']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('KiSt',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
              <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >SolZ *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="SolZ"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['SolZ']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('SolZ',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
          <Grid item xs={6} >  </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderTop:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >Summe Steuern</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid", borderTop:"solid" }} >
            <TextField
                id="SumLst"
                label="Summe eingeben"
                variant="outlined"
                value={antworten['SumLst']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('SumLst',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
          <Grid item xs={3} >
            <Typography variant="h3">====</Typography>
          </Grid>
          <Grid item xs={3} style={{border:"solid"}}>
            <TextField
                id="UebtragTax"
                label="Summe Steuern übertragen"
                variant="outlined"
                margin="normal"
                value={antworten['UebtragTax']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('UebtragTax',event.target.value)} 
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
      </Grid>


      <Grid container spacing={1} style={{marginTop:'30px'}} >
          <Grid item xs={6} style={{border:"solid", backgroundColor: "#1fbba6"}} >
            <Typography variant="h6" >Sozialversicherung Arbeitnehmer</Typography> 
          </Grid>
          <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)"}} >
            <Typography variant="h6">Bezeichnung</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid",  backgroundColor: "rgb(220, 220, 220)"}} >
            <Typography variant="h6" >Betrag</Typography> 
          </Grid>
          <Grid item xs={6} >
          </Grid>

           <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
            <Typography variant="h6" >KV *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="KV"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['Kv']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Kv',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
        </Grid>
          <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >RV *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="RV"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['Rv']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Rv',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
              <Grid item xs={6} >
          </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >AV *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="AV"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['Av']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Av',event.target.value)} 

                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
          <Grid item xs={6} >  </Grid>

          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >PV *</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid" }} >
            <TextField
                id="PV"
                label="Betrag eingeben"
                variant="outlined"
                value={antworten['Pv']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Pv',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
          <Grid item xs={6} >  </Grid>


          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderTop:"solid", backgroundColor: "rgb(220, 220, 220)" }} >
              <Typography variant="h6" >Summe Sozialversicherung</Typography> 
          </Grid>
          <Grid item xs={3} style={{borderLeft:"solid", borderBottom:"solid", borderRight:"solid", borderTop:"solid" }} >
            <TextField
                id="SumSv"
                label="Summe eingeben"
                variant="outlined"
                value={antworten['SumSv']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('SumSv',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
          <Grid item xs={3} >
            <Typography variant="h3">====</Typography>
          </Grid>
          <Grid item xs={3} style={{border:"solid"}}>
            <TextField
                id="SumSvUeb"
                label="Summe übertragen"
                variant="outlined"
                value={antworten['SumSvUeb']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('SumSvUeb',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
      </Grid>



      <Grid container spacing={1} style={{marginTop:'30px'}} >
          <Grid item xs={6} >
          </Grid>
          <Grid item xs={3} >
          </Grid>
          <Grid item xs={3} style={{border:"solid", borderColor:"#1fbba6" }} >
            <TextField
                id="Auszahlung"
                label="Auszahlung (Betrag-Steuern-SV)"
                variant="outlined"
                value={antworten['Auszahlung']}
                defaultValue={''}
                inputProps={{style: {textAlign:'right'}}}
                onChange={(event) => setzeAntworten('Auszahlung',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>
   
      </Grid>


      <Grid container spacing={1} style={{marginTop:'5px'}} >
          <Grid item xs={6} style={{border:"solid", backgroundColor:"#1fbba6" }} >
            <Typography>
                Wenn der Arbeitnehmer für 12 Monate = 30 Tage Urlaub erhält, wieviel Tage bekommt <br />
                dann der Arbeitnehmer, wenn er nur 8 Monate beschäftigt ist <br />
            </Typography>
          </Grid>
          <Grid item xs={6} > </Grid>
          <Grid item xs={3} style={{justifyContent:"center", display:"flex", borderLeft:"solid", borderRight:"solid", borderBottom:"solid", backgroundColor: "rgb(220, 220, 220)"}} >
              <Typography >
                  Zustehender Urlaub
              </Typography>
          </Grid>

          <Grid item xs={3} style={{borderRight:"solid", borderBottom:"solid" }} >
            <TextField
                id="Url"
                label="Tage eingeben"
                variant="outlined"
                value={antworten['Url']}
                defaultValue={''}
                inputProps={{style: {textAlign:'center'}}}
                onChange={(event) => setzeAntworten('Url',event.target.value)} 
                margin="normal"
//                error={ErrorLiga !==""}
//                helperText={ErrorLiga}
                InputLabelProps={{
                  shrink: true,
                }}       
                >
            </TextField> 
          </Grid>

          <Grid item xs={6} >
          </Grid>

      </Grid>

      <Grid container spacing={1} style={{margin:'30px'}} justifyContent="center">
          <Grid item xs={8} >
            <Button 
                disabled={testabgabe}
                fullWidth 
                color="primary" 
                variant="contained"
                onClick={() => antwortenSenden()} 
                >
                Test abgeben
            </Button>
          </Grid>
      </Grid>
      </div>     
      }

      {(testabgabe === true) && 
      <Fragment>
        <Grid container spacing={2} justifyContent="center" style={{marginTop:"15px"}} >
          <Grid item xs={8}>
              {zwangabgabe & !bereitsabgabe && 
              <Typography variant="h6">
                Dein Test wurde wegen der Zeitüberschreitung automatisch am {abgabedat} um {abgabezeit} Uhr abgegeben. <br /> <br />
              </Typography>
              }

              {!zwangabgabe & bereitsabgabe && 
              <Typography variant="h6">
                Dein Test wurde bereits am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br /> <br />
              </Typography>
              }

              {!zwangabgabe & !bereitsabgabe && 
              <Typography variant="h6">
                Dein Test wurde am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br /> <br />
              </Typography>
              }

              <Typography variant="h6">
                Nun kommst Du zum Test Nr. 2 <br />
                Für den Test Nr.2 hast Du 45 Minuten Zeit.  <br />
                Sobald Du auf den Button "MIT TEST 2 BEGINNEN" klickst, läuft die Zeit. 
              </Typography>
          </Grid>   
          <Grid item xs={8}>
              <Button 
      //            fullWidth color="primary" 
                  color="primary"
                  variant="contained"
                  onClick={() => navigate('/011/teil2')} 
                  >
                  Mit Test 2 beginnen
              </Button>
          </Grid>
        </Grid>
      </Fragment>
     }


    </div>

  )
}

export default Eignungstest1;