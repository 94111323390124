import React, { useState } from 'react';
import {Button, Grid, Typography } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
const GUT = '/media/Gif_Stufen.gif';
const SCHLECHT = '/media/Gif_crying.gif';





function Quizabgeben (props) {
  const {testabgabeken, antwortenSenden, anzahlFehler, bezeichnung, bemerkungen} = props;
  const navigate=useNavigate();

  let text="";
  let dkennung=false;
  let textBtn="Quizfrage abgeben";
  let zusatztxt='';
  let quizende = false;


    if (bemerkungen !== undefined) {
      zusatztxt = bemerkungen
    }

    const ausgabeZusatz=zusatztxt.split("^").map((objekt, count)=>{
      return (
          <Typography key={count} style={{textAlign:"left"}}>
              {objekt}
          </Typography>
      )
  }
  )

    if (bezeichnung !== undefined) {
      textBtn=bezeichnung
    }
    
    if (anzahlFehler==='0'){
       if (testabgabeken === true) {dkennung=true} 
       text=<strong>Prima! Du hast keinen Fehler gemacht!</strong>
      }else{
       text=<strong>Du hast {anzahlFehler} Fehler gemacht. </strong>
    }

    const ergebnis = (anzahlFehler) => {
        if (anzahlFehler==='0'){
            let dkennung=true
            return(
              <img src={GUT} height="150" loading="lazy"/>  

            )
        } else {
            return(
            <img src={SCHLECHT} height="150" loading="lazy"/>  
          )
        }
    }
  

return(

    <Grid container spacing={0} style={{marginTop:"25px",marginBottom:"25px"}} justifyContent="center">
      
        {(testabgabeken === true) && 
          <Grid container spacing={2} justifyContent="center" style={{border:"solid", borderColor:"#1fbba6"}}>
              <Grid item xs={6} >
                  <Typography>
                  <br/>{text}<br/>
                  </Typography>
              </Grid>
              <Grid item xs={4}>
                  {ergebnis(anzahlFehler)}
              </Grid>
          </Grid>
        }

        <Grid item xs={8}style={{marginTop:"15px",marginBottom:"15px"}}>
            <Button 
                disabled={dkennung}
                color="primary"  
                variant="contained"
                fullWidth
                onClick={(event)=> antwortenSenden()}
                >
                {textBtn}
              </Button>
        </Grid>

        {(quizende === true) &&
            <Grid item xs={8}style={{marginTop:"15px",marginBottom:"15px"}}>
                <Button 
                  color="primary"  
                  variant="contained"
                  fullWidth
                  onClick={()=> navigate(-1)}
                >
                Zurück zum Verteiler
                </Button>
            </Grid>
        }


    </Grid>
)
}


export {Quizabgeben};