import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import { Kursabgeben } from '../../components/Rahmendaten/Kursabgeben';
import { Briefkopf } from '../../components/Rahmendaten/Briefkopf';
import { BruttoNeu2 } from '../Rahmendaten/BruttoNeu2';
import ForwardIcon from '@mui/icons-material/Forward';
import FeedIcon from '@mui/icons-material/Feed';
import CalculateIcon from '@mui/icons-material/Calculate';
import { NettoLoa2 } from '../Rahmendaten/NettoLoa2';
import { Tabelle2spaltig } from './../Rahmendaten/Tabelle2spaltig'

function BAV2(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate = useNavigate();
  const [antworten, setAntworten] = useState({});
  const [testabgabe, setTestabgabe] = useState(false);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const [fehlerliste, setFehlerliste] = useState([]);
  const [anzFehler, setAnzFehler] = useState('99')
  const { showErrorMessage } = useMessage();
  const [bemerkungen, setBemerkungen] = useState('')
  const [ausgewaehlteLohnarten, setAusgewaehlteLohnarten] = useState([]);
  const [reactnummer] = useState('049');
  const [merkmal] = useState("BAV2");
  const einrueck = "50px";

  var gehalt = '2700.00'
  const setzeAntworten = (key, value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }

  const setRedirect = (path) => {
    navigate(path);
  }


  const fehlerAusLesen = (fehler) => {
    console.log("fehler: " + fehler)
    let array = fehler.split(";");
    let fehlerzwis = []
    array.map(
      // eslint-disable-next-line
      (objekt) => {
        fehlerzwis[objekt] = true;
      }
    );
    setFehlerliste(fehlerzwis);
  };


  const antwortenSenden = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "ausgewaehlteLoas": ausgewaehlteLohnarten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    }
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }


  const kurseCb = (response) => {
    if (response.status === 200) {
      setTestabgabe(true);
      setAbgabedat(response.data.abgabedat.slice(6, 8) + "." + response.data.abgabedat.slice(4, 6) + "." + response.data.abgabedat.slice(0, 4));
      setAbgabezeit(response.data.abgabezeit);
      fehlerAusLesen(response.data.fehler);
      setAnzFehler(response.data.anzfehler);
      setBemerkungen(response.data.besonderheiten);
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  const linkCb = (response) => {
    if (response.status === 200) {
      if (response.data.rehmlink !== "") {
        window.open(response.data.rehmlink)
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }

  }

  const LexikonLohnbüro = () => {
    const headers = {
      "Authorization": "Bearer " + accessToken,
    }
    const payload = {
      "kennung": "kursabgabe",
      "merkmal": "getLink",
      "reactnummer": "046"
    }
    apiRequest('POST', 'kursabgabe', payload, headers, linkCb, setRedirect);
  }

  const kug_tabelle = () => {
    setRedirect();
  }

  return (
    <div>
      <Briefkopf
        ueberschrift1="Die betriebliche Altersvorsorge-2 (bAV)"
        ueberschrift2="Welche Grenzen für die steuer- und sv-Freiheit gibt es bei der arbeitnehmer- und arbeitgeberfinanzierten bAV?"
      />

      <Grid container spacing={0} style={{ marginTop: '15px', display: "flex" }} >
        <Grid item xs={12} style={{ textAlign: "left" }} >
          <Typography variant="h5" color="primary" >
            Die Betragsgrenzen für die Steuer- und SV-Freiheit bei der bAV <br />
            <b>§ 3 (63) EStG</b>
          </Typography>
          <Typography variant='h5'>
            Du kannst Dir sicherlich denken, dass eine Steuer- und SV-Freiheit immer an Grenzen und Bedingungen gebunden ist.<br />
            Und deshalb gibt es auch für die bAV Grenzen und Bedingungen für die Steuer- und SV-Freiheit.<br />
            Zwei maßgebliche Bedingungen sind zu beachten:
          </Typography>

          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                1.
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Sozialversicherungspflichtiges Beschäftigungsverhältnis
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                2.
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Nur im ersten Beschäftigungsverhältnis ist eine bAV möglich. Bei allen weiteren, parallelen Beschäftigungsverhältnisse ist eine bAV nicht möglich. <br />
                Bei Arbeitnehmern, die mit Steuerklasse 6 abgerechnet werden, ist eine bAV demnach nicht möglich.
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
          <Typography variant='h5'>
            Die Grenze der bAV für die Steuer- und SV-Freiheit ist
          </Typography>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                <b>4 % der jährlichen Beitragsbemessungsgrenze West der Rentenversicherung</b><br />
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ textAlign: "left", marginLeft: einrueck }} >
          <Tabelle2spaltig
            anzReihen="3"
            ueberschrift="Ermittlung der Jahres-Freibetragsgrenze für 2023"
            uebschr_spalte1="Bezeichnung"
            spalte1Platz="left"
            uebschr_spalte2="Wert"
            spalte2Platz="right"
            spalten1={["Beitragsbemessungsgrenze West der Rentenversicherung", "4% von 87600,00 = SV-Freibetrag für die bAV", "weitere 4% (also 8%) von 87600,00 = Steuerfreibetrag für die bAV"]}
            spalten2={["87600,00", "3504,00", "7008,00"]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>


        <Grid item xs={12} style={{ textAlign: "left", marginTop: "30px" }} >
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Es spielt keine Rolle, ob der Betrag in einem Monat vollständig oder in mehreren Monaten teilweise verwendet wird.
                Somit kann auch 1/12 pro Monat monatlich in eine oder mehrere bAV's steuer- und sv-frei eingezahlt werden.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Die Grenze gilt pro erstes Beschäftigungsverhältnis pro Jahr.<br />
                Das bedeutet, dass ein Arbeitnehmer, der innnerhalb eines Jahres sein Beschäftigungsverhältnis wechselt, zweimal die Grenzen zur bAV anwenden kann.
                Wichtig dabei ist, dass es sich jedesmal um sein erstes Beschäftigungsverhältnis handeln muss.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginLeft: einrueck }}>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                <Typography variant="h5">
                  Man kann auch gleichzeitig mehrere Durchführungswege bedienen.<br />
                  Solange die Summe der Beträge aller zu bedienenden Durchführungswege nicht höher ist, als 4% der BBG-RV West, greift SV-Freiheit. <br />
                  Solange die Summe der Beträge aller zu bedienenden Durchführungswege nicht höher ist, als 8% der BBG-RV West, greift die Steuer-Freiheit. <br />
                </Typography>
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{ marginTop: '30px', display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }} >
        <Grid >
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6" }}>
                R
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h5">
                Eigenrecherche im Lexikon für das Lohnbüro
              </Typography>
              <Typography style={{ textAlign: "left" }}>
                Mit Klick auf den Button gelangst Du auf das digitale Lexikon für das Lohnbüro. <br />
                Benutze das Lexikon, um Dich auf dem Gebiet der betrieblichen Altersvorsorge schlau zu machen.<br />
                Mit dem Stichwort "Zukunftssicherung" steht Dir reichlich Informationsmaterial im Lexikon zur Verfügung.<br />
              </Typography>
              <Button
                color="primary"
                variant="contained"
                alignItems="center"
                backgroundColor="#4F4F4F"
                style={{ align: "center" }}
                onClick={() => LexikonLohnbüro()}
              >
                Lexikon für das Lohnbüro
              </Button>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '40px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "60px", height: "60px" }}>
                <CalculateIcon style={{ width: "50px", height: "50px" }}></CalculateIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                <strong>Lege am besten gleich los und werfe Dein ganzes angeeignetes Wissen mit in die Waagschale und löse folgende Aufgaben:</strong><br />
              </Typography>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
        <Grid item xs={12} >
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                Beispiel 1<br />
                In 2023 nützt ein Arbeitnehmer gleichzeitig folgende Durchführungswege als bAV für den Aufbau seiner späteren Rente: <br />
                Der Arbeitgeber bezuschusst den Durchführungsweg der Pensionskasse mit 20%. Der Rest ist Arbeitnehmerfinanziert.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ marginLeft: "40px", backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                <ForwardIcon></ForwardIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                Pensionskasse mntl. 100,- Euro<br />
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ marginLeft: "40px", backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                <ForwardIcon></ForwardIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                Direktversicherung mntl. 100,- Euro<br />
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem >
            <ListItemAvatar>
              <Avatar style={{ marginLeft: "40px", backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
                <ForwardIcon></ForwardIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" style={{ marginLeft: "10px" }}>
                Pensionsfond mntl. 80,- Euro<br />
              </Typography>
            </ListItemText>
          </ListItem>

        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={3}
            tabellenKey="1"
            Lohnart={['1029', '0760']}
            Intern={['029', '760']}
            Bezeichnung={["Gehalt", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00']}
            Stunden={["174,00", '0,00']}
            Faktor={[gehalt, ""]}
            Kennung={["LFD", 'LFD']}
            Prozent={["0,00", '0,00']}
            Steuerpflichtig={[gehalt, "", ""]}
            Sozialverspflichtig={[gehalt, "", ""]}
            Betrag={[gehalt, "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={4}
            tabellenKey="1"
            text=''
            NLohnart={['0761', '2301', '2301', '2301']}
            NIntern={['761', '301', '301', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bez.Pensionska", "Bez.Direktvers", "Bez.Pensionsfo"]}
            NBetrag={["", "", "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '30px' }} justifyContent={"left"} alignItems={"left"} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
              <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              Beispiel 2<br />
              Ein anderer Arbeitnehmer zahlt einmal pro Jahr 4000,- als bAV in einen Pensionsfond ein. Der Arbeitgeberzuschuss beträgt 15%.<br />
              Damit die Zahlung den Arbeitnehmer nicht so stark finanziell belastet, bedient der Arbeitnehmer in dem Monat seine bAV, in welchem er eine Sonderzahlung in Höhe von 4000,- Euro bekommt. <br />
              Wie erfolgt nun der steuer- und beitragsrechtliche Ausweis der bAV auf der Entgeltabrechnung unter Berücksichtigung der Grenzen für Steuer- und SV-Freiheit?
            </Typography>
          </ListItemText>
        </ListItem>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={4}
            tabellenKey="2"
            Lohnart={['1029', '1310', '0760']}
            Intern={['029', '310', '760']}
            Bezeichnung={["Gehalt", "Sonderzahlung", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00', '0,00']}
            Stunden={["174,00", '0,00', '0,00']}
            Faktor={[gehalt, "4000,00", ""]}
            Kennung={["LFD", 'EGA', 'LFD']}
            Prozent={["0,00", '0,00', '0,00']}
            Steuerpflichtig={[gehalt, "4000,00", "", ""]}
            Sozialverspflichtig={[gehalt, "4000,00", "", ""]}
            Betrag={[gehalt, "4000,00", "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={2}
            tabellenKey="2"
            text=''
            NLohnart={['0761', '2301']}
            NIntern={['761', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bez.Pensionsfo"]}
            NBetrag={["", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '50px' }} justifyContent={"left"} alignItems={"left"} >
        <ListItem >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#1fbba6", width: "40px", height: "40px" }}>
              <FeedIcon style={{ width: "35px", height: "35px" }}></FeedIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              Beispiel 3<br />
              Ein weiterer Arbeitnehmer zahlt zweimal pro Jahr 1800,- als bAV in eine Direktversicherung nach neuem Recht gem. § 3 (63) EStG ein.
              Es gibt Direktversicherungen nach altem Recht gem. § 40b EStG. Deshalb wird hier explizit erwähnt, dass es sich um eine Direktversicherung nach neuem Recht handelt,
              welche steuer- und sv-rechtlich gleich behandelt wird, wie eine Pensionskasse oder ein Pensionsfond.
              Die Direktversicherung nach altem Recht wird in einem weiteren Kurs separat behandelt.<br />
              Die Zahlung der bAV erfolgt im Juni und im November, jeweils in Verbindung mit Sonderzahlungen, damit die finanzielle Belastung kompensiert wird.
              Der Arbeitgeberzuschuss beträgt jeweils 15%.<br />
            </Typography>
          </ListItemText>
        </ListItem>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV auf der Entgeltabrechnung Juni</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={4}
            tabellenKey="3"
            Lohnart={['1029', '1310', '0760']}
            Intern={['029', '310', '760']}
            Bezeichnung={["Gehalt", "Sonderzahlung", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00', '0,00']}
            Stunden={["174,00", '0,00', '0,00']}
            Faktor={[gehalt, "2000,00", ""]}
            Kennung={["LFD", 'EGA', 'LFD']}
            Prozent={["0,00", '0,00', '0,00']}
            Steuerpflichtig={[gehalt, "2000,00", "", ""]}
            Sozialverspflichtig={[gehalt, "2000,00", "", ""]}
            Betrag={[gehalt, "2000,00", "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={2}
            tabellenKey="3"
            text=''
            NLohnart={['0761', '2301']}
            NIntern={['761', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bez.Direktvers."]}
            NBetrag={["", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "80px" }} >
          <Typography align="left">
            <strong>Steuer- und beitragsrechtlicher Ausweis der bAV auf der Entgeltabrechnung November</strong>
          </Typography>
          <BruttoNeu2
            anzReihen={4}
            tabellenKey="4"
            Lohnart={['1029', '1310', '0760']}
            Intern={['029', '310', '760']}
            Bezeichnung={["Gehalt", "Sonderzahlung", "bAV Eigenant.", "Summen"]}
            Tage={["21,75", '0,00', '0,00']}
            Stunden={["174,00", '0,00', '0,00']}
            Faktor={[gehalt, "2000,00", ""]}
            Kennung={["LFD", 'EGA', 'LFD']}
            Prozent={["0,00", '0,00', '0,00']}
            Steuerpflichtig={[gehalt, "2000,00", "", ""]}
            Sozialverspflichtig={[gehalt, "2000,00", "", ""]}
            Betrag={[gehalt, "2000,00", "", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <NettoLoa2
            anzReihen={2}
            tabellenKey="4"
            text=''
            NLohnart={['0761', '2301']}
            NIntern={['761', '301']}
            NBezeichnung={["bAV AG Zusch.", "Bez.Direktvers."]}
            NBetrag={["", ""]}
            setzeAntworten={setzeAntworten}
            antworten={antworten}
            fehlerliste={fehlerliste}
          />
        </Grid>
      </Grid>

      <Kursabgeben anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />

    </div>
  )
}


export default BAV2;
