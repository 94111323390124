import React, { useState, useEffect } from "react";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";





const CheckBoxSingle = ({ fehlerliste, id, setzeAntworten, label, savedInput= "" }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        switch (savedInput[id]) {
            case true:
                setChecked(true)
                break;
            case false:
                setChecked(false)
                break;
        }
    }, [savedInput[id]])

    const changeCheck = (id) => {
        setzeAntworten(id, !checked)
        setChecked(!checked)
    }

    


    return (
        <>
            <FormControlLabel control={<Checkbox style={{ backgroundColor: fehlerliste[id] === true ? "red" : "", }} error={fehlerliste[id]} checked={checked} id={id} onChange={() => changeCheck(id)} />} labelPlacement="end" label={label} />
        </>
    )
}

const CheckBoxDouble = ({ fehlerliste, id, setzeAntworten, label1, label2, savedInput = "" }) => {

    useEffect(() => {
        setzeAntworten(id, label1 + false)
        setzeAntworten(id, label2 + false)
    }, [])

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);

    useEffect(() => {
        switch (savedInput[id]) {
            case label1 + "true":
                setChecked1(true)
                break;
            case label1 + "false":
                setChecked1(false)
                break;
            case label2 + "true":
                setChecked2(true)
                break;
            case label2 + "false":
                setChecked2(false)
                break;
        }
    }, [savedInput[id]])





    const changeCheck1 = (id) => {
        if (checked1){
            setzeAntworten(id, label2 + !checked1)
        }else {
            setzeAntworten(id, label1 + !checked1)
        }
        
        setChecked1(!checked1)
        setChecked2(false)
    }
    const changeCheck2 = (id) => {
        setzeAntworten(id, label2 + !checked2)
        setChecked2(!checked2)
        setChecked1(false)
    }


    return (
        <>
            <FormControlLabel control={<Checkbox style={{ backgroundColor: fehlerliste[id] === true ? "red" : "", }} error={fehlerliste[id]} checked={checked1} id={id} onChange={() => changeCheck1(id)} />} labelPlacement="end" label={label1} />
            <FormControlLabel control={<Checkbox style={{ backgroundColor: fehlerliste[id] === true ? "red" : "", }} error={fehlerliste[id]} checked={checked2} id={id} onChange={() => changeCheck2(id)} />} labelPlacement="end" label={label2} />

        </>
    )
}

const SimpleTextField = ({ id, placeholder, label, fehlerliste, width, display, float, style, setzeAntworten, savedInput = " " }) => {

    const myStyle = { backgroundColor: fehlerliste[id] === true ? "red" : "", width: width, display: display, float: float }
    const mergedStyle = { ...myStyle, ...style }

    return (
        <>
            <TextField id={id} placeholder={placeholder} label={label} error={fehlerliste[id]} style={mergedStyle} inputProps={{ style: { textAlign: "center" } }} onChange={(event) => setzeAntworten(id, event.target.value)} value={savedInput[id]}>
                {" "}
            </TextField>
        </>
    )

}





export { CheckBoxSingle, CheckBoxDouble, SimpleTextField }