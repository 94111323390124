import React, {useState, useCallback} from 'react';
import {Avatar, Box, Button, Card, CardContent, CardMedia, Container, Typography, FormGroup, FormControlLabel, Checkbox, MenuItem, TextField, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import { VariableCheckBox } from '../Rahmendaten/VariableCheckBox';


function ScheckFE2(props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { accessToken, hsutkey, } = props;
    const [merkmal]=useState('FragenEntgelt2');
    const [antworten,setAntworten]=useState({});
    const [testabgabe, setTestabgabe]=useState(false);
    const [abgabedat, setAbgabedat] = useState('');
    const [abgabezeit, setAbgabezeit] = useState('');
    const [fehlerliste,setFehlerliste]=useState([]);
    const [anzFehler, setAnzFehler]=useState('0')
    const [bemerkungen, setBemerkungen] = useState('');
    const [fragenzaehler, setFragenzaehler]=useState(0)
    const [reactnummer]=useState('814');

    const setRedirect = (path) => {
      navigate(path);
    }

    const setzeAntworten = ( key,value) => {
        let values = Object.assign(antworten);
        values[key] = value;
        setAntworten(values);
        forceUpdate();   
      }
    
      const fehlerAusLesen=(fehler)=>{
        let array=fehler.split(";");
        let fehlerzwis=[]
        array.map(
          // eslint-disable-next-line
          (objekt)=> {
            fehlerzwis[objekt]=true;    
          }
        );
        setFehlerliste(fehlerzwis);
      };
    
    const antwortenSenden = () => {
        forceUpdate();   
        const headers={
          "Authorization": "Bearer "+accessToken,
        }
        const payload={
          "kennung": "kursabgabe",
          "merkmal": merkmal,
          "daten": antworten,
          "reactnummer": reactnummer,
          "frage":fragenzaehler,
          "hsutkey": hsutkey
        } 
        apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
    
      }
    
      const kurseCb = (response) => {
        if (response.status === 200) {
          setTestabgabe(true);
          setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
          setAbgabezeit(response.data.abgabezeit);
          setAnzFehler(response.data.anzfehler);
          setBemerkungen(response.data.besonderheiten);
          fehlerAusLesen(response.data.fehler);

        } else {
          if (response) {
            props.showError(response.data.message);
          } else {
            props.showError('Keine Internetverbindung');
          }
        }
      }

return(
 <div>

 <Briefkopf  
    ueberschrift1="Fragen zur Entgeltabrechnung"
    ueberschrift2="Alles verstanden? Dann beantwortest Du die Fragen im handumdrehen." 
 />

 <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
   <ListItem>
     <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F1
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
            Welche Informationen über einen Mitarbeiter können wir anhand der Rentenversicherungsnummer erkennen?
            </Typography>
          </ListItemText>
        </ListItem>

    {/* <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}> */}
      <VariableCheckBox
        tabellenKey="1" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Anfangesbuchstaben des Geburtsnamen.",
                     "Geburtsdatum.",
                     "Geschlecht.",
                     "Familienstand."]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    {/* </div> */}
    </Grid>


    <Grid style={{marginTop:'10px' }} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
            <Avatar style={{backgroundColor:"#1fbba6"}}>
              F2
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h4">
                Wie ist bei der Beitragsgruppe die Reihenfolge der Sozialversicherungszweige?"
            </Typography>
          </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="2" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Arbeitslosenversicherung, Krankenversicherung, Pflegeversicherung, Rentenversicherung",
                     "Krankenversicherung, Pflegeversicherung, Rentenversicherung, Arbeitslosenversicherung",
                     "Krankenversicherung, Rentenversicherung, Arbeitslosenversicherung, Pflegeversicherung",
                     "Rentenversicherung, Arbeitslosenversicherung, Krankenversicherung, Pflegeversicherung"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />

    </Grid>


    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F3
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wie lautet die Personengruppe für einen normal SV-pflichtigen Beschäftigten?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="3" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["100",
                     "101",
                     "102",
                     "103"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F4
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wie lautet der Versicherungsstatus einer Person mit der Beitragsgruppe 9111?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="4" 
        ueberschrift=""
        anzReihen={4}
        Checktexte={["In der KV freiwillig versicherter Selbstzahler",
                     "In der KV gesetzlich versichert",
                     "In der KV freiwillig versicherter Firmenzahler",
                     "In der KV sozialversicherungsfrei"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F5
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Gibt es Begrenzungen bei der Beitragspflicht?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="5"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Nein, Beiträge sind immer aus voller Höhe des Entgelts zu berechnen",
                     "Ja, Begrenzung auf die Jahresarbeitsentgeltgrenze",
                     "Ja, Begrenzung auf die Beitragsbemessungsgrenze",
                     "Ja, aber nur für Auszubildende",
                     "Ja, aber nur für Personen mit Elterneigenschaft",]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F6
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Gibt es in der Beitragsberechnung Unterschiede zwischen laufendem und einmalig gezahlten Entgelt?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="6"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Nein, Beitragsberechnung erfolgt immer über die volle Höhe des Entgelts",
                     "Ja, bei laufendem Entgelt ist die monatliche Beitragsbemessungsgrenze zu berücksichtigen, bei einmalig gezahlten Entgelt die jährliche Beitragsbemessungsgrenze",
                     "Ja, bei laufendem Entgelt ist die monatliche Beitragsbemessungsgrenze zu berücksichtigen, bei einmalig gezahlten Entgelt die anteilige jährliche Beitragsbemessungsgrenze",
                     "Ja, bei einmalig gezahltem Entgelt ist die monatliche Beitragsbemessungsgrenze zu berücksichtigen, bei laufendem Entgelt die jährliche Beitragsbemessungsgrenze."]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F7
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wann wird eine DEÜV-Meldung erstellt?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="7"
        ueberschrift=""
        anzReihen={5}
        Checktexte={["Bei Eintritt eines Arbeitnehmers",
                     "Bei Wechsel der Krankenkasse",
                     "Bei Wechsel der Steuerklasse",
                     "Bei Austritt eines Arbeitnehmers",
                     "Bei wechsel der Personengruppe"]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F8
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welches Prinzip gilt für die Lohnsteuerberechnung?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="8"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Das Quartalsprinzip - d. h. die Steuer wird generell quartals",
                     "Das Fiktionsprinzip - d. h. die Steuer wird auch dann berechnet, wenn der Arbeitnehmer nur ein Recht auf eine Vergütung hat, diese Zahlung aber nocht nicht bekommen hat.",
                     "Das Zuflussprinzip - d. h. die Steuer wird immer dann berechnet, wenn der Arbeitnehmer die Vergütung tatsächlich bekommt. Ihm also zufließt",
                     "Das Entstehungsprinzip - d. h. die Lohnsteuer wird immer dann berechnet, wenn der Anspruch auf die Zahlung entsteht. Der Zufluss der Zahlung erfolgte noch nicht."]}
        checked={[false,false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F9
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Welche Informationen sind im Tätigkeitsschlüssel zu finden?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="9"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Befristung",
                     "Schulabschluss",
                     "Berufsbezeichnung",
                     "Elterneigenschaft",
                    ]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F10
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Mit welcher Berechnungsformel werden die Monatsstunden in der Regel berechnet?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="10"
        ueberschrift=""
        anzReihen={4}
        Checktexte={["Stunden pro Tag x 5",
                     "Stunden pro Woche x 4,35",
                     "Arbeitstage pro Woche x Arbeitsstunden pro Tag",
                     "21,75 x Arbeitsstunden pro Tag",
                    ]}
        checked={[false,false,false, false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px', backgroundColor: '#cfe8fc'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F11
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Woran erkennt man auf der DEÜV-Meldung, ob es sich um einen Auszubildenden handelt?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="11"
        ueberschrift=""
        anzReihen={3}
        Checktexte={["am Tätigkeitsschlüssel",
                     "an der Personengruppe",
                     "an der Beitragsgruppe",
                    ]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>

    <Grid style={{marginTop:'10px'}} justifyContent={"left"} align="left" >
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:"#1fbba6"}}>
            F12
          </Avatar> 
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h4">
              Wo findet man im SCHECK-Programm mandantenspezifische Besonderheiten, die bei der Bearbeitung zu berücksichtigen sind?"
          </Typography>
        </ListItemText>
      </ListItem>

      <VariableCheckBox
        tabellenKey="12"
        ueberschrift=""
        anzReihen={3}
        Checktexte={["In den Terminaufgaben",
                     "Im Lohnprotokoll",
                     "In den Verarbeitungsläufen",
                    ]}
        checked={[false,false,false]}
        setzeAntworten={setzeAntworten} 
        antworten={antworten} 
        fehlerliste={fehlerliste}
      />
    </Grid>


    <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal} />
    </div>
    )
}
export default ScheckFE2;
