import React, {useState, useCallback, useEffect, Fragment} from 'react';
import { Button, TextField, Typography, Grid, Paper} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
const acadjpg = '/media/academy.jpg';
import { useMessage } from '../../context/message-context';

import "../../../node_modules/video-react/dist/video-react.css"


function Eignungstest3(props) {
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [testabgabe, setTestabgabe]=useState(false);
  const [zeit, setZeit] = useState();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [antworten, setAntworten] = useState({});
  const [uhrzeit,] = useState(new Date().toLocaleTimeString('de-DE'));  
  const heute = new Date().toLocaleDateString('de-DE');
  const [okay, setOkay] = useState(true);
  const [abgabedat, setAbgabedat] = useState('')
  const [abgabezeit, setAbgabezeit] = useState('')
  const {showErrorMessage} = useMessage();
  const [bereitsabgabe, setBereitsabgabe]=useState(false);
  const [zwangabgabe, setZwangabgabe]=useState(false);

  const setRedirect = (path) => {
    navigate(path);
  }
  
  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();   
  }

  useEffect(() => {
    setOkay(false);
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "sperren",
      "merkmal": "Eignung3",
      "reactnummer": "011",
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'eignungstest', payload, headers, holeDatenCb, setRedirect);
    //eslint-disable-next-line
  }, [])

  const holeDatenCb = (response) => {   
    if (response.status === 200) {
      var zeitlimit = 900;
      setZeit(zeitlimit);
      if (response.data.Eignung3_zeitstempel !== 0) {
        var zwiszeit = response.data.Eignung3_zeitstempel;

        var startzeit = parseInt(zwiszeit.slice(0,2))*60*60
                    + parseInt(zwiszeit.slice(3,5))*60
                    + parseInt(zwiszeit.slice(6,8));

                    var endzeit = parseInt(uhrzeit.slice(0,2))*60*60
                    + parseInt(uhrzeit.slice(3,5))*60
                    + parseInt(uhrzeit.slice(6,8));

                    var differenz =  endzeit-startzeit; 

        if (differenz < 0) {
          differenz = 0;
        }
        zwiszeit = zeitlimit - differenz
        
        setZeit(zwiszeit);
        if (zwiszeit > 0) {
          setOkay(true);
        }
        if (response.data.Eignung3_tagesdat === "FALSE") {
          setOkay(false);
          setZeit(0);
        }

      }

      if (response.data.Eignung3_abgabedat !== '') {
        setAbgabedat(response.data.Eignung3_abgabedat.slice(6,8)+"."+response.data.Eignung3_abgabedat.slice(4,6)+"."+response.data.Eignung3_abgabedat.slice(0,4));
        setAbgabezeit(response.data.Eignung3_abgabezeit);
        setZeit(0);
        setOkay(false);
        setTestabgabe(true);
      }
    } else {
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage('Keine Internetverbindung');
      }
    }
  }

  //eslint-disable-next-line
  useEffect(() => {
    const timer=setTimeout(() => {
        setZeit(zeit-1);
    }, 1000);
    if (zeit < 0) {
      setOkay(false);
      if (testabgabe === false) {
          antwortenSenden()
          setZwangabgabe(true);
      };
      clearTimeout(timer);
    } 
  return () => clearTimeout(timer);
  });

  const antwortenSenden = () => {
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "testabgabe",
      "merkmal": "Eignung3",
      "daten": antworten,
      "reactnummer": "011",
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'eignungstest', payload, headers, kurseCb, setRedirect);
}

const kurseCb = (response) => {
  if (response.status === 200) {
    setTestabgabe(true);
    setOkay(false);
    setZeit(0);
    setAbgabedat(response.data.Eignung3_abgabedat.slice(6,8)+"."+response.data.Eignung3_abgabedat.slice(4,6)+"."+response.data.Eignung3_abgabedat.slice(0,4));
    setAbgabezeit(response.data.Eignung3_abgabezeit);
    if (response.data.Eignung3_bereitsabgabe === "ja") {setBereitsabgabe(true)}
  } else {
    if (response) {
      showErrorMessage(response.data.message);
    } else {
      showErrorMessage('Keine Internetverbindung');
    }
  }
}

  function unterstrichen(buchstabe){
    return(
      <div style={{display:"inline-block",textDecoration:"underline",fontWeight:"bold"}}>{buchstabe}</div>
    );
  }
  return(
    <div>
      <Paper>
        <Grid container spacing={1} style={{marginTop:'10px'}} >
          <Grid item>
              <img src={acadjpg} height="150" alt="ectool" loading="lazy"/>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                  <Typography variant="h3" color="primary">Eignungstest</Typography> 
                  <Typography color="error">
                      Der Test kann nur einmal aktiviert werden.<br />
                      Das Schließen des Fensters, ohne vorher den Test abzugeben, wird als Testabbruch gewertet.<br />
                      Der Test wird mit dem Anklicken des Buttons "Test abgeben" abgegeben. <br /> <br />
                  </Typography>
                    <h5>Verbleibende Zeit: </h5>
                  {zeit>0 ? 
                    <h5>{
                       Math.floor((zeit / 60) % 60)+' Minuten ' + Math.floor((zeit) % 60)+' Sekunden'
                      }</h5> 
                      : 
                      bereitsabgabe && !zwangabgabe?
                        <h4>
                          Der Test wurde bereits abgegeben
                        </h4> 
                      :
                      bereitsabgabe && zwangabgabe ?
                        <h4>
                          Die Zeit ist abgelaufen! Dein Test wurde an ectool übermittelt!
                        </h4> 
                      :
                      <h4>
                        Test erfolgreich abgegeben
                      </h4> 
                      }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>


      {(okay === true) && 
      <div>
      <Grid container spacing={0} justifyContent={"center"} style={{marginTop:'30px'}} >
      <Grid item xs={8}>
          <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}><h6>Aufgabenstellung:</h6></Typography> <div style={{display:"inline-block",width:"94%"}}></div>
          <Typography style={{display:"inline-block",width:"100%",textAlign:"left"}}>Stelle die Abweichungen in den Adressdaten fest und tragen Sie die Anzahl der Abweichungen ein. Bitte beachten Sie, dass jede einzelne Abweichungen zu zählen ist und in einem Wort mehrfach Abweichungen vorkommen können.</Typography><div style={{display:"inline-block",width:"25%"}}></div>
          <Typography varian="h6"style={{textAlign:"right",textDecoration:"underline", display:"inline-block",width:"80%"}}>Abweichungen</Typography>
          <Typography style={{textAlign:"left", display:"inline-block",width:"100%"}}><h6>Beispiel:</h6></Typography>
          <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Dr. Luna Logan<br/> Goldregenweg 92 <br/> 56244 Freilingen </Typography><div style={{display:"inline-block",width:"25%"}}></div>
          <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Dr. Luna{unterstrichen("r")} Logan<br/> Goldr{unterstrichen("ä")}genw{unterstrichen("ä")}g 92 <br/> 56{unterstrichen("3")}4{unterstrichen("6")} Freil{unterstrichen("l")}ingen</Typography>
          <TextField style={{width:"20%",display:"", textAlign:"center"}} disabled="true" variant="outlined" value="6" label="Anzahl Abweichungen"></TextField>
          </Grid>
        <Grid item xs={8} style={{marginTop:"35px"}}>
          <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.1</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
          <div style={{border:"solid",borderColor:"#1fbba6", width:"100%",marginTop:""}}></div>
          <div style={{width:"5%",display:"inline-block"}}></div>
          <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Prof. Birgit Hall <br/> Stolvergstr. 55 <br/> 56244 Freilingen</Typography><div style={{display:"inline-block",width:"25%"}}></div>
          <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Prof. Birgitt Hall <br/> Stolwergstr. 55 <br/> 56244 Freillingen</Typography>
          <TextField style={{textAlign:"center",width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F1',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.2</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Melina Andreas <br/> Offerkämpe 110 <br/> 67169 Kallstadt</Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Melina Andres <br/> Offerkämpe 111 <br/> 67169 Kallstadt</Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F2',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.3</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Isabel Tran <br/> Wolbecker Str. 31 <br/> 55608 Schneppenbach</Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Isabel Tran <br/> Wolbecker Str. 31 <br/> 55508 Schneppenbach</Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F3',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.4</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Dr. Christof Bruder <br/> Am Römerhof 151 <br/> 96197 Wonsees</Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Dr. Christoph Bruder <br/> Am Römerhoft 151 <br/> 96199 Wonses</Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F4',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.5</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Claus Lewis <br/> Am Burloh 131 <br/> 54646 Stockem</Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Claus Levis <br/> Am Burloh 131 <br/> 54646 Stockem</Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F5',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.6</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Käthe Liebig <br/> Wiedehagen 29 <br/> 94569 Stephansposching</Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Käthe Liebiq <br/> Wiedhagen 29 <br/> 94569 Stephanposching </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F6',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.7</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Ralph Brück <br/> Im Sonnentau 17 <br/> 54483 Kleinich </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Ralph Brück <br/> Im Sonnentau 17 <br/> 54483 Kleinich </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F7',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.8</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Madeleine Bühler <br/> Schiffahrter Damm 41 <br/> 37327 Leinefelde-Worbis </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Madleine Buehler <br/> Schifffahrter Dam 41 <br/> 37337 Leinefeld-Worbis </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F8',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.9</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Renate Moos <br/> Dahlienweg 90 <br/> 1734 Rabenau </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Renate Moos <br/> Dalienweg 90 <br/> 1734 Rabenau </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F9',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.10</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Emilia Bender <br/> Kröver Str. 141 <br/> 42289 Wuppertal </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Emilya Bender <br/> Kröwer Str. 141 <br/> 42289 Wupperthal </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F10',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.11</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Dr. Melvin McGee <br/> Am Burloh 84a <br/> 96123 Litzendorf </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Dr. Melvin McGee <br/> Am Burloh 84a <br/> 94123 Litzendorf </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F11',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.12</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Kira Gomez <br/> Von-Holte-Str. 83 <br/> 10315 Berlin </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Kira Gomez <br/> Von-Hohlte-Str. 83 <br/> 10315 Berlin </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F12',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.13</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Rieke Cao <br/> Am Oedingteich 43 <br/> 54316 Lampaden </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Ricke Cao <br/> An Ödingteich 43 <br/> 54316 Lampaden </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F13',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.14</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Luna Bischoff <br/> Rincklakeweg 123 <br/> 67317 Altleiningen </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Frau Luna Bischoff <br/> Rinklakeweg 123 <br/> 37317 Altlehningen </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F14',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.15</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Prof. Colin Franke <br/> Hillscheider Weg 157 <br/> 25557 Bendorf </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Prof. Colin Franke <br/> Hilfscheider Weg 157 <br/> 25557 Bändorf </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F15',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.16</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Siegfried Cunningham <br/> Gremmendorfer Weg 106 <br/> 80337 München </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Sigfrid Cuningham <br/> Gremmendorfer Weg 107 <br/> 80337 München </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F16',event.target.value)} ></TextField>
          </Grid>
          <Grid item xs={8} style={{marginTop:"15px"}}>
            <Typography style={{textAling:"left",width:"5%",display:"inline-block"}}>Nr.17</Typography> <div style={{display:"inline-block",width:"94%"}}></div>
            <div style={{border:"solid",borderColor:"#1fbba6",width:"100%",marginTop:""}}></div>
            <div style={{width:"5%",display:"inline-block"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Kilian Zell <br/> Kurze Str. 26 <br/> 55590 Meisenheim </Typography><div style={{display:"inline-block",width:"25%"}}></div>
            <Typography style={{display:"inline-block",width:"25%",textAlign:"left"}}>Herr Kilian Cell <br/> Kunze Str. 26 <br/> 55590 Meißenheim </Typography>
            <TextField style={{width:"20%",marginTop:"10px" ,height:"50px"}} variant="outlined" InputLabelProps={{shrink:true}} label="Abweichungen eingeben"onChange={(event) => setzeAntworten('F17',event.target.value)} ></TextField>
          </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" style={{marginTop:"15px"}}>
      <Grid item xs={4}>
      <Button 
                fullWidth 
                color="primary" 
                variant="contained"
                onClick={() => antwortenSenden()}          
                >
                Test Abgeben
              </Button>
      </Grid>

    </Grid>
    </div>
  }

  {(testabgabe === true) && 
      <Fragment>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
          {zwangabgabe & !bereitsabgabe && 
                <Typography variant="h6">
                  Dein 3.Test wurde wegen der Zeitüberschreitung automatisch am {abgabedat} um {abgabezeit} Uhr abgegeben. <br /> <br />
                </Typography>
              }

              {!zwangabgabe & bereitsabgabe && 
                <Typography variant="h6">
                  Dein 3.Test wurde bereits am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br /> <br />
                </Typography>
              }

              {!zwangabgabe & !bereitsabgabe && 
                <Typography variant="h6" >
                  <br /> Geschafft!!! <br /> <br />
                  Dein 3.Test wurde am {abgabedat} um {abgabezeit} Uhr bei ectool abgegeben. <br />
                  Somit bist Du nun am Ende der Testreihe angelangt. <br /> <br />
                </Typography>
              }

              <Typography variant="h6">
                Dein Test wird zeitnah ausgewertet. <br />
                Im Anschluß daran setzen wir uns mit Dir per E-Mail in Verbindung. <br />
              </Typography>
          </Grid>   
        </Grid>
      </Fragment>
  }
  </div>

  )
}

export default Eignungstest3;