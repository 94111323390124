import React, {useState, useCallback} from 'react';
import {Button, Grid, ListItem, ListItemAvatar, ListItemText, Avatar, Typography} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { Link, useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
import {Kursabgeben} from '../../components/Rahmendaten/Kursabgeben';
import {Briefkopf} from '../../components/Rahmendaten/Briefkopf';
import {PersonengruppenAuswahl} from '../../components/Rahmendaten/PersonengruppenAuswahl';
import {BeitragsgruppenAuswahl} from '../../components/Rahmendaten/BeitragsgruppenAuswahl';
import {DEBUG_FLAG} from "../../constants/debug";

function SVModelleeccheck(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [,  updateState] = useState();
  const { accessToken, hsutkey } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [testabgabe, setTestabgabe]=useState(false);
  const [abgabedat, setAbgabedat] = useState('');
  const [abgabezeit, setAbgabezeit] = useState('');
  const [fehlerliste,setFehlerliste]=useState([]);
  const {showErrorMessage} = useMessage();
  const [anzFehler, setAnzFehler]=useState('99');
  const [bemerkungen, setBemerkungen] = useState('');
  const [kvk,setKvk]=useState('0');
  const [rvk,setRvk]=useState('0');
  const [avk,setAvk]=useState('0');
  const [pvk,setPvk]=useState('0');
  const [reactnummer]=useState('038');
  const [merkmal]=useState("SVModelle_eccheck");

  const internetseite = DEBUG_FLAG ? "http://localhost:3000/#/interview/IVECSV" : "https://www.ecschool.de/#/interview/IVECSV"

  const setzeAntworten = ( key,value) => {
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  }
  
  const setRedirect = (path) => {
      navigate(path);
  }
  

  const fehlerAusLesen=(fehler,anzahlFehler)=>{
    let array=fehler.split(";");
    let fehlerzwis=[]
    array.map(
      // eslint-disable-next-line
      (objekt)=> {
        fehlerzwis[objekt]=true;
      }
    );
    setFehlerliste(fehlerzwis)
  };


  const antwortenSenden = () => {

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
       if (response.status === 200) {
         setTestabgabe(true);
         fehlerAusLesen(response.data.fehler,response.data.anzfehler)
         setAbgabedat(response.data.abgabedat.slice(6,8)+"."+response.data.abgabedat.slice(4,6)+"."+response.data.abgabedat.slice(0,4));
         setAbgabezeit(response.data.abgabezeit);
         setAnzFehler(response.data.anzfehler);
         setBemerkungen(response.data.besonderheiten);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }

    return(
      <div>
        
        <Briefkopf  
            ueberschrift1="eccheck - hilft dir bei der Bestimmung der richtigen Personen- und Beitragsgruppe" 
            ueberschrift2="" 
        />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  H1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h6">
                  In dem Kurs "Beschäftigungsmodelle in der SV" hast du die Anwendung von ein paar Personen- und Beitragsgruppen gelernt. <br/>
                  Es gibt natürlich noch weitaus mehr unterschiedliche Kombinationen von Personen- und Beitragsgruppen.<br/>
                  Die unterschiedlichen zu beachtenden Kriterien alle auswendig zu erlernen ist die eine Möglichkeit. <br/> 
                  Die andere Möglichkeit ist, wenn du dir mit eccheck-SV dabei helfen lässt, die richtige Personen- und Beitragsgruppen zu bestimmen. <br/>
                  Wobei eccheck-SV dir nicht bei allen, aber einem Großteil von Kombinationen helfen kann. 
                </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  H2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h6">
                  In den Geringfügigkeitsrichtlinien sind die unterschiedlichen zu beachtenden Kriterien zusammen mit dem rechtlichen Hintergrund dargestellt. < br/>
                  Du kannst dir gerne die aktuelle Version der Geringfügigkeitsrichtlinien von der Homepage der Minijobzentrale herunterladen und dir insbesondere die Beispiele genauer anschauen. < br/>
                  Die Beispiele zeigen auf, dass die richtige Verwendung der Personen- und Beitragsgruppe je Beschäftigungsmodell an bestimmten Merkmalen und Kriterien dingfest gemacht werden.
                </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  I
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h6">
                  Lass dir für die Beurteilung der Personen- und Beitragsgruppe bei den Aufgaben jeweils vom Interview helfen.<br/>
                  Gerne kannst Du auch in den Geringfügigkeitsrichtlinien die Lösung eines vergleichbaren Beispiels recherchieren.
                </Typography>
              </ListItemText> 
            </ListItem>
          </Grid>
  
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={8} style={{marginBottom:"30px"}}>
              <Button
                  disabled={false} 
                  color="primary"  
                  variant="contained"
                  fullWidth
                  href={internetseite}
                  target="_blank"
                  >
                  eccheck-SV Interview starten
              </Button>
          </Grid>
          <Grid item xs={2}>
          </Grid>
 
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A1
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 1</b><br/>
                  Eine Hausfrau nimmt am 01.12.20 eine Beschäftigung als Aushilfsverkäuferin gegen ein monatliches Arbeitsentgelt von 900 Euro auf. <br/>
                  Die Beschäftigung ist von vornherein bis zum 28.02.21 des Folgejahres befristet. <br/>
                  Die Arbeitszeit ist an 5 Tagen in der Woche zu je 6 Stunden pro Tag.<br/>
                  Die Hausfrau hat in dem Jahr der zu beurteilenden Beschäftigung bereits vom 01.07.20 bis zum 31.08.20 eine Beschäftigung ausgeübt. 
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="1"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="1"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A2
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 2</b><br/>
                    Eine Hausfrau nimmt am 03.05.2021 eine Beschäftigung als Aushilfsverkäuferin (Urlaubsvertretung) auf, die von vornherein bis zum 08.07.2021 befristet ist und wöchentlich
                    sechs Arbeitstage zu je 6 Stunden pro Tag umfassen soll. <br/>
                    Ihr Stundenlohn beträgt 13,- Euro. Somit beläuft sich ihr durchschnittlicher Monatsverdienst auf 1508,- Euro.<br/>
                    Die Hausfrau war im laufenden Kalenderjahr wie folgt beschäftigt:<br/>
                    a) vom 02.01. bis 25.01.<br/>
                    b) vom 31.03. bis 15.04.
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="2"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="2"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A3
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 3</b><br/>
                    Eine Schülerin macht in den Sommerferien einen Ferienjob vom 01.08.2021 bis 31.08.2021 an 5 Tagen die Woche.<br/>
                    Für den Ferienjob bekommt die Schülerin ein Arbeitsentgelt in Höhe von 1700,- Euro. <br/>
                    Vorherige Beschäftigungen übte die Schülerin nicht aus. 
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="3"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="3"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A4
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 4</b><br/>
                    Eine Schulabgängerin mit Studienabsicht arbeitet als Bürokraft an 5-Tagen die Woche für die Zeit vom 01.06.2021 bis 31.08.2021.<br/>
                    Ihr monatliches Arbeitsentgelt ist 1900,- Euro.
                    In der Zeit vom 01.01. bis 31.05. konzentrierte sich die Abiturientin voll und ganz auf die Schule und arbeitete somit nicht. 
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="4"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="4"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A5
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 5</b><br/>
                    Hans arbeitet nach Abschluss der mittleren Reife für die Zeit vom 01.06.2021 bis 31.07.2021 an 4-Tagen die Woche in einer Produktionsfirma,
                    um sich das Geld für einen im August stattfindenden Urlaub zu verdienen. <br/>
                    Etwaige Vorbeschäftigungen hatte Hans nicht.<br/>
                    Am 01.09.2021 beginnt Hans eine Ausbildung als Schreiner in einer anderen Firma.
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="5"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="5"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>


        <Grid container spacing={0} style={{marginTop:'40px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <ListItem style={{marginTop:"10px"}}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  A6
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                  <b>Aufgabe 6</b><br/>
                    Ein Bezieher von Arbeitslosengeld wird als Kellner für die Zeit vom 01.08.21 bis 20.08.21 gegen ein Arbeitsentgelt von 650,- Euro und einer wöchentlichen Arbeitszeit von 2 Tagen und 7 Stunden pro Tag beschäftigt.
              </ListItemText> 
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <PersonengruppenAuswahl
                tabellenKey="6"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
          <Grid item xs={12}>
            <BeitragsgruppenAuswahl
                tabellenKey="6"
                setzeAntworten={setzeAntworten} 
                antworten={antworten} 
                fehlerliste={fehlerliste}
            />
          </Grid>
        </Grid>




        <Kursabgeben  anzahlFehler={anzFehler} antwortenSenden={antwortenSenden} testabgabeken={testabgabe} abgabedat={abgabedat} abgabezeit={abgabezeit} bemerkungen={bemerkungen} reactnummer={reactnummer} merkmal={merkmal}/>

      </div>
    )
  }
  

export default SVModelleeccheck;
