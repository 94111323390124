import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe } from '../Rahmendaten/Eingaben';



function Erstattungsantrag (props) {
  const { tabellenKey, fehlerliste, setzeAntworten,antworten, kkname, kkstrasse, kkort, brutto, netto, kalnetto, zuschuss, erstattung, entbindung, vonerstattung, biserstattung} = props;

   if (vonerstattung !== '') {
    antworten["vonerstattung"+tabellenKey]=vonerstattung;
    var vonerstattungblockiert=true;
  }

  if (biserstattung !== '') {
    antworten["biserstattung"+tabellenKey]=biserstattung;
    var biserstattungblockiert=true;
  }

  if (kkname !== '') {
    antworten["kkname"+tabellenKey]=kkname;
    var kknameblockiert=true;
  }

  if (kkstrasse !== '') {
    antworten["kkstrasse"+tabellenKey]=kkstrasse;
    var kkstrasseblockiert=true;
  }

  if (kkort !== '') {
    antworten["kkort"+tabellenKey]=kkort;
    var kkortblockiert=true;
  }

  if (brutto !== '') {
    antworten["brutto"+tabellenKey]=brutto;
    var bruttoblockiert=true;
  }

  if (netto !== '') {
    antworten["netto"+tabellenKey]=netto;
    var nettoblockiert=true;
  }

  if (kalnetto !== '') {
    antworten["kalnetto"+tabellenKey]=kalnetto;
    var kalnettoblockiert=true;
  }

  if (zuschuss !== '') {
    antworten["zuschuss"+tabellenKey]=zuschuss;
    var zuschussblockiert=true;
  }

  if (erstattung !== '') {
    antworten["erstattung"+tabellenKey]=erstattung;
    var erstattungblockiert=true;
  }

  if (entbindung !== '') {
    antworten["entbindung"+tabellenKey]=entbindung;
    var entbindungblockiert=true;
  }


  return(
    <div>
    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>     
        <Grid item xs={12} style={{marginTop:'30px'}} >
            <Typography variant="h5"> 
                Erstattung der AG-Aufwendungen nach dem Lohnfortzahlungsgesetz (LFZG)
            </Typography>
        </Grid>
    </Grid>

    <Grid container spacing={0} xs={8} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>

        <TableContainer>
            <Table border="solid">
                <TableHead>
                    <TableRow >
                        <TableCell style={{textAlign:"left"}}>Krankenkasse</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell style={{textAlign:"left"}}>
                            <Eingabe 
                                text="true"
                                disabled={kknameblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["kkname"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kkname"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["kkname"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('kkname'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow >

                    <TableRow >
                        <TableCell style={{textAlign:"left"}}>
                            <Eingabe 
                                text="true"
                                disabled={kkstrasseblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["kkstrasse"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kkstrasse"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["kkstrasse"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('kkstrasse'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left"}}>
                            <Eingabe 
                                text="true"
                                disabled={kkortblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["kkort"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kkort"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["kkort"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('kkort'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>


    <Grid container spacing={0} xs={12} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>     
        <TableContainer>
            <Table border="solid">
                <TableHead>
                    <TableRow >
                        <TableCell style={{textAlign:"left"}}>Angaben zur Erstattung</TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>
        <TableContainer>
            <Table >
                <TableBody>
                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Grund der Abgabe
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                        <Typography >
                                Erstattung der Aufwendungen bei Mutterschaft
                            </Typography>
                        </TableCell>
                    </TableRow >

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Erstattungszeitraum von: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                datum="true"
                                disabled={vonerstattungblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["vonerstattung"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["vonerstattung"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["vonerstattung"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('vonerstattung'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow >

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Erstattungszeitraum bis: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                        <Eingabe 
                                datum="true"
                                disabled={biserstattungblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["biserstattung"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["biserstattung"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["biserstattung"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('biserstattung'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow >

                    <TableRow></TableRow>


                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Höhe des monatlichen Bruttoentgelts: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                disabled={bruttoblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["brutto"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["brutto"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["brutto"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('brutto'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Höhe des monatlichen Nettoentgelts: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                disabled={nettoblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["netto"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["netto"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["netto"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('netto'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Kalendertägliches Nettoarbeitsgelt: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                disabled={kalnettoblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["kalnetto"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kalnetto"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["kalnetto"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('kalnetto'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Zuschuss zum Mutterschaftsgeld bzw. fortgezahltes Bruttoarbeitsentgelt:<br/>
                                (ohne Einmalzahlungen) 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                disabled={zuschussblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["zuschuss"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["zuschuss"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["zuschuss"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('zuschuss'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Erstattungsbetrag (brutto): 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                disabled={erstattungblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["erstattung"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["erstattung"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["erstattung"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('erstattung'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Typography >
                                Mutmaßlicher Entbindungstag: 
                            </Typography>
                        </TableCell>
                        <TableCell style={{textAlign:"left", border:"solid", borderWidth:"1px" }}>
                            <Eingabe 
                                datum="true"
                                disabled={entbindungblockiert}
                                placeholder="Eintrag" 
                                error={fehlerliste["entbindung"+tabellenKey]}     
                                style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["entbindung"+tabellenKey]===true)?"red":""}}
                                inputProps={{style:{textAlign:"center"}}}
                                value={antworten["entbindung"+tabellenKey]||''}
                                onChange={(event) => setzeAntworten('entbindung'+tabellenKey,event.target.value)}
                                >
                            </Eingabe>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
</div>

  )
}


export {Erstattungsantrag};

