import React, {useState,useEffect,} from 'react';
import { useNavigate,useParams } from "react-router-dom";
import apiRequest from '../../functions/api';
import { TextField,Typography,Box,Button,Grid } from "@material-ui/core";
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { useMessage } from "../../context/message-context";
import {useAuth} from '../../context/auth-context'
import {useLocalStorage,} from '../../utils/hooks';
import { DEBUG_FLAG } from '../../constants/debug';
import { LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import { VERSION } from '../../constants/version';
import {
  isBrowser,
  isMobile,
  osName,
  osVersion,
  browserVersion,
  browserName,
  mobileModel
} from "react-device-detect";
import { client } from '../../utils/ApiClient';

function Support(props) {
  let { vonPGM } = useParams();
  const navigate = useNavigate();
  const {user} = useAuth();
  const [programm,setProgramm] = useState(vonPGM);
  const { accessToken,client} = props;
  const [mail, setMail]=useState("");
  const [errmail,setErrMail]=useState("");
  const [erranfrage,setErrAnfrage]=useState("");
  const [anfrage,setAnfrage]=useState("");
  const {hideMessage, showErrorMessage,showSuccessMessage,} = useMessage();
  const [sending,setSending]=useState(false);
  const [deviceId, ] = useLocalStorage(DEBUG_FLAG()?LOCAL_STORAGE_KEY.deviceIdLocal:LOCAL_STORAGE_KEY.deviceId);
  const [username] = useLocalStorage(LOCAL_STORAGE_KEY.username);
 //Laden der E-Mail beim öffnen der Seite
  useEffect(() => {
    if (user) {
      if (user.mail) {
        setMail(user.mail)
      }  
      if (user.stammformular) {
        if (user.stammformular==="J") {
          setProgramm("STAMBO")
        }
      }
    }
    // eslint-disable-next-line
  }, [])
 
  const mailsenden = () => {
    setSending(true);
    var payload={}
      if (isMobile) {
        payload={
          "mail": mail,
          "anfrage":anfrage,
          "vonPGM":programm,
          "username": username,
          "deviceId": deviceId,
          "device": mobileModel,
          "os": osName,
          "osBez": mobileModel+' '+osName+' '+osVersion,
          "clientVersion": VERSION,
          "client":client,
        }
      }

      if (isBrowser) {
        payload={
          "mail": mail,
          "anfrage":anfrage,
          "vonPGM":programm,
          "username": username,
          "deviceId": deviceId,
          "device": browserName+' '+browserVersion,
          "os": osName,
          "osBez": osName+' '+osVersion+' '+browserName+' '+browserVersion,
          "clientVersion": VERSION,
          "client":client,
        }
      }

    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    apiRequest('POST', 'support', payload, headers, mailCb);
  }

  const mailCb = (response) => {
    setSending(false)
    if (response.status === 200) {
        hideMessage();
        showSuccessMessage('Ihre Supportanfrage wurde erfolgreich verschickt.')
        navigate(-1); 
      } else {
        if (response) {
          showErrorMessage(response.data.message);
        } else {
          showErrorMessage('Keine Internetverbindung');
        }
    }      
  }    

  const pruefe_eingabe = (value) => {
    var fehler=0;
     if (anfrage==="") {
      setErrAnfrage("Bitte geben Sie eine Nachricht ein");
      fehler=1
      } else {
      setErrAnfrage("");
      }

      if (mail==="") {
        setErrMail("Bitte geben Sie eine gültige E-Mailadresse ein");
        fehler=1;
      } else {
        if ( !mail.includes("@")  || !mail.includes(".") )  {
          setErrMail("Bitte geben Sie eine gültige E-Mailadresse ein");
          fehler=1
        } else {
          setErrMail("");
        } 
      }
    if (fehler===0) {mailsenden()}
  }

  if (sending) {
    return <LoadingComponent />
  }

  return (
    <div>
        <Box pl={2} pr={2} pt={1} pb={1}>
            <Typography variant="h6">Ihre Nachricht an den ectool Support</Typography>
        </Box>
        <Box p={2} >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField 
                variant="outlined"  
                error={errmail} 
                helperText={!errmail?" ":errmail} 
                fullWidth
                value={mail}
                label="E-Mail Adresse"
                InputLabelProps={{
                  shrink: true, 
                }}
                placeholder={"Bitte geben Sie hier Ihre E-Mail Adresse ein"} 
                onChange={ (event) => setMail(event.target.value)}>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                variant="outlined" 
                fullWidth 
                multiline 
                rows={15}
                value={anfrage}
                label="Anfrage"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={"Bitte geben Sie hier Ihre Anfrage ein"} 
                error={erranfrage} 
                helperText={!erranfrage? " ":erranfrage} 
                onChange={ (event) => setAnfrage(event.target.value)}>
                
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Box pr={1}>
                <Button fullWidth color="primary" onClick={()=>navigate(-1)}>zurück</Button>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box pl={1}>
                <Button variant="contained" color="primary" fullWidth onClick={()=>pruefe_eingabe()}>Senden</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
    </div>
  )
}

function SupportButton(props) {
  const [deviceId, ] = useLocalStorage(LOCAL_STORAGE_KEY?.deviceId);
  const [username] = useLocalStorage(LOCAL_STORAGE_KEY?.username);
 
  return (
    <Button
      style={{align:"center"}}
      href={"mailto:support@ectool.de?subject=Supportanfrage ecschool&body=%0d%0a%0d%0a%0d%0a------------------------------%0d%0aWichtige Informationen für ecschool:%0d%0aBitte hier nichts verändern"
            +"%0d%0aVersion: "+VERSION
            +"%0d%0aDeviceId: "+deviceId
            +"%0d%0aBetriebssystem: "+osName
            +"%0d%0aUsername: "+username
            +"%0d%0aReactNr.: "+props.reactnummer
            +"%0d%0aKursname: "+props.merkmal
            +"%0d%0a------------------------------"}
      color=""
      variant="contained"
      
      >Support kontaktieren
    </Button>
   )
}

export default Support;
export {SupportButton};
