import React from 'react';
import {Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';




function FehlzeitenBlock (props) {
//  const {tabellenKey, fehlerliste, setzeAntworten, antworten, bruttolfd, bruttoega, bruttogesamt, steuern, sozvers, agantAusz, netto} = props;



return(
    <div>

    <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
    

        <Grid item xs={6}>

           <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Bezeichnung</TableCell>
                            <TableCell>Zahlung von Entgelt</TableCell>
                            <TableCell>Kürzung von SV Tagen</TableCell>
                            <TableCell>Besonderheiten</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell >
                                Krank mit Lohnfortzahlung
                            </TableCell>
                            <TableCell >
                               Ja 
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                ---
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell >
                                Krank ohne Lohnfortzahlung (Krankentagegeld)
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                Ja
                            </TableCell>
                            <TableCell >
                                ---
                            </TableCell>
                        </TableRow>
                        <TableRow  >
                            <TableCell >
                                Pflege eines erkrankten Kindes
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                Ja
                            </TableCell>
                            <TableCell >
                                ---
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell >
                               Mutterschutz
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                Ja
                            </TableCell>
                            <TableCell >
                                Zahlung von Mutterschaftsgeld
                            </TableCell>
                        </TableRow>

                        <TableRow  >
                            <TableCell >
                               Beschäftigungsverbot bei werdenden Mütter
                            </TableCell>
                            <TableCell >
                                Ja
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                ---
                            </TableCell>
                            
                        </TableRow>

                        <TableRow >
                            <TableCell >
                                Elternzeit/ Erziehungsurlaub
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                                Ja
                            </TableCell>
                            <TableCell >
                                ---
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell >
                                Unbezahlter Urlaub
                            </TableCell>
                            <TableCell >
                               Nein
                            </TableCell>
                            <TableCell >
                                Ja, wenn unbezahlter Urlaub länger als 1 Monat
                            </TableCell>
                            <TableCell >
                               ---
                            </TableCell>
                            
                        </TableRow>
                        <TableRow  >
                            <TableCell >
                                Bezahlter Urlaub
                            </TableCell>
                            <TableCell >
                               Ja
                            </TableCell>
                            <TableCell >
                                Nein
                            </TableCell>
                            <TableCell >
                               ---
                            </TableCell>
                            
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>

    </div>
 
  )
}

export {FehlzeitenBlock};