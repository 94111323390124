import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Eingabe} from './Eingaben';




function PKW_Rechnung (props) {
  const {tabellenKey, fehlerliste, setzeAntworten, antworten, kdnr, fgnr, datum, rgnr} = props;

    if (kdnr !== '') {
        antworten["kdnr"+tabellenKey]=kdnr;
        var kdnrblockiert=true;
    }

    if (fgnr !== '') {
        antworten["fgnr"+tabellenKey]=fgnr;
        var fgnrblockiert=true;
    }

    if (datum !== '') {
        antworten["datum"+tabellenKey]=datum;
        var datumblockiert=true;
    }

    if (rgnr !== '') {
        antworten["rgnr"+tabellenKey]=rgnr;
        var rgnrblockiert=true;
    }



return(
    <div>
    <Grid container spacing={0} style={{marginTop:'20px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
            <TableContainer style={{marginTop:"10px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableHead>
                        <TableRow  >
                            <TableCell style={{textAlign:"center"}}> KfZ-Rechnung von Auto und Fahrspass GmbH</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={2}></Grid>
    </Grid>


    <Grid container spacing={0} style={{marginTop:'0px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
            <TableContainer style={{marginTop:"0px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:"lightgrey"}}>Kd.-Nummer:</TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={kdnrblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["kdnr"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["kdnr"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["kdnr"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('kdnr'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:"lightgrey"}}>GKV-Nummer:</TableCell>
                                <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={fgnrblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["fgnr"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["fgnr"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["fgnr"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('fgnr'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={4}>
            <TableContainer style={{marginTop:"0px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:"lightgrey"}}>Datum</TableCell>
                            <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={datumblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["datumblockiert"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["datum"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["datum"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('datum'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"center", backgroundColor:"lightgrey"}}>Rechnungsnummer:</TableCell>
                                <TableCell style={{textAlign:"center"}}>
                                <Eingabe 
                                    disabled={rgnrblockiert}
                                    placeholder="Eintrag" 
                                    error={fehlerliste["rgnr"+tabellenKey]}     
                                    style={{display:"inline-block",marginTop:"7px",backgroundColor:(fehlerliste["rgnr"+tabellenKey]===true)?"red":""}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    value={antworten["rgnr"+tabellenKey]||''}
                                    onChange={(event) => setzeAntworten('rgnr'+tabellenKey,event.target.value)}
                                >
                                </Eingabe>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={2}></Grid>

    </Grid>





    <Grid container spacing={0} style={{marginTop:'0px'}} justifyContent={"center"} alignItems={"center"}>

        <Grid item xs={8}>
            <TableContainer style={{marginTop:"0px", border:"solid", borderColor:"#1fbba6", borderWidth:"5px"}}>
                <Table>
                    <TableHead>
                        <TableRow  >
                            <TableCell style={{textAlign:"left"}}>Artikel-Nr.</TableCell>
                            <TableCell style={{textAlign:"left"}}>Artikel-Bezeichnung</TableCell>
                            <TableCell style={{textAlign:"right"}}>Euro</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>CB577SZ</TableCell>
                            <TableCell style={{textAlign:"left"}}>Variant Business 2.0 TDI <br/> 110 KW <br/> 7 Gang-Automatikgetriebe</TableCell>
                            <TableCell style={{textAlign:"right"}}>35.470,48</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>K2KTOL</TableCell>
                            <TableCell style={{textAlign:"left"}}>Pyritsilber Metallic</TableCell>
                            <TableCell style={{textAlign:"right"}}>578,35</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>AFWR</TableCell>
                            <TableCell style={{textAlign:"left"}}>Adaptive Fahrwerksregelung</TableCell>
                            <TableCell style={{textAlign:"right"}}>1.006,69</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>PH47R</TableCell>
                            <TableCell style={{textAlign:"left"}}>Comfortsitze mit elektrischer Einstellung <br/> Memory- und Massagefunktion</TableCell>
                            <TableCell style={{textAlign:"right"}}>870,53</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>IAPPTK</TableCell>
                            <TableCell style={{textAlign:"left"}}>Panorama Aufstell-/Schiebedach</TableCell>
                            <TableCell style={{textAlign:"right"}}>1.099,53</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>R2D2</TableCell>
                            <TableCell style={{textAlign:"left"}}>Navigationssystem</TableCell>
                            <TableCell style={{textAlign:"right"}}>1.205,88</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>R2D3</TableCell>
                            <TableCell style={{textAlign:"left"}}>Freisprecheinrichtung <br/>Smartphone-Integration</TableCell>
                            <TableCell style={{textAlign:"right"}}>425,63</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>MOD3V</TableCell>
                            <TableCell style={{textAlign:"left"}}>Sicherheitspaket Modern 3 </TableCell>
                            <TableCell style={{textAlign:"right"}}>1.364,89</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>WINT4F</TableCell>
                            <TableCell style={{textAlign:"left"}}>Winterräder Allgripp <br/> 4 Leichtmetallräader Komplettsatz </TableCell>
                            <TableCell style={{textAlign:"right"}}>930,59</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}>FWAEX</TableCell>
                            <TableCell style={{textAlign:"left"}}>Feuerlöscher</TableCell>
                            <TableCell style={{textAlign:"right"}}>96,64</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left", borderTop:"solid" }}><b>Listenpreis</b></TableCell>
                            <TableCell style={{textAlign:"right", borderTop:"solid" }}><b>43.049,21</b></TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Mengennachlass 4%</TableCell>
                            <TableCell style={{textAlign:"right"}}>-1.721,97</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Sondernachlass 7%</TableCell>
                            <TableCell style={{textAlign:"right"}}>-3.013,44</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Aktionsprämie</TableCell>
                            <TableCell style={{textAlign:"right"}}>-1.591,11</TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Überführungskosten</TableCell>
                            <TableCell style={{textAlign:"right"}}>588,24</TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Zulassungskosten</TableCell>
                            <TableCell style={{textAlign:"right"}}>252,10</TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left", borderTop:"solid" }}><b>Fahrzeugpreis netto</b></TableCell>
                            <TableCell style={{textAlign:"right", borderTop:"solid" }}><b>37.563,03</b></TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left"}}>Umsatzsteuer 19%</TableCell>
                            <TableCell style={{textAlign:"right"}}>7.136,97</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell style={{textAlign:"left"}}></TableCell>
                            <TableCell style={{textAlign:"left", borderTop:"solid" }}><b>Gesamtbetrag</b></TableCell>
                            <TableCell style={{textAlign:"right", borderTop:"solid" }}><b>44.700,00</b></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>



    </Grid>





    </div>
 
  )
}

export {PKW_Rechnung};