import React, {useState, useCallback} from 'react';
import {Avatar, Button, ListItem,  ListItemAvatar, ListItemText, Typography, Grid, CardMedia, Card} from "@material-ui/core";
import apiRequest from '../../functions/api';
import { useNavigate } from "react-router-dom";
import { useMessage } from '../../context/message-context';
const excel = '/media/Vorlage Beitragsberechnung.xlsx';
const Infotext = '/media/Erklaerung_Beitragsberechnung.pdf';
const Abrechnungsozial1 = '/media/Gekavau_sozial.pdf';
const Abrechnungsozial2 = '/media/Naumann_sozial.pdf';
const Abrechnungsozial3 = '/media/kunstvoll-sozial.pdf';


function Beitragsberechnung(props) {
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const { accessToken, hsutkey, setSuccessMessage, setErrorMessage, client } = props;
  const navigate=useNavigate();
  const [antworten,setAntworten]=useState({});
  const [isSelected, setIsSelected]=useState(false);
  const [isSending, setIsSending]=useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const {showErrorMessage, hideMessage} = useMessage();
  const [reactnummer]=useState('009');
  const [merkmal]=useState('Beitragsberechnung');

  const setRedirect = (path) => {
    navigate(path);
  }
 

  const antwortenSenden = () => {
    console.log({antworten})
  
    const headers={
      "Authorization": "Bearer "+accessToken,
    }
    const payload={
      "kennung": "kursabgabe",
      "merkmal": merkmal,
      "daten": antworten,
      "reactnummer": reactnummer,
      "hsutkey": hsutkey
    } 
    apiRequest('POST', 'kursabgabe', payload, headers, kurseCb, setRedirect);
  }

  
  const kurseCb = (response) => {
    //   console.log(response.data.message);
   
       if (response.status === 200) {
         
         
         props.showSuccess(response.data.message);
       } else {
         if (response) {
           showErrorMessage(response.data.message);
         } else {
           showErrorMessage('Keine Internetverbindung');
         }
       }
     }






  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const dataCb = (response) => {
    if (response.status === 200) {
      setIsSending(false);
      if (response.data.message === 'ok') {
        setSuccessMessage("Upload erfolgreich.");
        hideMessage();
      }
    } else {
      setIsSending(false);
      if (response) {
        showErrorMessage(response.data.message);
      } else {
        showErrorMessage("Es ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.");
      }
    }
  }

  const getDataUrl = (img) => {
    // Create canvas
    return window.URL.createObjectURL(img);
  }
  
  const handleSubmission = () => {
    if (isSending) {
      return
    }
    if (!selectedFile) {
      setErrorMessage('Keine Datei ausgewählt');
      return
    }
    setIsSending(true);

    const formData = new FormData();

    formData.append('File', selectedFile);

    console.log(selectedFile.name);
    const headers={
        "Authorization": "Bearer "+accessToken,
        "FileName": selectedFile.name,
        "Keywords": "",
        "Mandant": client, // this.props.client,
        "Program": "CWARCH",
        "Statement": "DATEI_IN_DMS_ABLEGEN"
    }
    apiRequest('POST', 'upload-ecscool', formData, headers, dataCb, setRedirect);    

  
  };

  return(
    <div>
    <Grid container spacing={0} style={{marginTop:'30px', justify:"left",  alignItems:"center"  }} >
      <Grid item xs={12} >
         <Typography variant="h2" color="primary">
            Beitragsabrechnung
          </Typography> 
          
      </Grid>
    </Grid>

    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
         
        <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                I
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Hier lernst du wie die Beitragsberechnung geht."/>
          </ListItem>
        </Grid>
          <Grid item xs={8}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={Infotext}download>
                  Infotext
              </Button>
          </Grid>
      </Grid>

      <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
         
        <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Bitte verwende die drei Abrechnungen "/>
          </ListItem>
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={Abrechnungsozial1}download>
                Abrechnung von Frau Gekavau
              </Button>
          </Grid>


          
          <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={Abrechnungsozial2}download>
                Abrechnung von Frau Naumann
              </Button>
          </Grid>
           
            <Grid item xs={12}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={Abrechnungsozial3}download>
                Abrechnung von Frau Kunstvoll
              </Button>
            </Grid>
        </Grid>

      </Grid>


    <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={8}>
         
        <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor:"#1fbba6"}}>
                A
              </Avatar> 
            </ListItemAvatar>
            <ListItemText primary="Lade die Excel Datei runter. Erstelle die Beitragsberechnung bzw. Übertrage die Summe der Beiträge anhand der 3 Entgeltabrechnungen.  Gehe bei den Berechnungen der Umlage 1 - Beiträgen davon aus, dass der zweite Beitragssatz gewählt wurde und berechne den Umlage 1 Beitrag mit dem entsprechenden Beitragssatz. 
                                    Den jeweiligen Prozentsatz für die Umlage 2 und die Insolvenzgeltumlage entnimmst Du bitte der Tabelle. Wenn du fertig bist, lade sie wieder hoch"/>
          </ListItem>
        </Grid>
          <Grid item xs={8}style={{marginTop:"15px",marginBottom:"15px"}}>
              <Button 
                color="primary"  
                variant="contained"
  //              fullWidth
                href={excel}download>
                Excel- Datei
              </Button>
          </Grid>
      </Grid>


    <input type="file" name="file" onChange={changeHandler} />
			{isSelected ? 
      <Card>
        <CardMedia
          component="img"
          alt="Ausgewählte Datei"
          image={getDataUrl(selectedFile)}
          title="Ausgewählte Datei"
        />
        <Typography>{selectedFile.name}</Typography>
      </Card>
      : (
				<p>Wählen Sie eine Datei aus</p>
			)}
			<div>
				<button style={{backgroundColor:"#1fbba6"}} onClick={handleSubmission}>Datei abgeben </button>
			</div>

      </div>

     
      

   
     
    )
  }   


export default Beitragsberechnung;
