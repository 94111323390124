import React from 'react';
import {Grid, Typography, TextField, ListItem, ListItemText, Avatar, ListItemAvatar} from '@material-ui/core';


function TabelleEGA (props) {
    const {tabellenKey, fehlerliste, setzeAntworten, aufgabe, text} = props;
    
    return(
      
        <Grid container spacing={0} style={{marginTop:'30px'}} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}style={{marginTop:"30px"}}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:"#1fbba6"}}>
                  {aufgabe}
                </Avatar> 
              </ListItemAvatar>
              <ListItemText>
                <Typography   >
                  {text} <br/>
                </Typography>
              </ListItemText>
            </ListItem>
      
        </Grid>
        
      
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              Bezeichnung
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',borderTop:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
            Tage
            </Typography>
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              Pflichtig
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',borderTop:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              AN-Anteil
            </Typography>
          </Grid>
          <Grid item xs={2} style={{border:'solid',backgroundColor:'#71ff00'}}>
            <Typography>
              AG-Anteil
            </Typography>
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
            <Typography style={{marginTop:'13px'}}>
                KV LFD
            </Typography>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid'}}>
            <TextField  
                id={'tageKV'} 
                type='number'
                error={fehlerliste["tageKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('tageKV'+tabellenKey,event.target.value)}/>

          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
            <TextField  
                id={'pflichtigKV'} 
                type='number'
                error={fehlerliste["pflichtigKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigKV'+tabellenKey,event.target.value)}/>
          </Grid>
          <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
          <TextField  
                id={'ANaKV'} 
                type='number'
                error={fehlerliste["ANaKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaKV'+tabellenKey,event.target.value)}/>
          </Grid>
          <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
          <TextField  
                id={'AGaKV'} 
                type='number'
                error={fehlerliste["AGaKV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaKV'+tabellenKey,event.target.value)}/>
          </Grid>
          <Grid item xs={1}>
            
          </Grid>
          <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  KV EGA
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <TextField  
                id={'tageKVega'} 
                type='number'
                error={fehlerliste["tageKVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('tageKVega'+tabellenKey,event.target.value)}/>

            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <TextField  
                id={'pflichtigKVega'} 
                type='number'
                error={fehlerliste["pflichtigKVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigKVega'+tabellenKey,event.target.value)}/>

            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <TextField  
                id={'ANaKVega'} 
                type='number'
                error={fehlerliste["ANaKVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaKVega'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <TextField  
                id={'AGaKVega'} 
                type='number'
                error={fehlerliste["AGaKVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaKVega'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  RV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:"51px"}}>
            <TextField  
                id={'tageRV'} 
                type='number'
                error={fehlerliste["tageRV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('tageRV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:"51px"}}>
            <TextField  
                id={'pflichtigRV'} 
                type='number'
                error={fehlerliste["pflichtigRV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigRV'+tabellenKey,event.target.value)}/>

            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <TextField  
                id={'ANaRV'} 
                type='number'
                error={fehlerliste["ANaRV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaRV'+tabellenKey,event.target.value)}/>

            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <TextField  
                id={'AGaRV'} 
                type='number'
                error={fehlerliste["AGaRV"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaRV'+tabellenKey,event.target.value)}/>

            </Grid>
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    RV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                id={'tageRVega'} 
                type='number'
                error={fehlerliste["tageRVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('tageRVega'+tabellenKey,event.target.value)}/>

              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                id={'pflichtigRVega'} 
                type='number'
                error={fehlerliste["pflichtigRVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigRVega'+tabellenKey,event.target.value)}/>

              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                id={'ANaRVega'} 
                type='number'
                error={fehlerliste["ANaRVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaRVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                id={'AGaRVega'} 
                type='number'
                error={fehlerliste["AGaRVega"+tabellenKey]}
                label="Eintrag" 
                inputProps={{style:{textAlign:"center"}}}
                placeholder=""  
                style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaRVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={1}>
                
              </Grid>
              <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  AV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid'}}>
              <TextField  
                  id={'tageAV'} 
                  type='number'
                  error={fehlerliste["tageAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('tageAV'+tabellenKey,event.target.value)}/>
  
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
              <TextField  
                  id={'pflichtigAV'} 
                  type='number'
                  error={fehlerliste["pflichtigAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigAV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <TextField  
                  id={'ANaAV'} 
                  type='number'
                  error={fehlerliste["ANaAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaAV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <TextField  
                  id={'AGaAV'} 
                  type='number'
                  error={fehlerliste["AGaAV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaAV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    AV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                  id={'tageAVega'} 
                  type='number'
                  error={fehlerliste["tageAVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('tageAVega'+tabellenKey,event.target.value)}/>
                                                      
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                  id={'pflichtigAVega'} 
                  type='number'
                  error={fehlerliste["pflichtigAVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigAVega'+tabellenKey,event.target.value)}/>
                   
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                  id={'ANaAVega'} 
                  type='number'
                  error={fehlerliste["ANaAVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaAVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                  id={'AGaAVega'} 
                  type='number'
                  error={fehlerliste["AGaAVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaAVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={1}>
                
              </Grid>
              <Grid item xs={1}>
            
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
              <Typography style={{marginTop:'13px'}}>
                  PV LFD
              </Typography>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid'}}>
              <TextField  
                  id={'tagePV'} 
                  type='number'
                  error={fehlerliste["tagePV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('tagePV'+tabellenKey,event.target.value)}/>
  
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid'}}>
              <TextField  
                  id={'pflichtigPV'} 
                  type='number'
                  error={fehlerliste["pflichtigPV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigPV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
            <TextField  
                  id={'ANaPV'} 
                  type='number'
                  error={fehlerliste["ANaPV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaPV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
            <TextField  
                  id={'AGaPV'} 
                  type='number'
                  error={fehlerliste["AGaPV"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaPV'+tabellenKey,event.target.value)}/>
            </Grid>
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={1}>
              
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',backgroundColor:'#71ff00', height:'51px'}}>
                <Typography style={{marginTop:'13px'}}>
                    PV EGA
                </Typography>
              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                  id={'tagePVega'} 
                  type='number'
                  error={fehlerliste["tagePVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('tagePVega'+tabellenKey,event.target.value)}/>

              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                  id={'pflichtigPVega'} 
                  type='number'
                  error={fehlerliste["pflichtigPVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('pflichtigPVega'+tabellenKey,event.target.value)}/>

              </Grid>
              <Grid item xs={2} style={{borderBottom:'solid',height:'51px'}}>
              <TextField  
                  id={'ANaPVega'} 
                  type='number'
                  error={fehlerliste["ANaPVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('ANaPVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={2} style={{borderRight:'solid',borderBottom:'solid',borderLeft:'solid',height:'51px'}}>
              <TextField  
                  id={'AGaPVega'} 
                  type='number'
                  error={fehlerliste["AGaPVega"+tabellenKey]}
                  label="Eintrag" 
                  inputProps={{style:{textAlign:"center"}}}
                  placeholder=""  
                  style={{display:"flex"}}onChange={(event) => setzeAntworten('AGaPVega'+tabellenKey,event.target.value)}/>
              </Grid>
              <Grid item xs={1}>
                
              </Grid>
      

      </Grid>
    
      



    
    )
}



export {TabelleEGA};


